export default class ApiConst {
  public static EncType = {
    Urlencoded: 'application/x-www-form-urlencoded',
    Multipart: 'multipart/form-data'
  };

  public static get Res() {
    return {
      Ok: 'ok',
      Failed: 'failed'
    };
  }

  public static get Mode() {
    return {
      Default: '',
      Valid: 'valid',
    };
  }

  public static get LogoutMode() {
    return {
      Normal: '',
      All: 'all'
    };
  }

  public static get LoadState() {
    return {
      Ready: -1,
      Loading: -2
    };
  }
}
