/*

	YH: Data Spread Sheet is Here
	https://docs.google.com/spreadsheets/d/1GP-wc5RwVL54UcYqxjH_ZfCQWY7IAfuL_BSC2SXCL8s/edit?usp=sharing

*/

export const DummyPageStore =
{
	id: 'store',
	name: 'Shop',
	type: 'page',
	pattern: {
		index: 'c-page-store-index-standard',
		detail: 'c-page-store-detail-standard',
	},
    aspect: 'auto',
};










