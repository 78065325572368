/*

	YH: Data Spread Sheet is Here
	https://docs.google.com/spreadsheets/d/18RF7vwKqvJwd7ZKlx4oDoTJSatrjOCZ9_Qh2O49ikhM/edit?usp=sharing

*/

export const Tour = [

	{
		name: 'manager-project',
		finish: false,
		steps: [

			{
				target: '.c-sidecover-frame',
				addClass: '-none',
				animation: false,
				maskSize: 0,
				maskAddX: 0,
				maskAddY: 0,
				boxPos: 'none',
				body: '',
				delay: 0,
				scroll: false,
				nextBtn: '',
			},
			{
				target: '.m-primary-frame',
				addClass: '',
				animation: true,
				maskSize: 1,
				maskAddX: 0,
				maskAddY: 0,
				boxPos: 'right',
				body: 'THOUGHTは、内容の入力だけで基本作業が完了します。',
				delay: 0,
				scroll: true,
				nextBtn: '',
			},
			{
				target: '.m-primary-frame',
				addClass: '',
				animation: true,
				maskSize: 1,
				maskAddX: 0,
				maskAddY: 0,
				boxPos: 'right',
				body: 'どんな内容を入力するのか実際の画面を見てみましょう。',
				delay: 0,
				scroll: true,
				nextBtn: '',
			},
			{
				target: '.c-mv-frame',
				addClass: '',
				animation: true,
				maskSize: 1,
				maskAddX: 0,
				maskAddY: 0,
				boxPos: 'left',
				body: 'このエリアはメインビジュアルと呼ばれ、写真を配置できます。',
				delay: 0,
				scroll: true,
				nextBtn: '',
			},
			{
				target: '.c-sidecover-frame',
				addClass: '-none',
				animation: false,
				maskSize: 0,
				maskAddX: 0,
				maskAddY: 0,
				boxPos: 'none',
				body: '',
				delay: 0,
				scroll: false,
				nextBtn: '',
			},
			{
				target: '.c-sidecover',
				addClass: '',
				animation: true,
				maskSize: 2.5,
				maskAddX: 0,
				maskAddY: 0,
				boxPos: 'left',
				body: 'このエリアはサイドカバーと呼ばれます。',
				delay: 0,
				scroll: true,
				nextBtn: '',
			},
			{
				target: '.c-sidecover',
				addClass: '',
				animation: true,
				maskSize: 2.5,
				maskAddX: 0,
				maskAddY: 0,
				boxPos: 'left',
				body: '道順や更新情報を表示することができます。OFFにもできます。',
				delay: 0,
				scroll: true,
				nextBtn: '',
			},
			{
				target: '.c-sidecover-frame',
				addClass: '-none',
				animation: false,
				maskSize: 0,
				maskAddX: 0,
				maskAddY: 0,
				boxPos: 'none',
				body: '',
				delay: 0,
				scroll: false,
				nextBtn: '',
			},
			{
				target: '.c-mv-address-contents',
				addClass: '',
				animation: true,
				maskSize: 2.5,
				maskAddX: 0,
				maskAddY: 0,
				boxPos: 'left',
				body: 'お店の住所や営業時間などを表示するエリアです。',
				delay: 0,
				scroll: true,
				nextBtn: '',
			},
			{
				target: '.-block-concept',
				addClass: '',
				animation: true,
				maskSize: 1,
				maskAddX: 0,
				maskAddY: 0,
				boxPos: 'left',
				body: 'お店やあなたのコンセプトを文や写真で伝えることができます。',
				delay: 0,
				scroll: true,
				nextBtn: '',
			},
			{
				target: '.-block-gallery',
				addClass: '',
				animation: true,
				maskSize: 1,
				maskAddX: 0,
				maskAddY: 0,
				boxPos: 'left',
				body: 'ギャラリーが表示されます。文章で魅せることもできます。',
				delay: 0,
				scroll: true,
				nextBtn: '',
			},
			{
				target: '.-block-staff',
				addClass: '',
				animation: true,
				maskSize: 1,
				maskAddX: 0,
				maskAddY: 0,
				boxPos: 'left',
				body: 'スタッフが表示されます。文章で魅せることもできます。',
				delay: 0,
				scroll: true,
				nextBtn: '',
			},
			{
				target: '.-block-store',
				addClass: '',
				animation: true,
				maskSize: 1,
				maskAddX: 0,
				maskAddY: 0,
				boxPos: 'left',
				body: 'お店が表示されます。文章で魅せることもできます。',
				delay: 0,
				scroll: true,
				nextBtn: '',
			},
			{
				target: '.-block-store',
				addClass: '',
				animation: true,
				maskSize: 1,
				maskAddX: 0,
				maskAddY: 0,
				boxPos: 'left',
				body: 'たったこれだけの入力できれいなホームページが完成します。',
				delay: 0,
				scroll: true,
				nextBtn: '',
			},
			{
				target: '.id--top',
				addClass: '',
				animation: true,
				maskSize: 0.5,
				maskAddX: -106,
				maskAddY: 0,
				boxPos: 'right',
				body: 'まずはトップページの編集からおこなってみましょう。',
				delay: 0,
				scroll: true,
				nextBtn: '',
			},
			{
				target: '.m-icon-launch',
				addClass: '',
				animation: true,
				maskSize: 3,
				maskAddX: 0,
				maskAddY: 0,
				boxPos: 'bottom',
				body: '準備が整ったら&quot;公開ボタン&quot;を押してホームページを公開しましょう!',
				delay: 0,
				scroll: true,
				nextBtn: '',
			},
			{
				target: '.c-sidecover-frame',
				addClass: '-none',
				animation: false,
				maskSize: 0,
				maskAddX: 0,
				maskAddY: 0,
				boxPos: 'none',
				body: '',
				delay: 0,
				scroll: false,
				nextBtn: '',
			},
			{
				target: '.c-mv-frame',
				addClass: '',
				animation: false,
				maskSize: 0,
				maskAddX: 0,
				maskAddY: 0,
				boxPos: 'none',
				body: '',
				delay: 0,
				scroll: true,
				nextBtn: '',
			},

		]
	},
	{
		name: 'manager-project-home',
		finish: false,
		steps: [
						{
				target: '.c-sidecover-frame',
				addClass: '-none',
				animation: false,
				maskSize: 0,
				maskAddX: 0,
				maskAddY: 0,
				boxPos: 'none',
				body: '',
				delay: 0,
				scroll: false,
				nextBtn: '',
			},
			{
				target: '.c-mv-frame',
				addClass: '',
				animation: true,
				maskSize: 1.2,
				maskAddX: 0,
				maskAddY: 0,
				boxPos: 'left',
				body: 'トップページはアクセスしたときに最初に表示されるページです',
				delay: 0,
				scroll: true,
				nextBtn: '',
			},
			{
				target: '.c-mv-frame',
				addClass: '',
				animation: true,
				maskSize: 1.2,
				maskAddX: 0,
				maskAddY: 0,
				boxPos: 'left',
				body: 'トップページにはどんな要素が配置できるか一緒に見てみましょう',
				delay: 0,
				scroll: true,
				nextBtn: '',
			},
			{
				target: '.c-mv-frame',
				addClass: '',
				animation: true,
				maskSize: 1,
				maskAddX: 0,
				maskAddY: 0,
				boxPos: 'left',
				body: 'メインビジュアルであなただけの世界観を表現できます。',
				delay: 0,
				scroll: true,
				nextBtn: '',
			},
			{
				target: '.-block-concept',
				addClass: '',
				animation: true,
				maskSize: 1,
				maskAddX: 0,
				maskAddY: 0,
				boxPos: 'left',
				body: 'お店やあなたのコンセプトを文や写真で伝えることができます。',
				delay: 0,
				scroll: true,
				nextBtn: '',
			},
			{
				target: '.-block-gallery',
				addClass: '',
				animation: true,
				maskSize: 1,
				maskAddX: 0,
				maskAddY: 0,
				boxPos: 'left',
				body: 'カタログやポートフォリオへの誘導ブロックを設置できます',
				delay: 0,
				scroll: true,
				nextBtn: '',
			},
			{
				target: '.-block-staff',
				addClass: '',
				animation: true,
				maskSize: 1,
				maskAddX: 0,
				maskAddY: 0,
				boxPos: 'left',
				body: 'スタッフページへの誘導ブロックを設置できます',
				delay: 0,
				scroll: true,
				nextBtn: '',
			},
			{
				target: '.-block-store',
				addClass: '',
				animation: true,
				maskSize: 1,
				maskAddX: 0,
				maskAddY: 0,
				boxPos: 'left',
				body: 'お店の地図、紹介ページへの誘導ブロックを設置できます',
				delay: 0,
				scroll: true,
				nextBtn: '',
			},
			{
				target: '.-block-blog-news',
				addClass: '',
				animation: true,
				maskSize: 1,
				maskAddX: 0,
				maskAddY: 0,
				boxPos: 'left',
				body: 'ブログやニュースへの誘導ブロックを設置できます',
				delay: 0,
				scroll: true,
				nextBtn: '',
			},
			{
				target: '.-block-instagram',
				addClass: '',
				animation: true,
				maskSize: 1,
				maskAddX: 0,
				maskAddY: 0,
				boxPos: 'left',
				body: 'Instagramへの誘導ブロックを設置できます',
				delay: 0,
				scroll: true,
				nextBtn: '',
			},
			{
				target: '.id-m-menu',
				addClass: '',
				animation: true,
				maskSize: 1,
				maskAddX: 0,
				maskAddY: 0,
				boxPos: 'right',
				body: 'メニューから各ブロックの表示やレイアウト編集を触ってみましょう',
				delay: 0,
				scroll: true,
				nextBtn: '',
			},
			{
				target: '.c-sidecover-frame',
				addClass: '-none',
				animation: false,
				maskSize: 0,
				maskAddX: 0,
				maskAddY: 0,
				boxPos: 'none',
				body: '',
				delay: 0,
				scroll: false,
				nextBtn: '',
			},
			{
				target: '.c-mv-frame',
				addClass: '',
				animation: false,
				maskSize: 0,
				maskAddX: 0,
				maskAddY: 0,
				boxPos: 'none',
				body: '',
				delay: 0,
				scroll: true,
				nextBtn: '',
			},
		]
	},


];
