import {OsApi} from '@/classes/app/api/os-api';

export class OsLoader {
    public static async load(page: string = 'index', param: any = null): Promise<any> {

        let r = {
            title: 'Test'
        };

        switch (page) {
            case 'index':
                await OsApi.index.get().then((res: any) => {
                    console.log('OsApi.index.get() > res > ', res.data);
                    r = res.data;
                }).exec();
                break;
            case 'staff':
                await OsApi.staff.get().then((res: any) => {
                    console.log('OsApi.staff.get() > res > ', res.data);
                    r = res.data;
                }).exec();
                break;
            case 'menu':
                await OsApi.menu.get().then((res: any) => {
                    console.log('OsApi.menu.get() > res > ', res.data);
                    r = res.data;
                }).exec();
                break;
            case 'gallery':
                await OsApi.gallery.get(param).then((res: any) => {
                    // console.log('res > ', res.data);
                    r = res.data;
                }).exec();
                break;
            case 'blog':
                await OsApi.blog.get(param).then((res: any) => {
                    // console.log('res > ', res.data);
                    r = res.data;
                }).exec();
                break;
            case 'store':
                await OsApi.storex.get(param).then((res: any) => {
                    console.log('res > ', res.data);
                    r = res.data;
                }).exec();
                break;
            default:
                break;
        }
        return r;
    }
}
