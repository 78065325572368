import VarUtil, {$v} from "@/classes/utils/var-util";
import {sprintf} from "sprintf-js";
import {ConfigAppPrefectures} from "@/configs/app/prefectures";
import {MvAddressBlock2BodyRender} from "@/classes/domain/module/os/project/binder/render/mv-address-block2-body-render";
import {HolidayDefaultFormatter} from "@/classes/domain/module/model/site/store/holiday-default-formatter";
import {ISiteStoreHoliday, ISiteStoreWorktime} from "@/classes/domain/module/model/site/store/i-site-store";
import {Lang} from "@/classes/app/app-const";
import {WorktimeDefaultFormatter} from "@/classes/domain/module/model/site/store/worktime-default-formatter";
import {AddressUtil} from "@/classes/domain/module/model/address/address-util";

export class ProjectOwnerAdapter {

    private readonly _project: any;
    private readonly _fns = [
        {k: 'mainstore.open', r: () => this.mainStoreOpen},
        {k: 'mainstore.close', r: () => this.mainStoreClose},
        {k: 'mainstore.prefecture', r: () => this.mainStoreAddressPrefecture},
        {k: 'mainstore.address1', r: () => this.mainStoreAddress1},
        {k: 'mainstore.address2', r: () => this.mainStoreAddress2},
        {k: 'mainstore.tel', r: () => this.mainStoreTel},
        {k: 'mvAddress.block1body', r: () => this.mvAddressBlock1Body},
        {k: 'mvAddress.block2body', r: () => this.mvAddressBlock2Body},
    ];

    constructor(project: any) {
        this._project = project;
    }

    // Computed //////////////////////////////////////////////////////////////
    public get project(): any {
        return this._project;
    }

    public get mainStoreOpen(): string[] {
        const worktimes = this.p('mainstore.worktimes');
        return (new WorktimeDefaultFormatter(worktimes, {
            lang: Lang.Ja,
        })).formats();
    }

    public get mainStoreClose(): string[] {
        const holidays = this.p('mainstore.holidays') || [];
        return holidays.map((_: ISiteStoreHoliday) => {
            return (new HolidayDefaultFormatter(_, {
                lang: Lang.Ja,
            })).format()
        });
    }

    public get mainStoreTel(): string {
        const contacts = this.p('mainstore.contacts') || [];
        const c = contacts.find((_: any) => {
            return (_.type === 'tel');
        });

        return $v.p(c, 'body');
    }

    public get mainStore(): string {
        return this.p('mainstore');
    }


    public get mainStoreAddress1(): string {
        return this.mainStoreAddressPrefecture(Lang.Ja)
            + $v.p(this.address('ja'), 'city');
    }

    public get mainStoreAddress2(): string {
        return $v.p(this.address('ja'), 'street');
    }

    public get mvAddressBlock1Body(): string {
        return sprintf('%s<br>%s %s',
            $v.p(this.address('en'), 'street'),
            $v.p(this.address('en'), 'city'),
            this.prefecture('jp', 'en'),
        );
    }

    public get mvAddressBlock2Body(): string {
        return (new MvAddressBlock2BodyRender(this.project)).render();
    }

    // Methods //////////////////////////////////////////////////////////////
    public mainStoreAddressPrefecture(lang: Lang = Lang.Ja): string {

        const country = this.p('mainstore.address.country');
        const prefecture = this.p('mainstore.address.prefecture');

        return AddressUtil.prefecture(country, prefecture, lang);
    }

    public address(lang: string): any {
        const langs = this.p('mainstore.address.langs');

        if (!$v.isArray(langs)) {
            return '';
        }
        // console.log('langs > ', langs);

        return langs.find((_: any) => {
            return ($v.p(_, 'lang') === lang);
        });
    }

    public prefecture(country: string, lang: string): any {
        const pref = this.p('mainstore.address.prefecture');
        const src = ConfigAppPrefectures.find((_: any) => {
            return (_.country === country && _.lang === lang);
        });

        if (!src) {
            return '';
        }

        const p = src.prefectures.findByKey('value', pref);
        // console.log('prefecture() > (pref, p)', pref, p);

        return !!p ? p.label : '';
    }

    public p(path: string, def: string = ''): any {

        const fn = this._fns.findByKey('k', path);
        if (!!fn) {
            if ($v.isFunction(fn.r)) {
                return (fn.r as any)();
            } else {
                return fn.r;
            }
        }

        return VarUtil.p(this._project, path, def);
    }
}
