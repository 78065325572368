/*

	YH: Data Spread Sheet is Here
    https://docs.google.com/spreadsheets/d/1g3gwEqasOmpKq_Ypqb-i-Onl4PNeV5FHqOTxfTehv3M/edit?usp=sharing

*/

export const Pagetype = [

{
	pagetype: 'fullslide',
	group: 'tool',
	layout: 'full',
	gnav: true,
	prlink: true,
	logo: false,
	navbg: false
},
{
	pagetype: 'official-home',
	group: 'pr',
	layout: 'full',
	gnav: true,
	prlink: true,
	logo: true,
	navbg: false
},
{
	pagetype: 'official-layout',
	group: 'pr',
	layout: 'full',
	gnav: true,
	prlink: true,
	logo: true,
	navbg: false
},
{
	pagetype: 'official-page',
	group: 'pr',
	layout: 'full',
	gnav: true,
	prlink: true,
	logo: true,
	navbg: false
},
{
	pagetype: 'official-help',
	group: 'pr',
	layout: 'right-primary',
	gnav: true,
	prlink: true,
	logo: true,
	navbg: false
},
{
	pagetype: 'official-doc',
	group: 'pr',
	layout: 'full',
	gnav: true,
	prlink: true,
	logo: true,
	navbg: false
},
{
	pagetype: 'official-half-gray',
	group: 'pr',
	layout: 'right-primary',
	gnav: true,
	prlink: true,
	logo: true,
	navbg: false
},
{
	pagetype: 'official',
	group: 'pr',
	layout: 'full',
	gnav: true,
	prlink: true,
	logo: true,
	navbg: false
},
{
	pagetype: 'layout',
	group: 'tool',
	layout: 'full',
	gnav: true,
	prlink: false,
	logo: true,
	navbg: false
},
{
	pagetype: 'pr',
	group: 'pr',
	layout: 'note',
	gnav: true,
	prlink: false,
	logo: true,
	navbg: false
},
{
	pagetype: 'account',
	group: 'tool',
	layout: 'right-primary',
	gnav: true,
	prlink: false,
	logo: true,
	navbg: false
},
{
	pagetype: 'procedure',
	group: 'procedure',
	layout: 'right-primary',
	gnav: true,
	prlink: false,
	logo: true,
	navbg: false
},
{
	pagetype: 'project',
	group: 'tool',
	layout: 'left-primary',
	gnav: false,
	prlink: false,
	logo: true,
	navbg: false
},
{
	pagetype: 'wizard-start',
	group: 'tool',
	layout: 'wizard',
	gnav: true,
	prlink: true,
	logo: false,
	navbg: false
},
{
	pagetype: 'wizard',
	group: 'tool',
	layout: 'wizard',
	gnav: true,
	prlink: true,
	logo: false,
	navbg: false
},
{
	pagetype: 'usertop',
	group: 'tool',
	layout: 'full',
	gnav: true,
	prlink: false,
	logo: true,
	navbg: true
},
{
	pagetype: 'fulltool',
	group: 'tool',
	layout: 'full',
	gnav: true,
	prlink: false,
	logo: true,
	navbg: false
},



];
