import {Mutation, MutationAction, Action, VuexModule, getModule, Module} from 'vuex-module-decorators';
import store from '@/classes/core/store';
import {IOsHomeContent} from '@/classes/domain/module/os/home/i-os-home-content';
import {OsLoader} from '@/classes/app/os/os-loader';
import {ownersiteModule} from '@/store/ownersite';

// state's interface
export interface IOsStaffModule {
    content: any | null;
}

@Module({dynamic: true, store, name: 'osStaff', namespaced: true})
class Store extends VuexModule implements IOsStaffModule {

    // Page data
    public content: any | null = null;

    @Mutation
    public updateContent(value: any) {
        this.content = value;
    }

    @Action
    public async fetch() {

        // console.log('Dummy pj', JSON.stringify(DummyProject));
        // console.log('Dummy page home', JSON.stringify(DummyPageHome));

        const page = await OsLoader.load('staff');
        ownersiteModule.updateProject(page.project);
        osStaffModule.updateContent(page.content);
    }
}

export const osStaffModule = getModule(Store);

