import {Mutation, MutationAction, Action, VuexModule, getModule, Module} from 'vuex-module-decorators';
import store from '@/classes/core/store';

// state's interface
export interface IAccountModule {
    dev_domainStates: string;
    dev_procedure: string;
}

@Module({dynamic: true, store, name: 'account', namespaced: true})
class Account extends VuexModule implements IAccountModule {

	/*
		ドメイン状況
		テスト表示用の変数

		no : 契約なし
		connect-wait : 接続待ち
		connect-success : 接続済み
		get-wait : 取得作業中
		get-success : 取得済み
	*/
    public dev_domainStates: string = 'no';

    /*
		テスト表示用の変数
	    plan-subscribe : プラン新規
	    plan-change : プラン変更
		plan-unsubscribe : 解約
	    domain-subscribe : ドメイン取得
	    domain-connect : ドメイン接続
		domain-unsubscribe : 解約
		domain-move : ドメインの移行
    */
    public dev_procedure: string = '';


    @Mutation
    public updateDevDomainStates(value: string) {
		this.dev_domainStates = value;
    }

    @Mutation
    public updateDevProcedure(value: string) {
		this.dev_procedure = value;
    }

//     @Mutation
//     public updateProcedureStep(value: number) {
// 		this.procedureStep = value;
//     }

}

export const accountModule = getModule(Account);

