import {Component, Vue} from 'nuxt-property-decorator';
import {themeMainModule} from '@/store/theme/theme-main';
import CMix from '@/classes/theme/mixin/c-mix';
import CPageBlogIndexStandard from '@/components/theme/page/blog/index/CPageBlogIndexStandard.vue';

@Component({
    mixins: [CMix],
    components: {
        CPageBlogIndexStandard,
    }
})
export default class CIndex extends Vue {

    public title: string;

    ///////// 全ページ共通 ////////////////////////////////////////////
    public page: any = {
        type: 'page',
        page: 'blog',
        depth: 'index',
    };

    public async created() {
        //PAGE情報をSTOREにセット
        this!['updateMyPage'](this.page, themeMainModule);
    }

    public mounted() {

        this!['init']();
    }
}
