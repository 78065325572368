import {Component, Prop} from '@/node_modules/nuxt-property-decorator';
import APopup from "@/classes/page/a-popup";

import MPopupMBody from '@/components/popup/module/MPopupMBody.vue';
import {SystemConfig} from "@/configs/system";
import {$v} from "@/classes/utils/var-util";
import {IPopup, popupModule} from "@/store/popup";
import {sprintf} from "sprintf-js";
import {UiStoreWorktimeUtil} from "@/classes/domain/module/manager/ui/store-worktime/ui-store-worktime-util";
import {IWorktime} from "@/classes/domain/module/manager/ui/store-worktime/i-worktime";
import {UiStoreWorktime} from "@/classes/domain/module/manager/ui/store-worktime/ui-store-worktime";
import {cmdModule} from "@/store/cmd";
import {ConfigManagerAction} from "@/configs/app/manager-action";
import {ISiteStoreWorktime} from "@/classes/domain/module/model/site/store/i-site-store";

const t = (hour: number, minute: number = 0) => {
    return (60 * hour) + minute;
};

const WORKTIME_TMPL: ISiteStoreWorktime[] = [
    {w: 'w1', st: t(10, 0), end: t(19)},
    {w: 'w2', st: t(10, 0), end: t(19)},
    {w: 'w3', st: t(10, 0), end: t(19)},
    {w: 'w4', st: t(10, 0), end: t(19)},
    {w: 'w5', st: t(10, 0), end: t(19)},
    {w: 'w6', st: t(10, 0), end: t(19)},
    {w: 'w0', st: t(10, 0), end: t(19)},
    {w: 'w7', st: t(10, 0), end: t(19)},
];

@Component({
    components: {
        MPopupMBody
    }
})
export default class MPopupStoreHours extends APopup {
    @Prop({default: '@'})
    public mid: string;

    public state = {
        config: {
            editId: 'popupStoreWorktime',
            popup: {
                editCancel: 'worktime.editCancel',
            },
            timeSeparator: '—',
            cmd: [
                ''
            ],
            weeks: SystemConfig.weeks.from(),
            timeBase: (60 * 7), // -> 7H
            timeRange: (60 * 19), // -> AM:7 - AM:2
            unitPx: 12.5, // -> px
            unitTime: 30, // -> min
        },
        view: {
            upcnt: 0,
        },
        input: {
            worktimes: [],
        },
        event: {
            drag: {
                target: '', // top, bottom, body
                w: null as IWorktime, // WorkTime
                wt: null as UiStoreWorktime,
                top: 0,
                mv: 0,
                mvt: 0,
                org: null as any,
                histories: [],
            },
        },
    };

    // Mounted /////////////////////////////////////////////////////
    public async mounted() {
        await this.initValues();
    }

    public async initValues() {
        this.state.input.worktimes = $v.p(this.option, 'worktimes', WORKTIME_TMPL.from());
    }

    // Computed ///////////////////////////////////////////////////
    public get popup(): IPopup {
        return popupModule.popups.findByKey('id', this.mid);
    }

    public get option(): any {
        return $v.p(this.popup, 'option', {});
    }

    public get weeks(): any[] {
        return this.state.config.weeks;
    }

    public get worktimes(): any[] {
        return this.state.input.worktimes;
    }

    public get tsp(): string {
        return this.state.config.timeSeparator;
    }

    public get isDragging(): boolean {
        return !$v.isEmpty(this.drag.target);
    }

    public get drag(): any {
        return this.state.event.drag;
    }

    // Methods ////////////////////////////////////////////////////
    public wSL(wk: string) {
        const w = this.weeks.findByKey('key', wk);
        return $v.p(w, 'labelS');
    }

    public tL(t: number) {
        return sprintf('%s:%02s', Math.floor(t / 60), (t % 60));
    }

    public startDrag(target: string, w: IWorktime, top: number) {

        if (this.isDragging) {
            this.enterDrag(top);
            return;
        }

        console.log('start drag > ', target, w, top);
        this.state.event.drag = {
            target,
            w,
            wt: this.getEditWorkTime(w),
            top,
            mv: 0,
            mvt: 0,
            org: w,
            histories: [],
        };
    }

    public enterDrag(clientY: number) {
        const mv = (this.drag.top - clientY);
        const wt = this.getDragWorkTime(this.drag.target, mv);

        this.state.event.drag = {
            ...this.drag,
            ...{
                mv,
                wt,
                histories: [{
                    st: wt.worktime.st,
                    end: wt.worktime.end
                }].from(this.drag.histories.slice(0, 3)),
            },
        };

        // console.log('enterDrag() > mv=%s, st=%s, end=%s',
        //     mv,
        //     this.drag.wt.rawStartTime,
        //     this.drag.wt.rawEndTime,
        // );
    }

    public endDrag() {

        if (this.drag.histories.length > 0) {

            const nwt = {
                w: this.drag.w.w,
                st: this.drag.histories[1].st,
                end: this.drag.histories[1].end,
            };

            // console.log('end drag',
            //     this.drag.w.t[0],
            //     this.drag.w.t[1]
            // );
            //
            // console.log(' - to >',
            //     nwt,
            //     nwt.t[0],
            //     nwt.t[1]
            // );

            this.state.input.worktimes = this.state
                .input
                .worktimes
                .replaceByKey('w', nwt)
                .array;
        }

        this.state.event.drag = {
            target: '',
            w: null,
            wt: null,
            top: 0,
            mv: 0,
            mvt: 0,
            org: null,
            histories: [],
        };
    }

    // - L -----------------------------------------------------
    public isCurrentWorktime(w: IWorktime): boolean {
        return ($v.p(this.drag, 'w.w') === w.w);
    }

    public workTimeDragging(): UiStoreWorktime {
        const cfg = this.state.config;

        // console.log('workTimeDragging > ', $v.p(this, 'drag.w'));
        return UiStoreWorktimeUtil.createWorkTime(
            $v.p(this, 'drag.w', {
                w: 'w',
                st: 0,
                end: 0,
            }),
            cfg.timeBase,
            cfg.timeRange,
            cfg.unitTime,
            cfg.unitPx,
        );
    }

    public getDragWorkTime(target: string, mv: number): UiStoreWorktime {
        const cfg = this.state.config;
        switch (target) {
            case 'top':
                return this.workTimeDragging().moveStartPx(mv * -1);
            case 'body':
                return this.workTimeDragging().movePx(mv * -1);
            case 'bottom':
                return this.workTimeDragging().moveEndPx(mv * -1);
            default:
                console.error('target error', this.drag);
                return UiStoreWorktimeUtil.createWorkTime(
                    this.drag.w,
                    cfg.timeBase,
                    cfg.timeRange,
                    cfg.unitTime,
                    cfg.unitPx,
                );
        }
    }

    public getEditWorkTime(w: IWorktime): UiStoreWorktime {
        const cfg = this.state.config;
        return this.isCurrentWorktime(w) ? this.drag.wt
            : UiStoreWorktimeUtil.createWorkTime(
                w,
                cfg.timeBase,
                cfg.timeRange,
                cfg.unitTime,
                cfg.unitPx,
            );
    }

    public styleWTPos(w: IWorktime) {
        const wt = this.getEditWorkTime(w);
        return {
            top: `${wt.topPx}px`,
            height: `${wt.heightPx}px`,
        }
    }

    // Events /////////////////////////////////////////////////////
    ////////// POPUP LAYER 共通処理 /////////////////////////////////
    public onClose(): any {
        this.$emit('close');
    }

    // - Drag / Top -----------------------------------------
    public onDragHandleTop(w: IWorktime, evt: any) {
        // console.log('onDragStartHandleTop');
        this.startDrag('top',
            w,
            $v.num($v.p(evt, 'clientY')));
    }

    // - Drag / Bottom -----------------------------------------
    public onDragHandleBottom(w: IWorktime, evt: any) {
        // console.log('onDragStartHandleBottom');
        this.startDrag('bottom',
            w,
            $v.num($v.p(evt, 'clientY')));
    }

    // - Drag / Body -----------------------------------------
    public onDragHandleBody(w: IWorktime, evt: any) {
        // console.log('onDragStartHandleBddy', evt);
        this.startDrag('body',
            w,
            $v.num($v.p(evt, 'clientY')));
    }

    public onDragEnd(evt: any) {
        this.endDrag();
    }

    public async onClickSave() {
        // if (this.upcnt > 0) {
        //     await cmdModule.registCmd({
        //         cmd: ConfigManagerAction.Popup.StoreHoliday.Save,
        //         request: {
        //             holidays: this.holidays,
        //         },
        //     });
        // }
        await cmdModule.registCmd({
            cmd: ConfigManagerAction.Popup.StoreWorktime.Save,
            request: {
                worktimes: this.worktimes,
            },
        });

        this.onClose();
    }
}
