import {Mutation, MutationAction, Action, VuexModule, getModule, Module} from 'vuex-module-decorators';
import store from '@/classes/core/store';
import {AppType} from '@/classes/app/app-const';
import {Ownersite} from '@/classes/domain/model';

// state's interface
export interface IAppStore {

    appType: AppType;  // アプリ種別

    lang: string;

    //const
    defaultShopname: string;
    defaultTagline: string;

    //Page
    pagetype: string; //Spread SheetのPagelistを参照 & CSS Class
    divider: string; //CSS Class
    lastToolPagePath: string; //左上のもどるボタンの戻り先。pagetype.groupがtoolの時のみ保存
    inPageTransition: boolean; //ページ遷移時にPagetypeを比較するために使用

    //Account
    //Popupに移植後に layouts/default.vueで使用
    isAccount: boolean; //Accountを開いていたらtrue

    //Page data by Pagetype
    pageGroup: string;
    pageLayout: string;
    pageGnav: boolean;
    pagePrlink: boolean;
    pageLogo: boolean;
    pageNavbg: boolean;

    navBgColor: string;

    //State
    signin: boolean;

    //Index, Start, Make
//     baseLoading: boolean;
    shopname: string;
    tagline: string;
    logoActive: string;

    //Brand
    brandId: string;

    //Device
    deviceSize: string;
    deviceDevice: string;
    deviceOs: string;

	mainFrameWidth: number;
	mainFrameHeight: number;
	scrollTop: number;

}

@Module({dynamic: true, store, name: 'app', namespaced: true})
class App extends VuexModule implements IAppStore {

    public appType: AppType = AppType.Production;

    public lang: string = 'ja';

    public loading: boolean = true;

    //const
    public defaultShopname: string = 'Yourname';
    public defaultTagline: string = 'Slogan, Tagline Here';

    //Brand
    public brandId: string = 'tiny-garamond';

    //Account
    public isAccount: boolean = false;

    //Page
    public pagetype: string = '';
    public divider: string = 's';
    public lastToolPagePath: string = '';
    public inPageTransition: boolean = false;

    //Page data by Pagetype
    public pageGroup: string = '';
    public pageLayout: string = '';
    public pageGnav: boolean = false;
    public pagePrlink: boolean = false;
    public pageLogo: boolean = true;
    public pageNavbg: boolean = true;

    public navBgColor: string = 'wh';

    //State
    public signin: boolean = false;

    //Index, Start, Make
//     public baseLoading: boolean = true;
    public shopname: string = this.defaultShopname;
    public tagline: string = this.defaultTagline;
    public logoActive: string = '1';

    //Device
    public deviceSize: string = '';
    public deviceDevice: string = '';
    public deviceOs: string = '';

	public mainFrameWidth: number = 0;
	public mainFrameHeight: number = 0;
	public scrollTop: number = 0;



    //appType
    @Mutation updateAppType(value: AppType) {
        this.appType = value;
    }

    //Device
    @Mutation
    public updateDeviceSize(value: string) {
	    this.deviceSize = value;
    }

    @Mutation
    public updateDeviceDevice(value: string) {
	    this.deviceDevice = value;
    }

    @Mutation
    public updateDeviceOs(value: string) {
	    this.deviceOs = value;
    }

    //main frame sizes
    @Mutation
    public updateMainFrameWidth(value: number) {
	    this.mainFrameWidth = value;
    }

    @Mutation
    public updateMainFrameHeight(value: number) {
	    this.mainFrameHeight = value;
    }


    // Account ////////////////////////////////////////////////////////////

    @Mutation
    public updateIsAccount(value: boolean) {
        console.log('aadawdawdawdadasdasasdasdas');
	    this.isAccount = value;
    }



    /*------------------------------------*\
        Page
    \*------------------------------------*/
    @Mutation
    public updatePagetype(value: string = '') {
        console.log('@Todo - store/app.updatePagetype()', value);
        this.pagetype = value;
    }

    @Mutation
    public updatePageGroup(value: string = '') {
        this.pageGroup = value;
    }

    @Mutation
    public updatePageLayout(value: string = '') {
        this.pageLayout = value;
    }

    @Mutation
    public updatePageGnav(value: boolean = false) {
        this.pageGnav = value;
    }

    @Mutation
    public updatePagePrlink(value: boolean = false) {
        this.pagePrlink = value;
    }

    @Mutation
    public updatePageLogo(value: boolean = false) {
        this.pageLogo = value;
    }

    @Mutation
    public updatePageNavbg(value: boolean = false) {
        this.pageNavbg = value;
    }

    @Mutation
    public updateNavBgColor(value: string = 'wh') {
        this.navBgColor = value;
    }

    @Mutation
    public updateDivider(value: string = '') {
        this.divider = value;
    }

    @Mutation
    public updateInPageTransition(value: boolean = false) {
        this.inPageTransition = value;
    }

    @Mutation
    public updateLastToolPagePath(value: string = '') {
        this.lastToolPagePath = value;
        //console.log(`lastToolPagePath: ${this.lastToolPagePath}`);
    }

    /*------------------------------------*\
    State
    \*------------------------------------*/
    @Mutation
    public updateSignin(value: boolean = false) {
        this.signin = value;
    }

    @Mutation
    public updateBrandId(value: string) {
        this.brandId = value;
    }

    /*------------------------------------*\
    Index, Start, Wizard
    \*------------------------------------*/
//     @Mutation
//     public updateBaseLoading(value: boolean = true) {
//         this.baseLoading = value;
//     }

    @Mutation
    public updateShopname(value: string = '') {
        this.shopname = value;
    }

    @Mutation
    public updateTagline(value: string = '') {
        this.tagline = value;
    }

    @Mutation
    public updateLogoActive(value: string) {
        this.logoActive = value;
    }

    @Mutation
    public updateScrollTop(value: number) {
	    this.scrollTop = value;
    }
}

export const appModule = getModule(App);
