import {Component, Watch, Prop, Vue} from '@/node_modules/nuxt-property-decorator';
import APopup from "@/classes/page/a-popup";

import {layoutSelectModule} from '@/store/layout-select';
import {SelectLayouts} from '@/configs/select-layouts';
import SelectLayoutSliderMix from '@/classes/mixin/select-layout-slider-mix';

import MPopupMBody from '@/components/popup/module/MPopupMBody.vue';

@Component({
    mixins: [SelectLayoutSliderMix],
    components: {
    }
})
export default class MPopupLayoutPreview extends APopup {

    //Popup
    public device: string = 'pc';
    public isPopDevice: boolean = false;

    // Component //////////////////////////////////////////////////////////////
    public list: any = SelectLayouts;


    public created() {

    }


    // Computed //////////////////////////////////////////////////////////////
    public get activeIndex(): any {
        return layoutSelectModule.activeIndex;
    }

    // Method //////////////////////////////////////////////////////////////
    public changeDevice(d: string): any {
	    this.device = d;
    }

    public onPopDevice(){
	    if( this.isPopDevice ){
	    	this.isPopDevice = false;
	    }else{
	    	this.isPopDevice = true;
	    }
    }

    ////////// POPUP LAYER 共通処理 ///////////////////////////////////////////
	public onClose(to: string=''): any {
		if(to != ''){
			this.$router.push(to);
		}
        this.$emit('close');
	}
}
