import {Component, Prop, Vue} from 'nuxt-property-decorator';
import {themeMainModule} from '@/store/theme/theme-main';
import CPatternMix from '@/classes/theme/mixin/c-pattern-mix';

import {DummyArticleCategory} from '@/configs/dummy/dummy-article-category';
import {DummyGallery} from '@/configs/dummy/dummy-gallery';

import * as $ from 'jquery';
import {osGalleryModule} from '@/store/os/gallery';

@Component({
	mixins: [
	    CPatternMix,
	],
    components: {
    }
})
export default class CPageGalleryIndexStandard extends Vue {

    // public list: any = DummyGallery;
    public classBorder: any = [];


	//ACTIVE CATEGORY
	public activeId: string = '0';


	/*
	    PAGE TITLE
	*/
	public get pageId(): any {
		return themeMainModule.pageData.id;
	}


	/*
	    Category Dataを取得
	*/
	public get categories(): any {
		// return DummyArticleCategory;
        return osGalleryModule.content!['categories'];
	}

    public get list(): any {
        // return DummyArticleCategory;
        return osGalleryModule.content!['galleries'];
    }


}
