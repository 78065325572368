import {Component, Prop, Vue} from "vue-property-decorator";
import {themeMainModule} from '@/store/theme/theme-main';
import {themeStateModule} from '@/store/theme/theme-state';
import TxtUtil from "@/classes/view/txt-util";

@Component({
    components: {
    }
})
export default class CNavItem extends Vue {


    @Prop({default:() => {} })
    public d: any;


    public mounted() {
        console.log('CNavItem > ', this.d);
    }

 	/*
	 	CLASS名
 	*/
 	public get classId(): any {

     	if (!this.d) { return }

	 	/*
		 	SNSの場合、.-type--sns をつける
	 	*/
	 	if(this.d.type == 'sns') {
	 		return 'c-nav-' + this.d.id + ' -type--sns';
	 	}

	 	return 'c-nav-' + this.d.id;

 	}


	/*
		文字種別
	*/
	public get classCase(): any {
     	if (!this.slabel) { return }

		return TxtUtil.detectCase(this.slabel);
	}


 	/*
	 	CLICK
 	*/
 	public onClick() {

	 	/*
		 	MOBILE MENUだった場合に閉じる
	 	*/
	 	if(themeStateModule.navOpen){
		 	themeStateModule.updateNavOpen(false);
	 	}

 	}


 	/*
	 	ACTIVE
 	*/
 	public get classState(): any {

     	if (!this.d) { return }

	 	if (themeMainModule.pagePage == this.d.id) {
		 	return '-c-active';
	 	}

	 	return '';
 	}

 	public get sid(): any {
	 	return this.d.id;
 	}

 	public get slabel(): any {
	 	return this.d.label;
 	}

 	public get stype(): any {
	 	return this.d.type;
 	}

 	public get slink(): any {
	 	return this.d.link;
 	}

    // ある文言だったらそれに応じたアイコンをつける
    public get icon(): any {

        /*
            Reservation, 予約, book
        */
        const value = this.d.label.toUpperCase();

        if (value.match(/RESERVATION/) || value.match(/予約/) || value.match(/book/)) {
            return 'reservation';
        }

        return false;
    }

}

