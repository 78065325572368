import {IUploadimgState, uploadimgModule} from "@/store/uploadimg";
import {IImg} from "@/classes/domain/module/model/i-img";

export class ImageUploaderService {


    public async addImg(id: string, pos: number, img: string) {

        const current = uploadimgModule.uploadimgs.findByKey('id', id);

        console.log('id=%s, current > ', id, current);
        await uploadimgModule.updateUploadImg({
            id,
            imgs: current.imgs.map((_: IImg, idx: number) => {
                return (idx === pos) ? {
                    src: img,
                } : _;
            }),
        });
    }
}
