import {Component, Prop, Vue} from '@/node_modules/nuxt-property-decorator';
import {Notice} from '@/configs/notice';
import {noticeModule} from "@/store/notice";
import * as $ from 'jquery';

/**
 * <m-notice
 *    n="Notice Id"
 * >
 */
@Component
export default class MNotice extends Vue {

    @Prop({default: ''})
    public n: string;

    private notice: any;

    public mounted() {
        this.notice = Notice.findByKey('id', this.n );
    }

    public onClick() {
        //console.log('click m-notice');
        noticeModule.updateCurrent(this.notice);
    }
}
