import {IImg} from "@/classes/domain/module/model/i-img";
import VarUtil, {$v} from "@/classes/utils/var-util";

export class MUplodimgUtil {


    public static stateMenuTriming(imgType: string): boolean {
        return (imgType === 'img');
    }

    public static stateMenuSort(limit: number): boolean {
        return $v.num(limit) > 1;
    }

    public static stateMenuDelete(img: string): boolean {
        return !$v.isEmpty(img);
    }

    //
    public static src(src: string | IImg): string {

        if (typeof (src) === 'string') {
            return src as string;
        } else {
            return VarUtil.path(src, 'src');
        }
    }
}
