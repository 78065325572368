











	// @ts-ignore
    import _ from './CTxtlinkList.ts'; export default _;
