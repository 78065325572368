










































	
import {Component, Vue} from "nuxt-property-decorator";

@Component({
	layout: 'note',
    components: {
    }
})
export default class MMailAuth extends Vue {

    
}

