

















	// @ts-ignore
    import _ from './MSecondaryDomainIndex.ts'; export default _;
