






























































	// @ts-ignore
    import _ from './AccountDomainLeaveOtherService.ts'; export default _;
