import {Component, Watch, Prop, Vue} from "vue-property-decorator";
import VarUtil from "@/classes/utils/var-util";
import {themeMainModule} from '@/store/theme/theme-main';
import {editModule} from '@/store/edit';
import {Edit} from '@/configs/edit';
import {DummyPageHome} from '@/configs/dummy/dummy-page-home';
// import CEditMix from '@/classes/theme/mixin/c-edit-mix';

import * as $ from 'jquery';

@Component({
//     mixins: [CEditMix]
})
export default class CEdit extends Vue {

    @Prop({default: ''})
    public eid: string;

    @Prop({default: 'div'})
    public tag: string;

    private edit: any;
    private list: any;
    private listType: string;
    private isFixed: boolean = false;
    private mydata: any = {};


    public created() {
        if (themeMainModule.manager) {
            this.init();
        }
    }

    public mounted() {

		if(this.edit){

            // Components
            if (this.edit.components.length > 1) {
                this.list = this.edit.components;
                this.listType = 'components';

                // Is CBlock
                if (this.edit.type == 'block') {

                    // 自分のBlock Componentを取得
            		let d: any = DummyPageHome.blockData.findByKey('id', this.edit.id);
            		if(!d){
            			return
            		}

            		this.mydata = d;
                }

            // Layout
            } else if(this.edit.type == 'layout') {
                this.list = themeMainModule.brand.layouts;
                this.listType = 'layouts';

            }

        }
    }

    public mouseenter() {

        if (themeMainModule.manager) {
    		if(!editModule.forceActive){
                this.updateCurrent();
    		}
        }
    }


    public mouseleave() {
        //console.log('CEdit mouseleave 1');
        if (themeMainModule.manager) {
    		if(this.edit && !editModule.forceActive){
                //console.log('CEdit mouseleave 2');
                setTimeout(
                    () => {
                        if(editModule.current) {
                            if(!editModule.lock && this.eid == editModule.current.eid){
                                //console.log(this.edit.eid + 'CEdit mouseleave 3');
                                editModule.clearCurrent();
                            }
                        }
                    }, 2);
    		}
        }
    }


    //Componentを変更した時
    @Watch('switchedComponent')
    public watchSwitchedComponent(value: string) {
        if (value && value == this.eid) {
            //console.log('watchSwitchedComponent');
            //this.init();
            setTimeout(
                () => {
                    this.updateCurrent();
                    editModule.clearSwitchedComponent();
                }, 2);
        }
    }

    public get switchedComponent(): any {
        return editModule.switchedComponent;
    }


    // Init Data
    public init() {
        this.edit = Edit.findByKey('eid', this.eid);
    }


    // Update Current
    public updateCurrent() {

        if (this.edit) {

            const $el = $(this.$slots!['default']![0]!['elm'] as HTMLElement);

            if ($el.length > 0) {
                const w = $el.outerWidth();
                let h: number = $el.outerHeight();

                //高さが取れなかった場合、子要素を確かめる
                let i: number = 0;
                if (h == 0) {
                    while (i < 4) {
                        h = $el.children().outerHeight();
                        if (h > 0) {
                            break;
                        }
                        i++;
                    }
                }

                //Is Fixed Element
                if (
                    $el.closest('.c-sidecover-frame').css('position') == 'fixed' ||
                    $el.closest('.c-sidecover-frame').css('position') == 'sticky' ||
                    $el.closest('.c-info-navigation-frame').css('position') == 'fixed' ||
                    $el.closest('.c-info-navigation-frame').css('position') == 'sticky' ||
                    $el.closest('.c-nav-frame').css('position') == 'fixed' ||
                    $el.closest('.c-nav-frame').css('position') == 'sticky'
                ) {
                    this.isFixed = true;
                } else {
                    this.isFixed = false;
                }

                const edit = VarUtil.export(this.edit,
                    {
                        el: $el
                    },
                    {
        				size: { w, h }
                    },
                    {
                        list: this.list
                    },
                    {
                        listType: this.listType
                    },
                    {
                        fixed: this.isFixed
                    },
                    {
                        mydata: this.mydata
                    }
                );

                //console.log('mov > ', edit);

                editModule.updateCurrent(edit);
            }
        }
    }



}



