import {Component, Vue} from 'nuxt-property-decorator';
import AVueComponent from '@/classes/page/a-vue-component';
import {appModule} from '@/store/app';
import {officialModule} from '@/store/official';

@Component({
    components: {
    }
})
export default class MSecondaryHelp extends AVueComponent {

    public open: boolean = false;

    public onOpen() {
        this.open = true;
    }

    public get isOpen(): any {

        if (this.deviceSize != 'mb') {
            return true;
        }

        return this.open;
    }

	public get deviceSize(): any {
    	return appModule.deviceSize;
	}
}
