















































	// @ts-ignore
    import _ from './MSecondaryPlanChange.ts'; export default _;
