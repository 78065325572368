import {Component, Watch, Prop, Vue} from "vue-property-decorator";
import {themeMainModule} from '@/store/theme/theme-main';
import ACBlock from '@/classes/theme/class/a-c-block';
import CBlockMix from '@/classes/theme/mixin/c-block-mix';

import {DummyStore} from '@/configs/dummy/dummy-store';

import * as $ from 'jquery';

@Component({
    mixins: [CBlockMix],
    components: {
    }
})
export default class CBlockImgtxt extends ACBlock {


    /*
        LAYOUT is NORMAL
    */
    public verticalImg: boolean = false;


    /*
        LAYOUT is ImgtxtAside
    */
    public maxImgHeight: number = 390;
    public imgTop: number = 4;
    public asideRatio: number = 0.6175;

    public styleImg: any = {};
    public $block: any;
    public $detail: any;
    public $detailTxt: any;
    public $detailImg: any;
    public gap: number = 70;
    public pt: number = 70;
    public styleDetailBlock: any = {};
    public detailTxtHeight: number = 0;



	public created() {

        /*
            DummeHomeのデータから指定Blockのデータを取得
            設定画面: manager/project/home/
        */
        this.init(this.k);

	}


    public mounted() {

        /*
            FOR LAYOUT IS ASIDE
        */
        this.$block = $(this.$refs.block!['$el'] as HTMLElement);
        this.$detail = $(this.$refs.detail);
        this.$detailTxt = $(this.$refs.detailTxt);

        if(this.isImg){
            this.$detailImg = $(this.$refs.detailImg!['$el'] as HTMLElement);
            this.maxImgHeight = parseInt( this.$detailImg.css('max-height') );
        }
        this.resize();
    }


    public mountedImgRatio() {
        /*
            LAYOUT IS NORMAL
        */
        if(this.layoutMode != 'aside'){

            //画像のRATIOがVERTICAL or SQUARE
            if(this.$refs.imgRatio['saspect'] != 'horizontal'){

                //BLOCKに　.-ratio--vertical 設定
                this.verticalImg = true;
            }
        }

    }


	/*
    	RESIZE
	*/
	@Watch('mainFrameResized')
	public watchMainFrameResized() {
		this.resize();
	}

    public get mainFrameResized(): any {
        return themeMainModule.mainFrameResized;
    }

    public resize() {

        /*
            Asideレイアウトの適用
        */
        if(this.layoutMode == 'aside'){

            if(themeMainModule.deviceSize != 'mb') {

                let direction: string = 'left';

                /*
                    画像がある
                */
        		if(this.blockData.img[0]) {

                    this.gap = parseInt( this.$detail.css('grid-column-gap') );
                    this.pt = parseInt( this.$detail.css('padding-top') );
                    this.detailTxtHeight = this.$detailTxt.outerHeight();

                    //まだエレメントをいれてなければ設定
                    if(!this.$detailImg){
                        this.$detailImg = $(this.$refs.detailImg!['$el'] as HTMLElement);
                        this.maxImgHeight = parseInt( this.$detailImg.css('max-height') );
                    }

                    setTimeout(
                        () => {

                            if(this.$detail){

                                // 1GRIDの横幅を取得
                                const left = (themeMainModule.mainFrameWidth - this.$detail.outerWidth() ) / 2;
                                const txt_w = this.$detailTxt.outerWidth();
                                const img_w = themeMainModule.mainFrameWidth - (left + this.gap + txt_w);

                                let img_h: number = Math.floor(img_w * this.asideRatio);
                                let detailBlockHeight = this.detailTxtHeight;

                                if(img_h > this.maxImgHeight){
                                    img_h = this.maxImgHeight;
                                }

                                console.log(`
                                    main-frame-w: ${themeMainModule.mainFrameWidth}
                                    left: ${left}
                                    img_w: ${img_w}
                                `);

                                if(this.isReverse){
                                    this.styleImg = {
                                        width: `${img_w}px`,
                                        height: `${img_h}px`,
                                        top: `${this.imgTop}px`,
                                        right: `-${left}px`
                                    }
                                }else{
                                    this.styleImg = {
                                        width: `${img_w}px`,
                                        height: `${img_h}px`,
                                        top: `${this.imgTop}px`,
                                        left: `-${left}px`
                                    }
                                }


                                if(this.detailTxtHeight < img_h){
                                    detailBlockHeight = img_h + this.imgTop;
                                }

                                this.styleDetailBlock = {
                                    height: `${detailBlockHeight}px`,
                                }
                            }

                        }, 50);
        		}
    		}
            else{

                this.styleImg = {}
                this.styleDetailBlock = {}

            }
        }
	}


    /*
        Get Layout Mode
        none, aside
    */
    public get layoutMode(): any {
        return themeMainModule.layout.imgtxt;
    }


    /*
        ASPECT
    */
    public get imgtxtAspect(): any {

        // PC, TABLET
        if(themeMainModule.deviceSize != 'mb') {
            if(this.layoutMode == 'aside'){
                return 's100';
            }
        }

		return 'auto';
    }


	/*
		COMPONENTの処理
	*/
	public get sClassDetailType(): any {

		if(this.blockData.img[0]) {
			return '';

		}else{
			return '-txt-only';

		}
		return '';
	}


	//Reverse
	public get isReverse(): any {

        let ary: any = [];

		//自分が何番目のimgtxtか調べる
		for( let i:number = 0 ; i < themeMainModule.homeBlockData.length ; i++ ) {

			if(themeMainModule.homeBlockData[i].component == 'CBlockImgtxt') {

    			//画像がある
    			if(themeMainModule.homeBlockData[i].img.length > 0){
        			ary.push(themeMainModule.homeBlockData[i].id)
    			}
			}
		}

		for( let i:number = 0 ; i < ary.length ; i++ ) {

			//自分だったら
			if(ary[i] == this.k) {

				//偶数だったらreverce
				if(( i % 2 ) != 0) {
					return true;
				}
			}
        }

		return false
	}


	public get img(): any {
		return this.blockData.img;
	}


	public get isLogoImg(): any {

		if(themeMainModule.project.logo.type == 'webfont') {
			return false;
		}

		return true;

	}


	/*
		STORE
	*/
	public get address1(): any {

		if(this.k == 'store') {
			if(themeMainModule.mainStoreAddress1) {
				return themeMainModule.mainStoreAddress1;
			}else{
				return DummyStore[0].address1;
			}
		}

		return
	}

	public get address2(): any {

		if(this.k == 'store') {
			if(themeMainModule.mainStoreAddress2) {
				return themeMainModule.mainStoreAddress2;
			}else{
				return DummyStore[0].address2;
			}
		}

		return
	}

	public get tel(): any {

		if(this.k == 'store') {
			if(themeMainModule.mainStoreTel) {
				return themeMainModule.mainStoreTel;
			}else{
				return DummyStore[0].tel;
			}
		}
		return
	}

	public get open(): any {

		if(this.k == 'store') {
			if(themeMainModule.mainStoreOpen) {
				return themeMainModule.mainStoreOpen;
			}else{
				return DummyStore[0].open;
			}
		}
		return
	}

	public get close(): any {

		if(this.k == 'store') {
			if(themeMainModule.mainStoreClose) {
				return themeMainModule.mainStoreClose;
			}else{
				return DummyStore[0].close;
			}
		}
		return
	}


	/*
		共通処理
	*/
	public get body(): any {
		return this.blockData.body;
	}

}

