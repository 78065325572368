import {Logos} from '@/configs/logos';
import VarUtil from "@/classes/utils/var-util";

declare var window: Window;

export default class WebfontLoader {


	/*

	Webfont Map
	データ構造

	デバイスごとに書体を追加し、各OSの必要な書体を読み込む

	public webfont_map: any = {

		mac: {
			typekit: typekit_id,
			monotype: MTIProjectId,
			google: []
		},

		ios: {
			typekit: typekit_id,
			monotype: MTIProjectId,
			google: []
		},

		windows: {
			typekit: typekit_id,
			monotype: MTIProjectId,
			google:[]
		},

		android: {
			typekit: typekit_id,
			monotype: MTIProjectId,
			google:[]
		}
	};
	*/
    public static load(loadGoogleFont: string = '') {

        //すべてのGoogle Fontを読み込み
        if (loadGoogleFont == 'all') {
            const urls = [];
            for (const logo of Logos) {
                urls.push(logo.mfamily);
                urls.push(logo.sfamily);
            }
            window!['google_font'] = urls;
        } else {
            //@Todo All以外のときのロード処理
            //IDで読み込み書体を指定
            const urls = [];
        	const font = VarUtil.find(Logos, 'id', loadGoogleFont );
        	console.log(font);
            //urls.push(font.mfamily);
            //urls.push(font.sfamily);
            //window!['google_font'] = urls;
        }
    }


    public static loadMonotype(MTIProjectId: string = '4f0411d0-bfcd-4146-8249-ab3f4cc23342') {
        let mtiTracking: any = document.createElement('script');
        mtiTracking.type='text/javascript';
        mtiTracking.async='true';
        mtiTracking.src='/common/css/themes/mtiFontTrackingCode.js';
        (document.getElementsByTagName('head')[0]||document.getElementsByTagName('body')[0]).appendChild( mtiTracking );
    }




}










