import {Component, Watch, Prop, Vue} from "vue-property-decorator";
import {themeMainModule} from '@/store/theme/theme-main';

@Component({
    components: {
    }
})
export default class CSidenavFrame extends Vue {
	
    @Prop({default: () => {} })
	public d: any;
	
    @Prop({default: '0'})
	public active: string;
	
}



