import VarUtil, {$v} from '@/classes/utils/var-util';

export default class Locale {

    public static src: any;

    /**
     *
     * @param {string} contextKey
     */
    public static repo(contextKey: string) {
        if (typeof (Locale.src) == 'function') {
            return VarUtil.path(Locale.src(), contextKey);
        } else {
            return VarUtil.path(Locale.src, contextKey);
        }
    }

    public static _(path: string, opt: any = {}): string {

        let pp = path.replace(/\.([^\.]+)$/, '');
        let k = RegExp.$1;

        pp = pp == '' ? '/' : pp;
        let tree = Locale.repo(pp);
        const def = $v.isString(opt) ? opt : $v.path(opt, 'def');

        console.log('tree of', tree);

        if (!$v.isArray(tree)) {
            return def;
        }

        let txt = $v.tap((tree as any[]).findByKey('key', k), (t: any) => {
            return $v.p(t, 'label', def);
        });

        if (opt['param']) {
            const r = /(%[^%]+%)/g;
            let m;
            while ((m = r.exec(txt)) != null) {
                const rp = m[1].substr(1, m[1].length - 2);
                const rv = opt['param']![rp] || '';

                txt = txt.replace(new RegExp(m[1]), rv);
            }
        }

        return txt;
    }
}

export const _ = Locale._;
