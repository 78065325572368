import {Component, Prop, Vue} from 'vue-property-decorator';
import CBlockMix from '@/classes/theme/mixin/c-block-mix';
import ViewUtil from '@/classes/view/view-util';
import {themeMainModule} from '@/store/theme/theme-main';
import ACBlock from '@/classes/theme/class/a-c-block';

import {DummyGallery} from '@/configs/dummy/dummy-gallery';
import {DummyStaff} from '@/configs/dummy/dummy-staff';
import {DummyStore} from '@/configs/dummy/dummy-store';
import {DummyInstagram} from '@/configs/dummy/dummy-instagram';

@Component({
    mixins: [CBlockMix],
    components: {
    }
})
export default class CBlockList extends ACBlock {

    // LIST
    @Prop({default: () => {}})
	public list: any;

    // COLUMN
    @Prop({default: ''})
	public column: string;


    //アイテム数
    public length: number = 0;


    public created() {

        /*
            DummeHomeのデータから指定Blockのデータを取得
            設定画面: manager/project/home/
        */
        this.init(this.k);

    }


    /*

        Component処理

    */

    /*
        データを取得
    */
    public get olist(): any {

        if(!this.list){

            if (this.blockData.data == 'gallery') {

                this.length = DummyGallery.length;
                return DummyGallery.slice(0, 6);

            } else if (this.blockData.data == 'staff') {

                this.length = DummyStaff.length;
                return DummyStaff.slice(0, 12);

            } else if (this.blockData.data == 'store') {

                this.length = DummyStore.length;
                return DummyStore.slice(0, 6);

            } else if (this.blockData.data == 'instagram') {

                this.length = DummyInstagram.length;
                return DummyInstagram.slice(0, 6);

            }

        }

        this.length = this.list.length;
        return this.list;

    }


    /*
        IMG HOVER
    */

    //Hoverしたときの表現を特殊にするかどうか
    public get isImghover(): any {
        return themeMainModule.brand.decor[this.k].imgHover;
    }

    // カラム数
//     public get sClassColumn(): any {
//
//         let c: string = '';
//
//         // Propsで設定があった場合
//         if(this.column){
//             c = this.column;
//         }else if(this.blockData.column){
//             c = this.blockData.column;
//         }else{
//             c = 'r';
//         }
//
//         /*
//             中途半端な数になる場合、自動できれいにする
//         */
//         if(themeMainModule.deviceSize != 'mb'){
//
//             if(c == 'r'){
//                 if(this.length % 4 == 1){
//                     c = 'm';
//                 }
//             }else if(c == 'm'){
//                 if(this.length % 3 == 1){
//                     c = 'r';
//                 }
//             }
//
//         }else{
//
//             if(c == 'r'){
//                 if(this.length % 3 == 1){
//                     c = 'm';
//                 }
//             }
//
//         }
//
//         return '-column-' + c;
//     }

    /*
        アイテム数
    */
    public get sClassLength(): any {
        return '-length' + String(this.length);
    }




}

