export const DummyCssvars = {

    baseId: 'morning',
    link: '26,26,26',
    key: '26,26,26',

    mvTxtBase: 'auto',
    mvTxt: '255,0,0',
    mvBg: '254,254,254',

    sidecoverId: 'gold',
    sidecoverTxtMode: 'auto',
    sidecoverTxt: '26,26,26',
    sidecoverBg: '227,237,0',

    mvOpacity: 0.2,

    navLogoScale: 1,
    mvContentLogoScale: 1,
    mvContentImgScale: 1,
    mvContentTxtScale: 1,
    loaderStrokeWidth: 1.5,
    borderWidth: 1,
    ratioHorizontal: 70.7,
    ratioVertical: 130,

};

