











	// @ts-ignore
    import _ from './AccountDomainsDetail.ts'; export default _;
