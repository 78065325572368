
















// @ts-ignore
import _ from "./SLoader.ts";

export default _;
