import {Component, Prop, Vue} from '@/node_modules/nuxt-property-decorator';
import {DummyMNews} from '@/configs/dummy/dummy-m-news';

@Component({
    components: {
    }
})
export default class MListNews extends Vue {
   	
    @Prop({default: () => (DummyMNews)})
    public articles: any;
	
    ////////// POPUP LAYER 共通処理 /////////////////////////////////
	public onClose(to: string=''): any {
		if(to != ''){
			this.$router.push(to);
		}
        this.$emit('close');
	}	
	
}