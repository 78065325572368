import {Component, Prop, Vue} from '@/node_modules/nuxt-property-decorator';
import VarUtil from "@/classes/utils/var-util";
import {Tips} from '@/configs/tips';
import {tipsModule} from "@/store/tips";
import * as $ from 'jquery';

/**
 * <m-tips
 *    t="TIPS ID"
 * >
 */
@Component
export default class MTips extends Vue {

    @Prop({default: ''})
    public t: string;

    @Prop({default: true})
    public run: boolean;

    @Prop({default: 'div'})
    public tag: string;

	//つかうかどうか
    @Prop({default: true})
    public use: boolean;

    private tips: any;

    public mounted() {
	    //console.log(this.t);
        this.tips = Tips.findByKey('id', this.t);
        if( !this.tips ){
            console.log('MTips Error: No Element');
            return
        }
    }

    public onMouseOver() {

		if(this.tips && this.run && !tipsModule.lock && this.use){

            const $t = $(this.$slots!['default']![0]!['elm'] as HTMLElement);
            if(!$t) {
                console.error('MTips() > element error');
                return;
            }

            const {top, left} = $t.offset();
            const w = $t.outerWidth();
            const h = $t.outerHeight();

            const tips = VarUtil.export(this.tips,
            {
                pos: { top, left }
            },
            {
				size: { w, h }
            }
            );

            //console.log('mov > ', tips);

            tipsModule.updateCurrent(tips);
		}
    }

    public onMouseOut() {

		if(this.tips && this.run && this.use){
            //console.log('mou > ', this.t, this.tips);
            tipsModule.clearCurrent();
		}
    }

    public onClick() {
		if(this.tips && this.run && this.use){
            tipsModule.clearCurrent();
			tipsModule.updateLock(true);
			//一定時間後にロック解除
	        setTimeout(
	            () => {
					tipsModule.updateLock(false);
	            }, 600);

		}
        this.$emit('click');
    }

}
