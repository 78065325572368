import {$v} from "@/classes/utils/var-util";

export class MediaNavigator {

    private readonly _model: any;

    constructor(model: any) {
        this._model = model;
    }

    public get medias(): any[] {
        return $v.p(this._model, 'medias') || [];
    }

    public get hasMedias(): boolean {
        return this.medias.length > 0;
    }

    public media(name: string): any {
        return this.medias.findByKey('mkey', name);
    }

    public hasMedia(name: string): boolean {
        return !!this.media(name);
    }

    public file(name: string): string {
        const media = this.media(name);
        if (!media) {
            return '';
        }

        return $v.p(media, 'file');
    }

    public url(name: string): string {
        const file = this.file(name);
        return !!file ? $v.p(file, 'url') : '';
    }
}
