var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"resize",rawName:"v-resize:debounce",value:(_vm.resize),expression:"resize",arg:"debounce"}],staticClass:"m-main-frame -d--full ja -teaser",class:[
        	_vm.classPagetype,
        	'-m--' + _vm.deviceSize,
            _vm.pathName,
            {'-display': _vm.display}
        ]},[_c('m-loader-frame'),_vm._v(" "),_c('div',{key:"block2",staticClass:"m-base-frame -loading",class:[
                {'-nav-open': _vm.isNavOpen},
                {'-loaded': !_vm.loadingMain}
            ]},[_c('m-notice-frame'),_vm._v(" "),_c('m-popup-frame'),_vm._v(" "),_c('te-nav-frame',{attrs:{"visible":true}}),_vm._v(" "),_c('div',{staticClass:"mo-frame"},[_c('nuxt'),_vm._v(" "),_c('te-global-footer'),_vm._v(" "),_c('te-official-nav')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }