












































































// @ts-ignore
import _ from './MSecondaryWeb.ts';

export default _;
