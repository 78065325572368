import {Component, Watch, Prop, Vue} from '@/node_modules/nuxt-property-decorator';
import * as $ from 'jquery';
import {noticeModule} from "@/store/notice";
import {Notice} from '@/configs/notice';

@Component
export default class MNoticeFrame extends Vue {
    
    public get current(): any {
        return noticeModule.current;
    }

    public get hasCurrent(): boolean {
        return !!this.current;
    }

    public get txt() {
        return this.current.body;
    }
    
    public clearSelf(){
        //console.log('clearNotice');
        noticeModule.clearCurrent();
    }
    
}
