import {Component, Vue} from 'nuxt-property-decorator';
import {themeMainModule} from '@/store/theme/theme-main';
import CMix from '@/classes/theme/mixin/c-mix';

import CPageIndexIndexStandard from '@/components/theme/page/index/index/CPageIndexIndexStandard.vue';
import {osIndexModule} from '@/store/os';

@Component({
    mixins: [CMix],
    components: {
        CPageIndexIndexStandard,
    }
})
export default class CIndex extends Vue {

    ///////// 全ページ共通 ////////////////////////////////////////////
    public page: any = {
        type: 'index',
        page: 'index',
        depth: 'index',
    };


    // ヘッダタグの構成要素
    // public head() {
    //   return {
    //     title: this.title
    //   };
    // }
    public async mounted() {

        console.log('CIndex > mounted() > ', osIndexModule.content);

        //PAGE情報をSTOREにセット
        this!['updateMyPage'](this.page, themeMainModule);
        this!['init']();
    }
}
