































































	// @ts-ignore
    import _ from './CPageContactIndexStandard.ts'; export default _;
