import {Component, Prop, Vue, Watch} from '@/node_modules/nuxt-property-decorator';
import {UiTyping} from '@/classes/view/ui/ui-typing';

/**
 * <typing
 *  go="true=動く/false=停止"
 *  txt="文字 または [文字, 文字, 文字]"
 *  type="1文字のタイプスピード(90)"
 *  wait="すべて文字が出て空になるまでの時間(3000)"
 *  blank="ループ時に空になった場合の次のタイピングが始まるまでの待機時間(1000)"
 *  nl2br="改行を<br>にする"
 *  loop="ループする"
 * ></tying>
 */
@Component
export default class Typing extends Vue {

    // go="true=動く/false=停止"
    @Prop({default: true})
    public go: boolean;

    // txt="文字 または [文字, 文字, 文字]"
    @Prop({default: ''})
    public txt: string | string[];

    // type="1文字のタイプスピード(90)"
    @Prop({default: 90})
    public type: string;

    // wait="すべて文字が出て空になるまでの時間(3000)"
    @Prop({default: 3000})
    public wait: string;

    // blank="ループ時に空になった場合の次のタイピングが始まるまでの待機時間(1000)"
    @Prop({default: 1000})
    public blank: string;

    // nl2br="改行を<br>にする"
    @Prop({default: true})
    public nl2br: boolean;

    // loop="ループする"
    @Prop({default: false})
    public loop: boolean;

    public ready: boolean = false;
    public going: boolean = false;
    public typing: UiTyping;

    @Watch('go')
    public watchGo() {
        this.going = this.go;
    }

    @Watch('going')
    public watchGoing() {
        if (this.going) {
            this.typing.start();
        } else {
            this.typing.stop();
        }
    }

    public typingCurrent(): string {
        return this.typing.current;
    }

    public mounted() {
        this.typing = new UiTyping({
            content: Array.isArray(this.txt) ? this.txt : [this.txt],
            speed: {
                type: this.type,
                wait: this.wait,
                blank: this.blank,
            },
            onUpdateCurrent: () => {
                this.$forceUpdate();
            },
            nl2br: this.nl2br,
            loop: this.loop
        });
        this.ready = true;
        this.going = this.go;
    }

    public destroyed() {
        this.going = false;
    }
}
