import {Component, Watch, Prop, Vue} from "vue-property-decorator";
import {themeMainModule} from '@/store/theme/theme-main';
import ACBlock from '@/classes/theme/class/a-c-block';
import CBlockMix from '@/classes/theme/mixin/c-block-mix';

import * as $ from 'jquery';

@Component({
    mixins: [CBlockMix],
    components: {
    }
})
export default class CBlockMap extends ACBlock {


    @Prop({default: '' })
	public src: string;

	public isLoading: boolean = true;

    /*
        SCROLLでひっかからないようにする
    */
    public style: any = {
        'pointer-events': 'none'
    };


	public created() {

        /*
            DummeHomeのデータから指定Blockのデータを取得
            設定画面: manager/project/home/
        */
        this.init(this.k);

	}

    /*
        EVENTを有効
    */
    public click() {
        this.style = {
            'pointer-events': 'auto'
        }
    }

    /*
        SCROLLでひっかからないようにする
    */
    public mouseleave() {
        this.style = {
            'pointer-events': 'none'
        }
    }


    /*
	    LOADED
    */
    public loaded() {
	    this.isLoading = false;
    }

}



