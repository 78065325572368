import {Action, getModule, Module, Mutation, VuexModule} from "@/node_modules/vuex-module-decorators";
import store from "@/classes/core/store";
import {IAppVar} from "@/classes/core/app-var";

// export interface IAppVar {
//     id: string;
//     value: any;
//     opt?: any;
// }

export interface IAppVarModule {
    vars: IAppVar[];
}

const PK = 'id';

@Module({dynamic: true, store, name: 'appVar', namespaced: true})
class Store extends VuexModule implements IAppVarModule {
    private _vars: IAppVar[] = [];

    // Getters //////////////////////////
    public get vars(): IAppVar[] {
        return this._vars;
    }

    // Mutations ////////////////////////
    @Mutation
    public updateVar(value: IAppVar) {
        this._vars = this._vars.replaceByKey(PK, value).array;
    }

    @Mutation
    public remove(value: IAppVar) {
        this._vars = this._vars.rejectByKey(PK, value);
    }
}

export const appVarModule = getModule(Store);

const M = appVarModule;
