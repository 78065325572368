


































	// @ts-ignore
    import _ from './CMenuMb.ts'; export default _;
