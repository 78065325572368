import {Component, Watch} from '@/node_modules/nuxt-property-decorator';
import APopup from "@/classes/page/a-popup";

import MPopupMBody from '@/components/popup/module/MPopupMBody.vue';
import {$v} from "@/classes/utils/var-util";
import {IPopup, popupModule} from "@/store/popup";
import {cmdModule, ICmd} from "@/store/cmd";
import {SystemConfig} from "@/configs/system";
import {Prop} from "nuxt-property-decorator";
import {ConfigManagerAction} from "@/configs/app/manager-action";

const HOLIDAY_TMPL = {
    id: 1,
    cycle: 0,
    w1: 0,
    w2: 0,
    w3: 0,
    w4: 0,
    w5: 0,
    w6: 0,
    w0: 0,
    w7: 0,  //祝
};

@Component({
    components: {
        MPopupMBody,
    }
})
export default class MPopupStoreHoliday extends APopup {

    @Prop({default: '@'})
    public mid: string;

    // public devlist: number = 1;
    public holidays: any[] = [
        {...HOLIDAY_TMPL},
    ];

    public state = {
        config: {
            editId: 'popupStoreHoliday',
            popup: {
                editCancel: 'holiday.editCancel',
            },
            cmd: [
                ''
            ],
            weeks: {...SystemConfig.weeks},
        },
        view: {
            upcnt: 0,
        },
        input: {
            holidays: [
                {...HOLIDAY_TMPL},
            ]
        }
    };

    // Watch
    @Watch('cmds')
    public async watchCmds(now: ICmd) {

        if (!now) {
            return;
        }

        console.log('MPopupStoreHoliday.cmds() > ', now);

        switch (now.cmd) {
            case ConfigManagerAction.Popup.Confirm.Ok:
                console.log('- ok');
                popupModule.closePopup(this.state.config.popup.editCancel);
                this.$nextTick(() => {
                    this.onClose();
                });
                break;
            case ConfigManagerAction.Popup.Confirm.Cancel:
                console.log('- cancel');
                popupModule.closePopup(this.state.config.popup.editCancel);
                break;
            default:
                console.log('- default');
                break;
        }

        cmdModule.trashQueue(now.id);
    }

    // Mounted /////////////////////////////////////
    public async mounted() {
        await this.initValues();
    }

    public async initValues() {
        this.holidays = $v.p(this.option, 'holidays', []);
    }

    // Computed ////////////////////////////////////
    public get popup(): IPopup {
        return popupModule.popups.findByKey('id', this.mid);
    }

    public get option(): any {
        return $v.p(this.popup, 'option', {});
    }

    public get hasAdd(): boolean {
        return this.holidays.length < 4;
    }

    public get weeks(): any {
        return this.state.config.weeks;
    }

    public get nextId(): number {

        let maxid = 0;
        this.holidays.map((_: any) => {
            if (_.id > maxid) {
                maxid = _.id;
            }
        });

        return maxid + 1;
    }

    public get upcnt(): number {
        return this.state.view.upcnt;
    }

    public get cmds(): ICmd {
        return cmdModule.queues.filter((_: ICmd) => {
            return ($v.p(_, 'request.target') === this.state.config.popup.editCancel);
        }).first() as ICmd | null;
    }

    // Methods ////////////////////////////////////
    public addHoliday() {
        this.holidays = this.holidays.from({
            ...HOLIDAY_TMPL,
            ...{id: this.nextId},
        });

        ++this.state.view.upcnt;
    }

    public removeHoliday(id: number) {
        this.holidays = this.holidays.rejectByKey('id', id);
    }

    public updateHoliday(upd: any, cnt: boolean = true) {
        this.holidays = this.holidays.replaceByKey('id', upd).array;
        if (cnt) {
            ++this.state.view.upcnt;
        }

        console.log('MPopupStoreHoliday > updateHolidays > after', this.upcnt, this.holidays);
    }

    public isStateInactive(h: any, w: string): boolean {
        return $v.bool($v.p(h, w));
    }

    // Events ///////////////////////////////////////
    public async onClickToggle(h: any, w: string) {
        this.updateHoliday({
            ...h,
            ...{
                [w]: !!$v.num($v.p(h, w)) ? 0 : 1,
            },
        });
    }

    public async onInputCycle(h: any, e: any) {
        console.log('onInputCycle > e > ', h, e);
        this.updateHoliday({
            ...h,
            ...{
                cycle: e.value,
            },
        });
    }

    public async onClickAdd() {
        this.addHoliday();
    }

    public async onClickRemove(id: number) {
        this.removeHoliday(id);
    }

    public async onClickCancel() {
        if (this.upcnt > 0) {
            await popupModule.open({
                id: this.state.config.popup.editCancel,
                type: 'confirm',
                option: {
                    title: '定休日編集のキャンセル',
                    body: '編集した内容は破棄されます',
                },
            });
        } else {
            this.onClose();
        }
    }

    public async onClickSave() {
        if (this.upcnt > 0) {
            await cmdModule.registCmd({
                cmd: ConfigManagerAction.Popup.StoreHoliday.Save,
                request: {
                    holidays: this.holidays,
                },
            });
        }
        this.onClose();
    }

    ////////// POPUP LAYER 共通処理 /////////////////////////////////
    public onClose(): any {
        this.$emit('close');
    }
}
