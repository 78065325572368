import {Official} from "@/classes/app/api/prod/official";
import {OwnerAuth} from "@/classes/app/api/prod/owner-auth";
import System from "@/classes/app/api/manager/system";
import {ExActivation} from "@/classes/app/api/prod/ex-activation";

export default class ProdApi {
    public static OwnerAuth: OwnerAuth = new OwnerAuth();
    public static System: System = new System();
    public static Official: Official = new Official();
    public static ExActivation: ExActivation = new ExActivation();

}
