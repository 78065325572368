import {Component, Watch, Prop, Vue} from "vue-property-decorator";
import {themeMainModule} from '@/store/theme/theme-main';

@Component({
    components: {
    }
})
export default class CTab extends Vue {


	public static self: CTab;

	//DATA
	@Prop({default: () => {} })
	public d: any;

    // 初期アクティブ
    public activeItemId: string = '0';

    public loading: boolean = true;


    public mounted() {

		CTab.self = this;
		setTimeout(
		    () => {
				this.init();
                this.loading = false;
		    }, 300);
    }

    public init() {
		this!['slide'].init();
    }


    //Swiper
    public swiperOption: any = {

        slidesPerView: 'auto',
        centeredSlides: true,
        spaceBetween: 0,
        navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
        },
        on: {
	        init: function () {

	            if( !CTab.self ){
		            return;
	            }

	        },
            slideChange: function () {

	            if( !CTab.self ){
		            return;
	            }
            },
        }
    };


    /*
        CLICK
    */
    public onClick(item: any, i: number, ref: string) {
        this.activeItemId = String(i);
    }


}

