import {Component, Prop, Vue} from "vue-property-decorator";
import {themeMainModule} from '@/store/theme/theme-main';
import TxtUtil from "@/classes/view/txt-util";

import {DummyProject} from '@/configs/dummy/dummy-project';
import {DummyNavCategory} from '@/configs/dummy/dummy-nav-category';

import CSelect from '@/components/theme/module/select/CSelect.vue';

@Component({
    components: {
	    CSelect,
    }
})
export default class CNavBlockSns extends Vue {


    @Prop({default:() => {} })
    public d: any;


	public isOpen: boolean = false;


 	/*
	 	CLASS名
 	*/
 	public classById(value: any): any {

	 	/*
		 	SNSの場合、.-type--sns をつける
	 	*/
	 	if(value.type == 'sns') {
	 		return 'c-nav-' + value.id + ' -type--sns';
	 	}

	 	return 'c-nav-' + value.id;

 	}

 	public get snsTitle(): any {
        return DummyNavCategory[3].label;
 	}

	/*
		文字種別
	*/
	public get classCase(): any {
		return TxtUtil.detectCase(this.snsTitle);
	}


 	public onToggle() {
	 	this.isOpen = !this.isOpen;
 	}


}

