import {Component} from "nuxt-property-decorator";
import RoutingUtil from "@/classes/view/routing-util";
import AAccount from '@/classes/page/a-account';
import {accountModule} from '@/store/account';
import {appModule} from '@/store/app';

import MSecondaryDomainSubscribe from '@/components/account/procedure/secondary/domain/MSecondaryDomainSubscribe.vue';
import MInputCentered from '@/components/module/MInputCentered.vue';

@Component({
    components: {
        MSecondaryDomainSubscribe,
	    MInputCentered
    }
})
export default class ProcedureDomainSearch extends AAccount {

	public isSearch: boolean = false;

	public mypagetype: string = 'procedure';
	public mydivider: string = 'half';

    // SSRでサーバーサイドの処理から変数をセット
    public async asyncData(context: any): Promise<any> {
        return {
            test: 128,
            title: 'Indexa',
        }
    }

    // ヘッダタグの構成要素
    public head() {
        return {
            //title: this.title
        }
    }

    ///////// 全ページ共通 ////////////////////////////////////////////
    public transition(to: any, from: any) {
		return RoutingUtil.transition(to, from);
	}

    public created(){

		///////// 全ページ共通 ////////////////////////////////////////////
		this.setPagetype( appModule, this.mypagetype, this.mydivider );

    }


    public inputSearch(value: any) {

	    if(value != ''){
	    	this.isSearch = true;
	    }else{
	    	this.isSearch = false;
	    }

    }

    public get osearch(): any {
	    return this.isSearch;
    }

}
