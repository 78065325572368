/*

	YH: Data Spread Sheet is Here
	https://docs.google.com/spreadsheets/d/1m8wavYfVRS0UKfK29YpBoYfp1lv33osynHnrhOLLpnc/edit?usp=sharing

    動的なデータは
    //動的　と記載

*/
export const Brands = [

/////////////////////////////////////////////////////////////////
{
	brandid: 'tiny-garamond',
	name: 'Tiny Garamond',
	comment: 'Amiri & Yu Serif',

	//ベース書体の指定
	webfont: {
		monotype: '',
		google: {
			en: 'Amiri:400,400i,600,700',
			ja: 'Sawarabi Mincho',
		},
		load_with_mac: false,
	},

	/*
    	デザイン選択時に使用する初期値
	*/
	select: {
		logoclass: 'crimpsonTextSBUpper', //動的 初回デザイン選択時に DummyProject.logo.webfont に代入される
	},

	decor: {
		concept: {
			imgHover: false,
			numberFormat: '',
		},
		gallery: {
			imgHover: false,
			numberFormat: '',
		},
		staff: {
			imgHover: false,
			numberFormat: '',
		},
		store: {
			imgHover: true,
			numberFormat: 'words',
		},
		blogNews: {
			imgHover: false,
			numberFormat: '',
		},
		instagram: {
			imgHover: true,
			numberFormat: 'words',
		},
	},

	layouts: [
		{
            no: '1',
			id: 'classic',
			name: 'Classic book breathing',
			layout: 'queen',
			imgtxt: '',
			logo: {
    			pc:{
    				head: {
        				scaletype: 'queen',
        				size: 49.4,
    				},
    				mv: {
        				scaletype: 'mv-logo',
        				size: 219.9,
                    },
    				foot: {
        				scaletype: 'normal',
        				size: 14.3,
                    }
    			},
    			mb:{
    				head: {
        				scaletype: 'normal',
        				size: 12.6,
                    },
    				mv: {
        				scaletype: 'normal',
        				size: 202.6,
                    },
    				foot: {
        				scaletype: 'normal',
        				size: 34.3,
                    }
    			}
			},
			mv: {
    			address: 'user-setting',
				addressTxtBase: 'bg',
				height: 'calc',
			},
			navigation: {
				sns: 'icon',
				pos: 'top',
			},
		},
		{
    		no: '2',
			id: 'drawn',
			name: 'Drawn into the world',
			layout: 'center',
			imgtxt: '',
			logo: {
    			pc:{
    				head: {
        				scaletype: 'normal',
        				size: 39.3,
                    },
    				mv: {
        				scaletype: 'mv-logo',
        				size: 219.9,
                    },
    				foot: {
        				scaletype: 'normal',
        				size: 14.3,
                    }
    			},
    			mb:{
    				head: {
        				scaletype: 'normal',
        				size: 12.6,
                    },
    				mv: {
        				scaletype: 'normal',
        				size: 202.6,
                    },
    				foot: {
        				scaletype: 'normal',
        				size: 34.3,
                    }
    			}
			},
			mv: {
    			address: 'user-setting',
				addressTxtBase: 'bg',
				height: 'css',
			},
			navigation: {
				sns: 'select',
				pos: 'top',
			},
		},
		{
			no: '3',
			id: 'moving',
			name: 'Always moving',
			layout: 'right',
			imgtxt: 'aside',
			logo: {
    			pc:{
    				head: {
        				scaletype: 'normal',
        				size: 32.0,
                    },
    				mv: {
        				scaletype: 'mv-logo',
        				size: 219.9,
                    },
    				foot: {
        				scaletype: 'normal',
        				size: 14.3,
                    }
    			},
    			mb:{
    				head: {
        				scaletype: 'normal',
        				size: 12.6,
                    },
    				mv: {
        				scaletype: 'normal',
        				size: 202.6,
                    },
    				foot: {
        				scaletype: 'normal',
        				size: 34.3,
                    }
    			}
			},
			mv: {
    			address: 'force-true',
				addressTxtBase: 'txt',
				height: 'calc',
			},
			navigation: {
				sns: 'select',
				pos: 'top',
			},
		},
		{
			no: '4',
			id: 'spring',
			name: 'Springs from your feet',
			layout: 'center',
			imgtxt: '',
			logo: {
    			pc:{
    				head: {
        				scaletype: 'normal',
        				size: 16.8,
                    },
    				mv: {
        				scaletype: 'mv-logo',
        				size: 219.9,
                    },
    				foot: {
        				scaletype: 'normal',
        				size: 14.3,
                    },
    			},
    			mb:{
    				head: {
        				scaletype: 'normal',
        				size: 12.6,
                    },
    				mv: {
        				scaletype: 'normal',
        				size: 202.6,
                    },
    				foot: {
        				scaletype: 'normal',
        				size: 34.3,
                    }
    			}
			},
			mv: {
    			/*
        			MVの住所の表示をどうするか
        			user-setting: ユーザーの設定優先
        			force-true: 強制的に表示
        			force-false: 強制的に非表示
    			*/
    			address: 'user-setting',
				addressTxtBase: 'bg',
				height: '100',
			},
			navigation: {
				sns: 'select',
				pos: 'bottom',
			},
		},
		{
    		no: '5',
			id: 'small',
			name: 'Keep it small',
			layout: 'center',
			imgtxt: '',
			logo: {
    			pc:{
    				head: {
        				scaletype: 'normal',
        				size: 13.6,
        			},
    				mv: {
        				scaletype: 'mv-logo',
        				size: 200,
                    },
    				foot: {
        				scaletype: 'normal',
        				size: 14.3,
        			}
    			},
    			mb:{
    				head: {
        				scaletype: 'normal',
        				size: 12.6,
                    },
    				mv: {
        				scaletype: 'normal',
        				size: 202.6,
                    },
    				foot: {
        				scaletype: 'normal',
        				size: 34.3,
                    }
    			}
			},
			mv: {
    			address: 'user-setting',
				addressTxtBase: 'bg',
				height: 'css',
			},
			navigation: {
				sns: 'select',
				pos: 'top',
			},
		},
	],
},

/////////////////////////////////////////////////////////////////
{
	brandid: 'morning-window',
	name: 'Morning Window',
	comment: 'Helvetica Now & Tazugane Gothic',

	//デザインのベース書体の指定
	webfont: {
		monotype: '',
		load_with_mac: false,
	},

	/*
    	デザイン選択時に使用する初期値
    	ロゴが選択されていない状態のときのみ有効
	*/
	select: {
		logoclass: 'interMedium', //動的 初回デザイン選択時に DummyProject.logo.webfont に代入される
	},

	decor: {
		concept: {
			imgHover: false,
			numberFormat: '',
		},
		gallery: {
			imgHover: false,
			numberFormat: '',
		},
		staff: {
			imgHover: false,
			numberFormat: '',
		},
		store: {
			imgHover: true,
			numberFormat: 'words',
		},
		blogNews: {
			imgHover: false,
			numberFormat: '',
		},
		instagram: {
			imgHover: true,
			numberFormat: 'words',
		},
	},

	layouts: [
		{
            no: '1',
			id: 'today',
			name: 'Normal operation today',
			layout: 'hamburger',
			imgtxt: '',
			logo: {
    			pc:{
    				head: {
        				scaletype: 'normal',
        				size: 20,
                    },
    				mv: {
        				scaletype: 'mv-logo',
        				size: 219.9,
                    },
    				foot: {
        				scaletype: 'normal',
        				size: 14.3,
                    }
    			},
    			mb:{
    				head: {
        				scaletype: 'normal',
        				size: 12.6,
                    },
    				mv: {
        				scaletype: 'normal',
        				size: 202.6,
                    },
    				foot: {
        				scaletype: 'normal',
        				size: 34.3,
                    }
    			}
			},
			mv: {
    			address: 'user-setting',
				addressTxtBase: 'txt',
				height: '100',
			},
			navigation: {
				sns: 'icon',
				pos: 'top',
			},
		},
	],
},


];

