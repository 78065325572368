import {Component, Watch, Prop, Vue} from "vue-property-decorator";
import {themeMainModule} from '@/store/theme/theme-main';
import {editModule} from '@/store/edit';

import CBlockImgtxt from '@/components/theme/module/block/CBlockImgtxt.vue';
import CBlockListOneLarge from '@/components/theme/module/block/CBlockListOneLarge.vue';
import CBlockList from '@/components/theme/module/block/CBlockList.vue';
import CBlockBlogNews from '@/components/theme/module/block/CBlockBlogNews.vue';
import CBlockInstagram from '@/components/theme/module/block/CBlockInstagram.vue';

import * as $ from 'jquery';

@Component({
    components: {
        CBlockImgtxt,
        CBlockListOneLarge,
        CBlockList,
        CBlockBlogNews,
        CBlockInstagram,
    }
})
export default class CBlock extends Vue {


    /*
        ID, Key
    */
    @Prop({default: 'concept'})
    public k: string;


    /*
        何番目?
    */
    @Prop({default: 1})
    public num: number;


    /*
        k からcomponentを指定
    */
    public get ocomponent(): any {

        const d = themeMainModule.homeBlockData.findByKey('id', this.k);
        if (!d) {
            return
        }

        return d.component;
    }


    /*
        コンポーネントが存在するか
    */
    public get isActive() {
        const d = themeMainModule.homeBlockData.findByKey('id', this.k);
        if (!d) {
            return false;
        }
        return true;
    }

    /*
        ManagerのときとTransitionをきりかえ
    */
    public get stransition(): any {

        if (editModule.editTransition) {
            return editModule.editTransition;
        }

        return 'ct-change';

    }

    // Edit box
    public get eid(): any {
        return 'block--' + this.k;
    }
}

