import {Component, Watch, Prop, Vue} from 'vue-property-decorator';
import {themeMainModule} from '@/store/theme/theme-main';

import CLink from '@/components/theme/module/CLink.vue';
import CTxtlinkList from '@/components/theme/module/CTxtlinkList.vue';
import moment from 'moment-timezone';

export interface ICArticleFooterContent {
    date: string,
    textlink: any[],
    categories: any[],
}

@Component({
    components: {
        CLink,
        CTxtlinkList,
    }
})
export default class CArticleFooter extends Vue {

    @Prop({default: null})
    public content: ICArticleFooterContent | null;

    @Prop({default: false})
    public like: boolean;

    public mounted() {
        console.log('CArticleFooter.content > ', this.content);
        console.log('date > ', this.content!['date']);
    }

    public get deviceSize(): any {
        return themeMainModule.deviceSize;
    }

    public get dateL(): string {
        return this.content ? moment(this.content.date, ["YYYY.MM.DD"]).format('LL') : '';
    }

    public get textlink(): any {
        return this.content ? this.content!['textlink'] : [];
    }

    public get categories(): any {
        return this.content ? this.content!['categories'] : [];
    }

    // public textlink: any = [
    //     {
    // 	    name: 'facebook',
    // 	    link: 'https://www.facebook.com/sharer/sharer.php?u=https://thought-brand.com/'
    //     },
    //     {
    // 	    name: 'twitter',
    // 	    link: 'https://twitter.com/share?url=https://thought-brand.com/'
    //     },
    //     {
    // 	    name: 'pocket',
    // 	    link: 'http://getpocket.com/edit?url=https://thought-brand.com/&title=THOUGHT'
    //     },
    // ];
    //
    //
    // public categories: any = [
    //     {
    // 	    name: 'Category A',
    // 	    link: '/gallery/'
    //     },
    //     {
    // 	    name: 'Category B',
    // 	    link: '/gallery/'
    //     },
    //     {
    // 	    name: 'Category C',
    // 	    link: '/gallery/'
    //     },
    // ];

}



