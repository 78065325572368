import {Mutation, MutationAction, Action, VuexModule, getModule, Module} from 'vuex-module-decorators';
import store from '@/classes/core/store';

// state's interface
export interface IOpenBlockModule {

    groups: any;

}

@Module({dynamic: true, store, name: 'openBlock', namespaced: true})
class OpenBlock extends VuexModule implements IOpenBlockModule {


    public groups: any = [];


    @Mutation
    public updateGroups(value: any) {
        this.groups = value;
    }

    @Mutation
    public updateGroup(value: any) {
        this.groups.push(value);
    }

    @Action
    public async addGroup(value: any) {
        //同じGroupがあるか確認
        if (!M.groups.findByKey('groupId', value.groupId)) {
            M.updateGroup(value);
        }

        console.log('addGroups > ', M.groups);
    }

    @Action
    public addBlock(value: any) {

        //対象グループを探す
        const group: any = this.groups.findByKey('groupId', value.groupId);

        //すでにGroupがあったとき
        if (group) {
            group.blocks.push(value.block);

            //Groupがまだなかった時、グループを新規作成
        } else {

            const groupData: any = {
                groupId: value.groupId,
                openId: '',
                blocks: [value.block],
            }

            this.addGroup(groupData);
        }
    }

    @Mutation
    public updateBtn(d: any) {
        d.group.btns.push(d.value);
    }

    @Mutation
    public newBtns(d: any) {
        d.group.btns = [d.value];
    }

    @Action
    public addBtn(value: any) {

        const group: any = this.groups.findByKey('groupId', value.groupId);

        //すでにGroupがあったとき
        if (group) {
//             console.log('Group あった');
//     		console.log(group.btns);

            if (!group.btns) {
//                 console.log('btnsなかった');
                const d: any = {
                    group: group,
                    value: value.btn
                }

                this.newBtns(d);
//         		console.log(group);
//         		console.log(group.btns);

            } else {
//                 console.log('btnsあった');
                const d: any = {
                    group: group,
                    value: value.btn
                }
                this.updateBtn(d);
//         		console.log(group);
//         		console.log(group.btns);

            }

            //Groupがまだなかった時、新規作成
        } else {
//             console.log('Group なかった');

            const groupData: any = {
                groupId: value.groupId,
                openId: '',
                btns: [value.btn],
                blocks: [],
            }

            this.addGroup(groupData);
        }
    }

    @Action
    public async open(value: any) {
        console.log('openBlockModule.open().then() > ', value);
        M.updateGroups(M.groups.replaceByKey('groupId', {
            ...M.groups.findByKey('groupId', value.groupId),
            ...{
                openId: value.openId,
            }
        }).array.from());
    }
}

export const openBlockModule = getModule(OpenBlock);

const M = openBlockModule;
