import {
    ApiExecuterAsync,
    ApiExecuterFactory,
    ApiMethod,
    ApiUtil
} from '@/classes/core/api/import';
import {vsprintf} from 'sprintf-js';

export default class OwnerRegist {

    public index(): ApiExecuterAsync {
        let url = ApiUtil.url('/owner/regist');
        let request = ApiExecuterFactory.createRequest({
            method: ApiMethod.Get,
            url: url,
        });

        return ApiExecuterFactory.createAsync(request);
    }
    public store(record:any): ApiExecuterAsync {
        let url = ApiUtil.url('/owner/regist');
        let request = ApiExecuterFactory.createRequest({
            method: ApiMethod.Post,
            url: url,
            param: record
        });

        return ApiExecuterFactory.createAsync(request);
    }
    public userValid(param: any): ApiExecuterAsync {
        let url = ApiUtil.url('/owner/regist/user/valid');
        let request = ApiExecuterFactory.createRequest({
            method: ApiMethod.Post,
            url: url,
            param
        });

        return ApiExecuterFactory.createAsync(request);
    }
    public ownerValid(): ApiExecuterAsync {
        let url = ApiUtil.url('/owner/regist/customer/valid');
        let request = ApiExecuterFactory.createRequest({
            method: ApiMethod.Post,
            url: url,
        });

        return ApiExecuterFactory.createAsync(request);
    }
    public cardValid(): ApiExecuterAsync {
        let url = ApiUtil.url('/owner/regist/card/valid');
        let request = ApiExecuterFactory.createRequest({
            method: ApiMethod.Post,
            url: url,
        });

        return ApiExecuterFactory.createAsync(request);
    }
    public domainValid(param:any): ApiExecuterAsync {
        let url = ApiUtil.url('/owner/regist/domain/valid');
        let request = ApiExecuterFactory.createRequest({
            method: ApiMethod.Post,
            url: url,
            param: param
        });

        return ApiExecuterFactory.createAsync(request);
    }
}
