import {Component, Watch} from 'nuxt-property-decorator';
import AVueComponent from '@/classes/page/a-vue-component';
import UseragentUtil from '@/classes/view/useragent-util';
import CCssvarsMix from '@/classes/theme/mixin/c-cssvars-mix';
import ManagerLoaderMix from '@/classes/mixin/manager-loader-mix';

import {appModule} from '@/store/app';
import {officialModule} from '@/store/official';
import {themeMainModule} from '@/store/theme/theme-main';
import {managerLoaderModule} from '@/store/manager/loader';
import {bodyModule} from '@/store/body';

import MNoticeFrame from '@/components/module/MNoticeFrame.vue';
import MPopupFrame from '@/components/popup/MPopupFrame.vue';
import MLoaderFrame from '@/components/module/MLoaderFrame.vue';

import TeGlobalFooter from '@/components/part/teaser/TeGlobalFooter.vue';
import TeNavFrame from '@/components/part/teaser/TeNavFrame.vue';
import TeOfficialNav from '@/components/part/teaser/TeOfficialNav.vue';

import OverlayScrollbars from 'overlayscrollbars';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-vue';

//Account
import {ownersiteModule} from '@/store/ownersite';

import * as $ from 'jquery';
//bodyModule.scrollEvent

@Component({
    mixins: [
        CCssvarsMix,
        ManagerLoaderMix
    ],
    components: {
	    MNoticeFrame,
	    MPopupFrame,
	    MLoaderFrame,
	    TeGlobalFooter,
	    TeNavFrame,
	    TeOfficialNav,
        'overlay-scrollbars': OverlayScrollbarsComponent,
    }
})
export default class Teaser extends AVueComponent {

    public isMounted: boolean = false;
	public gnav: boolean = false;
	public display: boolean = false;

//     public moFrameOption: any = {
//         scrollbars: {
//             visibility: 'auto',
//             autoHide: 'scroll',
//             autoHideDelay: 800,
//         },
//         overflowBehavior: {
//             x: 'hidden',
//         },
//         callbacks: {
//             onScroll: this.onScrollMpf,
//         }
//     };

	public created() {

	    //Project Data をStoreに格納
	    // themeMainModule.updateProject(DummyProject);
        console.log('layouts > Default.mounted()')
        themeMainModule.updateProject(ownersiteModule.project);

        this!['runLoading'](managerLoaderModule);
        this.isMounted = true;

        //Useragent and Browser Size



	}

	public mounted() {

    	//OS, Device
    	this.setDevice();

        //Force Resize Event
	    themeMainModule.updateMainFrameResized();

	    //最初に画面が真っ白なのを解除
        setTimeout(() => {
            this.display = true;
        }, 100);

	}

    /*
        Resize Event
        Managerの場合は、resize eventはownerサイトと同時に2つ起動できないので、
        Manager側のみでownerサイトも管理する
    */
	@Watch('mainFrameResized')
	public watchMainFrameResized() {
        this.resizedWindow()
	}

// 	@Watch('bodyScrollEvent')
// 	public watchBodyScrollEvent(e: any) {
//     	//console.log('e > ', e.target.scrollTop);
//         this.onScroll()
// 	}


	// Computed /////////////////////////////////////////////////////////////////

	public get bodyScrollEvent(): any {
	    return bodyModule.scrollEvent;
	}

	public get mainFrameResized(): any {
	    return themeMainModule.mainFrameResized;
	}

	public get deviceSize(): any {
    	return appModule.deviceSize;
	}

    public get pathName(): any {
        return this.$nuxt.$route.name;
    }

    public get isLoading(): boolean {
        return false;
    }

    public get loadingMain(): any {

        //mountするまでは false を返さない
        if (!this.isMounted) {
            return true;
        }

        return managerLoaderModule.loading;
    }

    public get isNavOpen(): any {
        return officialModule.navOpen;
    }

    public get classPagetype(): any {
        return '-p--' + appModule.pagetype;
    }

	/*
		ACCOUNT
	*/
	public get isAccount(): any {
		if (/^manager-account/.test(this.$nuxt.$route.name) && !/^manager-account-procedure/.test(this.$nuxt.$route.name) ) {
		   return true;
		}
		return false;
	}



	// Methods /////////////////////////////////////////////////////////////////

    public resize() {
	    themeMainModule.updateMainFrameResized();
    }

	public resizedWindow() {

	    let w: number = 0;
	    let h: number = 0;

    	if (process.client) {
    	    w = $(window).width();
    	    h = $(window).height();

    	} else {
            w = window.innerWidth;
            h = window.innerHeight;
    	}

	    appModule.updateMainFrameWidth(w);
	    appModule.updateMainFrameHeight(h);
		appModule.updateDeviceSize(UseragentUtil.size(w));

		// 最初に、ビューポートの高さを取得し、0.01を掛けて1%の値を算出して、vh単位の値を取得
        let vh = h * 0.01;
        // カスタム変数--vhの値をドキュメントのルートに設定
        document.documentElement.style.setProperty('--vh', `${vh}px`);
	}

	/*
		デバイス判定
	*/
	public setDevice() {
	    const w: number = window.innerWidth;
		appModule.updateDeviceSize(UseragentUtil.size(w));
		appModule.updateDeviceOs(UseragentUtil.os());
		appModule.updateDeviceDevice(UseragentUtil.device());
	}

	public path(path: string){
		if(!this){ return }
		const reg = new RegExp( '^'+ path);
		return reg.test(this.$nuxt.$route.path);
	}

//     public onScroll() {
//         console.log('aaa');
//     }


	// Events /////////////////////////////////////////////////////////////////


}





