


















	// @ts-ignore
    import _ from './MSelectColorMvTxt.ts'; export default _;
