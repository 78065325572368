
































	// @ts-ignore
    import _ from './CPageContactFinishStandard.ts'; export default _;
