import {Action, getModule, Module, Mutation, VuexModule} from "@/node_modules/vuex-module-decorators";
import store from "@/classes/core/store";
import ManagerApi from "@/classes/app/api/manager-api";
import {appAuthModule} from "@/store/app/auth";
import {$apm} from "@/classes/app/app-manager";

export interface IBlogCategory {
    id: number,
    toid: string,
    title: string,
    category_title: string,
}

export interface IManagerBlogCategoryModule {
    fetched: boolean;
    seq: number;
    info: any;
    records: IBlogCategory[];
}

@Module({dynamic: true, store, name: 'managerBlogCategory', namespaced: true})
class Store extends VuexModule implements IManagerBlogCategoryModule {

    public fetched: boolean = false;
    public seq: number = -1;
    public info: any;
    public records: IBlogCategory[] = [];

    @Mutation
    public updateRecords(value: IBlogCategory[]) {
        this.records = value;
    }

    @Mutation
    public updateFetched(fetched: boolean) {
        this.fetched = fetched;
    }

    @Mutation
    public updateSeq(value: number = -1) {
        this.seq = value;
    }

    @Action
    public async addCategory() {
        M.updateRecords([{
            id: M.seq,
            toid: '',
            category_title: '',
        } as IBlogCategory].concat(M.records));
        M.updateSeq(M.seq - 1);
    }

    @Action
    public async fetch(force: boolean = false): Promise<any> {

        if (!force && M.fetched) {
            return;
        }

        try {
            const records = await ManagerApi.BlogCategory.get(Object.assign({
                site: $apm.siteid,
                type: 'blog',
            })).execAsync('records.list');
            console.log('managerBlogCategoryModule.fetch() > records > ', records);
            M.updateRecords(records || []);
            M.updateFetched(true);

            return null;
        } catch (e) {
            return e;
        }
    }

    @Action
    public async save(records: IBlogCategory[]) {
        try {
            await ManagerApi.BlogCategory.post(Object.assign({
                src: JSON.stringify({
                    site: appAuthModule.auth!.project,
                    type: 'blog',
                    records,
                }),
            })).execAsync();

            await M.fetch(true);

            return true;
        } catch (e) {

            console.error(e);

            return false;
        }
    }
}

export const managerBlogCategoryModule = getModule(Store);

const M = managerBlogCategoryModule;
