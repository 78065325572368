import {Vue, Component, Prop, Watch} from 'nuxt-property-decorator';

import {WizardMake} from "@/configs/wizard-make";
import {WizardStart} from "@/configs/wizard-start";
import {wizardModule} from '@/store/wizard';
import {$v} from "@/classes/utils/var-util";
import {popupModule} from "@/store/popup";
import {cmdModule, ICmd} from "@/store/cmd";
import {prodStartModule} from "@/store/prod/start";
import {ManagerUtil} from "@/classes/domain/module/manager/manager-util";

@Component
export default class MFooterWiz extends Vue {

    public isPrevDisabled: boolean = false;
    public isNextDisabled: boolean = false;

    @Watch('cmd')
    public async watchLastCmd(now: ICmd) {
        console.log('MFooterWiz.watchLastCmd > ', now);
        if (!now) {
            return;
        }

        switch (now.cmd) {
            case 'popup.confirm.ok':
                console.log('popup.confirm.ok', now);
                if ($v.p(now, 'request.target') === 'wizardCancel') {
                    await this.onWizardCacncelOk(now);
                }
                break;
            case 'popup.confirm.cancel':
                console.log('popup.confirm.cancel', now);
                if ($v.p(now, 'request.target') === 'wizardCancel') {
                    await this.onWizardCancelCancel(now);
                }
                break;
            default:
                break;
        }
    }

    public mounted() {
        console.log('WFooterWiz.mounted() > url > ', this.ownersiteUrl);
        console.log(' - step = ', wizardModule.step);
    }

    // Computed ///////////////////////////////////////////
    public get ownersiteUrl(): string {
        return ManagerUtil.ownersiteUrl;
    }

    public get isMake(): any {
        return wizardModule.genre == 'wizard-make';
    }

    public get ototal(): any {
        return wizardModule.totalStep;
    }

    public get ostep(): any {
        return wizardModule.step;
    }

    public get steps(): any[] {
        return (this.isMake) ? WizardMake : WizardStart;
    }

    public get oprev(): any {

        let id: string = '';

        if (wizardModule.step <= 1) {
            this.isPrevDisabled = true;
            return '/official/layout';

        } else {
            this.isPrevDisabled = false;
            const data = this.steps.findByKey('step', wizardModule.step - 1);
            if (data) {
                id = data.id;
            }
            return {path: id, query: {step: wizardModule.step - 1}};
        }
    }

    public get onext(): any {

        let id: string = '';

        if (wizardModule.step >= wizardModule.totalStep || wizardModule.step == 1) {
            this.isNextDisabled = true;
        } else {
            this.isNextDisabled = false;
            const data = this.steps.findByKey('step', wizardModule.step + 1);
            if (data) {
                id = data.id;
            }
        }
        return {path: id, query: {step: wizardModule.step + 1}};
    }

    public get cmd(): ICmd {
        return cmdModule.queues.last();
    }

    // Events //////////////////////////////////////////////////////
    public async onClickCancel() {
        console.log('onClickCancel');
        await popupModule.open({
            id: 'wizardCancel',
            type: 'confirm',
            option: {
                title: 'ウィザードのキャンセル',
                body: 'ウィザードをキャンセルしてHomeに戻ります',
            },
        });
    }

    public async onWizardCacncelOk(queue: ICmd) {
        popupModule.closePopup('wizardCancel');
        await prodStartModule.resetInput();
        await this.$router.push('/');
    }

    public async onWizardCancelCancel(queue: ICmd) {
        popupModule.closePopup('wizardCancel');
    }
}
