import {
    ApiExecuterAsync,
    ApiExecuterFactory,
    ApiMethod,
    ApiUtil
} from '@/classes/core/api/import';
import {vsprintf} from 'sprintf-js';
import ApiConst from '@/classes/core/api/api-const';

export default class FileIndex {

    public index(id: string | number): ApiExecuterAsync {
        let url = ApiUtil.url(vsprintf('/file/%s', [id]));
        let request = ApiExecuterFactory.createRequest({
            method: ApiMethod.Get,
            url: url,
            param: {
                id: id,
            }
        });

        return ApiExecuterFactory.createAsync(request);
    }

    public store(file: any): ApiExecuterAsync {
        let url = ApiUtil.url('/file');
        let request = ApiExecuterFactory.createRequest({
            method: ApiMethod.Post,
            enctype: ApiConst.EncType.Multipart,
            url: url,
            param: file
        });

        return ApiExecuterFactory.createAsync(request);
    }

    public destroy(id: string | number): ApiExecuterAsync {
        let url = ApiUtil.url(vsprintf('/file/%s', [id]));
        let request = ApiExecuterFactory.createRequest({
            method: ApiMethod.Delete,
            url: url,
            param: {
                id: id,
            }
        });

        return ApiExecuterFactory.createAsync(request);
    }
}
