export const Ownersite = {
  pages: [
    {path: '/', component: 'c-index'},

    {path: '/blog', component: 'c-blog-index'},
    {path: '/blog/:key', component: 'c-blog-detail'},

    {path: '/contact/', component: 'c-contact-index'},
    {path: '/contact/confirm', component: 'c-contact-confirm'},
    {path: '/contact/finish', component: 'c-contact-finish'},

    {path: '/gallery', component: 'c-gallery-index'},
    {path: '/gallery/:key', component: 'c-gallery-detail'},

    {path: '/menu', component: 'c-menu'},
    {path: '/news', component: 'c-news'},
    {path: '/staff', component: 'c-staff-index'},
    {path: '/staff/:key', component: 'c-staff-detail'},

    {path: '/store', component: 'c-store-index'},
    {path: '/store/:key', component: 'c-store-detail'},
  ],
  404: '404'
};
