import {Component, Prop, Vue} from 'vue-property-decorator';
import {themeMainModule} from '@/store/theme/theme-main';

@Component({
    components: {
    }
})
export default class CListItemDetailHeaderTitle extends Vue {

    @Prop({default: ''})
    public k: string;

    @Prop({default: ''})
    public title: string;

    @Prop({default: ''})
    public title_en: string;


    public get stitle(): any {

        /*
            STAFFのときはtitle_enを優先する
        */
        if(this.k == 'staff'){
            return this.title_en;
        }

        return this.title;

    }

}

