













































	// @ts-ignore
    import _ from './MTxtbtn.ts'; export default _;
