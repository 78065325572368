import {Ownersite} from '@/configs/ownersite';

export class IRoute {
  path: string;
  component: string;
  param?: { [key: string]: any };
}

export class UrlParser {

  public static parse(path: string = '/', src: IRoute[] = Ownersite.pages) {

    if (path === '/') {
      return src.find((_: IRoute) => {
        return (_.path == '/');
      });
      // return src.findByKey('path', '/');
    }

    const rpaths = path
      .replace(/^\//, '')
      .replace(/\/$/, '')
      .split('/');

    let matched = false;
    let component = null;
    let param = {} as { [key: string]: any };

    src.some((_: any) => {
      const paths = _.path
        .replace(/^\//, '')
        .replace(/\/$/, '')
        .split('/');
      let match = false;
      param = {};

      paths.some((__: any, i: number) => {
        // console.log('i > %s : %s', __, i, rpaths[i]);

        if (/^:/.test(__)) {
          if (rpaths[i] != undefined) {
            // console.log('param > %s = %s', __, rpaths[i]);
            const pi = __.replace(/^:/, '');
            param[pi] = rpaths[i];
          }
          return false;
        } else {
          matched = (__ === rpaths[i]);
          // console.log('matched > ', matched);
          return !matched;
        }
      });

      // console.log('test > ', _.path, matched);

      if (matched && (paths.length === rpaths.length)) {
        component = _.component;
        return true;
      } else {
        return false;
      }
    });

    return matched ? {
      path: path,
      component: component,
      param: param
    } : null;
  }
}
