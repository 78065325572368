import {Component} from '@/node_modules/nuxt-property-decorator';
import _ from 'lodash';
import {sprintf} from "sprintf-js";

import APopup from "@/classes/page/a-popup";
import {popupModule} from '@/store/popup';

import MUploadimg from '@/components/module/MUploadimg.vue';
import MTextarea from '@/components/module/MTextarea.vue';
import {appEditModule} from "@/store/app/edit";
import {managerBlogArticleModule, managerBlogArticleModule as articleModule} from "@/store/manager/blog-article";
import {managerBlogCategoryModule} from "@/store/manager/blog-category";

import WUtil from "@/classes/view/w-util";
import VarUtil from "@/classes/utils/var-util";
import Throttle from "@/classes/core/throttle";
import {SelectUtil} from "@/classes/utils/select-util";
import {StoreAppEditUtil} from "@/classes/view/store/app/store-app-edit-util";
import {ExtEdit} from "@/classes/ext/ext-edit";
import {IChangeEvent, ISelect} from "@/classes/domain/model";

import Blog from '@/components/popup/articleDetail/Blog.vue';
import Gallery from '@/components/popup/articleDetail/Gallery.vue';
import News from '@/components/popup/articleDetail/News.vue';
import {ManagerService} from "@/classes/domain/module/manager/manager-service";
import QuillUtil from "@/classes/vendor/quill/quill-util";
import ImageUtil from "@/classes/utils/image-util";
import ManagerApi from "@/classes/app/api/manager-api";
import ViewModelUtil from "@/classes/view/view-model-util";
import {SystemConfig} from "@/configs/system";
import {managerBlogModule} from "@/store/manager/blog";
import {BlogUtil} from "@/classes/domain/module/model/blog/blog-util";
import {uploadimgModule} from "@/store/uploadimg";
import {upfileModule} from "@/store/upfile";
import {AuthManager} from "@/classes/app/auth-manager";


@Component({
    components: {
        MUploadimg,
        MTextarea,

        Blog,
        Gallery,
        News,
    }
})
export default class MPopupArticleDetail extends APopup {

    public type: string = popupModule.type;
    public rte: any;
    public bodyThrottle: Throttle;
    public images: any[] = [];
    public articleComponents = [
        'blog',
        'gallery',
        'news'
    ];

    public state = ViewModelUtil.createPageState({
        config: {
            cover: {
                uploadimgId: 'blogCover',
                upload: null,
            },
            article: {
                uploadimgId: 'blogArticle',
                upload: null,
            },
        },
    });

    public async mounted() {

        this.state.config.cover.upload = SystemConfig.uploadimg.purpose.findByKey('key', this.config.cover.uploadimgId);
        this.state.config.article.upload = SystemConfig.uploadimg.purpose.findByKey('key', this.config.article.uploadimgId);

        if (this.articleComponents.indexOf(this.type) < 0) {
            console.error('Article detail error invalid type > ', this.type);
            return;
        }

        // console.log('MPopupArticleDetail.mounted() > ', this.type, this.editId, articleModule.article);

        await appEditModule.start({
            id: this.editId,
            current: Object.assign({}, articleModule.article),
            input: Object.assign({}, articleModule.article),
            ext: [
                {name: 'media', value: []},
            ],
        });

        WUtil.browser(() => {
            this.images = [];
            const medias = VarUtil.path(articleModule.article, 'medias');
            // console.log('medias > ', medias);
            if (!!medias) {
                medias.map((_: any) => {
                    const img = new Image();
                    img.src = VarUtil.path(_, 'file.url');
                    // console.log('preoad > ', img.src);
                    this.images.push(img);
                })

                const cover = medias.findByKey('purpose', 'blogCover');
                if (!!cover) {
                    console.log('cover > ', cover);
                    uploadimgModule.updateUploadImg({
                        id: this.state.config.cover.uploadimgId,
                        imgs: [{src: VarUtil.path(cover, 'file.url')}],
                    });
                    console.log(' - ', uploadimgModule.uploadimgs);
                }
            }


            this.$nextTick(() => {
                const QuillOperator = require('@/classes/vendor/quill/quill-operator').default;
                this.bodyThrottle = new Throttle(800);
                this.rte = new QuillOperator();
                this.initRte();
            });
        });
    }

    public initRte() {

        const QuillUtil = require('@/classes/vendor/quill/quill-util').default;

        this.rte.init();
        this.rte.medias = BlogUtil.medias(this.edit.current);

        console.log('input > body > ', this.input.body);
        if (!!this.input.body) {
            this.rte.initContents(JSON.parse(this.input.body),);
        }

        this.rte.on('text-change', async (e: any) => {
            if (this.rte.lock) {
                console.log('RTE LOCKED');
                return true;
            }

            let img = VarUtil.path(e, 'ops.0.insert.image') || VarUtil.path(e, 'ops.1.insert.image');
            if (QuillUtil.isDataURI(img)) {
                console.log('rte.on() >  insert image> e:', e);
                const imgLimit = (1024 * 1024 * 10) * 1.3;
                console.log('valid filesize: img=%s, limit=%s', img.length, imgLimit);
                if (this.medias.length >= 20) {

                    alert("エラー｜画像登録失敗\n1つの記事に登録できる画像は20枚です");
                    this.rte.setContents(this.rte.last);
                    return;
                } else {
                    this.rte.lock = true;
                    const rimg = await this.resizeImage(img);
                    const retain = e.ops.length > 1 ? e.ops[0].retain : 0;
                    this.rte.setContents(this.rte.last);
                    this.rte.insertEmbed(retain, 'image', await ImageUtil.toDataURI(rimg));
                }
            }
            this.rte.last = this.rte.getContents();

            return true;
        });
    }

    private async resizeImage(img: string): Promise<any> {

        const config = {
            sizeLimit: 4000,
            quality: 0.7,
        };

        // console.log('resizeImage');
        return new Promise(async (resolve: any, reject: any) => {
            resolve(await ManagerService.image.resize({
                file: QuillUtil.dataURI2File('image.jpg', img),
                sizeLimit: config.sizeLimit,
                quality: config.quality,
            }));
        });
    }

    // Computed ////////////////////////////////////////////////////
    public get extEdit(): ExtEdit {
        return new ExtEdit(this);
    }

    public get editId(): any {
        return articleModule.editId;
    }

    public get config(): any {
        return this.state.config;
    }

    public get edit(): any {
        return StoreAppEditUtil.find(this.editId);
    }

    public get input(): any {
        return this.edit.input;
    }

    public get medias(): any[] {
        return this.edit.ext.findByKey('name', 'medias') || [];
    }

    public get current(): any {
        return this.edit.current;
    }

    public get record(): any {
        return articleModule.article || {
            published_at: '',
            updated_at: '',
        };
    }

    public get categoryModule(): any {
        switch (this.type) {
            case 'blog':
                return managerBlogCategoryModule;
            default:
                return managerBlogCategoryModule;
        }
    }

    public get categories(): any[] {
        return this.categoryModule.records;
    }

    public get categorySelects(): ISelect[] {
        return this.categoryModule.records.map((_: any) => {
            return {
                value: _.toid,
                label: _.title,
            };
        });
    }

    public get isPublished(): boolean {

        if (!VarUtil.has(this.record, 'published_at')) {
            return false;
        }

        return (
            !!this.record.published_at
            && this.record.is_active === 1
        );
    }

    public get inputCategories(): any[] {
        return VarUtil.path(this.input, 'categories', []) || [];
    }

    public get relatedFacebookL(): string {
        return SelectUtil.selectedL(
            VarUtil.path(this.input, 'related_facebook', 1), 'onoff');
    }

    public get relatedTwitterL(): string {
        return SelectUtil.selectedL(
            VarUtil.path(this.input, 'related_twitter', 1), 'onoff');
    }

    public get publicDateL(): string {
        if (this.isPublished) {
            return VarUtil.dateFormat(this.record.published_at);
        } else {
            return '-';
        }
    }

    public get labelNo(): string {
        return sprintf('News  No.%s', VarUtil.path(this.record, 'no', '---'));
    }

    // Label Methods ///////////////////////////////////////////////
    public dateYMDL(dt: string): string {
        if (!_.isString(dt) || dt.length === 0) {
            return '';
        } else {
            return VarUtil.dateFormat(dt, 'YYYY.MM.DD');
        }
    }

    public categoryL(category: any): string {
        // console.log('category > ', category);
        return BlogUtil.categoryL(category);
        // const cat = this.categories.findByKey('toid', category);
        // return !!cat ? cat.title : '*';
    }

    public async removeCategory(category: string) {
        await this.extEdit.updateInput({
            categories: this.inputCategories.filter((_: any) => _ !== category),
        });
    }


    public async save(publish: boolean = false) {
        try {
            const body = this.rte.getPostContent();
            const article = Object.assign({}, this.input, {
                body: VarUtil.path(body, 'body'),
            });

            // console.log('onClickSaveDraft', body);
            await managerBlogArticleModule.save({
                publish,
                article,
            });

            managerBlogModule.updateArticle(Object.assign(this.input));

            const up = upfileModule.ups.findByKey('id', this.config.cover.uploadimgId);
            if (!!up) {
                // console.log('regis > ', up);
                const upload = await ManagerApi.FileUpload.store({
                    id: this.config.uploadimgId,
                    site: AuthManager.auth!.project,
                    purpose: this.config.cover.upload.key,
                    article: article.toid,
                    file: up.file,
                }).execAsync('upload');

                await upfileModule.patchUp({
                    id: up.id,
                    progress: Object.assign({}, up.progress, {
                        result: true,
                    }),
                });
            }

            const files = VarUtil.path(body, 'file');
            // console.log(' - files > ', files);
            if (Array.isArray(files)) {
                for (let i = 0; i < files.length; i++) {
                    await ManagerApi.FileUpload.store({
                        id: this.config.article.uploadimgId,
                        site: AuthManager.auth!.project,
                        purpose: this.config.article.upload.key,
                        article: article.toid,
                        mkey: files[i].img,
                        file: ImageUtil.b642File(files[i].content, files[i].img + '.jpg'),
                    }).execAsync('upload');
                }
            }
            await managerBlogModule.fetch(true);

        } catch (e) {
            console.error(e);
        }

        this.$emit('close');
    }

    // Events //////////////////////////////////////////////////////
    public async onChangeSetting(e: IChangeEvent) {
        console.log('onChangeSetting', e);
    }

    public async onInput(e: any) {
        await this.extEdit.updateInput(BlogUtil.filterInput(e, this.input));
    }

    public async onSelect(e: any) {
        await this.extEdit.updateInput(e);
    }

    public async onCSelectCategory(e: any) {

        // console.log('onSelectCategory > ', this.inputCategories, e);

        const cat = this.inputCategories.find((_: any) => (_ === e.value));
        if (!cat) {
            await this.extEdit.updateInput({
                categories: VarUtil.tap(this.input.categories, (cats: string[]) => {
                    cats.push(e.value);
                    return cats;
                }),
            });
        } else {
            await this.removeCategory(e.value);
        }
    }

    public async onClickCategory(item: string) {
        await this.removeCategory(item);
    }

    public async onClickCancel() {
        console.log('onClickCancel');
        this.$emit('close');
    }

    public async onClickSaveDraft() {
        await this.save(false);
    }

    public async onClickSavePublish() {
        console.log('onClickSavePublish');
        await this.save();
        this.$emit('close');
    }

    ////////// POPUP LAYER 共通処理 /////////////////////////////////
    public onClose(to: string = ''): any {
        if (to != '') {
            this.$router.push(to);
        }
        this.$emit('close');
    }
}
