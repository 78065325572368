var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"resize",rawName:"v-resize:debounce",value:(_vm.resize),expression:"resize",arg:"debounce"}],ref:"mainframe",staticClass:"c-main-frame notranslate",class:[
			_vm.classLang,
			_vm.classMedia,
			_vm.classDeviceSize,
			_vm.classDeviceDevice,
			_vm.classPageType,
			_vm.classPagePage,
			_vm.classPageDepth,
			_vm.classLayoutId,
			_vm.classSidecoverDesign,
            _vm.classMvImageBrightness,
            {'-header-color-reverse': _vm.switchScrollDownReverseColor},
			{'-nav--open': _vm.navOpen} ],style:(_vm.cssVars)},[_c('overlay-scrollbars',{ref:"mScrollframe",staticClass:"c-scroll-frame",attrs:{"options":_vm.mScrollFrameOption}},[_c('transition',{attrs:{"name":"ct-loader-frame"}},[(_vm.isLoadingMain)?_c('c-loader-frame'):_vm._e()],1),_vm._v(" "),_c('c-popup-frame',{ref:"popup"}),_vm._v(" "),_c('c-edit-frame'),_vm._v(" "),_c('div',{staticClass:"c-base-frame",class:{'-loading': _vm.visibleContents},attrs:{"id":"top"}},[_c('c-header',{ref:"header"}),_vm._v(" "),_c('transition',{attrs:{"name":"ct-mv"}},[(_vm.pageType == 'index')?_c('c-mv',{ref:"mv"}):_vm._e()],1),_vm._v(" "),(_vm.pageType == 'index')?_c('c-sidecover',{ref:"sidecover"}):_vm._e(),_vm._v(" "),_vm._t("default"),_vm._v(" "),_c('c-footer',{ref:"footer"})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }