export default class RoutingUtil {

    /**
     *
     * @param to
     * @param from
     */
    public static transition(to: any, from: any): string | null {
        if (!from) {
            return null;
        } else {
            //Procedure
            if (/^manager-account-procedure/.test(to.name)) {
                console.log('routing: procedure');
                if (!/^manager-account-procedure/.test(from.name)) {
                    console.log('switch procedure');
                    return 'page-switch';
                }else{
                    return 'page-next';
                }
            }
            //Project
            else if (/^manager-project/.test(to.name)) {
                console.log('routing: project');
                if (!/^manager-project/.test(from.name)) {
                    console.log('switch project');
                    return 'page-switch';
                }else if( to.query.d == 'back' ){
                    return 'page-prev';
                }else{
                    return 'page-next';
                }
            }
            //Account
            else if (/^manager-account/.test(to.name)) {
                console.log('routing: account');
                if (!/^manager-account/.test(from.name) || /^manager-account-procedure/.test(from.name)) {
                    console.log('switch account');
                    return 'page-switch';
                }else{
	                //Account
	                if( /^manager-account-account/.test(from.name) ){
		                if( /^manager-account-account-/.test(to.name) ){
		                		return 'page-next';
	                	}else if( /^manager-account-account/.test(to.name) ){
		                		return 'page-prev';
	                	}else{
		                		return 'page-next';
	                	}
	                }
	                //Subscriptions
	                if( /^manager-account-subscriptions/.test(from.name) ){
		                if( /^manager-account-subscriptions-/.test(to.name) ){
		                		return 'page-next';
	                	}else if( /^manager-account-subscriptions/.test(to.name) ){
		                		return 'page-prev';
	                	}else{
		                		return 'page-next';
	                	}
	                }
	                //Domains
	                if( /^manager-account-domains/.test(from.name) ){
		                if( /^manager-account-domains-/.test(to.name) ){
		                		return 'page-next';
	                	}else if( /^manager-account-domains/.test(to.name) ){
		                		return 'page-prev';
	                	}else{
		                		return 'page-next';
	                	}
	                }
	                //Card
	                if( /^manager-account-card/.test(from.name) ){
		                if( /^manager-account-card-/.test(to.name) ){
		                		return 'page-next';
	                	}else if( /^manager-account-card/.test(to.name) ){
		                		return 'page-prev';
	                	}else{
		                		return 'page-next';
	                	}
	                }
                }
            }
            //Wizard
            else if (/^manager-wizard-make-/.test(to.name) || /^wizard-start-/.test(to.name)) {
                console.log('routing: wizard');
                if (!/^manager-wizard-make-/.test(from.name) ) {
                    if (!/^wizard-start-/.test(from.name)) {
                        console.log('switch wizard');
                        return 'page-switch';
                    }else{
	                    //Wizard Start > Wizard Start
	                    if( from.query.step > to.query.step ){
		                    return 'page-prev';
	                    }else{
		                    return 'page-next';
	                    }
                    }
                }else if(!/^wizard-start-/.test(from.name) ){
                    if (!/^manager-wizard-make-/.test(from.name)) {
                        console.log('switch wizard');
                        return 'page-switch';
                    }else{
	                    //Wizard Make > Wizard Make
	                    if( from.query.step > to.query.step ){
		                    return 'page-prev';
	                    }else{
		                    return 'page-next';
	                    }
                    }
                }else{
					console.log('routing: wizard | ?');
					return 'page-next';
                }
            }
            //User Top
            else if (/^manager/.test(to.name)) {
                console.log('routing: usertop');
                if ( /^manager-project/.test(from.name) 
	                || /^manager-wizard-/.test(from.name) 
	                || /^manager-account/.test(from.name) ) {
                    console.log('switch usertop');
                    return 'page-switch';
                }
            }
            //Full Width
            //Index, Layout
            else if ( /^index/.test(to.name) || /^layout/.test(to.name) ) {
                console.log('routing: index full');
                console.log(from.name);
                if (/^manager/.test(from.name) || /^start/.test(from.name) || /^pr/.test(from.name)) {
                    console.log('switch index');
                    return 'page-switch';
                }else if( /^layout/.test(from.name) ) {
                    return 'page-to-index';
                }else if( /^index/.test(from.name) ) {
	                console.log('page-to-layout');
                    return 'page-to-layout';
                }
            }
        }

        return null;
    }


    /**
     * /manager/account/* から来ました
     *
     * * @param name
     */
    public static matchOfManagerAccount(name: string): boolean {
        return name ? /^manager-account/.test(name) : false;
    }
}