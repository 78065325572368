import {Component, Prop, Vue} from '@/node_modules/nuxt-property-decorator';
import {tabModule} from '@/store/tab';

@Component
export default class MTabMove extends Vue {
   	
   	//Tab Key
    @Prop({default: 'tab'})
    public k: string;
    
    //移動先 Tab番号 (show)
    @Prop({default: 1})
    public to: number;
    
	public onTab(to: number) {
		
    	//どのタブか
    	const tab: any = tabModule.tabs.findByKey('k', this.k );
    	if(!tab){
	    	return;
    	}
		tab.show = to;
    	
    	//アクティブになるタブの取得
    	const map: any = tab.map.findByKey('id', to );
    	if(!map){
	    	return;
    	}
		tab.active = map.tabid;
		
		//show に to を代入
		tabModule.updateTab(tab);
	}
	
}
