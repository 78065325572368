/*

	YH: Data Spread Sheet is Here
    https://docs.google.com/spreadsheets/d/1qv9BXpEhA5Xodaoq52PTWGO455vFbi1IKFF8aqwpUIY/edit?usp=sharing

*/
export const SelectLayouts = [

{
	pattern_id: '1',
	brand_id: 'tiny-garamond',
	layout_id: 'photo-fill',
	label: {
		brand: 'Tiny Garamond',
		layout: 'Minimal and modern',
		font: 'Adobe Garamond',
	},
	logo: {
		id: 'crimpsonTextSB',
		header_tagline: false,
	},
	visual: {
		salon: '1',
		coffee: '1',
		type: 'normal',
	},
	sidecover: false,
	basecolor: 'white',
	sccolor: 'pink',
},
{
	pattern_id: '2',
	brand_id: 'tiny-garamond',
	layout_id: 'normal-center',
	label: {
		brand: 'Tiny Garamond',
		layout: 'Standard center',
		font: 'Adobe Garamond',
	},
	logo: {
		id: 'crimpsonTextSB',
		header_tagline: false,
	},
	visual: {
		salon: '1',
		coffee: '1',
		type: 'normal',
	},
	sidecover: false,
	basecolor: 'white',
	sccolor: 'pink',
},
{
	pattern_id: '3',
	brand_id: 'tiny-garamond',
	layout_id: 'normal-left',
	label: {
		brand: 'Tiny Garamond',
		layout: 'Standard left',
		font: 'Adobe Garamond',
	},
	logo: {
		id: 'crimpsonTextSB',
		header_tagline: false,
	},
	visual: {
		salon: '1',
		coffee: '1',
		type: 'normal',
	},
	sidecover: false,
	basecolor: 'white',
	sccolor: 'pink',
},
{
	pattern_id: '4',
	brand_id: 'tiny-garamond',
	layout_id: 'background-photo-center',
	label: {
		brand: 'Tiny Garamond',
		layout: 'Leading photo center',
		font: 'Adobe Garamond',
	},
	logo: {
		id: 'crimpsonTextSB',
		header_tagline: false,
	},
	visual: {
		salon: '1',
		coffee: '1',
		type: 'sidecover',
	},
	sidecover: true,
	basecolor: 'white',
	sccolor: 'pink',
},
{
	pattern_id: '5',
	brand_id: 'tiny-garamond',
	layout_id: 'background-photo-left',
	label: {
		brand: 'Tiny Garamond',
		layout: 'Leading photo left',
		font: 'Adobe Garamond',
	},
	logo: {
		id: 'crimpsonTextSB',
		header_tagline: false,
	},
	visual: {
		salon: '1',
		coffee: '1',
		type: 'sidecover',
	},
	sidecover: true,
	basecolor: 'white',
	sccolor: 'pink',
},
{
	pattern_id: '6',
	brand_id: 'tiny-garamond',
	layout_id: 'nostalgic',
	label: {
		brand: 'Tiny Garamond',
		layout: 'Nostalgic',
		font: 'Adobe Garamond',
	},
	logo: {
		id: 'crimpsonTextSB',
		header_tagline: false,
	},
	visual: {
		salon: '1',
		coffee: '1',
		type: 'nostalgic',
	},
	sidecover: false,
	basecolor: 'white',
	sccolor: 'pink',
},
{
	pattern_id: '7',
	brand_id: 'tiny-garamond',
	layout_id: 'cozy-frame',
	label: {
		brand: 'Tiny Garamond',
		layout: 'Framed',
		font: 'Adobe Garamond',
	},
	logo: {
		id: 'crimpsonTextSB',
		header_tagline: false,
	},
	visual: {
		salon: '1',
		coffee: '1',
		type: 'normal',
	},
	sidecover: true,
	basecolor: 'white',
	sccolor: 'pink',
},
{
	pattern_id: '8',
	brand_id: 'tiny-garamond',
	layout_id: 'cozy-shape',
	label: {
		brand: 'Tiny Garamond',
		layout: 'On the plate',
		font: 'Adobe Garamond',
	},
	logo: {
		id: 'crimpsonTextSB',
		header_tagline: false,
	},
	visual: {
		salon: '1',
		coffee: '1',
		type: 'normal',
	},
	sidecover: false,
	basecolor: 'white',
	sccolor: 'pink',
},
{
	pattern_id: '9',
	brand_id: 'tiny-garamond',
	layout_id: 'classic',
	label: {
		brand: 'Tiny Garamond',
		layout: 'Classic',
		font: 'Adobe Garamond',
	},
	logo: {
		id: 'crimpsonTextSB',
		header_tagline: false,
	},
	visual: {
		salon: '1',
		coffee: '1',
		type: 'normal',
	},
	sidecover: false,
	basecolor: 'white',
	sccolor: 'pink',
},

];

