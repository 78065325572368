import QRCode, {QRCodeToDataURLOptions} from 'qrcode';

export interface IQrcodeOption extends QRCodeToDataURLOptions {
}

export class QrcodeMaker {
    /**
     * @param text
     * @param opt
     */
    public static async create(text: string, opt: IQrcodeOption = {}): Promise<string> {
        try {
            return await QRCode.toDataURL(text, Object.assign({
                width: 120,
                color: {
                    dark: '#1e1e1e',
                    light: '#f8f7f6',
                }
            }, opt));
        } catch (e) {
            throw e;
        }
    }
}
