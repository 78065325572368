import AApi from "@/classes/core/api/a-api";
import ApiExecuterAsync from "@/classes/core/api/api-executer-async";
import ApiExecuterFactory from "@/classes/core/api/api-executer-factory";
import {ApiMethod} from "@/classes/core/api/api-method";
import {ApiUtil} from "@/classes/core/api/import";

export class ExActivation extends AApi {

    public show(code: string, param: any = {}): ApiExecuterAsync {
        let url = ApiUtil.url("/ex/activation/" + code);
        let request = ApiExecuterFactory.createRequest({
            method: ApiMethod.Get,
            url: url,
            param,
        });

        return ApiExecuterFactory.createAsync(request);
    }
}
