






































































	// @ts-ignore
    import _ from './ProcedureSharePayment.ts'; export default _;
