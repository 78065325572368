import {appEditModule, IEdit} from "@/store/app/edit";
import VarUtil, {$v} from "@/classes/utils/var-util";

export class StoreAppEditUtil {

    public static async input(editId: string, e: any) {
        await appEditModule.input({
            id: editId,
            input: StoreAppEditUtil.adaptInputEvent(e),
        });
    }

    public static adaptInputEvent(e: any) {
        // target経由
        if (!!$v.get(e, 'target')) {
            const a = $v.adaptInput(e);
            return {
                [a.name]: a.value,
            };
        // その他の整形済みデータ
        } else {
            return e;
        }
    };

    public static createEdit(base: any = {}): IEdit {
        return Object.assign({}, appEditModule.editTemplate, base);
    }

    public static find(id: string): IEdit {

        const na = StoreAppEditUtil.createEdit({
            id: id,
        });

        if (!appEditModule.edits) {
            return na;
        }

        return appEditModule.edits.findByKey('id', id) || na;
    }
}
