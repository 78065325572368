import {Component, Prop, Vue} from 'vue-property-decorator';
import AppTypeUtil from '@/classes/app/app-type-util';
import {ownersiteModule} from '@/store/ownersite';
import App from '@/classes/app/app';

@Component
export default class CLink extends Vue {

  @Prop({default: '/'})
  public to: string;

  @Prop({default: null})
  public rkey: string | number | null;

  public get linkPath(): string {
    return this.to + ((!!this.rkey) ? `/${this.rkey}` : '');
  }

  /*
    外部リンクかどうか
  */
  public get isInternalLink(): any {
    return !this.isExternalLink;
  }

  public get isExternalLink(): boolean {
    return /^http[s]?:\/\//.test(this.to);
  }

  public get isProduction(): boolean {
    return AppTypeUtil.isProduction;
  }

  public onClickHref() {
    console.log('CLink.onClickHref() > ', this.to, this.linkPath);
    ownersiteModule.linkTo(this.linkPath);
    // this.$router.push(this.$route.path + '?preview=' + this.to);
  }
}



