import {Component, Vue, Watch} from 'nuxt-property-decorator';
import AVueComponent from '@/classes/page/a-vue-component';
import UseragentUtil from '@/classes/view/useragent-util';
import CCssvarsMix from '@/classes/theme/mixin/c-cssvars-mix';
import ManagerLoaderMix from '@/classes/mixin/manager-loader-mix';

import {appModule} from '@/store/app';
import {accountModule} from '@/store/account';
import {themeMainModule} from '@/store/theme/theme-main';
import {IManagerLoader, managerLoaderModule, ManagerLoaderProgressType} from '@/store/manager/loader';

import MAccountFrame from '@/components/account/MAccountFrame.vue';
import MHelpFrame from '@/components/module/MHelpFrame.vue';
import MTourFrame from '@/components/module/MTourFrame.vue';
import MNavFrame from '@/components/module/MNavFrame.vue';
import MNoticeFrame from '@/components/module/MNoticeFrame.vue';
import MTipsFrame from '@/components/module/MTipsFrame.vue';
import MPopupFrame from '@/components/popup/MPopupFrame.vue';
import MSelectFrame from '@/components/select/MSelectFrame.vue';
// import MBaseLoader from '@/components/module/MBaseLoader.vue';
import MLoaderFrame from '@/components/module/MLoaderFrame.vue';
import MGlobalFooter from '@/components/part/share/MGlobalFooter.vue';
import MoOfficialNav from '@/components/part/official/MoOfficialNav.vue';
//Wizard
import MSecondaryWiz from '@/components/secondary-frame/MSecondaryWiz.vue';

//Account Popupに移行後削除
import MSecondaryAccount from '@/components/secondary-frame/MSecondaryAccount.vue';
import MSecondaryPlanSubscribe from '@/components/secondary-frame/MSecondaryPlanSubscribe.vue';
import MSecondaryPlanChange from '@/components/secondary-frame/MSecondaryPlanChange.vue';
import MSecondaryPlanUnsubscribe from '@/components/secondary-frame/MSecondaryPlanUnsubscribe.vue';
import MSecondaryDomainIndex from '@/components/secondary-frame/MSecondaryDomainIndex.vue';
import MSecondaryDomainSubscribe from '@/components/secondary-frame/MSecondaryDomainSubscribe.vue';
import MSecondaryDomainConnect from '@/components/secondary-frame/MSecondaryDomainConnect.vue';
import MSecondaryDomainUnsubscribe from '@/components/secondary-frame/MSecondaryDomainUnsubscribe.vue';
import MSecondaryDomainMove from '@/components/secondary-frame/MSecondaryDomainMove.vue';


import MSecondaryGraphicIndex from '@/components/secondary-frame/MSecondaryGraphicIndex.vue';
import MSecondaryWeb from '@/components/secondary-frame/MSecondaryWeb.vue';
import MSecondaryHelp from '@/components/secondary-frame/MSecondaryHelp.vue';
import MSecondaryTips from '@/components/secondary-frame/MSecondaryTips.vue';
import MSecondaryPress from '@/components/secondary-frame/MSecondaryPress.vue';
import MSecondaryNews from '@/components/secondary-frame/MSecondaryNews.vue';
import {ownersiteModule} from '@/store/ownersite';
import {OverlayScrollbarsComponent} from "@/node_modules/overlayscrollbars-vue";
import OverlayScrollbars from "@/node_modules/@types/overlayscrollbars";
import {wizardModule} from "@/store/wizard";
import {ExtAuthManager} from "@/classes/ext/ext-auth-manager";
import {ExtWizardMake} from "@/classes/ext/manager/ext-wizard-make";
import {$v} from "@/classes/utils/var-util";
import {layoutDefaultModule} from "@/store/layout/default";
import {AppConst} from "@/classes/app/app-const";
import {AuthManager} from "@/classes/app/auth-manager";
import {officialModule} from "@/store/official";
import {popupModule} from "@/store/popup";
import {selectModule} from "@/store/select";
import {managerAppModule} from "@/store/manager/app";
import {DevUtil} from "@/classes/utils/dev-util";
import {$apm} from "@/classes/app/app-manager";
import CmdController from "@/components/part/share/CmdController.vue";
import {AppManagerRoutingUtil} from "@/classes/app/manager/app-manager-routing-util";
import {appAuthModule} from "@/store/app/auth";
import {cmdModule, ICmd} from "@/store/cmd";
import {ConfigManagerAction} from "@/configs/app/manager-action";

@Component({
    mixins: [
        CCssvarsMix,
        ManagerLoaderMix
    ],
    components: {
        MAccountFrame,
        MTourFrame,
        MHelpFrame,
        MNavFrame,
        MNoticeFrame,
        MTipsFrame,
        MPopupFrame,
        MSelectFrame,
        MLoaderFrame,
        MGlobalFooter,
        MoOfficialNav,

        //Account Popupに移行後削除
        MSecondaryAccount,
        MSecondaryPlanSubscribe,
        MSecondaryPlanChange,
        MSecondaryPlanUnsubscribe,
        MSecondaryDomainIndex,
        MSecondaryDomainSubscribe,
        MSecondaryDomainConnect,
        MSecondaryDomainUnsubscribe,
        MSecondaryDomainMove,

        MSecondaryWiz,
        MSecondaryGraphicIndex,
        MSecondaryWeb,
        MSecondaryHelp,
        MSecondaryPress,
        MSecondaryNews,
        MSecondaryTips,

        CmdController,
    }
})
export default class Default extends AVueComponent {

    public mPrimaryFrameOption: any = {
        scrollbars: {
            visibility: 'auto',
            autoHide: 'scroll',
            autoHideDelay: 800,
        },
        overflowBehavior: {
            x: 'hidden',
        },
        callbacks: {
            onScroll: this.onScrollMpf,
        }
    };

    public mSecondaryFrameOption: any = {
        scrollbars: {
            visibility: 'auto',
            autoHide: 'scroll',
            autoHideDelay: 800,
        },
        overflowBehavior: {
            x: 'hidden',
        },
    };

    public isMounted: boolean = false;
    public isLoaded: boolean = false;

    public layout: string = '';
    public gnav: boolean = false;

    public state = {
        config: {
            loaderMid: 'init',
        },
        view: {
            isViewManagerSecondaryFrame: false,
            isPjReady: false,
        },
    };

    @Watch('$route.path')
    public watchRouterPath() {
        console.log('Layouts.default > watchRouterPath()');

        setTimeout(() => {
            this.resizedWindow();
        }, 300);
    }

    /*
        Resize Event
        Managerの場合は、resize eventはownerサイトと同時に2つ起動できないので、
        Manager側のみでownerサイトも管理する
    */
    @Watch('mainFrameResized')
    public watchMainFrameResized() {
        console.log('manager resized Event');
        this.resizedWindow()
    }

    @Watch('primaryFrame.scroll')
    public watchPrimaryFrameScroll(now: number | null) {
        if (now !== null) {
            this.scrollPrimaryFrameTo(now);
        }
    }

    /**
     * Loader complete
     * @param now
     */
    @Watch('layoutLoader')
    public watchIsLoaded(now: IManagerLoader) {
        if (now.progress === AppConst.ProgressFull) {
            this.state.view.isViewManagerSecondaryFrame = true;
            this.isMounted = true;
            layoutDefaultModule.updateReady(true);
        }
    }

    /**
     * 認証エラー[401]
     * @param now
     */
    @Watch('isAuthError')
    public watcIshAuthError(now: boolean) {
        console.log('layouts/default.vue > watchIsAuthError() > ', now, this.currentPageGiveManagerLogin());
        if (now && this.currentPageGiveManagerLogin()) {
            this.openPopupLogin();
        }
    }

    /**
     * PrimaryFrame読込み完了
     * @param now
     */
    @Watch('isReadyPrimary')
    public watchIsReadyPrimary(now: boolean) {
        DevUtil.log('layouts/default.vue > watchIsReadyPrimary() > ', now);
        managerLoaderModule.updateLoader({
            id: this.state.config.loaderMid,
            progress: AppConst.ProgressFull,
        });
        DevUtil.log('layouts/default.vue > watchIsReadyPrimary() > loader > ', this.layoutLoader);
    }

    /**
     * ゾンビLoadingの回収
     * @param now
     */
    @Watch('lastLoadingCmd')
    public watchLastLoadingCmd(now: ICmd | null) {
        if (!now) {
            return;
        }

        console.log('layouts/default.vue watchLastLoadingCmd()', now);

        const req = $v.p(now, 'request');
        if (req.step - 1) {
            this.watchIsReadyPrimary(this.isReadyPrimary);
        }
    }

    // Init //////////////////////////////////////////////////////////////
    public async created() {
        //Project Data をStoreに格納
        // themeMainModule.updateProject(DummyProject);
        // console.log('layouts > Default.mounted()');
        themeMainModule.updateProject(ownersiteModule.project);
        //Useragent and Browser Size
    }

    public async mounted() {

        //OS, Device
        this.setDevice();

        //Force Resize Event
        themeMainModule.updateMainFrameResized();

        this.$nextTick(async () => {
            this!['runLoading'](managerLoaderModule,
                this.state.config.loaderMid,
                ManagerLoaderProgressType.Semiauto);

            this.$nextTick(async () => {
                // 起動処理
                await this.boot();
                console.log('booted!');
            });
        });

        // this.isMounted = true;
    }

    public async boot() {

        console.log('layouts/default.vue > boot() > ', this.$route.path);
        console.log('project Fetch > ',
            AuthManager.isLogin(),
            $v.p(this.getRefMainContent(), 'giveManagerLogin', false));

        // Project ID 取得
        if (await $apm.boot(this)) {
            this.state.view.isPjReady = true;
        }

        return true;
    }


    // Computed //////////////////////////////////////////////////////////////////////
    public get extAuth(): ExtAuthManager {
        return new ExtAuthManager(this);
    }

    public get extWizard(): ExtWizardMake {
        return new ExtWizardMake(this);
    }

    public get pj(): string {
        return $v.path(this.$route, 'params.project');
    }

    /*
        LOADING
    */
//     public get isLoading(): any {
//         return appModule.baseLoading;
//     }


    /*
        ACCOUNT
        移植後削除
    */
    public get isAccount(): any {
        if (/^manager-account/.test(this.$nuxt.$route.name) && !/^manager-account-procedure/.test(this.$nuxt.$route.name)) {
            return true;
        }
        return false;
    }

    public get devProcedure() {
        return accountModule.dev_procedure;
    }

    // Account Popupに移植後に使用
    // main-frameにクラスを付加するためだけに使用
    public get isAccount2(): any {
        console.log(`appModule.isAccount: ${appModule.isAccount}`);
        return appModule.isAccount;
    }

    // Official Pageの場合は、Helpアイコンを非表示
    public get isHelp(): any {

        if (
            appModule.pagetype == 'official_layout' ||
            appModule.pagetype == 'wizard' ||
            appModule.pagetype == 'wizard-start'
        ) {
            return false;
        }

        return true;
    }

    public get opagetype(): any {

        //Base Layout
        this.layout = '-l--' + appModule.pageLayout;

        //Wizard Style
        let wizStyle: string = '';
        if (appModule.pagetype == 'wizard') {
            wizStyle = '-w--' + wizardModule.type;
        }

        this.gnav = appModule.pageGnav;

        return '-p--' + appModule.pagetype + ' ' + wizStyle;
    }

    public get odivider(): any {
        return '-d--' + appModule.divider;
    }

    public get pageName(): any {
        return this.$nuxt.$route.name;
    }

    public get mainFrameResized(): any {
        return themeMainModule.mainFrameResized;
    }

    public get isFooter(): any {
        if (this.$nuxt.$route.name == 'manager'
            && this.isMounted) {
            return true;

        } else {
            return false;
        }
    }

    public get deviceSize(): any {

        if (appModule.deviceSize == 'mb') {
            this.$nextTick(() => {
                (this.$refs.mPrimaryFrame as OverlayScrollbarsComponent).osInstance().destroy();
                (this.$refs.mSecondaryFrame as OverlayScrollbarsComponent).osInstance().destroy();
            });
        }

        return appModule.deviceSize;
    }

    public get primaryFrame(): any {
        return [];
    }

    public get mPrimaryFrameScroll(): OverlayScrollbars | null {
        return (this.$refs['mPrimaryFrame'] as OverlayScrollbarsComponent).osInstance();
    }

    public get managerLoaders(): IManagerLoader[] {
        return managerLoaderModule.loaders;
    }

    public get layoutLoader(): IManagerLoader {
        return managerLoaderModule.loaders.findByKey('id', this.state.config.loaderMid);
    }

    public get isAuthError(): boolean {
        return AuthManager.isE401();
    }

    public get loadingMain(): any {

        //mountするまでは false を返さない
        if (!this.isMounted) {
            return true;
        }

        return this.layoutLoader.loading;
    }

    public get isReadyPrimary(): boolean {
        return managerAppModule.readyPrimary;
    }

    public get isDeviceMb(): boolean {
        return this.deviceSize != 'mb';
    }

    public get isViewSecondaryFrame(): boolean {
        return this.path('/manager/project/') && this.isDeviceMb;
    }

    public get isViewManagerSecondaryFrame(): boolean {
        return this.state.view.isViewManagerSecondaryFrame;
    }

    public get isPjReady(): boolean {
        return this.state.view.isPjReady;
    }

    public get lastLoadingCmd(): ICmd | null {
        return $v.tap(cmdModule.queues.filter((_: ICmd) => {
            return (_.cmd === ConfigManagerAction.Loader.SemiAuto
                && $v.p(_, 'request.mid', '') === this.state.config.loaderMid)
        }), (queues: ICmd[]) => {
            return queues.length > 0 ? queues[0] : null;
        });
    }

    // Methods ///////////////////////////////////////////////////////////////////////////
    // メインコンテンツComponent（PrimaryFrame)
    public getRefMainContent(): Vue {
        return $v.p(this.$refs, 'main.$children.0');
    }

    public resize() {
        themeMainModule.updateMainFrameResized();
    }

    public resizedWindow() {
        const w: number = window.innerWidth;
        const h: number = window.innerHeight;
        console.log('Layouts.default > resizeWindow > w=%spx, h=%spx', w, h);
        appModule.updateMainFrameWidth(w);
        appModule.updateMainFrameHeight(h);
        appModule.updateDeviceSize(UseragentUtil.size(w));
    }

    public setDevice() {
        appModule.updateDeviceOs(UseragentUtil.os());
        appModule.updateDeviceDevice(UseragentUtil.device());
    }

    public path(path: string) {
        if (!this) {
            return
        }
        const reg = new RegExp('^' + path);
        return reg.test(this.$nuxt.$route.path);
    }

    public scrollPrimaryFrameTo(to: number) {

        console.log('scroll to');

        this.mPrimaryFrameScroll.scroll(to, 1000);
        this.$nextTick(() => {
            // cmsModule.scrollToPrimaryFrame(null);
        });
    }


    public currentPageGiveManagerLogin(): boolean {
        return $v.p(this.getRefMainContent(), 'giveManagerLogin', false);
    }

    public openPopupLogin() {
        officialModule.closeNav();
        popupModule.openPopup('login');
        selectModule.clearCurrent();
    }

    // Events ////////////////////////////////////////////////////////////////
    // @dev
    public onScrollMpf() {

    }
}
