import {$v} from "@/classes/utils/var-util";
import {StoreAppEditUtil} from "@/classes/view/store/app/store-app-edit-util";
import {IBlogCategory, managerBlogCategoryModule} from "@/store/manager/blog-category";
import Ld from 'lodash';

export class BlogUtil {


    public static categoryL(cat: string | IBlogCategory, pSrc: IBlogCategory[] | null = null): string {

        const toid = Ld.isString(cat) ? cat : $v.p(cat, 'toid');
        const src = Array.isArray(pSrc) ? pSrc : managerBlogCategoryModule.records;

        return $v.tap(src.findByKey('toid', toid), (rcat: IBlogCategory) => {
            return !!rcat ? rcat.title : '';
        });
    }

    public static filterInput(e: any, last: any): any {
        const input = StoreAppEditUtil.adaptInputEvent(e);
        if ($v.has(input, 'title')) {
            input['title'] = String(input!['title'])
                .split(/\n/)
                .length > 3 ? $v.p(last, 'title', '') : $v.p(input, 'title');
        }

        return input;
    }

    public static state(item: any): string {

        const isActive = $v.p(item, 'is_active');
        const publishedAt = $v.p(item, 'published_at');

        if (!publishedAt) {
            return 'draft';
        } else if (!isActive) {
            return 'hidden';
        }

        return '';
    }

    public static medias(article: any): any[] {

        const medias = $v.p(article, 'medias');
        if (!medias) {
            return [];
        }

        return medias.map((_: any) => {
            const mkey = $v.tap(_['purpose'].split('.'), (fragments: string[]) => {
                return fragments[fragments.length - 1];
            });
            return {
                mkey,
                url: $v.p(_, 'file.url'),
            }
        });
    }
}
