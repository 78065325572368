import {$apm} from "@/classes/app/app-manager";

export class AppManagerRoutingUtil {

    public static isManagerAccess(path: string): boolean {
        if (/^\/manager/.test(path)) {
            return true;
        }

        return false;
    }
}
