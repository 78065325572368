







































	// @ts-ignore
    import _ from './CArticleFooter.ts'; export default _;
