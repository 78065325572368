import {Component, Vue, Prop} from '@/node_modules/nuxt-property-decorator';
import {themeMainModule} from '@/store/theme/theme-main';
import {themeCssvarsModule} from '@/store/theme/theme-cssvars';
import {ColorMvTxt} from '@/configs/color/color-mv-txt';

@Component({
    components: {
    }
})
export default class MSelectColorMvTxt extends Vue {

    public d: any = ColorMvTxt;

    public onClick(value: any) {
        themeCssvarsModule.updateMvTxtBase(value.value);
        themeCssvarsModule.setMvTxtBase();
        themeCssvarsModule.updateMvTxtLabel(value.label);

    }

    public classColor(value: string) {

        if (value) {
            return '-' + value;
        }

        return;
    }


   	/////// 共通処理 /////////////////////////////////
    ////////// POPUP LAYER 共通処理 /////////////////////////////////
	public onClose(to: string=''): any {
		if(to != ''){
			this.$router.push(to);
		}
        this.$emit('close');
	}


}
