import {Component, Prop, Vue} from 'vue-property-decorator';
import ACBlock from '@/classes/theme/class/a-c-block';
import ViewUtil from '@/classes/view/view-util';
import {themeMainModule} from '@/store/theme/theme-main';
import CBlockMix from '@/classes/theme/mixin/c-block-mix';

import {DummyInstagram} from '@/configs/dummy/dummy-instagram';

@Component({
    mixins: [CBlockMix],
    components: {
    }
})
export default class CBlockInstagram extends ACBlock {


    // COLUMN
    @Prop({default: ''})
	public column: string;


    /*
        処理用変数
    */
    public data: any = [];

    //アイテム数
    public length: number = 0;


    public created() {

        /*
            DummeHomeのデータから指定Blockのデータを取得
            設定画面: manager/project/home/
        */
        this.init(this.k);

    }


    /*

        Component処理

    */
    public get odata(): any {

        //件数を取得
        this.length = DummyInstagram.length;
        this.data = DummyInstagram.slice(0, 6);

        return this.data;

    }


    /*
        IMG HOVER
    */

    // カラム数
//     public get sClassColumn(): any {
//         if(this.column){
//             return '-column-' + this.column;
//         }
//         return '-column-' + this.blockData.column;
//     }

    /*
        アイテム数
    */
    public get sClassLength(): any {
        return '-length' + String(this.length);
    }

    /*
        VALUES
    */

    public get isLink(): any {
        if (this.blockData.link) {
            return true;
        }
            return false;
    }
}

