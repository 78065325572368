import {Mutation, MutationAction, Action, VuexModule, getModule, Module} from 'vuex-module-decorators';
import store from '@/classes/core/store';

// state's interface
export interface IBodyModule {
    scrollEvent: any;
}

@Module({dynamic: true, store, name: 'body', namespaced: true})
class Store extends VuexModule implements IBodyModule {
    private _scrollEvent: any = null;

    //Getters
    public get scrollEvent(): any {
        return this._scrollEvent;
    }

    // Mutations
    @Mutation
    public updateScrollEvent(value: any) {
        this._scrollEvent = value;
    }
}

export const bodyModule = getModule(Store);

const M = bodyModule;
