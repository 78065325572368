











































	// @ts-ignore
    import _ from './CPageStaffDetailStandard.ts'; export default _;
