


































































	// @ts-ignore
    import _ from './TeGlobalFooter.ts'; export default _;
