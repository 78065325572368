import {Component, Watch, Prop, Vue} from "vue-property-decorator";
import {themeMainModule} from '@/store/theme/theme-main';

import CMvAddress from '@/components/theme/module/home/mv/CMvAddress.vue';
import CMvContentGallery from '@/components/theme/module/home/mv/CMvContentGallery.vue';
import CNewsList from '@/components/theme/module/CNewsList.vue';

import * as $ from 'jquery';


@Component({
    components: {
        CNewsList,
	    CMvAddress,
	    CMvContentGallery,
    }
})
export default class CMv extends Vue {

    public styleMvFrameHeight: any = {};
    public resizing: boolean = false;
    public delayStyleManagerMobile: number = 150;

    @Watch('mainFrameResized')
    public watchMainFrameResized() {
        this.setStyleMvFrameHeight();
    }

    @Watch('layoutId')
    public watchLayoutId() {
        console.log('watchLayoutId()', this.layoutId);
        this.setStyleMvFrameHeight();
    }

    public setStyleMvFrameHeight(): any {

	    let y: number = 0;
	    let unit: string = 'vh';

        if (!this.resizing) {

		    this.resizing = true;
		    let style: any = {};

    	    /*
    			MANAGERでの画面の縦幅
    	    */
    	    if(themeMainModule.manager) {
    	        if($('.m-preview-attention').length) {
    				y = $('.m-preview-attention').outerHeight();
    	        }
    	    }

    	    if(
        	    themeMainModule.deviceSize == 'pc'
        	    || themeMainModule.deviceSize == 'tablet'
            ) {

    		    if(themeMainModule.layout.mv.height == 'calc') {

    			    const h = themeMainModule.navHeaderOuterH;

    			    style = {
    					height: `calc(100${unit} - ${h + y}px)`,
                        'flex-basis': `calc(100${unit} - ${h + y}px)`,
    			    };

    		    }else{
    			    style = {
    					height: `calc(100${unit} - ${y}px)`,
                        'flex-basis': `calc(100${unit} - ${y}px)`,
    			    };
    		    }

                this.styleMvFrameHeight = style;
                this.resizing = false;

    	    } else if (themeMainModule.deviceSize == 'mb') {

                //Manager
        	    if (themeMainModule.manager) {

        		    this.styleMvFrameHeight = {};

        	        setTimeout(
        	            () => {

                            unit = '%';

                            let $mbNavFrame: any = $('.c-mb-nav-header');
                            const mvHeight: number = parseInt($('.c-mv-frame').css('height'));
                            let h: number = 0;

                            //透明じゃなかった時だけHeightを取得
                            if ($mbNavFrame.css('background-color') != 'transparent') {
                		        h = $mbNavFrame.outerHeight();
                            }

                		    let navbottomH: number = $('.c-info-navigation-frame').outerHeight();

                		    if (!navbottomH) {
                    		    navbottomH = 0;
                		    }

//                 		    console.log(`mvHeight：${mvHeight}, h: ${h}, navbottom: ${navbottomH}`);

                		    style = {
                				top: `${h}px`,
                				height: `${mvHeight - h - navbottomH}px`,
                                flex: `100 0 ${mvHeight - h - navbottomH}px`,
                                marginBottom: `${h}px`,
                		    };

                		    this.styleMvFrameHeight = style;
                            this.resizing = false;

        	            }, this.delayStyleManagerMobile);

        	    } else {
                    this.resizing = false;

        	    }
    	    }
        }
    }


    public get mainFrameResized(): any {
        return themeMainModule.mainFrameResized;
    }

    public get layoutId(): any {
	    return themeMainModule.project.global.layout;
    }

    public get webTitle(): any {
	    return themeMainModule.project.web.title;
    }

    /*
	    Img
    */
    public get imgs(): any {
	    return themeMainModule.mvImg;
    }


    /*
		Content
    */
    public get isTopics(): any {
        return true;
	    //return themeMainModule.mvTopics;
    }

    public get isAddress(): any {

        /*
            Brand Layoutファイルで強制表示されていたらtrue
        */
        if(themeMainModule.layout.mv.address == 'force-true'){
            return true;
        }

	    return themeMainModule.mvAddress;
    }

    public get scontent(): any {
	    return themeMainModule.mvContent;
    }


}



