




















































	// @ts-ignore
    import _ from './MSecondaryGraphicIndex.ts'; export default _;
