import {Component, Prop, Vue} from '@/node_modules/nuxt-property-decorator';

@Component({
    props: ['error'],
})
export default class Error extends Vue {

}





