import {Env} from "@/classes/app/env";
import ApiUtil from "@/classes/app/api/api-util";

export class EnvUtil {
    public static get isAppModeProduction(): boolean {
        return Env.NODE_ENV === 'production';
    }

    public static get isAppModeDevelop(): boolean {
        return !self.isAppModeProduction;
    }

    public static get isDummy(): boolean {
        return ApiUtil.dataRef() === 'dummy';
    }
}

const self = EnvUtil;
