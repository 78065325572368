


















	// @ts-ignore
    import _ from './MSelectAccountMenu.ts'; export default _;
