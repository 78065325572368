import {Component} from "nuxt-property-decorator";
import RoutingUtil from "@/classes/view/routing-util";
import APopup from '@/classes/page/a-page';
import {appModule} from '@/store/app';

//upload-layerの表示管理
import {uploadlayerModule} from '@/store/uploadlayer';

import MPopupUploadImgDrag from '@/components/popup/upload/MPopupUploadImgDrag.vue';

@Component({
    components: {
	    MPopupUploadImgDrag
    }
})
export default class MPopupUploadLogo extends APopup {




	////////////// UPLOAD LAYER 共通 ///////////////////////////////////

	//初期表示のupload-layerをセット
	public created(){
		uploadlayerModule.updateLayer('select-img');
	}

	//upload-layerを表示
	public get page(): any {
		return uploadlayerModule.layer;
	}

	//upload-layerの移動
	public on(to: string) {
		uploadlayerModule.updateLayer(to);
	}

	////////////// Popup ///////////////////////////////////

	public onClosePopupOpen(value: string) {
		if(value != ''){
			this.onPopupOpen(value);
		}
        this.$emit('close');
	}

	public onClose(to: string=''): any {
		if(to != ''){
			this.$router.push(to);
		}
        this.$emit('close');
	}



}
