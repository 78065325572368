import {Component, Vue} from 'nuxt-property-decorator';
import {themeMainModule} from '@/store/theme/theme-main';
import {osMenuModule} from '@/store/os/menu';

@Component({
    components: {
    }
})
export default class CMenuPc extends Vue {

    public get menus(): any {
        return osMenuModule.content!['menus'];
    }
}
