import {Component, Watch, Prop, Vue} from '@/node_modules/nuxt-property-decorator';
import {tourModule} from '@/store/tour';
import {appModule} from '@/store/app';

import {Tour} from '@/configs/tour';

import * as $ from 'jquery';

@Component({
	components: {
	}
})
export default class MTourFrame extends Vue {


    /*
	    TOUR ID
    */
    @Prop({default: 'welcome'})
    public k: string;

    @Prop({default: 1})
    public startstep: number;


    /*
	    DATA
    */
    public tour: any = {};
    public step: any = {};
    public stepNumber: number = 1;
    public stepTotal: number = 0;


    /*
	    BINDING DATA & STYLES
    */
    public body: string = '';

    public isBg: boolean = false;
    public isPop: boolean = false;
    public isMask: boolean = false;
    public isCursor: boolean = false;

    public styleMaskPrimary: any = {};
    public styleTourLayer: any = {};
    public styleCursor: any = {};
    public stylePopWrapper: any = {};
    public styleClickHere: any = {};
    public isNextButton: boolean = true;


    /*
	    CONST
    */
    //_m-pop.scss
    public pop_w: number = 222;
	public pop_h: number = 196;

	public first_delay: number = 0;
	public dur_in: number = 480;
	public dur_out: number	= 480;
	public maskbg_dur_in: number = 600;
	public mask_move_dur: number = 1000;


    /*
	    VARIABLES
    */
	public pop_mt: number = 0;
	public pos: any = '';
	public posX: number = 0;
	public posY: number = 0;
	public objW: number = 0;
	public objH: number = 0;
	public maskX: number = 0;
	public maskY: number = 0;
	public boxY: number = 0;
	public boxX: number = 0;
	public boxPos: string = 'bottom';
	public maskAddX: number = 0;
	public maskAddY: number = 0;
	public addClass: string = '';
	public maskSize: number = 0;
	public delay: number = 0;
	public $target: any;
	public type: string = '';
	public exType: string = '';
	public firstAnimationStep: boolean = false;
	public addedClass: any = [];


	/*
		ACTIVE
	*/
	public get isActive(): any {
		return tourModule.active;
	}

    @Watch('isActive')
    public watchActive() {
		if(tourModule.active == true && this.deviceSize != 'mb') {
			this.start();
		}
    }


	public created() {
		tourModule.updateData(Tour);
	}


	/*
		START
	*/
	public start(){

    	const tour: any = Tour.findByKey('name', tourModule.name);
    	if( !tour ){
	    	return
    	}

		this.tour = tour;

		this.stepNumber = tourModule.stepNumber;

    	const step: any = this.tour.steps[this.stepNumber];
    	if( !step ){
	    	return
    	}
		this.step = step;


		/*
			トータルステップ数
		*/
		this.stepTotal = this.tour.steps.length;


		/*
			START
		*/
		this.pop_mt = parseInt( $(this.$refs.popWrapper).css('margin-top') );
		if(!this.pop_mt) {
			this.pop_mt = 0;
		}

        setTimeout(
            () => {
			this.display();
		}, this.first_delay);

	}


	/*
		DISPLAY
	*/
	public display() {

		if(this.step) {

			let baseX: number = 0,
				baseY: number = 0;

			/*
				SET TARGET
			*/
			this.$target = $(this.step.target);
			if(!this.$target.length){
				this.next();
			}

			if(this.step.scroll) {


				/*
					画面下の120PXより下だった場合、スクロールさせてから座標を取得する
				*/
				console.log(this.$target);
				console.log(this.$target.offset().top);
				const winH: number = $(window).height();
				const targetY: number = this.$target.offset().top;
				const targetH: number = this.$target.outerHeight();


				/*
					親が m-primary-frame or c-main-frame or m-secondary-frame
				*/
				if(this.$target.closest('.m-primary-frame').length) {

					this.scrollTargetFrame('.m-primary-frame', winH, targetY, targetH);


				}else if(this.$target.closest('.c-main-frame').length) {

					this.scrollTargetFrame('.c-main-frame', winH, targetY, targetH);


				}else if(this.$target.closest('.m-secondary-frame').length) {

					this.scrollTargetFrame('.m-secondary-frame', winH, targetY, targetH);

				}

			}

			this.pos = this.$target.offset();
			this.posX = this.$target.offset().left + baseX;
			this.posY = this.$target.offset().top + baseY;
			this.objW = this.$target.outerWidth();
			this.objH = this.$target.outerHeight();
			this.maskAddX = this.step.maskAddX;
			this.maskAddY = this.step.maskAddY;
			this.maskX = this.posX + this.maskAddX + (this.objW / 2);
			this.maskY = this.posY + this.maskAddY + (this.objH / 2);
			this.addClass = this.step.addClass;
			this.maskSize = this.step.maskSize * this.$target.outerWidth();
			this.delay = this.step.delay;
			this.boxPos = this.step.boxPos;
			this.body = 	this.step.body;

			console.log(`
				pos: ${this.pos},
				posX: ${this.posX},
				posY: ${this.posY},
				objW: ${this.objW},
				objH: ${this.objH},
				maskAddX: ${this.maskAddX},
				maskAddY: ${this.maskAddY},
				maskX: ${this.maskX},
				maskY: ${this.maskY},
				addClass: ${this.addClass},
				maskSize: ${this.maskSize},
				delay: ${this.delay},
			`);


			/*
				nextBtnがない場合、reader, ある場合 command
				animation: false の場合、action
				actionの場合は、Target に Classを指定するだけ
			*/
			/*
				ACTION
				Target に addClassして次へ
			*/
			if(!this.step.animation) {

				this.type = 'action';
				this.addClassToTarget();
				this.next();

			/*
				READER
			*/
			}else if(!this.step.nextbtn) {

				this.type = 'reader';

				/* 一番最初の場合
					マスクの位置をリセット */
				if(!this.firstAnimationStep) {

					this.resetMaskPos();
					this.initCursor();

			        setTimeout(
			            () => {

						this.isBg = true;
						this.isCursor = true;

				        setTimeout(
				            () => {
							this.isMask = true;
							this.moveMask();
							this.moveCursor();
							this.initPop();
						}, this.maskbg_dur_in);

				        setTimeout(
				            () => {
							this.addClassToTarget();
							this.isPop = true;
						}, this.maskbg_dur_in + this.mask_move_dur);

					}, this.delay);

					this.firstAnimationStep = true;

				}else{

					if(this.exType = 'command') {
						this.resetMaskPos();
						this.initCursor();
						this.isBg = true;
						this.isCursor = true;
					}

			        setTimeout(
			            () => {
						this.isMask = true;
						this.moveMask();
						this.moveCursor();
						this.initPop();

				        setTimeout(
				            () => {
							this.addClassToTarget();
							this.isPop = true;
						}, this.mask_move_dur);

					}, this.step.delay);

				}

			/* -----------------------------------------
				COMMAND
			----------------------------------------- */
			}else{

				this.type = 'command';

				const $nextbtn: any = $(this.step.nextbtn) || undefined;
				this.maskSize = 0;

				this.isBg = false;
				this.isMask = false;
				this.isCursor = false;

				$nextbtn.on('click',
					() => {
						this.next();
					});

				this.initClickHere(this.objW, this.objH, this.posX, this.posY);

		        setTimeout(
		            () => {

					this.initPop();

					/* 吹き出しの座標を計算 */
			        setTimeout(
			            () => {
						this.addClassToTarget();
						this.isPop = true;
					}, this.maskbg_dur_in);

				}, this.delay);

			}

			this.exType = this.type;

		}else{
			this.finish();
		}

	}


	public scrollTargetFrame(
		frame: string,
		winH: number,
		vtargetY: number,
		targetH: number
	) {

		//画面ギリギリだとまずいので余裕をもって中にいれる幅
		const correct: number = 120;

		const scr: number = $(frame).scrollTop();

		//スクロールされたターゲットの一番上からのY
		const targetY: number = scr + vtargetY;

		//スクロールされた画面の下の一番上からのY
		const scrBottom: number = scr + winH;

		//修正後の適正な位置
		let nextTargetY: number = targetY - ((winH - targetH) / 2);
		const nextTargetYFromBottom: number = scrBottom - correct - targetH;
		const nextTargetYFromTop: number = scrBottom - correct;

		console.log(`${frame}:
			scrBottom: ${scrBottom},
			scr: ${scr},
			targetY: ${targetY},
			targetH: ${targetH},
			nextTargetYFromBottom: ${nextTargetYFromBottom},
			nextTargetYFromTop: ${nextTargetYFromTop},
		`);

		/*
			ターゲットが画面より下にある場合、下にスクロール
		*/
		if(nextTargetYFromBottom < targetY) {

			$(frame).scrollTop(nextTargetY);

		/*
			ターゲットが上にある場合、上にスクロール
		*/
		}else if(nextTargetYFromTop > targetY) {

			if (nextTargetY < 100) {
    			nextTargetY = 0;
            }

			$(frame).scrollTop(nextTargetY);

		}

	}


	/*
		ターゲットの操作
		JQUERYで操作する
	*/
	public addClassToTarget() {
		if( this.addClass != '' ){
			/*
				すでに同じクラスが追加されていた場合はremoveする
			*/
			if(this.$target.hasClass(this.addClass)) {
				this.$target.removeClass(this.addClass);
			}else{
				this.$target.addClass(this.addClass);

				/*
					FINISHしたときに削除するためにリストを作成しておく
				*/
				const add: any = {
					target: this.$target,
					addedClass: this.addClass
				}
				this.addedClass.push(add);
			}
		}
	}

	public removeClassToTarget() {
		if( this.addClass != '' ){
			this.$target.removeClass(this.addClass);
		}
	}


	/*
		POP
		吹き出しの座標を計算
	*/
	public initPop() {

		const w: number = $(this.$refs.popWrapper).outerWidth();
		const h: number = $(this.$refs.popWrapper).outerHeight();

		switch (this.boxPos){

			case 'right':

				//Commandモードの時は、中央に配置するのではなく、寄せる
				this.boxX = Math.ceil((this.maskSize / 2) + this.maskX);
				this.boxY = Math.ceil((this.maskY - (this.maskSize / 2)) + ((this.maskSize - h) / 2 ));

				break;


			case 'left':

				this.boxY = Math.ceil((this.maskY - (this.maskSize / 2)) + ((this.maskSize - h) / 2 ));

				//Commandモードの時は、中央に配置するのではなく、寄せる
				if(this.type == 'reader') {
					this.boxX = Math.ceil(this.maskX - (this.maskSize / 2) - w - this.pop_mt);

				//COMMAND
				}else{
					this.boxX = Math.ceil(this.posX - w - this.pop_mt);
				}

				break;


			case 'top':

				this.boxX = Math.ceil(this.maskX - (this.pop_w / 2));

				//Commandモードの時は、中央に配置するのではなく、寄せる
				if(this.type == 'reader') {
					this.boxY = Math.ceil((this.maskY - (this.maskSize / 2)) - h);

				}else{
					this.boxY = Math.ceil(this.posY + this.maskAddY - h - this.pop_mt);

				}

				break;


			case 'bottom':

				this.boxX = Math.ceil(this.maskX - (w / 2));

				//Commandモードの時は、中央に配置するのではなく、寄せる
				if(this.type == 'command') {
					this.maskY = this.posY + this.maskAddY + this.objH;
				}

				this.boxY = Math.ceil((this.maskY + (this.maskSize / 2)) + this.pop_mt);

				break;

		}

		/* 吹き出しを動かす */
		this.stylePopWrapper = {
			top: `${this.boxY}px`,
			left: `${this.boxX}px`
		}

	}


	/*
		CLASS & STYLE
	*/
	public get classType(): any {
		return 'm-tour-type--' + this.type;
	}

	public get classPopPos(): any {
		return 'm-pop--' + this.boxPos;
	}


	/*
		MASK
	*/
	public moveMask() {
		this.styleMaskPrimary = {
			transform: 'translate(' + this.maskX + 'px,' + this.maskY + 'px) scale(' + (this.maskSize / 20) + ')'
		}
	}

	public resetMaskPos() {
		this.styleMaskPrimary = {
			transform: 'translate(50%,50%)'
		}
	}


	/*
		CURSOR
	*/
	public initCursor() {

	}

	public moveCursor() {
		this.styleCursor = {
			transform: 'translate(' + this.maskX + 'px,' + this.maskY + 'px)'
		}
	}


	/*
		COMMANDのクリックを促すアニメーション
	*/
	public initClickHere( w, h, left, top ) {

		const itemleft_x = 65;
		let x: number = 0,
			y: number = 0;

		const myW = $(this.$refs.clickHere).outerWidth();
		const myH = $(this.$refs.clickHere).outerHeight();


		/* 左メニューだったら左寄せ */
		if( this.$target.children('.m-list-item-left').length ) {
			x = itemleft_x;
			y = ( top + (( h - myH ) / 2) );
		}else{
			x = ( left + (( w - myW ) / 2) );
			y = ( top + (( h - myH ) / 2) );
		}

		this.styleClickHere = {
			left: `${x}px`,
			top: `${y}px`
		}

	}


	/*
		現在のSTEPを渡す
	*/
	public next() {

		/*
			今最後のステップかどうか
		*/
		if(!this.tour.steps[this.stepNumber + 1]) {

			this.finish();

		}else{

			/*
				次のステップも同じターゲットだったら
				BODYを変えるだけ
			*/
			if(this.step.target == this.tour.steps[this.stepNumber + 1].target) {

				++this.stepNumber;
				this.step = this.tour.steps[this.stepNumber]
				//this.body = this.step.body;
				this.display();

			}else{

				/* POPをフェードアウト */
				this.isPop = false;

				/*
					Actionだった場合、追加されたクラスをremoveしない
				*/
				if(this.type != 'action') {
					this.removeClassToTarget();
				}

		        setTimeout(
		            () => {
					this.initPop();
					++this.stepNumber;
					this.step = this.tour.steps[this.stepNumber];
					this.display();
				}, this.dur_in);

			}
		}
	}


	/*
		Tourを終了する
	*/
	public finish() {

		this.removeClassToTarget();

		/*
			追加したすべてのクラスを削除
		*/
		this.addedClass.forEach( (item) => {
			item.target.removeClass(item.addedClass);
		});

		tourModule.updateFinish(this.tour.name);
		tourModule.updateActive(false);

		this.stepTotal = 0;
		this.stepNumber = 0;
		this.isBg = false;
		this.isPop = false;
		this.isMask = false;
		this.isCursor = false;
		this.firstAnimationStep = false;
		this.addedClass = [];

	}


	/*
		BUTTON
	*/
	public onFinish() {
		this.finish();
	}


	public onNext() {
		this.next();
	}

	public get deviceSize(): any {
    	return appModule.deviceSize;
	}

}
