import {Component, Prop, Vue} from "vue-property-decorator";

@Component({
    components: {
    }
})
export default class CMenuItem extends Vue {


    @Prop({default: ''})
	public title: string;


    @Prop({default: 0})
	public value: number;

    // ~ (以上) をつけるかどうか
    @Prop({default: false})
	public over: boolean;


    @Prop({default: ''})
	public comment: string;



}



