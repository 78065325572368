































































	// @ts-ignore
    import _ from './MGlobalFooter.ts'; export default _;
