import {Component, Vue} from 'nuxt-property-decorator';
import {themeMainModule} from '@/store/theme/theme-main';
import CMix from '@/classes/theme/mixin/c-mix';

import CPageContactConfirmStandard from '@/components/theme/page/contact/confirm/CPageContactConfirmStandard.vue';
import OsMix from '@/classes/mixin/os-mix';

@Component({
    mixins: [OsMix],
    components: {
      CPageContactConfirmStandard,
    }
})
export default class CConfirm extends Vue {


	public title: string;


    ///////// 全ページ共通 ////////////////////////////////////////////
    public page: any = {
		type: 'page',
		page: 'contact',
		depth: 'confirm',
    };


    // SSRでサーバーサイドの処理から変数をセット
    public async asyncData(context: any): Promise<any> {
        return {
            title: 'Indexa',
        }
    }


    // ヘッダタグの構成要素
    public head() {
        return {
            title: this.title
        }
    }


    public created() {

	    //PAGE情報をSTOREにセット
		this!['updateMyPage'](this.page, themeMainModule);

    }

  
	public mounted() {
		this!['init']();
	}


}
