




















































































































































































































































































































































import {Component} from "nuxt-property-decorator";
import RoutingUtil from "@/classes/view/routing-util";
import AAccount from '@/classes/page/a-account';
import {accountModule} from '@/store/account';
import {appModule} from '@/store/app';

@Component({
    components: {

    }
})
export default class MDomainsDetail extends AAccount {


	public state: string = accountModule.dev_domainStates;

}

