import {IApiBehavior} from "./i-api-behavior";
import {IApiRequest} from "./i-api-request";
import {ApiMethod} from "./api-method";
import axios, {AxiosInstance, AxiosRequestConfig} from 'axios';
import ApiUtil from "./api-util";

export default class AxiosBehavior implements IApiBehavior {

    private _axios: AxiosInstance;

    constructor() {
        this._axios = axios.create({
            withCredentials: true,
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        });
    }

    access(req: IApiRequest): void {
        console.log("access()", req);
        switch (req.method) {
            case ApiMethod.Get:
                this.getAccess(req);
                break;
            case ApiMethod.Post:
                this.postAccess(req);
                break;
            case ApiMethod.Put:
                this.putAccess(req);
                break;
            case ApiMethod.Patch:
                this.patchAccess(req);
                break;
            case ApiMethod.Delete:
                this.deleteAccess(req);
                break;
            default:
                throw "Method spec error!";
        }
    }


    getAccess(req: IApiRequest): void {
        this._axios.get(req.url, {
            params: req.param
        })
            .then((res: any) => {
                req.cbThen(res);
                req.cbAll(res);
            })
            .catch((res: any) => {
                req.cbCatch(res);
                req.cbAll(res);
            });
    }

    postAccess(req: IApiRequest, method: string = "post"): void {
        let params = ApiUtil.request2Param(req);
        this._axios[method](req.url, params)
            .then((res: any) => {
                req.cbThen(res);
                req.cbAll(res);
            })
            .catch((res: any) => {
                req.cbCatch(res);
                req.cbAll(res);
            });
    }

    putAccess(req: IApiRequest): void {
        this.postAccess(req, "put");
    }

    patchAccess(req: IApiRequest): void {
        this.postAccess(req, "patch");
    }

    deleteAccess(req: IApiRequest): void {
        this.postAccess(req, "delete");
    }
}
