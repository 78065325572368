import {Component, Vue} from 'vue-property-decorator';
import {themeMainModule} from '@/store/theme/theme-main';
import {editModule} from '@/store/edit';
import {ScrollUtil} from '@/classes/view/scroll-util';

import {DummyPageHome} from '@/configs/dummy/dummy-page-home';
import {DummyPageGallery} from '@/configs/dummy/dummy-page-gallery';
import {DummyPageStaff} from '@/configs/dummy/dummy-page-staff';
import {DummyPageStore} from '@/configs/dummy/dummy-page-store';
import {DummyPageMenu} from '@/configs/dummy/dummy-page-menu';
import {DummyPageBlog} from '@/configs/dummy/dummy-page-blog';
import {DummyPageNews} from '@/configs/dummy/dummy-page-news';
import {DummyPageContact} from '@/configs/dummy/dummy-page-contact';
import {osIndexModule} from '@/store/os';
import {IOsHomeContent} from '@/classes/domain/module/os/home/i-os-home-content';
import VarUtil from '@/classes/utils/var-util';
import {DevUtil} from "@/classes/utils/dev-util";

@Component
export default class CMix extends Vue {

    /*
      全ページ共通
    */
    public init() {

        /*
            Scroll Resize
        */
        this.$nextTick(function () {
            this.$nextTick(function () {
                this.$nextTick(function () {
                    this.$nextTick(function () {
                        setTimeout(
                            () => {
                                //ScrollUtil.resize();
                            }, 800);
                    });
                });
            });
        });
    }

    //PAGE情報をSTOREにセット
    public updateMyPage(page: any, themeMainModule: any, values: any = null) {

        console.log('cMix.updateMyPage() **********************', page);

        //基本情報
        themeMainModule.updatePageType(page.type);
        themeMainModule.updatePagePage(page.page);
        themeMainModule.updatePageDepth(page.depth);

        /*
        自分のページデータをSTOREにいれる
        DummyPageHomeなど
        */
        switch (page.page) {

            case 'blog':
                themeMainModule.updatePageData(DummyPageBlog);
                break;

            case 'gallery':
                themeMainModule.updatePageData(DummyPageGallery);
                break;

            case 'staff':
                themeMainModule.updatePageData(DummyPageStaff);
                break;

            case 'menu':
                themeMainModule.updatePageData(DummyPageMenu);
                break;

            case 'store':
                themeMainModule.updatePageData(DummyPageStore);
                break;

            case 'news':
                themeMainModule.updatePageData(DummyPageNews);
                break;

            case 'contact':
                themeMainModule.updatePageData(DummyPageContact);
                break;

            case 'index':
                const c = osIndexModule.content as IOsHomeContent;

                DevUtil.log('cMix.updateMyPage > page index > c', c);
                // console.log('page index > c2', DummyPageHome);

                themeMainModule.updatePageData(c);
                themeMainModule.updateHomeBlock(Array.from(c.block));
                themeMainModule.updateHomeBlockData(Array.from(c.blockData));

                // themeMainModule.updatePageData(DummyPageHome);
                // themeMainModule.updateHomeBlock(DummyPageHome.block);
                // themeMainModule.updateHomeBlockData(DummyPageHome.blockData);

                break;

            // 404 /////////////////////////////////////////////////////////////
            default:
                break;
        }
    }

    /*
      LAYOUT COMPONENTを指定する
      pageData: {
        depth: {
          index : CIndexListなど
          detail : CGalleryDetailStandard など
        }
      }
    */
    public get component(): any {

        if (!themeMainModule.pageData) {
            return null;
        }

        console.log('component()1 > ', themeMainModule.pageData);
        console.log('component()2 > ', themeMainModule.pageData.pattern[themeMainModule.pageDepth]);
        return themeMainModule.pageData.pattern[themeMainModule.pageDepth];
    }

    //PAGE TITLE
    public get pageTitle(): any {
        return themeMainModule.pageData.name;
    }

    /*
        for CPreviewBox
        ManagerのときとTransitionをきりかえ
    */
    public get stransition(): any {

        if (editModule.editTransition) {
            return editModule.editTransition;
        }

        return 'ct-change';

    }

}
