import {Mutation, MutationAction, Action, VuexModule, getModule, Module} from 'vuex-module-decorators';
import store from '@/classes/core/store';

// state's interface
export interface IAppStore {

    activeIndex: number;
    total: number;
 }

@Module({dynamic: true, store, name: 'layoutSelect', namespaced: true})
class App extends VuexModule implements IAppStore {

    public activeIndex: number = 0;
    public total: number = 0;

    @Mutation
    public updateActiveIndex(value: number) {
        this.activeIndex = value;
    }

    @Mutation
    public prevActiveIndex() {
        if (0 < this.activeIndex) {
            this.activeIndex--;
//             console.log(this.activeIndex);
        }
    }

    @Mutation
    public nextActiveIndex() {
        if ((this.total - 1) > this.activeIndex) {
            this.activeIndex++;
            //console.log(this.activeIndex);
        }
    }

    @Mutation
    public updateTotal(value: number) {
        this.total = value;
    }
}

export const layoutSelectModule = getModule(App);
