import {Component, Prop, Vue} from '@/node_modules/nuxt-property-decorator';

@Component({
	components: {
	}
})
export default class MHelpFrame extends Vue {
    
    
    @Prop({default: 'close'})
    public i: string;
    
    
}
