










	// @ts-ignore
    import _ from './MHeader.ts'; export default _;
