import {Component, Watch, Prop, Vue} from '@/node_modules/nuxt-property-decorator';

@Component
export default class MPopupMBody extends Vue {
    
    @Prop({default: ''})
    public title: string;
	
    @Prop({default: ''})
    public titleicon: string;
}
