export const ConfigManagerAction = {
    // Loader
    Loader: {
        SemiAuto: 'loader.semiAuto',
    },

    // Popup関係　
    Popup: {
        Confirm: {
            Ok: 'popup.confirm.ok',
            Cancel: 'popup.confirm.cancel',
        },
        // 定休日設定
        StoreHoliday: {
            Save: 'popup.storeHoliday.save',
        },
        StoreWorktime: {
            Save: 'popup.storeWorktime.save',
        }
    },
};
