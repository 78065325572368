import {Action, getModule, Module, Mutation, VuexModule} from "@/node_modules/vuex-module-decorators";
import store from "@/classes/core/store";
import ManagerApi from "@/classes/app/api/manager-api";
import {appAuthModule} from "@/store/app/auth";
import VarUtil from "@/classes/utils/var-util";
import {$apm} from "@/classes/app/app-manager";

export interface IBlog {
    toid: string;
    title: string;
    body: string;
    is_active: string;
    related_twitter: number;
    related_facebook: number;
    published_at: string;
    created_at: string;
    updated_at: string;
    categories?: string[];
}

export interface IBlogFilter {
    type?: string;
    status?: number;
    word?: string;
    date?: string;
}

export interface IManagerBlogModule {
    fetched: boolean;
    filter: any;
    page: number;
    info: any;
    records: IBlog[];
}

@Module({dynamic: true, store, name: 'managerBlog', namespaced: true})
class Store extends VuexModule implements IManagerBlogModule {

    public fetched: boolean = false;
    public filter: any = {
        type: 'blog',
        word: '',
        status: null,
        date: '',
    };
    public page: number = 1;
    public info: any;
    public records: IBlog[] = [];

    @Mutation
    public updateRecords(value: IBlog[]) {
        this.records = value;
    }

    @Mutation
    public updateFilter(filter: IBlogFilter) {
        this.filter = Object.assign({}, M.filter, filter);
    }

    @Mutation
    public updateArticle(value: IBlog) {
        this.records = Array.from(this.records).map((_: IBlog) => {
            return (_.toid === value.toid) ?
                Object.assign({}, _, value) : _;
        });
    }

    @Mutation
    public updateFetched(fetched: boolean) {
        this.fetched = fetched;
    }

    @Action
    public async resetFilter() {
        M.updateFilter({
            status: 0,
            word: '',
            date: '',
        });
    }

    @Action
    public async fetch(force: boolean = false): Promise<any> {

        console.log('store/blog.ts > fetch() > ', M.fetched);

        if (!force && M.fetched) {
            return;
        }

        try {
            const records = await ManagerApi.BlogArticle.get(Object.assign({
                site: $apm.siteid,
                page: M.page
            }, M.filter)).execAsync('records.list');

            // console.log('managerBlogModule.fetch() > records > ', records);

            M.updateRecords(records || []);
            M.updateFetched(true);

            return null;
        } catch (e) {
            console.error(e);
            return e;
        }
    }
}

export const managerBlogModule = getModule(Store);

const M = managerBlogModule;
