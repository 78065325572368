import {Component, Vue} from 'vue-property-decorator';
import {themeMainModule} from '@/store/theme/theme-main';

@Component
export default class CNavigationMix extends Vue {

    public get projectNavigationPage(): any {
        return themeMainModule.projectNavigationPage;
    }

    public get projectNavigationPageSpecial(): any {
        return this.projectNavigationPage.filter( function(element, index, array) {
            return element.category == 'special';
        });
    }

    public get projectNavigationSns(): any {
        return themeMainModule.projectNavigationSns;
    }

    public get projectNavigationPagePrimary(): any {
        return this.projectNavigationPage.filter( function(element, index, array) {
            return element.category == 'primary';
        });
    }

    public get projectNavigationPageSecondary(): any {
        return this.projectNavigationPage.filter( function(element, index, array) {
            return element.category == 'secondary';
        });
    }

//     public get projectNavigationPageThird(): any {
//         return this.projectNavigationPage.findByKey('category', 'third' );
//     }
}
