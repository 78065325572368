import {Component, Prop, Vue} from 'vue-property-decorator';
import {themeMainModule} from '@/store/theme/theme-main';

@Component({
    components: {
    }
})
export default class CBlockTitle extends Vue {

    @Prop({default: 'Block Title Here'})
    public t: string;

    public get isMe(): any {
        return themeMainModule.pageType == 'index' ? true : false;
    }

}

