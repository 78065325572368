import { render, staticRenderFns } from "./MPolicyFrame.vue?vue&type=template&id=727ca4b4&scoped=true&"
import script from "./MPolicyFrame.vue?vue&type=script&lang=ts&"
export * from "./MPolicyFrame.vue?vue&type=script&lang=ts&"
import style0 from "./MPolicyFrame.vue?vue&type=style&index=0&id=727ca4b4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "727ca4b4",
  null
  
)

export default component.exports