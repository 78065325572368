import {Component, Watch, Prop, Vue} from "vue-property-decorator";
import {themeMainModule} from '@/store/theme/theme-main';
import {themeStateModule} from '@/store/theme/theme-state';

import {DummyBlog} from '@/configs/dummy/dummy-blog';

import * as $ from 'jquery';

@Component({
    components: {
    }
})
export default class CSidecoverTopics extends Vue {


	//Blogの最新を表示する
	public topics: any = DummyBlog;
	public topicslist: any = this.topics.slice(1,5);

	public get mainFrameResized(): any {
	    return themeMainModule.mainFrameResized;
	}

	public get otopicsLatest(): any {
		return this.topics[0];
	}

	public onClose() {
		themeStateModule.updateSidecoverDisplay(false);
	}


}



