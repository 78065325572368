import AApi from '@/classes/core/api/a-api';
import App from '@/classes/app/app';
import {HostUtil} from '@/classes/app/host-util';
import WUtil from '@/classes/view/w-util';
import {Env} from "@/classes/app/env";

export class AOsApi extends AApi {
  /**
   *
   */
  protected _apiDomain: string = Env.API_HOST_SSR;

  /**
   *
   */
  public get url(): string {
    return ((WUtil.isBrowser) ? '' : this._apiDomain)
      + '/api/v1/ownersite/'
      + HostUtil.filter()
      + this._url;
  }
}
