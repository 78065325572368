/*
	
	YH: Data Spread Sheet is Here
	https://docs.google.com/spreadsheets/d/1GP-wc5RwVL54UcYqxjH_ZfCQWY7IAfuL_BSC2SXCL8s/edit?usp=sharing

*/

export const DummyArticleCategory = [
	{
    	id: '0',
    	name: 'All',
    },
	{
    	id: '1',
    	name: 'Long',
    },
	{
    	id: '2',
    	name: 'Hair hair ',
    },
	{
    	id: '3',
    	name: 'Short',
    },
	{
    	id: '4',
    	name: 'Semi Long',
    },
	{
    	id: '5',
    	name: 'Mens',
    },
	{
    	id: '6',
    	name: 'Very Short',
    },
	{
    	id: '7',
    	name: 'Long',
    },
	{
    	id: '8',
    	name: 'Hair hair ',
    },
	{
    	id: '9',
    	name: 'Short',
    },
	{
    	id: '10',
    	name: 'Semi Long',
    },
	{
    	id: '11',
    	name: 'Mens',
    },
	{
    	id: '12',
    	name: 'Very Short',
    },
];