import {Component, Vue, Prop} from '@/node_modules/nuxt-property-decorator';

@Component({
    components: {
    }
})
export default class CSelectSnsList extends Vue {
  
    @Prop({default: []})
    public d: any;
    
    	    		
   	/////// 共通処理 /////////////////////////////////
    ////////// POPUP LAYER 共通処理 /////////////////////////////////
	public onClose(to: string=''): any {
		if(to != ''){
			this.$router.push(to);
		}
        this.$emit('close');
	}
	
	
}
