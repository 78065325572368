/*

    ページの管理で使用
    /manager/project/page/

*/

export const DummyNavCategory = [

    {
        no: 1,
    	id: 'primary',
    	label: 'OUR SALON',
    },
    {
        no: 2,
    	id: 'secondary',
    	label: 'INFO',
    },
    {
        no: 3,
    	id: 'special',
    	label: 'OTHER',
    },
    {
        no: 4,
    	id: 'sns',
    	label: 'FOLLOW',
    },

];
