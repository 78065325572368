import {Component, Watch, Prop, Vue} from "vue-property-decorator";
import {themeMainModule} from '@/store/theme/theme-main';

import CLink from '@/components/theme/module/CLink.vue';

@Component({
    components: {
	    CLink,
    }
})
export default class CIconlinkList extends Vue {

	@Prop({default: () => {} })
	public d: any;
	
	/*
		最大数
	*/
	@Prop({default: 0})
	public max: number;
	
	
	public get odata(): any {
	
		if(this.max != 0) {			
			return this.d.slice(0,this.max);			
		}
		return this.d;
		
	}	
	
}



