import {Mutation, MutationAction, Action, VuexModule, getModule, Module} from 'vuex-module-decorators';
import store from '@/classes/core/store';

// state's interface
export interface IEditModule {

    current: any;
    lock: boolean;
    forceActive: boolean;
    switchedComponent: string;

	/*
		id: pagekey-data
		edit: 'manager-project',
		post: 'manager-project-blog',
	*/
    editTransition: string;
    layoutChangeAnimation: boolean;

}

@Module({dynamic: true, store, name: 'edit', namespaced: true})
class Edit extends VuexModule implements IEditModule {

    public current: any = null;

    //Click時に一定時間反応しないようにするためのスイッチ
    public lock: boolean = false;

    //
    public forceActive: boolean = false;

    // UIでレイアウト（Component）を変更したらスイッチする
    public switchedComponent: string = '';


    public editTransition: string = '';
    public layoutChangeAnimation: boolean = false;


    @Mutation
    public clearCurrent() {
        this.current = null;
    }

    @Mutation
    public updateCurrent(current: any = null) {
        this.current = current;
    }

    @Mutation
    public updateLock( value: boolean ) {
        this.lock = value;
    }

    @Mutation
    public updateForceActive( value: boolean ) {
        this.forceActive = value;
    }

    @Mutation
    public updateSwitchedComponent( value: string ) {
        this.switchedComponent = value;
    }

    @Mutation
    public clearSwitchedComponent() {
        this.switchedComponent = null;
    }

    @Mutation
    public updateEditTransition(value: string) {
        this.editTransition = value;
    }

    @Mutation
    public updateLayoutChangeAnimation(value: boolean) {
        this.layoutChangeAnimation = value;
    }
}

export const editModule = getModule(Edit);

