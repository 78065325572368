import {Country, Lang} from "@/classes/app/app-const";
import {ConfigAppPrefectures} from "@/configs/app/prefectures";
import {$v} from "@/classes/utils/var-util";
import {ArrayUtil} from "@/classes/utils/array-util";

export class AddressUtil {
    /**
     * @param country
     * @param prefcode
     * @param lang
     */
    public static prefecture(country: Country, prefcode: string, lang: Lang = Lang.Ja): string {

        const src = ConfigAppPrefectures.find((_: any) => {
            return (_.lang === lang && _.country === country);
        });

        // console.log('AddressUtil > ', src);
        if (!src) {
            return '';
        }

        return $v.tap(ArrayUtil.findByKey(src.prefectures, 'value', prefcode), (p: any) => {
            return $v.p(p, 'label');
        });
    }
}
