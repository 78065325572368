import {Watch, Component, Prop, Vue} from '@/node_modules/nuxt-property-decorator';
import {appModule} from '@/store/app';
import {wizardModule} from '@/store/wizard';

import {WizardMake} from "@/configs/wizard-make";
import {WizardStart} from "@/configs/wizard-start";

import MFooterWiz from '@/components/part/wizard/MFooterWiz.vue';

@Component({
    components: {
        MFooterWiz,
    }
})
export default class MSecondaryWiz extends Vue {

    public styleScale: any = {};

    @Watch('mainFrameWidth')
    public watchMainFrameWidth() {
        console.log('MSecondaryWiz > watchMainFrameWidth() ++++++++++++++++++++++++++++++++++++++++++++');
        this.resizeScreen();
    }

    public async mounted() {
        console.log('MSecondaryWiz > mounted()  ++++++++++++++++++++++++++++++++++++++++++++');
        console.log('wizardModule.secondary = ', wizardModule.secondary);
        this.$nextTick(() => {
            this.resizeScreen();
        });
    }

    // Computed //////////////////////////////////////////////////////////////////
    public get goodImg(): any {
        return wizardModule.good ?
            '/manager/wizard/badge-' + wizardModule.good + '.svg' :
            '/manager/wizard/badge-ok.svg';
    }

    public get isGood(): any {
        return wizardModule.good;
    }

    public get isSecondaryFrameWeb(): any {
        return wizardModule.secondary == 'web';
    }

    public get p(): any {
        return wizardModule.secWebPage;
    }

    public get mainFrameWidth(): any {
        return appModule.mainFrameWidth;
    }


    // Methods //////////////////////////////////////////////////////////////////

    public resizeScreen() {

        const screenWidth: number = $(this.$refs.screen).outerWidth();

        this.styleScale = {
            transform: `scale(${screenWidth / 1024})`,
        };
    }

    // Events ///////////////////////////////////////////////////////////
    public async onMountedPreviewFrame() {
        console.log('MSecondaryWiz > onMountedPreviewFrame');
        this.resizeScreen();
    }
}

