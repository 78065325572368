import {Action, getModule, Module, Mutation, VuexModule} from "@/node_modules/vuex-module-decorators";
import store from "@/classes/core/store";
import ManagerApi from "@/classes/app/api/manager-api";
import VarUtil from "@/classes/utils/var-util";
import ApiUtil from "@/classes/core/api/api-util";
import {$apm} from "@/classes/app/app-manager";

export interface IShopMenu {
    id: number;
    toid: string;
    category_id: number;
    title: string;
    body: string;
    is_active: string;
    published_at: string;
    created_at: string;
    updated_at: string;
    categories?: string[];
}

export interface IShopMenuFilter {
    type?: string;
    status?: number;
    word?: string;
    date?: string;
}

export interface IManagerShopMenuModule {
    fetched: boolean;
    filter: any;
    page: number;
    info: any;
    records: IShopMenu[];
}

@Module({dynamic: true, store, name: 'managerShopMenu', namespaced: true})
class Store extends VuexModule implements IManagerShopMenuModule {

    public fetched: boolean = false;
    public filter: any = {
        type: 'blog',
        word: '',
        status: null,
        date: '',
    };
    public page: number = 1;
    public info: any;
    public records: IShopMenu[] = [];

    @Mutation
    public updateRecords(value: IShopMenu[]) {
        this.records = value;
    }

    @Mutation
    public updateFilter(filter: IShopMenuFilter) {
        this.filter = Object.assign({}, M.filter, filter);
    }

    @Mutation
    public updateArticle(value: IShopMenu) {
        this.records = Array.from(this.records).map((_: IShopMenu) => {
            return (_.toid === value.toid) ?
                Object.assign({}, _, value) : _;
        });
    }

    @Mutation
    public updateFetched(fetched: boolean) {
        this.fetched = fetched;
    }

    @Action
    public async resetFilter() {
        M.updateFilter({
            status: 0,
            word: '',
            date: '',
        });
    }

    @Action
    public async fetch(force: boolean = false): Promise<any> {

        if (!force && M.fetched) {
            return;
        }

        try {
            const records = await ManagerApi.ShopMenu.get({
                site: $apm.pj.toid,
            }).execAsync('records.list');

            // console.log('managerShopMenuModule.fetch() > records > ', records);

            M.updateRecords(records || []);
            M.updateFetched(true);

            return null;
        } catch (e) {
            return e;
        }
    }

    @Action
    public async save(record: any): Promise<any> {
        console.log('save > ', record);
        return await ApiUtil.post(ManagerApi.ShopMenu, record);
    }
}

export const managerShopMenuModule = getModule(Store);

const M = managerShopMenuModule;
