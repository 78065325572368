import {Component, Prop, Vue} from 'nuxt-property-decorator';
import {themeMainModule} from '@/store/theme/theme-main';
import CPatternMix from '@/classes/theme/mixin/c-pattern-mix';
import CStyleListMix from '@/classes/theme/mixin/style/c-style-list-mix';

import {DummyArticleCategory} from '@/configs/dummy/dummy-article-category';
import {DummyBlog} from '@/configs/dummy/dummy-blog';

import * as $ from 'jquery';

@Component({
	mixins: [
	    CPatternMix,
	    CStyleListMix,
	],
    components: {
    }
})
export default class CPageBlogIndexStandard extends Vue {

    public pagePostNumber: number = 20;
    public list: any = DummyBlog.slice(0, this.pagePostNumber);
    public classBorder: any = [];

    //ACTIVE CATEGORY
    public activeId: string = '0';

    /*
        PAGE TITLE
    */
    public get pageId(): any {
        return themeMainModule.pageData.id;
    }

    /*
        Category Dataを取得
    */
    public get categories(): any {
        return DummyArticleCategory;
    }
}



