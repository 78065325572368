import {
    ApiExecuterAsync,
    ApiExecuterFactory,
    ApiMethod,
    ApiUtil
} from '@/classes/core/api/import';

export default class PostCode {

    public get(param: any = {}): ApiExecuterAsync {

        let url = ApiUtil.url(`/postcode/${param.country}/${param.postcode}`);
        let request = ApiExecuterFactory.createRequest({
            method: ApiMethod.Get,
            url: url,
            param: null,
        });

        return ApiExecuterFactory.createAsync(request);
    }
}
