
























































	// @ts-ignore
    import _ from './CMenuPc.ts'; export default _;
