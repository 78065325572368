import {Component, Prop, Vue} from "vue-property-decorator";
import {themeMainModule} from '@/store/theme/theme-main';

import {DummyGallery} from '@/configs/dummy/dummy-gallery';

@Component({
    components: {
    }
})
export default class CMvContentGallery extends Vue {

    public static self: CMvContentGallery;

    public list: any = DummyGallery;
    public slides: any = this.list.slice(0,5);


    public mounted() {

        CMvContentGallery.self = this;

	    /*
		    いきなりInitするとおかしくなるのであとで動かす
	    */
        setTimeout(
            () => {
				this!['slide'].init();
            }, 50);

    }


    //Swiper
    public swiperOption: any = {

	    init: false,
        loop: true,
        slidesPerView: 'auto',
		parallax: true,
        effect: 'fade',
        speed: 540,
        centeredSlides: true,
        spaceBetween: 0,
        pagination: {
            el: '.swiper-pagination',
            clickable: true
        },
        on: {
	        init: function () {

	            if( !CMvContentGallery.self ){
		            return;
	            }

	        },
            slideChange: function () {

	            if( !CMvContentGallery.self ){
		            return;
	            }

            },
            tap() {
            }
        },
        navigation: {
            nextEl: '.mv-swiper-r',
            prevEl: '.mv-swiper-l'
        }
    };


}

