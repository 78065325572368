import {Component, Prop, Vue} from "vue-property-decorator";
import ACBlock from '@/classes/theme/class/a-c-block';
import {themeMainModule} from '@/store/theme/theme-main';
import CBlockMix from '@/classes/theme/mixin/c-block-mix';

import {DummyBlog} from '@/configs/dummy/dummy-blog';
import {DummyNews} from '@/configs/dummy/dummy-news';

@Component({
    mixins: [CBlockMix],
    components: {
    }
})
export default class CBlockBlogNews extends ACBlock {


	/*
		処理用変数
	*/
	public data1: any = [];
	public data2: any = [];
	public isUse: boolean = true;
	public blogData: any = DummyBlog;
	public newsData: any = DummyNews;

	public created() {

        /*
            DummeHomeのデータから指定Blockのデータを取得
            設定画面: manager/project/home/
        */
        this.init(this.k);


		/*
			BLOGがあればBLOG表示
			なければNEWSを表示
		*/
		//BLOGがある
		if(this.blogData.length > 0) {
			this.data1 = this.blogData;
			if(this.newsData.length > 0) {
				this.data2 = this.newsData;
			}else{
				this.data2 = this.blogData;
			}

		//BLOGがない NEWSがある
		}else if(this.newsData.length > 0) {
			this.data1 = this.newsData;
			this.data2 = this.newsData;

		//どっちもない
		}else{
			//非表示にする
			this.isUse = false;
		}

	}


	/*

		Component処理

	*/
	public get isData2(): any {
		if(this.data2[0]){
			return true;
		}
		return false;
	}

	public get sblogTitle(): any {
		return this.data1[0].title;
	}

	public get sblogBody(): any {
		return this.data1[0].body;
	}

	public get sblogImg(): any {
		return this.data1[0].img[0];
	}

	public get onews(): any {

		let d: any = [];

		if(themeMainModule.deviceSize != 'mb'){
    		d = this.data2.slice(0,4);
		}else{
    		d = this.data2.slice(0,3);
		}
		return d;
	}

	public get sblogPageName(): any {
    	const d = themeMainModule.projectNavigationPage.findByKey('id', 'blog' );
    	if( !d ){
	    	return 'ErBlog';
    	}
		return d.label;
	}

	public get snewsPageName(): any {
    	const d = themeMainModule.projectNavigationPage.findByKey('id', 'news' );
    	if( !d ){
	    	return 'ErNews';
    	}
		return d.label;
	}

	public get pageTitle(): any {

    	// PCの時
    	if (themeMainModule.deviceSize != 'mb') {

    		//BLOG & NEWSある
    		if(this.blogData.length > 0 && this.newsData.length > 0) {
        		return `${this.sblogPageName} and ${this.snewsPageName}`;

    		//BLOGがある
    		} else if(this.blogData.length > 0) {
        		return `${this.sblogPageName}`;

    		//BLOGがない NEWSがある
    		}else if(this.newsData.length > 0) {
        		return `${this.snewsPageName}`;

    		//どっちもない
    		}else{
        		return `Topics`;
    		}


    	//MBの時
    	} else {
    		return this.snewsPageName;
    	}

        return `Topics`;
	}

}

