import {Component, Watch, Prop, Vue} from "vue-property-decorator";
import {themeMainModule} from '@/store/theme/theme-main';

import {DummyProject} from '@/configs/dummy/dummy-project';
import {DummyBlog} from '@/configs/dummy/dummy-blog';
import {DummyNews} from '@/configs/dummy/dummy-news';

import CLink from '@/components/theme/module/CLink.vue';
import CSlotNav from '@/components/theme/module/CSlotNav.vue';

import * as $ from 'jquery';


@Component({
    components: {
	    CLink,
	    CSlotNav,
    }
})
export default class CInfoNavigation extends Vue {


    @Prop({default: true})
    public info: boolean;

	@Watch('mainFrameResized')
	public watchMainFrameResized() {
	    this.resize();
	}

	public data: any = [];
	public isUseArticles: boolean = true;
	public styleFrame: any = {};

    public created() {

	    //Newsがある
	    if(DummyNews.length > 0) {
		    this.data = DummyNews;

	    //Newsがない BLOGある
	    }else if(DummyBlog.length > 0) {
		    this.data = DummyBlog;

		//どっちもない場合非表示
	    }else{
		    this.isUseArticles = false;
	    }
    }

    public mounted() {
	    this.init();
    }

    public init() {
	    this.resize();
    }

	public get mainFrameResized(): any {
	    return themeMainModule.mainFrameResized;
	}

	public resize() {

		if(themeMainModule.deviceSize == 'pc' || themeMainModule.deviceSize == 'tablet') {

		    if(themeMainModule.manager) {

				this.styleFrame = {};

		    }

		}else if(themeMainModule.deviceSize == 'mb') {

		    if(themeMainModule.manager) {

				this.$nextTick(() => {
    	        setTimeout(
    	            () => {

    				    const frameH: number = $('.m-preview-frame').outerHeight() | 580;
    				    const navH: number = $(this.$refs.mbNavHeader).outerHeight() | 48;

    					this.styleFrame = {
    						top: `${this.mainFrameOffset.top + frameH - navH}px`,
    						width: `${themeMainModule.mainFrameWidth - 2}px`,
    						bottom: 'auto'
    					}

    	            }, 600);
				});

		    }
		}
	}

    public get hasMap(): boolean {
        return !!this.oinfoMap;
    }

    public get odata(): any {
	    return this.data.slice(0,5);
    }

    public get oinfo(): any {
	    return this.info;
    }

    public get oinfoMap(): any {
	    return themeMainModule.mainStoreMap;
    }

    public get oinfoTel(): any {
        const value = themeMainModule.mainStoreTel.replace(/-/g, ' ');
	    return value;
    }

    public get oinfoReservationName(): any {
	    return themeMainModule.mainStoreReservation.name;
    }

    public get oinfoReservationLink(): any {
	    return themeMainModule.mainStoreReservation.link;
    }

    /*
        GET DEVICE SIZE
        pc, tablet, mb
    */
	public get deviceSize(): any {
		return themeMainModule.deviceSize;
	}

	public get mainFrameOffset(): any {
    	return themeMainModule.mainFrameOffset;
	}
}

