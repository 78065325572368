






























































	// @ts-ignore
    import _ from './CPageContactConfirmStandard.ts'; export default _;
