import {AExt} from "@/classes/ext/a-ext";
import {$v} from "@/classes/utils/var-util";
import {appAuthModule} from "@/store/app/auth";
import {AuthManager} from "@/classes/app/auth-manager";
import {EnvUtil} from "@/classes/app/env-util";

export abstract class AExtAuth extends AExt {

    // Computed ////////////////////////////////////////////////////
    //
    public get isLogin(): boolean {
        return AuthManager.isLogin();
    }

    // Methods ///////////////////////////////////////////////////////
    public async init() {
        await appAuthModule.load();
    }

    public async login() {
        // @Todo
    }

    public async logout(to: string = '/') {
        await appAuthModule.logout();
        window.location.href = to; // this.vue.$router.push(to);
    }
}
