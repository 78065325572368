import {sprintf} from "sprintf-js";

export class TimeUtil {

    public static hm2t(hour: number, minute: number = 0): number {
        return (60 * hour) + minute;
    }

    public static m2hmL(min: number, zpad: boolean = false): string {
        const tmplH = zpad ? '%02s' : '%s';
        return sprintf(`${tmplH}:%02s`,
            ((min > 0) ? Math.floor(min / 60) : 0),
            (min % 60)
        );
    }
}
