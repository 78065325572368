import {Component, Vue} from 'vue-property-decorator';
import {themeMainModule} from '@/store/theme/theme-main';

import * as $ from 'jquery';

@Component
export default class CStyleListMix extends Vue {

    /*
        縦のボーダーをいれる
    */
    public styleBorderList(component: any) {

        /*
            ボーダーを描画
        */
        for (let i: number = 0; i < component.$data['list'].length; i++) {

            let cls: string = '';
            let me: number = 0;
            let prev: number = 0;
            let next: number = 0;

            if (i > 0) {
                prev = $('.-list--border .c-list-item').eq(i-1).outerHeight();
            }

            me = $('.-list--border .c-list-item').eq(i).outerHeight();

            if (i < component.$data['list'].length - 1) {
                next = $('.-list--border .c-list-item').eq(i+1).outerHeight();
            }


            //次の高さのほうが高い
            if (prev < me) {
                cls += '-border--left';
            }

            //自分のほうが高い
            if (me > next) {
                cls += ' -border--right';

            } else if (me == next) {
                cls += ' -border--right';
            }

            console.log(`${i}: ${cls} | ${me} : ${next}`);

            component.$data['classBorder'].push(cls);

        }

    }


}
