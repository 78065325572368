import {
    ApiExecuterAsync,
    ApiExecuterFactory,
    ApiMethod,
    ApiUtil
} from '@/classes/core/api/import';
import {vsprintf} from 'sprintf-js';
import ApiConst from '@/classes/core/api/api-const';

export default class FileSign {

    public store(file: any): Promise<any> {
        let url = ApiUtil.url('/file/sign');
        let request = ApiExecuterFactory.createRequest({
            method: ApiMethod.Post,
            enctype: ApiConst.EncType.Multipart,
            url: url,
            param: file
        });

        return ApiExecuterFactory.createAsync(request).execAsync();
    }

    public put(url: string, file: any): Promise<any> {
        let request = ApiExecuterFactory.createRequest({
            method: ApiMethod.Put,
            enctype: ApiConst.EncType.Multipart,
            url: url,
            param: file
        });

        return ApiExecuterFactory.createAsync(request).execAsync();
    }
}
