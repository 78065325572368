import {Mutation, MutationAction, Action, VuexModule, getModule, Module} from 'vuex-module-decorators';
import store from '@/classes/core/store';

// state's interface
export interface IPriceMachineModule {

    storeNumber: number;
    period: string;
    base: number;
    each: number;

    total: number;
    hour: number;
    day: number;
    month: number;
    year: number;
}

@Module({dynamic: true, store, name: 'priceMachine', namespaced: true})
class PriceMachine extends VuexModule implements IPriceMachineModule {


    public storeNumber: number = 1;
    public period: string = 'year'; // month / year
    public base: number = 1580;
    public each: number = 1100;

    //for display
    public total: number = 0;
    public hour: number = 0;
    public day: number = 0;
    public month: number = 0;
    public year: number = 0;

    public duration: number = 0.4;


    @Mutation
    public updateStoreNumber(value: number) {
        this.storeNumber = value;
    }

    @Mutation
    public updatePeriod(value: string) {
        this.period = value;
    }

    @Mutation
    public updateBase(value: number) {
        this.base = value;
    }

    @Mutation
    public updateEach(value: number) {
        this.each = value;
    }

    @Mutation
    public updateTotal(value: number) {
        this.total = value;
    }

    @Mutation
    public updateHour(value: number) {
        this.hour = value;
    }

    @Mutation
    public updateDay(value: number) {
        this.day = value;
    }

    @Mutation
    public updateYear(value: number) {
        this.year = value;
    }
}

export const priceMachineModule = getModule(PriceMachine);

