







































	// @ts-ignore
    import _ from './ProcedureDomainMoveOutAccount.ts'; export default _;
