import {Component, Prop, Vue} from "vue-property-decorator";
import {themeMainModule} from '@/store/theme/theme-main';

@Component({
    components: {
    }
})
export default class CNewsItem extends Vue {


    @Prop({default: ''})
	public title: string;

    @Prop({default: ''})
	public body: string;

    @Prop({default: ''})
	public date: string;

    @Prop({default: () => {}})
	public link: any;

    public get deviceSize(): any {
	    return themeMainModule.deviceSize;
    }

}



