/*
	
	YH: Data Spread Sheet is Here    
    https://docs.google.com/spreadsheets/d/1g3gwEqasOmpKq_Ypqb-i-Onl4PNeV5FHqOTxfTehv3M/edit?usp=sharing

*/

export const Uploadimg = [

{
	type:'img',
	btnclass:'m-icon-imgs-l',
	btnlabel: '記事メイン写真',
	click: 'upload-img',
	trimming: true,
	sort: true,
	delete: true
}, 
{
	type:'logo-wizard',
	btnclass:'m-icon-logo-l',
	btnlabel: 'ロゴをつくる',
	click: 'logo-list',
	trimming: false,
	sort: false,
	delete: true
}, 
{
	type:'logo',
	btnclass:'m-icon-logo-l',
	btnlabel: 'ロゴを登録',
	click: 'upload-logo',
	trimming: false,
	sort: false,
	delete: true
}, 
{
	type:'logo-white',
	btnclass:'m-icon-logo-l',
	btnlabel: '明るい背景用',
	click: 'upload-img-drag',
	trimming: false,
	sort: false,
	delete: true
}, 
{
	type:'logo-black',
	btnclass:'m-icon-logo-l',
	btnlabel: '暗い背景用',
	click: 'upload-img-drag',
	trimming: false,
	sort: false,
	delete: true
}, 
{
	type:'staff',
	btnclass:'m-icon-staff-l',
	btnlabel: 'スタッフ写真',
	click: 'upload-img',
	trimming: true,
	sort: false,
	delete: true
}, 


];