/*

	YH: Data Spread Sheet is Here
	https://docs.google.com/spreadsheets/d/1LU73eygtCKuQDHk5ezhlTSXi4wo650g0pEm5kdNfiq4/edit?usp=sharing

*/

export const TxtSize = [

    //tiny-garamond--pc--mv-txt
    {
        id: 'mv-txt',
        size: 8.2,
        sizeAcc: 0.68,
        fontsizeAcc: 0.8,
        lineAcc: 65,
    },

];
