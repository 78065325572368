import Vue from 'vue'

Vue.directive('wscroll', {
    inserted: function (el, binding) {
        let f = function (evt) {
            if (binding.value(evt, el)) {
                window.removeEventListener('scroll', f);
            }
        };

        window.addEventListener('scroll', f);
    }
});

Vue.directive('scroll', {
    inserted: function (el, binding) {
        let f = function (evt) {
            if (binding.value(evt, el)) {
                el.removeEventListener('scroll', f);
            }
        };

        el.addEventListener('scroll', f);
    }
});
