import {Component, Vue} from 'vue-property-decorator';
import VarUtil, {$v} from '@/classes/utils/var-util';
import {themeMainModule} from '@/store/theme/theme-main';

import * as $ from 'jquery';

@Component
export default class CLogoHeaderMix extends Vue {

    // Logo Type from ProjectData
    public get logoType(): any {
        if (!themeMainModule.project) {
            return 'none';
        }
        return $v.p(themeMainModule, 'project.logo.type');
    }

    // Logo Data from ProjectData
    public get logoData(): any {

        if (!themeMainModule.layout) {
            return;
        }

        if (themeMainModule.layout.navigation.color == 'mv') {
            return themeMainModule.project.logo.bg;
        }

        return themeMainModule.project.logo.txt;
    }

    // Logo Size from layout in Brand.ts
    public get navlogoSize(): any {

        if (!themeMainModule.layout) {
            return;
        }

        //Mobile
        if (themeMainModule.deviceSize == 'mb') {
            return themeMainModule.layout.logo.mb.head.size;
        }

        return $v.p(themeMainModule, 'layout.logo.pc.head.size');
    }

    // Logo Size from layout in Brand.ts
    public get navlogoScaleType(): any {

        if (!themeMainModule.layout) {
            return;
        }

        //Mobile
        if (themeMainModule.deviceSize == 'mb') {
            return themeMainModule.layout.logo.mb.head.scaletype;
        }

        return $v.p(themeMainModule, 'layout.logo.pc.head.scaletype');
    }
}
