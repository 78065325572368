/*
	
	YH: Data Spread Sheet is Here
	https://docs.google.com/spreadsheets/d/1GP-wc5RwVL54UcYqxjH_ZfCQWY7IAfuL_BSC2SXCL8s/edit?usp=sharing

*/

export const DummyMInfo = [
{
	id: 1,
	date: '2019.04.05',
	title: '決済に失敗しました',
	body: 'ご登録されているカードでの決済に失敗しました。 カード情報をご確認してください。2週間以内にご変更がなかった場合、自動的に退会となります。 カード情報を更新する',
	img: '/test/manager/news/01.jpg'
}, 
 
];