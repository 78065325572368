import {Mutation, MutationAction, Action, VuexModule, getModule, Module} from 'vuex-module-decorators';
import store from '@/classes/core/store';

export interface IPopup {
    id: string;
    type: string;

    open?: boolean;
    num?: number;
    option?: { [key: string]: any };
}

// state's interface
export interface IPopupModule {

    popups: IPopup[];

    id: string;
    num: number;
    type: string;
    closeWrapper: boolean;
}

@Module({dynamic: true, store, name: 'popup', namespaced: true})
class Popup extends VuexModule implements IPopupModule {

    public popups: IPopup[] = [];

    //Open ID
    public id: string = '';

    //Popup個数
    public num: number = 0;

    //Popup Contents Type
    //blog, gallery, news などの同じコンポーネント内での分岐
    public type: string = '';

    //Close Wrapperを表示させるか（閉じるボタン）
    public closeWrapper: boolean = true;

    /**
     *
     */
    public lastCloseOption: any = null;

    // Mutations ////////////////////////////////////////////////
    @Mutation
    public updatePopup(popup: IPopup) {
        console.log('popupModule.updatePopup > ', popup);
        this.popups = this.popups.replaceByKey('id', popup).array;
    }

    @Mutation
    public removePopup(id: string) {
        this.popups = this.popups.rejectByKey('id', id);
    }

    @Mutation
    public resetPopup() {
        this.id = '';
        this.num = 0;
    }

    @Mutation
    public closePopup(id: string = '') {
        if (!!id && String(id) !== '0') {
            const current = M.popups.findByKey('id', id);
            if (current.open) {
                M.updatePopup({
                    ...current,
                    ...{
                        open: false,
                    }
                });
            }

            console.log(`closePopup: id=${id}`);
        } else {
            --this.num;
            this.id = '';
            console.log(`closePopup: num=${this.num}`);
        }
    }

    @Mutation
    public openPopup(value: string = '') {
        ++this.num;
        this.id = value;
        console.log(`popupModule.onPopup() > popup number: id=%s, no=%s`, popupModule.id, popupModule.num);
    }

    @Mutation
    public updateId(value: string) {
        this.id = value;
    }

    @Mutation
    public updateNum(value: number) {
        this.num = value;
    }

    @Mutation
    public updateType(value: string) {
        this.type = value;
    }

    @Mutation
    public updateCloseWrapper(value: boolean) {
        this.closeWrapper = value;
    }

    @Mutation
    public updateLastCloseOption(value: any = null) {
        this.lastCloseOption = value;
    }

    // Actions ////////////////////////////////////////////////
    @Action
    public async open(popup: IPopup): Promise<void> {
        M.updateNum(M.num + 1);
        M.updatePopup({
            ...popup,
            ...{
                open: true,
                num: M.num,
            }
        });
    }
}

export const popupModule = getModule(Popup);

const M = popupModule;
