































	// @ts-ignore
    import _ from './MSecondaryDomainMove.ts'; export default _;
