import {Component, Watch, Prop, Vue} from '@/node_modules/nuxt-property-decorator';
import ViewPosMix from '@/classes/mixin/view-pos-mix';
import * as $ from 'jquery';
import {tipsModule} from "@/store/tips";

@Component({
    mixins: [ViewPosMix]
})
export default class MTipsFrame extends Vue {

    @Prop({default: ''})
    public target: string | null;

    @Watch('current')
    public watchCurrent() {
        this.setStyles();
        //console.log('change current', this.current);
    }

    //View Pos Mixで使用
    public isNoArrow: boolean = false;

    //View Pos Mixで使用
    public styleArrow = {};

    public styles = {};

    public get current(): any {
        return tipsModule.current;
    }

    public get hasCurrent(): boolean {
        return !!this.current;
    }

    public get txt() {
        return this.current.read;
    }

    public setStyles() {

		if( this.current ){

            const pos = this.current.pos;
            const size = this.current.size;

            //Tips 描画後に計算
			this.$nextTick(() => {

	            const myw = $(this.$refs.wrapper).outerWidth();
	            const myh = $(this.$refs.wrapper).outerHeight();
	            const ww = $(window).width();
	            const wh = $(window).height();

	            //Bottomでの位置を取得
	            const p: any = this!['posPop'](pos, size, myw, myh, ww, wh, this.current.cpos, this);
				//console.log(p);

				this.styles = {
	                top: `${p.top}px`,
	                left: `${p.left}px`
	            }
			});
		}

    }


    public get otype(): string {
        return 'm-pop--' + this.current.type;
    }

    public get opos(): string {
        return 'm-pop--' + this.current.cpos;
	}

    public mounted() {

        //console.log($(this.target).height());
        //ターゲットにマウスイベントを設定
    }
}
