/*

	YH: Data Spread Sheet is Here
	https://docs.google.com/spreadsheets/d/1h3_uxeAJsJ0OWEEeJ_kE3M8uWwNZho2B_8CigoLO964/edit?usp=sharing

*/
import {ISelectSource} from '@/classes/domain/model';

export const Select: ISelectSource[] = [
/////////////////////////////////////////////////////////////


{
	id: 'accept',
	component:'m-select-list',
	sizeclass:'',
	type:'n',
	d:[{value: 1, label: '受付'},
{value: 0, label: '受付けない'},],
	icon:[],
},
{
	id: 'accept',
	component:'m-select-list',
	sizeclass:'',
	type:'n',
	d:[{value: 1, label: '受付'},
{value: 0, label: '受付けない'},],
	icon:[],
},
{
	id: 'account-add-store',
	component:'m-select-list',
	sizeclass:'',
	type:'n',
	d:[{value: 1, label: '1店舗'},
{value: 2, label: '2店舗'},
{value: 3, label: '3店舗'},
{value: 4, label: '4店舗'},
{value: 5, label: '5店舗'},
{value: 6, label: '6店舗'},
{value: 7, label: '7店舗'},
{value: 8, label: '8店舗'},
{value: 9, label: '9店舗'},
{value: 10, label: '10店舗'},],
	icon:[],
},
{
	id: 'account-card-year',
	component:'m-select-list',
	sizeclass:'',
	type:'s',
	d:['2019','2020','2021','2022','2023','2024'],
	icon:[],
},
{
	id: 'account-country',
	component:'m-select-list',
	sizeclass:'',
	type:'s',
	d:[{value: 'jp', label: '日本'},
{value: 'other', label: 'その他'},],
	icon:[],
},
{
	id: 'account-lang',
	component:'m-select-list',
	sizeclass:'',
	type:'s',
	d:[{value: 'jp', label: '日本語'},
{value: 'en', label: '英語'},],
	icon:[],
},
{
	id: 'account-menu',
	component:'m-select-account-menu',
	sizeclass:'',
	type:'',
	d:[],
	icon:[],
},
{
	id: 'account-mg',
	component:'m-select-list',
	sizeclass:'',
	type:'n',
	d:[{value: 1, label: '受け取る'},
{value: 0, label: '受け取らない'},],
	icon:[],
},
{
	id: 'account-pay-history',
	component:'m-select-list',
	sizeclass:'',
	type:'n',
	d:[{value: 2019, label: '2019年'},
{value: 2018, label: '2018年'},],
	icon:[],
},
{
	id: 'account-sbs',
	component:'m-select-list',
	sizeclass:'',
	type:'n',
	d:[{value: 1, label :'自動更新'},
{value: 0, label: '更新しない'},],
	icon:[],
},
{
	id: 'add-cat',
	component:'m-select-add-cat',
	sizeclass:'',
	type:'',
	d:[],
	icon:[],
},
{
	id: 'address-country',
	component:'m-select-list',
	sizeclass:'',
	type:'s',
	d:[{value: 'jp', label: '日本'},
{value: 'vt', label: 'ベトナム'},
{value: 'us', label: 'アメリカ'}],
	icon:[],
},
{
	id: 'article-setting',
	component:'m-select-list',
	sizeclass:'',
	type:'n',
	d:[{value: 0, label: 'この記事を非表示'},
{value: 1, label: 'この記事を削除'},],
	icon:[],
},
{
	id: 'blog-index-filter',
	component:'m-blog-index-filter',
	sizeclass:'4w',
	type:'',
	d:[],
	icon:[],
},
{
	id: 'bottom-navigation',
	component:'m-select-list',
	sizeclass:'',
	type:'s',
	d:[{value: 'reserve_news', label: '予約情報とニュース'},
{value: 'news', label: 'ニュース'},
{value: 'none', label: 'つかわない'},],
	icon:[],
},
{
	id: 'brand-format',
	component:'m-select-list',
	sizeclass:'',
	type:'s',
	d:[{value: 'shop', label: 'お店'},
{value: 'company', label: '企業'},
{value: 'personal', label: '個人・フリーランス'},],
	icon:[],
},
{
	id: 'display',
	component:'m-select-list',
	sizeclass:'',
	type:'n',
	d:[{value: 1, label: '表示'},
{value: 0, label: '非表示'},],
	icon:[],
},
{
	id: 'gallery-list',
	component:'m-select-list',
	sizeclass:'',
	type:'n',
	d:[{value: 1, label: '記事タイトル A'},
{value: 2, label: '記事タイトル B'},],
	icon:[],
},
{
	id: 'help',
	component:'m-select-help',
	sizeclass:'',
	type:'',
	d:[],
	icon:[],
},
{
	id: 'home-block-link',
	component:'m-select-list',
	sizeclass:'',
	type:'s',
	d:[
        {value: '/collection/', label: 'Collection'},
        {value: '/salon/', label: 'Salon'},
        {value: '/access/', label: 'Access'},
        {value: '/menu/', label: 'Menu'},
        {value: '/contact/', label: 'Contact'}
    ],
	icon:[],
},
{
	id: 'img-ratio',
	component:'m-select-list',
	sizeclass:'',
	type:'s',
	d:[{value: 'landscape', label: '横長'},
{value: 'portrait', label: '縦長'},
{value: 'auto', label: '自動'},],
	icon:[],
},
{
	id: 'info',
	component:'m-select-info',
	sizeclass:'l',
	type:'',
	d:[],
	icon:[],
},
{
	id: 'jp-state',
	component:'m-select-list',
	sizeclass:'',
	type:'s',
	d:[{value: 'Hokkaido', label: '北海道'},
{value: 'Aomori', label: '青森'},
{value: 'Yamagata', label :'山形'},],
	icon:[],
},
{
	id: 'menu-cat-list',
	component:'m-select-list',
	sizeclass:'',
	type:'s',
	d:[{value: 'cut', label: 'カット'},
{value: 'color', label: 'カラー'},
{value: 'treatment', label: 'トリートメント'},],
	icon:['m-icon-gicon red','m-icon-gicon green','m-icon-gicon blue'],
},
{
	id: 'menu-cat-setting',
	component:'m-select-list',
	sizeclass:'',
	type:'s',
	d:[{value: 'edit', label: 'カテゴリー カットを編集'},
{value: 'remove', label: 'カテゴリー カットを削除'},],
	icon:[],
},
{
	id: 'menu-genre-setting',
	component:'m-select-list',
	sizeclass:'',
	type:'s',
	d:[{value: 'hide', label: 'このジャンルを非表示'},
{value: 'remove', label: 'このジャンルを削除'},],
	icon:['eye-off', 'trash'],
},
{
	id: 'menu-icon-color',
	component:'m-select-list',
	sizeclass:'',
	type:'sa',
	d:['BLACK','RED','GREEN','BLUE','YELLOW','GRAY','PURPLE','SKY BLUE'],
	icon:['m-icon-gicon black','m-icon-gicon red','m-icon-gicon green','m-icon-gicon blue','m-icon-gicon yellow','m-icon-gicon gray','m-icon-gicon purple','m-icon-gicon sky'],
},
{
	id: 'menu-item-setting',
	component:'m-select-list',
	sizeclass:'',
	type:'s',
	d:['アイテムを非表示', 'アイテムを削除'],
	icon:[],
},
{
	id: 'menu-item-time',
	component:'m-select-list',
	sizeclass:'',
	type:'n',
	d:[{value: 0.5, label: '30分'},
{value: 1, label: '1時間'},
{value: 6, label: '6時間'},],
	icon:[],
},
{
	id: 'money-range',
	component:'m-select-list',
	sizeclass:'',
	type:'s',
	d:[{value: 'yen', label: '円'},
{value: 'yen_high', label: '円〜(以上)'}],
	icon:[],
},
{
	id: 'month',
	component:'m-select-list',
	sizeclass:'',
	type:'n',
	d:[1,2,3,4,5,6,7,8,9,10,11,12],
	icon:[],
},
{
	id: 'color-base',
	component:'m-select-color-base',
	sizeclass:'',
	type:'',
	d:[],
	icon:[],
},
{
	id: 'color-txt',
	component:'m-select-color-txt',
	sizeclass:'',
	type:'',
	d:[],
	icon:[],
},
{
	id: 'color-bg',
	component:'m-select-color-bg',
	sizeclass:'',
	type:'',
	d:[],
	icon:[],
},
{
	id: 'color-mv-txt',
	component:'m-select-color-mv-txt',
	sizeclass:'',
	type:'',
	d:[],
	icon:[],
},
{
	id: 'color-mv-bg',
	component:'m-select-color-mv-bg',
	sizeclass:'',
	type:'',
	d:[],
	icon:[],
},
{
	id: 'color-sidecover',
	component:'m-select-color-sidecover',
	sizeclass:'',
	type:'',
	d:[],
	icon:[],
},
{
	id: 'color-sidecover-txt',
	component:'m-select-color-sidecover-txt',
	sizeclass:'',
	type:'',
	d:[],
	icon:[],
},
{
	id: 'color-sidecover-bg',
	component:'m-select-color-sidecover-bg',
	sizeclass:'',
	type:'',
	d:[],
	icon:[],
},
{
	id: 'mv-bg-color',
	component:'m-select-list',
	sizeclass:'',
	type:'sa',
	d:['White','Light Gray','Black','Smoky Purple','Eco Green','Smoky Leaf','Green','Paper Baby Pink','Scarlets','Chocolates','Paper Baby Blues','Paper Aquas','School Blues','Blues','Ink Blue'],
	icon:['m-colorchip s-mv-bg--white','m-colorchip s-mv-bg--light-gray','m-colorchip s-mv-bg--black','m-colorchip s-mv-bg--smoky-purple','m-colorchip s-mv-bg--eco-green','m-colorchip s-mv-bg--smoky-leaf','m-colorchip s-mv-bg--green','m-colorchip s-mv-bg--p-baby-pink','m-colorchip s-mv-bg--orange','m-colorchip s-mv-bg--scarlet','m-colorchip s-mv-bg--chocolate','m-colorchip s-mv-bg--p-baby-blue','m-colorchip s-mv-bg--p-aqua','m-colorchip s-mv-bg--school-blue','m-colorchip s-mv-bg--blue','m-colorchip s-mv-bg--ink-blue'],
},
{
	id: 'mv-content-gallery',
	component:'m-select-list',
	sizeclass:'',
	type:'s',
	d:[{value: 1, label: '記事タイトル A'},
{value: 2, label: '記事タイトル B'},
{value: 3, label: '記事タイトル C'},
{value: 4, label: '記事タイトル D'},
{value: 5, label: '記事タイトル E'},],
	icon:[],
},
{
	id: 'mv-cv-color',
	component:'m-select-list',
	sizeclass:'',
	type:'sa',
	d:['Paper Baby Pink','Paper Baby Blue','Paper Light Gray','Nude','Eco Paper','Concrete','Dark Army','Black'],
	icon:['m-colorchip s-cv--p-baby-pink','m-colorchip s-cv--p-baby-blue','m-colorchip s-cv--p-l-gray','m-colorchip s-cv--nude','m-colorchip s-cv--eco-p','m-colorchip s-cv--concrete','m-colorchip s-cv--dark-army','m-colorchip s-cv--black'],
},
{
	id: 'mv-cv-decor',
	component:'m-select-list',
	sizeclass:'',
	type:'sa',
	d:['Simple Line','Cat Walk','Boots'],
	icon:[],
},
{
	id: 'mv-photo-effect',
	component:'m-select-list',
	sizeclass:'',
	type:'s',
	d:[{value: 'fade', label: 'フェード'},
{value: 'slide', label: 'スライド'},
{value: '3d',  label: '3D'},
{value: 'none', label: 'なし'},],
	icon:[],
},
{
	id: 'mv-txt-color',
	component:'m-select-list',
	sizeclass:'',
	type:'sa',
	d:['White','Text Color'],
	icon:['s-mv-txt--white', 's-mv-txt--txt'],
},
{
	id: 'news',
	component:'m-select-news',
	sizeclass:'l',
	type:'n',
	d:[],
	icon:[],
},
{
	id: 'onoff',
	component:'m-select-list',
	sizeclass:'',
	type:'n',
	d:[{value: 1, label: 'ON'},
{value: 0, label: 'OFF'},],
	icon:[],
},
{
	id: 'print-num',
	component:'m-select-list',
	sizeclass:'',
	type:'n',
	d:[{value: 100, label: '100枚'},
{value: 200, label: '200枚'},
{value: 300, label: '300枚'},
{value: 500, label: '500枚'},],
	icon:[],
},
{
	id: 'pv-mv-edit',
	component:'m-select-list',
	sizeclass:'',
	type:'s',
	d:[{value: 'img', label: '画像の変更'},
{value: 'copy', label: 'キャッチコピーの編集'},
{value: 'logo', label: 'ロゴの変更'},],
	icon:[],
},
{
	id: 'rs-hours',
	component:'m-select-list',
	sizeclass:'',
	type:'n',
	d:[{value: 30, label: '30分'},
{value: 60, label: '1時間'},
{value: 90, label: '1時間30分'},
{value: 120, label: '2時間'},
{value: 150, label: '2時間30分'},
{value: 180, label: '3時間'},
{value: 210,label: '3時間30分'},],
	icon:[],
},
{
	id: 'rs-link-mv',
	component:'m-select-list',
	sizeclass:'',
	type:'s',
	d:['予約リンク: https://beauty.hotpepper.jp/slnH000042374/','入力する','リンクなし'],
	icon:[],
},
{
	id: 'rs-link-store',
	component:'m-select-list',
	sizeclass:'',
	type:'s',
	d:['全体と同じ: https://beauty.hotpepper.jp/slnH000042374/','入力する','リンクなし'],
	icon:[],
},
{
	id: 'rs-setting',
	component:'m-select-list',
	sizeclass:'',
	type:'n',
	d:[{value: 0, label: '受付の一時停止'},
{value: 1, label: '予約設定'}],
	icon:[],
},
{
	id: 'rs-staff',
	component:'m-select-list',
	sizeclass:'',
	type:'s',
	d:[{value: '0', label: '未設定'},
{value: 'aaaaaa', label: '浅野 夏希'},
{value: 'bbbbbb', label: '山田 大輔'},],
	icon:[],
},
{
	id: 'rs-time',
	component:'m-select-list',
	sizeclass:'',
	type:'s',
	d:['9:00','9:30','10:00','10:30','11:00','11:30','---','12:00','12:30','13:00','13:30','14:00','14:30','15:00','15:30','16:00','16:30','17:00','17:30','---','18:00','18:30','19:00'],
	icon:[],
},
{
	id: 'staff-list',
	component:'m-select-list',
	sizeclass:'',
	type:'s',
	d:[{value: 'aaaaaa', label: '浅野 夏希'},
{value: 'bbbbbb', label: '木村 孝子'},],
	icon:[],
},
{
	id: 'staff-setting',
	component:'m-select-list',
	sizeclass:'',
	type:'s',
	d:[{value: 'hide', label: 'このスタッフを非表示'},
{value: 'remove', label: 'このスタッフを削除'},],
	icon:[],
},
{
	id: 'store-holiday-pattern',
	component:'m-select-list',
	sizeclass:'',
	type:'n',
	d:[{value: 0, label: '毎週'},
{value: 1, label: '第1'},
{value: 2, label: '第2'},
{value: 3, label: '第3'},
{value: 4, label: '第4'},
{value: 5, label: '第1,第2'},
{value: 6, label: '第1,第3'},
{value: 7, label: '第1,第4'},
{value: 8, label: '第1,第2,第3'},
{value: 9, label: '第1,第2,第4'},
{value:10, label: '第1,第3,第4'},
{value:11, label: '第2,第3'},
{value: 12, label: '第2,第4'},
{value: 13, label: '第2,第3,第4'},
{value: 14, label: '第3,第4'},],
	icon:[],
},
{
	id: 'store-list',
	component:'m-select-list',
	sizeclass:'',
	type:'s',
	d:[{value: 'aaaa', label: 'FOCUS SAPPORO'},
{value: 'bbbb', label: 'FOCUS ABC'},
{value: 'cccc', label: 'FOCUS KOAN'},
{value: 'dddd', label: 'FOCUS XERXES'}],
	icon:[],
},
{
	id: 'store-setting',
	component:'m-select-list',
	sizeclass:'',
	type:'s',
	d:[{value: 'hide', label: 'このお店を非表示'},
{value: 'remove', label: 'このお店を削除'},],
	icon:[],
},
{
	id: 'subdomain',
	component:'m-select-list',
	sizeclass:'',
	type:'',
	d:['.hair-design.salon','.design.be','.design.studio'],
	icon:[],
},
{
	id: 'unsubsc-survey',
	component:'m-select-list',
	sizeclass:'',
	type:'s',
	d:['イキったデザインが欲しかった','ムカついた','なんか気に入らなかった','THOUGHTを卒業します'],
	icon:[],
},
{
    id: 'uploadimg',
    component:'m-select-uploadimg',
    sizeclass:'3w',
    type:'',
    d:[],
    icon:[],
},
{
	id: 'project-other',
	component:'m-select-list',
	sizeclass:'',
	type:'s',
	d:[{value: '', label: '問題や意見を報告'},],
	icon:[],
},
{
	id: 'plan-period',
	component:'m-select-list',
	sizeclass:'',
	type:'s',
	d:[{value: 'year', label: '年払い'},
{value: 'month', label: '月払い'},],
	icon:[],
},
{
	id: 'contact-category',
	component:'m-select-list',
	sizeclass:'',
	type:'',
	d:[{value: 'question', label: '利用方法について'},
{value: 'bug', label: '不具合のご連絡'},
{value: 'subscription', label: 'お支払いについて'},
{value: 'press', label: '取材や雑誌掲載について'},
{value: 'other', label: 'その他'},],
	icon:[],
},
{
	id: 'web-public',
	component:'m-select-list',
	sizeclass:'',
	type:'',
	d:[{value: 'unlimited', label: '制限なし'},
{value: 'all', label: 'サイト全体'},
{value: 'gallery', label: 'ギャラリーのみ'},],
	icon:[],
},


/////////////////////////////////////////////////////////////
];
