import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'

const _af875026 = () => interopDefault(import('../../pages.teaser/official/concept/index.vue' /* webpackChunkName: "pages.teaser/official/concept/index" */))
const _ec3588b6 = () => interopDefault(import('../../pages.teaser/official/contact/index.vue' /* webpackChunkName: "pages.teaser/official/contact/index" */))
const _0926a7ca = () => interopDefault(import('../../pages.teaser/official/faq/index.vue' /* webpackChunkName: "pages.teaser/official/faq/index" */))
const _784a7efb = () => interopDefault(import('../../pages.teaser/official/feature/index.vue' /* webpackChunkName: "pages.teaser/official/feature/index" */))
const _18cde81b = () => interopDefault(import('../../pages.teaser/official/layout/index.vue' /* webpackChunkName: "pages.teaser/official/layout/index" */))
const _9591d42c = () => interopDefault(import('../../pages.teaser/official/music/index.vue' /* webpackChunkName: "pages.teaser/official/music/index" */))
const _757e5604 = () => interopDefault(import('../../pages.teaser/official/news/index.vue' /* webpackChunkName: "pages.teaser/official/news/index" */))
const _2d1362da = () => interopDefault(import('../../pages.teaser/official/plan/index.vue' /* webpackChunkName: "pages.teaser/official/plan/index" */))
const _a57d82f0 = () => interopDefault(import('../../pages.teaser/official/press/index.vue' /* webpackChunkName: "pages.teaser/official/press/index" */))
const _167bd185 = () => interopDefault(import('../../pages.teaser/official/contact/send.vue' /* webpackChunkName: "pages.teaser/official/contact/send" */))
const _fec5408a = () => interopDefault(import('../../pages.teaser/official/legal/cookies.vue' /* webpackChunkName: "pages.teaser/official/legal/cookies" */))
const _3fe21c34 = () => interopDefault(import('../../pages.teaser/official/legal/privacy.vue' /* webpackChunkName: "pages.teaser/official/legal/privacy" */))
const _4e259e60 = () => interopDefault(import('../../pages.teaser/official/legal/sct.vue' /* webpackChunkName: "pages.teaser/official/legal/sct" */))
const _9054c09a = () => interopDefault(import('../../pages.teaser/official/legal/terms.vue' /* webpackChunkName: "pages.teaser/official/legal/terms" */))
const _39aa3e1c = () => interopDefault(import('../../pages.teaser/index.vue' /* webpackChunkName: "pages.teaser/index" */))

Vue.use(Router)

const scrollBehavior = function (to, from, savedPosition) {
            return { x: 0, y: 0 };
        }

export function createRouter() {
  return new Router({
    mode: 'history',
    base: decodeURI('/'),
    linkActiveClass: 'nuxt-link-active',
    linkExactActiveClass: 'nuxt-link-exact-active',
    scrollBehavior,

    routes: [{
      path: "/official/concept",
      component: _af875026,
      name: "official-concept"
    }, {
      path: "/official/contact",
      component: _ec3588b6,
      name: "official-contact"
    }, {
      path: "/official/faq",
      component: _0926a7ca,
      name: "official-faq"
    }, {
      path: "/official/feature",
      component: _784a7efb,
      name: "official-feature"
    }, {
      path: "/official/layout",
      component: _18cde81b,
      name: "official-layout"
    }, {
      path: "/official/music",
      component: _9591d42c,
      name: "official-music"
    }, {
      path: "/official/news",
      component: _757e5604,
      name: "official-news"
    }, {
      path: "/official/plan",
      component: _2d1362da,
      name: "official-plan"
    }, {
      path: "/official/press",
      component: _a57d82f0,
      name: "official-press"
    }, {
      path: "/official/contact/send",
      component: _167bd185,
      name: "official-contact-send"
    }, {
      path: "/official/legal/cookies",
      component: _fec5408a,
      name: "official-legal-cookies"
    }, {
      path: "/official/legal/privacy",
      component: _3fe21c34,
      name: "official-legal-privacy"
    }, {
      path: "/official/legal/sct",
      component: _4e259e60,
      name: "official-legal-sct"
    }, {
      path: "/official/legal/terms",
      component: _9054c09a,
      name: "official-legal-terms"
    }, {
      path: "/",
      component: _39aa3e1c,
      name: "index"
    }],

    fallback: false
  })
}
