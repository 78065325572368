import {Action, getModule, Module, Mutation, VuexModule} from "@/node_modules/vuex-module-decorators";
import store from "@/classes/core/store";
import ManagerApi from "@/classes/app/api/manager-api";
import {$v} from "@/classes/utils/var-util";
import {appAuthModule, AuthProduct} from "@/store/app/auth";

export interface IProject {
    toid: string;
}

export interface IManagerProjectModule {
    fetched: boolean;
    records: IProject[];
}

@Module({dynamic: true, store, name: 'managerProject', namespaced: true})
class Store extends VuexModule implements IManagerProjectModule {

    private _fetched: boolean = false;
    private _records: IProject[] = [];

    // Getters ///////////////////////
    public get fetched(): boolean {
        return this._fetched;
    }

    public get records(): IProject[] {
        return this._records;
    }

    @Mutation
    public updateRecords(value: IProject[]) {
        this._records = value;
    }

    @Mutation
    public updateRecord(value: IProject) {
        this._records = this._records.replaceByKey('toid', value).array.from();
        console.log('updateRecord > ', value, this._records);
    }

    @Mutation
    public updateFetched(fetched: boolean) {
        this._fetched = fetched;
    }

    @Action
    public async fetch(force: boolean = false): Promise<any> {

        if (!force && M.fetched) {
            return;
        }

        try {
            const records = await ManagerApi.OwnerSite.get().execAsync('records.list');
            // console.log('managerProjectModule.fetch() > records > ', records);
            M.updateFetched(true);
            M.updateRecords(Array.isArray(records) ? Array.from(records) : []);

            return null;
        } catch (e) {

            // console.log('sore/manager/project.fetch() error > ', $v.p(e, 'response.statusCode'));
            if ($v.p(e, 'response.statusCode') === 401) {
                console.log('- logout');
                await appAuthModule.logout(AuthProduct.To004Owner);
            }

            return e;
        }
    }
}

export const managerProjectModule = getModule(Store);

const M = managerProjectModule;
