import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import AVueComponent from '@/classes/page/a-vue-component';
import {IUploadimgState, uploadimgModule} from '@/store/uploadimg';

import {Uploadimg} from '@/configs/uploadimg';
import {SystemConfig} from '@/configs/system';
import VarUtil, {$v} from '@/classes/utils/var-util';
import {ExtUploadImg} from "@/classes/ext/ext-upload-img";
import {IUpFile, upfileModule, UploadType} from "@/store/upfile";
import {AppConst} from "@/classes/app/app-const";
import {prodWizardModule} from "@/store/prod/wizard";
import {sprintf} from "sprintf-js";
import {IImg, UploadImgConst} from "@/classes/domain/module/model/i-img";
import {ILogo} from "@/classes/domain/module/model/i-logo";
import {themeMainModule} from "@/store/theme/theme-main";
import {MUplodimgUtil} from "@/classes/view/m-uplodimg-util";

@Component({
    components: {}
})
export default class MUploadimg extends AVueComponent {

    // Module ID
    @Prop({default: '@'})
    public mid: string;

    @Prop({default: ''})
    public type: string;

    @Prop({default: 1})
    public limit: number;

    @Prop({default: ''})
    public caption: string;

    @Prop({default: null})
    public logo: ILogo | null;

    // site(transId), token
    @Prop()
    public opt: any;

    //for Dev
    // mounted : Mount Image
    @Prop({default: ''})
    public dev: string;

    public extUploadImg: ExtUploadImg;

    //セレクターメニュー
    public trimming: boolean = false;
    public sort: boolean = false;
    public remove: boolean = false;
    public select: any = {};

    //機能
    //リスト
    public list: boolean = true;

    //MODE
    //並び替えモード
    public modeSort: boolean = false;

    //STATES
    public state = {
        config: {
            sizeLimit: SystemConfig.uploadimg.sizeLimit,
            quality: SystemConfig.uploadimg.quality,
            clickTo: [
                'upload-img',
                'upload-logo',
                'upload-img-drag',
                'logo-list',
            ],
            logos: [
                'logo',
                'logo-black',
                'logo-white',
            ],
            //スタイル
            btnclass: 'm-icon-close',
            btnlabel: 'ロード失敗',
            // SystemConfig.uploadimg.purpose
            upload: null as any,
        },
        drag: {
            hover: {
                window: false,
                windowLeave: 0,
                area: false,
                areaLeave: 0,
            },
        }
    };

    @Watch('up', {deep: true})
    public watchUps(now: IUpFile) {
        console.log('MUploadimg > %s > ups', this.mid, now);

        if (now.progress.result === null) {
            this.$emit('change', {
                id: this.imgState.id,
                pos: this.imgState.pos,
                img: now.file,
            });
        }
    }

    @Watch('logo', {deep: true})
    public async watchLogo(logo: ILogo) {
        await prodWizardModule.inputValue({
            name: 'logo',
            value: logo,
        });
    }

    //Dummy Data
    public devImgs: any = [
        '/dummy-user/pjname/blog/1-s.jpg',
        '/dummy-user/pjname/blog/2-s.jpg',
        '/dummy-user/pjname/blog/3-s.jpg',
        '/dummy-user/pjname/blog/4-s.jpg',
        '/dummy-user/pjname/blog/1-s.jpg',
        '/dummy-user/pjname/blog/2-s.jpg',
    ];

    public created() {
        this.extUploadImg = new ExtUploadImg(this);
        this.state.config.upload = SystemConfig.uploadimg.purpose.findByKey('key', this.mid);
    }

    public mounted() {

        this.bindEvent();

        if (!this.state.config.upload) {
            throw new Error(sprintf('Config load error > %s', this.mid));
        }

        //Search a Uploadimg Type
        VarUtil.tap(this.uploadimgType, (it: any) => {
            const imgs = [];
            for (let i = 0; i < this.limit; i++) {
                imgs.push(null);
            }

            console.log('MUploadimg.mounted()', this.site);
            console.log(' - transId > ', prodWizardModule.input.transId);
            console.log(' - site > ', this.site);
            console.log(' - config.upload > ', this.config.upload);

            const dataType = (/^logo/.test(this.uploadimgType.type) ? UploadImgConst.DataType.DATA_TYPE_LOGO
                : UploadImgConst.DataType.DATA_TYPE_IMG);

            uploadimgModule.updateUploadImg({
                id: this.mid,
                type: this.uploadimgType,
                dataType,
                menuTrimming: it.trimming,
                menuSort: it.sort,
                menuDelete: it.remove,
                pos: 0,
                imgs: imgs,
                logo: this.clogo,
                site: this.site,
                purpose: this.config.upload.purpose,
            });
        });

        // limitが2以上だったら追加ボタンを配置
        // HTMLに記載済み
        // this.state.imgs = [];
        // for (let i = 0; i < this.limit; i++) {
        //     this.state.imgs.push(null);
        // }
    }

    public destroyed() {
        // console.log('destroooooy');
        this.unbindEvent();
    }

    //////////////////////////////////////////////////////////////////
    public bindEvent() {
        // ブラウザ内の画像ドロップ禁止に"dragover"が必要（Chrome)
        $(window['document'] as any).on('drop dragover dragenter dragleave', (e: any) => {

            // console.log('test');
            switch (e.type) {
                case 'dragenter':
                    // console.log('dragEnter');
                    this.onDragWindowEnter(e);
                    break;
                case 'dragleave':
                    // console.log('dragOut');
                    this.onDragWindowLeave(e);
                    break;
                case 'drop':
                    this.onDragWindowLeave(e);
                    break;
                default:
                    break;
            }

            e.stopPropagation();
            e.preventDefault();
        });
    }

    public unbindEvent() {
        $(window['document'] as any).off('drop dragover dragenter dragleave');
    }

    //並び替えモード終了
    public onSortClose() {
        uploadimgModule.updateModeSort(false);
    }

    // Computed /////////////////////////////////////////////
    public get uploadimgs() {
        return uploadimgModule.uploadimgs;
    }

    public get imgType(): string {
        return !!this.type ? this.type : this.config.upload.type;
    }

    public get uploadimgType(): any {
        return Uploadimg.findByKey('type', this.imgType);
    }

    public get imgState(): IUploadimgState {
        return uploadimgModule.uploadimgs.findByKey('id', this.mid);
    }

    public get dataType(): string {
        return /^logo/.test(this.uploadimgType.type)
            ? UploadImgConst.DataType.DATA_TYPE_LOGO
            : UploadImgConst.DataType.DATA_TYPE_IMG;
    }

    public get up(): IUpFile {
        return upfileModule.ups.findByKey('id', this.mid);
    }

    public get config(): any {
        return this.state.config;
    }

    public get upload(): any {
        return this.state.config.upload;
    }

    public get addLimit(): any {
        return $v.p(this.upload, 'limit') || this.limit;
    }

    public get drag(): any {
        return this.state.drag;
    }

    public get isTypeLogoOrLogoWizard(): boolean {
        return ['logo', 'logo-wizard'].indexOf(this.type) >= 0;
    }

    public get hasImgOrLogo(): boolean {
        if (this.dataType === UploadImgConst.DataType.DATA_TYPE_IMG) {
            return this.imgNumber > 0;
        } else {
            return this.clogo.name.length > 0;
        }
    }

    // 有効な画像枚数
    public get imgNumber(): number {
        let r = 0;
        if (this.imgs) {
            this.imgs.map((_: any) => {
                r += (_ !== null) ? 1 : 0;
            });
        }
        return r;
    }

    public get pos(): number {
        return this.imgState ? this.imgState.pos : 0;
    }

    // 現在の画像
    public get img(): any {
        return this.imgs.length > 0 ? MUplodimgUtil.src(this.imgs[this.pos]) : null;
    }

    // 画像の一覧
    public get imgs(): any {

        const r = (!!this.imgState && !!this.imgState.imgs) ? this.imgState.imgs.filter(((_: any, idx: number) => {
            return (this.addLimit > idx);
        })) : [];

        if (r.length < this.addLimit) {
            r.push({
                src: '',
            });
        }

        return r;
    }

    public hasImg(img: IImg | null) {
        return (img && img.src !== '');
    }

    // 対象Site.toid
    public get site(): any {
        if (this.config.upload.src === AppConst.UploaderSource.SITE) {
            return ''; //@Todo ManagterStoreからのSite.Toid呼び出し
        } else {
            return prodWizardModule.input.transId;
        }
    }

    public get clogo(): ILogo {
        return !!this.logo ? this.logo : {
            name: $v.p(themeMainModule, 'shopname', ''),
            type: $v.p(themeMainModule, 'project.logo', 'webfont'),
            tagline: $v.p(themeMainModule, 'tagline', ''),
            webfont: $v.p(themeMainModule, 'logoActive', AppConst.Logo.DEFAULT_FONT_ID),
            scale: AppConst.Logo.DEFAULT_SCALE,
        };
    }

    public get btnlabel(): string {
        return VarUtil.tap(this.uploadimgType, (it: any) => {
            return (it) ? it.btnlabel : '';
        });
    }

    public get btnclass(): string {
        return VarUtil.tap(this.uploadimgType, (it: any) => {
            return (it) ? it.btnclass : '';
        });
    }

    //TYPEごとの分岐
    public get isModeLogo(): boolean {
        return (this.config.logos.indexOf(this.type) >= 0);
    }

    // Logoはサムネイル機能をつかわない
    public get useList(): boolean {
        return (!this.isModeLogo
            && this.addLimit > 1
            && this.imgNumber > 1
        );
    }

    //クリック時の挙動
    /*
		menu-img : アップロードメニューを開く
		menu-logo : ロゴメニューを開く
		upload : メニューなしでいきなりアップロード画面
    */
    public get clickType(): string | null {
        return VarUtil.tap(this.uploadimgType, (it: any) => {
            if (it && this.config.clickTo.indexOf(it.click) >= 0) {
                return it.click;
            } else {
                return null;
            }
        });
    }

    //並び替えモード
    public get isSort(): any {
        return uploadimgModule.find().modeSort;
    }

    //画面内ドラッグ中
    public get isDrag(): any {
        return this.drag.hover.window;
    }

    //ドラッグ中に上にきたとき
    public get isDragHover(): any {
        return this.drag.hover.area;
    }

    // Select option
    public get selectOption(): any {
        return {
            imgType: this.imgType,
            pos: this.pos,
            purpose: $v.p(this.upload, 'key'),
            img: this.img,
            limit: this.addLimit,
        };
    }

    // Methods ////////////////////////////////////////////
    // Filter
    // Methods //////////////////////////////////////////////////////

    public clearHover() {
        this.drag.hover.window = false;
        this.drag.hover.area = false;
    }

    public async execProcessImage(file: File) {
        await this.extUploadImg.processImage(file, this.config, this.imgState);
    }

    // Events ///////////////////////////////////////////
    // Drop
    public async onDrop(e: any) {
        console.log('drop');
        this.clearHover();
        await this.execProcessImage(e.dataTransfer.files[0]);
    }

    public onDragWindowEnter(e: any) {
        this.state.drag.hover.window = true;
    }

    public onDragWindowLeave(e: any) {
        this.state.drag.hover.window = false;
    }

    public onDragAreaEnter(e: any) {
        this.state.drag.hover.area = true;
    }

    public onDragAreaLeave(e: any) {
        this.drag.hover.area = false;
    }

    public onPopupOpen(id: string) {
        console.log('MUploadimg > onPopupOpen > id=%s, mid=%s', id, this.mid);
        uploadimgModule.open(this.mid);
        super.onPopupOpen(id);
    }

    public onClickThumb(index: number) {
        console.log('MUploadimg.onClickThumb() > index > ', index);
        uploadimgModule.updateUploadImg({
            id: this.imgState.id,
            pos: index,
        });
        this.$nextTick(() => {
            console.log('MUploadimg.onClickThumb() >', this.img);
        });
    }
}

