/*

	YH: Data Spread Sheet is Here
    https://docs.google.com/spreadsheets/d/1yYumRGbZGnNoTVJihuboJgTNQm9BFVv89s_IQpFEMJE/edit?usp=sharing

*/

export const Tips = [

{
	id: 'brand-next',
	type:'r-auto',
	cpos: 'top',
	read:'世界観はあとでも変更できます。<br>次はレイアウトをえらびます。',
},
{
	id: 'brand-select',
	type:'r-auto',
	cpos: 'top',
	read:'世界観からデザインを<br>えらびます',
},
{
	id: 'category',
	type:'single',
	cpos: 'bottom',
	read:'カテゴリー',
},
{
	id: 'category-add',
	type:'single',
	cpos: 'bottom',
	read:'カテゴリー追加',
},
{
	id: 'launch-website',
	type:'single',
	cpos: 'bottom',
	read:'サイトを公開',
},
{
	id: 'close-website',
	type:'single',
	cpos: 'bottom',
	read:'サイトを非公開',
},
{
	id: 'delete-post',
	type:'single',
	cpos: 'bottom',
	read:'記事の削除',
},
{
	id: 'design',
	type:'single',
	cpos: 'bottom',
	read:'デザイン',
},
{
	id: 'edit',
	type:'single',
	cpos: 'bottom',
	read:'編集',
},
{
	id: 'edit-cat',
	type:'single',
	cpos: 'bottom',
	read:'カテゴリー編集',
},
{
	id: 'edit-date',
	type:'single',
	cpos: 'bottom',
	read:'編集日',
},
{
	id: 'example',
	type:'single',
	cpos: 'bottom',
	read:'例文',
},
{
	id: 'example--top',
	type:'single',
	cpos: 'top',
	read:'例文',
},
{
	id: 'filter',
	type:'single',
	cpos: 'bottom',
	read:'絞込み',
},
{
	id: 'font-en',
	type:'single',
	cpos: 'top',
	read:'英語書体に切替',
},
{
	id: 'font-ja',
	type:'single',
	cpos: 'top',
	read:'日本語書体に切替',
},
{
	id: 'help',
	type:'single',
	cpos: 'bottom',
	read:'ヘルプ',
},
{
	id: 'help--top',
	type:'single',
	cpos: 'top',
	read:'ヘルプ',
},
{
	id: 'help-googlemap',
	type:'single',
	cpos: 'bottom',
	read:'地図設定方法',
},
{
	id: 'howto-photo',
	type:'single',
	cpos: 'bottom',
	read:'綺麗な写真を撮る方法',
},
{
	id: 'howto-photo--top',
	type:'single',
	cpos: 'top',
	read:'綺麗な写真を撮る方法',
},
{
	id: 'info',
	type:'single',
	cpos: 'bottom',
	read:'お知らせ',
},
{
	id: 'item-add',
	type:'single',
	cpos: 'bottom',
	read:'アイテム追加',
},
{
	id: 'lang-en',
	type:'single',
	cpos: 'bottom',
	read:'英語',
},
{
	id: 'lang-ja',
	type:'single',
	cpos: 'bottom',
	read:'日本語',
},
{
	id: 'layout-select',
	type:'r-auto',
	cpos: 'top',
	read:'世界観もあとで変更可能。<br>気軽にいきましょう。',
},
{
	id: 'link-setting',
	type:'single',
	cpos: 'bottom',
	read:'リンク設定',
},
{
	id: 'menu',
	type:'single',
	cpos: 'bottom',
	read:'メニュー',
},
{
	id: 'menu-category',
	type:'r',
	cpos: 'bottom',
	read:'商品カテゴリー: 使用例 カットというカテゴリーをつくり、その中に 高校生カットというアイテムを作成。',
},
{
	id: 'menu-description',
	type:'r',
	cpos: 'bottom',
	read:'商品説明: 店舗によって料金が変わる場合など、補足が必要なメニューに記載します。',
},
{
	id: 'menu-display',
	type:'r',
	cpos: 'bottom',
	read:'アイテムを価格表ページに掲載するかどうか。',
},
{
	id: 'menu-genre',
	type:'r',
	cpos: 'bottom',
	read:'ジャンル名: メニューのカテゴリーは、大・小があり、ジャンルは大カテゴリーになります。',
},
{
	id: 'menu-length',
	type:'r',
	cpos: 'bottom',
	read:'施術時間の目安: 予約機能で使用。',
},
{
	id: 'menu-name',
	type:'r',
	cpos: 'bottom',
	read:'商品名: できるだけ短くすることで見た目と判読性を高めることができます。',
},
{
	id: 'menu-price',
	type:'r',
	cpos: 'bottom',
	read:'商品価格: 変動する料金は「〜以上」に設定してください。',
},
{
	id: 'menu-rs-shops',
	type:'r',
	cpos: 'bottom',
	read:'アイテムの予約受付が可能な店舗を設定。',
},
{
	id: 'menu-use',
	type:'r',
	cpos: 'bottom',
	read:'アイテムを予約ページに掲載するかどうか。掲載すると予約が可能になります。',
},
{
	id: 'mv-addr',
	type:'r',
	cpos: 'bottom',
	read:'メインビジュアルに英語住所を表示します。 ',
},
{
	id: 'mv-addr-tel',
	type:'r',
	cpos: 'bottom',
	read:'電話番号などを入力します。',
},
{
	id: 'mv-addr-time',
	type:'r',
	cpos: 'bottom',
	read:'営業時間の設定がされていると自動で入力されます。',
},
{
	id: 'mv-addr-title',
	type:'r',
	cpos: 'bottom',
	read:'業種や店名を入力します。',
},
{
	id: 'mv-addr-txt',
	type:'r',
	cpos: 'bottom',
	read:'住所を英語で入力します。 住所 英語 変換 で検索し、英語住所への自動変換サービスのご利用をおすすめします。',
},
{
	id: 'mv-bg-col',
	type:'r',
	cpos: 'bottom',
	read:'写真が登録されていない時の背景色。 写真が切り替わる合間にも影響します。 通常は白を使用します。',
},
{
	id: 'mv-co-gallery',
	type:'r',
	cpos: 'bottom',
	read:'メインビジュアルにピックアップしたギャラリーを表示します。',
},
{
	id: 'mv-co-img',
	type:'r',
	cpos: 'bottom',
	read:'メインビジュアルに画像を配置します。 透明の画像をつかうことで自由なデザインが可能です。',
},
{
	id: 'mv-co-img-size',
	type:'r',
	cpos: 'bottom',
	read:'画像サイズを画面に対してのパーセントで指定します。',
},
{
	id: 'mv-co-logo',
	type:'r',
	cpos: 'bottom',
	read:'メインビジュアルにロゴを配置します。',
},
{
	id: 'mv-co-photo',
	type:'r',
	cpos: 'bottom',
	read:'標準設定です。メインビジュアルに写真のみ配置します。',
},
{
	id: 'mv-co-txt',
	type:'r',
	cpos: 'bottom',
	read:'メインビジュアルにテキストを配置します。',
},
{
	id: 'mv-cv-col',
	type:'r',
	cpos: 'bottom',
	read:'サイドカバーのカラーテーマを指定します。',
},
{
	id: 'mv-cv-direction',
	type:'r',
	cpos: 'bottom',
	read:'トップページに道順を表示します。',
},
{
	id: 'mv-cv-direction-decor',
	type:'r',
	cpos: 'bottom',
	read:'お店の雰囲気にあった見た目を指定します。',
},
{
	id: 'mv-cv-direction-txt',
	type:'r',
	cpos: 'bottom',
	read:'短い文で道を説明します。例文を参考に目印や曲がる方向を記載してください。',
},
{
	id: 'mv-cv-topics',
	type:'r',
	cpos: 'bottom',
	read:'トップページに更新情報を表示します。',
},
{
	id: 'mv-opacity',
	type:'r',
	cpos: 'bottom',
	read:'写真の上の文字が見えるように写真を暗くします。 通常は6~20%程度にします。',
},
{
	id: 'mv-txt-col',
	type:'r',
	cpos: 'bottom',
	read:'メインビジュアル内の文字色を設定します。 通常は白を設定します。',
},
{
	id: 'newrelease',
	type:'single',
	cpos: 'bottom',
	read:'新機能&ニュース',
},
{
	id: 'other',
	type:'single',
	cpos: 'bottom',
	read:'その他',
},
{
	id: 'printer',
	type:'single',
	cpos: 'bottom',
	read:'プリンター出力',
},
{
	id: 'page-private',
	type:'single',
	cpos: 'bottom',
	read:'このページを非公開',
},
{
	id: 'plan-change',
	type:'single',
	cpos: 'bottom',
	read:'プラン追加・変更',
},
{
	id: 'plan-change--top',
	type:'single',
	cpos: 'top',
	read:'プラン追加・変更',
},
{
	id: 'post-duplicate',
	type:'single',
	cpos: 'bottom',
	read:'この記事を複製',
},
{
	id: 'preview',
	type:'single',
	cpos: 'bottom',
	read:'別画面でプレビュー',
},
{
	id: 'print-staff',
	type:'single',
	cpos: 'bottom',
	read:'名刺を印刷',
},
{
	id: 'print-store',
	type:'single',
	cpos: 'bottom',
	read:'カードを印刷',
},
{
	id: 'private',
	type:'single',
	cpos: 'bottom',
	read:'非公開にする',
},
{
	id: 'publish',
	type:'single',
	cpos: 'bottom',
	read:'公開する',
},
{
	id: 'publish-date',
	type:'single',
	cpos: 'bottom',
	read:'公開日',
},
{
	id: 'rs-display',
	type:'r',
	cpos: 'bottom',
	read:'サイト表示: ホームページへの掲載と、予約受付が<span class=strong>[受付]</span>になっている場合は指名予約を可能にします。 サイト表示を<span class=strong>[非表示]</span>にして予約受付を<span class=strong>[受付]</span>にすると、予約システムだけで予約が可能な架空の予約スペースを確保できます。',
},
{
	id: 'rs-genre-store-use',
	type:'r',
	cpos: 'bottom',
	read:'予約受付店舗: このジャンルの予約を受け付ける店舗を設定します。',
},
{
	id: 'rs-use',
	type:'r',
	cpos: 'bottom',
	read:'予約受付: 予約が可能になります。',
},
{
	id: 'signup-sitename',
	type:'r',
	cpos: 'bottom',
	read:'ロゴにあたる組織名を入力します。 スローガンなどは省きます。 英語の場合は、半角英数モードで入力してください。',
},
{
	id: 'signup-username',
	type:'r',
	cpos: 'bottom',
	read:'管理画面でのみ使用されます。公開されることはありません。',
},
{
	id: 'sns-url',
	type:'r',
	cpos: 'bottom',
	read:'SNSページのアドレスを入力してください。',
},
{
	id: 'sort-delete',
	type:'single',
	cpos: 'bottom',
	read:'並替え&削除',
},
{
	id: 'staff-add',
	type:'single',
	cpos: 'bottom',
	read:'スタッフ追加',
},
{
	id: 'staff-body',
	type:'r',
	cpos: 'bottom',
	read:'人柄がわかるプロフィールやPR文を書きます。',
},
{
	id: 'genre-hair',
	type:'single',
	cpos: 'top',
	read:'美容室',
},
{
	id: 'genre-coffee',
	type:'single',
	cpos: 'top',
	read:'カフェ',
},
{
	id: 'genre-fashion',
	type:'single',
	cpos: 'top',
	read:'ファッション',
},
{
	id: 'genre-img',
	type:'single',
	cpos: 'top',
	read:'画像アップロード',
},
{
	id: 'staff-img',
	type:'r',
	cpos: 'bottom',
	read:'スタッフ写真をアップロードします。2400px以内のjpg, png形式に対応しています。',
},
{
	id: 'staff-img',
	type:'r',
	cpos: 'bottom',
	read:'スタッフ写真をアップロードできます。2400px以内のjpg, png形式に対応しています。',
},
{
	id: 'staff-job',
	type:'r',
	cpos: 'bottom',
	read:'職種や肩書きを入力します。',
},
{
	id: 'staff-name-en',
	type:'r',
	cpos: 'bottom',
	read:'デザインによっては使用しない場合もあります。入力を推奨します。',
},
{
	id: 'staff-name-ja',
	type:'r',
	cpos: 'bottom',
	read:'日本語で名前を入力します。',
},
{
	id: 'staff-profile',
	type:'r',
	cpos: 'bottom',
	read:'プロフィール: 趣味や自己紹介、人柄がわかる内容を記入します。',
},
{
	id: 'staff-rs-fee',
	type:'r',
	cpos: 'bottom',
	read:'指名料: ウェブ予約時に前もって提示されます。',
},
{
	id: 'staff-rs-store',
	type:'r',
	cpos: 'bottom',
	read:'予約受付店舗: このスタッフを指名できる店舗を選択してください。',
},
{
	id: 'staff-rs-use',
	type:'r',
	cpos: 'bottom',
	read:'予約受付: このスタッフの予約が可能になります。サイト表示が<span class=strong>[非表示]</span>の場合は、指名ができません。',
},
{
	id: 'staff-site',
	type:'r',
	cpos: 'bottom',
	read:'スタッフのブログやウェブサイトのアドレスを入力します。',
},
{
	id: 'staff-store',
	type:'r',
	cpos: 'bottom',
	read:'所属店舗: スタッフページで店舗を基準に絞り込むことができます。',
},
{
	id: 'store-add',
	type:'single',
	cpos: 'bottom',
	read:'お店追加',
},
{
	id: 'store-area',
	type:'r',
	cpos: 'bottom',
	read:'地域を1つの単語で記載します。 どこにある店舗なのかぱっと見でわかるようになります。デザインによっては使用されない場合があります。例: 中央区、都道府県名、地域名など',
},
{
	id: 'store-body',
	type:'r',
	cpos: 'bottom',
	read:'お店の紹介文を入力します。',
},
{
	id: 'store-holiday',
	type:'r',
	cpos: 'bottom',
	read:'お店の定休日を設定します。',
},
{
	id: 'store-launch',
	type:'single',
	cpos: 'bottom',
	read:'公開設定',
},
{
	id: 'store-rs-link',
	type:'r',
	cpos: 'bottom',
	read:'外部で使用しているネット予約のリンクアドレスを入力します。',
},
{
	id: 'mainstore',
	type:'r',
	cpos: 'bottom',
	read:'代表店・本店を設定することができます。フッターなどの住所が表示される部分に代表店の情報が掲載されます。',
},
{
	id: 'store-upgrade',
	type:'single',
	cpos: 'bottom',
	read:'契約店舗数の変更',
},
{
	id: 'store-plan-count',
	type:'r',
	cpos: 'bottom',
	read:'本プロジェクトのご契約中の店舗数です。プランの変更でいつでも数量を変更することができます。',
},
{
	id: 'store-publish-count',
	type:'r',
	cpos: 'bottom',
	read:'ご契約の店舗の数だけ、お店ページを公開することができます。どの店舗を公開するかは、上のアイコンの公開店舗設定から変更することができます。',
},
{
	id: 'target-page',
	type:'single',
	cpos: 'bottom',
	read:'対象ページ',
},
{
	id: 'thumb-aspect',
	type:'r',
	cpos: 'bottom',
	read:'サムネイルの比率を設定します。',
},
{
	id: 'trash',
	type:'single',
	cpos: 'bottom',
	read:'削除',
},
{
	id: 'unsplash-notice',
	type:'single',
	cpos: 'bottom',
	read:'ご利用の注意',
},
{
	id: 'window-l',
	type:'single',
	cpos: 'bottom',
	read:'大きい画面',
},

];
