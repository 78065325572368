import {Component, Vue} from 'nuxt-property-decorator';
import AVueComponent from '@/classes/page/a-vue-component';

@Component({
    components: {
    }
})
export default class MSecondaryPlanUnsubscribe extends AVueComponent {
	
}
