import {Mutation, MutationAction, Action, VuexModule, getModule, Module} from 'vuex-module-decorators';
import store from '@/classes/core/store';
//import {Component} from "nuxt-property-decorator";
import o from '@/classes/core/o';
import VarUtil from '@/classes/utils/var-util';

// state's interface
export interface ITourStore {
	
	active: boolean;
	data: any;
	name: string;
	stepNumber: number;
	
}

@Module({dynamic: true, store, name: 'tour', namespaced: true})
class Tour extends VuexModule implements ITourStore {


	public active: boolean = false;
	public data: any = [];
	public name: string = '';
	public stepNumber: number = 1;
		

    @Mutation
    public updateData(value: any) {
        this.data = value;
    }

    @Mutation
    public updateActive(value: boolean) {
        this.active = value;
    }

    @Mutation
    public updateName(value: string) {
        this.name = value;
    }

    @Mutation
    public updateStepNumber(value: number) {
        this.stepNumber = value;
    }
    
    @Mutation
    public updateFinish(name: string) {
	    
    	console.log(name);
    	const tour: any = this.data.findByKey('name', name);
    	if( !tour ){
	    	return
    	}
    	tour.finish = true;
    	console.log(tour.finish);
    }

}

export const tourModule = getModule(Tour);

