import {appModule} from '@/store/app';
import {AppType} from '@/classes/app/app-const';
import App from '@/classes/app/app';
import {SystemConfig} from '@/configs/system';

export default class AppTypeUtil {

  /**
   * @param host
   * @param config
   */
  public static detect(host: string, config: string[] = SystemConfig.productionHost): AppType {
    return !!(config.find((_: string) => new RegExp(_).test(host))) ?
      AppType.Production : AppType.Ownersite;
  }

  public static get isProduction(): boolean {
    return App.appType === AppType.Production;
  }

  public static get isOwnersite(): boolean {
    return App.appType === AppType.Ownersite;
  }
}
