import {Component, Prop, Vue} from 'nuxt-property-decorator';
import {themeMainModule} from '@/store/theme/theme-main';
import CPatternMix from '@/classes/theme/mixin/c-pattern-mix';
import {DummyStore} from '@/configs/dummy/dummy-store';

import * as $ from 'jquery';

@Component({
	mixins: [
	    CPatternMix,
	],
})
export default class CPageStoreIndexStandard extends Vue {


    public list: any = DummyStore;


	//ACTIVE CATEGORY
	public activeId: string = '0';


	/*
	    PAGE TITLE
	*/

	public get pageId(): any {
		return themeMainModule.pageData.id;
	}

	// すべて画像がなければクラスをいれてデザインを変更
	public get isNoImgs(): any {

    	for (let i = 0 ; i < this.list.length ; i++) {

    	    if (this.list[i].img != '' || this.list[i].img.length > 0) {
        	    return false;
    	    }
    	}

    	return true;
	}



}
