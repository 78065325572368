import {IApiRequest} from '@/classes/core/api/i-api-request';
import {DummyProject} from '@/configs/dummy/dummy-project';
import {DummyPageHome} from '@/configs/dummy/dummy-page-home';
import VarUtil from '@/classes/utils/var-util';
import {DummyPageGallery} from '@/configs/dummy/dummy-page-gallery';
import {DummyArticleCategory} from '@/configs/dummy/dummy-article-category';
import {DummyGallery} from '@/configs/dummy/dummy-gallery';
import {DummyBlog} from '@/configs/dummy/dummy-blog';
import {DummyMNews} from '@/configs/dummy/dummy-m-news';
import {DummyNews} from '@/configs/dummy/dummy-news';
import {DummyStore} from '@/configs/dummy/dummy-store';
import {DummyStaff} from '@/configs/dummy/dummy-staff';
import {DummyMenuItem2} from '@/configs/dummy/dummy-menu-item2';
import ApiConst from "@/classes/core/api/api-const";
import {DummyProjects} from "@/configs/dummy/dummy-projects";
import {managerShopModule} from "@/store/manager/shop";

export default class DummyApi {

    private pjBase = {
        project: DummyProject,
    };

    /**
     * @param req
     */
    public access(req: IApiRequest): any {

        console.log('DummyApi.access() > ', req.url);
        if (/\/api\/v1\/system\/hello$/.test(req.url)) {
            console.log('- hello');
            return {
                result: ApiConst.Res.Ok,
            };
        }

        // Manager ---------------------------------------------------------------------------- //
        if (/\/api\/v1\/owner\/site$/.test(req.url)) {
            return {
                records: {
                    list: DummyProjects.map((_: any) => {
                        return Object.assign({}, _, {
                            dkey: _.subdomainUrl,
                            site_title: _.name,
                        });
                    }),
                },
            };
        }

        if (/\/api\/v1\/owner\/site\/blog\/category$/.test(req.url)) {
            let idx = 1;
            return {
                records: {
                    list: DummyArticleCategory.map((_: any) => {
                        return Object.assign({}, _, {
                            toid: 'd' + idx,
                            title: _.name,
                            rank: idx++,
                        });
                    }),
                },
            };
        }
        if (/\/api\/v1\/owner\/site\/blog\/article$/.test(req.url)) {
            return {
                records: {
                    list: DummyBlog.map((_: any) => {
                        return Object.assign({}, _, {
                            body: "{\"ops\":[{\"insert\":\"テストです\\n\"}]}",
                            categories: [
                                'd1', 'd2', 'd3'
                            ]
                        });
                    }),
                },
            };
        }

        if (/\/api\/v1\/owner\/site\/shop$/.test(req.url)) {
            return {
                records: {
                    list: DummyStore.map((_: any) => {
                        return Object.assign({}, _, {
                            toid: _.id,
                            shop_name: _.title,
                        });
                    }),
                },
            };
        }

        if (/\/api\/v1\/ownersite\/[^\/]+\/$/.test(req.url)) {
            return {
                project: DummyProject,
                content: DummyPageHome
            };
        }

        // Official/Start --------------------------------------------------------------------- //
        if (/\/api\/v1\/official\/start$/.test(req.url)) {
            return {
                transId: 'dummyTransId',
            };
        }

        if (/\/api\/v1\/official\/start\/dummyTransId$/.test(req.url)) {
            return {};
        }

        if (/\/api\/v1\/official\/start\/domain\/valid$/.test(req.url)) {
            return {
                result: ApiConst.Res.Ok,
            };
        }

        if (/\/api\/v1\/official\/start\/user$/.test(req.url)) {
            return {
                result: ApiConst.Res.Ok,
                project: 'dummy-project',
                owner: {
                    toid: 'dummy-owner'
                },
                user: {
                    toid: 'dummy-user',
                },
                token: 'dummy-token',
            };
        }

        if (/\/api\/v1\/official\/start\/workType$/.test(req.url)) {
            return {};
        }

        // Manager ----------------------------------------------------------------------- //
        if (/\/api\/v1\/owner\/site/.test(req.url)) {
            return {
                return: ApiConst.Res.Ok,
                records: {
                    list: [
                        {
                            id: 1,
                            toid: 'dummy-project',
                            site_title: 'COLLECTIVE',
                        },
                    ],
                },
            };
        }


        // Ownersite --------------------------------------------------------------------- //
        if (/\/api\/v1\/ownersite\/[^\/]+\/gallery$/.test(req.url)) {
            return this.gallery();
        }

        if (/\/api\/v1\/ownersite\/[^\/]+\/gallery\/.+$/.test(req.url)) {
            return this.gallery();
        }

        if (/\/api\/v1\/ownersite\/[^\/]+\/staff$/.test(req.url)) {
            console.log('DummyApi.access() > staff');
            return this.staff();
        }

        if (/\/api\/v1\/ownersite\/[^\/]+\/blog$/.test(req.url)) {
            return this.blog();
        }

        if (/\/api\/v1\/ownersite\/[^\/]+\/blog\/.+$/.test(req.url)) {
            return this.blog();
        }

        if (/\/api\/v1\/ownersite\/[^\/]+\/news$/.test(req.url)) {
            return this.news();
        }

        if (/\/api\/v1\/ownersite\/[^\/]+\/menu$/.test(req.url)) {
            return this.menu();
        }

        if (/\/api\/v1\/ownersite\/[^\/]+\/store$/.test(req.url)) {
            return this.store();
        }

        if (/\/api\/v1\/ownersite\/[^\/]+\/store\/.+$/.test(req.url)) {
            return this.store();
        }

        return {
            status: 400,
            error: 'page not found'
        };
    }

    private gallery(): any {
        return VarUtil.export({}, this.pjBase, DummyPageGallery, {
            content: {
                categories: DummyArticleCategory,
                galleries: DummyGallery,
                gallery: DummyGallery[0]
            }
        });
    }

    private staff(): any {
        return VarUtil.export({}, this.pjBase, {
            content: {
                stores: DummyArticleCategory,
                staffs: DummyStaff,
                staff: DummyStaff[0],
            },
        });
    }

    private menu(): any {
        return VarUtil.export({}, this.pjBase, {
            content: {
                menus: DummyMenuItem2,
            },
        });
    }

    private news(): any {
        return VarUtil.export({}, this.pjBase, {
            content: {
                news: DummyNews,
            },
        });
    }

    private blog(): any {
        return VarUtil.export({}, this.pjBase, DummyArticleCategory, {
            content: {
                categories: DummyArticleCategory,
                blogs: DummyBlog,
                blog: DummyBlog[0],
            },
        });
    }

    private store(): any {
        return VarUtil.export({}, this.pjBase, {
            content: {
                stores: DummyStore,
            },
        });
    }
}
