import o from '@/classes/core/o';


export class UiTyping {

    private _timer: number;
    private readonly _typing = {
        idx: 0,
        content: [],
        current: ""
    };
    private readonly _speed = {
        type: 90,
        wait: 3000,
        blank: 1000,
    };
    private _nl2br: boolean = true;
    private _loop: boolean = false;
    private _onUpdateCurrent: (current: string) => void;

    public constructor(option: any = null) {
        const oop = o(option);

        if (oop.has('typing')) {
            this._typing = oop.get('typing');
        }

        if (oop.has('content')) {
            this.typing.content = oop.get('content');
        }

        if (oop.has('current')) {
            this.typing.current = oop.get('current');
        }

        if (oop.has('idx')) {
            this.typing.idx = oop.get('idx');
        }

        if (oop.has('speed')) {
            this._speed = oop.get('speed');
        }

        if (oop.has('nl2br')) {
            this._nl2br = oop.get('nl2br');
        }

        if (oop.has('loop')) {
            this._loop = oop.get('loop');
        }

        if (oop.has('onUpdateCurrent')) {
            this._onUpdateCurrent = oop.get('onUpdateCurrent');
        }
    }

    public get timer(): number {
        return this._timer;
    }

    public get typing(): any {
        return this._typing;
    }

    public get speed(): any {
        return this._speed;
    }

    public get current(): string {
        const r = this.typing.current;
        return (this._nl2br) ? r.replace(/\n/, '<br>') : r;
    }

    public udpateCurrent(current: string) {
        this.typing.current = current;

        if (this._onUpdateCurrent) {
            this._onUpdateCurrent(current);
        }
    }

    public set onUpdateCurrent(value: (current: string) => void) {
        this._onUpdateCurrent = value;
    }

    public start() {

        const d = this.typing;
        if (d.content.length == 0) {
            return;
        }

        const c = d.content[d.idx];
        const p = d.current;

        let to = this.speed.type;
        if (p.length !== c.length) {
            this.udpateCurrent(p + c.substr(p.length, 1));
        }

        if (d.current.length === c.length) {

            if(!this._loop && d.content.length == (d.idx + 1)) {
                this.stop();
                return;
            }

            to = this.speed.wait;
            d.idx += 1;
            if (d.idx >= d.content.length) {
                d.idx = 0;
            }
        }

        this._timer = setTimeout(() => {
            if (d.current.length === c.length) {
                this.udpateCurrent("");
                setTimeout(() => this.start(), this.speed.blank);
            } else {
                this.start();
            }
        }, to) as any;
    }

    public stop() {
        clearTimeout(this._timer);
    }
}
