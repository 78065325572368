
















































	// @ts-ignore
    import _ from './MPolicyFrame.ts'; export default _;
