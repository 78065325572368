








	// @ts-ignore
    import _ from './MSelectInfo.ts'; export default _;
