import {Component, Watch, Prop, Vue} from "vue-property-decorator";
import {themeMainModule} from '@/store/theme/theme-main';
import CPatternMix from '@/classes/theme/mixin/c-pattern-mix';

import CMenuPc from '@/components/theme/page/menu/share/CMenuPc.vue';
import CMenuMb from '@/components/theme/page/menu/share/CMenuMb.vue';

@Component({
	mixins: [CPatternMix],
    components: {
        CMenuPc,
        CMenuMb,
    }
})
export default class CPageMenuIndexStandard extends Vue {


}



