import {Action, getModule, Module, Mutation, VuexModule} from "@/node_modules/vuex-module-decorators";
import store from "@/classes/core/store";
import ManagerApi from "@/classes/app/api/manager-api";
import VarUtil, {$v} from "@/classes/utils/var-util";
import {AuthManager} from "@/classes/app/auth-manager";
import {$apm} from "@/classes/app/app-manager";

export interface IBlogArticle {
    id: number;
    toid: string;
    site_id: number;
    article_key: string;
    title: string;
    body: string;
    contexts: any;
    rank: number;
    is_active: number;
    published_at: string;
    related_facebook: boolean;
    related_twitter: boolean;
    created_at: string;
    deleted_at: string;
    ex: string;
    ex_value: IBlogArticleEx;
    categories: any[];
}

export interface IBlogArticleEx {
    type: string;
    mv: IUserFile | null;
    files: IUserFile[];
}

export interface IUserFile {
    toid: string;
    acc_key: string;
    mime_type: string;
}

export interface IManagerBlogArticleModule {
    editId: string;
    type: string;
    article: IBlogArticle | null;
}

@Module({dynamic: true, store, name: 'managerBlogArticle', namespaced: true})
class Store extends VuexModule implements IManagerBlogArticleModule {

    public editId: string = 'mBlogArticle';
    public article: any = null;
    public type: string = 'blog';
    public articleTemplate = {
        id: 0,
        toid: '',
        site_id: 0,
        article_key: '',
        title: '',
        body: '',
        contexts: null,
        rank: 0,
        is_active: 0,
        published_at: '',
        related_facebook: false,
        related_twitter: false,
        created_at: '',
        deleted_at: '',
        ex: '',
        ex_value: {
            type: '',
            mv: null,
            files: []
        },
        categories: [],
    };

    @Mutation
    public updateEdit(value: any) {
        this.article = VarUtil.path(value, 'article');
    }

    @Mutation
    public updateArticle(value: any) {
        this.article = value;
    }

    @Action
    public async create(param: {
                            type?: string,
                            site?: string,
                            from?: string,
                        } | null = null): Promise<boolean> {

        try {
            console.log('blog-article > create() > ', param);
            const record = await ManagerApi.BlogArticle.post({
                site: $v.p(param, 'site', $apm.siteid),
                type: $v.p(param, 'type', M.type),
                from: $v.p(param, 'from'),
            }).execAsync('record');

            console.log('create() > ', record);

            M.updateArticle({
                ...record,
                ...{
                    categories: []
                },
            });

            return true;
        } catch (e) {
            console.error(e);

            return false;
        }
    }

    @Action
    public async save(param: { publish: boolean, article: any }) {
        console.log('blog save > ', param.article);
        try {
            const res = await ManagerApi.BlogArticle.put(param.article.toid, Object.assign({}, param.article, {
                published_at: param.publish ? VarUtil.datetimeFormat() : null,
            })).execAsync();

            console.log('res > ', VarUtil.path(res, 'data'));

            return true;
        } catch (e) {
            console.error(e);
            return false;
        }
    }
}

export const managerBlogArticleModule = getModule(Store);

const M = managerBlogArticleModule;
