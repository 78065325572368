import {Logos} from "@/configs/logos";
import {themeMainModule} from "@/store/theme/theme-main";
import {ownersiteModule} from "@/store/ownersite";

export class MainModuleBinderHelper {
    public static setWebfonts(map: any, logo: any) {

        let webfonts = {
            mac: {
                adobe: map.adobe,
                monotype: map.monotype,
                google: []
            },

            ios: {
                adobe: map.adobe,
                monotype: map.monotype,
                google: []
            },

            windows: {
                adobe: map.adobe,
                monotype: map.monotype,
                google: [map.google_ja, map.google_en]
            },

            android: {
                adobe: map.adobe,
                monotype: map.monotype,
                google: [map.google_ja, map.google_en]
            }
        };

        /*
            mac_load: Mac, iOSでもGoogle Fontsを読み込むか
        */
        if (map.mac_load) {
            webfonts.mac.google = [map.google_ja, map.google_en];
            webfonts.ios.google = [map.google_ja, map.google_en];
        }

        /*
            ロゴがThought Logosだった場合必要な書体を登録
        */
        if (logo.type == 'webfont') {

            const logodata: any = Logos.findByKey('id', logo.white);
            if (!logodata) {
                return;
            }

            //ロゴデータがはいってなければ追加
            if (!themeMainModule.thoughtLogo) {
                themeMainModule.updateThoughtLogo(logodata);
            }

            //Googleに書体を追加
            webfonts.windows.google.push(themeMainModule.thoughtLogo.mfamily, themeMainModule.thoughtLogo.sfamily);
            webfonts.android.google.push(themeMainModule.thoughtLogo.mfamily, themeMainModule.thoughtLogo.sfamily);
        }

        ownersiteModule.updateWebfonts(webfonts);
    }
}
