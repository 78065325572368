import AVueComponent from "@/classes/page/a-vue-component";
import {managerBlogArticleModule as articleModule} from "@/store/manager/blog-article";
import {StoreAppEditUtil} from "@/classes/view/store/app/store-app-edit-util";
import {ExtEdit} from "@/classes/ext/ext-edit";
import {BlogUtil} from "@/classes/domain/module/model/blog/blog-util";

export abstract class AArticleDetail extends AVueComponent {

    public get extEdit(): ExtEdit {
        return new ExtEdit(this);
    }

    public get editId(): any {
        return articleModule.editId;
    }

    public get edit(): any {
        return StoreAppEditUtil.find(this.editId);
    }

    public get input(): any {
        return this.edit.input;
    }

    public async onInput(e: any) {
        await this.extEdit.updateInput(BlogUtil.filterInput(e, this.input));
    }
}
