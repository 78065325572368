































// @ts-ignore
import _ from './MSelectAddCat.ts';

export default _;
