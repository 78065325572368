import {Component, Watch, Prop, Vue} from '@/node_modules/nuxt-property-decorator';
import APopup from "@/classes/page/a-popup";

import MPopupMBody from '@/components/popup/module/MPopupMBody.vue';
import {popupModule} from "@/store/popup";

@Component({
    components: {
        MPopupMBody
    }
})
export default class MPopupConfirmDelete extends APopup {

    ////////// POPUP LAYER 共通処理 /////////////////////////////////
    public onClose(accept: number = 0): any {
        this.$emit('close', {
            accept,
        });
    }

    // Events /////////////////////////////////////////////////////
    public async onClickOk() {
        this.onClose(1);
    }

    public async onClickCancel() {
        this.onClose();
    }
}
