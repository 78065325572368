import Vue from 'vue';
import SImg from '@/components/foundation/SImg.vue';
import Typing from '@/components/foundation/Typing.vue';
import En from '@/components/foundation/En.vue';
import SLogo from '@/components/foundation/SLogo.vue';
import SBg from '@/components/foundation/SBg.vue';
import Icon from '@/components/foundation/Icon.vue';
import SLoader from '@/components/foundation/part/SLoader.vue';

import MTxtbtn from '@/components/module/MTxtbtn.vue';
import MNotice from '@/components/module/MNotice.vue';
import MHeader from '@/components/module/MHeader.vue';
import MContentsTitle from '@/components/module/MContentsTitle.vue';
import MSelect from '@/components/select/MSelect.vue';
import MInput from '@/components/module/MInput.vue';
import MInview from '@/components/module/MInview.vue';
import MInviewItem from '@/components/module/MInviewItem.vue';
import MTips from '@/components/module/MTips.vue';
import WUtil from '@/classes/view/w-util';
import VarUtil from '@/classes/utils/var-util';

/*

	TEMPLATE

*/
/*
	PAGES
*/
import MPreviewFrame from '@/components/theme/MPreviewFrame.vue';
import CIndex from '@/components/theme/page/index/CIndex.vue';
import CBlogIndex from '@/components/theme/page/blog/CIndex.vue';
import CBlogDetail from '@/components/theme/page/blog/CDetail.vue';
import CContactIndex from '@/components/theme/page/contact/CIndex.vue';
import CContactConfirm from '@/components/theme/page/contact/CConfirm.vue';
import CContactFinish from '@/components/theme/page/contact/CFinish.vue';
import CGalleryIndex from '@/components/theme/page/gallery/CIndex.vue';
import CGalleryDetail from '@/components/theme/page/gallery/CDetail.vue';
import CMenuIndex from '@/components/theme/page/menu/CIndex.vue';
import CNewsIndex from '@/components/theme/page/news/CIndex.vue';
import CStaffIndex from '@/components/theme/page/staff/CIndex.vue';
import CStaffDetail from '@/components/theme/page/staff/CDetail.vue';
import CStoreIndex from '@/components/theme/page/store/CIndex.vue';
import CStoreDetail from '@/components/theme/page/store/CDetail.vue';

import CArticleFilter from '@/components/theme/module/CArticleFilter';
import CArticlePrevnext from '@/components/theme/module/CArticlePrevnext';
import CActiveListItem from '@/components/theme/module/CActiveListItem.vue';
import CBlockTitle from '@/components/theme/module/block/share/CBlockTitle';
import CEditFrame from '@/components/theme/module/CEditFrame';
import CEdit from '@/components/theme/module/CEdit';
import CLink from '@/components/theme/module/CLink';
import CList from '@/components/theme/module/CList';
import CLike from '@/components/theme/module/CLike';
import CListItemDetailHeaderTitle from '@/components/theme/module/block/part/CListItemDetailHeaderTitle.vue';
import CHeaderImg from '@/components/theme/module/CHeaderImg.vue';
import CImgRatio from '@/components/theme/module/CImgRatio';
import CImgHover from '@/components/theme/module/CImgHover';
import CIcon from '@/components/theme/module/CIcon';
import CIconlinkList from '@/components/theme/module/CIconlinkList';
import CInview from '@/components/theme/module/CInview.vue';
import CInviewItem from '@/components/theme/module/CInviewItem.vue';
import CMenuItem from '@/components/theme/module/CMenuItem.vue';
// import CMvContentGallery from '@/components/theme/module/home/mv/CMvContentGallery.vue';
// import CMvContentImg from '@/components/theme/module/home/mv/CMvContentImg.vue';
import CMvContentLogo from '@/components/theme/module/home/mv/CMvContentLogo.vue';
import CMvContentTxt from '@/components/theme/module/home/mv/CMvContentTxt.vue';
import CMvSwiper from '@/components/theme/module/home/mv/CMvSwiper.vue';
import CNewsItem from '@/components/theme/module/CNewsItem.vue';
import CInfoNavigation from '@/components/theme/module/footer/CInfoNavigation';
import CPageTitle from '@/components/theme/module/CPageTitle.vue';
import CPager from '@/components/theme/module/CPager.vue';
import CPrice from '@/components/theme/module/CPrice.vue';
import CSwiperImg from '@/components/theme/module/CSwiperImg';
import CSidenavFrame from '@/components/theme/module/CSidenavFrame';
import CTxtlinkList from '@/components/theme/module/CTxtlinkList';
import CTab from '@/components/theme/module/CTab.vue';

import CBlock from '@/components/theme/module/block/CBlock';
import CBlockImgtxt from '@/components/theme/module/block/CBlockImgtxt.vue';
import CBlockListOneLarge from '@/components/theme/module/block/CBlockListOneLarge.vue';
import CBlockList from '@/components/theme/module/block/CBlockList.vue';
import CBlockBlogNews from '@/components/theme/module/block/CBlockBlogNews.vue';
import CBlockInstagram from '@/components/theme/module/block/CBlockInstagram.vue';
import CBlockMap from '@/components/theme/module/block/CBlockMap.vue';
import Err from '@/components/part/Err';

import {OverlayScrollbarsPlugin} from 'overlayscrollbars-vue';
// import 'overlayscrollbars/css/OverlayScrollbars.css';
// Vue.use(OverlayScrollbarsPlugin);

Vue.component('m-preview-frame', MPreviewFrame);
Vue.component('c-index', CIndex);
Vue.component('c-blog-index', CBlogIndex);
Vue.component('c-blog-detail', CBlogDetail);
Vue.component('c-contact-index', CContactIndex);
Vue.component('c-contact-confirm', CContactConfirm);
Vue.component('c-contact-finish', CContactFinish);
Vue.component('c-gallery-index', CGalleryIndex);
Vue.component('c-gallery-detail', CGalleryDetail);
Vue.component('c-menu-index', CMenuIndex);
Vue.component('c-news-index', CNewsIndex);
Vue.component('c-staff-index', CStaffIndex);
Vue.component('c-staff-detail', CStaffDetail);
Vue.component('c-store-index', CStoreIndex);
Vue.component('c-store-detail', CStoreDetail);

// ----------------------------------------------------------------
Vue.component('c-article-filter', CArticleFilter);
Vue.component('c-article-prevnext', CArticlePrevnext);
Vue.component('c-active-list-item', CActiveListItem);
Vue.component('c-block', CBlock);
Vue.component('c-block-title', CBlockTitle);
Vue.component('c-edit-frame', CEditFrame);
Vue.component('c-edit', CEdit);
Vue.component('c-header-img', CHeaderImg);
Vue.component('c-icon', CIcon);
Vue.component('c-iconlink-list', CIconlinkList);
Vue.component('c-inview', CInview);
Vue.component('c-inview-item', CInviewItem);
Vue.component('c-img-ratio', CImgRatio);
Vue.component('c-img-hover', CImgHover);
Vue.component('c-link', CLink);
Vue.component('c-list', CList);
Vue.component('c-like', CLike);
Vue.component('c-list-item-detail-header-title', CListItemDetailHeaderTitle);
Vue.component('c-menu-item', CMenuItem);
Vue.component('c-mv-content-txt', CMvContentTxt);
Vue.component('c-mv-content-logo', CMvContentLogo);
// Vue.component('c-mv-content-img', CMvContentImg);
// Vue.component('c-mv-content-gallery', CMvContentGallery);
Vue.component('c-mv-swiper', CMvSwiper);
Vue.component('c-info-navigation', CInfoNavigation);
Vue.component('c-news-item', CNewsItem);
Vue.component('c-pager', CPager);
Vue.component('c-page-title', CPageTitle);
Vue.component('c-price', CPrice);
Vue.component('c-sidenav-frame', CSidenavFrame);
Vue.component('c-swiper-img', CSwiperImg);
Vue.component('c-tab', CTab);
Vue.component('c-txtlink-list', CTxtlinkList);

// ----------------------------------------------------------------
Vue.component('c-block-imgtxt', CBlockImgtxt);
Vue.component('c-block-list-one-large', CBlockListOneLarge);
Vue.component('c-block-list', CBlockList);
Vue.component('c-block-blog-news', CBlockBlogNews);
Vue.component('c-block-instagram', CBlockInstagram);
Vue.component('c-block-map', CBlockMap);

// ----------------------------------------------------------------
Vue.component('s-bg', SBg);
Vue.component('s-logo', SLogo);
Vue.component('s-loader', SLoader);
Vue.component('m-header', MHeader);
Vue.component('m-contents-title', MContentsTitle);
Vue.component('m-tips', MTips);
Vue.component('icon', Icon);
Vue.component('m-txtbtn', MTxtbtn);
Vue.component('m-notice', MNotice);
Vue.component('m-input', MInput);
Vue.component('m-inview', MInview);
Vue.component('m-inview-item', MInviewItem);
Vue.component('m-select', MSelect);
Vue.component('en', En);
Vue.component('s-img', SImg);
Vue.component('typing', Typing);
Vue.component('err', Err);


//Windowオブジェクトリンク
WUtil.init();
// Arrayオブジェクト黒魔術
VarUtil.init();
