import ApiConst from "./manager/api-const";
import {$v} from "@/classes/utils/var-util";
import {Env} from "@/classes/app/env";
import App from "@/classes/app/app";
import {SystemConfig} from "@/configs/system";

export default class ApiUtil {

    public static dataRef(dummyHostas: string[] = []): string {

        let ref = Env.DATA_REF;
        (dummyHostas.length ? dummyHostas : SystemConfig.dummyHosts).some((_: string) => {

            const some = (new RegExp(_)).test(App.host);
            if (some) {
                ref = 'dummy';
            }

            return some;
        });

        return ref;
    }

    //

    public static url(path: string) {
        return `${ApiConst.BaseUrl}${path}`.replace(/\/+/, "/");
    }

    public static isErrorAuth(res: any) {
        return $v.p(res, 'responseStatus') === '401';
    }
}
