/*

	YH: Data Spread Sheet is Here
https://docs.google.com/spreadsheets/d/1sBa8xBr9f4YPZ99MQX6SixId22JZ-KrmycufhlWcmfI/edit#gid=1642380410
*/

export const ColorBase = [

{
	id: 'white',
	category: 'white',
	label: 'White',
	txt: '#000000',
	bg: '#FFFFFF',
	link: '',
	wh: '',
	bk: '',
	sidecover: 'white',
},
{
	id: 'bright-white',
	category: 'white',
	label: 'Bright White',
	txt: '#1A1A1A',
	bg: '#FEFEFE',
	link: '',
	wh: '',
	bk: '',
	sidecover: 'white',
},
{
	id: 'snow-white',
	category: 'white',
	label: 'Snow White',
	txt: '#0F0F0F',
	bg: '#FCFCFC',
	link: '',
	wh: '',
	bk: '',
	sidecover: 'white',
},
{
	id: 'off-white',
	category: 'white',
	label: 'Off White',
	txt: '#000000',
	bg: '#FAFAFA',
	link: '',
	wh: '',
	bk: '',
	sidecover: 'off-white',
},
{
	id: 'ghost',
	category: 'white',
	label: 'Ghost',
	txt: '#141414',
	bg: '#F7F7F7',
	link: '',
	wh: '',
	bk: '',
	sidecover: 'off-white',
},
{
	id: 'morning',
	category: 'white',
	label: 'Morning',
	txt: '#1a1a1a',
	bg: '#ffffff',
	link: '',
	wh: '',
	bk: '',
	sidecover: 'off-white',
},
{
	id: 'black',
	category: 'black',
	label: 'Black',
	txt: '#FFFFFF',
	bg: '#000000',
	link: '',
	wh: '',
	bk: '',
	sidecover: 'avantgarde',
},
{
	id: 'photography-black',
	category: 'black',
	label: 'Photography Black',
	txt: '#DEDEDE',
	bg: '#0A0A0A',
	link: '',
	wh: '',
	bk: '',
	sidecover: 'photo-black',
},
{
	id: 'night',
	category: 'black',
	label: 'Night',
	txt: '#FFFFFF',
	bg: '#0E0F12',
	link: '',
	wh: '',
	bk: '',
	sidecover: 'photo-black',
},
{
	id: 'moss-black',
	category: 'black',
	label: 'Moss Black',
	txt: '#FAFAFA',
	bg: '#292929',
	link: '',
	wh: '',
	bk: '',
	sidecover: 'moss-black',
},
{
	id: 'conclete',
	category: 'gray',
	label: 'Conclete',
	txt: '#000000',
	bg: '#E0E0E0',
	link: '',
	wh: '#FFFFFF',
	bk: '',
	sidecover: 'gray',
},
{
	id: 'clay',
	category: 'gray',
	label: 'Clay',
	txt: '#2B2B2B',
	bg: '#D8D8D8',
	link: '',
	wh: '#FFFFFF',
	bk: '',
	sidecover: 'gray',
},
{
	id: 'conclete-milk',
	category: 'gray',
	label: 'Conclete & Milk',
	txt: '#FFFFFF',
	bg: '#C2BFBA',
	link: '',
	wh: '',
	bk: '#000000',
	sidecover: 'gray',
},
{
	id: 'plantation',
	category: 'soft',
	label: 'Plantation',
	txt: '#13140D',
	bg: '#FCF4EA',
	link: '',
	wh: '#FAFAFA',
	bk: '',
	sidecover: 'pastel',
},
{
	id: 'humanity',
	category: 'soft',
	label: 'Humanity',
	txt: '#291F1E',
	bg: '#FCF0EC',
	link: '',
	wh: '#FAFAFA',
	bk: '',
	sidecover: 'pastel',
},
{
	id: 'red',
	category: 'color',
	label: 'Red',
	txt: '#FFFFFF',
	bg: '#FA2020',
	link: '',
	wh: '',
	bk: '',
	sidecover: 'off-white',
},
{
	id: 'blue',
	category: 'color',
	label: 'Blue',
	txt: '#FFFFFF',
	bg: '#112CCF',
	link: '',
	wh: '',
	bk: '#000000',
	sidecover: 'off-white',
},
{
	id: 'orange',
	category: 'color',
	label: 'Orange',
	txt: '#FFFFFF',
	bg: '#FC4B07',
	link: '',
	wh: '',
	bk: '#000000',
	sidecover: 'off-white',
},

];
