




























































	// @ts-ignore
    import _ from './MSecondaryTips.ts'; export default _;
