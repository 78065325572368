import {Component, Watch, Prop, Vue} from '@/node_modules/nuxt-property-decorator';
import {IPopup, popupModule} from '@/store/popup';
import {tourModule} from '@/store/tour';

import MPopupErrorIe from '@/components/popup/MPopupErrorIe.vue';
import MPopupLogoList from '@/components/popup/MPopupLogoList.vue';
import MPopupMes from '@/components/popup/MPopupMes.vue';
import MPopupYesNo from '@/components/popup/MPopupYesNo.vue';
import MPopupSave from '@/components/popup/MPopupSave.vue';
import MPopupLogin from '@/components/popup/MPopupLogin.vue';
import MPopupLaunchWebsite from '@/components/popup/MPopupLaunchWebsite.vue';
import MPopupRequest from '@/components/popup/MPopupRequest.vue';
import MPopupWelcome from '@/components/popup/MPopupWelcome.vue';
import MPopupLayoutPreview from '@/components/popup/MPopupLayoutPreview.vue';

import MPopupMailAuth from '@/components/popup/account/MPopupMailAuth.vue';
import MPopupAccountPayDetail from '@/components/popup/account/MPopupAccountPayDetail.vue';
import MPopupAccountUnsubscribe from '@/components/popup/account/MPopupAccountUnsubscribe.vue';
import MPopupDomainConnectCheckSuccess from '@/components/popup/account/MPopupDomainConnectCheckSuccess.vue';
import MPopupDomainConnectCheckFail from '@/components/popup/account/MPopupDomainConnectCheckFail.vue';

import MPopupNoticeLogoData from '@/components/popup/MPopupNoticeLogoData.vue';

import MPopupUploadImg from '@/components/popup/upload/MPopupUploadImg.vue';
import MPopupUploadImgDrag from '@/components/popup/upload/MPopupUploadImgDrag.vue';
import MPopupUploadImgQr from '@/components/popup/upload/MPopupUploadImgQr.vue';
import MPopupUploadImgMovie from '@/components/popup/upload/MPopupUploadImgMovie.vue';
import MPopupUploadLogoData from '@/components/popup/MPopupUploadLogoData.vue';
import MPopupImgTrimming from '@/components/popup/MPopupImgTrimming.vue';
import MPopupUploadLogo from '@/components/popup/upload/MPopupUploadLogo.vue';
import MPopupUploadPost from '@/components/popup/upload/MPopupUploadPost.vue';
import MPopupPostCopy from '@/components/popup/upload/MPopupPostCopy.vue';

import MPopupConfirmDelete from '@/components/popup/MPopupConfirmDelete.vue';
import MPopupStoreHours from '@/components/popup/MPopupStoreHours.vue';
import MPopupStoreHoliday from '@/components/popup/MPopupStoreHoliday.vue';


import MPopupArticleDetail from '@/components/popup/MPopupArticleDetail.vue';
// 	import MPopupEditor from '@/components/popup/MPopupEditor.vue';
import MPopupMenuGenre from '@/components/popup/MPopupMenuGenre.vue';
import MPopupWebPrivate from '@/components/popup/MPopupWebPrivate.vue';
import MPopupMenuCategory from '@/components/popup/MPopupMenuCategory.vue';

import MPopupPreviewQr from '@/components/popup/MPopupPreviewQr.vue';
import MPopupPageLink from '@/components/popup/MPopupPageLink.vue';
import MPopupConfirm from "@/components/popup/MPopupConfirm.vue";
import {Popup} from "@/configs/popup";
import {$v} from "@/classes/utils/var-util";

@Component({
    components: {

        MPopupErrorIe,
        MPopupLogoList,
        MPopupConfirm,
        MPopupSave,
        MPopupMes,
        MPopupYesNo,
        MPopupLaunchWebsite,
        MPopupRequest,
        MPopupWelcome,
        MPopupLayoutPreview,

        MPopupMailAuth,
        MPopupLogin,
        MPopupAccountPayDetail,
        MPopupAccountUnsubscribe,
        MPopupDomainConnectCheckSuccess,
        MPopupDomainConnectCheckFail,
        MPopupNoticeLogoData,

        MPopupUploadImg,
        MPopupUploadImgQr,
        MPopupUploadImgMovie,
        MPopupUploadImgDrag,
        MPopupImgTrimming,
        MPopupUploadLogo,
        MPopupUploadPost,
        MPopupUploadLogoData,
        MPopupPostCopy,

// 			MPopupEditor,
        MPopupStoreHours,
        MPopupStoreHoliday,
        MPopupConfirmDelete,
        MPopupArticleDetail,

        MPopupMenuGenre,
        MPopupMenuCategory,
        MPopupWebPrivate,

        MPopupPreviewQr,
        MPopupPageLink,
    }
})
export default class MPopupLayer extends Vue {

    @Prop({default: '0'})
    public mid: string;

    @Prop({default: ''})
    public p: string;

    public com: string = '';
    public bg: string = '';
    public size: string = '';
    public title: string = '';
    public titleicon: string = '';
    public body: string = '';
    public img: string = '';
    public closelink: string = '';
    public type: string = '';
    public transition: string = 'm-popup';

    public isDisplay: boolean = false;
    public styles: any = {};

    @Watch('currentPopupId')
    public watchCurrentPopupId(now: string | null, old: string | null) {
        if (!this.isCurrentPopup) {
            return;
        }

        this.setUp();
    }

    @Watch('src')
    public watchSrc(now: IPopup | null) {
        console.log('@watchSrc > ', this.p, popupModule.id, this.src);
        if (this.isCurrentPopup) {
            this.setUp();
        } else if (!$v.p(now, 'open', false)) {
            this.onClose();
        }
    }

    public async mounted() {
        console.log('MPopupLayer > mounted() > ', this.p);

        // console.log('MPoopupLayer.mounted()', this.p, this.mid, this.src, popupModule.popups);
        this.isDisplay = true;
        this.setUp();
    }

    public setUp() {
        console.log('MPoopupLayer.setUp() > this.p >', this.p, this.mid);
        const src = this.src;
        if (src === null && !this.p) {
            return;
        }

        console.log('MPoopupLayer.setUp() > ****** > ', this.p);
        const base = Popup.findByKey('id', !!this.p ? this.p : src.type) || {};
        const option = !!src ? src.option : {};

        this.onOpen({
            ...base,
            ...option,
        });
    }

    // Computed /////////////////////////////////////////////////
    public get currentPopupId() {
        return popupModule.id;
    }

    public get hasP(): boolean {
        return !!this.p && this.p !== '0';
    }

    public get isCurrentPopup() {
        return (this.hasP && popupModule.id === this.p);
    }

    public get src(): IPopup | null {
        return popupModule.popups.findByKey('id', this.mid);
    }

    public get oconponent(): any {
        return this.com;
    }

    public get otransition(): any {
        return this.transition;
    }

    public get isCloseWrapper(): any {
        return popupModule.closeWrapper;
    }

    public onOpen(data: any) {

        // console.error('***' +  this.mid, popupModule.popups);
        console.log('popupLayer.onOpen() > ', data);

        // CloseOptionをリセット
        popupModule.updateLastCloseOption();

        this.com = data.component;
        if (data.bg != '') {
            this.bg = 'm-pop-bg--' + data.bg;
        }
        this.size = 'm-pop--' + data.size;
        this.title = data.title;
        this.titleicon = data.titleicon;
        this.body = data.body;
        this.img = data.img;
        this.closelink = data.closelink;
        this.isDisplay = true;

        this.styles = {
            'z-index': popupModule.num + 1
        };

        if (!data.transition) {
            this.transition = 'm-popup';
        } else {
            this.transition = data.transition;
        }
    }


    public onClose(opt: any = {}) {

        // console.error('***');
        this.isDisplay = false;
        popupModule.updateLastCloseOption({
            ...opt,
            ...{
                mid: this.mid,
                p: this.p,
            },
        });

        console.log('MPopupLayer.onClose()', opt, popupModule.lastCloseOption);

        /*
            /manager/project/
            WELCOMEポップを閉じた時に、TOURを見終えたことにする
        */
        if (this.$nuxt.$route.name == 'manager-project') {
            tourModule.updateFinish('manager-project');
        }

        this.$nextTick(() => {
            popupModule.closePopup($v.p(this, 'mid'));
        });
    }

    // Events ////////////////////////////////
    public async onAfterLeave() {
        if (!this.src) {
            return;
        }

        console.log('MPopupLayer.destroy() > ', this.mid);
        popupModule.removePopup(this.mid);
    }
}
