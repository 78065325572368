import {Component, Watch, Vue} from 'nuxt-property-decorator';
import AVueComponent from '@/classes/page/a-vue-component';
import UseragentUtil from '@/classes/view/useragent-util';
import {appModule} from '@/store/app';
import {themeMainModule} from '@/store/theme/theme-main';
import * as $ from 'jquery';

@Component({
    components: {
    }
})
export default class Print extends AVueComponent {

    @Watch('mainFrameResized')
    public watchMainFrameResized() {
        console.log('manager resized Event');
        this.resizedWindow()
    }

    public get mainFrameResized(): any {
        return themeMainModule.mainFrameResized;
    }

    public resize() {
        themeMainModule.updateMainFrameResized();
    }

    public resizedWindow() {
        const w: number = window.innerWidth;
        const h: number = window.innerHeight;
        appModule.updateMainFrameWidth(w);
        appModule.updateMainFrameHeight(h);
        appModule.updateDeviceSize(UseragentUtil.size(w));
    }

    public get deviceSize(): any {
        return appModule.deviceSize;
    }

}
