import {Component, Vue} from 'vue-property-decorator';

/**
	Tips, Selectの座標計算

 */
@Component
export default class ViewPosMix extends Vue {
	/*
		clickedPos: Clicked Item Pos
		clickedSize: Clicked Item W/H
		popWidth: Select Width
		popHeight: Select Height
		windowWidth: Window Width
		windowHeight: Window Height
		popPosition: 配置位置
		component: Tips Frame, Select Frame Component
	*/
    public posPop(  clickedPos: any, 
				    clickedSize: any, 
				    popWidth: number, 
				    popHeight: number, 
				    windowWidth: number, 
				    windowHeight:number,
				    popPosition: string = 'bottom',
				    component: any ): any {
	    
        let top: number = 0;
        let left: number = 0;
        component.$data['styleArrow'] = {};
	    
        //
        // Y座標
        //
        
        //BOTTOM
        //Top
        if( popPosition == 'top' ){
            
	        //上にはみだしているか
	        //空いているスペースと自分の高さを比較
	        if( clickedPos.top < popHeight ){
	            
	            //はみだしている
	            //画面中央の位置を計算
		        top = 20;
	            
	            //吹き出しのArrowを削除
	            component.$data['isNoArrow'] = true;
	            
	        }else{
		        
	            //はみだしていない
				top = clickedPos.top - clickedSize.h - popHeight;
				console.log(top);
	        	
	            //吹き出しのArrowを表示
	            component.$data['isNoArrow'] = false;
	        }	        
            
		//Bottom
        }else if( popPosition == 'bottom' ){            
        
	        //下にはみだしているか
	        //空いているスペースと自分の高さを比較
	        if( (windowHeight - clickedPos.top) < popHeight + 40 ){
	            	            
	            //はみだしている
	            //画面下からセレクターの高さと40引いた値を入れる
	            const p:number = windowHeight - (popHeight + 40);
	            
	            //CSS側でScroolを表示
	            if( p < 20 ){
		            top = 20;
	            }else{
		            top = p;
	            }
	            
	            //吹き出しのArrowを削除
	            component.$data['isNoArrow'] = true;
	            
	        }else{
	            
	            //はみだしていない
	        	top = clickedPos.top + clickedSize.h;
	        	
	            //吹き出しのArrowを表示
	            component.$data['isNoArrow'] = false;
	        }
	        
        }
        
        
		//
		// X座標
		//
		// 基本X座標
         //Clicked Itemの中央
		left = clickedPos.left + ((clickedSize.w - popWidth) / 2);
		//console.log( `clickedPos.left: ${clickedPos.left} , clickedSize.h: ${clickedSize.h}px, clickedSize.w: ${clickedSize.w}, popWidth: ${popWidth}` );
		
		//画面の左に枠がはみ出していたら、右にずらす
		if( left < 20 ){
			
			const exleft = left;
			left = 20;
			
			//ずれた分の矢印の計算
			const diff_x = exleft - left;
			component.$data['styleArrow'] = {
				left: `calc(50% + ${diff_x}px)`
			}
			
		//画面の右に枠がはみだしていたら、左にずらす
		}else if( popWidth > (windowWidth - left) ){
			
			const exleft = left;
			left = windowWidth - popWidth - 20;
			
			//ずれた分の矢印の計算
			const diff_x = exleft - left;
			component.$data['styleArrow'] = {
				left: `calc(50% + ${diff_x}px)`
			}
		}
		
		return {top: top, left: left};
		
    }

    
    
}