import {Mutation, MutationAction, Action, VuexModule, getModule, Module} from 'vuex-module-decorators';
import VarUtil from "@/classes/utils/var-util";
import store from '@/classes/core/store';

// state's interface
export interface ITabModule {
    tabs: any;
}

@Module({dynamic: true, store, name: 'tab', namespaced: true})
class Tab extends VuexModule implements ITabModule {

    public tabs: any = [];

    @Mutation
    public updateTabs(tabs: any) {
        this.tabs = tabs;
    }

    @Action
    public addTab(tab: any) {

        //同じkeyのTabがなかった時だけ追加
        if (!!VarUtil.find(this.tabs, 'k', tab.k)) {
            return;
        }

        const ntabs = this.tabs.map(_ => _);
        ntabs.push(tab);
        this.updateTabs(ntabs);
        // console.log('added tab');
        console.log("tabs > ", this.tabs);
    }

    @Action
    public updateTab(tab: any) {
        this.updateTabs(this.tabs.map((_: any) => {
            return (tab.k == _.k) ? tab : _;
        }));
    }

    @Action
    public removeTab(tab: any) {
        this.updateTabs(this.tabs.filter((_: any) => {
            return (_.k != tab.key)
        }));
    }

    @Action
    public moveTab(param: {
        k: string,
        active?: number,
        show?: number,
    }) {
        const tab = M.tabs.findByKey('k', param.k);
        if (!tab) {
            return;
        }

        M.updateTab(Object.assign({}, tab, {
            active: VarUtil.path(param, 'active') || tab.active,
            show: VarUtil.path(param, 'show') || tab.show,
        }));
    }
}

export const tabModule = getModule(Tab);

const M = tabModule;
