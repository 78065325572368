export default class TxtUtil {

    /*
        大文字だけか小文字だけか返す
    */
    public static detectCase(value: string): any {

        if (!value) {
            return
        }

        // 最後が 's の場合、他が大文字だったら大文字あつかい
        if (value.slice(-2) == '\'s') {

            const s = value.slice(0, -2);
            if( s.match(/^[A-Z0-9\s]+$/) ){
			    return '-upper';
            }
        }

		//すべて大文字と数字だけ
		else if( value.match(/^[A-Z0-9\s]+$/) ){
			return '-upper';
        }


        else if( value.match(/^[a-z0-9\s]+$/) ){
			return '-lower';
		}

		return '';
    }

}
