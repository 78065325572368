import {Component, Vue} from 'vue-property-decorator';
import {
    ManagerLoaderBg,
    managerLoaderModule,
    ManagerLoaderProgressType,
    ManagerLoaderStyle
} from '@/store/manager/loader';

@Component
export default class ManagerLoaderMix extends Vue {

    public runLoading(module: any = null,
                      mid: string = '@',
                      progressType: ManagerLoaderProgressType = ManagerLoaderProgressType.Auto) {

        console.log('ManagerLoaderMix.runLoading');
        module = module || managerLoaderModule;
        module.init({
            id: mid,
            progress: 0,
            style: ManagerLoaderStyle.Progress,
            progressType,
            title: '',
            body: '',
            bg: ManagerLoaderBg.Fill,
            loading: true,
        });

        // //init | progress / circle
        // module.init('progress');
        // //
        // // //Progress Type | auto / semiauto / manual
        // // //現状autoで代用
        // module.updateProgressType('auto');
        // //
        // // //タイトル
        // module.updateTitle('読み込み中...');
        // //
        // // //背景色の設定 | transparent / light / medium / fill /
        // module.updateBg('fill');
        // //
        // // //ローダーを表示する | Boolean
        // module.updateLoading(true);
    }

//     public get sample1(): string {
//         return 'sample string';
//     }
//
//     public sample2(base: string) {
//         return base + '/' + this.sample1;
//     }
//
//     public sample3(component: any) {
//         return component.$data['base'];
//     }
}
