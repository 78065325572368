import {Component, Watch, Prop, Vue} from "vue-property-decorator";
import {themeMainModule} from '@/store/theme/theme-main';

import * as $ from 'jquery';

@Component
export default class CPopupFrame extends Vue {

	public styleFrame: any = {};
	public step1: number = 5;

	@Watch('mainFrameResized')
	public watchMainFrameResized() {

    	if (themeMainModule.manager) {
            setTimeout( () => {
                this.styleFrame = {
                    top: `${themeMainModule.mainFrameOffset.top}px`,
            	    width: `${themeMainModule.mainFrameWidth}px`,
            	    height: `${themeMainModule.mainFrameHeight}px`,
                };
            }, this.step1);
    	}
	}

	public get mainFrameResized(): any {
	    return themeMainModule.mainFrameResized;
	}


}



