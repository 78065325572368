import {
    ApiExecuterAsync,
    ApiExecuterFactory,
    ApiMethod,
    ApiUtil
} from '@/classes/core/api/import';
import * as objectAssign from 'object-assign';
import VarUtil from '@/classes/utils/var-util';

export default class OfficialStart {

    public transId: string;

    private rparam(param: any): any {
        return VarUtil.export(param, {
            transId: this.transId
        });
    }

    public postStart(): ApiExecuterAsync {
        let url = ApiUtil.url('/official/start');
        let request = ApiExecuterFactory.createRequest({
            method: ApiMethod.Post,
            url: url,
        });

        return ApiExecuterFactory.createAsync(request);
    }

    public postDomain(param: any = {}): ApiExecuterAsync {
        let url = ApiUtil.url('/official/start/domain');
        let request = ApiExecuterFactory.createRequest({
            method: ApiMethod.Post,
            url: url,
            param: this.rparam(param)
        });

        return ApiExecuterFactory.createAsync(request);
    }

    public postAccount(param: any = {}): ApiExecuterAsync {
        let url = ApiUtil.url('/official/start/account');
        let request = ApiExecuterFactory.createRequest({
            method: ApiMethod.Post,
            url: url,
            param: this.rparam(param)
        });

        return ApiExecuterFactory.createAsync(request);
    }

    public postOauth(param: any = {}): ApiExecuterAsync {
        let url = ApiUtil.url('/official/start/oauth');
        let request = ApiExecuterFactory.createRequest({
            method: ApiMethod.Post,
            url: url,
            param: this.rparam(param)
        });

        return ApiExecuterFactory.createAsync(request);
    }

    public postSitename(param: any = {}): ApiExecuterAsync {
        let url = ApiUtil.url('/official/start/sitename');
        let request = ApiExecuterFactory.createRequest({
            method: ApiMethod.Post,
            url: url,
            param: this.rparam(param)
        });

        return ApiExecuterFactory.createAsync(request);
    }

    public postWorktype(param: any = {}): ApiExecuterAsync {
        let url = ApiUtil.url('/official/start/worktype');
        let request = ApiExecuterFactory.createRequest({
            method: ApiMethod.Post,
            url: url,
            param: this.rparam(param)
        });

        return ApiExecuterFactory.createAsync(request);
    }

    public postCommit(param: any = {}): ApiExecuterAsync {
        let url = ApiUtil.url('/official/start/commit');
        let request = ApiExecuterFactory.createRequest({
            method: ApiMethod.Post,
            url: url,
            param: this.rparam(param)
        });

        return ApiExecuterFactory.createAsync(request);
    }

}
