import VarUtil from '@/classes/utils/var-util';
import ImageUtil from '@/classes/utils/image-util';

export default class QuillUtil {
  /**
   * メディアキーカウント
   * @param articles
   */
  public static detectMediaCount(articles: any[]): number {

    let max = 0;
    articles.map((_: any) => {

      if (!VarUtil.has(_, 'img')) {
        return;
      }

      const matched = _.img.match(/([0-9]+)$/);
      if (matched) {
        const n = parseInt(matched[1]);
        console.log('cnt -> ', n);
        if (!isNaN(n) && n > max) {
          max = n;
        }
      }
    });

    return max;
  }

  /**
   *
   * @param mimeType
   * @param prefix
   */
  public static mimeType2Ext(mimeType: string, prefix: string = '.'): string {

    const type = mimeType.match(/[^\/]\/([a-z]+)/)[1];
    const types = {
      jpeg: 'jpg',
      jpg: 'jpg',
      gif: 'gif',
      png: 'png',
      svg: 'svg',
    };

    let ext = VarUtil.has(types, type) ? types[type] : type;

    return prefix.length > 0 ? `${prefix}.${ext}` : ext;
  }

  /**
   *
   * @param dataURI
   */
  public static dataURI2File(name: string, dataURI: string): Blob {

    const byteString = atob(dataURI.split(',')[1]);
    const mimeType = ImageUtil.getMimeTypeByDataURI(dataURI);

    // バイナリからBlobを作成
    const l = byteString.length;
    const content = new Uint8Array(l);
    for (let i = 0; l > i; i++) {
      content[i] = byteString.charCodeAt(i);
    }

    return new File([content], name, {
      type: mimeType || 'application/binary',
    });
  }

  /**
   *
   * @param url
   */
  public static detectMkeyByUrl(url: string): string {
    const matched = url.match(/[\?&]mkey=(.+)$/);
    return (matched) ? matched[1] : '';
  }

  /**
   * @param content
   */
  public static isDataURI(content: string): boolean {
    return /^data:[0-9a-z]+\//.test(content);
  }

  public static bindMedia(content: any, articles: any[]): any {

    if (!VarUtil.has(content, 'ops')) {
      return null;
    }

    return {
      ops: content.ops.map((_: any) => {

        const mkey = VarUtil.path(_, 'insert.image');
        if (!mkey) {
          return _;
        }

        const media = VarUtil.find(articles, 'mkey', mkey);
        if (!VarUtil.has(media, 'file')) {
          return _;
        }

        // console.log('media: ', media);
        const f = media['file'];
        if (!f) {
          return _;
        }
        return {
          insert: {
            image: `/file/${f.acc_key}/m.${f.ext}?mkey=${mkey}`,
          },
          attributes: {
            mkey: mkey
          }
        };
      })
    };
  }

  public static dec2JSON(content: string, length: number | null = null, post: string = '...'): any {
    return JSON.parse(content);
  }

  public static enc2JSON(content: any): any {
    return JSON.stringify(content);
  }

  public static body2Text(body: any, length: number | null = null, pos: string = '...'): string {

    if (typeof (body) == 'string') {
      body = QuillUtil.dec2JSON(body) as any;
    }

    const r = body.ops.map((_: any) => {

      if (typeof (_['insert']) != 'string') {
        return null;
      }

      return _['insert'];

    }).filter((_: string) => (!!_)).join('');

    if (length && r.length > length) {
      return r.substr(0, length) + '...';
    } else {
      return r;
    }
  }
}
