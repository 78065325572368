import {Component, Prop, Vue} from '@/node_modules/nuxt-property-decorator';
import {priceMachineModule} from '@/store/price-machine';

@Component
export default class MPrice extends Vue {


    //文字サイズ
    @Prop({default: 'r'})
    public size: string;

    //通貨
    @Prop({default: '¥'})
    public currency: string;

    //金額
    @Prop({default: 0})
    public value: number | string;

    @Prop({default: 'month'})
    public period: string;

    /*
	    ~など最後につけるテキスト
    */
    @Prop({default: ''})
    public end: string;

    /*
		単位の表示
    */
    @Prop({default: true})
    public unit: boolean;


    // Computed ////////////////////////////////////////////////////

    //期間の表示
    public get txtPeriod(): any {

        if (this.period == 'none') {
            return '';

        }else if (this.period == 'year') {
		    return '年';
	    }
		return '月';
    }

    //Style
    public get sizeClass(): any {
	    if( this.size == 'none' ){
	    	return '';
	    }
	    return 'm-price--' + this.size;
    }

    public get ocurrency(): any {
	    return this.currency;
    }

    public get ovalue(): any {
	    const v = this.toLocale(this.value);
	    return v + this.end;
    }


    // Method ////////////////////////////////////////////////////

    //カンマ区切り高速版
    public toLocale(value: any): any {
        var arr;
        arr = String(value).split('.');
        arr[0] = arr[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
        return arr.join('.');
	}

}
