import {Component, Watch, Prop, Vue} from "vue-property-decorator";
import {themeMainModule} from '@/store/theme/theme-main';

import * as $ from 'jquery';

@Component({
    components: {
    }
})
export default class CHeaderImg extends Vue {


    @Prop({default: '' })
	public src: string;

}



