import {Component, Vue} from 'nuxt-property-decorator';
import AVueComponent from '@/classes/page/a-vue-component';
import {appModule} from '@/store/app';
import {accountModule} from '@/store/account';
import {appAuthModule} from "@/store/app/auth";

@Component({
    components: {
    }
})
export default class MSecondaryAccount extends AVueComponent {

    public open: boolean = false;

    public onOpen() {
        this.open = true;
    }

    public get isOpen(): any {

        if (this.deviceSize != 'mb') {
            return true;
        }

        return this.open;
    }

	public get deviceSize(): any {
    	return appModule.deviceSize;
	}

    public async onClickLogout() {
        console.log('onClickLogout');
        await appAuthModule.logout();
        this.$router.push('/');
        this.$forceUpdate();
    }

    public onClose() {
        this.open = false;
    }

    /*
        モバイルの時にメニューを閉じる
    */
    public onLink(to: string) {
        this.open = false;
        this.$router.push(to);
    }
}
