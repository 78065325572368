import {Component, Watch, Prop, Vue} from "vue-property-decorator";
import {themeMainModule} from '@/store/theme/theme-main';

import {DummyBlog} from '@/configs/dummy/dummy-blog';
import {DummyNews} from '@/configs/dummy/dummy-news';

import CLink from '@/components/theme/module/CLink.vue';

import * as $ from 'jquery';

@Component({
    components: {
	    CLink,
    }
})
export default class CNewsList extends Vue {

	public data: any = [];
	public isUseArticles: boolean = true;

    public created() {

	    //Newsがある
	    if(DummyNews.length > 0) {
		    this.data = DummyNews;

	    //Newsがない BLOGある
	    }else if(DummyBlog.length > 0) {
		    this.data = DummyBlog;

		//どっちもない場合非表示
	    }else{
		    this.isUseArticles = false;
	    }
    }

    public get odata(): any {
	    return this.data.slice(0,4);
    }
}

