import {Action, getModule, Module, Mutation, VuexModule} from "@/node_modules/vuex-module-decorators";
import store from "@/classes/core/store";
import ManagerApi from "@/classes/app/api/manager-api";
import {$v} from "@/classes/utils/var-util";

export interface IManagerSiteMediaModule {

}

@Module({dynamic: true, store, name: 'managerSiteMedia', namespaced: true})
class Store extends VuexModule implements IManagerSiteMediaModule {

    private _medias: any[] = [];

    // Getters //////////////////////////////
    public get medias(): any {
        return this._medias;
    }

    // Mutations ////////////////////////////
    @Mutation
    public updateMedia(value: any) {
        this._medias = this.medias.replaceByKey('toid', $v.p(value, 'toid')).array;
    }

    @Mutation
    public updateMedias(medias: any[]) {
        this._medias = medias;
    }

    // Actions ////////////////////////////
    @Action
    public async save(param: {
        id: string,
        param: any,
    }): Promise<boolean> {
        try {
            const res = await ManagerApi.SiteMedia
                .put(param.id, {
                    update: $v.p(param, 'param.update'),
                    content: JSON.stringify($v.p(param, 'param.content')),
                })
                .execAsync();

            return true;
        } catch (e) {
            console.error($v.p(e, 'response.data'));
            return false;
        }
    }

    @Action
    public async load(param: {
        query: {
            site: string,
            purpose?: string,
        },
    }): Promise<any> {
        try {
            const res = await ManagerApi.SiteMedia.get($v.p(param, 'query')).execAsync();
            const list = $v.p(res, 'records.list');
            console.log('maangerSiteMediaModule.load() > response > ', res, list);
            M.updateMedias(list);
        } catch (e) {

        }
    }
}

export const managerSiteMediaModule = getModule(Store);

const M = managerSiteMediaModule;
