import {Mutation, MutationAction, Action, VuexModule, getModule, Module} from 'vuex-module-decorators';
import store from '@/classes/core/store';

// themeState's interface
export interface IThemeStateModule {

	navOpen: boolean;
	navOpenPc: boolean;

	//CSelect
	select: any;

	//Sidecover
	sidecoverDisplay: boolean;

	//Navigation
	navPc: any;

    // Home でしたにスクロールした時にナビゲーションの色を黒にするレイアウトのときに色を切り替えた時にtrueにする
	switchScrollDownReverseColor: boolean;

	//MVの画像の明るさ bk or wh
	mvImageBrightness: string;

	//Loading
	loadingMain: boolean;
	loadingLogo: boolean;

}

@Module({dynamic: true, store, name: 'themeState', namespaced: true})
class ThemeState extends VuexModule implements IThemeStateModule {


	public navOpen: boolean = false;
	public navOpenPc: boolean = false;
    public select: any = null;
    public sidecoverDisplay: boolean = true;
    public navPc: any = {};
    public switchScrollDownReverseColor: boolean = false;
    public mvImageBrightness: string = '';
    public loadingMain: boolean = false;
    public loadingLogo: boolean = false;

    //Slider
    public slideActiveIndex: number = 1;


    @Mutation
    public updateNavPc(value: any) {
        this.navPc = value;
    }

    @Mutation
    public updateNavOpen(value: boolean) {
        this.navOpen = value;
    }

    @Mutation
    public updateNavOpenPc(value: boolean) {
        this.navOpenPc = value;
    }

    @Mutation
    public clearSelect() {
        this.select = null;
    }

    @Mutation
    public updateSelect(c: any = null) {
        this.select = c;
    }

    @Mutation
    public updateSidecoverDisplay(value: boolean) {
        this.sidecoverDisplay = value;
    }

    @Mutation
    public updateSwitchScrollDownReverseColor(value: boolean) {
        this.switchScrollDownReverseColor = value;
    }

    @Mutation
    public updateMvImageBrightness(value: string) {
        this.mvImageBrightness = value;
    }

    @Mutation
    public updateLoadingMain(value: boolean) {
        this.loadingMain = value;
    }

    @Mutation
    public updateLoadingLogo(value: boolean) {
        this.loadingLogo = value;
    }

}

export const themeStateModule = getModule(ThemeState);

