import {Component} from "nuxt-property-decorator";
import APopup from '@/classes/page/a-page';

//upload-layerの表示管理
import {uploadlayerModule} from '@/store/uploadlayer';

import MPopupUploadImgDrag from "@/components/popup/upload/MPopupUploadImgDrag.vue";
import MPopupUploadImgUnsplash from '@/components/popup/upload/MPopupUploadImgUnsplash.vue';

@Component({
    components: {
        MPopupUploadImgDrag,
        MPopupUploadImgUnsplash
    }
})
export default class MPopupUploadImg extends APopup {


    ////////////// UPLOAD LAYER 共通 ///////////////////////////////////
    //初期表示のupload-layerをセット
    public created() {
        uploadlayerModule.updateLayer('select-img');
    }

    //upload-layerを表示
    public get page(): any {
        return uploadlayerModule.layer;
    }

    //upload-layerの移動
    public on(to: string) {
        uploadlayerModule.updateLayer(to);
    }

    public onClose(to: string = ''): any {
        this.$emit('close');
    }
}
