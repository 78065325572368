var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"m-page-note"},[_c('div',{staticClass:"m-block"},[_c('div',{staticClass:"m-note-title"},[_c('en',{attrs:{"s":"a Domain for <br>Hashimoto salonあ"}})],1),_vm._v(" "),_c('div',{staticClass:"m-list -list-link -step"},[_c('nuxt-link',{staticClass:"m-list-item",class:{
						'state-active' : _vm.path('/manager/account/procedure/domain-subscribe/search'),
						'state-go' : _vm.path('/manager/account/procedure/common/mail-auth') || _vm.path('/manager/account/procedure/common/payment') ||  _vm.path('/manager/account/procedure/common/confirm')
					},attrs:{"to":"/manager/account/procedure/domain-subscribe/search"}},[_c('div',{staticClass:"m-list-item-left"},[_vm._v("希望ドメインの入力")])]),_vm._v(" "),_c('nuxt-link',{staticClass:"m-list-item",class:{
						'state-active' : _vm.path('/manager/account/procedure/common/payment') || _vm.path('/manager/account/procedure/common/mail-auth'),
						'state-go' : _vm.path('/manager/account/procedure/common/confirm')
					},attrs:{"to":"/manager/account/procedure/common/payment"}},[_c('div',{staticClass:"m-list-item-left"},[_vm._v("お支払方法の選択")])]),_vm._v(" "),_c('nuxt-link',{staticClass:"m-list-item",class:{
						'state-active' : _vm.path('/manager/account/procedure/common/confirm')
					},attrs:{"to":"/manager/account/procedure/common/confirm"}},[_c('div',{staticClass:"m-list-item-left"},[_vm._v("内容の確認")])]),_vm._v(" "),_c('div',{staticClass:"m-list-item",class:{
						'state-active' : _vm.path('/manager/account/procedure/common/thanks')
					}},[_c('div',{staticClass:"m-list-item-left"},[_vm._v("完了")])])],1)]),_vm._v(" "),_c('div',{staticClass:"m-device-background"})])}
var staticRenderFns = []

export { render, staticRenderFns }