import {Component, Watch, Vue} from 'nuxt-property-decorator';
import VarUtil from '@/classes/utils/var-util';
import ColorUtil from '@/classes/view/color-util';
import {themeMainModule} from '@/store/theme/theme-main';
import {themeCssvarsModule} from '@/store/theme/theme-cssvars';

import {ColorBase} from '@/configs/color/color-base';
import {ColorSidecover} from '@/configs/color/color-sidecover';
import {ColorSidecoverTxt} from '@/configs/color/color-sidecover-txt';
import {ColorMvTxt} from '@/configs/color/color-mv-txt';

import {DummyCssvars} from '@/configs/dummy/dummy-cssvars';


@Component
export default class CCssvarsMix extends Vue {

    /*
        Init by DummyCssvars
    */
    public updateCssvars(module: any, module2: any) {

        const cssvars: any = DummyCssvars;
        const labelCustom: string = 'Custom';

        this.headerColor();

        // By Brand
        themeCssvarsModule.updateMvAddressTxtBase(themeMainModule.layout.mv.addressTxtBase);

        // 色指定の判断基準をセット
        themeCssvarsModule.updateMvTxtBase(cssvars.mvTxtBase);
        themeCssvarsModule.updateSidecoverTxtMode(cssvars.sidecoverTxtMode);
        themeCssvarsModule.updateSidecoverId(cssvars.sidecoverId);


        // By User Settings
        /*
            ベースカラー
            カスタマイズされていなければ、テンプレートから取得する
        */
    	const base: any = ColorBase.findByKey('id', cssvars.baseId);
    	if (!base) { return }

        themeCssvarsModule.updateBaseLabel(base.label);

    	//テンプレートの色と違ったらカスタマイズされている
//         if (base.txt == cssvars.txt && base.bg == cssvars.bg) {
//             themeCssvarsModule.updateBaseLabel(base.label);
//         } else {
//             themeCssvarsModule.updateBaseLabel(labelCustom);
//         }

//         if (base.txt == cssvars.txt) {
//             themeCssvarsModule.updateBaseTxtLabel(base.label);
//         } else {
//             themeCssvarsModule.updateBaseTxtLabel(ColorUtil.rgbToHex(cssvars.txt));
//         }
//
//         if (base.bg == cssvars.bg) {
//             themeCssvarsModule.updateBaseBgLabel(base.label);
//         } else {
//             themeCssvarsModule.updateBaseBgLabel(ColorUtil.rgbToHex(cssvars.bg));
//         }

        themeCssvarsModule.setBase(base);

        //サイドカバー
        //カスタマイズされていなければ、テンプレートから取得する
    	const sidecover: any = themeCssvarsModule.sidecovers.findByKey('id', cssvars.sidecoverId);
    	if (!sidecover) { return }

    	//テンプレートの色と違ったらカスタマイズされている
        if (cssvars.sidecoverTxtMode != 'custom') {
        	const a: any = ColorSidecoverTxt.findByKey('value', themeCssvarsModule.sidecoverTxtMode);
        	if (!a) { return }

            themeCssvarsModule.updateSidecoverLabel(sidecover.label);
            themeCssvarsModule.updateSidecoverTxtLabel(a.label);

        } else {
            themeCssvarsModule.updateSidecoverLabel(labelCustom);
            themeCssvarsModule.updateSidecoverTxtLabel(ColorUtil.rgbToHex(cssvars.sidecoverTxt));
        }

        if (sidecover.bg == cssvars.sidecoverBg) {
            themeCssvarsModule.updateSidecoverBgLabel(sidecover.label);

        } else {
            themeCssvarsModule.updateSidecoverBgLabel(ColorUtil.rgbToHex(cssvars.sidecoverBg));
        }

        themeCssvarsModule.setSidecover(sidecover);

        //キーカラーは、現在未使用
        themeCssvarsModule.updateKey(cssvars.key);
        themeCssvarsModule.updateMvBg(cssvars.mvBg);
        themeCssvarsModule.updateMvOpacity(cssvars.mvOpacity);


        /*
            Labelを設定
        */
        if (themeCssvarsModule.mvTxtBase != 'custom') {
        	const a: any = ColorMvTxt.findByKey('value', themeCssvarsModule.mvTxtBase);
        	if (!a) { return }

            themeCssvarsModule.updateMvTxtLabel(a.label);

        } else {
            themeCssvarsModule.updateMvTxtLabel(ColorUtil.rgbToHex(themeCssvarsModule.mvTxt));

        }

    }

    ////////////////////////////////////////////

//     @Watch('txt')
//     public watchTxt(value: string) {
//         themeCssvarsModule.setTxt(value);
//     }
//
//     public get txt(): any {
//         return themeCssvarsModule.txt;
//     }

    ////////////////////////////////////////////

//     @Watch('bg')
//     public watchBg(value: string) {
//         themeCssvarsModule.setBg(value);
//     }
//
//     public get bg(): any {
//         return themeCssvarsModule.bg;
//     }

    ////////////////////////////////////////////

    @Watch('sidecoverTxtMode')
    public watchSidecoverTxtMode(value: string) {
        themeCssvarsModule.updateSidecoverTxtMode(value);
        themeCssvarsModule.setSidecoverTxt();
    }

    public get sidecoverTxtMode(): any {
        return themeCssvarsModule.sidecoverTxtMode;
    }

    ////////////////////////////////////////////

    @Watch('mvTxtBase')
    public watchMvTxtBase(value: string) {
        themeCssvarsModule.updateMvTxtBase(value);
        themeCssvarsModule.setMvTxtBase();
    }

    public get mvTxtBase(): any {
        return themeCssvarsModule.mvTxtBase;
    }

    ////////////////////////////////////////////

//     @Watch('navTxtBase')
//     public watchNavTxtBase(value: string) {
//         themeCssvarsModule.updateNavTxtBase(value);
//         themeCssvarsModule.setNavTxtBg();
//     }
//
//     public get navTxtBase(): any {
//         if (!themeMainModule.layout.length) {
//             return;
//         }
//         return themeMainModule.layout.navigation.txtBase;
//     }

    ////////////////////////////////////////////

    @Watch('mvAddressTxtBase')
    public watchMvAddressTxtBase(value: string) {
        themeCssvarsModule.updateMvAddressTxtBase(value);
        themeCssvarsModule.setMvAddressTxt();
    }

    public get mvAddressTxtBase(): any {
        if (!themeMainModule.layout.length) {
            return;
        }
        return themeMainModule.layout.mv.addressTxtBase;
    }

    ////////////////////////////////////////////

    /*
        Get Cssvars
    */
    public get cssVars(): any {

        if (
            !themeMainModule.layout
            || !themeMainModule.brand
            || !themeCssvarsModule.txt
            || !themeCssvarsModule.mvAddressTxt
        ) {
            return;
        }

        return {

    		'--txt': themeCssvarsModule.txt,
    		'--bg': themeCssvarsModule.bg,
    		'--bk': themeCssvarsModule.bk,
    		'--wh': themeCssvarsModule.wh,
    		'--link': themeCssvarsModule.link,
    		'--key': themeCssvarsModule.key,

    		'--nav-txt': themeCssvarsModule.navTxt,
    		'--nav-bg': themeCssvarsModule.navBg,
    		//'--nav-bg-op': themeMainModule.layout.navigation.bgOp,

    		'--mv-txt': themeCssvarsModule.mvTxt,
    		'--mv-txt-against': themeCssvarsModule.mvTxtAgainst,
    		'--mv-bg': themeCssvarsModule.mvBg,

            '--mv-address-txt': themeCssvarsModule.mvAddressTxt,
//             '--mv-address-bg-op': themeMainModule.layout.mv.addressBgOp,

            '--sidecover-txt': themeCssvarsModule.sidecoverTxt,
            '--sidecover-bg': themeCssvarsModule.sidecoverBg,

    		'--mv-opacity': themeCssvarsModule.mvOpacity,

//             '--logo-scale': themeCssvarsModule.navLogoScale,
//             '--mv-logo-scale': themeCssvarsModule.mvContentLogoScale,
//             '--loader-stroke-width': themeMainModule.brand.design.loaderStrokeWidth + 'px',
//             '--border-width': themeMainModule.brand.design.borderWidth + 'px',
//             '--ratio-horizontal': themeMainModule.brand.design.ratioHorizontal + '%',
//             '--ratio-vertical': themeMainModule.brand.design.ratioVertical + '%',
        }

    }

    public get cssVarsManager(): any {

        if ( !themeMainModule.layout) {
            return;
        }

        return {
    		'--txt': themeCssvarsModule.txt,
    		'--bg': themeCssvarsModule.bg,
    		'--bk': themeCssvarsModule.bk,
    		'--wh': themeCssvarsModule.wh,
    		'--mv-txt': themeCssvarsModule.mvTxt,
    		'--mv-bg': themeCssvarsModule.mvBg,
            '--sidecover-txt': themeCssvarsModule.sidecoverTxt,
            '--sidecover-bg': themeCssvarsModule.sidecoverBg,
        }

    }

    /*
        Headerの色の種類を設定する
    */
    public headerColor(reverse: boolean = false) {


        if (!process.client) { console.log(`Error`); themeCssvarsModule.updateNavTxtBase('txt'); }

        const el = document.getElementsByClassName('c-nav-frame')[0];

        // CSS Variablesの変更を検知するために一瞬ずらす
        setTimeout(() => {

            //CSS Variableから取得
            const col = window.getComputedStyle(el).getPropertyValue('--header-color');

            //スペースとダブルクォーテーション、クォーテーションをとる
            const result = col.replace(/"|\s+|'/g, '');

            //Storeに保存する
            if (result == 'mvtxt') {

                //実際のmvTxt Colorを　bk or wh にする
                themeCssvarsModule.updateNavTxtBase(ColorUtil.blackOrWhite(themeCssvarsModule.mvTxt));

            } else {
                themeCssvarsModule.updateNavTxtBase('txt');

            }
        }, 2);
    }




}
