import {Component, Prop, Vue} from "nuxt-property-decorator";
import {themeMainModule} from '@/store/theme/theme-main';

export default class ACBlock extends Vue {


	// ID, KEY
    @Prop({default: 'concept'})
	public k: string;


    // BLOCK COUNT
    @Prop({default: 1})
	public num: number;


    /*
        INDEX BLOCK基本データ
            id: concept, gallery etc
            name: Page Name
            aspect: auto, horizontal, etc

            component: CBlockImgtxt
            data: どのデータを使うか
            column: リストのカラム設定
            link.link: ページのリンク先
            link.name: ページのリンクラベル
            title:
            body:
            img:


        PAGEから渡されるデータ
            id: concept, gallery etc
            name: Page Name
            aspect: auto, horizontal, etc'
            column: リストのカラム設定

            type: 'page'
            pattern: {pagename: Component Name}

    */
    @Prop({default: () => {}})
	public d: any;


    /*
        DummeHomeのデータから指定Blockのデータを取得
        設定画面: manager/project/home/
    */
    public get blockData(): any {

        /*
            INDEXの場合
            自分のBLOCKを探してデータをもってくる
        */
        if(themeMainModule.pagePage == 'index'){

        	const d: any = themeMainModule.homeBlockData.findByKey('id', this.k );
        	if( !d ){
    	    	return
        	}
            return d;
        }

        /*
            下階層
            PROPSをみる
        */
        else{
            return this.d;
        }

        return this.d
    }


    public init(k: string) {
    }


	public get deviceSize(): any {
		return themeMainModule.deviceSize;
	}


    /*
        ID
    */
    public get sid(): any {

        // PROPSを優先
        if(this.k){
            return this.k;
        }

        if(!this.blockData.id){
            return ''
        }

        return this.blockData.id;
    }


    public get stitle(): any {

        if(!this.blockData.title){
            if(!this.blockData.name){
                return
            }
            return this.blockData.name;
        }

        return this.blockData.title;
    }


    public get sname(): any {
        return this.blockData.name;
    }


	public get saspect(): any {

        if(!this.blockData.aspect){
            return
        }

		if(this.blockData.aspect) {
			return this.blockData.aspect;
		}

		return 'auto';
	}


    public get link(): any {

        /*
            linkがなかった場合、idを設定
        */
        if(!this.blockData.link){
            return {link: this.sid}
        }
        return this.blockData.link;
    }


    /*
        ADD CLASS
    */
    public get sClassBlockId(): any {
        return '-block-' + this.sid;
    }


    public get sClassId(): any {
        return '-' + this.sid;
    }


    /*
        IF
    */
	public get isLink(): any {
		if(this.blockData.link) {
			return true;
		}
		return false;
	}


	public get isImg(): any {
		if(this.blockData.img[0]) {
			return true;
		}
		return false;
	}



}






