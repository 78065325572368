import {Component, Prop, Vue} from '@/node_modules/nuxt-property-decorator';
import {tabModule} from '@/store/tab';

import MTabContents from '@/components/module/MTabContents.vue';

@Component
export default class MTab extends Vue {
   	
   	//Tab Key
    @Prop({default: 'tab' })
    public k: string;
    
   	//Tab Map
    @Prop({default: () => ({}) })
    public map: any;
    
    //初期表示タブ
    @Prop({default: 1})
    public init: number;
    
    public tab: any = {};
	public isPrev: boolean = false;
	
	public created(){
				
		//Tabをリセット
		this.tab = {
			k: this.k, //Tab Key
		    show: this.init, //遷移するContents番号
		    active: this.init, //アクティブさせるタブの番号
		    map: this.map //Tab Map
		}
		
    	//console.log(`active: ${this.tab.active}`);
		
		tabModule.addTab( this.tab );
	}
	
	//タブのState
	public get stateActive(): any {
		
    	const tab:any = tabModule.tabs.findByKey('k', this.k );
    	if(!tab){
	    	return;
    	}
    	
    	//console.log(`${this.k} | active: ${tab.active}`);
    	
		return tab.active;
		
	}
	
	//タブの移動
	public onTab(show: number = 1, active: number = 1){
		
    	const tab:any = tabModule.tabs.findByKey('k', this.k );
    	if(!tab){
	    	return;
    	}
		
		if( show < tab.show ){
			this.isPrev = true;
		}else{
			this.isPrev = false;
		}
		
		tab.show = show;
		tab.active = active;
		
		//タブデータをSTOREに保存
		tabModule.updateTab(tab);
		
	}
	
	
}
