import {Mutation, MutationAction, Action, VuexModule, getModule, Module} from 'vuex-module-decorators';
import store from '@/classes/core/store';

// state's interface
export interface IOfficialModule {

    introWatched: boolean;
    navOpen: boolean;
}

@Module({dynamic: true, store, name: 'official', namespaced: true})
class Official extends VuexModule implements IOfficialModule {

    public introWatched: boolean = false;
    public navOpen: boolean = false;


    @Mutation
    public updateIntroWatched(value: boolean = false) {
        this.introWatched = value;
    }

    @Mutation
    public openNav() {
        this.navOpen = true;
    }

    @Mutation
    public closeNav() {
        this.navOpen = false;
    }

    @Mutation
    public toggleNav() {
        this.navOpen = !this.navOpen;
    }
}

export const officialModule = getModule(Official);

