import {ImageService} from "@/classes/domain/module/manager/image/image-service";
import {ImageUploaderService} from "@/classes/domain/module/manager/image/image-uploader-service";
import {SiteStartService} from "@/classes/domain/module/manager/site/service/site-start-service";
import {MediaNavigator} from "@/classes/domain/module/model/media-navigator";
import {SiteMediaService} from "@/classes/domain/module/manager/site/service/site-media-service";

export class ManagerService {

    // Media Access
    public static mediaNavigator(model: any): MediaNavigator {
        return new MediaNavigator(model);
    }

    public static get siteMedia(): SiteMediaService {
        return new SiteMediaService();
    }

    public static get image(): ImageService {
        return new ImageService();
    }

    public static get imageUploader(): ImageUploaderService {
        return new ImageUploaderService();
    }

    public static get siteStart(): SiteStartService {
        return new SiteStartService();
    }
}

export const MS = ManagerService;
