import {
    ApiExecuterAsync,
    ApiExecuterFactory,
    ApiMethod,
    ApiUtil
} from '@/classes/core/api/import';
import {vsprintf} from 'sprintf-js';
import ApiConst from '@/classes/core/api/api-const';

export default class FileUpload {

    public show(token: string): ApiExecuterAsync {
        let url = ApiUtil.url(vsprintf('/file/upload/%s', [token]));
        let request = ApiExecuterFactory.createRequest({
            method: ApiMethod.Get,
            url: url
        });

        return ApiExecuterFactory.createAsync(request);
    }

    public store(param: any): ApiExecuterAsync {
        let url = ApiUtil.url('/file/upload');
        let request = ApiExecuterFactory.createRequest({
            method: ApiMethod.Post,
            enctype: ApiConst.EncType.Multipart,
            url: url,
            param: param
        });

        return ApiExecuterFactory.createAsync(request);
    }

    public patch(param: any): ApiExecuterAsync {
        let url = ApiUtil.url('/file/upload/' + param.token);
        let request = ApiExecuterFactory.createRequest({
            method: ApiMethod.Patch,
            url: url,
            param: param
        });

        return ApiExecuterFactory.createAsync(request);
    }
}
