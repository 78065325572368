import {Component, Vue, Prop} from '@/node_modules/nuxt-property-decorator';
import {themeCssvarsModule} from '@/store/theme/theme-cssvars';
import {ColorSidecoverTxt} from '@/configs/color/color-sidecover-txt';
import {selectModule} from "@/store/select";

@Component({
    components: {
    }
})
export default class MSelectColorSidecoverTxt extends Vue {

    public d: any = ColorSidecoverTxt;

    public onClick(value: any) {
        themeCssvarsModule.updateSidecoverTxtMode(value.value);
        themeCssvarsModule.setSidecoverTxt();
        themeCssvarsModule.updateSidecoverTxtLabel(value.label);
        selectModule.updateCurrent(false);
    }

    public classColor(value: string) {

        if (value) {
            return '-' + value;
        }

        return;
    }


   	/////// 共通処理 /////////////////////////////////
    ////////// POPUP LAYER 共通処理 /////////////////////////////////
	public onClose(to: string=''): any {
		if(to != ''){
			this.$router.push(to);
		}
        this.$emit('close');
	}


}
