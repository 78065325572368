

























































































	// @ts-ignore
    import _ from './MPopupImgTrimming.ts'; export default _;
