import {Component, Prop, Vue} from 'nuxt-property-decorator';
import {themeMainModule} from '@/store/theme/theme-main';
import CPatternMix from '@/classes/theme/mixin/c-pattern-mix';
import CStyleListMix from '@/classes/theme/mixin/style/c-style-list-mix';

import {DummyBlog} from '@/configs/dummy/dummy-blog';
import CArticleFooter from '@/components/theme/module/CArticleFooter';

@Component({
	mixins: [
	    CPatternMix,
	    CStyleListMix,
	],
    components: {
        CArticleFooter
    }
})
export default class CPageBlogDetailStandard extends Vue {

    @Prop({default: '0'})
	public active: string;

    public relatedMax: number = 12;
    public list: any = DummyBlog.slice(0, this.relatedMax);


    public textlink: any = [
	    {
		    name: 'facebook',
		    link: 'https://www.facebook.com/sharer/sharer.php?u=https://thought-brand.com/'
	    },
	    {
		    name: 'twitter',
		    link: 'https://twitter.com/share?url=https://thought-brand.com/'
	    },
	    {
		    name: 'pocket',
		    link: 'http://getpocket.com/edit?url=https://thought-brand.com/&title=THOUGHT'
	    },
    ];


	public categoryList: any = [
	    {
		    name: 'Category A',
		    link: '/gallery/'
	    },
	    {
		    name: 'Category B',
		    link: '/gallery/'
	    },
	    {
		    name: 'Category C',
		    link: '/gallery/'
	    },
	];

    public get isVisual(): any{
	    return true;
    }

    public get articleFooterContent(): any {
        return {
            date: 'December 16, 2020',
            textlink: this.textlink,
            categories: this.categoryList,
        };
    }

}



