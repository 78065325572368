import {Component, Watch, Prop, Vue} from '@/node_modules/nuxt-property-decorator';
import APopup from "@/classes/page/a-popup";

import MPopupMBody from '@/components/popup/module/MPopupMBody.vue';
import MUploadimg from '@/components/module/MUploadimg.vue';
import MTextarea from '@/components/module/MTextarea.vue';

@Component({
    components: {
	    MPopupMBody,
		MTextarea,
		MUploadimg,
    }
})
export default class MPopupRequest extends APopup {

    ////////// POPUP LAYER 共通処理 /////////////////////////////////
	public onClose(to: string=''): any {
		if(to != ''){
			this.$router.push(to);
		}
        this.$emit('close');
	}
}
