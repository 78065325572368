/*

	YH: Data Spread Sheet is Here
	https://docs.google.com/spreadsheets/d/1LU73eygtCKuQDHk5ezhlTSXi4wo650g0pEm5kdNfiq4/edit?usp=sharing

*/

export const LogoSize = [

    {
        id: 'normal',
        sizeAcc: 3680,
        ratioAcc: 0.15,
        ratioAccAcc: 5,
        webfontAcc: 4.08,
        webfontSize: 0.84,
        webfontAccMin: 136,
        webfontAccLength: 0.49,
        webfontTaglineAcc: 1.22,
    },
    {
        id: 'queen',
        sizeAcc: 3000,
        ratioAcc: 0.362,
        ratioAccAcc: 30,
        webfontAcc: 4.08,
        webfontSize: 0.988,
        webfontAccMin: 136,
        webfontAccLength: 0.49,
        webfontTaglineAcc: 1.16,
    },
    {
        id: 'mv-logo',
        sizeAcc: 9800,
        ratioAcc: 0.15,
        ratioAccAcc: 18,
        webfontAcc: 4.08,
        webfontSize: 0.464,
        webfontAccMin: 136,
        webfontAccLength: 0.49,
        webfontTaglineAcc: 1.16,
    },
    {
        id: 'account-logo',
        sizeAcc: 510,
        ratioAcc: 0.45,
        ratioAccAcc: 8,
        webfontAcc: 4.36,
        webfontSize: 2.5,
        webfontAccMin: 1000,
        webfontAccLength: 0.01,
        webfontTaglineAcc: 1.16,
    },

];
