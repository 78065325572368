import {Component, Vue} from 'vue-property-decorator';
import {themeMainModule} from '@/store/theme/theme-main';

@Component
export default class CBlockMix extends Vue {

    /*
        CLASSに記述
        classes/theme/class/a-c-index-block.ts
    */

}
