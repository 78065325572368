import {Component, Prop, Vue} from '@/node_modules/nuxt-property-decorator';
import AVueComponent from '@/classes/page/a-vue-component';
import {wizardModule} from '@/store/wizard';
import {appModule} from '@/store/app';
import {popupModule} from '@/store/popup';
import {selectModule} from '@/store/select';
import {officialModule} from '@/store/official';

import {AuthManager} from "@/classes/app/auth-manager";
import VarUtil, {$v} from "@/classes/utils/var-util";
import {appAuthModule} from "@/store/app/auth";
import MNavFoot from '@/components/part/share/MNavFoot.vue';

@Component({
    components: {
        MNavFoot,
    }
})
export default class TeNavFrame extends AVueComponent {

    @Prop({default: false})
    public visible: boolean;

    public labelBack: string = 'もどる';
    public labelCancel: string = 'キャンセル';


    public get isNavOpen(): any {
        return officialModule.navOpen;
    }

    public onNavOpen() {
        officialModule.openNav();
    }

    public onNavClose() {
        officialModule.closeNav();
    }

    public onNavToggle() {
        officialModule.toggleNav();
    }


    public get isBackLink(): any{

        if (
            this.pagetype != 'wizard-start' &&
            this.pagetype != 'official-doc' &&
            this.pagetype != 'official-page' &&
            this.pagetype != 'official-home' &&
            this.pagetype != 'official-layout' &&
            this.pagetype != 'usertop' &&
            this.pagetype != 'wizard'
        ) {
            return true;
        }

	    return false;
    }

    public get ovisible(): any{
	    return this.visible;
    }

	public get pagetype(): any {
    	return appModule.pagetype;
	}

	public get prlink(): any {
    	return appModule.pagePrlink;
	}

	public get lastToolPagePath(): any {
		//console.log(`lastToolPagePath : ${appModule.lastToolPagePath}`);
		return appModule.lastToolPagePath;
	}

	public get txtBack(): any {

		let t: string = '';

		if( appModule.pageGroup != 'tool' ) {
			t = this.labelCancel;
		}else{
			t = this.labelBack;
		}

		return t;
	}

	public get isLogo(): any {

        if(this.isNavOpen) {
            return true;

    	} else if(this.$nuxt.$route.name == 'index') {
            return false;

    	} else if(this.imgDeviceSize == 'mb' && !appModule.signin) {
            return true;

    	} else if(this.imgDeviceSize == 'mb' && appModule.signin) {
        	return false;
    	}

    	return appModule.pageLogo;
	}

	public get bg(): any {
    	return appModule.pageNavbg;
	}

	public get navBgColor(): any {
    	return appModule.navBgColor;
	}

	public get signin(): any {
    	return $v.has(AuthManager.auth, 'token');
	}

    public get username(): any {

    	if(appModule.deviceSize == 'mb'){
            return '';
        }

        return VarUtil.path(AuthManager.auth, 'owner.name');
    }

	///////// WIZARD ///////////////////////////////////

	public get step(): any {
    	return wizardModule.step;
	}

	public get totalstep(): any {
    	return wizardModule.totalStep;
	}


	public get imgDeviceSize(): any {

    	if(appModule.deviceSize != 'mb'){
        	return 'pc'
    	}
    	return 'mb';
	}

	public get deviceSize(): any {
    	return appModule.deviceSize;
	}


}
