/*

	YH: Data Spread Sheet is Here
https://docs.google.com/spreadsheets/d/1sBa8xBr9f4YPZ99MQX6SixId22JZ-KrmycufhlWcmfI/edit#gid=1642380410
*/

export const ColorMvTxt = [

    {
        value: 'auto',
        label: 'Auto',
    },
    {
        value: 'wh',
        label: 'White',
    },
    {
        value: 'bk',
        label: 'Black',
    },
    {
        value: 'custom',
        label: 'Custom',
    },

];
