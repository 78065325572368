import {Vue} from "nuxt-property-decorator";
import {popupModule} from '@/store/popup';
import {selectModule} from '@/store/select';
import {officialModule} from '@/store/official';
import {ExtLoading} from "@/classes/ext/ext-loading";
import VarUtil from "@/classes/utils/var-util";
import {AppVar, IAppVar} from "@/classes/core/app-var";
import Locale from "@/classes/core/locale";

import {LangLabel} from "@/configs/lang/label";
import {LangWord} from "@/configs/lang/word";

export default class AVueComponent extends Vue {

    public localeBase: string = '';
    public extLoading: ExtLoading;

    public get LWord(): any {
        return LangWord;
    }

    public get jsv(): string {
        return 'javascript:void(0)';
    }

    public created() {
        Locale.src = LangLabel.ja;
        this.initExt();
    }

    public initExt() {
        this.extLoading = new ExtLoading(this);
    }

    public $d(path: string, def: any = null): any {
        return VarUtil.path(this, path, def);
    }

    public $dd(v: any, path: string, def: any = null): any {
        return VarUtil.path(v, path, def);
    }

    public _(path: string, opt: any = {}): string {
        return Locale._(this.localeBase + '.' + path, opt);
    }

    public __(path: string, opt: any = {}): string {
        return Locale._(path, opt);
    }

    //Path Check
    public path(path: string) {
        if (!this) {
            return
        }
        const reg = new RegExp('^' + path);
        return reg.test(this.$nuxt.$route.path);
    }

    public onPopupOpen(id: string, opt: IAppVar = null) {

        console.log('onPopupOpen > id=', id, opt);

        officialModule.closeNav();

        popupModule.openPopup(id);
        if (opt) {
            AppVar.put(opt);
        }

        selectModule.clearCurrent();
    }
}
