import {MediaNavigator} from "@/classes/domain/module/model/media-navigator";
import {ManagerService} from "@/classes/domain/module/manager/manager-service";
import {$v} from "@/classes/utils/var-util";

export class SiteMediaService {

    public getSiteMv(pj: any): any[] {
        const medias = $v.p(pj, 'medias') || [];
        return [];
    }

    public getShopMvFirst(medias: any[]): any {
        return medias.find((_: any) => {
            const purpose = $v.p(_, 'purpose');
            return /^shop@[0-9]+/.test(purpose);
        });
    }

    public navigator(model: any): MediaNavigator {
        return ManagerService.mediaNavigator(model);
    }
}
