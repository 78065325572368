import {Component, Prop, Vue} from '@/node_modules/nuxt-property-decorator';
import VarUtil from "@/classes/utils/var-util";
import {Select} from '@/configs/select';
import {themeStateModule} from "@/store/theme/theme-state";
import * as $ from 'jquery';

/**
 * <m-select
 *    s="Select ID"
 * >
 */
@Component
export default class CSelect extends Vue {

    @Prop({default: ''})
    public component: string;
    
    public active: boolean = false;

	public isSelect(): boolean {
		return themeStateModule.select;
	}

	public get isActive(): any {
		if( !themeStateModule.select ){
			this.active = false;
		}
		return this.active;
	}

    public onClick() {	        
        if( !this.component ){
	        return;
        }
        //const $s = $(this.$slots!['default']![0]!['elm'] as HTMLElement);
        const $s = $(this.$refs.el as HTMLElement);
        
        const {top, left} = $s.offset();
        
        const w = $s.outerWidth();
        const h = $s.outerHeight();
        const select = {
        	component: this.component,
			pos: { top, left },
			size: { w, h }
        };
        
        console.log($s);
		this.active = true;
        themeStateModule.updateSelect(select);
        
    }
}
