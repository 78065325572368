import {Mutation, MutationAction, Action, VuexModule, getModule, Module} from 'vuex-module-decorators';
import store from '@/classes/core/store';
import {StoreAppEditUtil} from "@/classes/view/store/app/store-app-edit-util";
import {IValidateErrorBag} from "@/classes/validation/validator";
import {$v} from "@/classes/utils/var-util";

export interface IEdit {
    id: string;
    seq: number;
    input: any;
    current: any;
    change: number;
    errors: IValidateErrorBag[];
    ext: any;
}

export interface IEditRequest {
    id: string;
    seq?: number;
    input?: any;
    current?: any;
    change?: number;
    errors?: IValidateErrorBag[];
    ext?: any;
}

// state's interface
export interface IAppEditModule {
    edits: IEdit[];
    editTemplate: IEdit;
}

@Module({dynamic: true, store, name: 'appEdit', namespaced: true})
class Store extends VuexModule implements IAppEditModule {

    public edits: IEdit[] = [];
    public editTemplate: IEdit = {
        id: '@',
        seq: -1,
        input: {},
        current: {},
        change: 0,
        errors: [],
        ext: {},
    };

    @Mutation
    public updateEdits(value: IEdit[]) {
        this.edits = value;
    }

    @Mutation
    public updateEdit(value: IEdit) {
        if (!!this.edits.findByKey('id', value.id)) {
            this.edits = this.edits.map((_: IEdit) => {
                return (_.id !== value.id) ? _ : value;
            });
        } else {
            this.edits = Array.from(this.edits).concat([value]);
        }
    }

    @Action
    public async clear(editId: string = '') {
        if (editId === '') {
            M.updateEdits([]);
        } else {
            M.updateEdits(M.edits.filter((_: any) => {
                return (_.id != editId);
            }));
        }
    }

    @Action
    public async start(req: {
        id: string,
        input: any,
        current?: any,
        ext?: any
    }) {
        await M.assign({
            id: req.id,
            current: $v.path(req, 'current'),
            input: $v.path(req, 'input'),
            ext: $v.path(req, 'ext', []),
            change: 0,
            errors: [],
        });
    }

    @Action
    public async assign(req: IEditRequest) {
        const current = StoreAppEditUtil.find(req.id);
        // console.log('assign - req > ', req);
        // console.log('assign - current > ', current);
        await M.updateEdit({
            ...current,
            ...req
        });
    }

    @Action
    public async input(param: {
        id: string,
        input: any
    }) {
        // console.log('input() > input > ', param.input);
        const current = StoreAppEditUtil.find(param.id);
        await M.assign({
            ...current,
            ...{
                input: {
                    ...current.input,
                    ...param.input,
                },
                change: current.change + 1,
            },
        });
    }
}

export const appEditModule = getModule(Store);

const M = appEditModule;
