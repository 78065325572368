import {Component, Vue} from 'nuxt-property-decorator';
import AVueComponent from '@/classes/page/a-vue-component';
import {accountModule} from '@/store/account';

@Component({
    components: {
    }
})
export default class MSecondaryPlanSubscribe extends AVueComponent {
	public mounted(){
		
		console.log(this.$nuxt.$route.path);
	}
}
