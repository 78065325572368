import AVueComponent from "@/classes/page/a-vue-component";
import {ISelect} from "@/classes/domain/model";
import {SelectUtil} from "@/classes/utils/select-util";
import {selectModule} from "@/store/select";

export class AComponentSelect extends AVueComponent {
    // Computed //////////////////////////////////////////
    public get name(): string {
        return selectModule.current.name;
    }

    public get items(): ISelect[] {
        return SelectUtil.source2selects(
            selectModule.current.d, selectModule.current.type);
    }

    // Methods ////////////////////////////////////////////
    public async updateValue(input: ISelect) {
        if (!!selectModule.onSelect) {
            await selectModule.onSelect({
                name: this.name,
                value: input.value,
            });
        } else {
            console.log('MSelectList > onClickValue() > is null');
        }

        selectModule.updateLastSelected(input);
    }

    // Events /////////////////////////////////////////////
    public async onClickValue(input: ISelect) {

        selectModule.updateCurrent(false);

        await this.updateValue(input);
        await this.$emit('close');
    }
}
