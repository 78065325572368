











	// @ts-ignore
    import _ from './CDetail.ts'; export default _;
