











	// @ts-ignore
    import _ from './MPopupPostCopy.ts'; export default _;
