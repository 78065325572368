import Vue from 'vue'
import NuxtLoading from './components/nuxt-loading.vue'

import '../../assets/scss/teaser.scss'

import '../../node_modules/swiper/dist/css/swiper.css'

import _6f6c098b from '../../layouts/default.vue'
import _1a3aec5f from '../../layouts/dev.vue'
import _2a10d0e1 from '../../layouts/official.vue'
import _77e03077 from '../../layouts/print.vue'
import _ee7125b0 from '../../layouts/simple.vue'
import _eb830474 from '../../layouts/teaser.vue'
import _70a6e1b9 from '../../layouts/theme/standard.vue'

const layouts = { "_default": _6f6c098b,"_dev": _1a3aec5f,"_official": _2a10d0e1,"_print": _77e03077,"_simple": _ee7125b0,"_teaser": _eb830474,"_theme/standard": _70a6e1b9 }

export default {
  head: {"title":"THOUGHT｜ブランディングツール","titleTemplate":"","meta":[{"charset":"utf-8"},{"name":"version","content":"*"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":"ヘアーサロン、移動店舗、カフェなどのデザイン重視の小規模店のオーナー向けブランディング・ホームページ作成ツールTHOUGHT（ソート）"},{"hid":"og:title","property":"og:title","content":"THOUGHT｜ブランディングツール"},{"hid":"og:site_name","property":"og:site_name","content":"THOUGHT｜ブランディングツール"},{"hid":"og:type","property":"og:type","content":"website"},{"hid":"og:url","property":"og:url","content":"https:\u002F\u002Fthought-brand.com"},{"hid":"og:description","property":"og:description","content":"ヘアーサロン、移動店舗、カフェなどのデザイン重視の小規模店のオーナー向けブランディング・ホームページ作成ツールTHOUGHT（ソート）"},{"hid":"og:image","property":"og:image","content":"https:\u002F\u002Fthought-brand.com\u002Fcommon\u002Fimg\u002Fsns\u002Fogp-facebook.png"},{"hid":"fb:app_id","property":"fb:app_id","content":"3214293395295630"},{"hid":"twitter:card","property":"twitter:card","content":"summary_large_image"},{"hid":"twitter:creator","property":"twitter:creator","content":"Timeoff"}],"link":[{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Ffavicon.ico"}],"style":[{"id":"fontplus","type":"text\u002Fcss"}],"script":[{"src":"\u002F\u002Fwebfont.fontplus.jp\u002Faccessor\u002Fscript\u002Ffontplus.js?2NB9AlvR3hI%3D&box=TNRo0-zQBNg%3D&timeout=5&pm=1&aa=1&ab=1&delay=1"}]},

  render(h, props) {
    const loadingEl = h('NuxtLoading', { ref: 'loading' })
    const layoutEl = h(this.layout || 'nuxt')
    const templateEl = h('div', {
      domProps: {
        id: '__layout'
      },
      key: this.layoutName
    }, [ layoutEl ])

    const transitionEl = h('transition', {
      props: {
        name: 'layout',
        mode: 'out-in'
      },
      on: {
        beforeEnter(el) {
          // Ensure to trigger scroll event after calling scrollBehavior
          window.$nuxt.$nextTick(() => {
            window.$nuxt.$emit('triggerScroll')
          })
        }
      }
    }, [ templateEl ])

    return h('div', {
      domProps: {
        id: '__nuxt'
      }
    }, [loadingEl, transitionEl])
  },
  data: () => ({
    isOnline: true,
    layout: null,
    layoutName: ''
  }),
  beforeCreate() {
    Vue.util.defineReactive(this, 'nuxt', this.$options.nuxt)
  },
  created() {
    // Add this.$nuxt in child instances
    Vue.prototype.$nuxt = this
    // add to window so we can listen when ready
    if (process.client) {
      window.$nuxt = this
      this.refreshOnlineStatus()
      // Setup the listeners
      window.addEventListener('online', this.refreshOnlineStatus)
      window.addEventListener('offline', this.refreshOnlineStatus)
    }
    // Add $nuxt.error()
    this.error = this.nuxt.error
  },

  mounted() {
    this.$loading = this.$refs.loading
  },
  watch: {
    'nuxt.err': 'errorChanged'
  },

  computed: {
    isOffline() {
      return !this.isOnline
    }
  },
  methods: {
    refreshOnlineStatus() {
      if (process.client) {
        if (typeof window.navigator.onLine === 'undefined') {
          // If the browser doesn't support connection status reports
          // assume that we are online because most apps' only react
          // when they now that the connection has been interrupted
          this.isOnline = true
        } else {
          this.isOnline = window.navigator.onLine
        }
      }
    },

    errorChanged() {
      if (this.nuxt.err && this.$loading) {
        if (this.$loading.fail) this.$loading.fail()
        if (this.$loading.finish) this.$loading.finish()
      }
    },

    setLayout(layout) {
      if (!layout || !layouts['_' + layout]) {
        layout = 'default'
      }
      this.layoutName = layout
      this.layout = layouts['_' + layout]
      return this.layout
    },
    loadLayout(layout) {
      if (!layout || !layouts['_' + layout]) {
        layout = 'default'
      }
      return Promise.resolve(layouts['_' + layout])
    }
  },
  components: {
    NuxtLoading
  }
}
