import {Component, Watch, Prop, Vue} from "vue-property-decorator";
import {themeMainModule} from '@/store/theme/theme-main';

@Component({
    components: {
    }
})
export default class CLike extends Vue {

    public isClicked: boolean = false;
    public count: number = 72;

    public onClick() {
        this.isClicked = true;
    }

}



