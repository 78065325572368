import {Mutation, MutationAction, Action, VuexModule, getModule, Module} from 'vuex-module-decorators';
import store from '@/classes/core/store';
import {OsLoader} from '@/classes/app/os/os-loader';
import {ownersiteModule} from '@/store/ownersite';

export interface IOStoreParam {
    storeId?: string | null;
    categoryId?: string | null;
}

// state's interface
export interface IOsStoreModule {
    content: any | null;
}

@Module({dynamic: true, store, name: 'osStore', namespaced: true})
class Store extends VuexModule implements IOsStoreModule {

    // Page data
    public param: IOStoreParam = {
        storeId: null,
        categoryId: null
    };

    // Page data
    public content: any | null = null;

    @Mutation
    public updateParam(value: IOStoreParam) {
        this.param = value;
    }

    @Mutation
    public updateContent(value: any) {
        this.content = value;
    }

    @Action
    public async fetch() {

        // console.log('Dummy pj', JSON.stringify(DummyProject));
        // console.log('Dummy page home', JSON.stringify(DummyPageHome));

        const page = await OsLoader.load('store', this.param);
        ownersiteModule.updateProject(page.project);
        osStoreModule.updateContent(page.content);
    }
}

export const osStoreModule = getModule(Store);

