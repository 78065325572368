




















	// @ts-ignore
    import _ from './MPopupUploadImgMovie.ts'; export default _;
