import {EnvUtil} from "@/classes/app/env-util";
import {AuthManager} from "@/classes/app/auth-manager";
import {managerProjectModule} from "@/store/manager/project";
import {DomainUtil} from "@/classes/domain/module/model/domain/domain-util";
import {$v} from "@/classes/utils/var-util";

export class ManagerUtil {

    public static get ownersiteUrl(): string {

        if (EnvUtil.isDummy || !AuthManager.isLogin()) {
            return '/';
        }

        const site = managerProjectModule.records.findByKey('toid', AuthManager.auth!.project);
        console.log('ManagerUtil.ownersiteUrl > ', site);

        return !!site ? DomainUtil.url($v.p(site, 'domain')) : '/';
    }
}
