/*

	YH: Data Spread Sheet is Here
	https://docs.google.com/spreadsheets/d/1GP-wc5RwVL54UcYqxjH_ZfCQWY7IAfuL_BSC2SXCL8s/edit?usp=sharing

*/

export const DummyPageContact =
{
	id: 'contact',
	name: 'Contact',
	type: 'page',
	pattern: {
		index: 'c-page-contact-index-standard',
		confirm: 'c-page-contact-confirm-standard',
		finish: 'c-page-contact-finish-standard',
	},
	aspect: '',
};
