import {Component, Prop, Vue} from '@/node_modules/nuxt-property-decorator';
import VarUtil from "@/classes/utils/var-util";

/**
 * <s-img
 *  // ソース(/common/imgが自動でつく)
 *      src="/manager/aaa/bbb.jpg"
 *  // Alt
 *      alt=""
 *  // Title
 *      title=""
 *  // srcset
 *      srcset="auto" ... 自動計算
 *      srcset="no|false" ... srcset無し
 *      srcset="直指定" ... 直接指定
 *  />
 **/
@Component
export default class SBg extends Vue {
	
    @Prop({default: '/common/img'})
    public base: string | null;

    @Prop({default: ''})
    public src: string;

    @Prop({default: 'auto'})
    public srcset: boolean | string;
    
    
    public styles: any = {};
    
    
    public get osrc(): string {

        if (/^[a-zA-Z]+:\/\//.test(this.src)) {
            return this.src;
        }

        return ((this.base || '') + '/' + this.src)
            .replace(/\/\/+/, '/');
    }
    
    
    public get ostyles(): string {

        if (this.isBlankSrcset) {
            return '';
        }

        if (this.isSrcsetOfString
            && (this.srcset !== 'auto'
                && this.srcset.hasOwnProperty('length'))) {
            return (this.srcset as string);
        }

        const ext = VarUtil.tap(/(\.[a-z]+)$/.test(this.osrc), (_: boolean) => {
            return RegExp.$1;
        });

        const img1 = this.osrc;
        const img2 = this.osrc.replace(/(\.[a-z]+)$/, '_2x' + ext);

        // console.log('***', this.osrc, ext, sets, RegExp.$1);
        
        this.styles = {
// 			backgroundImage: `url('${img1}')`,
// 			backgroundImage: `image-set(url('${img1}') 1x, url('${img2}') 2x)`,
			backgroundImage: `-webkit-image-set(url('${img1}') 1x, url('${img2}') 2x)`,
        };

        return this.styles;
    }


    public get isSrcsetOfString(): boolean {
        return this.srcset.hasOwnProperty('length');
    }


    /**
     * srcsetを空で返すか
     */
    public get isBlankSrcset(): boolean {

        // srcsetがBlank
        if(!this.srcset) {
            return true;
        }

        // svgファイルも不要
        if(/\.svg(\?.+)*$/.test(this.osrc)) {
            return true;
        }

        //テキスト且つ no または falseの場合も空
        if(this.isSrcsetOfString
            && ['no', 'false'].indexOf((this.srcset as string).toLowerCase()) >= 0) {
            return true;
        }

        return false;
    }
    
}
