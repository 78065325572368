import {ISiteStoreHoliday} from "@/classes/domain/module/model/site/store/i-site-store";
import {Lang} from "@/classes/app/app-const";
import {$v} from "@/classes/utils/var-util";
import {SelectUtil} from "@/classes/utils/select-util";
import {HolidayUtil} from "@/classes/domain/module/model/site/store/holiday-util";
import {SystemConfig} from "@/configs/system";
import {LangLabelUtil} from "@/classes/domain/lang/lang-label-util";

export interface IHolidayFormatterOption {
    lang?: Lang;
    select?: string;
    rejectEveryWeek?: boolean;
    separator?: string;
}

export class HolidayDefaultFormatter {

    public readonly holiday: ISiteStoreHoliday;
    public readonly option: IHolidayFormatterOption;

    constructor(holiday: ISiteStoreHoliday, option: IHolidayFormatterOption = {}) {
        this.holiday = holiday;
        this.option = {
            ...{
                lang: Lang.Ja,
                select: 'store-holiday-pattern',
                rejectEveryWeek: true,
                separator: ',',
            },
            ...option,
        };
    }

    // Getters ///////////////////////////////////////////////////////
    public get optLang(): Lang {
        return this.opt('lang', Lang.Ja);
    }

    public get optSelect(): string {
        return this.opt('select', Lang.Ja);
    }

    public get optRejectEveryWeek(): boolean {
        return this.opt('rejectEveryWeek', false);
    }

    public get optSeparator(): string {
        return this.opt('separator', '');
    }

    public get weeks(): any[] {
        return SystemConfig.weeks;
    }

    public get cycleLBase(): string {
        return SelectUtil.selectedL(this.holiday.cycle, this.optSelect);
    }

    public get isEveryWeek(): boolean {
        return HolidayUtil.isEvenryWeek(this.cycleLBase);
    }

    public get cycleL(): string {
        return (this.isEveryWeek && this.optRejectEveryWeek) ?
            '' : $v.tap(null, () => {
                const cbs = this.cycleLBase.split(',');
                const cycles = [];
                cbs.map((_: string) => {
                    cycles.push(HolidayUtil.trans(_, Lang.Ja, this.optLang));
                });
                return cycles.join(this.optSeparator);
            });
    }

    public get weekL(): string {

        const w = [];
        this.weeks.map((_: any) => {
            if (!!$v.p(this.holiday, _.key)) {
                w.push(LangLabelUtil._(_.key, 'week', this.optLang));
            }
        });

        // console.log(' - w > ', w);

        // @Todo required I18n
        return $v.tap(w.join(this.optSeparator), (r: string) => {
            if (!!r) {
                return r;
            } else {
                return '';
            }
        });
    }

    // Methods ///////////////////////////////////////////////////////
    public opt(name: string, def: any = null): any {
        return $v.p(this.option, name, def);
    }

    public format(): string {
        switch (this.optLang) {
            case Lang.Ja:
                return this.formatJa();
            default:
                return this.formatEn();
        }
    }

    public formatJa(): string {
        return this.cycleL + this.weekL;
    }

    public formatEn(): string {
        return `${this.cycleL} ${this.weekL}`.trim()
            + (!this.isEveryWeek ? ' in Every Month.' : '');
    }
}
