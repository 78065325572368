import {Component, Watch, Prop, Vue} from "vue-property-decorator";
import {themeMainModule} from '@/store/theme/theme-main';
import CPatternMix from '@/classes/theme/mixin/c-pattern-mix';

import {DummyGallery} from '@/configs/dummy/dummy-gallery';
import {DummyStaff} from '@/configs/dummy/dummy-staff';
import {DummyStore} from '@/configs/dummy/dummy-store';

@Component({
	mixins: [CPatternMix],
})
export default class CPageStaffDetailStandard extends Vue {

	@Prop({default: '1'})
	public active: string;


	public staff: any = {};


	public created() {

		const d: any = DummyStaff.findByKey('id', this.active );
		if( !d ){
			return
		}
		this.staff = d;

	}


	public get saspect(): any {
		return themeMainModule.pageData.list.aspect;
	}


    /*
	    POST DATAを取得
    */
	public get list(): any {

		switch(themeMainModule.pageData.id) {

			case 'gallery':
				return DummyGallery;
				break;

			case 'staff':
				return DummyStaff;
				break;

			case 'store':
				return DummyStore;
				break;
		}
	}

}

