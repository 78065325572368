import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {themeMainModule} from '@/store/theme/theme-main';

import CLayoutStandard from '@/components/theme/layouts/CLayoutStandard.vue';
import o from '@/classes/core/o';
import {ownersiteModule} from '@/store/ownersite';
import WUtil from "@/classes/view/w-util";
import ManagerApi from "@/classes/app/api/manager-api";
import {$apm} from "@/classes/app/app-manager";
import {$v} from "@/classes/utils/var-util";
import {MainModuleBinder} from "@/classes/domain/module/os/project/main-module-binder";
import {DummyProject} from "@/configs/dummy/dummy-project";
import {DevUtil} from "@/classes/utils/dev-util";
import {EnvUtil} from "@/classes/app/env-util";

@Component({
    components: {
        CLayoutStandard,
    }
})
export default class MPreviewFrame extends Vue {


    // public page: string = 'c-index';
    public ready: boolean = false;

    @Prop({default: ''})
    public device: string;

    @Watch('pj', {deep: true})
    public async watchPj() {
        console.log('MPreviewFrame.watchPj() > P1 >', this.pj);
        if (!this.pj) {
            return;
        }
        console.log('MPreviewFrame.watchPj() > P2 > load > ', $apm.subDomain);

        const res = await ManagerApi.Ownersite.get({
            domain: $apm.subDomain,
            path: '',
        }).execAsync();
        console.log('MPreviewFrame.watchPj() > P3 > loaded > ', $apm.pj, res);

        ownersiteModule.updateProject(DevUtil.filterDevProject($v.p(res, 'project')));
        ownersiteModule.updateContent($v.p(res, 'content'));
        ownersiteModule.updateRepository({
            id: $v.p(res, 'content.id'),
            list: $v.p(res, 'repository'),
        });

        this.start();
    }

    @Watch('page')
    public watchPage() {
        this.$router.push(this.$route.path + '?preview=' + this.url);
    }

    // Init /////////////////////////////////////////////////////////////////////////////////
    public created() {
    }

    public async mounted() {
        console.log('MPreviewFrame.mounted() > start ... ...', $apm.pj);
        // setTimeout(() => this.start(), 100);

        if (EnvUtil.isDummy || /^\/wizard-start\//.test(this.$route.path)) {
            console.log('MPreviewFrame.mounted(9 > wizard-start > force dummy ... ...');
            this.start(true);
        }
    }

    public start(forceDummy: boolean = false) {
        /*
          ON MANAGER
        */
        themeMainModule.updateManager(true);
        console.log('MPreviewFrame.start() > ', ownersiteModule.project);

        // Project load
        MainModuleBinder.bindProject(
            forceDummy ? DummyProject : ownersiteModule.project, forceDummy);

        /*
            モバイルプレビューなどでリサイズを無視する場合
        */
        if (this.device) {
            themeMainModule.updateOnResize(false);
            themeMainModule.updateDeviceSize(this.device);
        }

        this.initParam();
        this.ready = true;
        console.log('MPreviewFrame.mounted() > on ready');
    }

    // Getters /////////////////////////////////////////////////////////////////////////////////
    public get pj(): any {
        return $apm.pj;
    }

    public get isBrowser() {
        return WUtil.isBrowser;
    }

    public get url(): string {
        return ownersiteModule.url;
    }

    public get page(): string {
        return ownersiteModule.component || 'c-index';
    }

    public initParam() {

        const route = this.$route as any;
        const op = o(route.query);
        console.log('route.query > ', op.o);

        ownersiteModule.linkTo(op.get('preview', '/'));
        // this.page = VarUtil.tap(op.get('preview', 'c-index'), (page: string) => {
        //
        //   const path = UrlParser.parse(page);
        //   console.log('path > ', page, path);
        //
        //   return path ? path.component : 'c-index';
        // });
    }
}
