var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"m-page-note"},[_c('div',{staticClass:"m-block"},[_c('div',{staticClass:"m-note-title"},[_c('en',{attrs:{"s":"UPGRADE<br>Hashimoto Salonあ"}})],1),_vm._v(" "),_c('div',{staticClass:"m-list -list-link -step"},[_c('nuxt-link',{staticClass:"m-list-item",class:{
						'state-active' : _vm.path('/manager/account/procedure/plan-subscribe/select-store'),
						'state-go' : _vm.path('/manager/account/procedure/common/mail-auth') || _vm.path('/manager/account/procedure/common/confirm')
					},attrs:{"to":"/manager/account/procedure/plan-subscribe/select-store"}},[_c('div',{staticClass:"m-list-item-left"},[_c('span',{staticClass:"num"},[_vm._v("1")]),_c('en',{attrs:{"s":"店舗数の選択"}})],1)]),_vm._v(" "),_c('nuxt-link',{staticClass:"m-list-item",class:{
								'state-active' : _vm.path('/manager/account/procedure/common/payment') || _vm.path('/manager/account/procedure/common/mail-auth'),
								'state-go' : _vm.path('/manager/account/procedure/common/confirm')
							},attrs:{"to":"/manager/account/procedure/common/payment"}},[_c('div',{staticClass:"m-list-item-left"},[_c('span',{staticClass:"num"},[_vm._v("2")]),_c('en',{attrs:{"s":"お支払方法の選択"}})],1)]),_vm._v(" "),_c('nuxt-link',{staticClass:"m-list-item",class:{
					'state-active' : _vm.path('/manager/account/procedure/common/confirm')
				},attrs:{"to":"/manager/account/procedure/common/confirm"}},[_c('div',{staticClass:"m-list-item-left"},[_c('span',{staticClass:"num"},[_vm._v("3")]),_c('en',{attrs:{"s":"内容の確認"}})],1)]),_vm._v(" "),_c('div',{staticClass:"m-list-item",class:{
						'state-active' : _vm.path('/manager/account/procedure/common/thanks')
					}},[_c('div',{staticClass:"m-list-item-left"},[_c('span',{staticClass:"num"},[_vm._v("4")]),_c('en',{attrs:{"s":"完了"}})],1)])],1)]),_vm._v(" "),_c('div',{staticClass:"m-device-background"},[_c('div',{staticClass:"m-device -tablet-intro-slant -right"},[_c('div',{staticClass:"m-device-img"},[_c('s-img',{attrs:{"src":"/share/frame/tablet-intro-xl.png","loading":false}})],1),_vm._v(" "),_c('div',{staticClass:"m-screen"},[_c('div',{staticClass:"body"},[_c('div',{staticClass:"base"},[_c('s-img',{attrs:{"src":"/official/intro/ja/slant-right.jpg","loading":false}})],1)])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }