












































// @ts-ignore
import _ from './MPopupUploadImg.ts';

export default _;
