import {
    ApiExecuterAsync,
    ApiExecuterFactory,
    ApiMethod,
    ApiUtil
} from '@/classes/core/api/import';
import {vsprintf} from 'sprintf-js';

export default class ShopOpenTypeIndex {

    public get(): ApiExecuterAsync {
        let url = ApiUtil.url('/owner/site/shop/open/type');
        let request = ApiExecuterFactory.createRequest({
            method: ApiMethod.Get,
            url: url,
        });

        return ApiExecuterFactory.createAsync(request);
    }
    public show(id:number): ApiExecuterAsync {
        let url = ApiUtil.url(vsprintf('/owner/site/shop/open/type/%s', [id]));
        let request = ApiExecuterFactory.createRequest({
            method: ApiMethod.Get,
            url: url,
            param: {
                id: id,
            }
        });

        return ApiExecuterFactory.createAsync(request);
    }
    public post(record:any): ApiExecuterAsync {
        let url = ApiUtil.url('/owner/site/shop/open/type');
        let request = ApiExecuterFactory.createRequest({
            method: ApiMethod.Post,
            url: url,
            param: record
        });

        return ApiExecuterFactory.createAsync(request);
    }
    public put(id:number,record:any): ApiExecuterAsync {
        let url = ApiUtil.url(vsprintf('/owner/site/shop/open/type/%s', [id]));
        let request = ApiExecuterFactory.createRequest({
            method: ApiMethod.Patch,
            url: url,
            param: record
        });

        return ApiExecuterFactory.createAsync(request);
    }
    public patch(id:number,record:any): ApiExecuterAsync {
        let url = ApiUtil.url(vsprintf('/owner/site/shop/open/type/%s', [id]));
        let request = ApiExecuterFactory.createRequest({
            method: ApiMethod.Put,
            url: url,
            param: record
        });

        return ApiExecuterFactory.createAsync(request);
    }
    public delete(id:number): ApiExecuterAsync {
        let url = ApiUtil.url(vsprintf('/owner/site/shop/open/type/%s', [id]));
        let request = ApiExecuterFactory.createRequest({
            method: ApiMethod.Delete,
            url: url,
            param: {
                id: id,
            }
        });

        return ApiExecuterFactory.createAsync(request);
    }
}
