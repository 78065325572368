import {Mutation, MutationAction, Action, VuexModule, getModule, Module} from 'vuex-module-decorators';
import store from '@/classes/core/store';
import {ILogo} from "@/classes/domain/module/model/i-logo";
import {IImg, UploadImgConst} from "@/classes/domain/module/model/i-img";
import {$v} from "@/classes/utils/var-util";

export const UploadimgConst = {
    SCHEMA: 'Imguploader',
    DataType: {
        DATA_TYPE_IMG: 'img',
        DATA_TYPE_LOGO: 'logo',
    },
    Type: {
        TYPE_IMG: 'img',
        TYPE_STAFF: 'staff',
        TYPE_LOGO: 'logo',
        TYPE_LOGO_WIZARD: 'logo-wizard',
        TYPE_LOGO_WHITE: 'logo-white',
        TYPE_LOGO_BLACK: 'logo-black',
    },
};

export interface IImgCrop {
    x: number,
    y: number,
    width: number,
    height: number,
}

export interface IIMg {
    src: string;
    crop?: IImgCrop;
}


// state's interface
export interface IUploadimgStore {
    current: string;
    uploadimgs: Array<IUploadimgState>;
}

export interface IUploadimgState {
    id: string;
    imgNumber: number;

    dataType: 'img' | 'logo';
    type: 'img' | 'staff' | 'logo' | 'logo-wizard' | 'logo-white' | 'logo-black';

    //Use Menu
    menuTrimming: boolean;
    menuSort: boolean;
    menuDelete: boolean;

    // img manager
    pos: number;
    imgs: IImg[];
    logo?: ILogo;

    // upload info
    site: string;
    token: string;
    purpose: string;

    // option
    opt: any;

    //Mode
    modeSort: boolean;
    removes?: any[];
}

export interface IUpdateRequest {
    id: string;
    imgNumber?: number;

    dataType?: string;
    type?: string;

    // Use Menu
    menuTrimming?: boolean;
    menuSort?: boolean;
    menuDelete?: boolean;

    // img manager
    pos?: number;
    imgs?: any[];
    logo?: ILogo;

    // upload info
    site?: string;
    token?: string;
    purpose?: string;

    opt?: any;

    //Mode
    modeSort?: boolean;
    removes?: any[];
}

const DEFAULT_ID = '0';
const UPLOAD_IMG_STATE_DEFAULT = {
    id: '',
    imgNumber: 0,

    dataType: UploadimgConst.DataType.DATA_TYPE_IMG,
    type: UploadimgConst.Type.TYPE_IMG,

    //Use Menu
    menuTrimming: false,
    menuSort: false,
    menuDelete: false,

    pos: 0,
    imgs: [],

    site: '',
    token: '',
    purpose: '',

    opt: {},

    //Mode
    modeSort: false,
    removes: [],
} as IUploadimgState;

function createUploadState(base: IUpdateRequest): IUploadimgState {
    return Object.assign({}, UPLOAD_IMG_STATE_DEFAULT, base) as IUploadimgState;
}

@Module({dynamic: true, store, name: 'uploadimg', namespaced: true})
class Uploadimg extends VuexModule implements IUploadimgStore {

    public current: string = '';
    public uploadimgs: IUploadimgState[] = [
        createUploadState({id: DEFAULT_ID}),
    ];

    // Computed ///////////////////////////////////////////////////////////////
    public get currentImg(): IUploadimgState {
        return M.uploadimgs.findByKey('id', M.current)
            || createUploadState({id: DEFAULT_ID});
    }

    // Mutations /////////////////////////////////////////////////////////////
    @Mutation
    public updateUploadImg(value: IUpdateRequest) {

        const state = this.uploadimgs.findByKey('id', value.id);
        if (state) {
            this.uploadimgs = this.uploadimgs.map((r: IUploadimgState) => {
                return (r.id !== value.id) ? r : {
                    ...r,
                    ...value
                } as IUploadimgState; //Object.assign({}, r, value);
            });
        } else {
            this.uploadimgs = this.uploadimgs.concat([createUploadState(value)]);
        }
    }

    @Mutation
    public updateCurrent(value: string = '') {
        this.current = value;
    }

    @Mutation
    public menuTriming(): boolean {
        const c = this.uploadimgs.findByKey('id', this.current);
        const pos = $v.num($v.p(c, 'pos'));
        const img = $v.num($v.p(c, `imgs.${pos}`));

        return !!img;
    }

    // Actions /////////////////////////////////////////////////////////////
    @Action
    public updateImgNumber(value: number) {
        M.updateUploadImg({
            id: DEFAULT_ID,
            imgNumber: value,
        });
    }

    @Action
    public updateMenuTrimming(value: boolean) {
        M.updateUploadImg({
            id: DEFAULT_ID,
            menuTrimming: value,
        });
    }

    @Action
    public updateMenuSort(value: boolean) {
        M.updateUploadImg({
            id: DEFAULT_ID,
            menuSort: value,
        });
    }

    @Action
    public updateMenuDelete(value: boolean) {
        M.updateUploadImg({
            id: DEFAULT_ID,
            menuDelete: value,
        });
    }

    @Action
    public updateModeSort(value: boolean) {
        M.updateUploadImg({
            id: DEFAULT_ID,
            modeSort: value,
        });
    }

    @Action
    public find(id: string = DEFAULT_ID): IUploadimgState {
        return M.uploadimgs.findByKey('id', id)
            || createUploadState({id: DEFAULT_ID});
    }

    @Action
    public open(id: string = DEFAULT_ID) {
        M.updateCurrent(id);
    }

    @Action
    public close() {
        M.updateCurrent();
    }
}

export const uploadimgModule = getModule(Uploadimg);

const M = uploadimgModule;
