import {Component, Watch, Prop, Vue} from 'nuxt-property-decorator';
import WebfontLoader from "@/classes/view/webfont-loader";
import MInputCentered from '@/components/module/MInputCentered.vue';
import {themeMainModule} from '@/store/theme/theme-main';
import {Logos} from "@/configs/logos";

@Component({
    components: {
        MInputCentered
    }
})
export default class MLogoList extends Vue {

    @Prop({default: '1'})
    public active: string;

    public isLoaded: boolean = false;
    public displayTipTime: number = 1600;
    public tipJa: string = 'Switched to Japanese Typeface';
    public tipEn: string = 'Switched to English Typeface';
    public tipsDur: number = 2600;
    public loadDur: number = 1200;

    public isSwitched: boolean = false;
    public isLoading: boolean = false;

    public lang: string = 'en';
    public classLang: string = '-logo-input-ja';
    public zenkaku: boolean = false;
    public nameCase: string = '';
    public logoSizes = [];
    public logoActives: any[] = [];

    public onInputShopName(value: any) {

        console.log('onInputShopName', value);

        //全角文字の存在がある
        if (!value.match(/^(\w| |'|,|&)+$/)) {

            //全角じゃなかったときだけ
            if (!this.zenkaku) {

                //日本語書体だけに切り替える
                this.lang = 'ja';
                this.classLang = '-logo-input-ja';
                this.zenkaku = true;
                this.switchedInputType();
            }

            //半角だけになった
        } else {

            if (this.zenkaku) {
                this.lang = 'en';
                this.classLang = '-logo-input-en';
                this.zenkaku = false;
                this.switchedInputType();
            }
        }

        themeMainModule.updateShopname(value);
    }

    public onInputTagline(value: any) {
        themeMainModule.updateTagline(value);
    }

    public get shopname() {
        return themeMainModule.shopname;
    }

    public get tagline() {
        return themeMainModule.tagline;
    }

    public mounted() {

        //Google Font すべて読み込み
        WebfontLoader.load('all');

        this.$nextTick(() => {
            this.setActive(themeMainModule.logoActive || this.active);

            //Web Font読み込み完了後に表示
            setTimeout(
                () => {
                    this.isLoaded = true;
                }, 300);
        });
    }

    /*
        クリックでアクティブにする
    */
    public onLogo(id: any) {
        this.setActive(id);
        themeMainModule.updateLogoActive(id);
    }

    public setActive(id: any) {
        this.logoActives = [];
        for (const logo of Logos) {
            let a: boolean = false;
            if (logo.id == id) {
                a = true;
            }
            this.logoActives.push({id: logo.id, active: a});
        }
    }

    /*
	    state-activeをいれる
    */
    public getActive(id: any) {

        const a = this.logoActives.findByKey('id', id);
        let c = '';
        if (a !== null) {
            if (a.active !== null) {
                if (a.active) {
                    c = 'state-selected';
                }
            }
        }
        return c;
    }


    /*
        入力言語に合わせてリストを入れ替える
    */
    public get logos(): any {
        return Logos.filter(
            (item: any) => {
                return item.lang == this.lang;
            });
    }

    public switchedInputType() {

        //Tipsを一定時間表示
        //時間はcss:animationで行う
        this.isSwitched = true;
        setTimeout(
            () => {
                this.isSwitched = false;
            }, this.tipsDur);

        //ローディング開始 表示完了後表示
        this.isLoading = true;
        setTimeout(
            () => {
                this.isLoading = false;
            }, this.loadDur);

    }
}
