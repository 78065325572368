import {Action, getModule, Module, Mutation, VuexModule} from "@/node_modules/vuex-module-decorators";
import store from "@/classes/core/store";
import ManagerApi from "@/classes/app/api/manager-api";
import {$v} from "@/classes/utils/var-util";
import {appAuthModule, AuthProduct} from "@/store/app/auth";
import {PostcodeUtil} from "@/classes/utils/postcode-util";

export interface IPostcodeAddress {
    prefecture: string;
    address1: string;
    address2: string;
    address3: string;
    address4: string;
}

export interface IPostcodeDetail {
    [key: string]: IPostcodeAddress,
}

export interface IPostcode {
    code: string;
    data: IPostcodeDetail;
}

export interface ICountryPostcode {
    country: string;
    postcodes: IPostcode[];
}

export interface IManagerPostcodeModule {
    records: ICountryPostcode[];
}

@Module({dynamic: true, store, name: 'managerPostcode', namespaced: true})
class Store extends VuexModule implements IManagerPostcodeModule {

    private _records: ICountryPostcode[] = [];

    // Getters ///////////////////////
    public get records(): ICountryPostcode[] {
        return this._records;
    }

    @Mutation
    public updateRecords(value: ICountryPostcode[]) {
        this._records = value;
    }

    @Mutation
    public updateRecord(value: IPostcode) {
        this._records = this._records.replaceByKey('code', value).array.from();

        console.log('updateRecord > ', value, this._records);
    }

    @Action
    public async fetch(param: {
        country: string,
        postcode: string
    }): Promise<IPostcode | null> {
        try {
            const res = await ManagerApi.PostCode.get(param).execAsync();
            console.log('postcodeModule.fetch() > res', res);
            if (!!res) {
                return res;
            } else {
                return null;
            }
        } catch (e) {
            await appAuthModule.e401(e);
            return null;
        }
    }
}

export const managerPostcodeModule = getModule(Store);

const M = managerPostcodeModule;
