/*

	各Blockの選択肢
	https://docs.google.com/spreadsheets/d/1QQGIvSDT3U8CPvd8o63oSxFvi6UXpmYg6oggGc-yo-E/edit#gid=0

*/

export const Edit = [

    {
    	eid: 'layout--mv',
    	label: {
        	en: 'Main Visual & Navigation',
        	ja: 'メインビジュアル&ナビゲーション',
    	},
    	type: 'layout',
    	id: 'mv',
    	pathEditContent: [
        	{
            	label: 'メインビジュアル',
            	path: '/manager/project/home/mv/mv',
        	},
        	{
            	label: 'ナビゲーション',
            	path: '/manager/project/page/',
        	},
        	{
            	label: 'サイドカバー',
            	path: '/manager/project/home/mv/sidecover',
        	},
        	{
            	label: '下部ナビゲーション',
            	path: '/manager/project/footer/navbottom',
        	},
    	],
    	pathEditDesign: [
        	{
            	label: 'デザイン変更',
    	        path: '/manager/project/home/mv/layout',
        	},
        ],
    	post: '',
    	owner: 'index',
    	components: [],
    },
    {
    	eid: 'mv-address--mv-address',
    	label: {
        	en: 'Address',
        	ja: 'デザイン住所',
    	},
    	type: 'mv-address',
    	id: 'mv-address',
    	pathEditContent: [
        	{
            	label: '内容編集',
            	path: '/manager/project/home/mv/address',
        	},
    	],
    	pathEditDesign: [
        	{
            	label: 'デザイン変更',
    	        path: '/manager/project/home/mv/address',
        	},
    	],
    	post: '',
    	owner: 'index',
    	components: [
    	],
    },
//     {
//     	eid: 'sidecover--sidecover',
//     	label: {
//         	en: 'Side Cover',
//         	ja: 'サイドカバー',
//     	},
//     	type: 'sidecover',
//     	id: 'sidecover',
//     	pathEditContent: [
//         	{
//             	label: '内容編集',
//             	path: '/manager/project/home/mv/sidecover',
//         	},
//     	],
//     	pathEditDesign: [
//         	{
//             	label: 'デザイン変更',
//     	        path: '/manager/project/home/mv/sidecover',
//         	},
//     	],
//     	post: '',
//     	owner: 'index',
//     	components: [
//     		'CSidecoverTopics',
//     		'CSidecoverAddress',
//     	],
//
//     },
//     {
//     	eid: 'navbottom--navbottom',
//     	label: {
//         	en: 'Bottom Navigation',
//         	ja: '下部ナビ',
//     	},
//     	type: 'navbottom',
//     	id: 'navbottom',
//     	pathEditContent: [
//         	{
//             	label: '内容編集',
//             	path: '/manager/project/footer/navbottom',
//         	},
//     	],
//     	pathEditDesign: [
//         	{
//             	label: 'デザイン変更',
//     	        path: '/manager/project/footer/navbottom',
//         	},
//     	],
//     	post: '',
//     	owner: 'index',
//     	components: [
//     	],
//     },
//     {
//     	eid: 'nav--nav',
//     	label: {
//         	en: 'Navigation',
//         	ja: 'ナビゲーション',
//     	},
//     	type: 'nav',
//     	id: 'nav',
//     	pathEditContent: [
//         	{
//             	label: '内容編集',
//             	path: '/manager/project/page/',
//         	},
//     	],
//     	pathEditDesign: [
//         	{
//             	label: 'デザイン変更',
//     	        path: '/manager/project/page/',
//         	},
//     	],
//     	post: '',
//     	owner: 'index',
//     	components: [
//     	],
//     },
    {
    	eid: 'logo--footer-logo',
    	label: {
        	en: 'Footer Logo',
        	ja: 'フッターロゴ',
    	},
    	type: 'logo',
    	id: 'footer-logo',
    	pathEditContent: [
        	{
            	label: '内容編集',
            	path: '/manager/project/design/logo',
        	},
    	],
    	pathEditDesign: [
        	{
            	label: 'デザイン変更',
    	        path: '/manager/project/design/logo',
        	},
    	],
    	post: '',
    	owner: 'index',
    	components: [
    	],
    },
    {
    	eid: 'content--footer-title',
    	label: {
        	en: 'Footer Title',
        	ja: 'フッタータイトル',
    	},
    	type: 'content',
    	id: 'footer-title',
    	pathEditContent: [
        	{
            	label: '内容編集',
            	path: '/manager/project/footer/',
        	},
    	],
    	pathEditDesign: [
        	{
            	label: 'デザイン変更',
    	        path: '/manager/project/footer/',
        	},
    	],
    	post: '',
    	owner: 'index',
    	components: [
    	],
    },
    {
    	eid: 'content--footer-mainstore-address',
    	label: {
        	en: 'Main Store Address',
        	ja: 'メイン住所',
    	},
    	type: 'content',
    	id: 'footer-mainstore-address',
    	pathEditContent: [
        	{
            	label: '内容編集',
            	path: '/manager/project/store/',
        	},
    	],
    	pathEditDesign: [
        	{
            	label: 'デザイン変更',
    	        path: '/manager/project/store/',
        	},
    	],
    	post: '',
    	owner: 'index',
    	components: [
    	],
    },
    {
    	eid: 'content--footer-sns',
    	label: {
        	en: 'Footer SNS',
        	ja: 'フッターSNS',
    	},
    	type: 'content',
    	id: 'footer-sns',
    	pathEditContent: [
        	{
            	label: '内容編集',
            	path: '/manager/project/setting/sns',
        	},
    	],
    	pathEditDesign: [
        	{
            	label: 'デザイン変更',
    	        path: '/manager/project/setting/sns',
        	},
    	],
    	post: '',
    	owner: 'index',
    	components: [
    	],
    },
    {
    	eid: 'content--pr-thought',
    	label: {
        	en: 'PR THOUGHT',
        	ja: 'PR THOUGHT',
    	},
    	type: 'content',
    	id: 'pr-thought',
    	pathEditContent: [
        	{
            	label: '内容編集',
            	path: '/manager/project/setting/base',
        	},
    	],
    	pathEditDesign: [
        	{
            	label: 'デザイン変更',
    	        path: '/manager/project/setting/base',
        	},
    	],
    	post: '',
    	owner: 'index',
    	components: [
    	],
    },
    {
    	eid: 'block--concept',
    	label: {
        	en: 'Concept Block',
        	ja: 'コンセプトブロック',
    	},
    	type: 'block',
    	id: 'concept',
    	pathEditContent: [
        	{
            	label: '内容編集',
            	path: '/manager/project/home/concept',
        	},
    	],
    	pathEditDesign: [
        	{
            	label: 'デザイン変更',
    	        path: '/manager/project/home/concept',
        	},
    	],
    	post: '',
    	owner: 'index',
    	components: [
    		'CBlockImgtxt',
    	],
    },
    {
    	eid: 'block--gallery',
    	label: {
        	en: 'Gallery Block',
        	ja: 'ギャラリーブロック',
    	},
    	type: 'block',
    	id: 'gallery',
    	pathEditContent: [
        	{
            	label: '内容編集',
            	path: '/manager/project/home/gallery',
        	},
    	],
    	pathEditDesign: [
        	{
            	label: 'デザイン変更',
    	        path: '/manager/project/home/gallery',
        	},
    	],
    	post: 'manager-project-blog',
    	owner: 'index',
    	components: [
    		'CBlockImgtxt',
    		'CBlockList',
    		'CBlockListOneLarge',
    	],
    },
    {
    	eid: 'block--staff',
    	label: {
        	en: 'Staff Block',
        	ja: 'スタッフブロック',
    	},
    	type: 'block',
    	id: 'staff',
    	pathEditContent: [
        	{
            	label: '内容編集',
            	path: '/manager/project/home/staff',
        	},
    	],
    	pathEditDesign: [
        	{
            	label: 'デザイン変更',
    	        path: '/manager/project/home/staff',
        	},
    	],
    	post: 'manager-project-staff',
    	owner: 'index',
    	components: [
    		'CBlockImgtxt',
    		'CBlockList',
    	],
    },
    {
    	eid: 'block--store',
    	label: {
        	en: 'Store Block',
        	ja: 'お店ブロック',
    	},
    	type: 'block',
    	id: 'store',
    	pathEditContent: [
        	{
            	label: '内容編集',
            	path: '/manager/project/home/store',
        	},
    	],
    	pathEditDesign: [
        	{
            	label: 'デザイン変更',
    	        path: '/manager/project/home/store',
        	},
    	],
    	post: 'manager-project-store',
    	owner: 'index',
    	components: [
    		'CBlockImgtxt',
    		'CBlockList',
    	],
    },
    {
    	eid: 'block--blog-news',
    	label: {
        	en: 'Blog&News Block',
        	ja: 'ブログ&ニュースブロック',
    	},
    	type: 'block',
    	id: 'blog-news',
    	pathEditContent: [
        	{
            	label: 'ブログの更新',
            	path: '/manager/project/blog/',
        	},
        	{
            	label: 'ニュースの更新',
            	path: '/manager/project/news/',
        	},
    	],
    	pathEditDesign: [
        	{
            	label: 'デザイン変更',
    	        path: '/manager/project/home/blog-news',
        	},
    	],
    	post: 'manager-project-blog',
    	owner: 'index',
    	components: [
    		'CBlockBlogNews',
    	],
    },
    {
    	eid: 'block--instagram',
    	label: {
        	en: 'Instagram Block',
        	ja: 'Instagramブロック',
    	},
    	type: 'block',
    	id: 'instagram',
    	pathEditContent: [
        	{
            	label: '内容編集',
            	path: 'https://instagram.com/',
        	},
    	],
    	pathEditDesign: [
        	{
            	label: 'デザイン変更',
    	        path: '/manager/project/home/instagram',
        	},
    	],
    	post: '',
    	owner: 'index',
    	components: [
    		'CBlockInstagram',
    	],
    },



    {
    	eid: 'page--gallery-index',
    	label: {
        	en: 'Gallery',
        	ja: 'ギャラリー',
    	},
    	type: 'page',
    	id: 'gallery-index',
    	pathEditContent: [
        	{
            	label: '内容編集',
            	path: '/manager/project/gallery/',
        	},
    	],
    	pathEditDesign: [
    	],
    	post: 'manager-project-gallery',
    	owner: 'gallery-index',
    	components: [
    	],
    },
    {
    	eid: 'page--gallery-detail',
    	label: {
        	en: 'Gallery',
        	ja: 'ギャラリー',
    	},
    	type: 'page',
    	id: 'gallery-detail',
    	pathEditContent: [
        	{
            	label: '内容編集',
            	path: '/manager/project/gallery/',
        	},
    	],
    	pathEditDesign: [
    	],
    	post: '',
    	owner: 'gallery-detail',
    	components: [
    	],
    },


    {
    	eid: 'page--staff-index',
    	label: {
        	en: 'Staff',
        	ja: 'スタッフ',
    	},
    	type: 'page',
    	id: 'staff-index',
    	pathEditContent: [
        	{
            	label: '内容編集',
            	path: '/manager/project/staff/',
        	},
    	],
    	pathEditDesign: [
    	],
    	post: 'manager-project-staff',
    	owner: 'staff',
    	components: [
    	],
    },
    {
    	eid: 'page--store-index',
    	label: {
        	en: 'Store',
        	ja: 'お店',
    	},
    	type: 'page',
    	id: 'store-index',
    	pathEditContent: [
        	{
            	label: '内容編集',
            	path: '/manager/project/store/',
        	},
    	],
    	pathEditDesign: [
    	],
    	post: 'manager-project-store',
    	owner: 'store',
    	components: [
    	],
    },
    {
    	eid: 'page--blog-index',
    	label: {
        	en: 'Blog',
        	ja: 'ブログ',
    	},
    	type: 'page',
    	id: 'blog-index',
    	pathEditContent: [
        	{
            	label: '内容編集',
            	path: '/manager/project/blog/',
        	},
    	],
    	pathEditDesign: [
    	],
    	post: 'manager-project-blog',
    	owner: 'blog',
    	components: [
    	],
    },
    {
    	eid: 'page--menu-index',
    	label: {
        	en: 'Menu',
        	ja: 'メニュー',
    	},
    	type: 'page',
    	id: 'menu-index',
    	pathEditContent: [
        	{
            	label: '内容編集',
            	path: '/manager/project/menu/',
        	},
    	],
    	pathEditDesign: [
    	],
    	post: 'manager-project-menu',
    	owner: 'menu',
    	components: [
    	],
    },
    {
    	eid: 'page--news-index',
    	label: {
        	en: 'News',
        	ja: 'ニュース',
    	},
    	type: 'page',
    	id: 'news-index',
    	pathEditContent: [
        	{
            	label: '内容編集',
            	path: '/manager/project/news/',
        	},
    	],
    	pathEditDesign: [
    	],
    	post: 'manager-project-news',
    	owner: 'news',
    	components: [
    	],
    },

    {
    	eid: 'page--contact-index',
    	label: {
        	en: 'Contact',
        	ja: 'お問い合わせ',
    	},
    	type: 'page',
    	id: 'contact-index',
    	pathEditContent: [
        	{
            	label: '内容編集',
            	path: '/manager/project/contact/',
        	},
    	],
    	pathEditDesign: [
    	],
    	post: '',
    	owner: 'contact',
    	components: [
    	],
    },




];
