import {Mutation, MutationAction, Action, VuexModule, getModule, Module} from 'vuex-module-decorators';
import ColorUtil from '@/classes/view/color-util';
import store from '@/classes/core/store';
import {ColorSidecover} from '@/configs/color/color-sidecover';

// state's interface
export interface IThemeCssvarsModule {

    baseId: string;
    baseData: any;
    txt: string;
    bg: string;
    bk: string;
    wh: string;
    key: string;
    link: string;
    baseLabel: string;
    baseTxtLabel: string;
    baseBgLabel: string;
//     baseCustomized: boolean;

    navTxtBase: string;
    navTxt: string;
    navBg: string;
//     navBgOpacity: string;

    mvTxtBase: string;
    mvTxt: string;
    mvTxtAgainst: string;
    mvBg: string;
    mvTxtLabel: string;
    mvBgLabel: string;
    mvCustomized: boolean;

    mvAddressTxtBase: string;
    mvAddressTxt: string;
    mvAddressBgOp: string;

    sidecoverId: string;
    sidecovers: any;
    sidecoverData: any;
    sidecoverTxtMode: string;
    sidecoverTxtBase: string;
    sidecoverTxt: string;
    sidecoverBg: string;
    sidecoverBk: string;
    sidecoverWh: string;
    sidecoverCustomized: boolean;
    sidecoverLabel: string;
    sidecoverTxtLabel: string;
    sidecoverBgLabel: string;

    mvOpacity: number;

    loaderStrokeWidth: number;
    borderWidth: number;
    ratioHorizontal: number;
    ratioVertical: number;

}

@Module({dynamic: true, store, name: 'themeCssvars', namespaced: true})
class ThemeCssvars extends VuexModule implements IThemeCssvarsModule {


    ///////// Base ////////////////////////////////
    public baseId: string = '';
//     public baseCustomized: boolean = false;
    public baseData: any = {};

    public txt: string = '';
    public bg: string = '';
    public bk: string = '';
    public wh: string = '';

    public key: string = '';
    public linkBase: string = 'txt';
    public link: string = '';

    public baseLabel: string = '';
    public baseTxtLabel: string = '';
    public baseBgLabel: string = '';


    ///////// Navigation ////////////////////////////////
    public navTxtBase: string = '';
    public navTxt: string = '';
    public navBg: string = '';
//     public navBgOpacity: string = '';



    ///////// MV ////////////////////////////////
    //auto, txt, bg
    public mvTxtBase: string = 'bg';
    public mvTxt: string = '';
    public mvTxtAgainst: string = '';

    public mvBg: string = '';
    public mvCustomized: boolean = false;

    public mvTxtLabel: string = '';
    public mvBgLabel: string = '';

    public mvAddressTxtBase: string = '';
    public mvAddressTxt: string = '';
    public mvAddressBgOp: string = '';

    public mvOpacity: number = 0.2;



    ///////// Sidecover ////////////////////////////////
    public sidecoverId: string = '';
    public sidecovers: any = [];
    public sidecoverData: any = {};
    public sidecoverTxtMode: string = 'auto';
    public sidecoverTxtBase: string = 'txt';
    public sidecoverTxt: string = '';
    public sidecoverBg: string = '';
    public sidecoverBk: string = '';
    public sidecoverWh: string = '';
    public sidecoverCustomized: boolean = false;
    public sidecoverLabel: string = '';
    public sidecoverTxtLabel: string = '';
    public sidecoverBgLabel: string = '';



    ///////// Other ////////////////////////////////
    public loaderStrokeWidth: number = 1.5;
    public borderWidth: number = 1;
    public ratioHorizontal: number = 61;
    public ratioVertical: number = 130;



    @Action
    public setBase(value: any) {
        this.updateBaseData(value);
        this.updateTxt(value.txt);
        this.updateBg(value.bg);

        //暗い方を代入
        if (!value.bk) {
            if (ColorUtil.isBrighter(value.txt, value.bg)) {
                this.updateBk(value.bg);

            } else {
                this.updateBk(value.txt);
            }

        } else {
            this.updateBk(value.bk);
        }

        //あかるい方を代入
        if (!value.wh) {
            if (ColorUtil.isBrighter(value.bg, value.txt)) {
                this.updateWh(value.bg);

            } else {
                this.updateWh(value.txt);
            }

        } else {
            this.updateWh(value.wh);
        }

        //Linkの値がなければtxtをいれる
        if (!value.link) {
            this.updateLink(value.txt);

        } else {
            this.updateLink(value.link);
        }

        //MV Txt
        if (this.mvTxtBase == 'custom') {
            this.updateMvTxt(value.mvTxt);
            this.setMvAddressTxt();
            this.setMvTxtAgainst();

        } else {
            this.setMvTxtBase();
            this.setMvAddressTxt();
            this.setMvTxtAgainst();
        }

        //ベースカラーに一致したサイドカバーリストを取得
    	const osidecovers: any = ColorSidecover.findByKey('id', value.sidecover);
    	if (!osidecovers) { return; }

        this.updateSidecovers(osidecovers.data);

    	const sidecover: any = this.sidecovers.findByKey('id', this.sidecoverId);
    	if (!sidecover) { return }

    	this.updateSidecoverBg(sidecover.bg);


        //ベースカラーに一致したサイドカバーの色に変更
        //カスタムカラーはそのまま
        if (this.sidecoverId == 'custom') {
            this.updateSidecoverTxt(value.sidecoverTxt);

        } else {
            this.setSidecoverTxt();
        }

        this.setNavTxtBg();
        this.updateBaseLabel(value.label);
    }


//     @Action
//     public setTxt(value: string) {
//         this.updateTxt(value);
//         this.updateLink(value);
//         this.setMvTxtBase();
//         this.setMvAddressTxt();
//         this.setMvTxtAgainst();
//         this.setSidecoverTxt()
//         this.setNavTxtBg()
//     }
//
//     @Action
//     public setBg(value: string) {
//         this.updateBg(value);
//         this.setMvTxtBase();
//         this.setMvAddressTxt();
//         this.setMvTxtAgainst();
//         this.setSidecoverTxt()
//         this.setNavTxtBg()
//     }

    // txt, bg のいずれかが更新されたら起動
    @Action
    public setMvTxtBase() {
        if (this.mvTxtBase == 'bk') {
            this.updateMvTxt(this.bk);
            this.updateMvTxtAgainst(this.wh);

        } else if (this.mvTxtBase == 'wh') {
            this.updateMvTxt(this.wh);
            this.updateMvTxtAgainst(this.bk);

        } else if (this.mvTxtBase == 'auto') {
            this.updateMvTxt(this.bg);

            if(ColorUtil.blackOrWhite(this.bg) == 'wh') {
                this.updateMvTxtAgainst(this.bk);
            } else {
                this.updateMvTxtAgainst(this.wh);
            }

        } else {
            this.updateMvTxt(this.wh);
            this.updateMvTxtAgainst(this.bk);
        }
    }

    // txt, bg のいずれかが更新されたら起動
    @Action
    public setMvAddressTxt() {
        if(this.mvAddressTxtBase == 'bg'){
            this.updateMvAddressTxt(this.bg);

        }else{
            this.updateMvAddressTxt(this.txt);
        }
    }

    /*
        MV Text の反対の色
        txt, bg のいずれかが更新されたら起動
    */
    @Action
    public setMvTxtAgainst() {

        // Black is Better
        if(ColorUtil.blackOrWhite(this.mvTxt) == 'wh') {
            this.updateMvTxtAgainst(this.bk);

        //White is Better
        } else {
            this.updateMvTxtAgainst(this.wh);
        }
    }

    /*
        Navigation Text
        txt, bg のいずれかが更新されたら起動
    */
    @Action
    public setNavTxtBg() {
        if (this.navTxtBase == 'txt') {
            this.updateNavTxt(this.txt);
            this.updateNavBg(this.bg);

        } else if (this.navTxtBase == 'bg') {
            this.updateNavTxt(this.bg);
            this.updateNavBg(this.txt);

        } else {
            this.updateNavTxt(this.mvTxt);
        }
    }


    /*
        Sidecover
    */
    @Action
    public setSidecover(value: any) {
        this.updateSidecoverData(value);

        //あかるい方を代入
        //値が空だった場合、ベースカラーを代入
        if (!value.wh) {
            this.updateSidecoverWh(this.wh);

        } else {
            this.updateSidecoverWh(value.wh);
        }

        //暗い方を代入
        if (!value.bk) {
            this.updateSidecoverBk(this.bk);

        } else {
            this.updateSidecoverBk(value.bk);
        }

        this.updateSidecoverTxtBase(value.txt);

        if (this.sidecoverTxtMode == 'auto') {
            if (value.txt == 'bk') {
                this.updateSidecoverTxt(this.sidecoverBk);

            } else {
                this.updateSidecoverTxt(this.sidecoverWh);
            }
        }

        this.updateSidecoverBg(value.bg);
        this.updateSidecoverLabel(value.label);
    }


    /*
        Sidecover Text
        txt, bg のいずれかが更新されたら起動
    */
    @Action
    public setSidecoverTxt() {

        if (this.sidecoverTxtMode == 'auto') {
            if (this.sidecoverTxtBase == 'bk') {
                this.updateSidecoverTxt(this.sidecoverBk);

            } else {
                this.updateSidecoverTxt(this.sidecoverWh);
            }

        } else if (this.sidecoverTxtMode == 'bk') {
            this.updateSidecoverTxt(this.sidecoverBk);

        } else if (this.sidecoverTxtMode == 'wh') {
            this.updateSidecoverTxt(this.sidecoverWh);

        } else {
            this.updateSidecoverTxt(this.sidecoverTxt);

        }
    }


    ////////// Base ///////////////////////////////////////

    @Mutation
    public updateBaseId(value: string) {
        this.baseId = value;
    }

    @Mutation
    public updateBaseData(value: any) {
        this.baseData = value;
    }

    @Mutation
    public updateTxt(value: string) {
        this.txt = ColorUtil.hexToRgb(value);
    }

    @Mutation
    public updateBg(value: string) {
        this.bg = ColorUtil.hexToRgb(value);
    }

    @Mutation
    public updateBk(value: string) {
        this.bk = ColorUtil.hexToRgb(value);
    }

    @Mutation
    public updateWh(value: string) {
        this.wh = ColorUtil.hexToRgb(value);
    }

    @Mutation
    public updateLinkBase(value: string) {
        this.linkBase = value;
    }

    @Mutation
    public updateLink(value: string) {
        this.link = ColorUtil.hexToRgb(value);
    }

    @Mutation
    public updateKey(value: string) {
        this.key = ColorUtil.hexToRgb(value);
    }

    @Mutation
    public updateBaseLabel(value: string) {
        this.baseLabel = value;
    }

    @Mutation
    public updateBaseTxtLabel(value: string) {
        this.baseTxtLabel = value;
    }

    @Mutation
    public updateBaseBgLabel(value: string) {
        this.baseBgLabel = value;
    }

/*
    @Mutation
    public updateBaseCustomized(value: boolean) {
        this.baseCustomized = value;
    }
*/



    ////////// Navigation ///////////////////////////////////////

    @Mutation
    public updateNavTxt(value: string) {
        this.navTxt = ColorUtil.hexToRgb(value);
    }

    @Mutation
    public updateNavTxtBase(value: string) {
        this.navTxtBase = value;
    }

    @Mutation
    public updateNavBg(value: string) {
        this.navBg = ColorUtil.hexToRgb(value);
    }

    @Mutation
//     public updateNavBgOpacity(value: string) {
//         this.navBgOpacity = value;
//     }



    ////////// MV ///////////////////////////////////////

    // mvTxtBase = txt or bg
    @Mutation
    public updateMvTxtBase(value: string) {
        this.mvTxtBase = value;
    }

    @Mutation
    public updateMvTxt(value: string) {
        this.mvTxt = ColorUtil.hexToRgb(value);
    }

    @Mutation
    public updateMvTxtAgainst(value: string) {
        this.mvTxtAgainst = ColorUtil.hexToRgb(value);
    }

    @Mutation
    public updateMvBg(value: string) {
        this.mvBg = ColorUtil.hexToRgb(value);
    }

    @Mutation
    public updateMvCustomized(value: boolean) {
        this.mvCustomized = value;
    }

    @Mutation
    public updateMvAddressTxtBase(value: string) {
        this.mvAddressTxtBase = value;
    }

    @Mutation
    public updateMvAddressTxt(value: string) {
        this.mvAddressTxt = ColorUtil.hexToRgb(value);
    }

    @Mutation
    public updateMvAddressBgOp(value: string) {
        this.mvAddressBgOp = value;
    }

    @Mutation
    public updateMvTxtLabel(value: string) {
        this.mvTxtLabel = value;
    }

    @Mutation
    public updateMvBgLabel(value: string) {
        this.mvBgLabel = value;
    }

    @Mutation
    public updateMvOpacity(value: number) {
        this.mvOpacity = value;
    }



    ////////// Sidecover ///////////////////////////////////////

    @Mutation
    public updateSidecoverId(value: string) {
        this.sidecoverId = value;
    }

    @Mutation
    public updateSidecovers(value: any) {
        this.sidecovers = value;
    }

    @Mutation
    public updateSidecoverData(value: any) {
        this.sidecoverData = value;
    }

    @Mutation
    public updateSidecoverTxtMode(value: string) {
        this.sidecoverTxtMode = value;
    }

    @Mutation
    public updateSidecoverTxtBase(value: string) {
        this.sidecoverTxtBase = value;
    }

    @Mutation
    public updateSidecoverTxt(value: string) {
        this.sidecoverTxt = ColorUtil.hexToRgb(value);
    }

    @Mutation
    public updateSidecoverBg(value: string) {
        this.sidecoverBg = ColorUtil.hexToRgb(value);
    }

    @Mutation
    public updateSidecoverBk(value: string) {
        this.sidecoverBk = ColorUtil.hexToRgb(value);
    }

    @Mutation
    public updateSidecoverWh(value: string) {
        this.sidecoverWh = ColorUtil.hexToRgb(value);
    }

    @Mutation
    public updateSidecoverCustomized(value: boolean) {
        this.sidecoverCustomized = value;
    }

    @Mutation
    public updateSidecoverLabel(value: string) {
        this.sidecoverLabel = value;
    }

    @Mutation
    public updateSidecoverTxtLabel(value: string) {
        this.sidecoverTxtLabel = value;
    }

    @Mutation
    public updateSidecoverBgLabel(value: string) {
        this.sidecoverBgLabel = value;
    }



    ////////// Other ///////////////////////////////////////


    @Mutation
    public updateLoaderStrokeWidth(value: number) {
        this.loaderStrokeWidth = value;
    }

    @Mutation
    public updateBorderWidth(value: number) {
        this.borderWidth = value;
    }

    @Mutation
    public updateRatioHorizontal(value: number) {
        this.ratioHorizontal = value;
    }

    @Mutation
    public updateRatioVertical(value: number) {
        this.ratioVertical
    }

}

export const themeCssvarsModule = getModule(ThemeCssvars);

