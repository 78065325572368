import {$v} from "@/classes/utils/var-util";
import {SiteStartState} from "@/classes/domain/module/manager/site/site-const";

export class SiteStartService {
    // サイトの開始状態を検出
    public detectStarting(site: any): SiteStartState {

        // 業種選択未完了
        if ($v.p(site, 'worktype', '') === '') {
            return SiteStartState.WorkType;
        }

        // 業種選択未完了
        const stStep = $v.num($v.p(site, 'ex.start.step', ''));
        const stComp = $v.num($v.p(site, 'ex.start.completed', 0));

        if ($v.isEmpty(stStep) && stComp === 0) {
            return SiteStartState.Wizard;
        }

        return SiteStartState.Active;
    }

    // スタートを完了しているかスキップしている
    public isWizard(site: any): boolean {
        return this.detectStarting(site) === SiteStartState.Wizard;
    }
}

