import {Component, Watch, Prop, Vue} from 'vue-property-decorator';
import {themeMainModule} from '@/store/theme/theme-main';
import CPatternMix from '@/classes/theme/mixin/c-pattern-mix';

import {DummyNews} from '@/configs/dummy/dummy-news';

@Component({
    mixins: [CPatternMix],
    components: {
    }
})
export default class CPageNewsIndexStandard extends Vue {


    public list: any = DummyNews;


    public categories: any = [
        {
            id: '0',
            name: '2021',
            link: ''
        }, {
            id: '1',
            name: '2020',
            link: ''
        }, {
            id: '2',
            name: '2019',
            link: ''
        },
    ];


	//ACTIVE CATEGORY
	public activeId: string = '0';


    /*
        PAGE TITLE
    */


}



