import {AOsApi} from '@/classes/app/api/os/a-os-api';
import ApiExecuterAsync from '@/classes/core/api/api-executer-async';
import ApiExecuterFactory from '@/classes/core/api/api-executer-factory';
import {ApiMethod} from '@/classes/core/api/api-method';
import VarUtil from '@/classes/utils/var-util';

export class Gallery extends AOsApi {
    protected _url: string = '/gallery';

    public get(param: any = {}): ApiExecuterAsync {

        let url = this.url;
        const galleryId = VarUtil.path(param, 'galleryId');
        const categoryId = VarUtil.path(param, 'categoryId');

        if (!!galleryId) {
            url += '/detail/' + galleryId;
            if (!!categoryId) {
                url += '?category=' + encodeURI(categoryId);
            }
        } else {
            if (!!categoryId) {
                url += '/category/' + encodeURI(categoryId);
            }
        }

        return ApiExecuterFactory.createRequestWithAsync(ApiMethod.Get, url, this.filterParam(param));
    }
}
