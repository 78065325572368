



























	// @ts-ignore
    import _ from './AccountDomainLeaveStep1.ts'; export default _;
