import {
    ApiExecuterAsync,
    ApiExecuterFactory,
    ApiMethod,
    ApiUtil
} from '@/classes/core/api/import';

export default class OwnerMy {

    public index(): ApiExecuterAsync {
        let url = ApiUtil.url('/owner/my');
        let request = ApiExecuterFactory.createRequest({
            method: ApiMethod.Get,
            url: url,
        });

        return ApiExecuterFactory.createAsync(request);
    }
    public site(siteId:number): ApiExecuterAsync {
        let url = ApiUtil.url('/owner/my/site');
        let request = ApiExecuterFactory.createRequest({
            method: ApiMethod.Post,
            url: url,
            param: {
                siteId: siteId,
            }
        });

        return ApiExecuterFactory.createAsync(request);
    }
}
