import {Mutation, MutationAction, Action, VuexModule, getModule, Module} from 'vuex-module-decorators';
import store from '@/classes/core/store';
import {IRoute, UrlParser} from '@/classes/view/url-parser';
import {IOsProject} from '@/classes/domain/module/os/project/i-os-project';
import {$v} from "@/classes/utils/var-util";

// state's interface
export interface IOwnersiteModule {
    project: IOsProject.IOsProject;
    url: string;
    route: IRoute | null;
    webfonts: any;
    contents: any;
}

@Module({dynamic: true, store, name: 'ownersite', namespaced: true})
class Ownersite extends VuexModule implements IOwnersiteModule {

    public project: IOsProject.IOsProject = {
        global: {
            brandid: '',
            layout: '',
            basecolor: '',
        },

        logo: {
            shopname: '',
            tagline: '',
            type: '',
            white: '',
            black: '',
        },

        mainstore: {
            address1: '',
            address2: '',
            open: [],
            close: [],
            map: '',
            tel: '',
            reservation: null,
        },

        navigation: {
            pages: [],
            sns: [],
        },

        mv: {
            color: '',
            address: false,
            opacity: 0,
            bgcolor: '',
            content: '',
            img: [],
            contentTxt: '',
            contentImg: '',
        },

        sidecover: {
            type: '',
            design: '',
            bgcolor: '',
            addrTitle: '',
            addrLink: null,
            address: [],
            addrFooter: '',
            addrFooterTitle: '',
            addrFooterLink: '',
        },

        mvAddress: {
            title: '',

            block1title: '',
            block1body: '',
            block1link: null,

            block2title: '',
            block2body: '',

            block3title: '',
            block3body: '',
            block3link: null,
        },

        footer: {
            navbottom: '',
            title: '',
            primaryTitle: '',
            secondaryTitle: '',
            snsTitle: '',
            prThought: true,
        },
    };

    public title: string = 'owner site';
    public url: string = '/';
    public route: IRoute | null = null;
    public webfonts = {

        mac: {
            adobe: '',
            monotype: '',
            google: ''
        },

        ios: {
            adobe: '',
            monotype: '',
            google: ''
        },

        windows: {
            adobe: '',
            monotype: '',
            google: ''
        },

        android: {
            adobe: '',
            monotype: '',
            google: ''
        }
    };
    private _contents: any[] = [];
    private _repositories: any[] = [];

    // Getters ///////////////////////////////////////////////////////
    public get path(): string | null {
        return this.route ? this.route.path : null;
    }

    public get component(): string | null {
        return this.route ? this.route.component : null;
    }

    public get contents(): any {
        return this._contents;
    }

    public get repositories(): any {
        return this._repositories;
    }

    // Mutations ////////////////////////////////////////////////////////
    @Mutation
    public updateUrl(value: string) {
        this.url = value;
    }

    @Mutation
    public updateRoute(url: string) {
        this.route = UrlParser.parse(url);
    }

    @Mutation
    public updateProject(value: IOsProject.IOsProject) {
        this.project = value;
    }

    @Mutation
    public updateWebfonts(value: any) {
        this.webfonts = value;
    }

    @Mutation
    public updateContents(value: any[]) {
        this._contents = value;
    }

    @Mutation
    public updateContent(value: any) {
        this._contents = this._contents.replaceByKey('id', $v.p(value, 'id')).array;
    }

    @Mutation
    public updateRepositories(value: any[]) {
        this._repositories = value;
    }

    @Mutation
    public updateRepository(value: any) {
        this._repositories = this._repositories.replaceByKey('id', $v.p(value, 'id')).array;
    }


    // Actions ///////////////////////////////////////////////////////
    @Action
    public linkTo(url: string) {
        ownersiteModule.updateUrl(url);
        ownersiteModule.updateRoute(url);
    }

}

export const ownersiteModule = getModule(Ownersite);

const M = ownersiteModule;
