import {EnvUtil} from "@/classes/app/env-util";
import {managerProjectModule} from "@/store/manager/project";
import {$v} from "@/classes/utils/var-util";
import {DummyProjects} from "@/configs/dummy/dummy-projects";
import {appApiModule} from "@/store/app/api";
import {DomainUtil} from "@/classes/domain/module/model/domain/domain-util";
import {AppManagerRoutingUtil} from "@/classes/app/manager/app-manager-routing-util";
import {appAuthModule} from "@/store/app/auth";
import {AuthManager} from "@/classes/app/auth-manager";
import {managerAppModule} from "@/store/manager/app";
import {DevUtil} from "@/classes/utils/dev-util";

/**
 * App Manager
 * ※Managerコンテンツの共通概念アクセスクラス、ApplicationのManagerではない
 */
export default class AppManager {

    public static _pjid: string = '';
    public static _authError: boolean = false;
    public static mounted: boolean = false;
    public static mountInterval: Number | null = null;

    public static set pjid(pjid: string) {
        M._pjid = pjid;
    }

    public static get siteid(): string {
        return $v.p(M.pj, 'toid');
    }

    public static get pjid(): string {
        return M._pjid;
    }

    public static get pjs(): any[] {
        return managerProjectModule.records;
    }

    public static get pj(): any {
        if (EnvUtil.isDummy) {
            console.log('$apm.pj > M.pjs', M.pjid, M.pjs);
            const r = M.pjs.findByKey('subdomainUrl', M.pjid);
            return !!r ? r : DummyProjects[0];
        } else {
            return M.pjs.find((_: any) => {
                const sub = $v.path(_, 'domain.sub');
                const domain = sub + '.' + $v.path(_, 'domain.domain');
                return (M.pjid === domain);
            });
        }
    }

    public static get fetched(): boolean {
        return managerProjectModule.fetched;
    }

    public static get subDomain(): string {
        return $v.has(M.pj, 'domain') ? DomainUtil.subodmain(M.pj.domain) : '';
    }

    // Methods ////////////////////////////////////////////////////////////
    public static async fetch(force: boolean = false) {
        const e = await managerProjectModule.fetch(force);
        if (!!e) {
            await appApiModule.e(e);
        }
    }

    public static async boot(vue: any) {
        // Project ID 取得
        const hasPj = $v.has(vue.$route.params, 'project');
        $apm.pjid = $v.path(vue.$route, 'params.project');
        console.log('layout/default.vue > boot() > detect pj > ', $apm.pjid, vue.$route);

        // ログイン状態であればサイトリストを取得、
        // セッションが切れていれば401になりログアウトされる
        if (await appAuthModule.isAuthTo004()) {
            await $apm.fetch();
        }

        // プロジェクト取得
        if (AppManagerRoutingUtil.isManagerAccess(vue.$route.path)) {
            // @Todo : auth check
            // if (AuthManager.isLogin()) {
            console.log('$apm.boot() > manager access > auth', $apm.pj);
            if (!hasPj) {
                return true;
            }

            const project = $v.path($apm.pj, 'toid');
            if (!!project) {
                appAuthModule.updateAuth({
                    ...AuthManager.auth,
                    ...{
                        project,
                    },
                });
            } else {
                // @Todo : failure PJ - 飛ばすか404
                DevUtil.log('AppManager.boot() > no auth');
                if (!EnvUtil.isDummy) {
                    window.location.href = '/';
                    return false;
                }
            }
        } else {
            console.log('$apm.boot() > non manager access > auto progress');
            setTimeout(() => {
                console.log('$apm.boot() > timeout > updateReadyPrimary');
                managerAppModule.updateReadyPrimary(true);
            }, 400);
        }

        return true;
    }
}

// local alias
const M = AppManager;

// external alias
export const $apm = AppManager;
