



















	// @ts-ignore
    import _ from './MPopupUploadLogoData.ts'; export default _;
