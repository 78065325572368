import {SystemConfig} from '@/configs/system';
import App from '@/classes/app/app';

export class HostUtil {
    /**
     * @param host
     * @param map
     */
    public static filter(host: string = App.host, map: any[] = SystemConfig.domainAdapter) {

        // @Debug
        let h = host.replace(/\.test/, '');
        console.log('HostUtil.filter() > rep > host=%s, url=%s ', h, App.url);

        if (/^localhost/.test(h) && /\/preview\/([^\/]+)/.test(App.url)) {
            return RegExp.$1;
        }

        const rep = map.find((_: any) => {
            return (_.from === h);
        });

        return ((rep) ? rep.to : h).replace(/:[0-9]+$/, '');
    }
}
