








































































	// @ts-ignore
    import _ from './MPopupMenuGenre.ts'; export default _;
