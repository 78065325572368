import {Component, Watch, Prop, Vue} from 'vue-property-decorator';


import * as $ from 'jquery';

@Component({
    components: {
    }
})
export default class CList extends Vue {


  @Prop({ default: () => {}})
  public d: any;

  @Prop({default: 'auto'})
  public aspect: string;

  @Prop({default: true})
  public img: boolean;

  @Prop({default: false})
  public subtitle: boolean;

  @Prop({default: false})
  public cat: boolean;

  @Prop({default: false})
  public body: boolean;

  @Prop({default: false})
  public footer: boolean;

  @Prop({default: true})
  public detail: boolean;

  @Prop({default: false})
  public border: boolean;


  public classBorder: any = [];


  public mounted() {

    /*
      c-img-ratioの処理が終わってから計算
    */
	this.$nextTick(() => {
		this.$nextTick(() => {

	        /*
	          ボーダーを描画
	        */
	        if (this.border) {

	          for (let i: number = 0; i < this.d.length; i++) {

	            let cls: string = '';
	            let me: number = 0;
	            let prev: number = 0;
	            let next: number = 0;

	            if (i > 0) {
	              prev = $(this.$refs['li' + (i - 1)]).outerHeight();
	            }

	            me = $(this.$refs['li' + i]).outerHeight();

	            if (i < this.d.length - 1) {
	              next = $(this.$refs['li' + (i + 1)]).outerHeight();
	            }


	            //次の高さのほうが高い
	            if (prev < me) {
	              cls += '-border--left';
	            }

	            //自分のほうが高い
	            if (me > next) {
	              cls += ' -border--right';

	            } else if (me == next) {
	              cls += ' -border--right';
	            }

	            console.log(`${i}: ${cls} | ${me} : ${next}`);


	            this.classBorder.push(cls);

	          }

	        }
		});
    });
  }
}

