export default class LogoUtil {

    /*
        Resize Text
    */
    public static resizeTxt(
    	$inner: any,
    	txtLength: number,
    	txtLengthAcc: number,
    	txtAcc: number,
    	txtSize: number,
    	txtMinAcc: number,
    	sizeAcc: number,
    	baseSize: number,
    	manualScale: number,
    	fitOuter: any = { is: false },
    	tagline: any = null,
	): any {

    	if (!$inner) {
        	return;
    	}

        let scale: number = 0;
		let ratio: number = 1;

		const innerSize: any = {
			w: $inner.outerWidth(),
			h: $inner.outerHeight(),
		};

// 		console.log(`
// 		    w: ${innerSize.w}
// 		    h: ${innerSize.h}
// 		`);

        ratio = ((txtLength - 1) * txtLengthAcc);
		ratio = 1 + (ratio / txtAcc);

		const acc = ratio * sizeAcc;
		let imgh = Math.round( (Math.sqrt( (baseSize * (innerSize.h / innerSize.w)) * acc )) * 10 ) / 15;
		const imgw = Math.round( (imgh * (innerSize.w / innerSize.h)) * 10 ) / 10;

        scale = 1 / (1 + (txtAcc / (1 + (ratio / txtMinAcc ))));
        scale = scale * txtSize;

        // Is Tagline
        if (tagline) {
            if (tagline.has && tagline.body) {
                scale = scale * tagline.acc;
            }
        }

		const sizes = {
			w: Math.round(imgw * scale),
			h: Math.round(imgh * scale),
		}

        //Scale
		scale = sizes.w / innerSize.w;

        //外枠に合わせてはみでないように縮小させる場合
		if (fitOuter.is) {
			const w = (innerSize.w * scale) * manualScale;

			if (w > fitOuter.w) {
		        scale = this.resizeByOuterWidth( innerSize.w, fitOuter.w );
			}
		}

// 		console.log(`
//         	innerSize: ${innerSize.w}, ${innerSize.h},
//         	txtLength: ${txtLength},
//         	txtLengthAcc: ${txtLengthAcc},
//         	txtAcc: ${txtAcc},
//         	txtSize: ${txtSize},
//         	txtMinAcc: ${txtMinAcc},
//         	sizeAcc: ${sizeAcc},
//         	size: ${baseSize},
//         	manualScale: ${manualScale},
//         	fitOuter: ${fitOuter.is}, ${fitOuter.w},
//         	tagline: ${tagline.has},
//         	scale: ${scale}
// 		`);

// 		console.log(`
//         	innerSize: ${innerSize.w}, ${innerSize.h},
//         	size: ${(innerSize.w * scale)}, ${(innerSize.h * scale)},
//         	scale: ${scale}
// 		`);

        /*
            w,h : 外枠のサイズ
            scale : transformの値
        */
		return {
    		w: (innerSize.w * scale) * manualScale,
    		h: (innerSize.h * scale) * manualScale,
    		scale: scale * manualScale,
		};
    }


	/*
		Resize Image Logo
	*/
	public static resizeImage(
	    imgSize: any,
	    size: number,
	    sizeAcc: number,
	    ratioAcc: number,
	    ratioAccAcc: number,
	): any {

		//正方形に近いほど大きくする
		let ratio: number = 1;

		if( imgSize.w < imgSize.h ){

			//縦長
			ratio = ( imgSize.h / imgSize.w );

		}else{

			//横長
			ratio = ( imgSize.w / imgSize.h );
		}

		const acc = ratio * sizeAcc;

		let imgh = Math.round( (Math.sqrt( (size * (imgSize.h / imgSize.w)) * acc )) * 10 ) / 10;
        imgh = imgh / (1 + (ratio / (ratioAcc * (1 + (ratio / ratioAccAcc)) )));

		const imgw = Math.round( (imgh * (imgSize.w / imgSize.h)) * 10 ) / 10;

		const sizes = {
			h: Math.round(imgh),
			w: Math.round(imgw)
		};

// 		console.log(`
//     	    imgSize: ${imgSize.w}, ${imgSize.h},
//     	    size: ${size},
//     	    sizeAcc: ${sizeAcc},
//     	    ratioAcc: ${ratioAcc},
//     	    ratioAccAcc: ${ratioAccAcc},
// 		`);

		return sizes;
	}



	public static resizeScaleByArea(
	    $inner: any,
	    body: string,
	    size: number,
	    sizeAcc: number,
    	manualScale: number,
    	fontsize: number,
    	fontsizeAcc: number,
	): any {

    	if (!$inner || !size || !sizeAcc) {
        	return;
    	}

		const sizes: any = {
			w: $inner.outerWidth(),
			h: $inner.outerHeight(),
		};

        //面積
        const area = (sizes.w * sizes.h) / (fontsize * (fontsize * fontsizeAcc));

        //倍率
        const acc = 1 + (size / area);

        //スケール
        const scale = acc * sizeAcc;

        console.log(`
            body: ${body}
            sizes: ${sizes.w}, ${sizes.h},
            size: ${size},
            area: ${area},
            acc: ${acc},
            fontsize: ${fontsize}
            scale: ${scale}
            scale: ${scale * manualScale}
        `);

        return scale * manualScale;
	}

	/*
    	横に長いほど行間を開ける
	*/
	public static resizeLineHeightByRatio(
	    $inner: any,
	    lines: any,
	    lineAcc: number,
	    body: string,
	): any {

    	if (!$inner || !lines || !lineAcc) {
        	return;
    	}

        const startScale = 2.3; //行間を広げる開始のScale
        let scale: number = 0;
		let ratio: number = 1;
		let acc: number = 1;
		let line: any;

		const sizes: any = {
			w: $inner.outerWidth(),
			h: $inner.outerHeight(),
		};

		//タテヨコ比
		if ( sizes.w < sizes.h ) {
			ratio = ( sizes.h / sizes.w );

		} else {
			ratio = ( sizes.w / sizes.h );
		}

        if (ratio > startScale) {

            //倍率
            acc = 1 + (ratio / lineAcc);

            //スケール
            line = {
                ja: lines.ja * acc,
                en: lines.en * acc,
            };

        //変更する必要なし
        } else {
            return lines;
        }

/*
        console.log(`
            body: string,
            ratio: ${ratio}
            scale: ${scale}
            acc: ${acc}
            base line ja: ${lines.ja}
            base line en: ${lines.en}
            line ja: ${line.ja}
            line en: ${line.en}
        `);
*/

        return line;
	}


	/*
		横幅基準にはみ出したらリサイズ
	*/
	public static resizeByOuterWidth( inner_w: number, outer_w: number ): any {

		let scale: number = 1;

		if( inner_w > outer_w ){
			scale = outer_w / inner_w;
		}else if( inner_w < outer_w ){
			scale = inner_w / outer_w;
		}
		return scale;
	}



}
