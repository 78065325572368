

export const WizardMake = [


{
	step: 1,
	id: 'start',
	type: 'break',
	good: '',
	secondary: 'web',
	secWebPage: 'c-index',
	secWebBlock: '.c-mv-frame'
},
{
	step: 2,
	id: 'stores',
	type: 'input',
	good: '',
	secondary: 'web',
	secWebPage: 'c-index',
	secWebBlock: '.-blockId--store'
},
{
	step: 3,
	id: 'staff',
	type: 'input',
	good: '',
	secondary: 'web',
	secWebPage: 'c-index',
	secWebBlock: '.-blockId--staff'
},
{
	step: 4,
	id: 'address',
	type: 'input',
	good: '',
	secondary: 'web',
	secWebPage: 'c-index',
	secWebBlock: ''
},
{
	step: 5,
	id: 'logo',
	type: 'input',
	good: '',
	secondary: 'logo',
	secWebPage: '',
	secWebBlock: ''
},
{
	step: 6,
	id: 'logo-ok',
	type: 'input',
	good: 'ok',
	secondary: 'logo',
	secWebPage: '',
	secWebBlock: ''
},
{
	step: 7,
	id: 'mv',
	type: 'input',
	good: '',
	secondary: 'web',
	secWebPage: 'c-index',
	secWebBlock: '.c-mv-frame'
},
{
	step: 8,
	id: 'mv-ok',
	type: 'input',
	good: 'ok',
	secondary: 'web',
	secWebPage: 'c-index',
	secWebBlock: '.c-mv-frame'
},
{
	step: 9,
	id: 'gallery-use',
	type: 'input',
	good: '',
	secondary: 'web',
	secWebPage: 'c-gallery-index',
	secWebBlock: ''
},
{
	step: 10,
	id: 'blog-use',
	type: 'input',
	good: '',
	secondary: 'web',
	secWebPage: 'c-blog-detail',
	secWebBlock: ''
},
{
	step: 11,
	id: 'news-use',
	type: 'input',
	good: '',
	secondary: 'web',
	secWebPage: 'c-news-index',
	secWebBlock: ''
},
{
	step: 12,
	id: 'menu-use',
	type: 'input',
	good: '',
	secondary: 'web',
	secWebPage: 'c-menu-index',
	secWebBlock: ''
},
{
	step: 13,
	id: 'contact-use',
	type: 'input',
	good: '',
	secondary: 'web',
	secWebPage: 'c-contact-index',
	secWebBlock: ''
},
{
	step: 14,
	id: 'finish',
	type: 'break',
	good: 'ok',
	secondary: 'web',
	secWebPage: 'c-index',
	secWebBlock: '.c-mv-frame'
},

];
