import {
    ApiExecuterAsync,
    ApiExecuterFactory,
    ApiMethod,
    ApiUtil
} from '@/classes/core/api/import';

export default class System {

    public gw(): ApiExecuterAsync {
        let url = ApiUtil.url('/system/gw');
        let request = ApiExecuterFactory.createRequest({
            method: ApiMethod.Get,
            url: url,
        });

        return ApiExecuterFactory.createAsync(request);
    }

    public hello(param: any = {}): ApiExecuterAsync {
        let url = ApiUtil.url('/system/hello');
        let request = ApiExecuterFactory.createRequest({
            method: ApiMethod.Get,
            url: url,
            param: param
        });

        return ApiExecuterFactory.createAsync(request);
    }

    public logout(param: any = {}): ApiExecuterAsync {
        let url = ApiUtil.url('/system/logout');
        let request = ApiExecuterFactory.createRequest({
            method: ApiMethod.Post,
            url: url,
            param: param
        });

        return ApiExecuterFactory.createAsync(request);
    }
}
