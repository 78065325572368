


















	// @ts-ignore
    import _ from './MSelectColorBase.ts'; export default _;
