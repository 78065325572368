import {Vue, Component, Prop} from "vue-property-decorator";

@Component({})
export default class Err extends Vue {
    @Prop() e: string[];

    public get hasError(): boolean {
        return (this.e && this.e.length > 0);
    }
}
