import {Mutation, MutationAction, Action, VuexModule, getModule, Module} from 'vuex-module-decorators';
import store from '@/classes/core/store';
import {IOsHomeContent} from '@/classes/domain/module/os/home/i-os-home-content';
import {OsLoader} from '@/classes/app/os/os-loader';
import {ownersiteModule} from '@/store/ownersite';
import {DummyPageHome} from '@/configs/dummy/dummy-page-home';

// state's interface
export interface IOsIndexModule {
    content: IOsHomeContent | null;
}

@Module({dynamic: true, store, name: 'osIndex', namespaced: true})
class OsIndex extends VuexModule implements IOsIndexModule {

    // Page data
    public content: IOsHomeContent | null = DummyPageHome;

    @Mutation
    public updateContent(value: IOsHomeContent) {
        this.content = value;
    }

    @Action
    public async fetch() {

        // console.log('Dummy pj', JSON.stringify(DummyProject));
        // console.log('Dummy page home', JSON.stringify(DummyPageHome));

        const page = await OsLoader.load('index');
        ownersiteModule.updateProject(page.project);
        osIndexModule.updateContent(page.content);
    }
}

export const osIndexModule = getModule(OsIndex);

