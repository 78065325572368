import {Component, Prop, Vue} from '@/node_modules/nuxt-property-decorator';
import VarUtil from "@/classes/utils/var-util";

@Component
export default class MInputCentered extends Vue {

    @Prop({default: 'text'})
    public type: string;

    @Prop({default: ''})
    public name: string;

    @Prop({default: '40'})
    public maxlength: string;

    @Prop({default: false})
    public spellcheck: boolean;

    @Prop({default: 'Text Here...'})
    public placeholder: string;

    @Prop({default: ''})
    public value: string;

    //文字サイズ
    @Prop({default: ''})
    public size: string;

    //言語
    @Prop({default: ''})
    public lang: string;

    public isActive: boolean = false;
    public dummytxt: string = this.placeholder;

    public mounted() {
        this.setDummyTxt(this.value);
    }

    public get omaxlength(): any {
        return this.maxlength;
    }

    public get ospellcheck(): any {
        return this.spellcheck;
    }

    public get oplaceholder(): any {
        return this.placeholder;
    }

    public get ovalue(): any {
        return this.value;
    }

    public get osize(): any {
        return this.size ? 'size-' + this.size : '';
    }

    public focus() {
        this.isActive = true;
    }

    public blur() {
        this.isActive = false;
    }

    public onInput(e: any) {
        const name = VarUtil.path(e, 'target.name');
        if (!name) {
            this.$emit('input', e.target.value);
        } else {
            this.$emit('input', {
                name: e.target.name,
                value: e.target.value,
            });
        }

        this.setDummyTxt(e.target.value);
    }

    public setDummyTxt(value: string) {
        // if (value || value != '') {
        //     this.dummytxt = value;
        //     this.value = value;
        // } else {
        //     this.dummytxt = this.placeholder;
        //     this.value = '';
        // }
    }
}
