import {
    ApiExecuterAsync,
    ApiExecuterFactory,
    ApiMethod,
    ApiUtil
} from '@/classes/core/api/import';
import {AManagerApi} from "@/classes/app/api/manager/a-manager-api";

export default class FileUploadPrepare extends AManagerApi {

    protected _url: string = '/api/v1/file/upload/prepare';

}
