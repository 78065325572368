import {themeMainModule} from '@/store/theme/theme-main';
import {Brands} from '@/configs/brands';
import {Logos} from '@/configs/logos';
import {ownersiteModule} from '@/store/ownersite';
import {DummyProject} from '@/configs/dummy/dummy-project';
import {EnvUtil} from "@/classes/app/env-util";
import {MainModuleBinderHelper} from "@/classes/domain/module/os/project/binder/main-module-binder-helper";

export class MainModuleDummyBinder {

    public static bindProject(project: any) {

        project = project || DummyProject;

        // console.log('MainModuleDummyBinder.bind() > ', project);

        self.bindMain(project);
        self.biindSidecover(project);
        self.bindMv(project);
    }

    private static bindMain(project: any) {

        //PROJECT DATA を STORE に格納
        themeMainModule.updateProject(project);
        themeMainModule.updateShopname(project.logo.shopname);
        themeMainModule.updateTagline(project.logo.tagline);
        themeMainModule.updateNavbottom(project.footer.navbottom);

        //LOGO SCALES
        themeMainModule.updateNavLogoScale(project.logo.scale.navLogo);
        themeMainModule.updateFooterLogoScale(project.logo.scale.footerLogo);
        themeMainModule.updateBlockLogoScale(project.logo.scale.blockLogo);
        themeMainModule.updateLoadingLogoScale(project.logo.scale.loadingLogo);

        //NAVIGATION DATA
        themeMainModule.updateProjectNavigationPage(project.navigation.page);
        themeMainModule.updateProjectNavigationSns(project.navigation.sns);

        //MAIN STORE
        //FOOTER などで使用
        themeMainModule.updateMainStoreAddress1(project.mainstore.address1);
        themeMainModule.updateMainStoreAddress2(project.mainstore.address2);
        themeMainModule.updateMainStoreOpen(project.mainstore.open);
        themeMainModule.updateMainStoreClose(project.mainstore.close);
        themeMainModule.updateMainStoreMap(project.mainstore.map);
        themeMainModule.updateMainStoreTel(project.mainstore.tel);
        themeMainModule.updateMainStoreReservation(project.mainstore.reservation);

        //FOOTER
        themeMainModule.updateFooter(project.footer);

        //言語を設定
        themeMainModule.updateLang('ja');

        //BRAND DATA
        const brand: any = Brands.findByKey('brandid', project.global.brandid);
        if (!brand) {
            return
        }
        //console.log(brand);
        themeMainModule.updateBrand(brand);

        //LAYOUT DATA
        const layout: any = brand.layouts.findByKey('id', project.global.layout);
        if (!layout) {
            return
        }
        //console.log(layout);
        themeMainModule.updateLayout(layout);


        MainModuleBinderHelper.setWebfonts(brand.webfont, project.logo);
    }

    private static biindSidecover(project: any) {
        themeMainModule.updateScType(project.sidecover.type);
        themeMainModule.updateScDesign(project.sidecover.design);
        themeMainModule.updateScAddrTitle(project.sidecover.addrTitle);
        themeMainModule.updateScAddrLink(project.sidecover.addrLink);
        themeMainModule.updateScAddress(project.sidecover.address);
        themeMainModule.updateScAddrFooter(project.sidecover.addrFooter);
        themeMainModule.updateScAddrFooterTitle(project.sidecover.addrFooterTitle);
        themeMainModule.updateScAddrFooterLink(project.sidecover.addrFooterLink);
    }

    private static bindMv(project: any) {
        themeMainModule.updateMvAddress(project.mv.address);
        themeMainModule.updateMvTopics(project.mv.topics);
        themeMainModule.updateMvContent(project.mv.content);
        themeMainModule.updateMvImg(project.mv.img);
        themeMainModule.updateMvContentTxt(project.mv.contentTxt);
        themeMainModule.updateMvContentImg(project.mv.contentImg);
        themeMainModule.updateMvContentImgScale(project.mv.contentImgScale);
        themeMainModule.updateMvContentLogoScale(project.mv.ContentLogoScale);
        themeMainModule.updateMvContentTxtScale(project.mv.ContentTxtScale);

        themeMainModule.updateMvAddressTitle(project.mvAddress.title);
        themeMainModule.updateMvAddressBlock1title(project.mvAddress.block1title);
        themeMainModule.updateMvAddressBlock1body(project.mvAddress.block1body);
        themeMainModule.updateMvAddressBlock1link(project.mvAddress.block1link);
        themeMainModule.updateMvAddressBlock2title(project.mvAddress.block2title);
        themeMainModule.updateMvAddressBlock2body(project.mvAddress.block2body);
        themeMainModule.updateMvAddressBlock3title(project.mvAddress.block3title);
        themeMainModule.updateMvAddressBlock3body(project.mvAddress.block3body);
        themeMainModule.updateMvAddressBlock3link(project.mvAddress.block3link);
    }
}

const self = MainModuleDummyBinder;
