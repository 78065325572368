





















	// @ts-ignore
    import _ from './CPageMenuIndexStandard.ts'; export default _;
