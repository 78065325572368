






























	// @ts-ignore
    import _ from './ProcedureShareConfirm.ts'; export default _;
