













































	// @ts-ignore
    import _ from './SLogo.ts'; export default _;
