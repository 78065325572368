import {Component, Prop, Vue} from '@/node_modules/nuxt-property-decorator';
import MListNews from '@/components/module/MListNews.vue';
import {DummyMInfo} from '@/configs/dummy/dummy-m-info';

@Component({
    components: {
	    MListNews
    }
})
export default class MSelectInfo extends Vue {
   	
    public articles: any = DummyMInfo;
	
    ////////// POPUP LAYER 共通処理 /////////////////////////////////
	public onClose(to: string=''): any {
		if(to != ''){
			this.$router.push(to);
		}
        this.$emit('close');
	}
	
	
}
