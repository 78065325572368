
export const Price = {

    baseYear: 1000, // 年払い ベース料金
    eachYear: 1480, // 年払い 1店ごとの料金

    baseMonth: 1250, // 月払い ベース料金
    eachMonth: 1850, // 月払い 1店ごとの料金

    maxnum: 10,

}
