import {Component, Vue, Prop} from '@/node_modules/nuxt-property-decorator';
import {themeCssvarsModule} from '@/store/theme/theme-cssvars';
import ColorUtil from '@/classes/view/color-util';
import {AComponentSelect} from "@/classes/page/component/a-component-select";
import {$v} from "@/classes/utils/var-util";
import {selectModule} from "@/store/select";
import {themeStateModule} from "@/store/theme/theme-state";

@Component
export default class MSelectColorSidecover extends AComponentSelect {

    public async mounted() {
        console.log('MselectColorSidecover.mounted() > ', this.name);
    }

    //選択されているベースカラーに一致したサイドカバーリストを返す
    public get d(): any {
        return themeCssvarsModule.sidecovers;
    }

    public async updateTheme(input: any) {
        themeCssvarsModule.updateSidecoverTxtMode('auto');
        themeCssvarsModule.setSidecover(input);
        themeStateModule.updateSidecoverDisplay(true);
        selectModule.updateCurrent(false);
    }

    public async onClick(input: any) {
        await this.updateTheme(input);
        await this.updateValue({
            value: $v.p(input, 'id'),
            label: $v.p(input, 'label'),
        });
        await this.$emit('close');
        selectModule.clearCurrent();
    }

    public styleColor(value: string) {

        let color: string = value;

        if (ColorUtil.isHex(value)) {
            color = ColorUtil.hexToRgb(value);
        }

        return {
            'background-color': `rgb(${color})`
        }

    }

    /////// 共通処理 /////////////////////////////////
    ////////// POPUP LAYER 共通処理 /////////////////////////////////
}
