import VarUtil from '@/classes/utils/var-util';

export default function o(base: any): O {
    const next = VarUtil.export<any>({}, base);
    return (base instanceof O) ? next : new O(next);
}

export class O {

    protected _o: any;

    constructor(o: any = {}) {
        this._o = o;
    }

    public get o(): any {
        return this._o;
    }

    public get v(): any {
        return this._o;
    }

    public static o(base: any): O {
        return o(base);
    }

    public path(path: string, def: any = null): any {
        return VarUtil.path(this._o, path, def);
    }

    public patho(path: string, def: any = null): any {
        return o(VarUtil.path(this._o, path, def));
    }

    public get(path: Object | string, def: any = null): any {

        if (typeof (path) == 'object') {
            const r = {};
            for (let i in path) {
                const v = path[i];
                if (typeof (v) == 'object' && v.hasOwnProperty('length')) {
                    r[v[1]] = this.get(v[0]);
                } else {
                    r[v] = this.get(v);
                }
            }
            return r;
        } else {
            return this.in(path) ? this._o[path] : def;
        }
    }

    public fromObject(object: object) {

        if(!VarUtil.isObject(object)) {
            return;
        }

        for(const i of Object.keys(object)) {
            this.set(i, object[i]);
        }
    }

    public set(path: string, value: any, orgPath: string = null, base: any = null, depth: number = 0): O {

        const obj = base || this._o;
        const paths = path.split(".");
        const tg = paths[0];

        if (paths.length == 1) {
            obj[tg] = value;
        } else {
            if (!obj.hasOwnProperty(tg)) {
                return this;
                // throw `path no exists ${orgPath} > [${depth}] ${path} > ${tg}`;
            }

            return this.set(paths.slice(1).join("."), value,
                orgPath || path,
                obj[tg],
                ++depth);
        }

        return this;
    }

    public geto(path: Object | string, def: any = null): O {
        return o(this.get(path, def));
    }

    public find(key: string, find: any): any {
        console.log('find', this._o);
        return VarUtil.find(this._o as any, key, find);
    }

    public findo(key: string, find: any): any {
        return o(VarUtil.find(this._o as any, key, find));
    }

    public in(index: string = null): boolean {
        return VarUtil.in(this._o, index);
    }

    public has(path: string = null): boolean {
        return VarUtil.has(this._o, path);
    }

    public foreach(cb: (idx: any, val: any) => any): any[] {
        const r = [];
        for (let i in this._o) {
            r.push(cb(i, this._o[i]));
        }
        return r;
    }

    public async foreachAsync(cb: (idx: any, val: any) => Promise<any>): Promise<any[]> {
        const r = [];
        for (let i in this._o) {
            r.push(await cb(i, this._o[i]));
        }
        return r;
    }

    public filter(cb: (_: any) => any): any[] {
        return this._o.filter(cb);
    }

    public map(cb: (_: any) => any): any[] {
        return this._o.map(cb);
    }

    public isType(type: "undefined" | "object" | "boolean" | "number" | "string" | "symbol" | "function" | "object"): boolean {
        return (typeof (this._o) == type);
    }

    public get isTypeObject(): boolean {
        return this.isType("object");
    }

    public from(target: any, filter: string[] = []) {

        const binder = o(target).isType('function') ? (k: string, v: any) => {
            target(this, k, v);
        } : (k: string, v: any) => {
            this._o[k] = target[k];
        };

        this.foreach((k, v) => {
            if (filter.indexOf(k) >= 0) {
                return;
            }

            binder(k, v);
        })
    }

    public bind(target: any, filter: string[] = []) {

        const binder = o(target).isType('function') ? (k: string, v: any) => {
            target(k, v);
        } : (k: string, v: any) => {
            target[k] = v;
        };

        this.foreach((k, v) => {
            if (filter.indexOf(k) >= 0) {
                return;
            }

            binder(k, v);
        })
    }

    public keys(reject: string[] = []): string[] {

        if (!this.isTypeObject) {
            return [];
        }

        reject.push('__ob__');

        const r = [];
        const keys = Object.getOwnPropertyNames(this._o);
        const klen = keys.length;

        for (let i = 0; i < klen; i++) {
            const k = keys[i];
            if (reject.indexOf(k) < 0) {
                r.push(k);
            }
        }

        return r;
    }

    public cp(): O {
        return o(JSON.parse(JSON.stringify(this._o)));
    }

    public tap<T>(path: string, cb: (value: any) => T) {
        return cb(this.get(path));
    }
}