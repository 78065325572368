import {
    ApiExecuterAsync,
    ApiExecuterFactory,
    ApiMethod,
    ApiUtil
} from '@/classes/core/api/import';
import {vsprintf} from 'sprintf-js';

export default class SysVar {

    public get(param:any = {}): ApiExecuterAsync {
        let url = ApiUtil.url('/sys/var');
        let request = ApiExecuterFactory.createRequest({
            method: ApiMethod.Get,
            url: url,
            param: param
        });

        return ApiExecuterFactory.createAsync(request);
    }
    public show(id:number, param:any = {}): ApiExecuterAsync {
        let url = ApiUtil.url(vsprintf('/sys/var/%s', [id]));
        let request = ApiExecuterFactory.createRequest({
            method: ApiMethod.Get,
            url: url,
            param: param
        });

        return ApiExecuterFactory.createAsync(request);
    }

    /**
     * param: {
     *     domain(or site_id),
     *     purpose,
     *     f_id,
     *     rank
     * }
     *
     * @param param
     */
    public post(param:any = {}): ApiExecuterAsync {
        let url = ApiUtil.url('/sys/var');
        let request = ApiExecuterFactory.createRequest({
            method: ApiMethod.Post,
            url: url,
            param: param
        });

        return ApiExecuterFactory.createAsync(request);
    }
    public put(id:number, param:any = {}): ApiExecuterAsync {
        let url = ApiUtil.url(vsprintf('/sys/var/%s', [id]));
        let request = ApiExecuterFactory.createRequest({
            method: ApiMethod.Put,
            url: url,
            param: param
        });

        return ApiExecuterFactory.createAsync(request);
    }
    public patch(id:number, param:any = {}): ApiExecuterAsync {
        let url = ApiUtil.url(vsprintf('/sys/var/%s', [id]));
        let request = ApiExecuterFactory.createRequest({
            method: ApiMethod.Patch,
            url: url,
            param: param
        });

        return ApiExecuterFactory.createAsync(request);
    }
    public delete(id:number, param:any = {}): ApiExecuterAsync {

        console.log('delete() > param: ', param);

        let url = ApiUtil.url(vsprintf('/sys/var/%s?purpose=%s', [id, param.purpose]));
        let request = ApiExecuterFactory.createRequest({
            method: ApiMethod.Delete,
            url: url,
            param: param
        });

        return ApiExecuterFactory.createAsync(request);
    }
}
