import {Component, Vue} from 'vue-property-decorator';
import {IOsProject} from '@/classes/domain/module/os/project/i-os-project';
import {ownersiteModule} from '@/store/ownersite';
import {themeMainModule} from '@/store/theme/theme-main';
import {Brands} from '@/configs/brands';
import {Logos} from '@/configs/logos';
import {MainModuleBinder} from '@/classes/domain/module/os/project/main-module-binder';
import WUtil from '@/classes/view/w-util';
import {osIndexModule} from '@/store/os';

/**
 * MixIn内で状態を持たないように注意
 * （Method（関数）だけ定義するようにする
 * 必要な場合はコンポーネントを渡すかStoreを使う
 */
@Component
export default class OsMix extends Vue {

    public get pj(): IOsProject.IOsProject {
        return ownersiteModule.project;
    }

    public title(page: string | null = null): string {
        return (page ? `${page}｜` : '') + this.pj.logo.shopname;
    }

    public head() {
        return {
            title: this.title()
        };
    }

    public init(osModule: any, pageModule: any, project: any, pageData: any) {
        this.initProject();
    }

    public initProject() {
        // this.pj = Ssr.store;
        // console.log('CStandard.crated() > Ssr.store', Ssr.store);
        // this.pj = Ssr.store.project;

        //PROJECT DATA を STORE に格納
        console.log('initProject()> ', this.pj);
        MainModuleBinder.bindProject(this.pj);
    }
}
