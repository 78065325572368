/*

	YH: Data Spread Sheet is Here
	https://docs.google.com/spreadsheets/d/1GP-wc5RwVL54UcYqxjH_ZfCQWY7IAfuL_BSC2SXCL8s/edit?usp=sharing

*/

export const DummyStore = [


{
	id: '1',
	number: 1,
	date: '2018.06.12',
	state: 'draft',
	title: 'Weekend. Sapporo',
	subtitle: '北見市',
	address1: '札幌市中央区南1条西1丁目14-6',
	address2: 'シルキーハイツ701',
	tel: '011-215-5654',
	open: ['平日・土. 10:00 – 20:00', '日・祝. 10:00 – 18:00'],
	close: ['火', '第3水曜'],
	body: 'お店の紹介文章がここにはいります。お店の紹介文章がここにはいります。お店の紹介文章がここにはいります。お店の紹介文章がここにはいります。',
	img: ['/templates/hair/store/1-s.jpg'],
	main: true,
},
{
	id: '2',
	number: 2,
	date: '2019.04.07',
	state: '',
	title: 'Weekend. Nagoya',
	subtitle: '奈井江町',
	address1: '札幌市中央区南1条西1丁目14-7',
	address2: 'シルキーハイツ702',
	tel: '011-215-5655',
	open: ['平日・土. 10:00 – 20:00', '日・祝. 10:00 – 18:00'],
	close: ['火', '第3水曜'],
	body: 'お店の紹介文章がここにはいります。お店の紹介文章がここにはいります。お店の紹介文章がここにはいります。お店の紹介文章がここにはいります。',
	img: ['/templates/hair/store/2-s.jpg'],
	main: false,
},
{
	id: '3',
	number: 3,
	date: '2019.04.08',
	state: 'draft',
	title: 'Weekend. Hiroshima',
	subtitle: '赤井町',
	address1: '札幌市中央区南1条西1丁目14-8',
	address2: 'シルキーハイツ703',
	tel: '011-215-5656',
	open: ['平日・土. 10:00 – 20:00', '日・祝. 10:00 – 18:00'],
	close: ['火', '第3水曜'],
	body: 'お店の紹介文章がここにはいります。お店の紹介文章がここにはいります。お店の紹介文章がここにはいります。お店の紹介文章がここにはいります。',
	img: [],
	main: false,
},
{
	id: '4',
	number: 4,
	date: '2019.04.09',
	state: '',
	title: 'Weekend. Wakayama',
	subtitle: '函館市',
	address1: '札幌市中央区南1条西1丁目14-9',
	address2: 'シルキーハイツ704',
	tel: '011-215-5657',
	open: ['平日・土. 10:00 – 20:00', '日・祝. 10:00 – 18:00'],
	close: ['火', '第3水曜'],
	body: 'お店の紹介文章がここにはいります。お店の紹介文章がここにはいります。お店の紹介文章がここにはいります。お店の紹介文章がここにはいります。',
	img: [],
	main: false,
},


];
