/*
	
	YH: Data Spread Sheet is Here
	https://docs.google.com/spreadsheets/d/1GP-wc5RwVL54UcYqxjH_ZfCQWY7IAfuL_BSC2SXCL8s/edit?usp=sharing

*/

export const DummyMNews = [
{
	id: 1,
	date: '2019.04.05',
	title: '油で揚げると焦げやすい',
	body: '男爵イモを、湖池屋独自の技術で2015年の試験販売から年々増産。今年は昨年比5割増の3万ケース（1ケース6袋）を製造し「10月初旬までに完売した」（同社）という。今金町では、残る4千ケースを店頭販売する。味はうすしお。',
	img: '/test/manager/news/01.jpg'
}, 
{
	id: 2,
	date: '2019.04.06',
	title: 'グラウビュンデン州フリン　奇跡の村',
	body: 'スイス・グラウビュンデン地方、ヴァル・ルムネツィア（ルムネツィア谷）の奥深くに佇むフリン村。人口250人に満たない小さな村だ。地元建築家らによる再開発で息を吹き返した同村は、1998年、優れた開発を行った自治体に贈られるワッカー賞を受賞。2010年にも「ヨーロッパの村リニューアル賞」を受賞した。',
	img: ''
}, 
{
	id: 3,
	date: '2019.04.07',
	title: 'シリーズ「スイスの建築」',
	body: 'スイス公共放送協会が過去にスイスエンジニア・建築家協会（SIA）と共同で制作したシリーズ番組「Die Schweiz bauen（スイスの建築）」はスイス国内にある優れた13の建築物を紹介。建築家や関係者らのインタビューを交えながら、プロジェクト発足の経緯や建築の過程などを紹介する。1回につき約3～5分に分けた日本語字幕付きの全13話を、毎週日曜日にオンライン配信する。',
	img: ''
}, 
{
	id: 4,
	date: '2019.04.08',
	title: '油で揚げると焦げやすい',
	body: '男爵イモを、湖池屋独自の技術で2015年の試験販売から年々増産。今年は昨年比5割増の3万ケース（1ケース6袋）を製造し「10月初旬までに完売した」（同社）という。今金町では、残る4千ケースを店頭販売する。味はうすしお。',
	img: '/test/manager/news/01.jpg'
}, 
{
	id: 5,
	date: '2019.04.09',
	title: 'グラウビュンデン州フリン　奇跡の村',
	body: 'スイス・グラウビュンデン地方、ヴァル・ルムネツィア（ルムネツィア谷）の奥深くに佇むフリン村。人口250人に満たない小さな村だ。地元建築家らによる再開発で息を吹き返した同村は、1998年、優れた開発を行った自治体に贈られるワッカー賞を受賞。2010年にも「ヨーロッパの村リニューアル賞」を受賞した。',
	img: ''
}, 
{
	id: 6,
	date: '2019.04.10',
	title: 'シリーズ「スイスの建築」',
	body: 'スイス公共放送協会が過去にスイスエンジニア・建築家協会（SIA）と共同で制作したシリーズ番組「Die Schweiz bauen（スイスの建築）」はスイス国内にある優れた13の建築物を紹介。建築家や関係者らのインタビューを交えながら、プロジェクト発足の経緯や建築の過程などを紹介する。1回につき約3～5分に分けた日本語字幕付きの全13話を、毎週日曜日にオンライン配信する。',
	img: ''
}, 
{
	id: 7,
	date: '2019.04.11',
	title: '油で揚げると焦げやすい',
	body: '男爵イモを、湖池屋独自の技術で2015年の試験販売から年々増産。今年は昨年比5割増の3万ケース（1ケース6袋）を製造し「10月初旬までに完売した」（同社）という。今金町では、残る4千ケースを店頭販売する。味はうすしお。',
	img: '/test/manager/news/01.jpg'
}, 
{
	id: 8,
	date: '2019.04.12',
	title: 'グラウビュンデン州フリン　奇跡の村',
	body: 'スイス・グラウビュンデン地方、ヴァル・ルムネツィア（ルムネツィア谷）の奥深くに佇むフリン村。人口250人に満たない小さな村だ。地元建築家らによる再開発で息を吹き返した同村は、1998年、優れた開発を行った自治体に贈られるワッカー賞を受賞。2010年にも「ヨーロッパの村リニューアル賞」を受賞した。',
	img: ''
}, 
{
	id: 9,
	date: '2019.04.13',
	title: 'シリーズ「スイスの建築」',
	body: 'スイス公共放送協会が過去にスイスエンジニア・建築家協会（SIA）と共同で制作したシリーズ番組「Die Schweiz bauen（スイスの建築）」はスイス国内にある優れた13の建築物を紹介。建築家や関係者らのインタビューを交えながら、プロジェクト発足の経緯や建築の過程などを紹介する。1回につき約3～5分に分けた日本語字幕付きの全13話を、毎週日曜日にオンライン配信する。',
	img: '/test/manager/news/01.jpg'
}, 
 
];