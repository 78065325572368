import Vuex from 'vuex';
import {Vue} from '@/node_modules/nuxt-property-decorator';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {

    },
    mutations: {

    },
    actions: {

    },
});
