import {ManagerService} from "@/classes/domain/module/manager/manager-service";
import {IUploadimgState, uploadimgModule} from "@/store/uploadimg";
import {AExt} from "@/classes/ext/a-ext";
import {upfileModule} from "@/store/upfile";
import {SystemConfig} from "@/configs/system";
import {AppConst} from "@/classes/app/app-const";
import {prodWizardModule} from "@/store/prod/wizard";
import {
    ManagerLoaderBg,
    managerLoaderModule,
    ManagerLoaderProgressType,
    ManagerLoaderStyle
} from "@/store/manager/loader";

export class ExtUploadImg extends AExt {

    public static readonly CONFIG_KEY = 'uploadimgId';

    public updateImgs(imgs: any[]) {
        uploadimgModule.updateUploadImg({
            id: this.uploadimgId,
            imgs,
        });
    }

    public getImageConfig(): any {
        const config = SystemConfig.uploadimg;
        return {
            sizeLimit: config.sizeLimit,
            quality: config.quality,
        };
    }

    public getSiteId(id: string): string {
        const upload = SystemConfig.uploadimg.purpose.findByKey('key', id);
        if (upload.src === AppConst.UploaderSource.SITE) {
            return ''; // @Todo - Site.toid
        } else {
            return prodWizardModule.input.transId;
        }
    }

    public isImageFile(file: File): boolean {
        return /^iamge\//.test(file.type);
    }

    public isActiveFile(file: File): boolean {
        return this.isImageFile(file);
    }

    public async processImage(file: File, config: any, imgState: IUploadimgState): Promise<any> {

        if (!this.validFile(file)) {
            return;
        }

        uploadimgModule.updateCurrent(imgState.id);

        const img = await this.filterInputImage(file, config);

        await ManagerService.imageUploader.addImg(imgState.id, imgState.pos,
            await ManagerService.image.toDataURI(img));

        await this.upfileRegist(imgState, img);
    }

    public validFile(file: File) {
        if (file.size > (1024 * 1024 * 10)) {
            alert('ファイルサイズが大きすぎて登録できません、画像編集ソフトで10MB以下、または縦横4000px以下にリサイズしてください');
            return false;
        }

        if (file.size > (1024 * 1024 * 2)) {
            if (!confirm('ファイルサイズが大きいので処理に時間がかかる可能性があります。登録しますか？')) {
                return false;
            }
        }

        return true;
    }

    public async filterInputImage(file: File, config: any): Promise<any> {
        return await ManagerService.image.resize({
            file,
            sizeLimit: config.sizeLimit,
            quality: config.quality,
        });
    }

    public async upfileRegist(imgState: IUploadimgState, file: File) {
        await upfileModule.regist({
            id: imgState.id,
            purpose: imgState.purpose,
            site: imgState.site,
            file
        });
    }

    public remove() {

        const pos = this.state.pos;
        const img = this.state.imgs[pos];
        console.log('extUploadImg > remove() > ', pos, img);
        console.log('extUploadImg > remove() > config > ', this.config);

        const removes = this.state.removes;
        const imgs = this.state.imgs.filter((_: any) => {
            return _ != img;
        });

        uploadimgModule.updateUploadImg({
            id: this.uploadimgId,
            removes: removes.concat([img]),
            imgs: imgs.from(),
            pos: ((imgs.length >= pos) ? imgs.length - 1 : pos),
        });

        console.log('extUploadImg > remove() > state > ', this.state);
    }

    public async commit() {

        await managerLoaderModule.init({
            id: this.uploadimgId,
            loading: true,
            style: ManagerLoaderStyle.Progress,
            progressType: ManagerLoaderProgressType.Semiauto,
            bg: ManagerLoaderBg.Medium,
            title: '',
            progress: 0,
        });

        if (this.state.removes.length > 0) {
            managerLoaderModule.updateLoader({
                id: this.uploadimgId,
                title: '画像を削除してます',
            });
            await upfileModule.remove(this.state.removes);
        }

        managerLoaderModule.updateLoader({
            id: this.uploadimgId,
            title: '画像を登録してます',
        });
        await upfileModule.remove(this.state.removes);

        await managerLoaderModule.updateProgress(100);
    }

    // Computed ////////////////////////////////////////////////////////////////////
    public get uploadimgId(): string {
        return this.vue.state.config.uploadimgId;
    }

    public get config(): any {
        return SystemConfig.uploadimg.purpose.findByKey('key', this.uploadimgId);
    }

    public get state(): IUploadimgState {
        return uploadimgModule.uploadimgs.findByKey('id', this.uploadimgId);
    }
}
