/*

	YH: Data Spread Sheet is Here
	https://docs.google.com/spreadsheets/d/1GP-wc5RwVL54UcYqxjH_ZfCQWY7IAfuL_BSC2SXCL8s/edit?usp=sharing

*/
export const DummyPageHome =
{
	id: 'index',
	name: 'Home',
	type: 'index',

	pattern: {
		index: 'c-page-index-index-standard',
	},

	block: ['concept1','concept2','concept','gallery','staff','store','blog-news','instagram'],

	blockData: [
    	{
    		id: 'concept',
    		name: 'Concept',
    		layout: 'imgtxt',
    		component: 'CBlockImgtxt',
    		data: 'imgtxt',
    		aspect: 'auto',
    		link: {
    				name: 'About Staff',
    				link: '/staff/',
    		},
    		title: 'いつも、笑顔でいて欲しい',
    		body: '今夏も鎌倉より、＜イグル氷菓＞のアイスキャンディーが届きます。<br>イグル氷菓のあずきをベースに宇治・利招園の香ばしいほうじ茶を加えた、<br>まろやかな香りの「ほうじ茶あずき」と、旬の香り<br>高い国産完熟メロンを使用した爽やかな「メロン」の２種類。<br>どちらもA&Sのオリジナルフレーバーです。',
    		img: ['/templates/hair/home/concept-m.jpg'],
    	},
    	{
    		id: 'gallery',
    		name: 'Collection',
//     		layout: 'list-one-large',
//     		component: 'CBlockListOneLarge',
    		layout: 'list',
    		component: 'CBlockList',
    		data: 'gallery',
    		aspect: 'vertical',
    		link: {
    				name: 'Gallery',
    				link: '/gallery',
                    key: 'id',
    		},
    		title: 'いつも、笑顔でいて欲しい',
    		body: '今夏も鎌倉より、＜イグル氷菓＞のアイスキャンディーが届きます。<br>イグル氷菓のあずきをベースに宇治・利招園の香ばしいほうじ茶を加えた、<br>まろやかな香りの「ほうじ茶あずき」と、旬の香り<br>高い国産完熟メロンを使用した爽やかな「メロン」の２種類。<br>どちらもA&Sのオリジナルフレーバーです。',
    		img: ['/templates/hair/home/gallery-m.jpg'],
    	},	{
    		id: 'staff',
    		name: 'Staff',
    		layout: 'list',
    		component: 'CBlockList',
    		data: 'staff',
    		aspect: 'vertical',
    		link: {
    				name: 'About Staff',
    				link: '/staff',
                    key: 'id',
    		},
    		title: 'いつも、笑顔でいて欲しい',
    		body: '今夏も鎌倉より、＜イグル氷菓＞のアイスキャンディーが届きます。<br>イグル氷菓のあずきをベースに宇治・利招園の香ばしいほうじ茶を加えた、<br>まろやかな香りの「ほうじ茶あずき」と、旬の香り<br>高い国産完熟メロンを使用した爽やかな「メロン」の２種類。<br>どちらもA&Sのオリジナルフレーバーです。',
    		img: ['/templates/hair/staff/staff-m.jpg'],
    	},	{
    		id: 'store',
    		name: 'Access',
    		layout: 'list',
    		component: 'CBlockList',
    		data: 'store',
    		aspect: 'horizontal',
    		link: {
    				name: 'See more',
    				link: '/store/',
    		},
    		title: 'いつも、笑顔でいて欲しい',
    		body: '今夏も鎌倉より、＜イグル氷菓＞のアイスキャンディーが届きます。<br>イグル氷菓のあずきをベースに宇治・利招園の香ばしいほうじ茶を加えた、<br>まろやかな香りの「ほうじ茶あずき」と、旬の香り<br>高い国産完熟メロンを使用した爽やかな「メロン」の２種類。<br>どちらもA&Sのオリジナルフレーバーです。',
    		img: ['/dummy-user/pjname/mv/1-m.jpg'],
    	},	{
    		id: 'blog-news',
    		name: 'Blog & News',
    		layout: 'blog-news',
    		component: 'CBlockBlogNews',
    		data: 'blognews',
    		aspect: 'auto',
    		link: {
    				name: 'See more',
    				link: '',
    		},
    		title: '',
    		body: '',
    		img: [],
    	},	{
    		id: 'instagram',
    		name: 'Instagram',
    		layout: 'list',
    		component: 'CBlockInstagram',
    		data: 'instagram',
    		aspect: 'square',
    		link: {
    				name: 'See more',
    				link: 'https://instagram.com',
    		},
    		title: '',
    		body: '',
    		img: [],
    	},
	],
};
