import {Component, Watch, Vue} from 'vue-property-decorator';
import {layoutSelectModule} from '@/store/layout-select';

/**
	Tips, Selectの座標計算

 */
@Component
export default class SelectLayoutSliderMix extends Vue {

    public onSelectLayoutPrev() {
        layoutSelectModule.prevActiveIndex();
    }

    public onSelectLayoutNext() {
        layoutSelectModule.nextActiveIndex();
    }

	public get isSelectLayoutFirst(): boolean {

    	if (layoutSelectModule.activeIndex == 0) {
        	return true;
    	}

    	return false;
	}

	public get isSelectLayoutLast(): boolean {

    	if (layoutSelectModule.activeIndex == (layoutSelectModule.total - 1)) {
        	return true;
    	}

    	return false;
	}


}
