import FileUploadPrepare from "@/classes/app/api/manager/file-upload-prepare";
import ManagerApi from "@/classes/app/api/manager-api";
import {ApiConst} from "@/classes/core/api/import";
import VarUtil from "@/classes/utils/var-util";
import {FileUp} from "@/classes/domain/module/model/val/file-up";

export class QrRegister {

    // - Request
    // 1.codeリクエスト
    // 2.codeからQRコード作成
    // - Access
    // 3.コード検証
    // 4.アップロード処理
    // 5.アップロード確認

    /**
     * 1.codeリクエスト
     * @param site
     * @param purpose
     */
    public async request(site: string, purpose: string): Promise<string> {
        try {
            return await ManagerApi.FileUploadPrepare.post({
                site,
                purpose,
            }).execAsync('token');
        } catch (e) {
            return '';
        }
    }

    /**
     * 3.コード検証
     * @param token
     */
    public async show(token: string): Promise<FileUp> {
        let fileUp = {
            token: '',
            user: '',
            purpose: '',
            createdAt: null,
            limitedAt: null,
            uploadAt: null,
            fileId: null,
        };
        await ManagerApi.FileUploadPrepare.show(token)
            .then((res: any) => {
                fileUp = VarUtil.path(res.data, 'fileUp');
            })
            .failue((e: any) => {

            })
            .exec();
        return fileUp;
    }
}
