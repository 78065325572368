import {Component, Prop, Vue} from '@/node_modules/nuxt-property-decorator';
import {AComponentSelect} from "@/classes/page/component/a-component-select";
import {ISelect} from "@/classes/domain/model";

@Component({
    components: {
        MSelectAddCat
    }
})
export default class MSelectAddCat extends AComponentSelect {

    public category: string = '';

    // Base ////////////////////////////////////////////////////////
    public async mounted() {
        this.clearInput();
    }

    // Computed ////////////////////////////////////////////////////
    public get hasInput(): boolean {
        return (this.category.length > 0);
    }

    public get classAddBtn(): any {
        return {
            'state-disabled': !this.hasInput,
        };
    }

    // Methods ////////////////////////////////////////////////////
    public clearInput() {
        this.category = '';
    }

    public async submit() {
        const cat = this.items.find((_: ISelect) => (_.label === this.category));
        const icat = this.category;


        this.clearInput();
        return (!!cat) ? this.updateValue(cat) : null;
    }

    // Events //////////////////////////////////////////////////////
    public async onClickValue(input: ISelect) {
        await this.updateValue(input);
    }

    public async onSubmit() {
        await this.submit();
    }

    public async onClickSubmit() {
        await this.submit();
    }

    ////////// POPUP LAYER 共通処理 /////////////////////////////////
    public onClose(to: string = ''): any {
        if (to != '') {
            this.$router.push(to);
        }
        this.$emit('close');
    }

}
