























































	// @ts-ignore
    import _ from './CPageStoreDetailStandard.ts'; export default _;
