import {Component, Prop, Vue, Watch} from '@/node_modules/nuxt-property-decorator';
import VarUtil from '@/classes/utils/var-util';
import {Select} from '@/configs/select';
import {selectModule} from '@/store/select';
import * as $ from 'jquery';
import {sprintf} from "sprintf-js";

/**
 * <m-select
 *    s="Select ID"
 * >
 */
@Component
export default class MSelect extends Vue {

    @Prop({default: ''})
    public name: string;

    @Prop({default: ''})
    public s: string;

    @Prop({default: null})
    public src: any[] | null;

    @Prop({default: null})
    public value: any;

    @Prop({default: null})
    public labelFormat: string | null;

    @Prop({default: null})
    public labelFilter: ((label: any) => string) | null;

    // Option
    @Prop({default: null})
    public opt: any = null;

    public active: boolean = false;

    // Init ////////////////////////////////////////

    // Computed //////////////////////////////////////////////////////////
    public isCurrent(): boolean {
        return selectModule.current;
    }

    public get isActive(): any {
        if (!selectModule.current) {
            this.active = false;
        }
        return this.active;
    }

    public get hasSlotDefault(): boolean {
        return !!this.$slots!['default'];
    }

    public get selectSource(): any {
        const s = Select.findByKey('id', this.s);
        return !!s ? {
            ...s,
            ...{src: !!this.src ? this.src : s.d},
        } : null;
    }

    public get selectedL(): string {

        const src = VarUtil.path(this.selectSource, 'src');
        if (!src) {
            return '';
        }

        if (typeof (src[0]) != 'object') {
            const idx = src.indexOf(this.value);
            return (idx < 0) ? src[0] : src[idx];
        } else {
            return VarUtil.tap(src.findByKey('value', this.value), (s: any) => {

                const def = (Array.isArray(src) && src.length > 0) ? src[0].label : '';
                const r = !!s ? s.label : def;

                if (!!this.labelFormat) {
                    return sprintf(this.labelFormat, s.value);
                } else if (!!this.labelFilter) {
                    return this.labelFilter(r);
                } else {
                    return r
                }

            }) as string;
        }
    }

    // Events ////////////////////////////////////////////////////////////
    public async open() {
        if (!this.selectSource) {
            console.error('select is null');
            return;
        }

        console.log('MSelect.open() > name=%s, selectSource', this.name, this.selectSource);

        const $s = $(this.$refs.el as HTMLElement);
        const {top, left} = $s.offset();
        const w = $s.outerWidth();
        const h = $s.outerHeight();

        this.active = true;
        await selectModule.open({
            name: this.name,
            select: {
                ...this.selectSource,
                ...{
                    pos: {top, left},
                    size: {w, h}
                },
            },
            option: this.opt || {},
            callback: async (input: any) => {
                this.$emit('change', input);
                this.$emit('input', input);
            },
        });
    }


    // Events ////////////////////////////////////////////////////
    public async onClick() {
        await this.open();
    }
}
