import {Component, Watch, Prop, Vue} from "vue-property-decorator";
import ViewUtil from '@/classes/view/view-util';
import {themeMainModule} from '@/store/theme/theme-main';

import * as $ from 'jquery';

@Component({
    components: {
    }
})
export default class CImgHover extends Vue {

    @Prop({default: true})
	public words: boolean;

    @Prop({default: 0})
	public num: number;

    @Prop({default: ''})
	public title: string;

    @Prop({default: ''})
	public body: string;

    @Prop({default: true})
	public isnum: boolean;


    public get formatedNumber(): any {

        //ホバーに数字を使う場合、数字を文字にするか
        //数字を文字にする
        if (this.words) {
            return ViewUtil.toWords(this.num);
        }

        return String(this.num);

    }

    public get stitle(): any {
        return this.title.substr(0, 30);
    }

    public get sbody(): any {
        return this.body.substr(0, 30);
    }

    public get isNumber(): any {
        return this.isnum;
    }

    public get isTitle(): any {
        if(this.title){
            return true;
        }
        return false;
    }

    public get isBody(): any {
        if(this.body){
            return true;
        }
        return false;
    }


}

