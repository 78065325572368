import {Component, Watch, Prop, Vue} from '@/node_modules/nuxt-property-decorator';
import {IManagerLoader, managerLoaderModule} from '@/store/manager/loader';
import {TweenLite} from 'gsap'
import {cmdModule} from "@/store/cmd";
import {ConfigManagerAction} from "@/configs/app/manager-action";

@Component
export default class MLoaderFrame extends Vue {

    @Prop({default: '@'})
    public mid: string;

    public isTrans: boolean = true;
    public duration: number = 0.7;

    //表示用
    public nprogress: number = 0;

    /*
        Storeに下記を代入することで動くようにしてみたのだけど、
        どこにもつかってないので、システムの都合で完全につくりかえて大丈夫です

        bloading = "trueでLoaderを表示"
        style = "circle or progress でデザインを切り替えられる"
        progressType = "manual / auto  progressに値を直接いれるタイプ / ローディングが終わった時点で progressに 100をいれるタイプ"
        txt = "メッセージ"
        progress = "進行度"
    */

    /*
        ローディングスタート
    */
//     @Watch('bloading')
//     public watchLoading() {
//     	console.log('dawdawdawdaw');
//
//         if (this.bloading && this.loaderStyle == 'progress') {
//
//             if (this.progressType == 'auto') {
//                 this.autoProgress();
//
//             } else if (this.progressType == 'semiauto') {
//                 this.semiautoProgress();
//
//             }
//         }
//     }

    /*
        Progressの時、100になったら自動で閉じる
    */
    @Watch('progress')
    public watchProgress(now: number, old: number) {

        console.log('MLoaderFrame.watchProgress() > %s -> %s', old, now);

        TweenLite.to(this.$data, this.duration, {nprogress: this.progress});

        if (this.progress == 100) {
            setTimeout(() => {
                managerLoaderModule.updateLoader({
                    id: this.mid,
                    loading: false,
                });
            }, 1400);
        }
    }

    @Watch('isLoading')
    public async watchLoading(now: boolean) {
        console.log('MLoaderFrame.watchLoading() > watchLoading', now);

        if (!now) {
            return;
        }

        await this.start();
    }

    /*
        オートプログレス
        自動で100まで進む
    */
    public autoProgress() {

        this.isTrans = true;
        this.nprogress = 0;

        setTimeout(() => {
            managerLoaderModule.updateLoader({
                id: this.mid,
                progress: 24,
            });

            setTimeout(() => {
                managerLoaderModule.updateLoader({
                    id: this.mid,
                    progress: 100,
                });

            }, 2400);
        }, 700);
    }

    /*
        セミオートプログレス
        managerLoaderModule.updateProgress(100); にしてあげる必要あり。
    */
    public semiautoProgress() {

        this.isTrans = true;
        this.nprogress = 0;

        if (this.progress == 100) {
            return;
        }

        setTimeout(() => {

            //すでに100の場合はキャンセル
            console.log('MLoaderFrame > semiauto > p1 > ', this.loader, this.progress);
            if (this.progress == 100) {
                return;
            }

            managerLoaderModule.updateLoader({
                id: this.mid,
                progress: 24,
            });

            setTimeout(async () => {

                //すでに100の場合はキャンセル
                console.log('MLoaderFrame > semiauto > p2 > ', this.loader, this.progress);
                if (this.progress == 100) {
                    return;
                }

                await managerLoaderModule.updateLoader({
                    id: this.mid,
                    progress: 68,
                });

                await cmdModule.registCmd({
                    cmd: ConfigManagerAction.Loader.SemiAuto,
                    request: {
                        mid: this.mid,
                        step: 68,
                        progress: this.progress,
                    },
                });

                setTimeout(async () => {
                    console.log('MLoaderFrame > semiautoProgress > ', this.progress);
                    await cmdModule.registCmd({
                        cmd: ConfigManagerAction.Loader.SemiAuto,
                        request: {
                            mid: this.mid,
                            step: -1,
                            progress: this.progress,
                        },
                    });

                }, 1000);

            }, 1000);
        }, 300);
    }

    public onEndTransition() {
        this.isTrans = false;
    }

    public get loader(): IManagerLoader {
        return managerLoaderModule.loaders.findByKey('id', this.mid);
    }

    public get isLoading(): any {
        return this.loader && this.loader.loading;
    }

    public get loaderStyle(): any {
        return this.loader.style;
    }

    public get progressType(): any {
        return this.loader.progressType;
    }

    public get title(): any {

        if (this.loader.title == '') {
            return 'Loading Thought...';
        }

        return this.loader.title;
    }

    public get body(): any {
        return this.loader.body;
    }

    public get progress(): any {
        return this.loader.progress;
    }

    public get bg(): any {
        return this.loader.bg;
    }

    // Medhos ////////////////////////////////////
    //StoreでLoadingをTrueにすると表示される
    public async start() {

        if (this.loader.loading && this.loaderStyle == 'progress') {
            if (this.progressType == 'auto') {
                await this.autoProgress();
            } else if (this.progressType == 'semiauto') {
                await this.semiautoProgress();
            }
        }
    }
}
