import {Component, Watch, Prop, Vue} from '@/node_modules/nuxt-property-decorator';
import {appModule} from '@/store/app';
import {themeMainModule} from '@/store/theme/theme-main';
import {bodyModule} from '@/store/body';
import * as $ from 'jquery';

@Component
export default class MoOfficialNav extends Vue {

    public isActive: boolean = true;
    public start_pos: number = 0;
    public menuCount: number = 6;
    public menuCountPc: number = 6;
    public menuCountMb: number = 4;
    public onScrollDetect: boolean = true;
    public classActiveItem: string = '-home';
    public styleX: any = {};

    @Watch('pathName')
    public watchPathName() {

        if (this.$nuxt.$route.name != 'index') {
            //this.isActive = false;
            this.onScrollDetect = false;

            //遷移後のスクロールトップで反応しないようにする
            setTimeout(
                () => {
    				this.onScrollDetect= true;
                }, 600);
        }

        this.updateActive();
    }

    @Watch('deviceSize')
    public watchDeviceSize() {
        this.updateActive();
    }

	@Watch('mainFrameResized')
	public watchMainFrameResized() {
        this.updateActive();
	}

	@Watch('bodyScrollEvent')
	public watchBodyScrollEvent(e: any) {

    	//console.log(e.target.scrollingElement.scrollTop);

        if (this.onScrollDetect) {

            if (this.isHome) {
                this.isActive = true;

            } else {

                let current_pos = 0;

                // PC
                if (e.target.scrollTop != undefined) {
                    current_pos = e.target.scrollTop;

                // Mobile Touch Device
                } else {
                    current_pos = e.target.scrollingElement.scrollTop;
                }

                if (current_pos > this.start_pos) {
                    this.isActive = false;

                } else if ((current_pos + 120) < this.start_pos) {
                    this.isActive = true;
                }
                this.start_pos = current_pos;
            }
        }
	}


    public mounted() {
        this.updateActive();
    }

    // Active Menu
    public updateActive() {

        if (this.deviceSize != 'mb') {
            this.menuCount = this.menuCountPc;

        } else {
            this.menuCount = this.menuCountMb;
        }

        if (this.isHome) {
            this.styleX = {
                transform: `translateX(0)`,
            };

            this.classActiveItem = '-home';

        } else if (this.$nuxt.$route.name == 'official-layout') {

            if (!$(this.$refs.layout).length) {
                return;
            }

            const x = $(this.$refs.layout).offset().left;
            this.classActiveItem = '-layout';

            this.styleX = {
                transform: `translateX(${x}px)`,
            };

        } else if (this.$nuxt.$route.name == 'official-plan') {

            if (!$(this.$refs.plan).length) {
                return;
            }

            const x = $(this.$refs.plan).offset().left;
            this.classActiveItem = '-plan';

            this.styleX = {
                transform: `translateX(${x}px)`,
            };

        } else if (this.$nuxt.$route.name == 'official-feature') {

            if (!$(this.$refs.feature).length) {
                return;
            }

            const x = $(this.$refs.feature).offset().left;
            this.classActiveItem = '-feature';

            this.styleX = {
                transform: `translateX(${x}px)`,
            };

        } else {
            this.classActiveItem = '';
            this.styleX = {
                opacity: 0,
            };
        }

    }

    public get styleWidth(): any {
        return {
            width: `${appModule.mainFrameWidth / this.menuCount}px`,
        };
    }

    public get isHome(): any {
        if (this.$nuxt.$route.name == 'index') {
            this.isActive = true;
            return true;
        }
        return false;
    }

    public get pathName(): any {
        return this.$nuxt.$route.name;
    }

    public get isNav(): any {

        if (this.$nuxt.$route.name == 'index' || this.path('/official/')) {
            return true;
        }

        return false;
    }

	public path(path: string){
		if(!this){ return }
		const reg = new RegExp( '^'+ path);
		return reg.test(this.$nuxt.$route.path);
	}

	public get deviceSize(): any {
    	return appModule.deviceSize;
	}

	public get mainFrameResized(): any {
	    return themeMainModule.mainFrameResized;
	}

	public get bodyScrollEvent(): any {
	    return bodyModule.scrollEvent;
	}


}
