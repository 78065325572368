import {Component, Prop, Vue, Watch} from '@/node_modules/nuxt-property-decorator';
import * as $ from 'jquery';
import VarUtil, {$v} from "@/classes/utils/var-util";

@Component
export default class MInput extends Vue {

    @Prop({default: "text"})
    public type: string;

    @Prop({default: ""})
    public name: string;

    @Prop({default: "40"})
    public maxlength: string;

    @Prop({default: "false"})
    public spellcheck: string;

    @Prop({default: "Text Here..."})
    public placeholder: string;

    @Prop({default: ""})
    public value: string;

    /*
        s, m-book, l-book, xl-book etc
    */
    @Prop({default: ""})
    public size: string;

    //強制的にフォーカスさせる
    @Prop({default: false})
    public forcefocus: boolean;

    /*
      英語か数字の場合
      en
      num
    */
    @Prop({default: ''})
    public lang: string;

    public isActive: boolean = false;

    public get omaxlength(): string {
        return this.maxlength;
    }

    public get ospellcheck(): string {
        return this.spellcheck;
    }

    public get oplaceholder(): string {
        return this.placeholder;
    }

    public get olang(): any {
        return this.lang;
    }

    public get osize(): any {
        if (!this.size) {
            return
        }
        return 'size-' + this.size;
    }

    public focus() {
        this.isActive = true;
        this.triggerFocus();
    }

    public blur() {
        this.isActive = false;
    }

    public triggerFocus() {
        this.$nextTick(() => {
            $(this.$refs.form).trigger('focus');
        });
    }

    public onInput(e: any) {
        if (!$v.has(e, 'target.name')) {
            this.$emit('input', e.target.value);
        } else {
            this.$emit('input', {
                name: e.target.name,
                value: e.target.value,
            });
        }

        this.$nextTick(() => {
            this.$forceUpdate();
        });
    }

    public mounted() {
        if (this.forcefocus) {
            this.triggerFocus();
        }
    }
}
