import {Mutation, MutationAction, Action, VuexModule, getModule, Module} from 'vuex-module-decorators';
import store from '@/classes/core/store';

// state's interface
export interface INoticeModule {
    current: any;
}

@Module({dynamic: true, store, name: 'notice', namespaced: true})
class Notice extends VuexModule implements INoticeModule {

    public current: any = null;

    @Mutation
    public clearCurrent() {
        this.current = null;
    }

    @Mutation
    public updateCurrent(c: any = null) {
        this.current = c;
    }
}

export const noticeModule = getModule(Notice);

