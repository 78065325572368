import {Component, Prop, Vue, Watch} from '@/node_modules/nuxt-property-decorator';
import AVueComponent from '@/classes/page/a-vue-component';
import {appModule} from '@/store/app';
import {priceMachineModule} from '@/store/price-machine';
import {TweenLite} from 'gsap'
import {Price} from "@/configs/price";

import MPrice from '@/components/module/MPrice.vue';

@Component({
    components: {
	    MPrice
    }
})
export default class MPriceMachine extends AVueComponent {

    // 時間、日、年単位の表示
    @Prop({default: true})
	public info: boolean;

    // PRICE MACHINE ///////////////////////////////////////////////////
	//for tweenmax
    public base: number = 1580;
    public each: number = 1100;
    public total: number = 0;
    public day: number = 0;
    public month: number = 0;
    public year: number = 0;
    public isShake: boolean = false;


	@Watch('storeNumber')
	public watchNum(value: number) {
		const v = this.base + (this.each * value);
		TweenLite.to(this.$data, priceMachineModule.duration, {total: v});
	}

	@Watch('base')
	public watchBase(value: number) {
		const v = value + (this.each * this.storeNumber);
		TweenLite.to(this.$data, priceMachineModule.duration, {total: v});
	}

	@Watch('period')
	public watchPeriod(value: string) {

		if(value == 'month'){
			TweenLite.to(this.$data, priceMachineModule.duration, {each: this.eachMonth});
			TweenLite.to(this.$data, priceMachineModule.duration, {base: this.baseMonth});
		}else{
			TweenLite.to(this.$data, priceMachineModule.duration, {each: this.eachYear});
			TweenLite.to(this.$data, priceMachineModule.duration, {base: this.baseYear});
		}
	}

	@Watch('total')
	public watchTotal(value: number) {
// 	    const _hour = (value / 744);
	    const _day = (value / 31);
	    const _year = (value * 12);
// 		TweenLite.to(this.$data, priceMachineModule.duration, {hour: _hour});
		TweenLite.to(this.$data, priceMachineModule.duration, {day: _day});
		TweenLite.to(this.$data, priceMachineModule.duration, {month: value});
		TweenLite.to(this.$data, priceMachineModule.duration, {year: _year});
	}

    // SSRでサーバーサイドの処理から変数をセット
    public async asyncData(context: any): Promise<any> {
    }

    public created() {

	    if( priceMachineModule.period == 'month' ){
			this.base = this.baseMonth;
			this.each = this.eachMonth;
	    }else{
			this.base = this.baseYear;
			this.each = this.eachYear;
	    }

	    this.total = this.base + this.each;
    }

    public mounted() {

    }


    // Computed ///////////////////////////////////////////////////

    public get isMax(): boolean {
	    if (priceMachineModule.storeNumber < Price.maxnum) {
		    return false;
	    } else {
    	    return true;
	    }
    }

    public get isMin(): boolean {
	    if (priceMachineModule.storeNumber == 1) {
		    return true;
	    } else {
    	    return false;
	    }
    }

    public get isMore10(): boolean {
	    if (priceMachineModule.storeNumber >= 10) {
		    return true;
	    } else {
    	    return false;
	    }
    }

    public get storeNumber(): number {
        return priceMachineModule.storeNumber;
    }

    public get period(): any {
        return priceMachineModule.period;
    }

    // 月払い ベース料金
	public get baseMonth(): number {
    	return Price.baseMonth;
	}

    // 月払い 1店ごとの料金
	public get eachMonth(): number {
    	return Price.eachMonth;
	}

    // 年払い ベース料金
	public get baseYear(): number {
    	return Price.baseYear;
	}

    // 年払い 1店ごとの料金
	public get eachYear(): number {
    	return Price.eachYear;
	}

    // 最大店舗数
	public get maxnum(): number {
    	return Price.maxnum;
	}

    public get obase(): any {
	    return +this.base.toFixed(0);
    }

    public get oeach(): any {
	    return +this.each.toFixed(0);
    }

    public get oday(): any {
	    return +(Math.floor(this.day));
    }

    public get omonth(): any {
	    return +this.month.toFixed(0);
    }

    public get oyear(): any {
	    return +this.year.toFixed(0);
    }

	public get deviceSize(): any {
    	return appModule.deviceSize;
	}



    // Method ///////////////////////////////////////////////////

    public changePeriod(value: string = ''){

        if (value) {
		    priceMachineModule.updatePeriod(value);

        } else {

            if (priceMachineModule.period == 'month') {
		        priceMachineModule.updatePeriod('year');

            } else {
		        priceMachineModule.updatePeriod('month');
            }
        }
    }

    public increase() {

        this.isShake = false;

	    if( this.storeNumber < this.maxnum ){
		    priceMachineModule.updateStoreNumber(this.storeNumber + 1);

        } else {
            setTimeout( () => {
                this.isShake = true;
            }, 4);
        }
    }

    public decrease() {

        this.isShake = false;

	    if( this.storeNumber > 1 ){
		    priceMachineModule.updateStoreNumber(this.storeNumber - 1);
            this.isShake = false;

        } else {
            setTimeout( () => {
                this.isShake = true;
            }, 4);
        }
    }

    public removeShake() {
        this.isShake = false;
    }
}
