import {Mutation, MutationAction, Action, VuexModule, getModule, Module} from 'vuex-module-decorators';
import store from '@/classes/core/store';

// state's interface
export interface IAppStore {
	
	layer: string;
    
 }

@Module({dynamic: true, store, name: 'uploadlayer', namespaced: true})
class App extends VuexModule implements IAppStore {

    public layer: string = '';
    
    @Mutation
    public updateLayer(value: string) {
        this.layer = value;
    }
    
}

export const uploadlayerModule = getModule(App);