import {themeMainModule} from '@/store/theme/theme-main';
import {Brands} from '@/configs/brands';
import {DummyProject} from "@/configs/dummy/dummy-project";
import {ProjectOwnerAdapter} from "@/classes/domain/module/os/project/binder/project-owner-adapter";
import {MainModuleBinderHelper} from "@/classes/domain/module/os/project/binder/main-module-binder-helper";

export class MainModuleOwnerBinder {

    public static bindProject(project: any) {

        // console.log('MainModuleOwnerBinder.bind()');

        project = project || DummyProject;
        const adapter = new ProjectOwnerAdapter(project);

        self.bindMain(adapter);
        self.biindSidecover(adapter);
        self.bindMv(adapter);
    }

    private static bindMain(project: any) {

        //PROJECT DATA を STORE に格納
        themeMainModule.updateProject(project.project);
        themeMainModule.updateShopname(project.p('logo.shopname'));
        themeMainModule.updateTagline(project.p('logo.tagline'));
        themeMainModule.updateNavbottom(project.p('footer.navbottom'));

        //LOGO SCALES
        themeMainModule.updateNavLogoScale(project.p('logo.scale.navLogo'));
        themeMainModule.updateFooterLogoScale(project.p('logo.scale.footerLogo'));
        themeMainModule.updateBlockLogoScale(project.p('logo.scale.blockLogo'));
        themeMainModule.updateLoadingLogoScale(project.p('logo.scale.loadingLogo'));

        //NAVIGATION DATA
        themeMainModule.updateProjectNavigationPage(project.p('navigation.page', []));
        themeMainModule.updateProjectNavigationSns(project.p('navigation.sns'));

        //MAIN STORE
        //FOOTER などで使用
        themeMainModule.updateMainStoreAddress1(project.p('mainstore.address1'));
        themeMainModule.updateMainStoreAddress2(project.p('mainstore.address2'));
        themeMainModule.updateMainStoreOpen(project.p('mainstore.open'));
        themeMainModule.updateMainStoreClose(project.p('mainstore.close'));
        themeMainModule.updateMainStoreMap(project.p('mainstore.map'));
        themeMainModule.updateMainStoreTel(project.p('mainstore.tel') || '000-0000-00000');
        themeMainModule.updateMainStoreReservation(project.p('mainstore.reservation'));

        //FOOTER
        themeMainModule.updateFooter(project.p('footer'));

        //言語を設定
        themeMainModule.updateLang('ja');

        //BRAND DATA
        const brand: any = Brands.findByKey('brandid', project.p('global.brandid'));
        if (!brand) {
            return
        }
        //console.log(brand');
        themeMainModule.updateBrand(brand);

        //LAYOUT DATA
        const layout: any = brand.layouts.findByKey('id', project.p('global.layout'));
        if (!layout) {
            return
        }
        console.log('MainModuleOwnerBinder.bindMain() > layout > ',  project.p('global.layout'), layout);
        themeMainModule.updateLayout(layout);

        MainModuleBinderHelper.setWebfonts(brand.webfont, project.p('logo'));
    }

    private static biindSidecover(project: any) {
        themeMainModule.updateScType(project.p('sidecover.type'));
        themeMainModule.updateScDesign(project.p('sidecover.design'));
        themeMainModule.updateScAddrTitle(project.p('sidecover.addrTitle'));
        themeMainModule.updateScAddrLink(project.p('sidecover.addrLink'));
        themeMainModule.updateScAddress(project.p('sidecover.address', []));
        themeMainModule.updateScAddrFooter(project.p('sidecover.addrFooter'));
        themeMainModule.updateScAddrFooterTitle(project.p('sidecover.addrFooterTitle'));
        themeMainModule.updateScAddrFooterLink(project.p('sidecover.addrFooterLink'));
    }

    private static bindMv(project: any) {

        themeMainModule.updateMvAddress(project.p('mv.address'));
        themeMainModule.updateMvTopics(project.p('mv.topics'));
        themeMainModule.updateMvContent(project.p('mv.content'));
        themeMainModule.updateMvImg(project.p('mv.img'));
        themeMainModule.updateMvContentTxt(project.p('mv.contentTxt'));
        themeMainModule.updateMvContentImg(project.p('mv.contentImg'));
        themeMainModule.updateMvContentImgScale(project.p('mv.contentImgScale'));
        themeMainModule.updateMvContentLogoScale(project.p('mv.ContentLogoScale'));
        themeMainModule.updateMvContentTxtScale(project.p('mv.ContentTxtScale'));

        themeMainModule.updateMvAddressTitle(project.p('mvAddress.title'));
        themeMainModule.updateMvAddressBlock1title(project.p('mvAddress.block1title'));
        themeMainModule.updateMvAddressBlock1body(project.p('mvAddress.block1body'));
        themeMainModule.updateMvAddressBlock1link(project.p('mvAddress.block1link'));
        themeMainModule.updateMvAddressBlock2title(project.p('mvAddress.block2title'));
        themeMainModule.updateMvAddressBlock2body(project.p('mvAddress.block2body'));
        themeMainModule.updateMvAddressBlock3title(project.p('mvAddress.block3title'));
        themeMainModule.updateMvAddressBlock3body(project.p('mvAddress.block3body'));
        themeMainModule.updateMvAddressBlock3link(project.p('mvAddress.block3link'));
    }
}

const self = MainModuleOwnerBinder;
