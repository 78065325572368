import {Lang} from "@/classes/app/app-const";
import {$v} from "@/classes/utils/var-util";
import {LangLabel} from "@/configs/lang/label";

export class LangLabelUtil {
    /**
     * KeyからLabelを取得
     * @param key
     * @param labelPath
     * @param lang
     */
    public static _(key: string, labelPath: string, lang: Lang = Lang.Ja): string {
        return $v.p(self.lang(key, labelPath, lang), 'label', '');
    }

    /**
     * 言語データを取得
     * @param key
     * @param labelPath
     * @param lang
     */
    public static lang(key: string, labelPath: string, lang: Lang = Lang.Ja): any {
        const src: any[] = $v.p(LangLabel, `${lang}.${labelPath}`, []);

        // @ts-ignore
        return src!['findByKey']('key', key);
    }

    /**
     * キーワードから言語Indexを返す
     * @param key
     * @param labelPath
     * @param lang
     */
    public static findLangKeyByTxt(key: string, labelPath: string, lang: Lang = Lang.Ja): string {
        const src: any[] = $v.p(LangLabel, `${lang}.${labelPath}`, []);

        // @ts-ignore
        return $v.p(src!['findByKey']('label', key), 'key', '');
    }

    /**
     * ラベルの変換
     * @param label
     * @param labelPath
     * @param from
     * @param to
     */
    public static trans(label: string, labelPath: string, from: Lang = Lang.Ja, to: Lang = Lang.En): string {
        const lkey = self.findLangKeyByTxt(label, labelPath, from);
        const src: any[] = $v.p(LangLabel, `${to}.${labelPath}`, []);

        // console.log('**** src', lkey, src);
        // @ts-ignore
        return $v.p(src!['findByKey']('key', lkey), 'label', '');
    }
}

const self = LangLabelUtil;
