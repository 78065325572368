import {IApiBehavior} from './i-api-behavior';
import {IApiRequest} from './i-api-request';
import {ApiMethod} from './api-method';
import ApiUtil from './api-util';
import {sprintf} from 'sprintf-js';
import DummyApi from '@/classes/app/api/dummy-api';

export default class DummyBehaviorAsync implements IApiBehavior {

    constructor() {
    }

    access(req: IApiRequest): Promise<any> {
        console.log("DummyBehaviorAsync.access()", req);
        switch (req.method) {
            case ApiMethod.Get:
            case ApiMethod.Post:
            case ApiMethod.Put:
            case ApiMethod.Patch:
            case ApiMethod.Delete:
                return this.getAccess(req);
            default:
                throw new Error(sprintf('Method spec error!: %s', req.method));
        }
    }

    getAccess(req: IApiRequest, method: string = 'get'): Promise<any> {

        const dummy = new DummyApi();
        return new Promise((resolve, reject) => {
            resolve(req.cbThen({
                status: 200,
                data: dummy.access(req)
            }));
        }) as any;
    }

    deleteAccess(req: IApiRequest): void {
    }

    patchAccess(req: IApiRequest): void {
    }

    postAccess(req: IApiRequest): void {
    }

    putAccess(req: IApiRequest): void {
    }
}
