import {Component, Vue} from 'vue-property-decorator';
import {themeMainModule} from '@/store/theme/theme-main';

@Component
export default class CLogoFooterMix extends Vue {

    public get logoType(): any {
	    if(!themeMainModule.project){
		    return 'none'
		}
	    return themeMainModule.project.logo.type;
    }

    public get logoSize(): any {

        if (!themeMainModule.layout) {
            return;
        }

	    //Mobile
	    if(themeMainModule.deviceSize == 'mb') {
			return themeMainModule.layout.logo.mb.foot.size;
	    }

        return themeMainModule.layout.logo.pc.foot.size;
    }

    // Logo Size from layout in Brand.ts
    public get logoScaleType(): any {

        if (!themeMainModule.layout) {
            return;
        }

	    //Mobile
	    if(themeMainModule.deviceSize == 'mb') {
			return themeMainModule.layout.logo.mb.foot.scaletype;
	    }

        return themeMainModule.layout.logo.pc.foot.scaletype;
    }

    public get logoData(): any {
	    if(!themeMainModule.layout){
		    return
		}
	    if(themeMainModule.layout.navigation.color == 'mv') {
	    	return themeMainModule.project.logo.bg;
	    }
	    return themeMainModule.project.logo.txt;
    }

    public get logoColor(): any {

	    if(!themeMainModule.layout){
		    return
		}
	    let col: string = 'black';

	    if(themeMainModule.layout.navigation.color == 'mv') {
		    col = 'white';
	    }
	    return col;
    }


}
