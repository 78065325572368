




























































	// @ts-ignore
    import _ from './AccountDomainLeaveOtherAccount.ts'; export default _;
