import {Component, Vue} from 'nuxt-property-decorator';
import {themeMainModule} from '@/store/theme/theme-main';
import CMix from '@/classes/theme/mixin/c-mix';

// import CPatternIndexList from '@/components/theme/pattern/share/CIndexList.vue';
import CPageGalleryIndexStandard from '@/components/theme/page/gallery/index/CPageGalleryIndexStandard.vue';

@Component({
  mixins: [CMix],
  components: {
    CPageGalleryIndexStandard,
  }
})
export default class CIndex extends Vue {

  public title: string;

  ///////// 全ページ共通 ////////////////////////////////////////////
  public page: any = {
    type: 'page',
    page: 'gallery',
    depth: 'index',
  };

  // SSRでサーバーサイドの処理から変数をセット
  public async asyncData(context: any): Promise<any> {
    return {
      title: 'Indexa',
    };
  }

  // ヘッダタグの構成要素
  public head() {
    return {
      title: this.title
    };
  }

  public created() {

    //PAGE情報をSTOREにセット
    this!['updateMyPage'](this.page, themeMainModule);

  }
  
	public mounted() {
		this!['init']();
	}
  
}
