






























	// @ts-ignore
    import _ from './CTab.ts'; export default _;
