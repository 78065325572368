import {AApiExecuterAsync} from "@/classes/core/api/a-api-executer-async";
import {IApiBehavior} from "@/classes/core/api/i-api-behavior";
import VarUtil, {$v} from "@/classes/utils/var-util";
import {appAuthModule, AuthErrorCode, AuthProduct} from "@/store/app/auth";
import {IApiRequest} from "@/classes/core/api/i-api-request";

export class ManagerApiExecuterAsync extends AApiExecuterAsync {
    constructor(behavior: IApiBehavior, request: IApiRequest) {
        super(behavior);
        this.init(request);
    }

    public async execAsync(extract: string | null = null): Promise<any> {

        let r = null;
        let err = null;

        await this.then((res: any) => {
            if (!extract) {
                r = res.data;
            } else {
                if (VarUtil.has(res.data, extract)) {
                    r = VarUtil.path(res.data, extract);
                } else {
                    console.log('execAsync() > res.data', res.data);
                }
            }
        }).failue((res: any) => {
            const status = $v.p(res, 'response.status');
            console.error('ManagerApiExecuterAsync.failure() > ', status);
            if (status === 401) {
                console.log(' - 401 process');
                appAuthModule.logout(AuthProduct.To004Owner);
                appAuthModule.updateError({
                    prod: AuthProduct.To004Owner,
                    code: AuthErrorCode.E401,
                    name: 'e401',
                    messages: [
                        'Authentication error',
                    ],
                });
            }
            err = res;
        }).exec();

        if (!!err) {
            throw err;
        }

        return r;
    }
}
