import {Component, Watch} from 'nuxt-property-decorator';
import AVueComponent from '@/classes/page/a-vue-component';
import UseragentUtil from '@/classes/view/useragent-util';
import CCssvarsMix from '@/classes/theme/mixin/c-cssvars-mix';
import ManagerLoaderMix from '@/classes/mixin/manager-loader-mix';

import {appModule} from '@/store/app';
import {officialModule} from '@/store/official';
import {themeMainModule} from '@/store/theme/theme-main';
import {managerLoaderModule} from '@/store/manager/loader';

import MPolicyFrame from '@/components/module/MPolicyFrame.vue';
import MHelpFrame from '@/components/module/MHelpFrame.vue';
import MTourFrame from '@/components/module/MTourFrame.vue';
import MNavFrame from '@/components/module/MNavFrame.vue';
import MNoticeFrame from '@/components/module/MNoticeFrame.vue';
import MTipsFrame from '@/components/module/MTipsFrame.vue';
import MPopupFrame from '@/components/popup/MPopupFrame.vue';
import MSelectFrame from '@/components/select/MSelectFrame.vue';
import MLoaderFrame from '@/components/module/MLoaderFrame.vue';

import MoOfficialNav from '@/components/part/official/MoOfficialNav.vue';
import MGlobalFooter from '@/components/part/share/MGlobalFooter.vue';

//Account
import {ownersiteModule} from '@/store/ownersite';

@Component({
    mixins: [
        CCssvarsMix,
        ManagerLoaderMix
    ],
    components: {
	    MPolicyFrame,
	    MTourFrame,
	    MHelpFrame,
	    MNavFrame,
	    MNoticeFrame,
	    MTipsFrame,
	    MPopupFrame,
	    MSelectFrame,
	    MLoaderFrame,
	    MGlobalFooter,
	    MoOfficialNav,
    }
})
export default class Official extends AVueComponent {

    public isMounted: boolean = false;
	public gnav: boolean = false;

    public moFrameOption: any = {
        scrollbars: {
            visibility: 'auto',
            autoHide: 'scroll',
            autoHideDelay: 800,
        },
        overflowBehavior: {
            x: 'hidden',
        },
        callbacks: {
            onScroll: this.onScrollMpf,
        }
    };

	public created() {

	    //Project Data をStoreに格納
	    // themeMainModule.updateProject(DummyProject);
        console.log('layouts > Default.mounted()')
        themeMainModule.updateProject(ownersiteModule.project);

        this!['runLoading'](managerLoaderModule);
        this.isMounted = true;

        //Useragent and Browser Size

	}

	public mounted() {

    	//OS, Device
    	this.setDevice();

        //Force Resize Event
	    themeMainModule.updateMainFrameResized();
	}

    public get isNavOpen(): any {
        return officialModule.navOpen;
    }

    public get classPagetype(): any {
        return '-p--' + appModule.pagetype;
    }

	/*
		ACCOUNT
	*/
	public get isAccount(): any {
		if (/^manager-account/.test(this.$nuxt.$route.name) && !/^manager-account-procedure/.test(this.$nuxt.$route.name) ) {
		   return true;
		}
		return false;
	}

    /*
        Resize Event
        Managerの場合は、resize eventはownerサイトと同時に2つ起動できないので、
        Manager側のみでownerサイトも管理する
    */
	@Watch('mainFrameResized')
	public watchMainFrameResized() {
        this.resizedWindow()
	}

	public get mainFrameResized(): any {
	    return themeMainModule.mainFrameResized;
	}

    public resize() {
	    themeMainModule.updateMainFrameResized();
    }

	public resizedWindow() {
	    const w: number = window.innerWidth;
	    const h: number = window.innerHeight;
	    appModule.updateMainFrameWidth(w);
	    appModule.updateMainFrameHeight(h);
		appModule.updateDeviceSize(UseragentUtil.size(w));
	}

	/*
		デバイス判定
	*/
	public setDevice() {
	    const w: number = window.innerWidth;
		appModule.updateDeviceSize(UseragentUtil.size(w));
		appModule.updateDeviceOs(UseragentUtil.os());
		appModule.updateDeviceDevice(UseragentUtil.device());
	}

	public get deviceSize(): any {
    	return appModule.deviceSize;
	}

	public path(path: string){
		if(!this){ return }
		const reg = new RegExp( '^'+ path);
		return reg.test(this.$nuxt.$route.path);
	}

    public get pathName(): any {
        return this.$nuxt.$route.name;
    }

    public get isLoading(): boolean {
        return false;
    }

    public get loadingMain(): any {

        //mountするまでは false を返さない
        if (!this.isMounted) {
            return true;
        }

        return managerLoaderModule.loading;
    }



	// Methods /////////////////////////////////////////////////////////////////



	// Events /////////////////////////////////////////////////////////////////

    public onScrollMpf() {
        console.log('aaa');
    }


}





