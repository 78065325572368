import {Component, Vue} from '@/node_modules/nuxt-property-decorator';
import {appAuthModule} from "@/store/app/auth";

@Component({
    components: {
    }
})
export default class MSelectAccountMenu extends Vue {

    // Events //////////////////////////////////////////
    public async  onClickLogout() {
        await appAuthModule.logout();
        this.$router.push('/');
    }

   	/////// 共通処理 /////////////////////////////////
    ////////// POPUP LAYER 共通処理 /////////////////////////////////
	public onClose(to: string=''): any {
		if(to != ''){
			this.$router.push(to);
		}
        this.$emit('close');
	}


}
