import {
    ApiExecuterAsync,
    ApiExecuterFactory,
    ApiMethod,
    ApiUtil
} from '@/classes/core/api/import';
import {vsprintf} from 'sprintf-js';

export default class SystemRemainder {

    public valid(): ApiExecuterAsync {
        let url = ApiUtil.url('/system/remainder/valid');
        let request = ApiExecuterFactory.createRequest({
            method: ApiMethod.Post,
            url: url,
        });

        return ApiExecuterFactory.createAsync(request);
    }
    public show(accKey:string): ApiExecuterAsync {
        let url = ApiUtil.url(vsprintf('/system/remainder/%s', [accKey]));
        let request = ApiExecuterFactory.createRequest({
            method: ApiMethod.Get,
            url: url,
            param: {
                accKey: accKey,
            }
        });

        return ApiExecuterFactory.createAsync(request);
    }
    public store(email:string): ApiExecuterAsync {
        let url = ApiUtil.url('/system/remainder');
        let request = ApiExecuterFactory.createRequest({
            method: ApiMethod.Post,
            url: url,
            param: {
                email: email,
            }
        });

        return ApiExecuterFactory.createAsync(request);
    }
    public update(record:any): ApiExecuterAsync {
        let url = ApiUtil.url('/system/remainder');
        let request = ApiExecuterFactory.createRequest({
            method: ApiMethod.Put,
            url: url,
            param: record
        });

        return ApiExecuterFactory.createAsync(request);
    }
}
