










	// @ts-ignore
    import _ from './CIndex.ts'; export default _;
