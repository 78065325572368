








	// @ts-ignore
    import _ from './MSelectNews.ts'; export default _;
