

export const WizardStart = [

{
	step: 1,
	id: 'welcome',
	type: 'break',
	good: '',
	secondary: 'web',
	secWebPage: 'c-index',
	secWebBlock: 'mv'
}, 
{
	step: 2,
	id: 'subdomain',
	type: 'input',
	good: '',
	secondary: 'web',
	secWebPage: 'c-index',
	secWebBlock: 'mv'
}, 
{
	step: 3,
	id: 'signup',
	type: 'input',
	good: 'ready',
	secondary: 'web',
	secWebPage: 'c-index',
	secWebBlock: 'mv'
}, 
{
	step: 4,
	id: 'type',
	type: 'input',
	good: 'ready',
	secondary: 'web',
	secWebPage: 'c-index',
	secWebBlock: 'mv'
}, 

 
];