import {AManagerApi} from "@/classes/app/api/manager/a-manager-api";
import ApiExecuterAsync from "@/classes/core/api/api-executer-async";
import {ApiMethod} from "@/classes/core/api/api-method";
import {$v} from "@/classes/utils/var-util";

export class Ownersite extends AManagerApi {
    protected _url: string = '/ownersite';

    public get(param: any): ApiExecuterAsync {
        const domain = $v.p(param, 'domain');
        const path = $v.p(param, 'path');
        return this.createExecuterAsync(ApiMethod.Get,
            this.url + '/' + domain + '/' + path, {});
    }
}
