








	// @ts-ignore
    import _ from './MSecondaryPress.ts'; export default _;
