import ApiExecuter from './api-executer';
import AxiosBehavior from './axios-behavior';
import {ApiMethod} from './api-method';
import {IApiRequest} from './i-api-request';
import ApiExecuterAsync from './api-executer-async';
import ApiConst from './api-const';
import VarUtil from '@/classes/utils/var-util';
import {Env} from '@/classes/app/env';
import DummyBehaviorAsync from '@/classes/core/api/dummy-behavior-async';
import AxiosBehaviorAsync from '@/classes/core/api/axios-behavior-async';
import {appAuthModule} from "@/store/app/auth";
import {AuthManager} from "@/classes/app/auth-manager";
import ApiUtil from "@/classes/app/api/api-util";

export default class ApiExecuterFactory {

    public static createBehaviorAsync() {
        // console.log('ApiExecuterFactory.createBehavior() > DATA_REF = ', Env.DATA_REF);
        //switch (Env.DATA_REF) {
        // console.log('ApiUtil.dataRef > ', ApiUtil.dataRef());
        switch (ApiUtil.dataRef()) {
            case 'axios':
                return new AxiosBehaviorAsync();
            default:
                return new DummyBehaviorAsync();
        }
    }

    public static createRequest(request: { [key: string]: any } = {}): IApiRequest {

        const header = {};
        VarUtil.tap(VarUtil.path(AuthManager.auth, 'token'), (token) => {
            if (!!token) {
                header['X-Auth-Token'] = token;
            }
        });
        // console.log('createRequest > header', header);

        return VarUtil.export<any>({}, {
            method: ApiMethod.Get,
            url: '',
            param: null,
            enctype: ApiConst.EncType.Urlencoded,
            header,
            cbThen: (res: any) => {
            },
            cbCatch: async (res: any) => {
                console.log('cbCatch > res > ', res);

            },
            cbAll: (res: any) => {
            }
        }, request);
    }

    public static create(request: IApiRequest | null = null): ApiExecuter {
        //let api = new ApiExecuter(new JQueryBehavior());
        let api = new ApiExecuter(new AxiosBehavior());
        if (request != null) {
            api.init(request);
        }
        return api;
    }

    public static createAsync(request: IApiRequest | null = null): ApiExecuterAsync {

        let api = new ApiExecuterAsync(ApiExecuterFactory.createBehaviorAsync());
        if (request !== null) {
            api.init(request);
        }
        return api;
    }

    public static createRequestWithAsync(method: ApiMethod, url: string, param: any = {}): ApiExecuterAsync {
        let request = ApiExecuterFactory.createRequest({
            method,
            url,
            param
            // url: ApiUtil.url(url, param!['domain']),
        });
        return ApiExecuterFactory.createAsync(request);
    }

    public static createRequestRecordWithAsync(method: ApiMethod, url: string, param: any = {}): ApiExecuterAsync {

        console.log('[190302]createRequestRecordWithAsync > param > method=%s, url=%s', method, url, param);

        let request = ApiExecuterFactory.createRequest({
            method,
            url,
            param
            // url: ApiUtil.url(`${url}/${id}`, param!['domain']),
        });

        return ApiExecuterFactory.createAsync(request);
    }
}
