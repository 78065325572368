import ApiExecuterAsync from '@/classes/core/api/api-executer-async';
import ApiExecuterFactory from '@/classes/core/api/api-executer-factory';
import {ApiMethod} from '@/classes/core/api/api-method';
import VarUtil from '@/classes/utils/var-util';
import IApi from "@/classes/core/api/i-api";
import {ApiUtil} from "@/classes/core/api/import";
import {Env} from "@/classes/app/env";

export default class AApi implements IApi {

    protected _domain: string | null = null;
    protected _url: string = '';

    public get url(): string {
        return ApiUtil.url(this._url);
    }

    public get(param: any = {}): ApiExecuterAsync {
        return ApiExecuterFactory.createRequestWithAsync(ApiMethod.Get, this.url, this.filterParam(param));
    }

    public show(id: any, param: any = {}): ApiExecuterAsync {
        return ApiExecuterFactory.createRequestRecordWithAsync(ApiMethod.Get, this.url + '/' + id, this.filterParam(param));
    }

    public post(param: any = {}): ApiExecuterAsync {
        return ApiExecuterFactory.createRequestWithAsync(ApiMethod.Post, this.url, this.filterParam(param));
    }

    public put(id: any, param: any = {}): ApiExecuterAsync {
        return ApiExecuterFactory.createRequestRecordWithAsync(ApiMethod.Put, this.url + '/' + id, this.filterParam(param));
    }

    public patch(id: any, param: any = {}): ApiExecuterAsync {
        return ApiExecuterFactory.createRequestRecordWithAsync(ApiMethod.Patch, this.url + '/' + id, this.filterParam(param));
    }

    public delete(id: any, param: any = {}): ApiExecuterAsync {
        return ApiExecuterFactory.createRequestRecordWithAsync(ApiMethod.Delete, this.url + '/' + id, this.filterParam(param));
    }

    protected filterParam(param: any = {}): any {
        return VarUtil.export<any>({}, param || {}, {
            domain: this._domain
        });
    }
}
