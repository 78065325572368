import {Component, Watch, Prop, Vue} from '@/node_modules/nuxt-property-decorator';
import APopup from "@/classes/page/a-popup";

import MPopupMBody from '@/components/popup/module/MPopupMBody.vue';
import {ExtEdit} from "@/classes/ext/ext-edit";
import {AppVar} from "@/classes/core/app-var";
import {$v} from "@/classes/utils/var-util";

@Component({
    components: {
        MPopupMBody
    }
})
export default class MPopupPageLink extends APopup {

    public localeBase: string = 'manager.popup.pageLink';
    public mid: string = 'MPopupPageLink';

    @Watch('option')
    public async watchOption(now: any) {

        console.log('watchOption', now);
        if (!$v.path(now, 'saved')) {
            return;
        }

        this.close();
    }

    // Init /////////////////////////////////
    public mounted() {
        console.log('MPopupPageLink');
        this.initInput();
    }

    public initInput() {
        this.extEdit.updateInput({
            ...this.value,
        });
    }

    // Getters ///////////////////////////////
    public get extEdit(): ExtEdit {
        return new ExtEdit(this);
    }

    public get value(): any {
        return $v.p(AppVar.find(this.mid), 'value');
    }

    public get option(): any {
        return $v.p(AppVar.find(this.mid), 'opt');
    }

    public get input(): any {
        return this.extEdit.input;
    }

    public get withLabel(): boolean {
        return !!$v.p(this.option, 'withLabel');
    }

    public close() {
        this.$emit('close');
    }

    // Events ////////////////////////////////
    public onInput(e: any) {
        return this.extEdit.updateInput(e);
    }

    public async onClickOk() {
        AppVar.put({
            id: this.mid,
            value: {...this.input},
            opt: {
                saved: true,
            },
        });
    }

    ////////// POPUP LAYER 共通処理 /////////////////////////////////
    public onClose(): any {
        this.close();
    }
}
