import {Component, Prop, Vue} from '@/node_modules/nuxt-property-decorator';
import VarUtil from "@/classes/utils/var-util";
import ImageUtil from "@/classes/utils/image-util";
import {EnvUtil} from "@/classes/app/env-util";
import {Env} from "@/classes/app/env";

/**
 * <s-img
 *  // ソース(/common/imgが自動でつく)
 *      src="/manager/aaa/bbb.jpg"
 *  // Alt
 *      alt=""
 *  // Title
 *      title=""
 *  // srcset
 *      srcset="auto" ... 自動計算
 *      srcset="no|false" ... srcset無し
 *      srcset="直指定" ... 直接指定
 *  />
 **/
@Component
export default class SImg extends Vue {

    @Prop({default: '@'})
    public host: string;

    @Prop({default: '/common/img'})
    public base: string;

    @Prop({default: ''})
    public src: string;

    @Prop({default: ""})
    public alt: string;

    @Prop({default: ""})
    public title: string;

    @Prop({default: 'auto'})
    public srcset: boolean | string;

    @Prop({default: false})
    public loading: boolean;

    @Prop({default: false})
    public aspect: boolean;

    public classAspect: string = '-';
    public isLoading: boolean = this.loading;

    public created() {

        if (this.aspect) {
            this.setRatio();
        }
    }

    public setRatio() {

        let image: any = new Image();
        image.src = this.osrc;

        image.addEventListener('load', () => {

            const ratio: number = image.width / image.height;

            if (ratio < 0.87) {
                this.classAspect = '-vertical';

            } else if (ratio > 1.13) {
                this.classAspect = '-horizontal';

            } else {
                this.classAspect = '-square';

            }
        });
    }

    public get baseFull(): string {
        if (this.host === '@') {
            return ImageUtil.src(this.base);
        } else {
            return this.base;
        }
    }

    public get osrc(): string {

        // console.log('SImg > src=', Env.MEDIA_BASE, this.src);

        if (/^data:/.test(this.src)) {
            return this.src;
        }

        if (/^http(s)?:\/\//.test(this.src)) {
            return this.src;
        }

        if (/^\/common\//.test(this.src) || /^\/f\//.test(this.src)) {
            return String(Env.MEDIA_BASE).replace(/\/$/, '') + this.src;
        }

        return ((this.baseFull || '') + this.src);
    }

    public get oalt(): string {
        return this.alt;
    }

    public get otitle(): string {
        return this.title;
    }

    public get osrcset(): string {

        if (this.isBlankSrcset) {
            return '';
        }

        if (this.isSrcsetOfString
            && (this.srcset !== 'auto'
                && this.srcset.hasOwnProperty('length'))) {
            return (this.srcset as string);
        }

        const ext = VarUtil.tap(/(\.[a-z]+)$/.test(this.osrc), (_: boolean) => {
            return RegExp.$1;
        });

        const sets = [`${this.osrc} 1x`];
        sets.push(this.osrc.replace(/(\.[a-z]+)$/, '_2x' + ext) + ' 2x');

        // console.log('***', this.osrc, ext, sets, RegExp.$1);

        return sets.join(',');
    }

    public get isSrcsetOfString(): boolean {
        return this.srcset.hasOwnProperty('length');
    }

    /**
     * srcsetを空で返すか
     */
    public get isBlankSrcset(): boolean {

        // srcsetがBlank
        if (!this.srcset) {
            return true;
        }

        // svgファイルも不要
        if (/\.svg(\?.+)*$/.test(this.osrc)) {
            return true;
        }

        //テキスト且つ no または falseの場合も空
        if (this.isSrcsetOfString
            && ['no', 'false'].indexOf((this.srcset as string).toLowerCase()) >= 0) {
            return true;
        }

        return false;
    }


    /*
	    LOADED
    */
    public loaded() {
        this.isLoading = false;
    }
}
