











































	// @ts-ignore
    import _ from './MPopupLayoutPreview.ts'; export default _;
