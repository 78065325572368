import {Component, Vue} from 'nuxt-property-decorator';
import AVueComponent from '@/classes/page/a-vue-component';
import {accountModule} from '@/store/account';

@Component({
    components: {
    }
})
export default class MSecondaryDomainMove extends AVueComponent {
	
	public get isStep(): any {
		
		if( !this.path('/manager/account/procedure/domain-move/') || !this.path('/manager/account/procedure/domain-move/move-in-account') && accountModule.dev_procedure == 'domain-move' ){
			return false;
		}
		
		return true;
	}
	
}
