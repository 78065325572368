import {Component, Watch, Prop, Vue} from "vue-property-decorator";
import {themeMainModule} from '@/store/theme/theme-main';

@Component({
    components: {
    }
})
export default class CArticleFilter extends Vue {

	//表示デバイス
    @Prop({default: 'pc'})
	public device: string;

    @Prop({default: () => {} })
	public d: any;

    @Prop({default: '0'})
	public active: string;

    @Prop({default: ''})
    public link: string;

    // 初期アクティブ
    public activeItemId: string = this.active;


	public onActive(value: string) {
		this.activeItemId = value;
	}


	public get sdevice(): any {

    	if (this.device == 'auto') {
            if (themeMainModule.deviceSize != 'mb') {
                return 'pc';

            } else {
                return 'mb';

            }
    	}

    	return this.device;
	}

}



