import {Component, Watch, Prop, Vue} from 'vue-property-decorator';
import VarUtil, {$v} from '@/classes/utils/var-util';
import ColorUtil from '@/classes/view/color-util';
import {themeMainModule} from '@/store/theme/theme-main';
import {themeCssvarsModule} from '@/store/theme/theme-cssvars';
import {themeStateModule} from '@/store/theme/theme-state';

import CHeaderMix from '@/classes/theme/mixin/header/c-header-mix';
import CLogoHeaderMix from '@/classes/theme/mixin/c-logo-header-mix';
import CNavigationMix from '@/classes/theme/mixin/c-navigation-mix';

import CNavBlock from '@/components/theme/module/header/CNavBlock.vue';
import CNavBlockSns from '@/components/theme/module/header/CNavBlockSns.vue';

import * as $ from 'jquery';

@Component({
    mixins: [
        CHeaderMix,
        CLogoHeaderMix,
        CNavigationMix,
    ],
    components: {
        CNavBlock,
        CNavBlockSns,
    }
})
export default class CHeader extends Vue {

    public mount: boolean = false;
    public isTogglePc: boolean = false;
    public styleFrame: any = {};
    public $sLogo: any;
    public $navContainerPc: any;
    public $navFrame: any;
    public navFrameElement: any;
    public navItems: any = {
        primary: [],
        secondary: [],
    };
    public resizing: boolean = false;
    public snsType: string = 'select';
    public navlogoColor: string = 'txt';

    @Watch('projectNavigationPage')
    public watchNavigationPage() {
        if (this.mount) {
            this.resize();
        }
    }

    @Watch('projectNavigationSns')
    public watchNavigationSns() {
        if (this.mount) {
            this.resize();
        }
    }

    @Watch('layoutId')
    public watchLayoutId(value: string) {
        if (this.mount) {
            this.init();
        }
    }

    @Watch('deviceSize')
    public watchdeviceSize() {
        if (this.mount) {
            this.init();
        }
    }

    @Watch('mainFrameResized')
    public watchMainFrameResized() {
        if (this.mount) {
            this.resize();
        }
    }

    @Watch('colorMvTxt')
    public watchColorMvTxt() {
        this.calcNavLogoColor();
    }

    @Watch('colorTxt')
    public watchColorTxt() {
        this.calcNavLogoColor();
    }

    public mounted() {
        console.log('CHeader.mounted() > themeMainModule.project > ', themeMainModule.project);
        //LOADED OUT: LOGOの表示が完了してから実行
        setTimeout(() => {
            this.init();
        }, 300);
    }

    /*
        Init
        Styleを適用する
    */
    public init() {

        this.$nextTick(() => {
            this.$sLogo = $('.c-nav-logo .s-logo');
            this.$navContainerPc = $(this.$refs.navContainerPc);
            this.$navFrame = $(this.$refs.navFrame!['$el'] as HTMLElement);
            this.navFrameElement = this.$refs.navFrame!['$el'] as HTMLElement;

            //css variables から sns typeを取得して設定する
            this.setSnsType();

            if (themeMainModule.deviceSize == 'mb') {
                this.setContentsFrameStyle();

            } else {
                themeMainModule.updateNavMbHeaderH($(this.$refs.mbNavHeader).outerWidth());
                themeMainModule.updateNavHeaderH($(this.$refs.navLogo).innerHeight());
                themeMainModule.updateNavContainerH($(this.$refs.navWrapper).outerHeight());

                //LOADED OUT: ロゴの表示完了後に計算
                setTimeout(() => {
                    themeMainModule.updateNavHeaderOuterH(this.$navFrame.outerHeight());
                }, 300);
            }

            /*
                1文字の幅を得るために一旦描画されてから計算する

            */
            setTimeout(() => {
                //console.log('CHeader resize() from init()');
                this.resize();
            }, 10);
        });
    }


    /////////// Resize //////////////////////////////////////////////

    public resize() {

        if (!this.resizing) {

            //処理の最後にfalseにする
            this.resizing = true;

            // Mobile
            if (themeMainModule.deviceSize == 'mb') {

                // Manager
                if (themeMainModule.manager) {
                    this.$nextTick(() => {

                        if (this.$navFrame.css('position') == 'fixed') {

                            this.styleFrame = {
                                top: `${this.mainFrameOffset.top}px`,
                                width: `${themeMainModule.mainFrameWidth}px`,
                                height: `${themeMainModule.mainFrameHeight}px`
                            };
                        }
                    });
                }

                this.mount = true;
                this.resizing = false;


                // Pc, Tablet
            } else {

                // Manager
                if (themeMainModule.manager) {

                    if (this.$navFrame.css('position') == 'fixed') {

                        const marginLeft = parseInt(this.$navFrame.css('margin-left'));
                        const marginRight = parseInt(this.$navFrame.css('margin-right'));

                        this.styleFrame = {
                            top: `${this.mainFrameOffset.top}px`,
                            width: `${themeMainModule.mainFrameWidth - marginLeft - marginRight}px`
                        };

                    } else {
                        this.styleFrame = {};
                    }
                }

                /*
                    項目をバランスよく配置する
                    {
                        primary: [],
                        secondary: []
                    }
                */

                console.log('CHeader > projectNavigationPage > ', this!['projectNavigationPage'],);

                this.navItems = this!['layoutNavigation'](
                    this.$sLogo,
                    this.$navContainerPc,
                    this.navFrameElement,
                    this!['projectNavigationPage'],
                    this.cLayout,
                    this!['projectNavigationSns'],
                    this!['projectNavigationPageSpecial'],
                );

                setTimeout(
                    () => {
                        this.resizing = false;
                    }, 10);

                //初期に2回起動しないようにおくらせる
                setTimeout(
                    () => {
                        this.mount = true;
                        this.calcNavLogoColor();
                    }, 300);

                // Set Contents Frame Top
                this.setContentsFrameStyle();
            }
        }
    }


    /*
        MOBILE NAVIGATION OPEN TOGGLE
    */
    public onToggle() {
        let value: boolean = themeStateModule.navOpen;
        value = !value;
        themeStateModule.updateNavOpen(value);
    }


    /*
        SET HEIGHT STYLE ON .c-contents-frame WITH MOBILE
    */
    public setContentsFrameStyle() {

        let style: any = {};

        if (themeMainModule.deviceSize == 'mb') {
            if (this.$navFrame.css('position') == 'fixed') {
                style = {
                    paddingTop: `${$('.c-mb-nav-header').outerHeight()}px`
                };
            }
        }

        themeMainModule.updateContentsFrameStyle(style);
    }

    public calcNavLogoColor() {

        if (!this.mount) {
            return;
        }

        const col = window.getComputedStyle(this.navFrameElement).getPropertyValue('--header-color');

        //スペースとダブルクォーテーション、クォーテーションをとる
        const result = col.replace(/"|\s+|'/g, '');
        let txt: string = '0,0,0';

        //通常のテキスト色かMVテキストカラーか
        if (result == 'mvtxt') {
            txt = themeCssvarsModule.mvTxt;

        } else {
            txt = themeCssvarsModule.txt;
        }

        //RGBから黒か白か判断
        if (ColorUtil.blackOrWhite(txt) == 'bk') {
            this.navlogoColor = 'txt';

        } else {
            this.navlogoColor = 'bg';
        }
    }


    /*
        GETTER
    */
    public get deviceSize(): any {
        return themeMainModule.deviceSize;
    }

    public get layoutId(): any {
        return $v.p(themeMainModule, 'project.global.layout') || '';
    }

    public get projectMainstoreMap(): any {
        return $v.p(themeMainModule, 'project.mainstore.map') || [];
    }

    public get mainFrameResized(): any {
        return themeMainModule.mainFrameResized;
    }

    public get cLayout(): any {
        return VarUtil.path(themeMainModule, 'layout.layout') || 'center';
    }

    public get classNavLayout(): any {
        return '-layout--' + themeMainModule.layout.layout;
    }

//     public get switchScrollDownReverseColor(): any {
//         return themeStateModule.switchScrollDownReverseColor;
//     }

    public get mainFrameOffset(): any {
        return themeMainModule.mainFrameOffset;
    }

    public get colorMvTxt(): any {
        return themeCssvarsModule.mvTxt;
    }

    public get colorTxt(): any {
        return themeCssvarsModule.txt;
    }

    public get navOpenPc(): any {
        return themeStateModule.navOpenPc;
    }

    public setSnsType() {

        if (!this.navFrameElement) {
            return;
        }

        const value = window.getComputedStyle(this.navFrameElement).getPropertyValue('--nav-sns-type');
        this.snsType = value.replace(/"|\s+|'/g, '');

        //読み込めなくて空っぽになったらselectを代入
        if (!this.snsType) {
            this.snsType = 'select';
        }
    }


    /*
        PC NAVIGATION OPEN TOGGLE
    */
    public onTogglePc() {
        let value: boolean = themeStateModule.navOpenPc;
        value = !value;
        themeStateModule.updateNavOpenPc(value);
    }
}



