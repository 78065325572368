import {Component} from "nuxt-property-decorator";
import MLogoList from '@/components/module/MLogoList.vue';
import APopup from "@/classes/page/a-popup";

@Component({
    components: {
        MLogoList,
    }
})
export default class MPopupLogoList extends APopup {

    ////////// POPUP LAYER 共通処理 /////////////////////////////////
    public onClose(to: string = ''): any {
        if (to != '') {
            this.$router.push(to);
        }
        this.$emit('close');
    }
}
