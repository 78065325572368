/*

	YH: Data Spread Sheet is Here
	https://docs.google.com/spreadsheets/d/1GP-wc5RwVL54UcYqxjH_ZfCQWY7IAfuL_BSC2SXCL8s/edit?usp=sharing

*/

export const DummyGallery = [

{
	id: 1,
	date: '2018.06.12',
	state: 'draft',
	title: '醜く歪んだ「銃弾の破片」に、その強大な破壊力を見た ── 写真家がとらえた12の造形',
	body: '<p>ジョバンニが学校の門を出るとき、同じ組の七八人は家へ帰らずカムパネルラをまん中にして校庭の隅すみの桜さくらの木のところに集まっていました。それはこんやの星祭に青いあかりをこしらえて川へ流す烏瓜からすうりを取りに行く相談らしかったのです。</p><h2>午后ごごの授業</h2><p>ジョバンニが学校の門を出るとき、同じ組の七八人は家へ帰らずカムパネルラをまん中にして校庭の隅すみの桜さくらの木のところに集まっていました。それはこんやの星祭に青いあかりをこしらえて川へ流す烏瓜からすうりを取りに行く相談らしかったのです。</p><div class="s-img-component"><img src="/common/img/dummy-user/pjname/blog/1-m.jpg" alt="" title="" srcset="/common/img/dummy-user/pjname/blog/1-m.jpg 1x,/common/img/dummy-user/pjname/blog/1-m_2x.jpg 2x" nopin="nopin" class=""></div><h2>午后ごごの授業</h2><p>ジョバンニが学校の門を出るとき、同じ組の七八人は家へ帰らずカムパネルラをまん中にして校庭の隅すみの桜さくらの木のところに集まっていました。それはこんやの星祭に青いあかりをこしらえて川へ流す烏瓜からすうりを取りに行く相談らしかったのです。</p><h2>午后ごごの授業</h2><div class="s-img-component"><img src="/common/img/dummy-user/pjname/blog/1-m.jpg" alt="" title="" srcset="/common/img/dummy-user/pjname/blog/1-m.jpg 1x,/common/img/dummy-user/pjname/blog/1-m_2x.jpg 2x" nopin="nopin" class=""></div>',
	category: ['Category A', 'Category B', 'Category C'],
	img: ['/templates/hair/gallery/1-m.jpg', '/templates/hair/gallery/2-m.jpg', '/templates/hair/gallery/3-m.jpg']
},
{
	id: 2,
	date: '2019.04.07',
	state: '',
	title: 'iPad上で油絵や水彩画と同じ「塗り重ね」や「色混ざり」を実現するお絵かきツール',
	body: '<p>ジョバンニが学校の門を出るとき、同じ組の七八人は家へ帰らずカムパネルラをまん中にして校庭の隅すみの桜さくらの木のところに集まっていました。それはこんやの星祭に青いあかりをこしらえて川へ流す烏瓜からすうりを取りに行く相談らしかったのです。</p><h2>午后ごごの授業</h2><p>ジョバンニが学校の門を出るとき、同じ組の七八人は家へ帰らずカムパネルラをまん中にして校庭の隅すみの桜さくらの木のところに集まっていました。それはこんやの星祭に青いあかりをこしらえて川へ流す烏瓜からすうりを取りに行く相談らしかったのです。</p><div class="s-img-component"><img src="/common/img/dummy-user/pjname/blog/1-m.jpg" alt="" title="" srcset="/common/img/dummy-user/pjname/blog/1-m.jpg 1x,/common/img/dummy-user/pjname/blog/1-m_2x.jpg 2x" nopin="nopin" class=""></div><h2>午后ごごの授業</h2><p>ジョバンニが学校の門を出るとき、同じ組の七八人は家へ帰らずカムパネルラをまん中にして校庭の隅すみの桜さくらの木のところに集まっていました。それはこんやの星祭に青いあかりをこしらえて川へ流す烏瓜からすうりを取りに行く相談らしかったのです。</p><h2>午后ごごの授業</h2><div class="s-img-component"><img src="/common/img/dummy-user/pjname/blog/1-m.jpg" alt="" title="" srcset="/common/img/dummy-user/pjname/blog/1-m.jpg 1x,/common/img/dummy-user/pjname/blog/1-m_2x.jpg 2x" nopin="nopin" class=""></div>',
	category: ['Category A', 'Category B', 'Category C'],
	img: ['/templates/hair/gallery/2-m.jpg']
},
{
	id: 3,
	date: '2019.04.08',
	state: 'hidden',
	title: 'アルツハイマー病のリスクが3分の1になる薬の研究が闇に葬られた理由とは？',
	body: '<p>ジョバンニが学校の門を出るとき、同じ組の七八人は家へ帰らずカムパネルラをまん中にして校庭の隅すみの桜さくらの木のところに集まっていました。それはこんやの星祭に青いあかりをこしらえて川へ流す烏瓜からすうりを取りに行く相談らしかったのです。</p><h2>午后ごごの授業</h2><p>ジョバンニが学校の門を出るとき、同じ組の七八人は家へ帰らずカムパネルラをまん中にして校庭の隅すみの桜さくらの木のところに集まっていました。それはこんやの星祭に青いあかりをこしらえて川へ流す烏瓜からすうりを取りに行く相談らしかったのです。</p><div class="s-img-component"><img src="/common/img/dummy-user/pjname/blog/1-m.jpg" alt="" title="" srcset="/common/img/dummy-user/pjname/blog/1-m.jpg 1x,/common/img/dummy-user/pjname/blog/1-m_2x.jpg 2x" nopin="nopin" class=""></div><h2>午后ごごの授業</h2><p>ジョバンニが学校の門を出るとき、同じ組の七八人は家へ帰らずカムパネルラをまん中にして校庭の隅すみの桜さくらの木のところに集まっていました。それはこんやの星祭に青いあかりをこしらえて川へ流す烏瓜からすうりを取りに行く相談らしかったのです。</p><h2>午后ごごの授業</h2><div class="s-img-component"><img src="/common/img/dummy-user/pjname/blog/1-m.jpg" alt="" title="" srcset="/common/img/dummy-user/pjname/blog/1-m.jpg 1x,/common/img/dummy-user/pjname/blog/1-m_2x.jpg 2x" nopin="nopin" class=""></div>',
	category: ['Category A', 'Category B', 'Category C'],
	img: ['/templates/hair/gallery/3-m.jpg']
},
{
	id: 4,
	date: '2019.04.09',
	state: '',
	title: '人類初の月面到達まで1万1000時間分のアポロ11号の映像と音声をリアルタイムで配信するサイトが登場',
	body: '<p>ジョバンニが学校の門を出るとき、同じ組の七八人は家へ帰らずカムパネルラをまん中にして校庭の隅すみの桜さくらの木のところに集まっていました。それはこんやの星祭に青いあかりをこしらえて川へ流す烏瓜からすうりを取りに行く相談らしかったのです。</p><h2>午后ごごの授業</h2><p>ジョバンニが学校の門を出るとき、同じ組の七八人は家へ帰らずカムパネルラをまん中にして校庭の隅すみの桜さくらの木のところに集まっていました。それはこんやの星祭に青いあかりをこしらえて川へ流す烏瓜からすうりを取りに行く相談らしかったのです。</p><div class="s-img-component"><img src="/common/img/dummy-user/pjname/blog/1-m.jpg" alt="" title="" srcset="/common/img/dummy-user/pjname/blog/1-m.jpg 1x,/common/img/dummy-user/pjname/blog/1-m_2x.jpg 2x" nopin="nopin" class=""></div><h2>午后ごごの授業</h2><p>ジョバンニが学校の門を出るとき、同じ組の七八人は家へ帰らずカムパネルラをまん中にして校庭の隅すみの桜さくらの木のところに集まっていました。それはこんやの星祭に青いあかりをこしらえて川へ流す烏瓜からすうりを取りに行く相談らしかったのです。</p><h2>午后ごごの授業</h2><div class="s-img-component"><img src="/common/img/dummy-user/pjname/blog/1-m.jpg" alt="" title="" srcset="/common/img/dummy-user/pjname/blog/1-m.jpg 1x,/common/img/dummy-user/pjname/blog/1-m_2x.jpg 2x" nopin="nopin" class=""></div>',
	category: ['Category A', 'Category B', 'Category C'],
	img: ['/templates/hair/gallery/1-m.jpg']
},
{
	id: 5,
	date: '2019.04.10',
	state: 'draft',
	title: 'エンブレルがアルツハイマー病のリスクを低減',
	body: '<p>ジョバンニが学校の門を出るとき、同じ組の七八人は家へ帰らずカムパネルラをまん中にして校庭の隅すみの桜さくらの木のところに集まっていました。それはこんやの星祭に青いあかりをこしらえて川へ流す烏瓜からすうりを取りに行く相談らしかったのです。</p><h2>午后ごごの授業</h2><p>ジョバンニが学校の門を出るとき、同じ組の七八人は家へ帰らずカムパネルラをまん中にして校庭の隅すみの桜さくらの木のところに集まっていました。それはこんやの星祭に青いあかりをこしらえて川へ流す烏瓜からすうりを取りに行く相談らしかったのです。</p><div class="s-img-component"><img src="/common/img/dummy-user/pjname/blog/1-m.jpg" alt="" title="" srcset="/common/img/dummy-user/pjname/blog/1-m.jpg 1x,/common/img/dummy-user/pjname/blog/1-m_2x.jpg 2x" nopin="nopin" class=""></div><h2>午后ごごの授業</h2><p>ジョバンニが学校の門を出るとき、同じ組の七八人は家へ帰らずカムパネルラをまん中にして校庭の隅すみの桜さくらの木のところに集まっていました。それはこんやの星祭に青いあかりをこしらえて川へ流す烏瓜からすうりを取りに行く相談らしかったのです。</p><h2>午后ごごの授業</h2><div class="s-img-component"><img src="/common/img/dummy-user/pjname/blog/1-m.jpg" alt="" title="" srcset="/common/img/dummy-user/pjname/blog/1-m.jpg 1x,/common/img/dummy-user/pjname/blog/1-m_2x.jpg 2x" nopin="nopin" class=""></div>',
	category: ['Category A', 'Category B', 'Category C'],
	img: ['/templates/hair/gallery/2-m.jpg']
},
{
	id: 6,
	date: '2019.04.11',
	state: '',
	title: '国家の威信をかけた月面到達プロジェクトに挑んだニール・アームストロングの伝記映画',
	body: '<p>ジョバンニが学校の門を出るとき、同じ組の七八人は家へ帰らずカムパネルラをまん中にして校庭の隅すみの桜さくらの木のところに集まっていました。それはこんやの星祭に青いあかりをこしらえて川へ流す烏瓜からすうりを取りに行く相談らしかったのです。</p><h2>午后ごごの授業</h2><p>ジョバンニが学校の門を出るとき、同じ組の七八人は家へ帰らずカムパネルラをまん中にして校庭の隅すみの桜さくらの木のところに集まっていました。それはこんやの星祭に青いあかりをこしらえて川へ流す烏瓜からすうりを取りに行く相談らしかったのです。</p><div class="s-img-component"><img src="/common/img/dummy-user/pjname/blog/1-m.jpg" alt="" title="" srcset="/common/img/dummy-user/pjname/blog/1-m.jpg 1x,/common/img/dummy-user/pjname/blog/1-m_2x.jpg 2x" nopin="nopin" class=""></div><h2>午后ごごの授業</h2><p>ジョバンニが学校の門を出るとき、同じ組の七八人は家へ帰らずカムパネルラをまん中にして校庭の隅すみの桜さくらの木のところに集まっていました。それはこんやの星祭に青いあかりをこしらえて川へ流す烏瓜からすうりを取りに行く相談らしかったのです。</p><h2>午后ごごの授業</h2><div class="s-img-component"><img src="/common/img/dummy-user/pjname/blog/1-m.jpg" alt="" title="" srcset="/common/img/dummy-user/pjname/blog/1-m.jpg 1x,/common/img/dummy-user/pjname/blog/1-m_2x.jpg 2x" nopin="nopin" class=""></div>',
	category: ['Category A', 'Category B', 'Category C'],
	img: ['/templates/hair/gallery/3-m.jpg']
},
{
	id: 7,
	date: '2019.04.12',
	state: '',
	title: '醜く歪んだ「銃弾の破片」に、その強大な破壊力を見た ── 写真家がとらえた12の造形',
	body: '<p>ジョバンニが学校の門を出るとき、同じ組の七八人は家へ帰らずカムパネルラをまん中にして校庭の隅すみの桜さくらの木のところに集まっていました。それはこんやの星祭に青いあかりをこしらえて川へ流す烏瓜からすうりを取りに行く相談らしかったのです。</p><h2>午后ごごの授業</h2><p>ジョバンニが学校の門を出るとき、同じ組の七八人は家へ帰らずカムパネルラをまん中にして校庭の隅すみの桜さくらの木のところに集まっていました。それはこんやの星祭に青いあかりをこしらえて川へ流す烏瓜からすうりを取りに行く相談らしかったのです。</p><div class="s-img-component"><img src="/common/img/dummy-user/pjname/blog/1-m.jpg" alt="" title="" srcset="/common/img/dummy-user/pjname/blog/1-m.jpg 1x,/common/img/dummy-user/pjname/blog/1-m_2x.jpg 2x" nopin="nopin" class=""></div><h2>午后ごごの授業</h2><p>ジョバンニが学校の門を出るとき、同じ組の七八人は家へ帰らずカムパネルラをまん中にして校庭の隅すみの桜さくらの木のところに集まっていました。それはこんやの星祭に青いあかりをこしらえて川へ流す烏瓜からすうりを取りに行く相談らしかったのです。</p><h2>午后ごごの授業</h2><div class="s-img-component"><img src="/common/img/dummy-user/pjname/blog/1-m.jpg" alt="" title="" srcset="/common/img/dummy-user/pjname/blog/1-m.jpg 1x,/common/img/dummy-user/pjname/blog/1-m_2x.jpg 2x" nopin="nopin" class=""></div>',
	category: ['Category A', 'Category B', 'Category C'],
	img: ['/templates/hair/gallery/1-m.jpg']
},
{
	id: 8,
	date: '2019.04.13',
	state: '',
	title: 'iPad上で油絵や水彩画と同じ「塗り重ね」や「色混ざり」を実現するお絵かきツール',
	body: '<p>ジョバンニが学校の門を出るとき、同じ組の七八人は家へ帰らずカムパネルラをまん中にして校庭の隅すみの桜さくらの木のところに集まっていました。それはこんやの星祭に青いあかりをこしらえて川へ流す烏瓜からすうりを取りに行く相談らしかったのです。</p><h2>午后ごごの授業</h2><p>ジョバンニが学校の門を出るとき、同じ組の七八人は家へ帰らずカムパネルラをまん中にして校庭の隅すみの桜さくらの木のところに集まっていました。それはこんやの星祭に青いあかりをこしらえて川へ流す烏瓜からすうりを取りに行く相談らしかったのです。</p><div class="s-img-component"><img src="/common/img/dummy-user/pjname/blog/1-m.jpg" alt="" title="" srcset="/common/img/dummy-user/pjname/blog/1-m.jpg 1x,/common/img/dummy-user/pjname/blog/1-m_2x.jpg 2x" nopin="nopin" class=""></div><h2>午后ごごの授業</h2><p>ジョバンニが学校の門を出るとき、同じ組の七八人は家へ帰らずカムパネルラをまん中にして校庭の隅すみの桜さくらの木のところに集まっていました。それはこんやの星祭に青いあかりをこしらえて川へ流す烏瓜からすうりを取りに行く相談らしかったのです。</p><h2>午后ごごの授業</h2><div class="s-img-component"><img src="/common/img/dummy-user/pjname/blog/1-m.jpg" alt="" title="" srcset="/common/img/dummy-user/pjname/blog/1-m.jpg 1x,/common/img/dummy-user/pjname/blog/1-m_2x.jpg 2x" nopin="nopin" class=""></div>',
	category: ['Category A', 'Category B', 'Category C'],
	img: ['/templates/hair/gallery/2-m.jpg']
},
{
	id: 9,
	date: '2019.04.14',
	state: 'draft',
	title: 'アルツハイマー病のリスクが3分の1になる薬の研究が闇に葬られた理由とは？',
	body: '<p>ジョバンニが学校の門を出るとき、同じ組の七八人は家へ帰らずカムパネルラをまん中にして校庭の隅すみの桜さくらの木のところに集まっていました。それはこんやの星祭に青いあかりをこしらえて川へ流す烏瓜からすうりを取りに行く相談らしかったのです。</p><h2>午后ごごの授業</h2><p>ジョバンニが学校の門を出るとき、同じ組の七八人は家へ帰らずカムパネルラをまん中にして校庭の隅すみの桜さくらの木のところに集まっていました。それはこんやの星祭に青いあかりをこしらえて川へ流す烏瓜からすうりを取りに行く相談らしかったのです。</p><div class="s-img-component"><img src="/common/img/dummy-user/pjname/blog/1-m.jpg" alt="" title="" srcset="/common/img/dummy-user/pjname/blog/1-m.jpg 1x,/common/img/dummy-user/pjname/blog/1-m_2x.jpg 2x" nopin="nopin" class=""></div><h2>午后ごごの授業</h2><p>ジョバンニが学校の門を出るとき、同じ組の七八人は家へ帰らずカムパネルラをまん中にして校庭の隅すみの桜さくらの木のところに集まっていました。それはこんやの星祭に青いあかりをこしらえて川へ流す烏瓜からすうりを取りに行く相談らしかったのです。</p><h2>午后ごごの授業</h2><div class="s-img-component"><img src="/common/img/dummy-user/pjname/blog/1-m.jpg" alt="" title="" srcset="/common/img/dummy-user/pjname/blog/1-m.jpg 1x,/common/img/dummy-user/pjname/blog/1-m_2x.jpg 2x" nopin="nopin" class=""></div>',
	category: ['Category A', 'Category B', 'Category C'],
	img: ['/templates/hair/gallery/3-m.jpg']
},
{
	id: 10,
	date: '2018.06.13',
	state: '',
	title: '人類初の月面到達まで1万1000時間分のアポロ11号の映像と音声をリアルタイムで配信するサイトが登場',
	body: '<p>ジョバンニが学校の門を出るとき、同じ組の七八人は家へ帰らずカムパネルラをまん中にして校庭の隅すみの桜さくらの木のところに集まっていました。それはこんやの星祭に青いあかりをこしらえて川へ流す烏瓜からすうりを取りに行く相談らしかったのです。</p><h2>午后ごごの授業</h2><p>ジョバンニが学校の門を出るとき、同じ組の七八人は家へ帰らずカムパネルラをまん中にして校庭の隅すみの桜さくらの木のところに集まっていました。それはこんやの星祭に青いあかりをこしらえて川へ流す烏瓜からすうりを取りに行く相談らしかったのです。</p><div class="s-img-component"><img src="/common/img/dummy-user/pjname/blog/1-m.jpg" alt="" title="" srcset="/common/img/dummy-user/pjname/blog/1-m.jpg 1x,/common/img/dummy-user/pjname/blog/1-m_2x.jpg 2x" nopin="nopin" class=""></div><h2>午后ごごの授業</h2><p>ジョバンニが学校の門を出るとき、同じ組の七八人は家へ帰らずカムパネルラをまん中にして校庭の隅すみの桜さくらの木のところに集まっていました。それはこんやの星祭に青いあかりをこしらえて川へ流す烏瓜からすうりを取りに行く相談らしかったのです。</p>',
	category: ['Category A', 'Category B', 'Category C'],
	img: ['/templates/hair/gallery/1-m.jpg']
},
{
	id: 11,
	date: '2019.04.15',
	state: 'hidden',
	title: 'エンブレルがアルツハイマー病のリスクを低減',
	body: '<p>ジョバンニが学校の門を出るとき、同じ組の七八人は家へ帰らずカムパネルラをまん中にして校庭の隅すみの桜さくらの木のところに集まっていました。それはこんやの星祭に青いあかりをこしらえて川へ流す烏瓜からすうりを取りに行く相談らしかったのです。</p><h2>午后ごごの授業</h2><p>ジョバンニが学校の門を出るとき、同じ組の七八人は家へ帰らずカムパネルラをまん中にして校庭の隅すみの桜さくらの木のところに集まっていました。それはこんやの星祭に青いあかりをこしらえて川へ流す烏瓜からすうりを取りに行く相談らしかったのです。</p><div class="s-img-component"><img src="/common/img/dummy-user/pjname/blog/1-m.jpg" alt="" title="" srcset="/common/img/dummy-user/pjname/blog/1-m.jpg 1x,/common/img/dummy-user/pjname/blog/1-m_2x.jpg 2x" nopin="nopin" class=""></div><h2>午后ごごの授業</h2><p>ジョバンニが学校の門を出るとき、同じ組の七八人は家へ帰らずカムパネルラをまん中にして校庭の隅すみの桜さくらの木のところに集まっていました。それはこんやの星祭に青いあかりをこしらえて川へ流す烏瓜からすうりを取りに行く相談らしかったのです。</p>',
	category: ['Category A', 'Category B', 'Category C'],
	img: ['/templates/hair/gallery/2-m.jpg']
},
{
	id: 12,
	date: '2019.04.16',
	state: '',
	title: '国家の威信をかけた月面到達プロジェクトに挑んだニール・アームストロングの伝記映画',
	body: '<p>ジョバンニが学校の門を出るとき、同じ組の七八人は家へ帰らずカムパネルラをまん中にして校庭の隅すみの桜さくらの木のところに集まっていました。それはこんやの星祭に青いあかりをこしらえて川へ流す烏瓜からすうりを取りに行く相談らしかったのです。</p><h2>午后ごごの授業</h2><p>ジョバンニが学校の門を出るとき、同じ組の七八人は家へ帰らずカムパネルラをまん中にして校庭の隅すみの桜さくらの木のところに集まっていました。それはこんやの星祭に青いあかりをこしらえて川へ流す烏瓜からすうりを取りに行く相談らしかったのです。</p><div class="s-img-component"><img src="/common/img/dummy-user/pjname/blog/1-m.jpg" alt="" title="" srcset="/common/img/dummy-user/pjname/blog/1-m.jpg 1x,/common/img/dummy-user/pjname/blog/1-m_2x.jpg 2x" nopin="nopin" class=""></div><h2>午后ごごの授業</h2><p>ジョバンニが学校の門を出るとき、同じ組の七八人は家へ帰らずカムパネルラをまん中にして校庭の隅すみの桜さくらの木のところに集まっていました。それはこんやの星祭に青いあかりをこしらえて川へ流す烏瓜からすうりを取りに行く相談らしかったのです。</p>',
	category: ['Category A', 'Category B', 'Category C'],
	img: ['/templates/hair/gallery/3-m.jpg']
},

];
