import {Component, Watch, Prop, Vue} from '@/node_modules/nuxt-property-decorator';
import {accountModule} from '@/store/account';

// Primary ////////////////////////////////////////////////////////////
import AccountChange from '@/components/account/account/AccountChange.vue';
import AccountSetting from '@/components/account/account/AccountSetting.vue';
import AccountCardChange from '@/components/account/account/AccountCardChange.vue';
import AccountCard from '@/components/account/account/AccountCard.vue';
import AccountDomainsDetail from '@/components/account/account/AccountDomainsDetail.vue';
import AccountDomains from '@/components/account/account/AccountDomains.vue';
import AccountIndex from '@/components/account/account/AccountIndex.vue';
import AccountPayHistory from '@/components/account/account/AccountPayHistory.vue';
import AccountSubscriber from '@/components/account/account/AccountSubscriber.vue';
import AccountSubscriptionsDetail from '@/components/account/account/AccountSubscriptionsDetail.vue';
import AccountSubscriptions from '@/components/account/account/AccountSubscriptions.vue';
import AccountDomainLeaveStep1 from '@/components/account/account/AccountDomainLeaveStep1.vue';
import AccountDomainLeaveOtherAccount from '@/components/account/account/AccountDomainLeaveOtherAccount.vue';
import AccountDomainLeaveOtherService from '@/components/account/account/AccountDomainLeaveOtherService.vue';

import ProcedureShareConfirm from '@/components/account/procedure/share/ProcedureShareConfirm.vue';
import ProcedureShareMailAuth from '@/components/account/procedure/share/ProcedureShareMailAuth.vue';
import ProcedureSharePayment from '@/components/account/procedure/share/ProcedureSharePayment.vue';
import ProcedureShareThanks from '@/components/account/procedure/share/ProcedureShareThanks.vue';

import ProcedureDomainConnectInput from '@/components/account/procedure/domain-connect/ProcedureDomainConnectInput.vue';

import ProcedureDomainMoveIndex from '@/components/account/procedure/domain-move/ProcedureDomainMoveIndex.vue';
import ProcedureDomainMoveInAccount from '@/components/account/procedure/domain-move/ProcedureDomainMoveInAccount.vue';
import ProcedureDomainMoveOutAccount from '@/components/account/procedure/domain-move/ProcedureDomainMoveOutAccount.vue';

import ProcedureDomainIndex from '@/components/account/procedure/domain-subscribe/ProcedureDomainIndex.vue';
import ProcedureDomainSearch from '@/components/account/procedure/domain-subscribe/ProcedureDomainSearch.vue';
import ProcedureDomainUnsubscribeConfirm from '@/components/account/procedure/domain-unsubscribe/ProcedureDomainUnsubscribeConfirm.vue';

import ProcedurePlanChangeSelectStore from '@/components/account/procedure/plan-change/ProcedurePlanChangeSelectStore.vue';
import ProcedurePlanSubscribeSelectStore from '@/components/account/procedure/plan-subscribe/ProcedurePlanSubscribeSelectStore.vue';
import ProcedurePlanUnsubscribeConfirm from '@/components/account/procedure/plan-unsubscribe/ProcedurePlanUnsubscribeConfirm.vue';
import ProcedurePlanUnsubscribeQ from '@/components/account/procedure/plan-unsubscribe/ProcedurePlanUnsubscribeQ.vue';


@Component({
    components: {

        // Primary ////////////////////////////////////////////////////////////
        AccountChange,
        AccountSetting,
        AccountCardChange,
        AccountCard,
        AccountDomainsDetail,
        AccountDomains,
        AccountIndex,
        AccountPayHistory,
        AccountSubscriber,
        AccountSubscriptionsDetail,
        AccountSubscriptions,
        AccountDomainLeaveStep1,
        AccountDomainLeaveOtherAccount,
        AccountDomainLeaveOtherService,

        ProcedureShareConfirm,
        ProcedureShareMailAuth,
        ProcedureSharePayment,
        ProcedureShareThanks,

        ProcedureDomainConnectInput,

        ProcedureDomainMoveIndex,
        ProcedureDomainMoveInAccount,
        ProcedureDomainMoveOutAccount,

        ProcedureDomainIndex,
        ProcedureDomainSearch,
        ProcedureDomainUnsubscribeConfirm,

        ProcedurePlanChangeSelectStore,
        ProcedurePlanSubscribeSelectStore,
        ProcedurePlanUnsubscribeConfirm,
        ProcedurePlanUnsubscribeQ,
    }
})
export default class MAccountFrame extends Vue {


    // Computed //////////////////////////////////////////////////////////////////////

    public get devProcedure() {
        return accountModule.dev_procedure;
    }

    public get primary():any {
        return 'index';
    }

    public get secondary():any {
        return 'm-secondary-account';
    }










}
