import {Component, Watch, Prop, Vue} from "vue-property-decorator";
import {themeMainModule} from '@/store/theme/theme-main';
import {editModule} from '@/store/edit';

import * as $ from 'jquery';

@Component({
    components: {
    }
})
export default class CInview extends Vue {


    @Prop({default: 'div'})
    public tag: string;

    /*
        じゅんばん
    */
    @Prop({default: 1})
    public num: number;

    @Prop({default: false})
    public random: boolean;

    @Prop({default: ''})
    public test: string;

    public $window: any;
    public y: number = 0;
    public view: boolean = false;


    public created() {

        // CEdit で切り替えた時は 最初から表示
        if (editModule.forceActive) {
            this.view = true;
        }
    }


    public mounted() {

        if (!this.view) {

            this.$window = $(window);

            this.$nextTick(() => {
                this.$nextTick(() => {

                    setTimeout(
                        () => {

                            let top: number = 0;

                            if ($(this.$refs.el).length) {
                                top = $(this.$refs.el).offset().top;
                            }

                            //const scrollTop = $('.c-main-frame').scrollTop();
                            this.y = top + this.scrollTop;

    //                         console.log(`
    //                             c-main-frame: ${$('.c-main-frame').offset().top}
    //                             c-nav-frame: ${$('.c-nav-frame').offset().top}
    //                             c-page-frame: ${$('.c-page-frame').offset().top}
    //                             c-page-title: ${$('.c-page-title').offset().top}
    //                             c-nav-store: ${$('.c-nav-store').offset().top}
    //                             -inview: ${$('.-inview').offset().top}
    //                         `);

    //                         console.log(`
    //                             This Y: ${pos.top}
    //                             Scroll: ${scrollTop}
    //                         `);

                            this.checkPosition(this.scrollTop);

                        }, 600);

                });
            });


        }
    }


    /*
        Inview
    */
    @Watch('scrollTop')
    public watchScrollTop(value: number) {

        /*
            自分が画面内にはいったらFADE IN
            (scrollY + window.outerHeight) > 自分Y
        */
        if (!this.view) {
            this.checkPosition(value);
        }
    }


    public get scrollTop(): number {
        return themeMainModule.scrollTop;
    }


    public checkPosition(value: number) {

//         console.log(`
//             Scl: ${value} | ${(value + $(window).outerHeight()) - 0} > Y:${this.y}
//             window height: ${$(window).outerHeight()}
//         `);

        if( (value + (this.$window.height()) - 0) > this.y ) {
            /*
                一番上にあるときは少し遅らせる
            */
            if(value < 10){
                setTimeout(
                    () => {
        				 this.view = true;
                    }, 400);
            }else{
                this.view = true;
            }
        }
    }
}



