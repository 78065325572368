import {Component, Watch, Prop, Vue} from '@/node_modules/nuxt-property-decorator';
import APopup from "@/classes/page/a-popup";

import MPopupMBody from '@/components/popup/module/MPopupMBody.vue';
import {cmdModule} from "@/store/cmd";
import {IPopup, popupModule} from "@/store/popup";
import {$v} from "@/classes/utils/var-util";

@Component({
    components: {
        MPopupMBody
    }
})
export default class MPopupConfirm extends APopup {

    @Prop({default: '@'})
    public mid: string;

    // Computed ////////////////////////////////////////////////////
    public get src(): IPopup | null {
        return popupModule.popups.findByKey('id', this.mid);
    }

    public get option(): any {
        return $v.p(this.src, 'option', {});
    }

    public get okL(): string {
        return $v.p(this.option, 'okLabel', 'Ok');
    }

    public get cancelL(): string {
        return $v.p(this.option, 'cancelLabel', 'Cancel');
    }

    // Methods /////////////////////////////////////////////////////
    ////////// POPUP LAYER 共通処理 /////////////////////////////////
    public onClose(): any {
        this.$emit('close');
    }

    // Events /////////////////////////////////////////////////////
    public async onClickCancel() {
        await cmdModule.registCmd({
            cmd: `popup.confirm.cancel`,
            request: {
                target: this.mid,
            }
        });
    }

    public async onClickOk() {
        await cmdModule.registCmd({
            cmd: `popup.confirm.ok`,
            request: {
                target: this.mid,
            }
        });
    }
}
