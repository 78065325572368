/*
	
	YH: Data Spread Sheet is Here
	https://docs.google.com/spreadsheets/d/1mE64kzSo0GRZOv-vWucB0YYxoTUKGqdVexGyXuh98JA/edit?usp=sharing

*/

export const Notice = [
	{
		id: 'copied',
		style: '',
		body:'コピーしました！'
	}, 
];