import {Component, Watch, Prop, Vue} from "vue-property-decorator";
import {themeMainModule} from '@/store/theme/theme-main';
import {themeStateModule} from '@/store/theme/theme-state';

import * as $ from 'jquery';

@Component({
    components: {
    }
})
export default class CSidecoverAddress extends Vue {


    public mounted() {
		this.$emit('mounted');
    }

	public get mainFrameResized(): any {
	    return themeMainModule.mainFrameResized;
	}

	public get oscAddrTitle(): any {
		return themeMainModule.scAddrTitle;
	}

	public get oscAddress(): any {
		return themeMainModule.scAddress;
	}

	public get oscAddrLink(): any {
		return themeMainModule.scAddrLink;
	}

	public get oscAddrFooter(): any {
		return themeMainModule.scAddrFooter;
	}

	public get oscAddrFooterTitle(): any {
		return themeMainModule.scAddrFooterTitle;
	}

	public get oscAddrFooterLink(): any {
		return themeMainModule.scAddrFooterLink;
	}

	public onClose() {
		themeStateModule.updateSidecoverDisplay(false);
	}


}



