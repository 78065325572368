import {Action, getModule, Module, Mutation, VuexModule} from "@/node_modules/vuex-module-decorators";
import store from "@/classes/core/store";
import ManagerApi from "@/classes/app/api/manager-api";
import {appAuthModule} from "@/store/app/auth";
import VarUtil from "@/classes/utils/var-util";
import Var from "@/classes/app/api/manager/var";
import {
    IManagerShopMenuCategoryModule,
    IShopMenuCategory,
    IShopMenuCategoryFilter
} from "@/store/manager/shop-menu-category";
import {$apm} from "@/classes/app/app-manager";

@Module({dynamic: true, store, name: 'managerShopMenuGroup', namespaced: true})
class Store extends VuexModule implements IManagerShopMenuCategoryModule {

    public fetched: boolean = false;
    public filter: any = {
        type: 'blog',
        word: '',
        status: null,
        date: '',
    };
    public page: number = 1;
    public info: any;
    public records: IShopMenuCategory[] = [];

    @Mutation
    public updateRecords(value: IShopMenuCategory[]) {
        this.records = value;
    }

    @Mutation
    public updateFilter(filter: IShopMenuCategoryFilter) {
        this.filter = Object.assign({}, M.filter, filter);
    }

    @Mutation
    public updateArticle(value: IShopMenuCategory) {
        this.records = Array.from(this.records).map((_: IShopMenuCategory) => {
            return (_.toid === value.toid) ?
                Object.assign({}, _, value) : _;
        });
    }

    @Mutation
    public updateFetched(fetched: boolean) {
        this.fetched = fetched;
    }

    @Action
    public async resetFilter() {
        M.updateFilter({
            status: 0,
            word: '',
            date: '',
        });
    }

    @Action
    public async fetch(param: {
        force?: boolean,
        path?: string,
        children?: boolean,
    }): Promise<any> {

        if (!!VarUtil.path(param, 'force') && M.fetched) {
            return;
        }

        try {
            const records = await ManagerApi.ShopMenuCategory.get(Object.assign({
                site: $apm.siteid,
                path: VarUtil.path(param, 'depth'),
                children: VarUtil.path(param, 'children'),
            }, M.filter)).execAsync('records.list');

            M.updateRecords(records || []);
            M.updateFetched(true);

            return null;
        } catch (e) {
            return e;
        }
    }
}

export const managerShopMenuCategoryModule = getModule(Store);

const M = managerShopMenuCategoryModule;

