import {Component, Watch, Prop, Vue} from "vue-property-decorator";
import {bodyModule} from '@/store/body';
import * as $ from 'jquery';

@Component({
    components: {
    }
})
export default class MInview extends Vue {


	// Props /////////////////////////////////////////////////////////////////

    @Prop({default: 'div'})
    public tag: string;

    /*
        じゅんばん
    */
    @Prop({default: 1})
    public num: number;

    @Prop({default: false})
    public random: boolean;

    @Prop({default: ''})
    public test: string;

    public $window: any;
    public $body: any;
    public y: number = 0;
    public view: boolean = false;



	// Watch /////////////////////////////////////////////////////////////////

	@Watch('bodyScrollEvent')
	public watchBodyScrollEvent(e: any) {

        /*
            自分が画面内にはいったらFADE IN
            (scrollY + window.outerHeight) > 自分Y
        */
        if (!this.view) {

            let current_pos = 0;

            // PC
            if (e.target.scrollTop != undefined) {
                current_pos = e.target.scrollTop;

            // Mobile Touch Device
            } else {
                current_pos = e.target.scrollingElement.scrollTop;
            }

            this.checkPosition(current_pos);
        }
	}


    public mounted() {

        if (!this.view) {

            this.$window = $(window);
            this.$body = $('body');

            this.$nextTick(() => {
                this.$nextTick(() => {

                    setTimeout( () => {

                        let top: number = 0;

                        if ($(this.$refs.el).length) {
                            top = $(this.$refs.el).offset().top;
                        }

                        this.y = top + this.$body.scrollTop();

//                         console.log(`
//                             c-main-frame: ${$('.c-main-frame').offset().top}
//                             c-nav-frame: ${$('.c-nav-frame').offset().top}
//                             c-page-frame: ${$('.c-page-frame').offset().top}
//                             c-page-title: ${$('.c-page-title').offset().top}
//                             c-nav-store: ${$('.c-nav-store').offset().top}
//                             -inview: ${$('.-inview').offset().top}
//                         `);

//                         console.log(`
//                             Scroll: ${this.y}
//                         `);
//
                        this.checkPosition(this.$body.scrollTop());

                    }, 600);

                });
            });


        }
    }


	// Computed /////////////////////////////////////////////////////////////////

	public get bodyScrollEvent(): any {
	    return bodyModule.scrollEvent;
	}


	// Methods /////////////////////////////////////////////////////////////////

    public checkPosition(value: number) {

//         console.log(`
//             Scl: ${value} | ${(value + $(window).outerHeight()) - 0} > Y:${this.y}
//             window height: ${$(window).outerHeight()}
//         `);

        if( (value + (this.$window.height()) - 100) > this.y ) {
            /*
                一番上にあるときは少し遅らせる
            */
            if(value < 10){
                setTimeout(
                    () => {
        				 this.view = true;
                    }, 400);
            }else{
                this.view = true;
            }
        }
    }



}



