import AApi from "@/classes/core/api/a-api";
import ApiExecuterAsync from "@/classes/core/api/api-executer-async";
import ApiExecuterFactory from "@/classes/core/api/api-executer-factory";
import {ApiMethod} from "@/classes/core/api/api-method";
import {ManagerApiExecuterAsync} from "@/classes/app/api/manager/manager-api-executer-async";

export abstract class AManagerApi extends AApi {

    protected createExecuterAsync(method: ApiMethod, url: string, param: any): ApiExecuterAsync {
        return new ManagerApiExecuterAsync(
            ApiExecuterFactory.createBehaviorAsync(),
            ApiExecuterFactory.createRequest({method, url, param}));
    }

    public get(param: any = {}): ApiExecuterAsync {
        return this.createExecuterAsync(ApiMethod.Get, this.url, this.filterParam(param));
    }

    public show(id: any, param: any = {}): ApiExecuterAsync {
        return this.createExecuterAsync(ApiMethod.Get, this.url + '/' + id, this.filterParam(param));
    }

    public post(param: any = {}): ApiExecuterAsync {
        return this.createExecuterAsync(ApiMethod.Post, this.url, this.filterParam(param));
    }

    public put(id: any, param: any = {}): ApiExecuterAsync {
        return this.createExecuterAsync(ApiMethod.Put, this.url + '/' + id, this.filterParam(param));
    }

    public patch(id: any, param: any = {}): ApiExecuterAsync {
        return this.createExecuterAsync(ApiMethod.Patch, this.url + '/' + id, this.filterParam(param));
    }

    public delete(id: any, param: any = {}): ApiExecuterAsync {
        return this.createExecuterAsync(ApiMethod.Delete, this.url + '/' + id, this.filterParam(param));
    }
}
