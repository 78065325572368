import {Mutation, Action, VuexModule, getModule, Module} from 'vuex-module-decorators';
import store from '@/classes/core/store';
import VarUtil, {$v} from '@/classes/utils/var-util';
import {IInputError} from '@/classes/view/input/i-input-error';
import ProdApi from "@/classes/app/api/prod-api";
import {AppConst} from "@/classes/app/app-const";
import {ILogo} from "@/classes/domain/module/model/i-logo";
import ManagerApi from "@/classes/app/api/manager-api";
import {ISiteStore} from "@/classes/domain/module/model/site/store/i-site-store";
import {$apm} from "@/classes/app/app-manager";

export interface IProdWizardInputStore {
    storeName: string;
    staff: number;
}

export interface IProdWizardInput {
    schema: string;
    transId: string;
    site: string;
    dkey: string;
    step: string;
    store: number;
    stores: IProdWizardInputStore[];
    logo: ILogo,
    mv: string;
    page: {
        gallery: boolean,
        blog: boolean,
        news: boolean,
        menu: boolean,
        contact: boolean,
    },
    mainstore: ISiteStore,
    completed: number,
}

const INPUT_SCHEMA = '200408.0';
const INPUT_DEFAULT: IProdWizardInput = {
    schema: `${INPUT_SCHEMA}`,
    transId: '',
    site: '',
    dkey: '',
    step: '',
    store: 1,
    stores: [
        {storeName: '', staff: 1},
    ],
    logo: {
        name: '',
        tagline: '',
        type: 'webfont',
        webfont: AppConst.Logo.DEFAULT_FONT_ID,
        scale: AppConst.Logo.DEFAULT_SCALE,
    },
    mv: '',
    page: {
        gallery: true,
        blog: true,
        news: true,
        menu: true,
        contact: true,
    },
    mainstore: {
        id: 1,
        isMain: true,
        address: {
            country: 'jp',
            postCode: '',
            prefecture: '23',
            langs: [
                {
                    lang: 'ja',
                    prefecture: '東京都',
                    street: '',
                    city: '',
                },
                {
                    lang: 'en',
                    prefecture: 'Tokyo',
                    street: '',
                    city: '',
                },
            ]
        },
        contacts: [
            {
                id: 1,
                type: 'tel',
                no: 1,
                body: '',
                note: '',
            },
        ],
        gmap: '',
        holidays: [],
        worktimes: [],
    },
    completed: 0,
};

// state's interface
export interface IProdWizardModule {
    input: IProdWizardInput;
    loaded: boolean;
    error: IInputError[];
}

@Module({dynamic: true, store, name: 'prodWizard', namespaced: true})
class Store extends VuexModule implements IProdWizardModule {

    private _loaded: boolean = false;
    private _input: IProdWizardInput = Object.assign({}, INPUT_DEFAULT);
    private _error: IInputError[] = [];

    // Getters ////////////////////////////////////////////////////////
    public get loaded(): boolean {
        return this._loaded;
    }

    public get input(): IProdWizardInput {
        return this._input;
    }

    public get error(): IInputError[] {
        return this._error;
    }

    // Mutations /////////////////////////////////////////////////////////
    @Mutation
    public updateLoaded(value: boolean) {
        this._loaded = value;
    }

    @Mutation
    public updateInput(value: any) {
        this._input = value;
    }

    @Mutation
    public udpateError(value: IInputError[] = []) {
        this._error = value;
    }

    @Action
    public async inputValue(param: any) {

        console.log('prodWizardModule.inputValue() > base > ', M.input);

        const upd = {
            ...M.input,
            ...{
                [param.name]: param.value,
            },
        };
        await M.updateInput(upd);
        console.log('prodWizardModule.inputValue() > upd > ', upd);
    }

    @Action
    public hasError(name: string | string[]): boolean {
        const e = Array.isArray(name) ? name : [name];
        return !!M.error.find((_: IInputError) => (e.indexOf(_.name) >= 0));
    }

    @Action
    public async resetInput() {
        M.updateLoaded(false);
        M.updateInput({...INPUT_DEFAULT});
    }

    @Action
    public async check(): Promise<boolean> {

        const tid = M.input.transId;
        console.log('M.check() > start > ', tid);

        if (!tid) {
            return false;
        }

        try {
            console.log('M.check() > ', tid);
            await ProdApi.Official.show(tid).execAsync();
            console.log(' - OK', tid);
            return true;
        } catch (e) {
            console.log(' - Error!');
            return false;
        }
    }

    @Action
    public async save(param: {
        pj: any,
        input: IProdWizardInput | null,
    }): Promise<boolean> {
        try {
            console.trace('store/prod/prodWizard.saveSite() - TRACE');
            console.log('store/prod/prodWizard.saveSite() > param(pj, input) >  ', param);
            await ManagerApi.OwnerSite.put($v.p(param, 'pj.toid'), {
                update: 'ex.start',
                content: JSON.stringify($v.p(param, 'input', M.input)),
            }).execAsync();
            return true;
        } catch (e) {
            console.error(e);
            return false;
        }
    }

    @Action
    public async load(force: boolean = false) {

        if (!force && M.loaded) {
            return;
        }

        const start = $v.p($apm.pj, 'ex.start', {});
        console.log('prod/wizard.load() > ', start);

        M.updateInput({
            ...M.input,
            ...start,
        });

        M.updateLoaded(true);
    }
}

export const prodWizardModule = getModule(Store);

const M = prodWizardModule;
