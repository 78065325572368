import {Vue, Component, Prop, Watch} from 'vue-property-decorator';
import {cmdModule, ICmd} from "@/store/cmd";

@Component
export default class CmdController extends Vue {

    @Watch('queues')
    public watchQueues(queues: ICmd[]) {
        this.$nextTick(() => {
            queues.map((_: ICmd) => {
                console.log('trashQueue > ', _.id);
                cmdModule.trashQueue(_.id);
            });
        });
    }

    public get queues(): ICmd[] {
        return cmdModule.queues;
    }
}
