import AApi from "@/classes/core/api/a-api";
import ApiExecuterAsync from "@/classes/core/api/api-executer-async";
import ApiExecuterFactory from "@/classes/core/api/api-executer-factory";
import {ApiMethod} from "@/classes/core/api/api-method";
import {ApiUtil} from "@/classes/core/api/import";

export class OwnerAuth extends AApi {

    public post(param: any): ApiExecuterAsync {
        let url = ApiUtil.url("/owner/auth");
        let request = ApiExecuterFactory.createRequest({
            method: ApiMethod.Post,
            url: url,
            param,
        });

        return ApiExecuterFactory.createAsync(request);
    }

    public show(id: string, param: any = {}): ApiExecuterAsync {
        let url = ApiUtil.url("/owner/auth/" + id);
        let request = ApiExecuterFactory.createRequest({
            method: ApiMethod.Get,
            url: url,
            param,
        });

        return ApiExecuterFactory.createAsync(request);
    }
}
