import {Mutation, MutationAction, Action, VuexModule, getModule, Module} from 'vuex-module-decorators';
import store from '@/classes/core/store';

// state's interface
export interface IThemeMainModule {

	/*
		PAGE
	*/
	page: string;
	onResize: boolean;


	/*
		PROJECT
	*/
	project: any;


	/*
		PROJECT GLOBAL
	*/
	manager: boolean;
	navbottom: string;

	//NAVIGATION
	projectNavigationPage: any;
	projectNavigationSns: any;


	/*
		PROJECT STORE
	*/
	mainStoreAddress1: string;
	mainStoreAddress2: string;
	mainStoreOpen: any;
	mainStoreClose: any;
	mainStoreMap: string;
	mainStoreTel: string;
	mainStoreReservation: any;


	/*
		PROJECT FOOTER
	*/
	footer: any;


	/*
		DESIGN
	*/
	brand: any;
	layout: any;


	/*
		LOGO
	*/
	thoughtLogo: any;
	shopname: string;
	tagline: string;
	logoActive: string;
	forceResizeLogo: boolean;
	navLogoScale: number;
	footerLogoScale: number;
	blockLogoScale: number;
	loadingLogoScale: number;


	/*
		LAUNGUAGE
	*/
	lang: string;


	/*
		PAGE
	*/
	pageType: string;
	pagePage: string;
	pageDepth: string;
	pageDesign: string;

	pageData: any;


	/*
		DEVICE
	*/
	deviceSize: string;
	deviceDevice: string;
	deviceOs: string;


	/*
		MV
	*/
	mvAddress: boolean;
	mvContent: string;
	mvTopics: boolean;
	mvImg: any;
	mvContentTxt: string;
	mvContentImg: string;
	mvContentLogoScale: number;
	mvContentTxtScale: number;
	mvContentImgScale: number;

	mvAddressTitle: string;
	mvAddressBlock1title: string;
	mvAddressBlock1body: string;
	mvAddressBlock1link: any;
	mvAddressBlock2title: string;
	mvAddressBlock2body: string;
	mvAddressBlock3title: string;
	mvAddressBlock3body: string;
	mvAddressBlock3link: any;


	/*
		SIDECOVER
	*/
	scType: string;
	scDesign: string;
	scAddrTitle: string;
	scAddrLink: string;
	scAddress: any;
	scAddrFooter: string;
	scAddrFooterTitle: string;
	scAddrFooterLink: string;


	/*
		NAVIGATION
	*/
	navContainerH: number;
	navHeaderH: number;
	navHeaderOuterH: number;
	navMbHeaderH: number;
	navSpecialNavW: number;
	navIsSns: boolean;


	/*
		HOME BLOCK
	*/
	homeBlock: any;
	homeBlockData: any;


	/*
		処理用変数
	*/
	mainFrameWidth: number;
	mainFrameHeight: number;
	mainFrameOffset: any;
	mainFrameResized: boolean;
	contentsFrameStyle: any;
	scrollTop: number;
	//scrollTopLast: number;

}

@Module({dynamic: true, store, name: 'themeMain', namespaced: true})
class ThemeMain extends VuexModule implements IThemeMainModule {


	/*
		PAGE
	*/
	public page: string = '';
	public onResize: boolean = true;


	/*
		PROJECT
	*/
	public project: any = {};


	/*
		PROJECT GLOBAL
	*/
	public manager: boolean = false;
	public navbottom: string = '';
	public projectNavigationPage: any = [];
	public projectNavigationSns: any = [];


	/*
		PROJECT MAIN STORE
	*/
	public mainStoreAddress1: string = '';
	public mainStoreAddress2: string = '';
	public mainStoreOpen: any = [];
	public mainStoreClose: any = [];
	public mainStoreMap: string = '';
	public mainStoreTel: string = '';
	public mainStoreReservation: any = [];


	/*
		FOOTER
	*/
	public footer: any = {};


	/*
		thoughtLogo data構造
		{
			id: '1',
			name:'Roboto Medium',
			class: 's-logo--robotoMedium',
			lang:'en',
			mfamily: 'Roboto:500',
			sfamily: 'Roboto:500'
		},
	*/
	public thoughtLogo: any = {}
	public shopname: string = '';
	public tagline: string = '';
	public logoActive: string = '1';
    public forceResizeLogo: boolean = false;
	public navLogoScale: number = 1;
	public footerLogoScale: number = 1;
	public blockLogoScale: number = 1;
	public loadingLogoScale: number = 1;

	public brand: any = {};

	public layout: any = {};


	/*
		LAGUAGE
	*/
	public lang: string = '';


	/*
		PAGE
	*/
	public deviceSize: string = '';
	public deviceDevice: string = '';
	public deviceOs: string = '';

	public pageType: string = '';
	public pagePage: string = '';
	public pageDepth: string = '';
	public pageDesign: string = '';
	public pageData: any = '';


	/*
		MV
	*/
	public mvAddress: boolean = false;
	public mvTopics: boolean = false;
	public mvContent: string = '';
	public mvImg: any = [];
	public mvContentTxt: string = '';
	public mvContentImg: string = '';
	public mvContentLogoScale: number = 1;
	public mvContentTxtScale: number = 1;
	public mvContentImgScale: number = 1;

	public mvAddressTitle: string = '';
	public mvAddressBlock1title: string = '';
	public mvAddressBlock1body: string = '';
	public mvAddressBlock1link: any = {};
	public mvAddressBlock2title: string = '';
	public mvAddressBlock2body: string = '';
	public mvAddressBlock3title: string = '';
	public mvAddressBlock3body: string = '';
	public mvAddressBlock3link: any = {};


	/*
		SIDECOVER
	*/
	public scType: string = '';
	public scDesign: string = '';
	public scAddrTitle: string = '';
	public scAddrLink: string = '';
	public scAddress: any = '';
	public scAddrFooter: string = '';
	public scAddrFooterTitle: string = '';
	public scAddrFooterLink: string = '';


	/*
		NAVIGATION
	*/
	public navContainerH: number = 0;
	public navHeaderH: number = 0;
	public navHeaderOuterH: number = 0;
	public navMbHeaderH: number = 0;
	public navSpecialNavW: number = 0;
	public navIsSns: boolean = true;


	/*
		HOME BLOCK
	*/
	public homeBlock: any = [];
	public homeBlockData: any = [];


	/*
		処理用変数
	*/
	public mainFrameWidth: number = 0;
	public mainFrameHeight: number = 0;
	public mainFrameOffset: any = {top: 0, left: 0};
	public mainFrameResized: boolean = false;
	public contentsFrameStyle: any = {};
	public scrollTop: number = 0;
	//public scrollTopLast: number = 0;


	/*

		MUTATIONS & ACTIONS

	*/
    /*
		MEDIA
    */
    @Mutation
    public updateManager(value: boolean) {
	    this.manager = value;
    }

    @Mutation
    public updateOnResize(value: boolean) {
	    this.onResize = value;
    }


    /*
		PAGE
    */
    @Mutation
    public updatePage(value: string) {
	    this.page = value;
    }


    /*
		GLOBAL
    */
    @Mutation
    public updateProject(value: any) {
	    this.project = value;
    }

    @Mutation
    public updateShopname(value: string) {
	    console.log(value);
	    this.shopname = value;
    }

    @Mutation
    public updateTagline(value: string) {
	    this.tagline = value;
    }

    @Mutation
    public updateLogoActive(value: string) {
        this.logoActive = value;
    }


    @Mutation
    public updateThoughtLogo(value: any) {
	    this.thoughtLogo = value;
    }

    @Mutation
    public updateBrand(value: any) {
	    this.brand = value;
    }

    @Mutation
    public updateLayout(value: any) {
	    this.layout = value;
    }

    @Mutation
    public updateNavbottom(value: string) {
	    this.navbottom = value;
    }

    @Mutation
    public updateLayoutId(value: string) {
	    this.project.global.layout = value;
    }


    /*
		NAVIGATION LINK DATA
    */
    @Mutation
	public updateProjectNavigationPage(value: any) {
        const v = value.map(_ => _);
	    this.projectNavigationPage = v;
    }

    @Mutation
	public updateProjectNavigationSns(value: any) {
        //const v = value.map(_ => _);
	    this.projectNavigationSns = value;
    }


    /*
		MAIN STORE
    */
    @Mutation
    public updateMainStoreAddress1(value: string) {
	    this.mainStoreAddress1 = value;
    }

    @Mutation
    public updateMainStoreAddress2(value: string) {
	    this.mainStoreAddress2 = value;
    }

    @Mutation
    public updateMainStoreOpen(value: any) {
	    this.mainStoreOpen = value;
    }

    @Mutation
    public updateMainStoreClose(value: any) {
	    this.mainStoreClose = value;
    }

    @Mutation
    public updateMainStoreMap(value: string) {
	    this.mainStoreMap = value;
    }

    @Mutation
    public updateMainStoreTel(value: string) {
	    this.mainStoreTel = value;
    }

    @Mutation
    public updateMainStoreReservation(value: any) {
	    this.mainStoreReservation = value;
    }


    /*
		FOOTER
    */
    @Mutation
    public updateFooter(value: any) {
	    this.footer = value;
    }


    /*
	    SIDECOVER
    */
    @Mutation
    public updateScType(value: string) {
	    this.scType = value;
    }

    @Mutation
    public updateScDesign(value: string) {
	    this.scDesign = value;
    }

    @Mutation
    public updateScAddrTitle(value: string) {
	    this.scAddrTitle = value;
    }

    @Mutation
    public updateScAddrLink(value: string) {
	    this.scAddrLink = value;
    }

    @Mutation
    public updateScAddress(value: any) {
        const v = value.map(_ => _);
	    this.scAddress = v;
    }

    @Mutation
    public updateScAddrFooter(value: string) {
	    this.scAddrFooter = value;
    }

    @Mutation
    public updateScAddrFooterTitle(value: string) {
	    this.scAddrFooterTitle = value;
    }

    @Mutation
    public updateScAddrFooterLink(value: string) {
	    this.scAddrFooterLink = value;
    }


    /*
	    LANGUAGE
    */
    @Mutation
    public updateLang(value: string) {
	    this.lang = value;
    }


    /*
	    PAGE
    */
    @Mutation
    public updatePageType(value: string) {
	    this.pageType = value;
    }

    @Mutation
    public updatePagePage(value: string) {
	    this.pagePage = value;
    }

    @Mutation
    public updatePageDepth(value: string) {
	    this.pageDepth = value;
    }

    @Mutation
    public updatePageData(value: any) {
	    this.pageData = value;
    }


    /*
	    DEVICE
    */
    @Mutation
    public updateDeviceDevice(value: string) {
	    this.deviceDevice = value;
    }

    @Mutation
    public updateDeviceSize(value: string) {
	    this.deviceSize = value;
    }

    @Mutation
    public updateDeviceOs(value: string) {
	    this.deviceOs = value;
    }


    /*
	    MV
    */
    @Mutation
    public updateMvAddress(value: boolean) {
	    this.mvAddress = value;
    }

    @Mutation
    public updateMvTopics(value: boolean = false) {
	    this.mvTopics = value;
    }

    @Mutation
    public updateMvContent(value: string) {
	    this.mvContent = value;
    }

    @Mutation
    public updateMvImg(value: string) {
	    this.mvImg = value;
    }

    @Mutation
    public updateMvContentTxt(value: string) {
	    this.mvContentTxt = value;
    }

    @Mutation
    public updateMvContentImg(value: string) {
	    this.mvContentImg = value;
    }

    @Mutation
	public updateMvAddressTitle(value: string) {
	    this.mvAddressTitle = value;
    }

    @Mutation
	public updateMvAddressBlock1title(value: string) {
	    this.mvAddressBlock1title = value;
    }

    @Mutation
	public updateMvAddressBlock1body(value: string) {
	    this.mvAddressBlock1body = value;
    }

    @Mutation
	public updateMvAddressBlock1link(value: any) {
	    this.mvAddressBlock1link = value;
    }

    @Mutation
	public updateMvAddressBlock2title(value: string) {
	    this.mvAddressBlock2title = value;
    }

    @Mutation
	public updateMvAddressBlock2body(value: string) {
	    this.mvAddressBlock2body = value;
    }

    @Mutation
	public updateMvAddressBlock3title(value: string) {
	    this.mvAddressBlock3title = value;
    }

    @Mutation
	public updateMvAddressBlock3body(value: string) {
	    this.mvAddressBlock3body = value;
    }

    @Mutation
	public updateMvAddressBlock3link(value: any) {
	    this.mvAddressBlock3link = value;
    }

    @Mutation
    public updateMvContentLogoScale(value: number) {
        this.mvContentLogoScale = value;
    }

    @Mutation
    public updateMvContentTxtScale(value: number) {
        this.mvContentTxtScale = value;
    }

    @Mutation
    public updateMvContentImgScale(value: number) {
        this.mvContentImgScale = value;
    }

    /*
	    NAVIGATION
    */
/*
    @Mutation
    public updateNavPrimary(value: any) {
        const v = value.map(_ => _);
	    this.navPrimary = v;
    }

    @Mutation
    public updateNavSecondary(value: any) {
        const v = value.map(_ => _);
	    this.navSecondary = v;
    }

    @Mutation
    public updateNavThird(value: any) {
        const v = value.map(_ => _);
	    this.navThird = v;
    }

    @Mutation
    public updateNavSpecial(value: any) {
        const v = value.map(_ => _);
	    this.navSpecial = v;
    }
*/

/*
    @Mutation
    public updateNavSns(value: any) {
        const v = value.map(_ => _);
	    this.navSns = v;
    }
*/

    @Mutation
    public updateNavContainerH(value: number) {
	    this.navContainerH = value;
    }

    @Mutation
    public updateNavHeaderH(value: number) {
	    this.navHeaderH = value;
    }

    @Mutation
    public updateNavHeaderOuterH(value: number) {
	    this.navHeaderOuterH = value;
    }

    @Mutation
    public updateNavMbHeaderH(value: number) {
	    this.navMbHeaderH = value;
    }

    @Mutation
    public updateNavSpecialNavW(value: number) {
	    this.navSpecialNavW = value;
    }

    @Mutation
    public updateNavIsSns(value: boolean) {
		this.navIsSns = value;
    }


    /*
	    HOME BLOCK
    */
    @Mutation
    public updateHomeBlock(value: any) {
        const v = value.map(_ => _);
	    this.homeBlock = v;
    }

    @Mutation
    public updateHomeBlockData(value: any) {
        const v = value.map(_ => _);
	    this.homeBlockData = v;
    }

    @Mutation
    public updateHomeBlockDataData(block: any) {

        let result: any = this.homeBlockData.filter( function( value ) {
            return value.id != block.id;
        })

        result.push(block);

        this.homeBlockData = result;
        //console.log(this.homeBlockData);
    }


/*
    @Action
    public updateHomeBlockDataValue(key: string, data: string, value: any) {

	    if(!this.homeBlockData[key]){
		    console.log(`ERROR | updateHomeBlockDataValue(${key}) Not Found`);
		    return
	    }
	    if(!this.homeBlockData[key][data]){
		    console.log(`ERROR | updateHomeBlockDataValue(${key}, ${data}) Not Found`);
		    return
	    }

	    const d: any = {
			target: this.homeBlockData[key][data],
			value : value
	    };

	    this.saveHomeBlockDataValue(d);
    }

    @Mutation
    public saveHomeBlockDataValue(value: any) {
        const v = value.map(_ => _);
	    v.target = v.value;
    }
*/


    /*
	    処理用変数
    */
    @Mutation
    public updateMainFrameWidth(value: number) {
	    this.mainFrameWidth = value;
    }

    @Mutation
    public updateMainFrameHeight(value: number) {
	    this.mainFrameHeight = value;
    }

    @Mutation
    public updateMainFrameOffset(value: any) {
	    this.mainFrameOffset = value;
    }

    @Mutation
    public updateMainFrameResized() {
        if (this.mainFrameResized) {
            this.mainFrameResized = false;
        } else {
            this.mainFrameResized = true;
        }
    }

    @Mutation
    public updateContentsFrameStyle(value: any) {
	    this.contentsFrameStyle = value;
    }

    @Mutation
    public updateScrollTop(value: number) {
	    this.scrollTop = value;
    }

//     @Mutation
//     public updateScrollTopLast(value: number) {
// 	    this.scrollTopLast = value;
//     }


    @Mutation
    public updateForceResizeLogo() {
        if (this.forceResizeLogo) {
            this.forceResizeLogo = false;
        } else {
            this.forceResizeLogo = true;
        }
    }


    // Logo Scale /////////////////////////////////////////////

    @Mutation
    public updateNavLogoScale(value: number) {
        this.navLogoScale = value;
    }

    @Mutation
    public updateFooterLogoScale(value: number) {
        this.footerLogoScale = value;
    }

    @Mutation
    public updateBlockLogoScale(value: number) {
        this.blockLogoScale = value;
    }

    @Mutation
    public updateLoadingLogoScale(value: number) {
        this.loadingLogoScale = value;
    }

}

export const themeMainModule = getModule(ThemeMain);

