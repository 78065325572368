








	// @ts-ignore
    import _ from './CImgHover.ts'; export default _;
