import {VuexModule, getModule, Module, Mutation} from 'vuex-module-decorators';
import store from '@/classes/core/store';

// state's interface
export interface IAppStore {
    ready: boolean;
}

@Module({dynamic: true, store, name: 'layoutDefault', namespaced: true})
class Store extends VuexModule implements IAppStore {

    private _ready: boolean = false;

    // Getters //////////////////////////////
    public get ready(): boolean {
        return this._ready;
    }

    // Mutations ////////////////////////////
    @Mutation
    public updateReady(value: boolean) {
        this._ready = value;
    }

    // Actions ///////////////////////////////

}

export const layoutDefaultModule = getModule(Store);

const M = layoutDefaultModule;
