import {Component, Watch, Prop, Vue} from '@/node_modules/nuxt-property-decorator';
import APopup from "@/classes/page/a-popup";	
import {tabModule} from '@/store/tab';

import MTab from '@/components/module/MTab.vue';
import MTabContents from '@/components/module/MTabContents.vue';
import MTabMove from '@/components/module/MTabMove.vue';

@Component({
    components: {
	    MTab,
	    MTabContents,		    
	    MTabMove
    }
})
export default class MPopupImgTrimming extends APopup {
    
    public tabmap: any = [
	    {
		    id: 1,
		    tabid: 1,
		    label: 'パソコン・タブレット'
	    },
	    {
		    id: 2,
		    tabid: 2,
		    label: 'スマートフォン'
	    }
    ];
    
    ////////// POPUP LAYER 共通処理 /////////////////////////////////
	public onClose(to: string=''): any {
		if(to != ''){
			this.$router.push(to);
		}
        this.$emit('close');
	}
}
