import {ISiteStoreWorktime} from "@/classes/domain/module/model/site/store/i-site-store";
import {Lang} from "@/classes/app/app-const";
import {$v} from "@/classes/utils/var-util";
import {WorktimeUtil} from "@/classes/domain/module/model/site/store/worktime-util";
import {TimeUtil} from "@/classes/utils/time-util";
import {SystemConfig} from "@/configs/system";
import {IWorktime} from "@/classes/domain/module/manager/ui/store-worktime/i-worktime";
import {sprintf} from "sprintf-js";
import {LangLabelUtil} from "@/classes/domain/lang/lang-label-util";
import {ArrayUtil} from "@/classes/utils/array-util";

export interface IWorktimeFormatterOption {
    lang?: Lang;
    lineSeparator?: string;
    weekSeparator?: string;
}

export class WorktimeDefaultFormatter {

    public readonly worktimes: ISiteStoreWorktime[];
    public readonly option: IWorktimeFormatterOption;

    constructor(worktimes: ISiteStoreWorktime[], option: IWorktimeFormatterOption = {}) {
        this.worktimes = worktimes;
        this.option = {
            ...{
                lang: Lang.Ja,
                lineSeparator: '\n',
                weekSeparator: ','
            },
            ...option,
        }
    }

    // Getters /////////////////////////////////////////////////////////
    public get optLang(): Lang {
        return this.opt('lang', Lang.Ja);
    }

    public get optLineSeparator(): Lang {
        return this.opt('lineSeparator', ',');
    }

    public get optWeekSeparator(): Lang {
        return this.opt('weekSeparator', ',');
    }

    public get ws(): string[] {
        return WorktimeUtil.ws;
    }

    public get first(): ISiteStoreWorktime {
        return ArrayUtil.first(this.worktimes);
    }

    public get isOnce(): boolean {

        let once = true;
        const base = this.time2key(this.ws[0]);

        this.ws.some((_: string) => {
            if (once) {
                once = (base === this.time2key(_));
            }

            return !once;
        });

        return once;
    }

    // Methods /////////////////////////////////////////////////////////
    public opt(name: string, def: any = null): any {
        return $v.p(this.option, name, def);
    }

    public time2key(w: string): string {

        // console.log('WorktimeDefaultFormatter.time2key() > ', this.worktimes);
        const wt = ArrayUtil.findByKey(this.worktimes, 'w', w);
        return !!wt ? `${wt.st}-${wt.end}` : '';
    }

    public format(): string {
        switch (this.optLang) {
            case Lang.Ja:
                return this.formatJa();
            default:
                return this.formatEn();
        }
    }

    public formats(): string[] {
        switch (this.optLang) {
            case Lang.Ja:
                return this.formatsJa();
            default:
                return this.formatsEn();
        }
    }

    public formatLine(w: string): string {
        const wt = ArrayUtil.findByKey(this.worktimes, 'w', w);

        if (!wt) {
            return '';
        }

        const st = TimeUtil.m2hmL(wt.st);
        const end = TimeUtil.m2hmL(wt.end);
        return !!wt ? `${st}-${end}` : '';
    }

    public formatOnce(): string {
        return this.formatLine($v.p(this.first, 'w'));
    }

    public formatJa(): string {
        return this.formatsJa().join(this.optLineSeparator);
    }

    public formatsJa(): string[] {
        if (this.isOnce) {
            return [this.formatOnce()];
        } else {
            return this.worktimesL();
        }
    }

    public formatEn(): string {
        return this.formatsEn().join(this.optLineSeparator);
    }

    public formatsEn(): string[] {
        if (this.isOnce) {
            return [this.formatOnce()];
        } else {
            return this.worktimesL();
        }
    }

    ////////////////////////////////////////////////////////////////
    public worktimesL(): string[] {
        let r = [
            // {w: [], l: '00:00-00:00'}
        ];
        const sep = SystemConfig.weekSeparator;
        this.worktimes.map((_: IWorktime) => {

            const tL = sprintf('%s%s%s',
                TimeUtil.m2hmL(_.st),
                sep,
                TimeUtil.m2hmL(_.end)
            );

            const current = r.findByKey('l', tL);
            if (!!current) {
                r = r.map((__: any) => {
                    if (__.l !== tL) {
                        return __;
                    } else {
                        return {
                            w: __.w.from(_.w),
                            l: __.l,
                        };
                    }
                });
            } else {
                r.push({
                    w: [_.w],
                    l: tL,
                });
            }
        });

        return r.map((_: any, idx: number) => {
            return ((idx > 0) ? this.weekL(_.w) : '') + _.l;
        });
    }

    public weekL(wks: string[], weeks: any = null): string {
        weeks = Array.isArray(weeks) ? weeks : SystemConfig.weeks;

        // console.log('wks > ', wks);
        if (wks.length === 8) {
            return '';
        }

        let wt = [];
        let last = -1;
        let grp = [];
        const sep = SystemConfig.weekSeparator;

        weeks.map((w: any, idx: number) => {

            if (wks.indexOf(w.key) < 0) {
                if (grp.length > 0 && grp.length < 3) {
                    wt.push(grp.join(','));
                } else if (grp.length > 2) {
                    wt.push(grp.first() + sep + grp.last());
                }
                grp = [];
                return;
            }

            const next = (weeks.length > idx) ? weeks[idx + 1] : null;
            const nk = $v.p(next, 'key');
            const ll = this.wL(w);
            if (nk === 'w7' || wks.indexOf(nk) < 0) {
                grp.push(ll);
                if (grp.length > 0 && grp.length < 3) {
                    wt.push(grp.join(','));
                } else if (grp.length > 2) {
                    wt.push(grp.first() + sep + grp.last());
                } else {
                    wt.push(ll);
                }
                grp = [];
            } else {
                grp.push(ll);
            }

            last = idx;
        });

        // console.log('weekL > ', wks, wt.join(''));

        return wt.join() + ' ';
    }

    private wL(w: any): string {
        return (this.optLang === Lang.Ja) ? w.labelS :
            LangLabelUtil._(w.key, 'week', this.optLang);
    }
}
