export const UploadImgConst = {
    DataType: {
        DATA_TYPE_IMG: 'img',
        DATA_TYPE_LOGO: 'logo',
    },
    Type: {
        TYPE_IMG: 'img',
        TYPE_STAFF: 'staff',
        TYPE_LOGO: 'logo',
        TYPE_LOGO_WIZARD: 'logo-wizard',
        TYPE_LOGO_WHITE: 'logo-white',
        TYPE_LOGO_BLACK: 'logo-black',
    },
};

export interface IImgCrop {
    //オリジナルURL
    origin: string;
    x: number;
    y: number;
    width: number;
    height: number;
}

export interface IImg {
    src: string;
    crop?: IImgCrop;
}
