import {Component, Prop, Vue} from "vue-property-decorator";
import {themeMainModule} from '@/store/theme/theme-main';

import CLink from '@/components/theme/module/CLink.vue';

@Component({
    components: {
	    CLink,
    }
})
export default class CMvAddress extends Vue {

    /*
		MV ADDRESS
    */
    public get title(): any {
	    return themeMainModule.mvAddressTitle;
    }
    
    public get block1Title(): any {
	    return themeMainModule.mvAddressBlock1title;
    }
    
    public get block1Body(): any {
	    return themeMainModule.mvAddressBlock1body;
    }
    
    public get block1LinkName(): any {
	    return themeMainModule.mvAddressBlock1link.name;
    }
    
    public get block1LinkLink(): any {
	    return themeMainModule.mvAddressBlock1link.link;
    }
    
    public get block2Title(): any {
	    return themeMainModule.mvAddressBlock2title;
    }
    
    public get block2Body(): any {
	    return themeMainModule.mvAddressBlock2body;
    }
    
    public get block3Title(): any {
	    return themeMainModule.mvAddressBlock3title;
    }
    
    public get block3Body(): any {
	    return themeMainModule.mvAddressBlock3body;
    }
    
    public get block3LinkName(): any {
	    return themeMainModule.mvAddressBlock3link.name;
    }
    
    public get block3LinkLink(): any {
	    return themeMainModule.mvAddressBlock3link.link;
    }    
    
    //リンク先がGoogle Mapだと　true
    public get isLinkMap(): any {
		if(
			/^https:\/\/google/.test(themeMainModule.mvAddressBlock1link.link) || 
			/^https:\/\/www\.google/.test(themeMainModule.mvAddressBlock1link.link)
		) {
			return true;
		}
		return false;
    }
    
    
}

