<template>

	<div class="c-page-frame" data-page-id="page-gallery-index"
    	:class="[
        	{'-no-category': categories.length < 2},
        ]"
    >
		<main class="c-contents-frame" :style="styleContentsFrame">

			<c-page-title :title="title" />

			<!-- CATEGORY FILTER FOR MOBILE -->
			<c-article-filter
				:device="deviceSize"
				:d="categories"
				:active="activeId"
    			class="c-page-nav"
                link="/gallery/category"
			/>

            <div class="c-block-wrapper">
    			<section class="c-block -page -list">

                    <c-inview tag="ul" class="c-list -gallery">
                        <li
                            v-for="(item, index) in list" :key="`c-list_${index}`"
                            class="c-list-item"
                            :ref="`li${index}`"
                        >
                            <c-inview-item :num="index">

                                <div class="c-list-item-img">
                                    <c-link to="/gallery" :rkey="item.id"  class="c-img">
                                        <c-img-ratio :src="item.img[0]" :aspect="aspect" />
                                    </c-link>
                                </div>

                            </c-inview-item>
                        </li>
                    </c-inview>

    			</section>
            </div>

			<c-info-navigation />

		</main>
	</div>

</template>
<script>
  // @ts-ignore
  import _ from './CPageGalleryIndexStandard.ts';

  export default _;
</script>
