import {Component, Prop, Vue} from "vue-property-decorator";

@Component({
    components: {
    }
})
export default class CPrice extends Vue {


    @Prop({default: 0})
	public value: number;

    @Prop({default: false})
	public over: boolean;

    //通貨
    @Prop({default: '¥'})
    public currency: string;


    public get ovalue(): any {
	    const v = this.toLocale(this.value);

	    if(this.over){
            return v + ' ~';
	    }
        return v;
    }

    //カンマ区切り高速版
    public toLocale(value: any): any {
        var arr;
        arr = String(value).split('.');
        arr[0] = arr[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
        return arr.join('.');
	}

}



