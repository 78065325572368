















	// @ts-ignore
    import _ from './MListNews.ts'; export default _;
