


















	// @ts-ignore
    import _ from './MSelectColorSidecoverTxt.ts'; export default _;
