import {AExtAuth} from "@/classes/ext/a-ext-auth";
import {managerProjectModule} from "@/store/manager/project";

export class ExtAuthManager extends AExtAuth {

    public async init() {
        await this.fetchPj();
    }

    public async fetchPj() {
        await managerProjectModule.fetch();
    }
}
