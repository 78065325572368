import {Vue, Component, Prop, Watch} from 'vue-property-decorator';
import {AArticleDetail} from "@/components/popup/articleDetail/AArticleDetail";
import MUploadimg from "@/components/module/MUploadimg";
import MTextarea from "@/components/module/MTextarea";
import {ExtEdit} from "@/classes/ext/ext-edit";
import {BlogUtil} from "@/classes/domain/module/model/blog/blog-util";

@Component({
    components: {
        MUploadimg,
        MTextarea,
    }
})
export default class Blog extends AArticleDetail {
    public get extEdit(): ExtEdit {
        return new ExtEdit(this);
    }

    public async onInput(e: any) {
        await super.onInput(e);
    }
}
