import {Component, Watch, Prop, Vue} from '@/node_modules/nuxt-property-decorator';
import APopup from "@/classes/page/a-popup";

import MPopupMBody from '@/components/popup/module/MPopupMBody.vue';
import ViewModelUtil from "@/classes/view/view-model-util";
import {appEditModule, IEdit} from "@/store/app/edit";
import {ExtEdit} from "@/classes/ext/ext-edit";
import {managerShopMenuCategoryModule as M} from "@/store/manager/shop-menu-category";
import {$v} from "@/classes/utils/var-util";
import {$apm} from "@/classes/app/app-manager";

@Component({
    components: {
        MPopupMBody
    }
})
export default class MPopupMenuGenre extends APopup {

    public state: any = ViewModelUtil.createPageState({
        config: {
            editId: 'menuGenre',
        },
    });

    public created() {
        super.created();
    }

    public async mounted() {
        await this.initEdit();
    }

    public async initEdit() {
        await appEditModule.start({
            id: this.editId,
            input: {
                title: 'xxxx',
                is_active: 1,
            },
        });
    }

    ////////// POPUP LAYER 共通処理 /////////////////////////////////
    public onClose(): any {
        this.$emit('close');
    }

    // Computed //////////////////////////////////////////////////////
    // component config
    public get cfg(): string {
        return $v.path(this.state, 'config');
    }

    public get editId(): string {
        return $v.path(this.cfg, 'editId');
    }

    public get extEdit(): ExtEdit {
        return new ExtEdit(this);
    }

    public get edit(): IEdit {
        return this.extEdit.edit;
    }

    public get input(): any {
        return this.extEdit.edit.input;
    }

    public get filterInput(): any {
        return {
            ...this.input,
            ...{
                site: $apm.pj.toid,
            }
        };
    }

    // Methods ///////////////////////////////////////////////////////
    public e(elm: string): string[] {
        return [];
    }

    // Events ////////////////////////////////////////////////////////
    public async onInput(e: any) {
        await this.extEdit.onInput(e);
    }

    public async onSubmit() {

        // console.log('onSubmit', this.filterInput, $apm.pj);
        await this.extLoading.load(async () => {
            await M.save(this.filterInput);
            await M.fetch();
        });

        this.$emit('close');
    }
}
