import {Mutation, MutationAction, Action, VuexModule, getModule, Module} from 'vuex-module-decorators';
import store from '@/classes/core/store';
import ManagerApi from "@/classes/app/api/manager-api";
import VarUtil from "@/classes/utils/var-util";

export const MaterialDriver = {
    UNSPLASH: 'unsplash',
};

export interface IMaterialListInfo {
    count: number;
}

export interface IMaterial {
    ex?: {
        size?: {
            width: number,
            height: number,
        }
        urls?: {
            full: string;
            raw: string;
            regular: string;
            small: string;
            thumb: string;
        },
        user?: {
            id: string;
            name: string;
        },

    },
    src?: string;
    srcId: string;
    type: string;
}

export interface IMaterialFilter {
    word: string;
}

export interface IMaterialModule {
    driver: string;
    filter: IMaterialFilter;
    listInfo: IMaterialListInfo;
    readCount: number;
    records: IMaterial[];
}

@Module({dynamic: true, store, name: 'appUploadimgMaterial', namespaced: true})
class Store extends VuexModule implements IMaterialModule {
    driver: string = MaterialDriver.UNSPLASH;
    filter: IMaterialFilter = {
        word: '',
    };
    readCount: number = 0;
    listInfo: IMaterialListInfo = {
        count: 0,
    };
    records: any[] = [];

    // Mutations ////////////////////////////////////////////////////////////////
    @Mutation
    public updateReadCount(value: number) {
        this.readCount = value;
    }

    @Mutation
    public updateFilter(value: IMaterialFilter) {
        this.filter = value;
    }

    @Mutation
    public updateListInfo(value: IMaterialListInfo) {
        this.listInfo = value;
    }

    @Mutation
    public updateRecords(value: IMaterial[]) {
        this.records = value;
    }

    // Actions ////////////////////////////////////////////////////////////////
    @Action
    public async fetch() {

        console.log('appUploadimgMaterial.fetch()');
        try {
            const res = await ManagerApi.Materialindex.index(myModule.driver).execAsync();
            console.log(' - Res > ', res);

            if (VarUtil.has(res, 'records.page')) {
                await myModule.updateListInfo(VarUtil.path(res, 'records.page'));
                await myModule.updateRecords(VarUtil.path(res, 'records.list'));
            }
            await myModule.incReadCount();

        } catch (e) {
            console.log(' - Error', e);
        }
    }

    @Action
    public async incReadCount() {
        myModule.updateReadCount(myModule.readCount + 1);
    }
}

export const appUploadimgMaterial = getModule(Store);
const myModule = appUploadimgMaterial;
