import {Component, Prop, Vue} from '@/node_modules/nuxt-property-decorator';
import {StoreAppEditUtil} from "@/classes/view/store/app/store-app-edit-util";

@Component({
    components: {
    }
})
export default class MTextarea extends Vue {

    //Auto Height
    @Prop({default: '@'})
    public name: string;

    //Auto Height
    @Prop({default: true})
    public autoheight: boolean;

    //初期行数
    @Prop({default: 3})
    public row: number | string;

    //文字サイズ
    @Prop({default: 'book'})
    public size: string;

    //文字サイズ
    @Prop({default: 'Input Here...'})
    public placeholder: string;

    //最大行数(Style)
    @Prop({default: 24})
    public maxh: number;

    //Value
    @Prop({default: ''})
    public value: string;

	//行数
	public r: number = Number(this.row);

	//Global定数 1行あたりの高さ
	public gridH: number = 36;

	public get osize(): any {
		return 'size-' + this.size;
	}

	public get orowclass(): any {
		return '-row-' + this.r;
	}

	public get oplaceholder(): any {
		return this.placeholder;
	}

	public get styles(): any {
		return { 'min-height' : `${this.r * this.gridH}px` };
	}


    //Auto Height
    public get orow(): any {

	    if( this.autoheight && this.r <= this.maxh ){
			this.r = this.value.split("\n").length;
	        return (this.r > this.row) ? this.r : this.row;
	    }
		return this.row;
    }

    // Events //////////////////////////////////////////////////////
    public onInput(e: any) {
	    this.$emit('input', e);
	    this.$nextTick(() => {
	        this.$forceUpdate();
        });
    }

    ////////// POPUP LAYER 共通処理 /////////////////////////////////
	public onClose(to: string=''): any {
		if(to != ''){
			this.$router.push(to);
		}
        this.$emit('close');
	}


}
