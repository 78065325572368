import {IApiBehavior} from './i-api-behavior';
import {IApiRequest} from './i-api-request';
import {ApiMethod} from './api-method';
import axios, {AxiosInstance} from 'axios';
import ApiUtil from './api-util';
import {sprintf} from 'sprintf-js';

export default class AxiosBehaviorAsync implements IApiBehavior {

    constructor() {
    }

    public axios(req: IApiRequest): AxiosInstance {
        return axios.create({
            withCredentials: true,
            headers: Object.assign({}, {
                'X-Requested-With': 'XMLHttpRequest',
            }, req.header || {}),
        });
    }

    public access(req: IApiRequest): Promise<any> {
        console.log('AxiosBehaviorAsync.access()', req);
        switch (req.method) {
            case ApiMethod.Get:
                return this.getAccess(req);
            case ApiMethod.Post:
                return this.postAccess(req);
            case ApiMethod.Put:
                return this.putAccess(req);
            case ApiMethod.Patch:
                return this.patchAccess(req);
            case ApiMethod.Delete:
                return this.deleteAccess(req);
            default:
                throw new Error(sprintf('Method spec error!: %s', req.method));
        }
    }

    public getAccess(req: IApiRequest, method: string = 'get'): Promise<any> {

        return new Promise((resolve, reject) => {
            this.axios(req)[method](req.url, {
                params: req.param
            })
                .then(async (res: any) => {
                    await req.cbThen(res);
                    await req.cbAll(res);
                    resolve(res);
                })
                .catch(async (res: any) => {
                    console.log('getAccess() - Err', req, res!.response.status);
                    await req.cbCatch(res);
                    await req.cbAll(res);
                    resolve(reject);
                });
        }) as any;
    }

    public postAccess(req: IApiRequest, method: string = 'post'): Promise<any> {
        return new Promise((resolve, reject) => {
            let params = ApiUtil.request2Param(req);
            this.axios(req)[method](req.url, params, req.config)
                .then(async (res: any) => {
                    await req.cbThen(res);
                    await req.cbAll(res);
                    resolve(res);
                })
                .catch(async (res: any) => {
                    await req.cbCatch(res);
                    await req.cbAll(res);
                    reject(res);
                });
        }) as any;
    }

    public putAccess(req: IApiRequest): Promise<any> {
        return this.postAccess(req, 'put');
    }

    public patchAccess(req: IApiRequest): Promise<any> {
        return this.postAccess(req, 'patch');
    }

    public deleteAccess(req: IApiRequest): Promise<any> {
        return this.getAccess(req, 'delete');
    }
}
