import {Component, Watch, Prop, Vue} from "vue-property-decorator";
import {themeMainModule} from '@/store/theme/theme-main';

@Component({
    components: {
    }
})
export default class CSwiperImg extends Vue {


	public static self: CSwiperImg;

	//DATA
	@Prop({default: () => {} })
	public d: any;

	//ANIMATION
	@Prop({default: 'fade'})
	public motion: string;

	//AUTO PLAY
	@Prop({default: 3000})
	public autoplay: number;

	/*
    	BUTTONS
    	none / thumb
	*/
	@Prop({default: 'none'})
	public ui: string;

	/*
    	全画面など、可変サイズの場合は、TRUEにする
	*/
	@Prop({default: false})
	public resize: boolean;


    @Watch('nresize')
    public watchResize() {
		this!['slide'].update();
    }

    public loading: boolean = true;


    public get nresize() {

        if(this.resize){
            return themeMainModule.mainFrameResized;
        }
	    return;
    }


    public nowNumber: number = 0;


    public mounted() {

		CSwiperImg.self = this;
		setTimeout(
		    () => {
				this.init();
                this.loading = false;
		    }, 300);
    }


    public init() {
		this!['slide'].init();
		this.nowNumber = this!['slide']['realIndex'] + 1;
    }

    public setAutoplay(): any {
	    if(this.autoplay){
			return {
				delay: this.autoplay,
			}
	    }
	    return false;
    }


    //Swiper
    public swiperOption: any = {

	    init: false,
        loop: true,
        lazy: true,
        autoplay: this.setAutoplay(),
        slidesPerView: 'auto',
        disableOnInteraction: false,
		parallax: true,
		grabCursor: true,
        effect: this.motion,
        speed: 540,
        centeredSlides: true,
        spaceBetween: 0,
        on: {
	        init: function () {

	            if( !CSwiperImg.self ){
		            return;
	            }

	        },
            slideChange: function () {

	            if( !CSwiperImg.self ){
		            return;
	            }
	            CSwiperImg.self.slideChanged();

            },
            tap() {
            }
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        }
    };


    public slideChanged() {
		this.nowNumber = this!['slide']['realIndex'] + 1;
    }


    public get totalNumber(): any {
        return this.d.length;
    }

}

