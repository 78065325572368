/*

	YH: Data Spread Sheet is Here
	https://docs.google.com/spreadsheets/d/1W7nxH1TIuGknQPubutgd6Z6914SoEkG_7Pem9kxGSL0/edit?usp=sharing

*/

export const Popup = [


{
	id: 'logo-list',
	component:'m-popup-logo-list',
	bg: 'bk',
	size:'6w',
	title: '',
	titleicon: '',
	body: '',
	img: '',
	closelink: '',
	transition: ''
},
{
	id: 'wiz-break',
	component:'m-popup-mes',
	bg: 'bk',
	size:'4w',
	title: 'おつかれさまでした！',
	titleicon: '',
	body: '次はじめるときはこのページから再開できます。ページを閉じる前にブックマークすることをおすすめします。',
	img: '',
	closelink: '',
	transition: ''
},
{
	id: 'account-card-change',
	component:'m-popup-mes',
	bg: '',
	size:'txtw',
	title: '',
	titleicon: '',
	body: 'カード情報を変更しました。',
	img: '',
	closelink: '/manager/account/card',
	transition: ''
},
{
	id: 'account-pay-detail',
	component:'m-popup-account-pay-detail',
	bg: '',
	size:'6w',
	title: 'Project Nameはいる',
	titleicon: '',
	body: '',
	img: '',
	closelink: '',
	transition: ''
},
{
	id: 'account-unsubscribe',
	component:'m-popup-account-unsubscribe',
	bg: 'bk',
	size:'txtw',
	title: '',
	titleicon: '',
	body: '本プランを解約します。<br>本当によろしいですか？<br>この操作は取消ができません。',
	img: '',
	closelink: '',
	transition: ''
},
{
	id: 'mail-auth',
	component:'m-popup-mail-auth',
	bg: 'bk',
	size:'5w',
	title: '',
	titleicon: '',
	body: '',
	img: '',
	closelink: '',
	transition: ''
},
{
	id: 'login',
	component:'m-popup-login',
	bg: '',
	size:'5w',
	title: '',
	titleicon: '',
	body: '',
	img: '',
	closelink: '',
	transition: ''
},
{
	id: 'domain-connect-check-success',
	component:'m-popup-domain-connect-check-success',
	bg: '',
	size:'txtw',
	title: '',
	titleicon: '',
	body: '接続に成功しました。<br>接続プランのご契約へ<br>お進みください。',
	img: '',
	closelink: '',
	transition: ''
},
{
	id: 'domain-connect-check-fail',
	component:'m-popup-domain-connect-check-fail',
	bg: '',
	size:'4w',
	title: '接続失敗',
	titleicon: '',
	body: '接続に失敗しました。ご契約しているサービスのドメイン設定をもう一度ご確認ください。',
	img: '',
	closelink: '',
	transition: ''
},
{
	id: 'domain-moved',
	component:'m-popup-mes',
	bg: '',
	size:'txtw',
	title: '',
	titleicon: '',
	body: 'ドメイン移転手続き完了！<br>反映されるまで<br>少々お待ちください。',
	img: '',
	closelink: '/manager/account/domains',
	transition: ''
},
{
	id: 'account-mail-change-send',
	component:'m-popup-mes',
	bg: '',
	size:'txtw',
	title: '',
	titleicon: '',
	body: 'メールアドレスにメールを送信しました！<br>2時間以内に認証ページにアクセスし<br>変更を完了してください。',
	img: '',
	closelink: '/manager/account/account',
	transition: ''
},
{
	id: 'layout-preview',
	component:'m-popup-layout-preview',
	bg: 'bk',
	size:'full',
	title: '',
	titleicon: '',
	body: '',
	img: '',
	closelink: '',
	transition: ''
},
{
	id: 'upload-img',
	component:'m-popup-upload-img',
	bg: 'wh',
	size:'full',
	title: '',
	titleicon: '',
	body: '',
	img: '',
	closelink: '',
	transition: 'upload-menu'
},
{
	id: 'upload-logo',
	component:'m-popup-upload-logo',
	bg: 'wh',
	size:'full',
	title: '',
	titleicon: '',
	body: '',
	img: '',
	closelink: '',
	transition: 'upload-menu'
},
{
	id: 'upload-post',
	component:'m-popup-upload-post',
	bg: 'wh',
	size:'full',
	title: '',
	titleicon: '',
	body: '',
	img: '',
	closelink: '',
	transition: 'upload-menu'
},
{
	id: 'upload-img-qr',
	component:'m-popup-upload-img-qr',
	bg: '',
	size:'4w',
	title: 'アップロード用QRコード',
	titleicon: '',
	body: 'QRコードをスマホで読み取って、画像のアップロード用ページにアクセスしてください。アップロードが終わるまでこのQRコードを閉じないでください。',
	img: '/test/qr.png',
	closelink: '',
	transition: ''
},
{
	id: 'upload-img-movie',
	component:'m-popup-upload-img-movie',
	bg: '',
	size:'6w',
	title: '動画の埋め込みURL',
	titleicon: '',
	body: '1.掲載したいYOUTUBEの動画ページを開きます。<br>2.ブラウザ上部に表示されているURLをコピーします。<br>3.下記のフォームにURLを貼り付けてください。',
	img: '',
	closelink: '',
	transition: ''
},
{
	id: 'notice-logo-data',
	component:'m-popup-notice-logo-data',
	bg: '',
	size:'5w',
	title: 'ロゴ用のデータをお持ちですか?',
	titleicon: '',
	body: '背景が透明のSVG形式、または透過PNG(24bit)形式のデータをご用意していただく必要がございます。 ロゴデータをお持ちでない方は、かんたんにご用意できる Thought Logo（無料）をご利用ください。',
	img: '/manager/uploadimg/img-svgpng.jpg',
	closelink: '',
	transition: ''
},
{
	id: 'img-trimming',
	component:'m-popup-img-trimming',
	bg: '',
	size:'12w',
	title: '',
	titleicon: '',
	body: '',
	img: '',
	closelink: '',
	transition: ''
},
{
	id: 'post-copy',
	component:'m-popup-post-copy',
	bg: 'wh',
	size:'7w',
	title: '複製する記事',
	titleicon: '',
	body: '',
	img: '',
	closelink: '',
	transition: ''
},
{
	id: 'confirm-delete',
	component:'m-popup-confirm-delete',
	bg: '',
	size:'txtw',
	title: '',
	titleicon: '',
	body: '記事を削除します。<br>よろしいですか?<br>復元はできません。',
	img: '',
	closelink: '',
	transition: ''
},
{
    id: 'confirm',
    component:'m-popup-confirm',
    bg: '',
    size:'txtw',
    title: '',
    titleicon: '',
    body: '',
    img: '',
    closelink: '',
    transition: ''
},
{
	id: 'store-hours',
	component:'m-popup-store-hours',
	bg: 'bk',
	size:'',
	title: '営業時間',
	titleicon: '',
	body: '',
	img: '',
	closelink: '',
	transition: ''
},
{
	id: 'editor',
	component:'m-popup-editor',
	bg: 'bk',
	size:'9w',
	title: '',
	titleicon: '',
	body: '',
	img: '',
	closelink: '',
	transition: ''
},
{
	id: 'store-holiday',
	component:'m-popup-store-holiday',
	bg: 'bk',
	size:'5w',
	title: '定休日',
	titleicon: '',
	body: '',
	img: '',
	closelink: '',
	transition: ''
},
{
	id: 'menu-category',
	component:'m-popup-menu-category',
	bg: '',
	size:'6w',
	title: 'カテゴリーの追加',
	titleicon: 'category-color red',
	body: '',
	img: '',
	closelink: '',
	transition: ''
},
{
	id: 'menu-genre',
	component:'m-popup-menu-genre',
	bg: '',
	size:'6w',
	title: 'メニュージャンルの追加',
	titleicon: '',
	body: '',
	img: '',
	closelink: '',
	transition: ''
},
{
	id: 'menu-item',
	component:'m-popup-menu-item',
	bg: '',
	size:'6w',
	title: 'メニューの追加',
	titleicon: '',
	body: '',
	img: '',
	closelink: '',
	transition: ''
},
{
	id: 'preview-qr',
	component:'m-popup-preview-qr',
	bg: '',
	size:'4w',
	title: '',
	titleicon: '',
	body: '',
	img: '',
	closelink: '',
	transition: ''
},
{
	id: 'confirm-overwrite',
	component:'m-popup-confirm-overwrite',
	bg: '',
	size:'',
	title: '',
	titleicon: '',
	body: '現在入力されている内容は、複製元の記事の内容で上書きされます。 上書きしてよろしいですか?',
	img: '',
	closelink: '',
	transition: ''
},
{
	id: 'confirm-delete-img',
	component:'m-popup-confirm-delete',
	bg: '',
	size:'txtw',
	title: '',
	titleicon: '',
	body: '画像を削除します。<br>よろしいですか?<br>削除すると復元はできません。',
	img: '',
	closelink: '',
	transition: ''
},
{
	id: 'upload-img-drag',
	component:'m-popup-upload-img-drag',
	bg: 'wh',
	size:'full',
	title: '',
	titleicon: '',
	body: '',
	img: '',
	closelink: '',
	transition: ''
},
{
	id: 'unsplash-about',
	component:'m-popup-mes',
	bg: '',
	size:'5w',
	title: 'Unsplashのご利用について',
	titleicon: '',
	body: '本機能は高品質写真素材サービスUnsplashと連携し提供しています。 被写体のイメージを損なう使い方や、写真のイメージを落とすような使い方、ユーザーを騙したり誘導するような使い方は固く禁止されています。 必ずUnsplashの利用規約に同意した上でご利用ください。',
	img: '',
	closelink: '',
	transition: ''
},
{
	id: 'upload-logo-data',
	component:'m-popup-upload-logo-data',
	bg: '',
	size:'6w',
	title: '',
	titleicon: '',
	body: '',
	img: '',
	closelink: '',
	transition: ''
},
{
	id: 'confirm-duplicate',
	component:'m-popup-mes',
	bg: '',
	size:'txtw',
	title: '',
	titleicon: '',
	body: '記事を複製しました！',
	img: '',
	closelink: '',
	transition: ''
},
{
	id: 'article-detail--blog',
	component:'m-popup-article-detail',
	bg: 'bk',
	size:'post',
	title: '',
	titleicon: '',
	body: '',
	img: '',
	closelink: '',
	transition: ''
},
{
	id: 'article-detail--gallery',
	component:'m-popup-article-detail',
	bg: 'bk',
	size:'post',
	title: '',
	titleicon: '',
	body: '',
	img: '',
	closelink: '',
	transition: ''
},
{
	id: 'article-detail--news',
	component:'m-popup-article-detail',
	bg: 'bk',
	size:'post',
	title: '',
	titleicon: '',
	body: '',
	img: '',
	closelink: '',
	transition: ''
},
{
	id: 'confirm-item-genre-delete',
	component:'m-popup-confirm-delete',
	bg: '',
	size:'txtw',
	title: '',
	titleicon: '',
	body: 'ジャンルヘアーを削除します。<br>ジャンル内のカテゴリーと<br>アイテムもすべて削除されます。<br>削除してもよろしいですか?',
	img: '',
	closelink: '',
	transition: ''
},
{
	id: 'web-private',
	component:'m-popup-web-private',
	bg: 'bk',
	size:'4w',
	title: 'ホームページ非公開',
	titleicon: '',
	body: 'ホームページを非公開にしますか？ 非公開中は、一般ユーザーはホームページにアクセスできなくなります。',
	img: '',
	closelink: '',
	transition: ''
},
{
	id: 'page-private',
	component:'m-popup-yesno',
	bg: '',
	size:'txtw',
	title: '',
	titleicon: '',
	body: 'お問合せページを<br>非公開にします。<br>よろしいですか?',
	img: '',
	closelink: '',
	transition: ''
},
{
	id: 'launch-website',
	component:'m-popup-launch-website',
	bg: 'bk',
	size:'6w',
	title: '',
	titleicon: '',
	body: '',
	img: '',
	closelink: '',
	transition: ''
},
{
	id: 'page-link',
	component:'m-popup-page-link',
	bg: '',
	size:'5w',
	title: '外部リンクの設定',
	titleicon: '',
	body: '外部予約サイトや特設ページをナビゲーションに表示したい場合に使用します。',
	img: '',
	closelink: '',
	transition: ''
},
{
	id: 'launched-website',
	component:'m-popup-mes',
	bg: '',
	size:'txtw',
	title: '',
	titleicon: '',
	body: 'おめでとうございます！<br>ホームページを公開しました！<br>ホームページを見る',
	img: '',
	closelink: '',
	transition: ''
},
{
	id: 'welcome',
	component:'m-popup-welcome',
	bg: 'bk',
	size:'welcome',
	title: '',
	titleicon: '',
	body: '',
	img: '',
	closelink: '',
	transition: ''
},
{
	id: 'request',
	component:'m-popup-request',
	bg: '',
	size:'6w',
	title: '',
	titleicon: '',
	body: '',
	img: '',
	closelink: '',
	transition: ''
},
{
	id: 'error-ie',
	component:'m-popup-error-ie',
	bg: 'bk',
	size:'5w',
	title: '',
	titleicon: '',
	body: '',
	img: '',
	closelink: '',
	transition: ''
},

];
