import {AOsApi} from '@/classes/app/api/os/a-os-api';
import ApiExecuterAsync from '@/classes/core/api/api-executer-async';
import VarUtil from '@/classes/utils/var-util';
import ApiExecuterFactory from '@/classes/core/api/api-executer-factory';
import {ApiMethod} from '@/classes/core/api/api-method';

export class Storex extends AOsApi {
  protected _url: string = '/store';

    public get(param: any = {}): ApiExecuterAsync {

        console.log('Store > param', param);

        let url = this.url;
        const storeId = VarUtil.path(param, 'storeId');
        const categoryId = VarUtil.path(param, 'categoryId');

        if (!!storeId) {
            url += '/detail/' + storeId;
            if (!!categoryId) {
                url += '?category=' + encodeURI(categoryId);
            }
        } else {
            if (!!categoryId) {
                url += '/category/' + encodeURI(categoryId);
            }
        }

        return ApiExecuterFactory.createRequestWithAsync(ApiMethod.Get, url, this.filterParam(param));
    }
}
