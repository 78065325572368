import {Mutation, MutationAction, Action, VuexModule, getModule, Module} from 'vuex-module-decorators';
import store from '@/classes/core/store';

// state's interface
export interface IAppLoadingStore {
    loading: boolean;
}

@Module({dynamic: true, store, name: 'appLoading', namespaced: true})
class Store extends VuexModule implements IAppLoadingStore {

    public loading: boolean = false;

    @Mutation
    public updateLoading(value: boolean = false) {
        this.loading = value;
    }

    @Action
    public async load(proc: () => Promise<void>): Promise<any> {
        M.updateLoading(true);
        const res = await proc();
        M.updateLoading();
        return res;
    }
}

export const appLoadingModule = getModule(Store);

const M = appLoadingModule;
