
































	// @ts-ignore
    import _ from './ProcedureDomainConnectInput.ts'; export default _;
