import {Component, Prop, Vue} from '@/node_modules/nuxt-property-decorator';

@Component({
    components: {

    }
})
export default class MToggleListItem extends Vue {

    @Prop({default: {}})
	public data: any;

    @Prop({default: 'no'})
	public type: string;

    @Prop({default: 1})
	public no: number;

	//桁数
    @Prop({default: 1})
	public digit: number;

	public isOpen: boolean = false;


    // Computed ///////////////////////////////////////////////////////

    public get title_no(): any {

        if (this.type == 'faq') {
            return 'Q.';
        }

        return this.no + '.';
    }

    public get inner_no(): any {

        if (this.type == 'faq') {
            return 'A.';
        }

        return '';
    }


    // Method ///////////////////////////////////////////////////////

    public toggle(){
		this.isOpen = !this.isOpen;
    }

}
