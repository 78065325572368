declare var process: any;

export const Env = {
    NODE_ENV: process.env.NODE_ENV || 'develop',
    SERVER_VERSION: process.env.SERVER_VERSION || '',
    CLIENT_VERSION: process.env.CLIENT_VERSION || '',
    DATA_REF: process.env.DATA_REF || '',
    HTTP_PORT: process.env.HTTP_PORT || 3000,
    HTTP_HOST: process.env.HTTP_HOST || '0.0.0.0',
    SITE_URL: process.env.SITE_URL || 'http://localhost:3000',
    API_HOST_SSR: process.env.API_HOST_SSR,
    API_HOST_BROWSER: process.env.API_HOST_BROWSER,
    MEDIA_BASE: process.env.MEDIA_BASE || '',
};
