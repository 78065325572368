import {Mutation, MutationAction, Action, VuexModule, getModule, Module} from 'vuex-module-decorators';
import store from '@/classes/core/store';
import VarUtil from "@/classes/utils/var-util";

// state's interface
export interface ISelectModule {
    name: string;
    current: any;
    lastSelected: any
    onSelect: (ipt: any) => Promise<void> | void | null;
}

export interface ISelectRequest {
    name?: string;
    value?: any;
    select: any;
    option: any;
    callback: (ipt: any) => Promise<void> | void | null;
}

@Module({dynamic: true, store, name: 'select', namespaced: true})
class Select extends VuexModule implements ISelectModule {
    public name: string = 'none';
    public value: any = null;

    public current: any = null;
    public lastSelected: any = null;
    public onSelect: (ipt: any) => Promise<void> | void | null = null;
    public option: any = null;

    @Mutation
    public clearCurrent() {
        this.current = null;
    }

    @Mutation
    public updateName(value: string) {
        this.name = value;
    }

    @Mutation
    public updateValue(value: string) {
        this.value = value;
    }

    @Mutation
    public updateCurrent(c: any = null) {
        this.current = c;
    }

    @Mutation
    public updateLastSelected(value: any = null) {
        this.lastSelected = value;
    }

    @Mutation
    public updateOnSelect(cb: (ipt: any) => Promise<void> | void | null) {
        this.onSelect = cb;
    }

    @Mutation
    public updateOption(value: any) {
        this.option = value;
    }

    @Action
    public open(option: ISelectRequest) {

        console.log('selectModule.open() > name=%s, option=',
            VarUtil.path(option, 'name', '-'),
            VarUtil.path(option, 'select', '--')
        );

        selectModule.updateName(VarUtil.path(option, 'name', 'none'));
        selectModule.updateValue(VarUtil.path(option, 'value'));
        selectModule.updateCurrent({
            ...{
                name: M.name,
            },
            ...option.select,
        });
        selectModule.updateLastSelected();
        selectModule.updateOnSelect(option.callback);
        selectModule.updateOption(option.option);
    }
}

export const selectModule = getModule(Select);

const M = selectModule;
