import {ISelect, ISelectSource} from "@/classes/domain/model";
import {Select} from "@/configs/select";
import {$v} from "@/classes/utils/var-util";

export class SelectUtil {

    //選択データのラベルを抽出
    public static findSource(id: string): ISelectSource | null {
        return Select.findByKey('id', id);
    }

    //選択データのラベルを抽出
    public static selectedL(aValue: any, aSrc: ISelectSource | string): string {
        const src = $v.isString(aSrc) ? Select.findByKey('id', aSrc) : aSrc;
        if (!src) {
            console.error(`invalid select source`, aSrc, src);
            return '';
        }

        const val: any = $v.tap(null, () => {
            switch ($v.path(src, 'type')) {
                case 'n':
                    return Number(aValue);

                default:
                    return aValue;
            }
        });

        // console.log('src is ', src);

        const ss = (src as any).d as ISelect[] | string[];
        if (typeof (ss[0]) === 'string') {
            return $v.tap(ss.indexOf(val), (idx: number) => {
                return idx >= 0 ? String(ss[idx]) : '';
            });
        } else {
            return $v.tap(ss.findByKey('value', val), (sel: ISelect | null) => {
                return !!sel ? sel.label : '';
            });
        }
    }

    // sourde datas to ISelect
    public static source2selects(source: any[], type: string = 's'): ISelect[] {

        const first = source[0];

        if ($v.has(first, 'value')) {
            return source;
        }

        // 単一値をISElect型へ変換
        return source.map((_: any) => {
            const v = (type === 'n') ? Number(_) : _;
            return {
                value: (type === 'sa') ? SelectUtil.sa2s(v) : v,
                label: String(v),
            }
        }) as ISelect[];
    }

    // String Alphabet 2 string
    public static sa2s(str: string): string {
        return str.toLowerCase()
            .replace(/ +/, '_')
            .trim()
    }
}
