







































	// @ts-ignore
    import _ from './ProcedureShareThanks.ts'; export default _;
