










































import {Component,Prop, Vue} from 'nuxt-property-decorator';

import MPrice from '@/components/module/MPrice.vue';

@Component({
    components: {
	    MPrice
    }
})
export default class MTotalPrice extends Vue {
	
    @Prop({default: 'ご請求金額'})
    public title: string;
    
    @Prop({default: '<span class="en">Thought Branding Plan</span>'})
    public iname: string;
}
