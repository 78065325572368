import {Component, Prop, Vue} from "vue-property-decorator";
import APage from '@/classes/page/a-page';
import {sortModule} from '@/store/sort';

import {DummyBlog} from '@/configs/dummy/dummy-blog';

import MPager from '@/components/module/MPager.vue';
import MList from '@/components/module/MList.vue';
import MFilter from '@/components/module/MFilter.vue';
import {popupModule} from "@/store/popup";
import {managerBlogArticleModule} from "@/store/manager/blog-article";
import {managerDevModule} from "@/store/manager/dev";
import {appEditModule} from "@/store/app/edit";
import VarUtil from "@/classes/utils/var-util";
import {BlogUtil} from "@/classes/domain/module/model/blog/blog-util";

@Component({
    components: {
        MPager,
        MList,
        MFilter
    }
})
export default class MArticles extends APage {

    //記事ページなどのタイプ指定
    //blog, gallery, news など
    @Prop({default: 'blog'})
    public type: string;

    // @Prop({default: DummyBlog})
    // public list: any[];

    @Prop({default: []})
    public list: [];
    // public list = DummyBlog;

    // Computed ///////////////////////////////////////
    public get isBlogFilter(): any {
        return managerDevModule.isBlogFilter;
    }

    // Methods /////////////////////////////////////////
    // - Sort Modeじゃないときだけリンク有効
    public openPopupNoSort(value: string) {

        popupModule.updateType(this.type);

        if (!sortModule.run) {
            this.onPopupOpen(value);
        }
    }

    public getBlogState(item: any): string {
        return BlogUtil.state(item);
    }

    public isLastCategory(item: any, index: number): boolean {
        return (index !== item.categories.length - 1);
    }

    // Label Methods ////////////////////////////////////
    public sdateL(date: string): string {
        return VarUtil.dateFormat(date, 'MM &#047; DD');
    }

    public hasMediaCover(item: any): boolean {
        return !!this.getMediaCover(item);
    }

    public getMediaCover(item: any): any {
        return Array.isArray(VarUtil.path(item, 'medias')) ?
            item.medias.findByKey('purpose', 'blogCover') : null;
    }

    public getMediaCoverSrc(item: any): string {
        const mc = this.getMediaCover(item);
        return !!mc ? VarUtil.path(mc, 'file.url') : '';
    }

    public dateL(date: string): string {
        return '';
    }

    public categoryH(item: string): string {
        return ''
    }

    public categoryL(category: any): string {
        return BlogUtil.categoryL(category);
        // const cat = this.categories.findByKey('toid', category);
        // return !!cat ? cat.title : '*';
    }


    // Events ///////////////////////////////////////////
    public onClickArticle(item: any) {
        managerBlogArticleModule.updateEdit({
            type: this.type,
            article: item,
        });
        this.openPopupNoSort('article-detail--' + this.type);
    }

    public onFilter() {
        if (managerDevModule.isBlogFilter) {
            managerDevModule.updateIsBlogFilter(false);

        } else {
            managerDevModule.updateIsBlogFilter(true);
        }
    }

}
