import {Mutation, MutationAction, Action, VuexModule, getModule, Module} from 'vuex-module-decorators';
import store from '@/classes/core/store';
import _ from 'lodash';
import uuid from 'uuid';
import VarUtil from "@/classes/utils/var-util";
import WebApi from "@/classes/app/api/manager/web-api";
import ManagerApi from "@/classes/app/api/manager-api";
import Var from "@/classes/app/api/manager/var";
import ApiConst from "@/classes/core/api/api-const";
import {IUpdateRequest} from "@/store/uploadimg";

export const UploadType = {
    Img: 'img',
    File: 'file',
};

// state's interface
export interface IUpFileModule {
    ups: IUpFile[];
}

export interface IUpFile {
    // 識別用
    id: string;
    type: 'img' | 'file',
    // state
    purpose: string;
    site?: string;
    token?: string;
    option?: any;

    file?: File | Blob | string | null;
    progress: IUpProgress;
}

export interface IUpProgress {
    // progress
    retry: number;
    uploaded: boolean;
    result: null | boolean;
    logs: any[];
}

export interface IUpFileRequest {
    id?: string;
    // state
    purpose?: string;
    site?: string;
    token?: string;
    option?: any;

    file?: File | null,
    progress?: IUpProgress,
}

const TEMPLATE = {
    id: '',
    // state
    purpose: '',
    site: '',
    token: '',
    option: {},

    // progress
    retry: 0,
    uploaded: false,
    result: null,
    logs: [],

    progress: {
        retry: 0,
        uploaded: false,
        result: null,
        logs: [],
    }
};

const makeUpFile = (base: IUpFileRequest) => {
    return Object.assign({}, TEMPLATE, base);
};

@Module({dynamic: true, store, name: 'upfile', namespaced: true})
class Store extends VuexModule implements IUpFileModule {
    public loaded: boolean = false;
    public ups: IUpFile[] = [];

    @Mutation
    public async updateUp(value: IUpFile) {

        const current = this.ups.findByKey('id', value.id);
        if (!!current) {
            this.ups = this.ups.map((_: IUpFile) => {
                return (_.id === value.id) ? value : _;
            });
        } else {
            this.ups = this.ups.concat([value]);
        }
    }

    @Action
    public async patchUp(value: IUpFileRequest) {

        const nup = Object.assign({},
            upfileModule.ups.findByKey('id', value.id),
            value
        );

        await upfileModule.updateUp(nup);
    }

    // * opt.id - upfileid
    // * opt.purpose - ファイルの目的コード
    // * opt.site OwnerサイトID
    // * opt.transId ウィザードからのPrepareトランザクションID
    // * opt.file - File
    // opt.token QRなどのセッション外からのUP用Token
    // opt.option 送信付加データ(トリミング情報など)
    @Action
    public async regist(param: any) {

        const id = VarUtil.rndchars(12);
        const nparam = Object.assign({}, param, {
            id: (!!param!.id) ? param.id : id,
        });
        await upfileModule.updateUp(Object.assign({}, TEMPLATE, nparam));
    }

    // @Mutation
    // public regist(req: IUpFileRequest) {
    //     this.upfiles = Array.from(this.upfiles.concat([makeUpFile(req)]));
    // }

    // 必要項目

    // purpose+(site || transId) or token
    //
    // opt.id - upfileid
    // opt.purpose - ファイルの目的コード
    // opt.site OwnerサイトID
    // opt.transId ウィザードからのPrepareトランザクションID
    // opt.token QRなどのセッション外からのUP用Token
    // opt.option 送信付加データ(トリミング情報など)
    @Action
    public async upload(up: IUpFile): Promise<any> {

        let r = null;
        // await ManagerApi.FileUpload.store({
        //     site: up.site,
        //     token: up.token,
        //     purpose: up.purpose,
        //     file: up.file,
        //     option: up.option,
        // }).then((res: any) => {
        //     r = res;
        //     // if (!!up.onSuccess) {
        //     //     await up.onSuccess({
        //     //         up,
        //     //         res
        //     //     });
        //     //     r = res;
        //     // }
        // }).failue((res: any) => {
        //     // if (!!up.onFailed) {
        //     //     await up.onFailed({
        //     //         up,
        //     //         res,
        //     //     });
        //     // }
        // }).all((res: any) => {
        //     // if (!!up.onComplele) {
        //     //     await up.onComplele({
        //     //         up,
        //     //         res,
        //     //     });
        //     // }
        // }).exec();

        // if (VarUtil.path(r, 'data.result') === ApiConst.Res.Ok) {
        //     return VarUtil.path(r, 'data.upload');
        // } else {
        //     throw new Error(!!r ? r!['data'] : 'response error');
        // }
    }

    @Action
    public async check(token: string): Promise<any> {
        try {
            return await ManagerApi.FileUploadPrepare.show(token).execAsync();
        } catch (e) {
            return null;
        }
    }

    @Action
    public async remove(param: any): Promise<void> {
    }
}

export const upfileModule = getModule(Store);

