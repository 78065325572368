import {Component, Vue} from 'nuxt-property-decorator';

import CLayoutStandard from '@/components/theme/layouts/CLayoutStandard.vue';

@Component({
    components: {
		CLayoutStandard,
    }
})
export default class Standard extends Vue {

    public head() {
        return {
			meta: [
				{ name: 'google', content: 'notranslate' },
				{ name: 'format-detection', content: 'telephone=no' },

			]
        }
    }

}
