































	// @ts-ignore
    import _ from './MSecondaryDomainConnect.ts'; export default _;
