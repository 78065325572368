import {Component, Prop, Vue} from '@/node_modules/nuxt-property-decorator';
import {managerDevModule} from "@/store/manager/dev";

@Component({
    components: {
    }
})
export default class MFilter extends Vue {

    public isActive: boolean = true;

    public dummyState: any = [
        { id: 1, label: '公開済み' },
        { id: 2, label: '下書き中' },
    ];

    public dummyCategory: any = [
        { id: 1, label: 'カテゴリーA' },
        { id: 2, label: 'ジャーニー' },
        { id: 3, label: 'ポピュラス' },
        { id: 4, label: 'かてごり' },
    ];

    public dummyMonths: any = [
        { id: 1, state: '' },
        { id: 2, state: 'disabled' },
        { id: 3, state: '' },
        { id: 4, state: '' },
        { id: 5, state: '' },
        { id: 6, state: 'disabled' },
        { id: 7, state: '' },
        { id: 8, state: '' },
        { id: 9, state: 'disabled' },
        { id: 10, state: '' },
        { id: 11, state: '' },
        { id: 12, state: '' },
    ];

    // Computed ////////////////////////////////////////////////////////

    public get isBlogFilter(): any {
        return managerDevModule.isBlogFilter;
    }

    // Event ////////////////////////////////////////////////////////

    public onClose() {
        managerDevModule.updateIsBlogFilter(false);
    }

}

