import {Component, Vue} from '@/node_modules/nuxt-property-decorator';
import APage from '@/classes/page/a-page';
import {accountModule} from '@/store/account';

export default class AAccount extends APage {
	
    /*
	    契約画面の内容切り分けに使用
	    
	    plan-subscribe : プラン新規
	    plan-change : プラン変更
		plan-unsubscribe : 解約
	    domain-subscribe : ドメイン取得
	    domain-connect : ドメイン接続
		domain-unsubscribe : 解約
		domain-move : ドメインの移行
    */
    public toProcedure(to: string, procedureType: string, module: any = accountModule){
	    //accountModule
	    module.updateDevProcedure( procedureType );
	    this.$router.push(to)
    }
    
    /*
	    ドメイン詳細画面の内容切り分けに使用
	    
		ドメイン状況
		no : 契約なし
		connect-wait : 接続待ち
		connect-success : 接続済み
		get-wait : 取得作業中
		get-success : 取得済み
	    
    */
    public toDetail(domainStates: string){
	    accountModule.updateDevDomainStates( domainStates );
	    this.$router.push('/manager/account/domains-detail')
    }
	
}






