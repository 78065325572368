




























































	// @ts-ignore
    import _ from './MSecondaryAccount.ts'; export default _;
