/*

	YH: Data Spread Sheet is Here
	https://docs.google.com/spreadsheets/d/1GP-wc5RwVL54UcYqxjH_ZfCQWY7IAfuL_BSC2SXCL8s/edit?usp=sharing

*/
export const DummyProject =

{
    web: {
        title: 'atelier',
        description: '札幌のヘアーサロン',
        ga: '',
        publish: 1,
        password: {
            use: 'unlimited',
            password: '',
        },
        ogimg: '',
    },

    /*
        Brand IDとLayout Idの指定
    */
	global: {
	    brandName: 'Hair design studio THOUGHT',
		brandid: 'tiny-garamond',
        layout: 'spring', //前のid 1
//         layout: 'drawn', //前のid 5
//         layout: 'moving', //前のid 2
//         layout: 'classic', //前のidå 4
//         layout: 'small', //前のidå 4

// 		brandid: 'morning-window',
//         layout: 'today', //前のidå 4
	},

    /*
        Logo情報
    */
	logo: {
		shopname: 'atelier', //Shopname
		tagline: '', //ロゴの下に設定できるスローガン
		type: 'webfont',
// 		type: 'img', // webfont or img
/*
		bg: '/dummy-user/pjname/logo/wh-circle.png', //背景が黒の場合のロゴ画像（白文字のロゴ）
		txt: '/dummy-user/pjname/logo/bk-circle.png', //背景が白の場合のロゴ画像（黒文字のロゴ）
*/
// 		webfont: 'crimpsonTextSB', //type が webfontの時に指定される書体ID /configs/Logos.ts からデータをひっぱってくる
		webfont: 'interRegular',

        //自動リサイズ後にスケールを変更したい場合の設定値
        scale: {
            navLogo: 1, //ヘッダーのロゴスケール
            footerLogo: 1, //フッターのロゴスケール
            blockLogo: 1, //ブロックで使用される場合のスケール
            loadingLogo: 1, //Loadingでのスケール
        },
	},

    /*
        本店情報
    */
	mainstore: {
        address1: '札幌市中央区南1条西1丁目14-6',
		address2: 'シルキーハイツ701',
		open: ['平日・土. 10:00 – 20:00', '日・祝. 10:00 – 18:00'],
		close: ['火', '第3水曜'],
		map: 'https://google.com/map/',
		tel: '0124-556-8875',
		reservation: {
			name: 'Reservation',
			link: 'https://yoyaku.com',
		},
	},

    /*
        ナビゲーション
    */
	navigation: {
        categories: [

            {
                no: 1,
                id: 'primary',
                label: 'OUR SALON',
                use: 1,
            },
            {
                no: 2,
                id: 'secondary',
                label: 'INFO',
                use: 1,
            },
            {
                no: 3,
                id: 'special',
                label: 'OTHER',
                use: 1,
            },
            {
                no: 4,
                id: 'sns',
                label: 'Follow',
                use: 1,
            },
        ],
    	/*
        	type: 固定値（ index or page or link ）
        	no: 順番
        	id: ページID
        	use: 使用するかしないか
        	global: グローバルメニューに表示するかどうか
        	label: 表示名
        	category:
        	link: リンクアドレス
    	*/
		page: [
			{
				type: 'index',
			    no: 1,
				id: 'index',
				use: true,
				global: true,
				label: 'Home',
				category: 'primary',
				link: '/'
			},{
				type: 'page',
			    no: 2,
				id: 'gallery',
				use: true,
				global: true,
				label: 'Gallery',
				category: 'primary',
				link: '/gallery/'
			},{
				type: 'page',
			    no: 3,
				id: 'staff',
				use: true,
				global: true,
				label: 'Staff',
				category: 'primary',
				link: '/staff/'
			},{
				type: 'page',
			    no: 4,
				id: 'store',
				use: true,
				global: true,
				label: 'Salon',
				category: 'primary',
				link: '/store/'
			},{
				type: 'page',
			    no: 5,
				id: 'menu',
				use: true,
				global: true,
				label: 'Menu',
				category: 'primary',
				link: '/menu/'
			},{
				type: 'page',
			    no: 6,
				id: 'blog',
				use: true,
				global: true,
				label: 'Blog',
				category: 'secondary',
				link: '/blog/'
			},{
				type: 'page',
			    no: 7,
				id: 'news',
				use: true,
				global: false,
				label: 'News',
				category: 'secondary',
				link: '/news/'
			},{
				type: 'page',
			    no: 8,
				id: 'contact',
				use: false,
				global: false,
				label: 'Contact',
				category: 'secondary',
				link: '/contact/'
			},{
				type: 'link',
			    no: 9,
				id: 'link',
				use: true,
				global: true,
				label: 'Reservation',
				category: 'special',
				link: 'http://reservation.com'
			},
		],
		sns: {
			global: true,
    		data: [
    			{
    				type: 'sns',
				    no: 1,
    				id: 'facebook',
                    use: true,
    				label: 'Facebook',
    				link: 'https://www.facebook.com/[ID]'
    			},{
    				type: 'sns',
				    no: 2,
    				id: 'twitter',
                    use: true,
    				label: 'Twitter',
    				link: 'https://twitter.com/[ID]'
    			},{
    				type: 'sns',
				    no: 3,
    				id: 'instagram',
                    use: true,
    				label: 'Instagram',
    				link: 'https://www.instagram.com/[ID]/'
    			},{
    				type: 'sns',
				    no: 4,
    				id: 'line',
                    use: true,
    				label: 'Line',
    				link: 'https://timeline.line.me/'
    			}
    		]
		},
	},

    /*
        Main Visual
    */
	mv: {
		address: true, //英語住所の表示
/* 		content: 'logo', */
		content: 'txt', //メインビジュアルの写真の上に表示するコンテンツ: logo / txt / img / gallery
		img: ['/templates/hair/mv/1-l.jpg','/templates/hair/mv/2-l.jpg','/templates/hair/mv/3-l.jpg'], //写真（複数枚可）
		contentTxt: 'シグネチャー<br>ブレンド', //コンテンツ：文字列
/* 		contentTxt: 'Hair design Studio Thought<br>First Fog Everything', */
/* 		contentTxt: 'さまざまの灯あかり、<br>飾かざられた街を通って。', */
		contentImg: '/dummy-user/pjname/mv/contentImg/1.png', //コンテンツ：画像
		contentLogoScale: 1, //各コンテンツのスケール
		contentTxtScale: 1,
		contentImgScale: 1,
	},

    /*
        サイドカバー
    */
	sidecover: {
		type: 'CSidecoverAddress', //Component Name: CSidecoverAddress / CSidecoverTopics
// 		type: 'CSidecoverTopics', //Component Name: CSidecoverAddress / CSidecoverTopics
		design: 'simple-line', //Style: simple-line / catwalk / boots
		addrTitle: 'JR札幌駅から徒歩5分',
		addrLink: 'https://google.com/maps',
		address: ['JR札幌駅<br>4番出口','ローソンの<br>交差点を左','信号2つ目を右','電気屋の<br>交差点を左','左手の3軒目'],
		addrFooter: 'Tel. 548 5665 6694<br>10:00 - 21:00 / Closed on Tuesday,<br>Second Monday Every Month.',
		addrFooterTitle: 'Reservation',
		addrFooterLink: '/reserve/r1-store.html',
	},

    /*
        Main Visualに掲載する住所
        mv.address が true のときに表示
    */
	mvAddress: {
		title: 'Hair design Studio<br>Thought',
		block1title: 'Address',
		block1body: 'Main Street bld. 7f Tachigi 7-3,<br>4jo - 6chome Asahi-cho<br>Nishimurayama gun Yamagata',
		block1link: {
			name: 'map',
			link: 'https://google.com/maps/',
		},
		block2title: 'Hours',
		block2body: '10:00 - 21:00<br>Closed on Tuesday,<br>Second Monday Every Month.',
		block3title: 'Reservation',
		block3body: 'T. 265-5568-5481',
		block3link: {
			name: 'Online Reservation',
			link: '/themes/reservation/cp/reserve/r1-store.html',
		},
	},

    /*
        フッター
    */
	footer: {

    	//左下についてくるナビゲーション
		navbottom: {
            type: 'salon', //現状固定でsalon
            tel: true, //電話番号の表示
            map: true, //MAPリンクの表示
            link: true, //外部リンクの表示
            news: true, //右下のニュースの表示
        },
		title: 'Hair Design Studio Thought', //フッタータイトル
		prThought: true, //THOUGHTへのリンク
        menus: [
            {id: 'shop', label: 'Our Shop'},
            {id: 'info', label: 'Info'},
            {id: 'sns', label: 'Follow us'},
        ],
	},

    /*
        ローダー
    */
	loader: {
//     	type: 'circle',
    	type: 'logo', //Loader Typeの指定: circle / logo / bar
/*     	type: 'bar', */
	}

};











