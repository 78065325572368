import {IPageState} from '@/classes/view/model/i-page-state';

export default class ViewModelUtil {
    public static createPageState(ex: any = {}): IPageState {
        return Object.assign({}, {
            config: {},
            param: {},
            view: {
                ready: false,
            },
            form: {
                input: {},
                erros: []
            }
        }, ex);
    }
}
