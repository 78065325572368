import {
    ApiExecuterAsync,
    ApiExecuterFactory,
    ApiMethod,
    ApiUtil
} from '@/classes/core/api/import';
import {vsprintf} from 'sprintf-js';

export default class BlogCategory {

    public get(param: any = {}): ApiExecuterAsync {
        let url = ApiUtil.url('/owner/site/blog/category');
        let request = ApiExecuterFactory.createRequest({
            method: ApiMethod.Get,
            url: url,
            param: param
        });

        return ApiExecuterFactory.createAsync(request);
    }

    public show(id: number, param: any = {}): ApiExecuterAsync {
        let url = ApiUtil.url(vsprintf('/owner/site/blog/category/%s', [id]));
        let request = ApiExecuterFactory.createRequest({
            method: ApiMethod.Get,
            url: url,
            param: param
        });

        return ApiExecuterFactory.createAsync(request);
    }

    public post(param: any = {}): ApiExecuterAsync {
        let url = ApiUtil.url('/owner/site/blog/category');
        let request = ApiExecuterFactory.createRequest({
            method: ApiMethod.Post,
            url: url,
            param: param
        });

        return ApiExecuterFactory.createAsync(request);
    }

    public put(id: number, param: any = {}): ApiExecuterAsync {
        let url = ApiUtil.url(vsprintf('/owner/site/blog/category/%s', [id]));
        let request = ApiExecuterFactory.createRequest({
            method: ApiMethod.Put,
            url: url,
            param: param
        });

        return ApiExecuterFactory.createAsync(request);
    }

    public patch(id: number, param: any = {}): ApiExecuterAsync {
        let url = ApiUtil.url(vsprintf('/owner/site/blog/category/%s', [id]));
        let request = ApiExecuterFactory.createRequest({
            method: ApiMethod.Patch,
            url: url,
            param: param
        });

        return ApiExecuterFactory.createAsync(request);
    }

    public delete(id: number, param: any = {}): ApiExecuterAsync {
        let url = ApiUtil.url(vsprintf('/owner/site/blog/category/%s', [id]));
        let request = ApiExecuterFactory.createRequest({
            method: ApiMethod.Delete,
            url: url,
            param: param
        });

        return ApiExecuterFactory.createAsync(request);
    }
}
