






































	// @ts-ignore
    import _ from './print.ts'; export default _;
