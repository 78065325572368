import {Component, Vue} from 'nuxt-property-decorator';
import {themeMainModule} from '@/store/theme/theme-main';
import CMix from '@/classes/theme/mixin/c-mix';

import CPageMenuIndexStandard from '@/components/theme/page/menu/index/CPageMenuIndexStandard.vue';

@Component({
    mixins: [CMix],
    components: {
        CPageMenuIndexStandard,
    }
})
export default class CIndex extends Vue {


  public title: string;


  ///////// 全ページ共通 ////////////////////////////////////////////
  public page: any = {
    type: 'page',
    page: 'menu',
    depth: 'index',
  };


  // SSRでサーバーサイドの処理から変数をセット
  public async asyncData(context: any): Promise<any> {
    return {
      title: 'Indexa',
    };
  }


  // ヘッダタグの構成要素
  public head() {
    return {
      title: this.title
    };
  }


  public created() {

    //PAGE情報をSTOREにセット
    this!['updateMyPage'](this.page, themeMainModule);

  }


	public mounted() {
		this!['init']();
	}


}
