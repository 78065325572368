import {Component, Watch, Prop, Vue} from "vue-property-decorator";
import {themeMainModule} from '@/store/theme/theme-main';

@Component({
  components: {
  }
})
export default class CActiveListItem extends Vue {
	
  @Prop({default: 'li'})
  public tag: string;
		
  @Prop({default: ''})
  public k: string;
  
  @Prop({default: ''})
  public active_k: string;
  
  public get classActive(): any {
    
    if(this.k == this.active_k){
      return '-c-active';
    }
    
    return '';
    
  }
  
}



