import {Component, Watch, Prop, Vue} from '@/node_modules/nuxt-property-decorator';
import {themeMainModule} from '@/store/theme/theme-main';
// import {themeStateModule} from '@/store/theme/theme-state';
import UseragentUtil from '@/classes/view/useragent-util';

import * as $ from 'jquery';
import {EnvUtil} from "@/classes/app/env-util";
import {AuthManager} from "@/classes/app/auth-manager";
import {managerProjectModule} from "@/store/manager/project";
import {DomainUtil} from "@/classes/domain/module/model/domain/domain-util";
import {$v} from "@/classes/utils/var-util";
import {ManagerUtil} from "@/classes/domain/module/manager/manager-util";

@Component({
    components: {}
})
export default class MSecondaryWeb extends Vue {

    public attentionHeight: any = {};
    public $mSecScreen: any;
    public $mSecondaryWeb: any;
    public frameDevice: string = '-pc';

    public isManual: boolean = false; //手動で指定すると true に。
    public isPc: boolean = true;
    public isMobile: boolean = false;


    /*
        Windowがリサイズされたらm-secondary-webのwidthを取得して
        リサイズ判定を行う
    */
    @Watch('mainFrameResized')
    public watchMainFrameResized() {

        //手動でデバイス指定済み
        if (this.isManual) {

            const frameWidth = this.$mSecondaryWeb.outerWidth();
            const frameDeviceSize = UseragentUtil.size(frameWidth);
//             const screenWidth = this.$mSecScreen.outerWidth();
//             const screenDeviceSize = UseragentUtil.size(screenWidth);

            if (!frameWidth) {
                return;
            }

            /*
                PCが指定されているときは、frameDeviceSize がモバイルになったら
                mbに変更し、isManualも切る
            */
            if (this.frameDevice == 'pc' && frameDeviceSize == 'mb') {
                this.resize(UseragentUtil.size(frameWidth), true);
            }

//             console.log(`frameWidth: ${frameWidth}, frameDeviceSize: ${frameDeviceSize}`);

            //デバイス未指定（自動）
        } else {

            //フレームサイズを基準に指定
            const w = this.$mSecondaryWeb.outerWidth();

            if (!w) {
                return;
            }

            this.resize(UseragentUtil.size(w));
        }
    }


    public mounted() {

        //エレメントを代入
        this.$mSecondaryWeb = $(this.$refs.mSecondaryWeb);
        this.$mSecScreen = $(this.$refs.mSecScreen);

        //フレームサイズを基準に指定
        this.$nextTick(() => {
            const w = this.$mSecondaryWeb.outerWidth();
            this.resize(UseragentUtil.size(w));
        });
    }


    public resize(value: string, manual: boolean = false) {

        //手動設定をONにするか
        if (manual) {
            this.isManual = true;
        }

        if (value == 'pc' || value == 'tablet') {
            this.frameDevice = 'pc';

        } else {
            this.frameDevice = 'mb';
        }

//         console.log(`resize()
//             value: ${value}, manual: ${manual}, this.frameDevice: ${this.frameDevice}
//         `);

        // サイズが変わるので、強制リサイズイベント実行
        // フレームサイズを一旦変更して表示後に計算
        this.$nextTick(() => {
            const w = this.$mSecScreen.outerWidth();
            themeMainModule.updateMainFrameWidth(w);
        });
    }


    public get mainFrameResized(): any {
        return themeMainModule.mainFrameResized;
    }


    public get isAttention(): any {
        return false;
    }

    public get ownersiteUrl(): string {
        return ManagerUtil.ownersiteUrl;
    }
}





