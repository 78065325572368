











































// @ts-ignore
import _ from "./CPageNewsIndexStandard.ts";

export default _;
