import {$v} from "@/classes/utils/var-util";

export class ArrayUtil {

    public static findByKey<T>(target: Array<T>, key: any, value: any): T | null {

        if (typeof (target!['find']) === 'function') {
            return target!['find']((_: any) => {
                return (_![key] === value);
            }) || null;
        } else {
            for (let i = 0; i < target.length; i++) {
                if ($v.p(target[i], key) === value) {
                    return target[i];
                }
            }

            return null;
        }
    };

    // @ts-ignore
    public static findIndexByKey<T>(target: Array<T>, key: any, value: any): number {
        let hit = -1;
        target!['some']((_: any, idx: number) => {
            if (_![key] === value) {
                hit = idx;
                return true;
            } else {
                return false;
            }
        });
        return hit;
    };

    // @ts-ignore
    public static replaceByKey<T>(target: Array<T>, key: any, rep: any, add: boolean = true): {
        update: string, // '' | 'u' | 'a'
        array: Array<T>,
    } {

        let update = '';
        let replaced = false;
        const array = target.map((_: any) => {
            if ((_![key] === rep![key])) {
                update = 'u';
                replaced = true;
                return rep;
            } else {
                return _;
            }
        });

        if (!replaced && add) {
            update = 'a';
            array.push(rep);
        }

        return {
            update,
            array,
        };
    };

    // @ts-ignore
    public static rejectByKey<T>(target: Array<T>, key: any, value: any): Array<T> {
        const e = $v.isObject(value) ? $v.p(value, key) : value;
        return target!['filter']((_: any) => {
            return (_![key] !== e);
        });
    };

    // @ts-ignore
    public static first<T>(target: Array<T>): T | null {
        return target.length > 0 ? target[0] : null;
    };

    // @ts-ignore
    public static last<T>(target: Array<T>): T | null {
        return target.length > 0 ? target![target.length - 1] : null;
    }

    // @ts-ignore
    public static isLast(target: Array<any>, idx: number): boolean {
        return (idx === target.length - 1);
    };

    // @ts-ignore
    public static isOver(target: Array<any>, idx: number) {
        return (idx < 0 || idx >= target.length);
    };

    // @ts-ignore
    public static from<T>(target: Array<T>, concat: any = null): Array<T> {
        return $v.tap(Array.from(target), (_: any) => {
            if (!!concat) {
                return _.concat(Array.isArray(concat) ? concat : [concat]);
            } else {
                return _;
            }
        });
    }
}
