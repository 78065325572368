import {Component, Vue} from 'vue-property-decorator';
import {themeMainModule} from '@/store/theme/theme-main';
import * as $ from 'jquery';

@Component
export default class CPatternMix extends Vue {

    /*
        全PATTERN共通
    */
	public get title(): any {
		return themeMainModule.pageData.name;
	}


    /*
        MOBILEだった場合、固定ナビの高さを
        c-contents-frame のマージンに設定する
    */
    public get styleContentsFrame(): any {
        return themeMainModule.contentsFrameStyle;
    }

	public get deviceSize(): any {
		return themeMainModule.deviceSize;
	}

	public get aspect(): any {

		if(themeMainModule.pageData.aspect) {
			return themeMainModule.pageData.aspect;
		}

		return 'auto';
	}

    public get sClassColumn(): any {
        return '-column-' + themeMainModule.pageData.column;
    }


}
