import {Component, Vue} from 'vue-property-decorator';
import VarUtil from '@/classes/utils/var-util';
import {themeMainModule} from '@/store/theme/theme-main';
import {themeStateModule} from '@/store/theme/theme-state';

import * as $ from 'jquery';

@Component
export default class CHeaderMix extends Vue {

    /*
        ADJUST THE NUMBER OF DISPLAYS BY NAVIGATION LAYOUT.
        レイアウトにあわせて表示アイテム数を調整する

        ・index, page, link に分ける
        ・それぞれ no 順に並べ替える
        ・setItemsByLayout
        ・最優先コンテンツは link > page > index

    */
    public layoutNavigation(
        $logo: any,
        $navContainerPc: any,
        navFrameElement: any,
        _items: any,
        cLayout: string = 'center',
        projectNavigationSns: any,
        projectNavigationPageSpecial: any,
    ): any {

        const $cMainFrame = document.getElementsByClassName('c-main-frame')[0];

        if(!$cMainFrame || !$logo || !$navContainerPc || !navFrameElement || !_items){
            console.log('No Header Data');
            return {
                primary: [],
                secondary: [],
            };
        }

        const items = [].concat(_items);

        console.log('CHeaderMix.layoutNavigation() > init > ', items);

        //それぞれ配列で分ける
        let indexes: any = this.filterByKey(items, 'type', 'index');
        let pages: any = this.filterByKey(items, 'type', 'page');
        let links: any = this.filterByKey(items, 'type', 'link');

        //noで並び替え
        indexes = this.sortByKey(indexes, 'no');
        pages = this.sortByKey(pages, 'no');
        links = this.sortByKey(links, 'no');

//         console.log(items);
//         console.log(indexes);
//         console.log(pages);
//         console.log(links);

        //ロゴ、項目ごとのマージン、SNSなどのwidth計算
        const otherItemWidth = this.calcOtherItemWidth(
            $cMainFrame,
            $logo,
            $navContainerPc,
            navFrameElement,
            projectNavigationSns,
            projectNavigationPageSpecial,
        );

        //どういうふうに項目を並べるか
        const r = this.calcLayout(
            $logo,
            $navContainerPc,
            navFrameElement,
            items,
            indexes,
            pages,
            links,
            otherItemWidth,
            cLayout,
        );

        console.log('CHeaderMix.layoutNavigation() > result > ', r);

        return r;
    }


    /*
        ロゴ、項目ごとのマージン、SNSなどのwidth計算
    */
    public calcOtherItemWidth(
        $cMainFrame: any,
        $logo: any = null,
        $navContainerPc: any = null,
        navFrameElement: any = null,
        projectNavigationSns: any,
        projectNavigationPageSpecial: any,

    ): any {

        let logoWidth: number = 0;
        const logoMargin = 30;
        let snsWidth: number = 0;
        let linkWidth: number = 0;
        let itemMargin: number = 0;

        //ロゴのサイズを取得
        if ($logo) {
            logoWidth = $logo.outerWidth() + (logoMargin * 2);
        }

        //項目のマージンを取得
        let marginOrigin: string = window.getComputedStyle(navFrameElement).getPropertyValue('--nav-item-margin');

        //%の場合はpxに変換
        if (marginOrigin.lastIndexOf('%') != -1) {
            const baseWidth = parseInt(window.getComputedStyle($cMainFrame).getPropertyValue('--base-max-width'));
            itemMargin = parseInt(marginOrigin);
            itemMargin = (itemMargin / 100) * baseWidth;

        } else {
            itemMargin = parseInt(marginOrigin);

        }

        //SNS Dataがあるか
        //project.navigation.sns.global == true, project.navigation.sns.data.length > 0
        if (projectNavigationSns.global && projectNavigationSns.data.length > 0) {

            if ($('.c-nav-sns').length > 0) {
                snsWidth = $('.c-nav-sns').outerWidth();

            }
        }

        //linkのwidth あとから右側の余白から引く
        if (projectNavigationPageSpecial) {
            if ($('.c-nav-special').length > 0) {
                linkWidth = $('.c-nav-special').outerWidth();

            }
        }

/*
    	console.log(`
            logoWidth: ${logoWidth},
            itemMargin: ${itemMargin},
            snsWidth: ${snsWidth},
            linkWidth: ${linkWidth},
    	`);
*/

        return {
            logoWidth,
            itemMargin,
            snsWidth,
            linkWidth,
        }
    }


    /*
        type:center の場合、左右に割り振る
    */
    public calcLayout(
        $logo: any = null,
        $navContainerPc: any = null,
        navFrameElement: any = null,
        items: any = [],
        indexes: any = [],
        pages: any = [],
        links: any = [],
        w: any,
        cLayout: string = 'center',

    ): any {

        //Navigation c-nav-container の width
        const navContainerWidth = $navContainerPc.width();

        let primary: any = [];
        let secondary: any = [];

        let leftWidth: number = 0;
        let rightWidth: number = 0;
        let allWidth: number = 0;
        let characterWidth: number = 0;
        let itemsWidth: number = 0; //実際の項目のwidthの合計
        let pagesWidth: number = 0; //実際の項目のwidthの合計
        let turnWidth: number = 0;
        let leftWidthCalc: number = 0;
        let rightWidthCalc: number = 0;
        let turn = 0;
        let last = null;

        /*
            幅の計算
        */
        //cLayoutがQueenの場合
        if (cLayout == 'queen') {
            allWidth = navContainerWidth;

        // Center
        } else {

            //左側の余白 width と右側の余白width 両方合計の余白を取得
            leftWidth = (navContainerWidth - w.logoWidth) / 2;
            rightWidth = leftWidth - w.snsWidth - w.linkWidth - w.itemMargin;
            allWidth = leftWidth + rightWidth;
        }

        //文字数からそれぞれの item.width を計算
        characterWidth = this.characterWidth(items);
        pagesWidth = this.widthByAry(pages, w.itemMargin, characterWidth);


        // 折り返しのwidthを計算 ////////////////////////////////////////////////////////////
        //item のすべての width が　c-nav-container より短かった場合
        //console.log(`pagesWidth: ${pagesWidth} < allWidth: ${allWidth}`);

        if (pagesWidth < allWidth) {

/*             console.log('項目の方が短い'); */

            //itemが5つ以下だった場合　かつ　Home分のリンクの余裕があった場合 Homeを追加
            const homeWidth = this.widthByAry(indexes, w.itemMargin, characterWidth);

            if (
                pages.length <= 4
                && (homeWidth + itemsWidth) < allWidth
                && indexes.length > 0
                || cLayout != 'center'
            ) {
                //console.log(indexes[0]);
                primary.push(indexes[0]);
                pagesWidth += this.widthByCharacter(indexes[0].label, w.itemMargin, characterWidth);
            }

            if (cLayout == 'queen') {
                turnWidth = allWidth;

            } else {
                turnWidth = ((pagesWidth + w.snsWidth + w.linkWidth + (w.itemMargin * 2)) / 2) + 30;

            }

        } else {

            if (cLayout == 'queen') {
                turnWidth = allWidth;

            } else {
                turnWidth = leftWidth;

            }
        }

        // 項目の格納 ////////////////////////////////////////////////////////////
        //leftにはみ出すまでitemをいれる
/*         console.log(`turnWidth: ${turnWidth}`); */

        for(let i: number = 0 ; i < pages.length ; i++) {

            leftWidthCalc += this.widthByCharacter(pages[i].label, w.itemMargin, characterWidth);

            //console.log(`${pages[i].label} : ${leftWidthCalc}`);

            //はみだしたら　はみだしたところのアイテム番号を保存 | turnNumber
            if (leftWidthCalc > turnWidth) {

                //はみ出した分を引く
                leftWidthCalc -= this.widthByCharacter(pages[i].label, w.itemMargin, characterWidth);

                //折り返しの配列番号を保存
                turn = (i);

                //console.log(`turn: ${turn} | addedWidth: ${addedWidth}`);

                //right にはみだすまでitemをいれる
                last = 20;

                for(let h: number = i ; h < pages.length ; h++) {

                    rightWidthCalc += this.widthByCharacter(pages[h].label, w.itemMargin, characterWidth);
                    //console.log(`${pages[h].label} : ${rightWidthCalc}`);

                    //はみだしたら　はみだしたところのアイテムから以降は入れない
                    if (rightWidthCalc > rightWidth) {
                        rightWidthCalc -= this.widthByCharacter(pages[h].label, w.itemMargin, characterWidth);
                        last = h;
                        break;
                    }
                }

                break;
            }

            //はみださなかったらすべていれる
            if (i == pages.length - 1) {
                turn = pages.length;
                last = 20;
            }
        }

        // 左側より右側のほうが長くなってしまっていたら、左側に移すか、消す ///////////////////////////
        rightWidthCalc += w.snsWidth + w.linkWidth + w.itemMargin;

        if (rightWidthCalc + 30 > leftWidthCalc) {
/*             console.log('右のほうがながい'); */
            last -= 1;
        }

//         console.log(`
//             navContainerWidth: ${navContainerWidth}
//             w.logoWidth: ${w.logoWidth}
//             w.itemMargin: ${w.itemMargin}
//             w.snsWidth: ${w.snsWidth}
//             w.linkWidth: ${w.linkWidth}
//             leftWidth: ${leftWidth}
//             rightWidth: ${rightWidth}
//             characterWidth: ${characterWidth}
//             pagesWidth: ${pagesWidth}
//             turnWidth: ${turnWidth}
//             leftWidthCalc: ${leftWidthCalc}
//             rightWidthCalc: ${rightWidthCalc}
//             turn: ${turn}
//             last: ${last}
//         `);

        //項目を左と右の配列にいれていく
        //左側をいれる
        for (let i = 0 ; i < turn ; i++) {

            if (!pages[i]) {break}
//             primary = primary.concat([pages[i]]);
            primary.push(pages[i]);
        }

        //右をいれる
        for (let i = turn ; i < last ; i++) {

            if (!pages[i]) {break}

//             secondary = secondary.concat([pages[i]]);
            secondary.push(pages[i]);
        }

        return {
            primary: primary,
            secondary: secondary,
        };

    }

    /*
        1文字分のwidthを取得する
    */
    public characterWidth(ary: any): any {

        let w: number = 1;

        for( let i = 0 ; i < ary.length ; i++){
            if( $('.c-nav-' + ary[i].id).length > 0 ) {
                w = $('.c-nav-' + ary[i].id).outerWidth() / ary[i].label.length;
//                 console.log(`
//                     el: ${ary[i].id}
//                     length: ${ary[i].id.length}
//                     w: ${w}
//                 `);
                return w;
            }
        }

        return w;
    }

    /*
        配列内の項目の指定番号から指定番号までのwidth合計
    */
    public widthByAry(
        ary: any,
        margin: number,
        character_w: number,
        start: number = 0,
        end: number = -1,
    ): any {

        let w: number = 0;

        if (end == -1) {
            end = ary.length;
        }

        for ( let i = start ; i < end ; i++) {
            w += this.widthByCharacter(ary[i].label, margin, character_w);
        }

        return w;
    }

    public setBlocksWidth(ary: any, margin: number, character_w: number): any {

        let w: number = 0;

        for( let i = 0 ; i < ary.length ; i++){
            ary[i].w = this.widthByCharacter(ary[i].label, margin, character_w);
            w += ary[i].w;
        }

        return w;
    }

    // Item width
    public widthByCharacter(name: string, margin: number, character_w: number): any {
        return (name.length * character_w) + margin;
    }

    public popArray(from: any, count: number): any {

        for(let i = 0 ; i < count ; i++) {
            from.pop();
        }

        return from;

    }

	public appendTo(from: any, to: any): any {

        if (!from || !to) {
    		//console.log('Error c-header-mix.ts');
            return;
        }

        for(let i: number = 0 ; i < from.length ; i++ ){
            to.push(from[i]);
        }

		return to;
	}

	public prependTo(from: any, to: any): any {

		if (!from || !to) {
    		//console.log('Error c-header-mix.ts');
            return;
		}

		const vfrom: any = from.reverse();

        for(let i: number = 0 ; i < from.length ; i++ ){
            to.unshift(vfrom[i]);
        }

		return to;
	}

    /*
        抽出
    */
    public filterByKey(ary: any, key: string, value: string): any {

        if (!ary) { return }

        return ary.filter( function(element, index, array) {
            return element.type == value && element.use == true && element.global == true;
        });
    }

    /*
        並び替え
    */
    public sortByKey(value: any, key: string): any {

        if (!value) { return }

        value.sort((a,b) => {
            if (a[key] < b[key]) return -1;
            if (a[key] > b[key]) return 1;
            return 0;
        });

        return value;
    }

}
