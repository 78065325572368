import {Component, Watch, Prop, Vue} from "vue-property-decorator";
import {themeMainModule} from '@/store/theme/theme-main';
import CPatternMix from '@/classes/theme/mixin/c-pattern-mix';

@Component({
    mixins: [CPatternMix],
})
export default class CPageIndexIndexStandard extends Vue {

    public get blocks(): any {
        return themeMainModule.homeBlock;
    }
}

