import {
    ApiExecuterAsync,
    ApiExecuterFactory,
    ApiMethod,
    ApiUtil
} from '@/classes/core/api/import';
import {vsprintf} from 'sprintf-js';

export default class MaterialIndex {

    public index(driver: string, word: string = '', page: number = 1): ApiExecuterAsync {
        let url = ApiUtil.url(vsprintf('/material/%s', [driver]));
        let request = ApiExecuterFactory.createRequest({
            method: ApiMethod.Get,
            url: url,
            param: {
                id: driver,
                word: word,
                p: page,
            }
        });

        return ApiExecuterFactory.createAsync(request);
    }
}
