/*

	YH: Data Spread Sheet is Here
https://docs.google.com/spreadsheets/d/1sBa8xBr9f4YPZ99MQX6SixId22JZ-KrmycufhlWcmfI/edit#gid=1642380410
*/

export const ColorSidecover = [

{
	id: 'white',
	data: [
	{
	id: 'green',
	label: 'Green',
	txt: 'bk',
	bg: '#E1F1F2',
}, {
	id: 'pink',
	label: 'Pink',
	txt: 'bk',
	bg: '#FCEBEC',
}, {
	id: 'silver',
	label: 'Silver',
	txt: 'bk',
	bg: '#E1E6E8',
}, {
	id: 'beige',
	label: 'Beige',
	txt: 'bk',
	bg: '#E6D7C1',
}, {
	id: 'ball',
	label: 'Ball',
	txt: 'bk',
	bg: '#CCAE93',
}, {
	id: 'conclete',
	label: 'Conclete',
	txt: 'bk',
	bg: '#AFB1B5',
}, {
	id: 'gold',
	label: 'Gold',
	txt: 'bk',
	bg: '#C2AA95',
}, {
	id: 'conclete-milk',
	label: 'Conclete & Milk',
	txt: 'wh',
	bg: '#A0A3A3',
}, {
	id: 'black',
	label: 'Black',
	txt: 'wh',
	bg: '#292B30',
}, {
	id: 'white',
	label: 'White',
	txt: 'bk',
	bg: '#F5F5F5',
}, {
	id: 'orange',
	label: 'Orange',
	txt: 'bk',
	bg: '#F26849',
}, {
	id: 'green',
	label: 'Green',
	txt: 'bk',
	bg: '#74A182',
}, {
	id: 'red',
	label: 'Red',
	txt: 'bk',
	bg: '#FC5B5B',
}, {
	id: 'yellow',
	label: 'Yellow',
	txt: 'bk',
	bg: '#F7E75B',
},
],
}, 	{
	id: 'gray',
	data: [
	{
	id: 'green',
	label: 'Green',
	txt: 'bk',
	bg: '#C1D1D1',
}, {
	id: 'pink',
	label: 'Pink',
	txt: 'bk',
	bg: '#EBD6D6',
}, {
	id: 'silver',
	label: 'Silver',
	txt: 'bk',
	bg: '#CFD1CF',
}, {
	id: 'beige',
	label: 'Beige',
	txt: 'bk',
	bg: '#D6CFC7',
}, {
	id: 'ball',
	label: 'Ball',
	txt: 'bk',
	bg: '#D4D1CE',
}, {
	id: 'conclete',
	label: 'Conclete',
	txt: 'bk',
	bg: '#C2C2C2',
}, {
	id: 'gold',
	label: 'Gold',
	txt: 'bk',
	bg: '#B59E8B',
}, {
	id: 'conclete-milk',
	label: 'Conclete & Milk',
	txt: 'wh',
	bg: '#919394',
}, {
	id: 'black',
	label: 'Black',
	txt: 'wh',
	bg: '#232936',
}, {
	id: 'white',
	label: 'White',
	txt: 'bk',
	bg: '#E8E8E8',
}, {
	id: 'orange',
	label: 'Orange',
	txt: 'bk',
	bg: '#FA6341',
}, {
	id: 'green',
	label: 'Green',
	txt: 'bk',
	bg: '#77B593',
}, {
	id: 'red',
	label: 'Red',
	txt: 'bk',
	bg: '#F55656',
}, {
	id: 'yellow',
	label: 'Yellow',
	txt: 'bk',
	bg: '#E5F283',
},
],
}, 	{
	id: 'pastel',
	data: [
	{
	id: 'green',
	label: 'Green',
	txt: 'bk',
	bg: '#E1E3E3',
}, {
	id: 'pink',
	label: 'Pink',
	txt: 'bk',
	bg: '#F5DDD7',
}, {
	id: 'silver',
	label: 'Silver',
	txt: 'bk',
	bg: '#DEDEDE',
}, {
	id: 'beige',
	label: 'Beige',
	txt: 'bk',
	bg: '#EDE3DA',
}, {
	id: 'ball',
	label: 'Ball',
	txt: 'bk',
	bg: '#EBE6DB',
}, {
	id: 'conclete',
	label: 'Conclete',
	txt: 'bk',
	bg: '#929596',
}, {
	id: 'gold',
	label: 'Gold',
	txt: 'bk',
	bg: '#9E8770',
}, {
	id: 'conclete-milk',
	label: 'Conclete & Milk',
	txt: 'wh',
	bg: '#A3A3A3',
}, {
	id: 'black',
	label: 'Black',
	txt: 'wh',
	bg: '#181A1A',
}, {
	id: 'white',
	label: 'White',
	txt: 'bk',
	bg: '#F7F7F7',
}, {
	id: 'orange',
	label: 'Orange',
	txt: 'bk',
	bg: '#FA6341',
}, {
	id: 'green',
	label: 'Green',
	txt: 'bk',
	bg: '#78B56D',
}, {
	id: 'red',
	label: 'Red',
	txt: 'bk',
	bg: '#F54545',
}, {
	id: 'yellow',
	label: 'Yellow',
	txt: 'bk',
	bg: '#C7E03B',
},
],
}, 	{
	id: 'photo-black',
	data: [
	{
	id: 'green',
	label: 'Green',
	txt: 'bk',
	bg: '#BBC9C9',
}, {
	id: 'pink',
	label: 'Pink',
	txt: 'bk',
	bg: '#EDD8DC',
}, {
	id: 'silver',
	label: 'Silver',
	txt: 'bk',
	bg: '#C6CAD1',
}, {
	id: 'beige',
	label: 'Beige',
	txt: 'bk',
	bg: '#D1CAC2',
}, {
	id: 'ball',
	label: 'Ball',
	txt: 'bk',
	bg: '#D6D4D2',
}, {
	id: 'conclete',
	label: 'Conclete',
	txt: 'bk',
	bg: '#A3A3A3',
}, {
	id: 'gold',
	label: 'Gold',
	txt: 'bk',
	bg: '#A18671',
}, {
	id: 'conclete-milk',
	label: 'Conclete & Milk',
	txt: 'wh',
	bg: '#858585',
}, {
	id: 'black',
	label: 'Black',
	txt: 'wh',
	bg: '#141414',
}, {
	id: 'white',
	label: 'White',
	txt: 'bk',
	bg: '#F7F7F7',
}, {
	id: 'orange',
	label: 'Orange',
	txt: 'bk',
	bg: '#FA6341',
}, {
	id: 'green',
	label: 'Green',
	txt: 'bk',
	bg: '#78B56D',
}, {
	id: 'red',
	label: 'Red',
	txt: 'bk',
	bg: '#F54545',
}, {
	id: 'yellow',
	label: 'Yellow',
	txt: 'bk',
	bg: '#C7E03B',
},
],
}, 	{
	id: 'off-white',
	data: [
	{
	id: 'green',
	label: 'Green',
	txt: 'bk',
	bg: '#D3E3E3',
}, {
	id: 'pink',
	label: 'Pink',
	txt: 'bk',
	bg: '#F5EBEB',
}, {
	id: 'silver',
	label: 'Silver',
	txt: 'bk',
	bg: '#E3E8E8',
}, {
	id: 'beige',
	label: 'Beige',
	txt: 'bk',
	bg: '#E8E0D5',
}, {
	id: 'ball',
	label: 'Ball',
	txt: 'bk',
	bg: '#EDE7DF',
}, {
	id: 'conclete',
	label: 'Conclete',
	txt: 'bk',
	bg: '#A8AFB5',
}, {
	id: 'gold',
	label: 'Gold',
	txt: 'bk',
	bg: '#9E8C7D',
}, {
	id: 'conclete-milk',
	label: 'Conclete & Milk',
	txt: 'wh',
	bg: '#808A88',
}, {
	id: 'black',
	label: 'Black',
	txt: 'wh',
	bg: '#3C4040',
}, {
	id: 'white',
	label: 'White',
	txt: 'bk',
	bg: '#FAFAFA',
}, {
	id: 'orange',
	label: 'Orange',
	txt: 'bk',
	bg: '#FF774C',
}, {
	id: 'green',
	label: 'Green',
	txt: 'bk',
	bg: '#7DB578',
}, {
	id: 'red',
	label: 'Red',
	txt: 'bk',
	bg: '#F54D4C',
}, {
	id: 'yellow',
	label: 'Yellow',
	txt: 'bk',
	bg: '#F7F772',
},
],
}, 	{
	id: 'moss-black',
	data: [
	{
	id: 'green',
	label: 'Green',
	txt: 'bk',
	bg: '#BACFDE',
}, {
	id: 'pink',
	label: 'Pink',
	txt: 'bk',
	bg: '#E3D8D8',
}, {
	id: 'silver',
	label: 'Silver',
	txt: 'bk',
	bg: '#D0DBDB',
}, {
	id: 'beige',
	label: 'Beige',
	txt: 'bk',
	bg: '#F5E8DF',
}, {
	id: 'ball',
	label: 'Ball',
	txt: 'bk',
	bg: '#EBE3D9',
}, {
	id: 'conclete',
	label: 'Conclete',
	txt: 'bk',
	bg: '#96A6B3',
}, {
	id: 'gold',
	label: 'Gold',
	txt: 'bk',
	bg: '#8C7670',
}, {
	id: 'conclete-milk',
	label: 'Conclete & Milk',
	txt: 'wh',
	bg: '#737373',
}, {
	id: 'black',
	label: 'Black',
	txt: 'wh',
	bg: '#1F1F1F',
}, {
	id: 'white',
	label: 'White',
	txt: 'bk',
	bg: '#F2F2F2',
}, {
	id: 'orange',
	label: 'Orange',
	txt: 'bk',
	bg: '#FA5846',
}, {
	id: 'green',
	label: 'Green',
	txt: 'bk',
	bg: '#65A178',
}, {
	id: 'red',
	label: 'Red',
	txt: 'bk',
	bg: '#F05454',
}, {
	id: 'yellow',
	label: 'Yellow',
	txt: 'bk',
	bg: '#F7CD4D',
},
],
}, 	{
	id: 'avantgarde',
	data: [
	{
	id: 'green',
	label: 'Green',
	txt: 'bk',
	bg: '#C9F1FF',
}, {
	id: 'pink',
	label: 'Pink',
	txt: 'bk',
	bg: '#FFE8EF',
}, {
	id: 'silver',
	label: 'Silver',
	txt: 'bk',
	bg: '#BAEAFF',
}, {
	id: 'beige',
	label: 'Beige',
	txt: 'bk',
	bg: '#FFD4DB',
}, {
	id: 'ball',
	label: 'Ball',
	txt: 'bk',
	bg: '#D4C8C1',
}, {
	id: 'conclete',
	label: 'Conclete',
	txt: 'bk',
	bg: '#C7C7C7',
}, {
	id: 'gold',
	label: 'Gold',
	txt: 'bk',
	bg: '#B3957C',
}, {
	id: 'conclete-milk',
	label: 'Conclete & Milk',
	txt: 'wh',
	bg: '#8A8A8A',
}, {
	id: 'black',
	label: 'Black',
	txt: 'wh',
	bg: '#121212',
}, {
	id: 'white',
	label: 'White',
	txt: 'bk',
	bg: '#FFFFFF',
}, {
	id: 'orange',
	label: 'Orange',
	txt: 'bk',
	bg: '#FF5242',
}, {
	id: 'green',
	label: 'Green',
	txt: 'bk',
	bg: '#59C7AF',
}, {
	id: 'red',
	label: 'Red',
	txt: 'bk',
	bg: '#FF4A4A',
}, {
	id: 'yellow',
	label: 'Yellow',
	txt: 'bk',
	bg: '#D6ED93',
},
],
},




];
