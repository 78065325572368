import {Component, Watch, Prop, Vue} from '@/node_modules/nuxt-property-decorator';
import APopup from "@/classes/page/a-popup";
import {tourModule} from '@/store/tour';

@Component({
    components: {
    }
})
export default class MPopupWelcome extends APopup {


	public startTour(name: string, stepNumber: number) {

		/*
			manager/project/ だったら
		*/
		if(this.$nuxt.$route.name == name){
			tourModule.updateName(name);
			tourModule.updateStepNumber(stepNumber);
			tourModule.updateActive(true);
	        this.$emit('close');

		/*
			manager/project/ じゃなかったら
		*/
		}else{
			this.$router.push('/manager/project/');
	        setTimeout(
	            () => {
				tourModule.updateName(name);
				tourModule.updateStepNumber(stepNumber);
				tourModule.updateActive(true);
		        this.$emit('close');
			}, 300);
		}

	}


	public onClose(to: string=''): any {

		if(to != ''){
			this.$router.push(to);
		}
        this.$emit('close');
	}


}
