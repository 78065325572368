










	// @ts-ignore
    import _ from './CArticlePrevnext.ts'; export default _;
