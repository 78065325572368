












































// @ts-ignore
import _ from './MPopupPageLink.ts';

export default _;
