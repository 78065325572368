import {Mutation, MutationAction, Action, VuexModule, getModule, Module} from 'vuex-module-decorators';
import store from '@/classes/core/store';

// state's interface
export interface INoticeModule {
    mode: string;
    run: boolean;
}

@Module({dynamic: true, store, name: 'sort', namespaced: true})
class Notice extends VuexModule implements INoticeModule {
	
	/*
		Mode
		- sort : 並び替えと削除
		- delete : 削除のみ
	*/
    public mode: string = 'delete';
    
    //Toggle Switch
    public run: boolean = false;
    
    @Mutation
    public updateMode(value: string) {
        this.mode = value;
    }

    @Mutation
    public updateRun(value: boolean) {
        this.run = value;
    }
}

export const sortModule = getModule(Notice);

