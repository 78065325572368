
































































	// @ts-ignore
    import _ from './AccountIndex.ts'; export default _;
