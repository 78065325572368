import {Component, Watch, Prop, Vue} from '@/node_modules/nuxt-property-decorator';
import * as $ from 'jquery';

@Component
export default class MPolicyFrame extends Vue {

    public active: boolean = false;

    public onClose() {

        this.active = false;

    }

}
