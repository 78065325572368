import {Component, Watch, Prop, Vue} from '@/node_modules/nuxt-property-decorator';
import APopup from '@/classes/page/a-popup';

import MPopupMBody from '@/components/popup/module/MPopupMBody.vue';
import $ from 'jquery';
import {SystemConfig} from "@/configs/system";
import {IUploadimgState, uploadimgModule} from "@/store/uploadimg";
import {ExtUploadImg} from "@/classes/ext/ext-upload-img";

@Component({
    components: {
        MPopupMBody
    }
})
export default class MPopupUploadImgDrag extends APopup {

    public extUploadImg: ExtUploadImg;
    public state = {
        config: {
            sizeLimit: SystemConfig.uploadimg.sizeLimit,
            quality: SystemConfig.uploadimg.quality,
        },
        isDrag: false,
        input: {
            files: null as File[],
        }
    };

    public created() {
        this.extUploadImg = new ExtUploadImg(this);
    }

    // computed //////////////////////////////////////////////////////
    public get config(): any {
        return this.state.config;
    }

    public get rInput(): HTMLInputElement {
        return this.$refs!['input'] as HTMLInputElement;
    }

    //ドラッグ中
    public get isDrag(): any {
        return this.state.isDrag;
    }

    public get imgState(): IUploadimgState {
        return uploadimgModule.uploadimgs.findByKey('id', uploadimgModule.current);
    }

    // Methods //////////////////////////////////////////////////////
    public async execProcessImage(file: File) {
        await this.extUploadImg.processImage(file, this.config, this.imgState);
        this.$parent.$emit('close');
    }

    // Events //////////////////////////////////////////////////////
    public onClickFileSelector() {
        $(this.rInput).trigger('click');
    }

    public async onChangeFile(e: any) {
        const file = e.target.files[0];
        await this.execProcessImage(file);
    }

    public onDragEnter() {
        this.state.isDrag = true;
    }

    public onDragLeave() {
        console.log('onDragLeave');
        this.state.isDrag = false;
    }

    public async onDrop(e: any) {
        await this.execProcessImage(e.dataTransfer.files[0]);
    }

    ////////// POPUP LAYER 共通処理 /////////////////////////////////
    public onClose(to: string = ''): any {
        this.$emit('close');
    }
}
