import {AExt} from "@/classes/ext/a-ext";
import {IProdWizardInput, prodWizardModule} from "@/store/prod/wizard";
import {appLoadingModule} from "@/store/app/loading";

export class ExtLoading extends AExt {

    public get isLoading(): boolean {
        return appLoadingModule.loading;
    }

    public async load(proc: () => Promise<void>): Promise<any> {
        return await appLoadingModule.load(proc);
    }
}
