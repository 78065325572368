


















	// @ts-ignore
    import _ from './MSelectUploadimg.ts'; export default _;
