import {Mutation, MutationAction, Action, VuexModule, getModule, Module} from 'vuex-module-decorators';
import store from '@/classes/core/store';

export interface IAppAuth {
    token: string;
    user: any;
    owner: any;
    site: any;
}

export interface IAppWizard {
    transId: string;
}

// state's interface
export interface IAppSessionStore {
    auth: IAppAuth;
    wizard: IAppWizard;
}

@Module({dynamic: true, store, name: 'appSession', namespaced: true})
class Store extends VuexModule implements IAppSessionStore {

    public auth: IAppAuth = {
        token: '',
        user: null,
        owner: null,
        site: null,
    };

    public wizard: IAppWizard = {
        transId: '',
    };

    @Mutation
    public updateAuth(value: IAppAuth) {
        this.auth = value;
    }

    @Mutation
    public updateWizardTransId(transId: string = '') {
        this.wizard = {
            transId
        };
    }
}

export const appSessionModule = getModule(Store);
