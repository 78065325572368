import {Action, getModule, Module, Mutation, VuexModule} from "@/node_modules/vuex-module-decorators";
import store from "@/classes/core/store";
import ManagerApi from "@/classes/app/api/manager-api";
import {$v} from "@/classes/utils/var-util";

export interface IManagerSiteModule {

}

@Module({dynamic: true, store, name: 'managerSite', namespaced: true})
class Store extends VuexModule implements IManagerSiteModule {
    @Action
    public async save(param: {
        id: string,
        param: any,
    }): Promise<boolean> {
        try {
            const res = await ManagerApi.OwnerSite
                .put(param.id, {
                    update: $v.p(param, 'param.update'),
                    content: JSON.stringify($v.p(param, 'param.content')),
                })
                .execAsync();
            return true;
        } catch (e) {
            console.error($v.p(e, 'response.data'));
            return false;
        }
    }
}

export const managerSiteModule = getModule(Store);

const M = managerSiteModule;
