/*

	YH: Data Spread Sheet is Here
	https://docs.google.com/spreadsheets/d/1GP-wc5RwVL54UcYqxjH_ZfCQWY7IAfuL_BSC2SXCL8s/edit?usp=sharing

*/

export const DummyStaff = [

    {
    	id: '1',
    	date: '2018.06.12',
    	state: 'draft',
    	title: '菅原 京子',
    	title_en: 'Kyoko Sugawara1',
    	subtitle: 'Director',
    	body: 'こんにちわ! はじめまして! 石の上にも三年をモットーに、皆様にお会いできることを楽しみにしています!',
    	category: ['1'],
    	img: [],
    	sns: [{
     id: 'facebook',
     name: 'Facebook',
     link: 'https://facebook.com'
    },{
     id: 'instagram',
     name: 'Instagram',
     link: 'https://instagram.com'
    }
    ],
    	website: 'http://aiueo.com',
    },
    {
    	id: '2',
    	date: '2019.04.07',
    	state: '',
    	title: '小室 奈々',
    	title_en: 'Nana Komuro2',
    	subtitle: 'Designer',
    	body: 'こんにちわ! はじめまして! 石の上にも三年をモットーに、皆様にお会いできることを楽しみにしています!',
    	category: ['1'],
    	img: ['/templates/hair/store/1-s.jpg'],
    	sns: [{
     id: 'facebook',
     name: 'Facebook',
     link: 'https://facebook.com'
    },{
     id: 'instagram',
     name: 'Instagram',
     link: 'https://instagram.com'
    }
    ],
    	website: '',
    },
    {
    	id: '3',
    	date: '2019.04.07',
    	state: '',
    	title: '小室 奈々',
    	title_en: 'Nana Komuro2',
    	subtitle: 'Designer',
    	body: 'こんにちわ! はじめまして! 石の上にも三年をモットーに、皆様にお会いできることを楽しみにしています!',
    	category: ['1'],
    	img: ['/templates/hair/staff/2-s.jpg'],
    	sns: [{
     id: 'facebook',
     name: 'Facebook',
     link: 'https://facebook.com'
    },{
     id: 'instagram',
     name: 'Instagram',
     link: 'https://instagram.com'
    }
    ],
    	website: '',
    },
    {
    	id: '4',
    	date: '2019.04.07',
    	state: '',
    	title: '小室 奈々',
    	title_en: 'Nana Komuro2',
    	subtitle: 'Designer',
    	body: 'こんにちわ! はじめまして! 石の上にも三年をモットーに、皆様にお会いできることを楽しみにしています!',
    	category: ['1'],
    	img: ['/templates/hair/staff/4-s.jpg'],
    	sns: [{
     id: 'facebook',
     name: 'Facebook',
     link: 'https://facebook.com'
    },{
     id: 'instagram',
     name: 'Instagram',
     link: 'https://instagram.com'
    }
    ],
    	website: '',
    },
    {
    	id: '5',
    	date: '2019.04.07',
    	state: '',
    	title: '小室 奈々',
    	title_en: 'Nana Komuro2',
    	subtitle: 'Designer',
    	body: 'こんにちわ! はじめまして! 石の上にも三年をモットーに、皆様にお会いできることを楽しみにしています!',
    	category: ['1'],
    	img: ['/templates/hair/staff/5-s.jpg'],
    	sns: [{
     id: 'facebook',
     name: 'Facebook',
     link: 'https://facebook.com'
    },{
     id: 'instagram',
     name: 'Instagram',
     link: 'https://instagram.com'
    }
    ],
    	website: '',
    },
];
