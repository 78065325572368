import {Component, Vue, Prop} from '@/node_modules/nuxt-property-decorator';
import {themeMainModule} from '@/store/theme/theme-main';
import {themeCssvarsModule} from '@/store/theme/theme-cssvars';
import ColorUtil from '@/classes/view/color-util';
import {ColorBase} from '@/configs/color/color-base';

@Component
export default class MSelectColorBase extends Vue {


    public d: any = ColorBase;


    public onClick(value: any) {
        themeCssvarsModule.setBase(value);

    }

    public styleColor(value: string) {

        let color: string = value;

        if (ColorUtil.isHex(value)) {
            color = ColorUtil.hexToRgb(value);
        }

        return {
            'background-color': `rgb(${color})`
        }

    }


   	/////// 共通処理 /////////////////////////////////
    ////////// POPUP LAYER 共通処理 /////////////////////////////////
	public onClose(to: string=''): any {
		if(to != ''){
			this.$router.push(to);
		}
        this.$emit('close');
	}


}
