export interface IAppVar {
    id: string;
    value: any;
    opt?: any;
}

const PK = 'id';
export class AppVar {
    /**
     *
     */
    public static module: any;

    public static get vars(): IAppVar[] {
        return self.module.vars;
    }

    //
    public static make(id: string, value: any = null, opt: any = null): IAppVar {
        return {
            id,
            value,
            opt
        }
    }

    //
    public static find(id: string): IAppVar {
        return self.vars.findByKey(PK, id) || self.make(id);
    }

    public static put(value: IAppVar) {
        self.module.updateVar(value);
    }

    public static remove(id: string) {
        self.module.remove(id);
    }
}

const self = AppVar;
