/*

	YH: Data Spread Sheet is Here
	https://docs.google.com/spreadsheets/d/1GP-wc5RwVL54UcYqxjH_ZfCQWY7IAfuL_BSC2SXCL8s/edit?usp=sharing

*/

export const DummyPageNews =
{
	id: 'news',
	name: 'News',
	type: 'page',
	pattern: {
		index: 'c-page-news-index-standard',
		detail: '',
	},
	aspect: '',
};
