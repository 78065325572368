import VarUtil, {$v} from '@/classes/utils/var-util';
import AVueComponent from '@/classes/page/a-vue-component';
import {tourModule} from '@/store/tour';
import {Pagetype} from '@/configs/pagetype';
import ViewModelUtil from '@/classes/view/view-model-util';
import {IPageState} from '@/classes/view/model/i-page-state';
import {IValidateErrorBag} from '@/classes/validation/validator';
import RoutingUtil from "@/classes/view/routing-util";
import {appModule} from "@/store/app";
import {wizardModule} from "@/store/wizard";

export default class APage extends AVueComponent {

    public mypagetype: string = '';
    public mydivider: string = '';

    // Page local state
    public state: any = ViewModelUtil.createPageState();
    public ready: boolean = false;

    // init //////////////////////////////////////////
    public created() {

        super.created();

        ///////// 全ページ共通 ////////////////////////////////////////////
        this.setPagetype(appModule, this.mypagetype, this.mydivider);

        ///////// WIZARD共通 ////////////////////////////////////////////
        if ($v.isFunction(this!['setup'])) {
            //Step数などのデータを自分とStoreにセット
            this!['setup'](this.$nuxt.$route.name, wizardModule, this);
        }
    }

    // Computed ///////////////////////////////////////
    public get v(): any {
        return this.state!.view;
    }

    public get config(): any {
        return this.state!.config;
    }

    public get form(): any {
        return this.state!.form;
    }

    public get ipt(): any {
        return this.form.input;
    }

    public get errors(): any {
        return this.form.errors;
    }

    public get hasErrors(): boolean {
        return this.errors && this.errors.length > 0;
    }

    // Methods ////////////////////////////////////////////
    public onReady() {
        this.ready = true;
    }

    public pageState(state: any): IPageState | any {
        return ViewModelUtil.createPageState(state);
    }

    public e(elm: string): string[] {

        if (!this.hasErrors) {
            return [];
        }

        const e = this.errors.find((_: IValidateErrorBag) => (_.name === elm)) as IValidateErrorBag;
        return e ? e.messages : [];
    }

    public hasError(elm: string) {
        return (this.e(elm).length > 0);
    }

    public _(ckey: string, def: string = '***'): string {
        return def;
    }

    // 共通 /////////////////////////////////////////////
    public transition(to: any, from: any) {
        return RoutingUtil.transition(to, from);
    }

    // Other //////////////////////////////////////////////
    /*
        m-main-frameにクラスを振ったりするためのページタイプのデータを取得してStoreにセット
    */
    public setPagetype(module: any, pagetype: string, divider: string) {

        //PagetypeをStoreにセット
        if (module.pagetype != pagetype) {

            const pt: any = VarUtil.find(Pagetype, 'pagetype', pagetype);
            if (!pt) {
                return;
            }

            module.updatePagetype(pagetype);
            module.updatePageGroup(pt.group);
            module.updatePageLayout(pt.layout);
            module.updatePageGnav(pt.gnav);
            module.updatePagePrlink(pt.prlink);
            module.updatePageLogo(pt.logo);
            module.updatePageNavbg(pt.navbg);
        }

        //Divider(m-primary-frameの横幅)をStoreにセット
        module.updateDivider(divider);
        module.updateNavBgColor('wh'); //ナビゲーション背景色をリセット
    }

    public startTour(
        module: any,
        name: string,
        stepNumber: number = 0
    ) {

        const tour: any = tourModule.data.findByKey('name', name);

        if (tour && !tour.finish) {
            this.$nextTick(function () {
                tourModule.updateName(name);
                tourModule.updateStepNumber(stepNumber);
                tourModule.updateActive(true);
            });
        }
    }
}






