import {Component, Prop, Vue} from "vue-property-decorator";
import {themeMainModule} from '@/store/theme/theme-main';
import {themeCssvarsModule} from '@/store/theme/theme-cssvars';

@Component({
    components: {
    }
})
export default class CMvContentLogo extends Vue {

    public get scolor(): any {

	    if(!themeCssvarsModule.mvTxt){
		    return
		}
	    return themeCssvarsModule.mvTxt;
    }


    public get nsize(): any {

	    if(!themeMainModule.layout){
		    return;
		}

	    //Mobile
	    if(themeMainModule.deviceSize == 'mb') {
			return themeMainModule.layout.logo.mb.mv.size;
	    }

	    return themeMainModule.layout.logo.pc.mv.size;
    }

    // Logo Size from layout in Brand.ts
    public get logoScaleType(): any {

        if (!themeMainModule.layout) {
            return;
        }

	    //Mobile
	    if(themeMainModule.deviceSize == 'mb') {
			return themeMainModule.layout.logo.mb.mv.scaletype;
	    }

        return themeMainModule.layout.logo.pc.mv.scaletype;
    }


}

