export enum HttpStatus {
    Success = 200,
    Add = 201,
    Error = 400,
    ErrorAuth = 401,
    ErrorNotFound = 404,
    ServerError = 500
}

export enum PjProjectLogo {
    Webfont = 'webfont',
    Img = 'img',
}

export const SystemConfig = {

    dummyHosts: [
        'dummy\.test\.hairdesign\.salon'
    ],

    systemPopups: [
        "error-ie",
        "mes",
        "save",
        "logo-list",
        "wiz-break",
        "account-pay-detail",
        "account-unsubscribe",
        "account-card-change",
        "mail-auth",
        "launch-website",
        "layout-preview",
        "login",
        "domain-connect-check-success",
        "domain-connect-check-fail",
        "domain-moved",
        "account-mail-change-send",
        "upload-img",
        "upload-img-drag",
        "upload-img-qr",
        "upload-img-movie",
        "img-trimming",
        "upload-logo",
        "upload-post",
        "post-copy",
        "store-hours",
        "store-holiday",
        "editor",

        "menu-category",
        "menu-genre",
        "menu-item",

        "delete-confirm",
        "confirm-delete-img",
        "unsplash-about",
        "notice-logo-data",
        "upload-logo-data",
        "confirm-duplicate",
        "article-detail--blog",
        "article-detail--gallery",
        "article-detail--news",

        "web-private",
        "preview-qr",
        "page-private",
        "page-link",
        "welcome",
        "request",
    ],

    // 開発陽、ローカルドメインを変換する
    domainAdapter: [
        {from: 'localhost:3000', to: 'thought-brand.com'},
        {from: 'localhost:3001', to: 'sample.hairdesign.salon'},
    ],

    // プロダクションWEB（それ以外はユーザードメインとして問い合わせ）
    productionHost: [
        'thought-brand\.com$'
    ],
    weekSeparator: '-',
    weeks: [
        {key: 'w1', labelS: '月', label: '月曜'},
        {key: 'w2', labelS: '火', label: '火曜'},
        {key: 'w3', labelS: '水', label: '水曜'},
        {key: 'w4', labelS: '木', label: '木曜'},
        {key: 'w5', labelS: '金', label: '金曜'},
        {key: 'w6', labelS: '土', label: '土曜'},
        {key: 'w0', labelS: '日', label: '日曜'},
        {key: 'w7', labelS: '祝', label: '祝日'},
    ],

    uploadimg: {
        // 規定サイズ（縦横）を超えたらリサイズ処理
        sizeLimit: 4000,
        // Jpegの圧縮レベル(0-1)
        quality: 0.8,
        // 箇所別定義
        // src: 0 -> wizard.transId
        // src: 1 -> site
        purpose: [
            {key: '@', type: 'img', src: 1,},
            {key: 'logo', type: 'logo', src: 1,},
            {key: 'homeConcept', type: 'img', limit: 1, src: 1,},
            {key: 'homeGallery', type: 'img', limit: 1, src: 1,},
            {key: 'homeStaff', type: 'img', limit: 1, src: 1,},
            {key: 'homeStore', type: 'img', limit: 1, src: 1,},
            {key: 'ogimg', type: 'img', limit: 1, src: 1,},
            {key: 'mv', type: 'img', limit: 6, src: 1,},
            {key: 'sns', type: 'img', limit: 1, src: 1,},
            {key: 'staff', type: 'staff', limit: 1, src: 1,},
            {key: 'shop', type: 'img', limit: 3, src: 1,},
            {key: 'wizardLogo', type: 'logo-wizard', limit: 1, src: 0,},
            {key: 'wizardMv', purpose: 'mv', type: 'img', limit: 1, src: 0,},
            {key: 'blogCover', type: 'img', limit: 1, src: 1,},
            {key: 'blogArticle', type: 'img', limit: 20, src: 1,},
        ]
    },
};
