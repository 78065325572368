import AApi from "@/classes/core/api/a-api";
import ApiExecuterAsync from "@/classes/core/api/api-executer-async";
import ApiExecuterFactory from "@/classes/core/api/api-executer-factory";
import {ApiMethod} from "@/classes/core/api/api-method";
import {ApiUtil} from "@/classes/core/api/import";

export class Official extends AApi {

    protected _url = '/api/v1/official/start';

    public start(): ApiExecuterAsync {
        return ApiExecuterFactory.createRequestWithAsync(ApiMethod.Post, this.url, {});
    }

    public show(transId: string, option: any = {}): ApiExecuterAsync {
        const url = this.url + '/' + transId;
        return ApiExecuterFactory.createRequestWithAsync(ApiMethod.Get, url, option);
    }

    public index(): ApiExecuterAsync {
        let url = ApiUtil.url("/official/start");
        let request = ApiExecuterFactory.createRequest({
            method: ApiMethod.Get,
            url: url,
        });

        return ApiExecuterFactory.createAsync(request);
    }

    public store(record: any): ApiExecuterAsync {
        let url = ApiUtil.url("/official/start");
        let request = ApiExecuterFactory.createRequest({
            method: ApiMethod.Post,
            url: url,
            param: record
        });

        return ApiExecuterFactory.createAsync(request);
    }

    // User ///////////////////////////////////////////////////////////
    public storeUser(param: any): ApiExecuterAsync {
        let url = ApiUtil.url("/official/start/user");
        let request = ApiExecuterFactory.createRequest({
            method: ApiMethod.Post,
            url: url,
            param
        });

        return ApiExecuterFactory.createAsync(request);
    }

    public userValid(param: any): ApiExecuterAsync {
        let url = ApiUtil.url("/official/start/user/valid");
        let request = ApiExecuterFactory.createRequest({
            method: ApiMethod.Post,
            url: url,
            param
        });

        return ApiExecuterFactory.createAsync(request);
    }

    public domain(param: any): ApiExecuterAsync {
        let url = ApiUtil.url("/official/start/domain");
        let request = ApiExecuterFactory.createRequest({
            method: ApiMethod.Post,
            url: url,
            param: param
        });

        return ApiExecuterFactory.createAsync(request);
    }

    public domainValid(param: any): ApiExecuterAsync {
        let url = ApiUtil.url("/official/start/domain/valid");
        let request = ApiExecuterFactory.createRequest({
            method: ApiMethod.Post,
            url: url,
            param: param
        });

        return ApiExecuterFactory.createAsync(request);
    }

    public workType(param: any): ApiExecuterAsync {
        let url = ApiUtil.url("/official/start/workType");
        let request = ApiExecuterFactory.createRequest({
            method: ApiMethod.Post,
            url: url,
            param
        });

        return ApiExecuterFactory.createAsync(request);
    }

}
