import {Component, Watch, Prop, Vue} from '@/node_modules/nuxt-property-decorator';
import APopup from "@/classes/page/a-popup";

import MPopupMBody from '@/components/popup/module/MPopupMBody.vue';
import {QrcodeMaker} from "@/classes/vendor/qr/qrcode-maker";
import {IUploadimgState, uploadimgModule} from "@/store/uploadimg";
import {QrRegister} from "@/classes/domain/module/manager/image/uploader/register/qr-register";
import {prodWizardModule} from "@/store/prod/wizard";
import {Env} from "@/classes/app/env";
import WUtil from "@/classes/view/w-util";
import {upfileModule} from "@/store/upfile";
import VarUtil, {$v} from "@/classes/utils/var-util";
import {SystemConfig} from "@/configs/system";
import {ExtUploadImg} from "@/classes/ext/ext-upload-img";
import {IImg} from "@/classes/domain/module/model/i-img";
import {appAuthModule} from '@/store/app/auth';
import {AuthManager} from "@/classes/app/auth-manager";

export const WaitStatus = {
    WAITING: 0,
    UPLOADED: 1,
    E_UP: -1,
    E_LIMIT: -2,
};

@Component({
    components: {
        MPopupMBody
    }
})
export default class MPopupUploadImgQr extends APopup {

    public extUploadImg: ExtUploadImg;
    public state = {
        view: {
            isReady: false,
            isOpen: false,
            timer: {
                countdown: 0,
                check: 0,
            },
            wait: {
                cnt: 60 * 100, // 5sec
                status: 0,
                interval: 3000,
            },
            qr: {
                token: '',
                link: '',
                img: '',
            },
        },
    };


    ////////// init /////////////////////////////////////////////////
    public async created() {
        this.extUploadImg = new ExtUploadImg(this);
    }

    public async mounted() {

        // await prodWizardModule.load();
        console.log('MPopupUploadimgQr.mounted()');
        console.log(' - uploadConfig > ', this.uploadConfig);
        console.log(' - imgState > ', this.imgState);
        console.log(' - transId > ', prodWizardModule.input.transId);
        console.log(' - site > ', this.site);

        const qr = new QrRegister();
        this.qr.token = await qr.request(this.site, this.uploadConfig.purpose);

        if (this.qr.token) {
            await this.makeQR();
        }

        this.countdown();
        this.check();

        this.v.isReady = true;
        this.v.isOpen = true;
    }

    public destroyed() {
        console.log('destorooooy!!');
        this.v.isOpen = false;
        this.clearTimer();
    }

    ////////// Computed //////////////////////////////////////////////
    public get id(): string {
        return uploadimgModule.current;
    }

    public get v(): any {
        return this.state.view;
    }

    public get timer(): any {
        return this.v.timer;
    }

    public get qr(): any {
        return this.v.qr;
    }

    public get isReady(): boolean {
        return this.v.isReady;
    }

    public get wait(): any {
        return this.v.wait;
    }

    public get qrImg(): boolean {
        return this.v.qr.img;
    }

    public get imgState(): IUploadimgState {
        return uploadimgModule.uploadimgs.findByKey('id', this.id);
    }

    public get uploadConfig(): any {
        return SystemConfig.uploadimg.purpose.findByKey('key', this.id);
    }

    public get site(): string {
        return $v.p(AuthManager.auth, 'project'); //this.extUploadImg.getSiteId(this.id);
    }

    public get isStatusWaiting(): boolean {
        return (this.wait.status === WaitStatus.WAITING);
    }

    public get isStatusUploaded(): boolean {
        return (this.wait.status === WaitStatus.UPLOADED);
    }

    public get isStatusError(): boolean {
        return (this.wait.status < 0);
    }

    public get waitStatus(): any {
        return WaitStatus;
    }

    ////////// Method /////////////////////////////////////////////////
    public async makeQR() {
        this.v.qr.link = Env.SITE_URL + '/ex/up/' + this.qr.token;
        this.v.qr.img = await QrcodeMaker.create(this.v.qr.link);
    }

    ///
    public countdown() {

        if (this.wait.cnt <= 1) {
            this.clearTimer();
            this.wait.status = WaitStatus.E_LIMIT;
            return;
        }

        this.wait.cnt--;
        this.timer.countdown = setTimeout(() => {
            this.countdown();
        }, 1000);
    }

    public check() {

        if (this.wait.status !== WaitStatus.WAITING) {
            return;
        }

        upfileModule.check(this.qr.token).then((res: any) => {
            console.log('response is > ', res);

            if (!!VarUtil.path(res, 'file.acc_key')) {
                console.log('check() > success!');
                this.wait.status = WaitStatus.UPLOADED;

                const url = VarUtil.path(res, 'url');
                const nimgs = this.imgState.imgs.map((_: IImg, idx: number) => {
                    if (idx !== this.imgState.pos) {
                        return _;
                    } else {
                        return {
                            src: url,
                        };
                    }
                });

                console.log('nimgs > ', nimgs);

                uploadimgModule.updateUploadImg({
                    id: this.imgState.id,
                    imgs: nimgs,
                });

                upfileModule.regist({
                    id: this.imgState.id,
                    file: url
                });

                this.$parent.$parent.$emit('close');
            } else if (this.v.isOpen) {
                this.timer.check = setTimeout(() => {
                    this.check();
                }, this.wait.interval);
            }
        });
    }

    public clearTimer() {
        console.log('clearTimer');
        Object.keys(this.timer).map((k: string) => {
            if (this.timer[k] > 0) {
                clearTimeout(this.timer[k]);
            }
        });
    }

    ////////// POPUP LAYER 共通処理 /////////////////////////////////
    public onClickQr() {
        // @ts-ignore
        WUtil.browser(() => {
            window.open(this.qr.link);
        });
    }

    public onClose(to: string = ''): any {

        this.clearTimer();

        if (to != '') {
            this.$router.push(to);
        }
        this.$emit('close');
    }
}
