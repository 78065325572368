








































































































	// @ts-ignore
    import _ from './MPopupMenuCategory.ts'; export default _;
