







import {Component, Vue, Prop} from 'nuxt-property-decorator';
import AVueComponent from '@/classes/page/a-vue-component';

@Component({
    components: {
    }
})
export default class MThanks extends AVueComponent {
	
	@Prop({default: '<span class="en">THANK YOU FOR SUBSCRIBING!</span>'})
	public title: string;
	
	@Prop({default: 'THOUGHTにご契約いただきありがとうございます。<br>このサービスを楽しんでつかってもらえるよう、<br>より良い製品にするために研究と改善を続けていきます。<br>ご契約詳細をメールに送信しました。<br>ご契約内容は、契約一覧でご確認できます。'})
	public body: string;
	
}
	
