import {Component, Vue, Prop} from '@/node_modules/nuxt-property-decorator';
import AVueComponent from '@/classes/page/a-vue-component';
import {uploadimgModule} from '@/store/uploadimg';
import {ISelectRequest, selectModule} from '@/store/select';
import {$v} from '@/classes/utils/var-util';
import {MUplodimgUtil} from "@/classes/view/m-uplodimg-util";

@Component({
    components: {}
})
export default class MSelectUploadimg extends AVueComponent {

    @Prop({default: []})
    public d: any;

    @Prop({default: []})
    public icon: any;

    public menuTrimming: boolean = false;
    public menuSort: boolean = false;
    public menuDelete: boolean = false;

    public async mounted() {

        console.log('MSelectUploadimg > mounted() > selectOption', this.selectOption);

        //MUploadimg側の指定したメニューに絞り込む
        this.menuTrimming = MUplodimgUtil.stateMenuTriming($v.p(this.selectOption, 'imgType'));
        this.menuSort = MUplodimgUtil.stateMenuSort($v.num($v.p(this.selectOption, 'limit')));
        this.menuDelete = MUplodimgUtil.stateMenuDelete($v.p(this.selectOption, 'img'));
    }

    public onSort() {
        uploadimgModule.updateModeSort(true);
        selectModule.clearCurrent();
    }

    // Computed ///////////////////////////////////////////////
    public get selectOption(): ISelectRequest {
        return selectModule.option;
    }
}
