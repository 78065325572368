import {Component, Vue} from 'nuxt-property-decorator';
import AVueComponent from '@/classes/page/a-vue-component';

@Component({
    components: {
    }
})
export default class MSecondaryNews extends AVueComponent {

    public get noteTitle(): any {

        const n = this.$nuxt.$route.name;

        if ( n.endsWith('updates')) {
            return 'Product <br>Updates';

        } else if ( n.endsWith('news')) {
            return 'News';

        }
    }

}
