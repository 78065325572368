import {Component, Prop, Vue} from '@/node_modules/nuxt-property-decorator';

import MSortListBtn from '@/components/module/MSortListBtn.vue';

@Component({
    components: {
        MSortListBtn,
    }
})
export default class MTxtbtn extends Vue {

    /////// BASE SETTING ///////////////////////////////

    //m-icon-の後ろの名前
    @Prop({default: 'close'})
    public i: string;

    //Text
    @Prop({default: ''})
    public t: string;


    /////// COMPONENTS ///////////////////////////////
    /*
	    Div
	    NuxtLink
	    A
	    MSelect
	    MSortListBtn
	    MTips
    */

    //Selecter ID
    @Prop({default: ''})
    public select: string;

    //Link
    @Prop({default: ''})
    public to: string;

    /*
	    MSortListBtn
	    sort,
	    delete
    */
    @Prop({default: ''})
    public sort: string;

    //Tips ID
    @Prop({default: ''})
    public tips: string;

    //A href
    @Prop({default: ''})
    public href: string;


    /////// STYLE ///////////////////////////////

    //サイズ
    @Prop({default: ''})
    public size: string;

    //Icon to Back
    @Prop({default: false})
    public back: boolean;

    //Selecter Down Arrow
    @Prop({default: true})
    public arrow: boolean;

    //Link Border
    @Prop({default: false})
    public linkborder: boolean;


    /*
	    select : MSelect
	    to: NuxtLink
	    sort : MSortListBtn
    */
    public get ocomponent(): any {

        if (this.select) {
            return 'm-select';
        } else if (this.to) {
            return 'nuxt-link';
        } else if (this.sort) {
            return 'm-sort-list-btn';
        } else if (this.href) {
            return 'a';
        }
        return 'div';
    }

    //アイコン名
    public get oi(): any {
        return this.i;
    }

    //ラベル名
    public get ot(): any {
        return this.t;
    }

    //アイコンサイズ
    public get osize(): any {
        return this.size;
    }

    /*
	    Tips IDがあったらMTips
    */
    public get isTips(): any {

        if (this.tips) {
            return 'm-tips';
        }
        return 'div';
    }

    //Tips IDがあったらTipsにする
    public get otips(): any {

        if (this.tips) {
            return this.tips;
        }
        return;
    }

    //Selectがあったらm-selectにする
    public get oselect(): any {

        if (this.select) {
            return this.select;
        }
        return;
    }

    //MSortBtnのProp
    public get osort(): any {

        if (this.sort) {
            return this.sort;
        }
        return;
    }

    //Is MSelect Component
    public get isSelect(): any {

        if (this.select) {
            return true;
        }
        return false;
    }

    //Get NuxtLink to Prop
    public get oto(): any {

        if (this.to) {
            return this.to;
        }
        return;
    }

    //通常の外部リンク
    public get ohref(): any {

        if (this.href) {
            if (this.href === '-') {
                return 'javascript:void(0)';
            } else {
                return this.href;
            }
        }
        return;
    }

    public get otarget(): any {

        if (this.href) {
            if (this.href === '-') {
                return null;
            } else {
                return '_blank';
            }
        }
        return;
    }

    public onChange(e: any) {
        this.$emit('change', e);
    }

    public async onClick(e: MouseEvent) {
        this.$emit('click', e);
    }
}
