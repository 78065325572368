



























































	// @ts-ignore
    import _ from './CPageGalleryDetailStandard.ts'; export default _;
