
















































	// @ts-ignore
    import _ from './MSecondaryPlanSubscribe.ts'; export default _;
