import {Component, Watch, Prop, Vue} from '@/node_modules/nuxt-property-decorator';
import ViewPosMix from '@/classes/mixin/view-pos-mix';
import * as $ from 'jquery';
import {themeStateModule} from "@/store/theme/theme-state";

import CSelectSnsList from '@/components/theme/module/select/CSelectSnsList.vue';


//import MSelectInput from '@/components/select/MSelectInput.vue';


@Component({
    mixins: [ViewPosMix],
    components: {
        CSelectSnsList,
    }
})
export default class CSelectFrame extends Vue {

    @Prop({default: ''})
    public target: string | null;

    @Watch('select')
    public watchSelect() {
        this.setStyles();
    }

    public styles: any = {};
    public styleArrow: any = {};
    public component: string = '';
    public sizeclass: string = '';
    public isNoArrow: boolean = false;

    public get oconponent(): any {
        return this.component;
    }

    public get select(): any {
        return themeStateModule.select;
    }

    public get hasCurrent(): boolean {
        return !!this.select;
    }

    public onClose() {
        //console.log('MSelectFrame onClose');
        themeStateModule.clearSelect();
    }

    public setStyles() {

        if (this.select) {

            console.log(this.select);

            const pos = this.select.pos;
            const size = this.select.size;

            //Select 描画後に計算
            this.$nextTick(() => {

                const myw = this.select.sizeclass ? $(this.$refs.wrapper).outerWidth() : size.w;
                const myh = $(this.$refs.wrapper).outerHeight();
                const ww = $(window).width();
                const wh = $(window).height();

                //座標
                const p: any = this!['posPop'](pos, size, myw, myh, ww, wh, this.select.cpos, this);

                //サイズと配置
                if (this.select.sizeclass) {

                    //Size Class 指定あり
                    this.sizeclass = 'm-pop--' + this.select.sizeclass;

                    this.styles = {
                        top: `${p.top}px`,
                        left: `${p.left}px`
                    }

                } else {

                    //Size Class 指定なし
                    this.sizeclass = '';

                    this.styles = {
                        top: `${p.top}px`,
                        left: `${p.left}px`,
                        width: `${size.w}px`
                    }
                }
            });
        }
    }
}
