import {Component, Vue} from 'nuxt-property-decorator';
import {themeMainModule} from '@/store/theme/theme-main';
import CPatternMix from '@/classes/theme/mixin/c-pattern-mix';

@Component({
	mixins: [CPatternMix],
    components: {
    }
})
export default class CPageContactFinishStandard extends Vue {

}
