import { render, staticRenderFns } from "./CSidecoverAddress.vue?vue&type=template&id=b97f5346&"
import script from "./CSidecoverAddress.vue?vue&type=script&lang=ts&"
export * from "./CSidecoverAddress.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports