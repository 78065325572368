/*

	YH: Data Spread Sheet is Here
	https://docs.google.com/spreadsheets/d/1GP-wc5RwVL54UcYqxjH_ZfCQWY7IAfuL_BSC2SXCL8s/edit?usp=sharing

*/

export const DummyBlog = [

    {
        id: 1,
        date: '2018.06.12',
        state: 'draft',
        title: '醜く歪んだ「銃弾の破片」に、その強大な破壊力を見た ── 写真家がとらえた12の造形',
        body: '2015年に入り、ファイザーの炎症・免疫学部門の研究者らは、医療保険の請求に関するデータベースから、エンブレルがアルツハイマー病のリスクを大きく減少させる可能性があることを発見しました。具体的には、関節炎などの治療のためにエンブレルを服用していた患者は、そうでない患者に比べてアルツハイマー病の診断を受ける割合が64％も少なかったとのこと。ただし、これはあくまで統計的な結果にすぎないため、実際にエンブレルがアルツハイマー病のリスクを低減させるかどうか確かめるためには、さらなる研究が必要でした。しかし、ファイザーはエンブレルの研究を打ち切り、エンブレルがアルツハイマー病のリスクを低減させる可能性があるとの情報も公にしませんでした。そのため、ワシントン・ポストがファイザーの社内資料からこのことを突き止めるまで、エンブレルの隠れた薬効は誰の目にも触れることなく放置されてしまう結果となりました。',
        category: [{
            name: 'Category A',
            link: 'detail'
        }, {
            name: 'Category B',
            link: 'detail'
        }, {
            name: 'Category C',
            link: 'detail'
        },],
        img: ['/dummy-user/pjname/blog/1-s.jpg', '/dummy-user/pjname/blog/2-s.jpg']
    },
    {
        id: 2,
        date: '2019.04.07',
        state: '',
        title: 'iPad上で油絵や水彩画と同じ「塗り重ね」や「色混ざり」を実現するお絵かきツール',
        body: '2015年に入り、ファイザーの炎症・免疫学部門の研究者らは、医療保険の請求に関するデータベースから、エンブレルがアルツハイマー病のリスクを大きく減少させる可能性があることを発見しました。具体的には、関節炎などの治療のためにエンブレルを服用していた患者は、そうでない患者に比べてアルツハイマー病の診断を受ける割合が64％も少なかったとのこと。ただし、これはあくまで統計的な結果にすぎないため、実際にエンブレルがアルツハイマー病のリスクを低減させるかどうか確かめるためには、さらなる研究が必要でした。しかし、ファイザーはエンブレルの研究を打ち切り、エンブレルがアルツハイマー病のリスクを低減させる可能性があるとの情報も公にしませんでした。そのため、ワシントン・ポストがファイザーの社内資料からこのことを突き止めるまで、エンブレルの隠れた薬効は誰の目にも触れることなく放置されてしまう結果となりました。',
        category: [{
            name: 'Category A',
            link: 'detail'
        }, {
            name: 'Category B',
            link: 'detail'
        }, {
            name: 'Category C',
            link: 'detail'
        },],
        img: []
    },
    {
        id: 3,
        date: '2019.04.08',
        state: 'hidden',
        title: 'アルツハイマー病のリスクが3分の1になる薬の研究が闇に葬られた理由とは？',
        body: '2015年に入り、ファイザーの炎症・免疫学部門の研究者らは、医療保険の請求に関するデータベースから、エンブレルがアルツハイマー病のリスクを大きく減少させる可能性があることを発見しました。具体的には、関節炎などの治療のためにエンブレルを服用していた患者は、そうでない患者に比べてアルツハイマー病の診断を受ける割合が64％も少なかったとのこと。ただし、これはあくまで統計的な結果にすぎないため、実際にエンブレルがアルツハイマー病のリスクを低減させるかどうか確かめるためには、さらなる研究が必要でした。しかし、ファイザーはエンブレルの研究を打ち切り、エンブレルがアルツハイマー病のリスクを低減させる可能性があるとの情報も公にしませんでした。そのため、ワシントン・ポストがファイザーの社内資料からこのことを突き止めるまで、エンブレルの隠れた薬効は誰の目にも触れることなく放置されてしまう結果となりました。',
        category: [{
            name: 'Category A',
            link: 'detail'
        }, {
            name: 'Category B',
            link: 'detail'
        }, {
            name: 'Category C',
            link: 'detail'
        },],
        img: ['/dummy-user/pjname/blog/2-s.jpg']
    },
    {
        id: 4,
        date: '2019.04.09',
        state: '',
        title: '人類初の月面到達まで1万1000時間分のアポロ11号の映像と音声をリアルタイムで配信するサイトが登場',
        body: '2015年に入り、ファイザーの炎症・免疫学部門の研究者らは、医療保険の請求に関するデータベースから、エンブレルがアルツハイマー病のリスクを大きく減少させる可能性があることを発見しました。具体的には、関節炎などの治療のためにエンブレルを服用していた患者は、そうでない患者に比べてアルツハイマー病の診断を受ける割合が64％も少なかったとのこと。ただし、これはあくまで統計的な結果にすぎないため、実際にエンブレルがアルツハイマー病のリスクを低減させるかどうか確かめるためには、さらなる研究が必要でした。しかし、ファイザーはエンブレルの研究を打ち切り、エンブレルがアルツハイマー病のリスクを低減させる可能性があるとの情報も公にしませんでした。そのため、ワシントン・ポストがファイザーの社内資料からこのことを突き止めるまで、エンブレルの隠れた薬効は誰の目にも触れることなく放置されてしまう結果となりました。',
        category: [{
            name: 'Category A',
            link: 'detail'
        }, {
            name: 'Category B',
            link: 'detail'
        }, {
            name: 'Category C',
            link: 'detail'
        },],
        img: ['/dummy-user/pjname/blog/3-s.jpg']
    },
    {
        id: 5,
        date: '2019.04.10',
        state: 'draft',
        title: 'エンブレルがアルツハイマー病のリスクを低減',
        body: '2015年に入り、ファイザーの炎症・免疫学部門の研究者らは、医療保険の請求に関するデータベースから、エンブレルがアルツハイマー病のリスクを大きく減少させる可能性があることを発見しました。具体的には、関節炎などの治療のためにエンブレルを服用していた患者は、そうでない患者に比べてアルツハイマー病の診断を受ける割合が64％も少なかったとのこと。ただし、これはあくまで統計的な結果にすぎないため、実際にエンブレルがアルツハイマー病のリスクを低減させるかどうか確かめるためには、さらなる研究が必要でした。しかし、ファイザーはエンブレルの研究を打ち切り、エンブレルがアルツハイマー病のリスクを低減させる可能性があるとの情報も公にしませんでした。そのため、ワシントン・ポストがファイザーの社内資料からこのことを突き止めるまで、エンブレルの隠れた薬効は誰の目にも触れることなく放置されてしまう結果となりました。',
        category: [{
            name: 'Category A',
            link: 'detail'
        }, {
            name: 'Category B',
            link: 'detail'
        }, {
            name: 'Category C',
            link: 'detail'
        },],
        img: []
    },
    {
        id: 6,
        date: '2019.04.11',
        state: '',
        title: '国家の威信をかけた月面到達プロジェクトに挑んだニール・アームストロングの伝記映画',
        body: '2015年に入り、ファイザーの炎症・免疫学部門の研究者らは、医療保険の請求に関するデータベースから、エンブレルがアルツハイマー病のリスクを大きく減少させる可能性があることを発見しました。具体的には、関節炎などの治療のためにエンブレルを服用していた患者は、そうでない患者に比べてアルツハイマー病の診断を受ける割合が64％も少なかったとのこと。ただし、これはあくまで統計的な結果にすぎないため、実際にエンブレルがアルツハイマー病のリスクを低減させるかどうか確かめるためには、さらなる研究が必要でした。しかし、ファイザーはエンブレルの研究を打ち切り、エンブレルがアルツハイマー病のリスクを低減させる可能性があるとの情報も公にしませんでした。そのため、ワシントン・ポストがファイザーの社内資料からこのことを突き止めるまで、エンブレルの隠れた薬効は誰の目にも触れることなく放置されてしまう結果となりました。',
        category: [{
            name: 'Category A',
            link: 'detail'
        }, {
            name: 'Category B',
            link: 'detail'
        }, {
            name: 'Category C',
            link: 'detail'
        },],
        img: ['/dummy-user/pjname/blog/4-s.jpg']
    },
    {
        id: 7,
        date: '2019.04.12',
        state: '',
        title: '醜く歪んだ「銃弾の破片」に、その強大な破壊力を見た ── 写真家がとらえた12の造形',
        body: '2015年に入り、ファイザーの炎症・免疫学部門の研究者らは、医療保険の請求に関するデータベースから、エンブレルがアルツハイマー病のリスクを大きく減少させる可能性があることを発見しました。具体的には、関節炎などの治療のためにエンブレルを服用していた患者は、そうでない患者に比べてアルツハイマー病の診断を受ける割合が64％も少なかったとのこと。ただし、これはあくまで統計的な結果にすぎないため、実際にエンブレルがアルツハイマー病のリスクを低減させるかどうか確かめるためには、さらなる研究が必要でした。しかし、ファイザーはエンブレルの研究を打ち切り、エンブレルがアルツハイマー病のリスクを低減させる可能性があるとの情報も公にしませんでした。そのため、ワシントン・ポストがファイザーの社内資料からこのことを突き止めるまで、エンブレルの隠れた薬効は誰の目にも触れることなく放置されてしまう結果となりました。',
        category: [{
            name: 'Category A',
            link: 'detail'
        }, {
            name: 'Category B',
            link: 'detail'
        }, {
            name: 'Category C',
            link: 'detail'
        },],
        img: ['/dummy-user/pjname/blog/2-s.jpg']
    },
    {
        id: 8,
        date: '2019.04.13',
        state: '',
        title: 'iPad上で油絵や水彩画と同じ「塗り重ね」や「色混ざり」を実現するお絵かきツール',
        body: '2015年に入り、ファイザーの炎症・免疫学部門の研究者らは、医療保険の請求に関するデータベースから、エンブレルがアルツハイマー病のリスクを大きく減少させる可能性があることを発見しました。具体的には、関節炎などの治療のためにエンブレルを服用していた患者は、そうでない患者に比べてアルツハイマー病の診断を受ける割合が64％も少なかったとのこと。ただし、これはあくまで統計的な結果にすぎないため、実際にエンブレルがアルツハイマー病のリスクを低減させるかどうか確かめるためには、さらなる研究が必要でした。しかし、ファイザーはエンブレルの研究を打ち切り、エンブレルがアルツハイマー病のリスクを低減させる可能性があるとの情報も公にしませんでした。そのため、ワシントン・ポストがファイザーの社内資料からこのことを突き止めるまで、エンブレルの隠れた薬効は誰の目にも触れることなく放置されてしまう結果となりました。',
        category: [{
            name: 'Category A',
            link: 'detail'
        }, {
            name: 'Category B',
            link: 'detail'
        }, {
            name: 'Category C',
            link: 'detail'
        },],
        img: ['/dummy-user/pjname/blog/5-s.jpg']
    },
    {
        id: 9,
        date: '2019.04.14',
        state: 'draft',
        title: 'アルツハイマー病のリスクが3分の1になる薬の研究が闇に葬られた理由とは？',
        body: '2015年に入り、ファイザーの炎症・免疫学部門の研究者らは、医療保険の請求に関するデータベースから、エンブレルがアルツハイマー病のリスクを大きく減少させる可能性があることを発見しました。具体的には、関節炎などの治療のためにエンブレルを服用していた患者は、そうでない患者に比べてアルツハイマー病の診断を受ける割合が64％も少なかったとのこと。ただし、これはあくまで統計的な結果にすぎないため、実際にエンブレルがアルツハイマー病のリスクを低減させるかどうか確かめるためには、さらなる研究が必要でした。しかし、ファイザーはエンブレルの研究を打ち切り、エンブレルがアルツハイマー病のリスクを低減させる可能性があるとの情報も公にしませんでした。そのため、ワシントン・ポストがファイザーの社内資料からこのことを突き止めるまで、エンブレルの隠れた薬効は誰の目にも触れることなく放置されてしまう結果となりました。',
        category: [{
            name: 'Category A',
            link: 'detail'
        }, {
            name: 'Category B',
            link: 'detail'
        }, {
            name: 'Category C',
            link: 'detail'
        },],
        img: []
    },
    {
        id: 10,
        date: '2018.06.13',
        state: '',
        title: '人類初の月面到達まで1万1000時間分のアポロ11号の映像と音声をリアルタイムで配信するサイトが登場',
        body: '2015年に入り、ファイザーの炎症・免疫学部門の研究者らは、医療保険の請求に関するデータベースから、エンブレルがアルツハイマー病のリスクを大きく減少させる可能性があることを発見しました。具体的には、関節炎などの治療のためにエンブレルを服用していた患者は、そうでない患者に比べてアルツハイマー病の診断を受ける割合が64％も少なかったとのこと。ただし、これはあくまで統計的な結果にすぎないため、実際にエンブレルがアルツハイマー病のリスクを低減させるかどうか確かめるためには、さらなる研究が必要でした。しかし、ファイザーはエンブレルの研究を打ち切り、エンブレルがアルツハイマー病のリスクを低減させる可能性があるとの情報も公にしませんでした。そのため、ワシントン・ポストがファイザーの社内資料からこのことを突き止めるまで、エンブレルの隠れた薬効は誰の目にも触れることなく放置されてしまう結果となりました。',
        category: [{
            name: 'Category A',
            link: 'detail'
        }, {
            name: 'Category B',
            link: 'detail'
        }, {
            name: 'Category C',
            link: 'detail'
        },],
        img: ['/dummy-user/pjname/blog/3-s.jpg']
    },
    {
        id: 11,
        date: '2019.04.15',
        state: 'hidden',
        title: 'エンブレルがアルツハイマー病のリスクを低減',
        body: '2015年に入り、ファイザーの炎症・免疫学部門の研究者らは、医療保険の請求に関するデータベースから、エンブレルがアルツハイマー病のリスクを大きく減少させる可能性があることを発見しました。具体的には、関節炎などの治療のためにエンブレルを服用していた患者は、そうでない患者に比べてアルツハイマー病の診断を受ける割合が64％も少なかったとのこと。ただし、これはあくまで統計的な結果にすぎないため、実際にエンブレルがアルツハイマー病のリスクを低減させるかどうか確かめるためには、さらなる研究が必要でした。しかし、ファイザーはエンブレルの研究を打ち切り、エンブレルがアルツハイマー病のリスクを低減させる可能性があるとの情報も公にしませんでした。そのため、ワシントン・ポストがファイザーの社内資料からこのことを突き止めるまで、エンブレルの隠れた薬効は誰の目にも触れることなく放置されてしまう結果となりました。',
        category: [{
            name: 'Category A',
            link: 'detail'
        }, {
            name: 'Category B',
            link: 'detail'
        }, {
            name: 'Category C',
            link: 'detail'
        },],
        img: []
    },
    {
        id: 12,
        date: '2019.04.16',
        state: '',
        title: '国家の威信をかけた月面到達プロジェクトに挑んだニール・アームストロングの伝記映画',
        body: '2015年に入り、ファイザーの炎症・免疫学部門の研究者らは、医療保険の請求に関するデータベースから、エンブレルがアルツハイマー病のリスクを大きく減少させる可能性があることを発見しました。具体的には、関節炎などの治療のためにエンブレルを服用していた患者は、そうでない患者に比べてアルツハイマー病の診断を受ける割合が64％も少なかったとのこと。ただし、これはあくまで統計的な結果にすぎないため、実際にエンブレルがアルツハイマー病のリスクを低減させるかどうか確かめるためには、さらなる研究が必要でした。しかし、ファイザーはエンブレルの研究を打ち切り、エンブレルがアルツハイマー病のリスクを低減させる可能性があるとの情報も公にしませんでした。そのため、ワシントン・ポストがファイザーの社内資料からこのことを突き止めるまで、エンブレルの隠れた薬効は誰の目にも触れることなく放置されてしまう結果となりました。',
        category: [{
            name: 'Category A',
            link: 'detail'
        }, {
            name: 'Category B',
            link: 'detail'
        }, {
            name: 'Category C',
            link: 'detail'
        },],
        img: ['/dummy-user/pjname/blog/4-s.jpg']
    },

];
