

























	// @ts-ignore
    import _ from './MInputCentered.ts'; export default _;
