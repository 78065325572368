import {Component, Watch, Prop, Vue} from '@/node_modules/nuxt-property-decorator';
import VarUtil from '@/classes/utils/var-util';
import LogoUtil from '@/classes/view/logo-util';
import {themeMainModule} from '@/store/theme/theme-main';

import {Logos} from "@/configs/logos";
import {LogoSize} from '@/configs/auto-resize/logo-size';

import * as $ from 'jquery';

@Component
export default class SLogo extends Vue {


    public hasName: boolean = false;
    public hasTagline: boolean = false;
    public isLoaded: boolean = false;
    public scaleLogo: number = 1;
    public scaleUpAcc: number = 0.0004;
    public scaleType: any = {};

    public styleLogo: any = {};
    public styleWebFontTransform: any = {};
    public styleWrapper: any = {};

    public vcolor: string = 'txt';


    ///// SETTING //////////////////////////////////
    @Prop({default: '/common/img'})
    public base: string | null;

    // type: webfont / img
    @Prop({default: null})
    public type: string;

    // img src 強制的に指定する
    @Prop({default: null})
    public src: string;

    // webfont Id 強制的に指定する
    @Prop({default: null})
    public webfont: string;

    // color: bg or txt
    @Prop({default: 'txt'})
    public color: string;

    // logoSizes.ts から指定するか free を指定するとフレキシブルになる
//     @Prop({default: 'normal'})
//     public resizetype: string;

    // size
    @Prop({default: null})
    public size: number;

    // to
    @Prop({default: ''})
    public to: string;

    // In Theme? Managerはfalseにする
//     @Prop({default: true})
//     public c: boolean;

    // Location  none, mv
    @Prop({default: 'nav'})
    public locate: string;

    @Prop({default: false})
    public resizeByOuter: boolean;

    @Prop({default: 'normal'})
    public scaleTypeId: string;


    ///// WEB FONT //////////////////////////////////

    @Prop({default: null})
    public name: string;

    @Prop({default: null})
    public tagline: string;

    @Prop({default: true})
    public istagline: boolean;

    @Prop({default: 1})
    public scale: number;
//
//     @Prop({default: 'absolute'})
//     public position: string;
//
//     @Prop({default: 'center'})
//     public justify: string;
//
//     @Prop({default: 'center'})
//     public align: string;

    @Prop({default: true})
    public load: boolean;

    @Prop({default: true})
    public scaling: boolean;


    ///// Test ///////////////////////////////////////////////

    @Prop({default: null})
    public sizeAcc: number;

    @Prop({default: null})
    public ratioAcc: number;

    @Prop({default: null})
    public ratioAccAcc: number;

    @Prop({default: null})
    public webfontAcc: number;

    @Prop({default: null})
    public webfontSize: number;

    @Prop({default: null})
    public webfontAccMin: number;

    @Prop({default: null})
    public webfontAccLength: number;

    @Prop({default: null})
    public webfontTaglineAcc: number;


    @Watch('sizeAcc')
    public watchTestSizeAcc() {
        this.resize();
    }

    @Watch('ratioAcc')
    public watchTestRatioAcc() {
        this.resize();
    }

    @Watch('ratioAccAcc')
    public watchTestRatioAccAcc() {
        this.resize();
    }

    @Watch('webfontAcc')
    public watchTestWebfontAcc() {
        this.resize();
    }

    @Watch('webfontAccMin')
    public watchTestWebfontSizeAcc() {
        this.resize();
    }

    @Watch('webfontAccLength')
    public watchTestWebfontAccLength() {
        this.resize();
    }

    @Watch('webfontSize')
    public watchTestWebfontSize() {
        this.resize();
    }

    @Watch('webfontTaglineAcc')
    public watchTestWebfontTaglineAcc() {
        this.resize();
    }

    ///// Test End ///////////////////////////////////////////////////

    @Watch('size')
    public watchSize() {
        this.resize();
    }

    @Watch('name')
    public watchName() {
        this.resize();
    }

    @Watch('tagline')
    public watchTagline() {
        this.resize();
    }

    @Watch('webfont')
    public watchWebfont() {
        this.resize();
    }

    /*
        BREAK POINTが変わったとき
    */
    @Watch('changeBreakpoint')
    public watchBreakpoint() {
        this.resize();
    }

    // 強制Resize
    @Watch('forceResizeLogo')
    public watchForceResizeLogo() {
        this.resize();
    }


    public created() {

        //nameが設定されていたら設定値で固定する
        if (this.name) {
            this.hasName = true;
        }

        //taglineが設定されていたら設定値で固定する
        if (this.tagline) {
            this.hasTagline = true;
        }

        //Scale Type
        console.log('SLogo > scaleTypeId', this.scaleTypeId);
        this.scaleType = LogoSize.findByKey('id', this.scaleTypeId);

        if (!this.scaleType) {
            // @Todo とりあえずエラー回避
            this.scaleType = LogoSize.findByKey('id', 'normal');
            // console.log('!ERROR No ScaleType in SLogo');
            // return
        }
    }


    public mounted() {

        //LOADED OUT: Web Font読み込み完了後に計算
        this.resize();
    }


    /*
        Resize
    */
    public resize() {

        this.isLoaded = false;

        setTimeout(() => {

            if (!this.scaling) {
                this.isLoaded = true;
                return;
            }

            if (this.stype == 'img') {
                //console.log(this.base + this.ssrc);
                this.resizeImageLogo();

            } else {
                this.resizeWebfontLogo();
            }

            this.isLoaded = true;
        }, 1);
    }


    /*
        Resize Image Logo
    */
    public resizeImageLogo() {

        if (this.ssrc) {

            let image: any = new Image();
            let imgSize: any = {};

            image.src = this.base + this.ssrc;

            image.addEventListener('load', () => {

                // Get Image size
                imgSize = {
                    w: image.width,
                    h: image.height
                }

                //Resize
                const size: any = LogoUtil.resizeImage(
                    imgSize,
                    this.nsize,
                    this.nsizeAcc,
                    this.nratioAcc,
                    this.nratioAccAcc,
                );

                const w = size.w * this.manualScale;
                const h = size.h * this.manualScale;

                this.styleWrapper = {
                    width: `${w}px`,
                    height: `${h}px`
                };

                this.styleLogo = {
                    width: `${w}px`,
                    height: `${h}px`
                };

                this.isLoaded = true;
            });
        }
    }


    /*
        Resize Webfont Logo
    */
    public resizeWebfontLogo() {

        if (this.$refs.inner) {

            //Tagline
            const tag: any = {
                has: this.istagline,
                body: this.stagline,
                acc: this.nwebfontTaglineAcc,
            }

            //外枠に合わせてはみでないように縮小させる場合
            let fitOuter: any = {
                is: this.resizeByOuter,
            };

            if (this.resizeByOuter) {
                fitOuter.w = $(this.$refs.wrapper).outerWidth() - 60;
            }

            /*
                返す値
                w,h : 外枠のサイズ
                scale : transformの値
            */
            const size = LogoUtil.resizeTxt(
                $(this.$refs.inner),
                this.sname.length,
                this.nwebfontAccLength,
                this.nwebfontAcc,
                this.nwebfontSize,
                this.nwebfontAccMin,
                this.nsizeAcc,
                this.nsize,
                this.manualScale,
                fitOuter,
                tag,
            );

            this.styleLogo = {
                width: `${size.w}px`,
                height: `${size.h}px`
            };

            this.styleWebFontTransform = {
                transform: `scale(${size.scale})`
            };

            this.isLoaded = true;
        }
    }


    public get nsize(): any {

        if (this.size) {
            return this.size;
        }

        return this.scaleType.size;
    }

    public get nsizeAcc(): any {

        if (this.sizeAcc) {
            return this.sizeAcc;
        }

        return this.scaleType.sizeAcc;
    }

    public get nratioAcc(): any {

        if (this.ratioAcc) {
            return this.ratioAcc;
        }

        return this.scaleType.ratioAcc;
    }

    public get nratioAccAcc(): any {

        if (this.ratioAccAcc) {
            return this.ratioAccAcc;
        }

        return this.scaleType.ratioAccAcc;
    }

    public get nwebfontAcc(): any {

        if (this.webfontAcc) {
            return this.webfontAcc;
        }

        return this.scaleType.webfontAcc;
    }

    public get nwebfontSize(): any {

        if (this.webfontSize) {
            return this.webfontSize;
        }

        return this.scaleType.webfontSize;
    }

    public get nwebfontAccMin(): any {

        if (this.webfontAccMin) {
            return this.webfontAccMin;
        }

        return this.scaleType.webfontAccMin;
    }

    public get nwebfontAccLength(): any {

        if (this.webfontAccLength) {
            return this.webfontAccLength;
        }

        return this.scaleType.webfontAccLength;
    }

    public get nwebfontTaglineAcc(): any {

        if (this.webfontTaglineAcc) {
            return this.webfontTaglineAcc;
        }

        return this.scaleType.webfontTaglineAcc;
    }

    /*
	    画像データのときURLを返す
    */
    public get ssrc(): any {

        // Propsで指定されていたら優先
        if (this.src) {
            return this.src;
        }

        if (this.color == 'txt' || this.color == 'bk') {
            return themeMainModule.project.logo.txt;

        } else {
            return themeMainModule.project.logo.bg;

        }
    }

    /*
	    Shopname
    */
    public get sname(): any {

        let n: string = themeMainModule.shopname;

        if (!this.hasName) {

            if (n == '') {
                n = themeMainModule.shopname;
            }

            this.$nextTick(() => {
                this.resizeWebfontLogo();
            });

        } else {

            n = this.name;

        }

        return n;
    }

    public get stagline(): any {

        let n: string = themeMainModule.tagline;

        if (!this.hasName) {
            /*
                        if(n == ''){
                            n = themeMainModule.tagline;
                        }
            */
            this.$nextTick(() => {
                this.resizeWebfontLogo();
            });

        } else {
            n = this.tagline;
        }

        return n;
    }

    public get ostyleWebFontTransform(): any {
        return this.styleWebFontTransform;
    }

    public get oisLoaded(): any {
        return this.isLoaded;
    }

    /*
        文字種別
    */
    public get nameCase(): any {

        if (!this.sname) {
            return
        }

        //すべて大文字と数字だけ
        if (this.sname == this.sname.toUpperCase()) {
            return 'uppercase';

            //すべて小文字
        } else if (this.sname == this.sname.toLowerCase()) {
            return 'lowercase';
        }

        return;
    }

    /*
        ラッパータグ
        リンクがない場合はDIVにする
    */
    public get tagWrapper(): any {

        if (!this.to) {
            return 'div';

        } else {
            return 'nuxt-link';
        }
    }


    /*
        Logo Type
        webfont or img
    */
    public get stype(): any {

        if (this.type) {
            return this.type;

        } else {
            return VarUtil.path(themeMainModule.project, 'logo.type');
        }
    }

    /*
        手動スケールの取得
    */
    public get manualScale(): any {

        if (this.scale != 1) {
            return this.scale;

        } else {

            if (this.locate == 'nav') {
                return themeMainModule.navLogoScale;

            } else if (this.locate == 'mv') {
                return themeMainModule.mvContentLogoScale;

            } else if (this.locate == 'footer') {
                return themeMainModule.footerLogoScale;

            } else if (this.locate == 'block') {
                return themeMainModule.blockLogoScale;

            } else if (this.locate == 'loading') {
                return themeMainModule.loadingLogoScale;

            }

        }

        return 1;
    }

    /*
        Web Font
    */
    public get swebfont(): any {

        if (this.stype == 'webfont') {

            const id = this.webfont ? this.webfont : themeMainModule.project.logo.webfont;

            const f = Logos.findByKey('id', String(id));
            if (!f) {
                console.log('none:' + id);
                return
            }
            //console.log(f.class);
            return f.class;
        }

        return '';
    }

    public get classWebfont(): any {
        if (this.stype == 'webfont') {
            return this.swebfont;
        }
        return
    }


// 	public get classJustify(): any {
//     	if(this.justify){
// 		    return '-h--' + this.justify;
//     	}
//     	return;
// 	}
// 	public get classAlign(): any {
//     	if(this.align){
// 		    return '-v--' + this.align;
//     	}
//     	return;
// 	}
//
// 	public get classPosition(): any {
//     	if(this.position){
// 		    return '-p--' + this.position;
//     	}
//     	return;
// 	}

    public get classColor(): any {

        if (this.color == 'txt' || this.color == 'bk') {
            return '-txt';

        } else {
            return '-bg';
        }
    }

    public get classType(): any {
        if (this.stype) {
            return '-' + this.stype;
        }
        return;
    }

    public get forceResizeLogo(): any {
        return themeMainModule.forceResizeLogo;
    }

    public get changeBreakpoint(): any {
        return themeMainModule.deviceSize;
    }


}
