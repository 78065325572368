import {Index} from '@/classes/app/api/os';
import {Gallery} from '@/classes/app/api/os/gallery';
import {Menu} from '@/classes/app/api/os/staff';
import {Staff} from '@/classes/app/api/os/menu';
import {Storex} from '@/classes/app/api/os/store';
import {Blog} from '@/classes/app/api/os/blog';

export class OsApi {
    public static index: Index = new Index();
    public static menu: Menu = new Menu();
    public static staff: Staff = new Staff();
    public static gallery: Gallery = new Gallery();
    public static storex: Storex = new Storex();
    public static blog: Blog = new Blog();
}
