import {Component, Prop, Vue} from '@/node_modules/nuxt-property-decorator';
import {tabModule} from '@/store/tab';

@Component
export default class MTabContents extends Vue {
   	
   	
    @Prop({default: 'tab'})
    public k: string;
    
    @Prop({default: 1})
    public id: number;
    
    
	public get isActive(): any {
		
    	const tab:any = tabModule.tabs.findByKey('k', this.k );
    	if(!tab){
	    	return;
    	}
		return tab.show == this.id;
		
	}
	
}
