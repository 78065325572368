import {Component, Prop, Vue} from "vue-property-decorator";
import CLink from '@/components/theme/module/CLink.vue';
import CNavItem from '@/components/theme/module/header/CNavItem.vue';

@Component({
    components: {
	    CLink,
	    CNavItem,
    }
})
export default class CNavBlock extends Vue {

    @Prop({default:() => {} })
    public d: any;


}

