import {Component, Watch, Prop, Vue} from "vue-property-decorator";
import {themeMainModule} from '@/store/theme/theme-main';
import {themeStateModule} from '@/store/theme/theme-state';
import {editModule} from '@/store/edit';

import {DummyProject} from '@/configs/dummy/dummy-project';
import {DummyBlog} from '@/configs/dummy/dummy-blog';
import {DummyNews} from '@/configs/dummy/dummy-news';

import CSidecoverTopics from '@/components/theme/module/home/sidecover/CSidecoverTopics.vue';
import CSidecoverAddress from '@/components/theme/module/home/sidecover/CSidecoverAddress.vue';
//import CSidecoverReservation from '@/components/theme/module/home/sidecover/CSidecoverReservation.vue';

import * as $ from 'jquery';

@Component({
    components: {
        CSidecoverTopics,
        CSidecoverAddress,
    }
})
export default class CSidecover extends Vue {

    public $sidecover: any;
	public styleFrame: any = {};
	public styleEditBox: any = {};
	public classWindowHeight: string = '';

	@Watch('mainFrameResized')
	public watchMainFrameResized() {
	    this.resize();
	}


	// Computed //////////////////////////////////////////////////////////////////////

	public get scomponent(): any {
        return themeMainModule.scType;
	}

	public get mainFrameResized(): any {
	    return themeMainModule.mainFrameResized;
	}

    public get display(): any {

        if(themeMainModule.scType == 'none' || themeMainModule.scType == ''){
            return false;
        }

        return themeStateModule.sidecoverDisplay;
    }

	/*
    	ManagerのときとTransitionをきりかえ
	*/
	public get stransition(): any {

    	if(editModule.editTransition) {
        	return editModule.editTransition;
    	}

    	return 'ct-change';

	}

	public get mainFrameOffset(): any {
    	return themeMainModule.mainFrameOffset;
	}


	// Methods //////////////////////////////////////////////////////////////////////

	public mounted() {
    	this.$sidecover = $(this.$refs.sidecover!['$el'] as HTMLElement);
		this.resize();
	}

    public init() {
        this.resize();
    }

	/*
		RESIZE
	*/
	public resize() {

        setTimeout(
            () => {
                this.setStyleEditBox();
            }, 10);

        setTimeout(
            () => {
                this.setStyleFrame();
                themeStateModule.updateSidecoverDisplay(true);
            }, 20);
	}


    public setStyleEditBox() {

        if (this.$sidecover) {

            if(!themeMainModule.manager && themeMainModule.deviceSize != 'mb') {

                setTimeout(
                    () => {

                        let top: number = 108;

                        if (this.$sidecover.length) {
                            top = this.$sidecover.offset().top;
                        }

        				const w = this.$sidecover.outerWidth();
        				const h = this.$sidecover.outerHeight();

        				this.styleEditBox = {
            			    top: `${top}px`,
            			    left: 'auto',
            			    right: '4px',
            			    width: `${w - 8}px`,
            			    height: `${h - 4}px`,
        				};

                    }, 10);
            }

        }
    }

    /*
        c-sidecover-frame
    */
	public setStyleFrame() {

		//TOPICS
		if(themeMainModule.deviceSize == 'pc' || themeMainModule.deviceSize == 'tablet') {

			//MANAGER
			if(themeMainModule.manager) {

				/*
					position:fixed対応のためにc-mv-frameと同じ幅を代入
				*/
				this.styleFrame = {
					top: `${this.mainFrameOffset.top}px`,
					width: `${themeMainModule.mainFrameWidth}px`,
					height: `${themeMainModule.mainFrameHeight}px`
				}

			}

			//Window Heightに応じたクラスをいれる
			if (document.documentElement.clientHeight < 780) {
    			this.classWindowHeight = '-window-height-s';

			} else {
    			this.classWindowHeight = '';
			}

		}else{

			//MANAGER
			if(themeMainModule.manager) {

				this.styleFrame = {
					top: `0px`,
					width: `auto`,
					height: `auto`
				}

			}

		}
	}

}



