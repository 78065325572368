import {Component, Watch, Prop, Vue} from "vue-property-decorator";
import {themeMainModule} from '@/store/theme/theme-main';

@Component({
    components: {
    }
})
export default class CPager extends Vue {


}



