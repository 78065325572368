import {Component, Prop, Vue} from "vue-property-decorator";
import {themeMainModule} from '@/store/theme/theme-main';
import {themeStateModule} from '@/store/theme/theme-state';
import TxtUtil from "@/classes/view/txt-util";

import {DummyProject} from '@/configs/dummy/dummy-project';

@Component({
    components: {
    }
})
export default class CPageTitle extends Vue {


    @Prop({default: ''})
    public title: string;

	/*
		文字種別
	*/
	public get classCase(): any {
		return TxtUtil.detectCase(this.title);
	}

}

