




    import {Component, Prop, Vue} from '@/node_modules/nuxt-property-decorator';
    import {ToUtil} from "@/classes/utils/to-util";

    @Component
    export default class En extends Vue {
        @Prop({default: '@'})
        public c: string;

        @Prop({default: ''})
        public s: string;

	    @Prop({default: 'span'})
	    public tag: string;

        public get out(): string {
            // console.log('En > ', this.c, this.s);
            return ToUtil.langTag(String(this.s) || '')
                    .replace(/\n[ ]*/g, '<br>');
        }

    }
