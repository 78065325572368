import {$v} from "@/classes/utils/var-util";

export class DevUtil {

    public static log(...args) {

        const nargs = Array.from(args);
        nargs[0] = '@@ > ' + nargs[0];

        console.log.call(null, ...nargs);
    }

    /**
     * @param pj
     */
    public static filterDevProject(pj: any) {

        $v.put(pj, 'global', {
            brandid: 'tiny-gramond',
            layout: 'spring',
        });

        return pj;
    }
}
