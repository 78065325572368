import System from '@/classes/app/api/manager/system';
import SystemRemainder from '@/classes/app/api/manager/system-remainder';
import OwnerPaymentCard from '@/classes/app/api/manager/owner-payment-card';
import OwnerRegist from '@/classes/app/api/manager/owner-regist';
import OwnerSubscription from '@/classes/app/api/manager/owner-subscription';
import OwnerMy from '@/classes/app/api/manager/owner-my';
import OwnerSite from '@/classes/app/api/manager/owner-site';
import ShopMenu from '@/classes/app/api/manager/shop-menu';
import ShopMenuCategory from '@/classes/app/api/manager/shop-menu-category';
import ShopOpenTypeIndex from '@/classes/app/api/manager/shop-open-type-index';
import Shop from '@/classes/app/api/manager/shop';
import ShopStaff from '@/classes/app/api/manager/shop-staff';
import Owner from '@/classes/app/api/manager/owner';
import OwnerAccount from '@/classes/app/api/manager/owner-account';
import OwnerAccountEmail from '@/classes/app/api/manager/owner-account-email';
import User from '@/classes/app/api/manager/user';
import UserActivation from '@/classes/app/api/manager/user-activation';
import FileIndex from '@/classes/app/api/manager/file-index';
import FileUpload from '@/classes/app/api/manager/file-upload';
import FileUploadPrepare from '@/classes/app/api/manager/file-upload-prepare';
import MaterialIndex from '@/classes/app/api/manager/material-index';
import SiteMedia from '@/classes/app/api/manager/site-media';
import SiteExValue from '@/classes/app/api/manager/site-ex-value';
import ShopWorkScheduleTime from '@/classes/app/api/manager/shopworkscheduletime';
import ShopWorkScheduleDay from '@/classes/app/api/manager/shopworkscheduleday';
import Blog from '@/classes/app/api/manager/blog';
import BlogArticle from '@/classes/app/api/manager/blog-article';
import BlogCategory from '@/classes/app/api/manager/blog-category';
import Var from '@/classes/app/api/manager/var';
import SysVar from '@/classes/app/api/manager/sys-var';
import DesignTheme from '@/classes/app/api/manager/design-theme';
import SiteTheme from '@/classes/app/api/manager/site-theme';
import OwnerPayment from '@/classes/app/api/manager/owner-payment';
import OwnerSubscriber from '@/classes/app/api/manager/owner-subscriber';
import OwnerPlan from '@/classes/app/api/manager/owner-plan';
import OwnerInfoNews from '@/classes/app/api/manager/owner-info-news';
import OwnerInfoNotify from '@/classes/app/api/manager/owner-info-notify';
import OwnerCoupon from '@/classes/app/api/manager/owner-coupon';
import OwnerCouponTicket from '@/classes/app/api/manager/owner-coupon-ticket';
import OwnerDomainWhois from '@/classes/app/api/manager/owner-domain-whois';
import OwnerBusinessItem from '@/classes/app/api/manager/owner-business-item';
import OwnerPaymentCustomer from '@/classes/app/api/manager/owner-payment-customer';
import OwnerInvoice from '@/classes/app/api/manager/owner-invoice';
import FileSign from '@/classes/app/api/manager/file-sign';
import PostCode from "@/classes/app/api/manager/postcode";
import OfficialStart from "@/classes/app/api/manager/official-start";
import {Ownersite} from "@/classes/app/api/manager/ownersite";

export default class ManagerApi {

    public static DesignTheme = new DesignTheme();
    public static System = new System();
    public static SystemRemainder = new SystemRemainder();
    public static OfficialStart = new OfficialStart();

    public static OwnerInfoNews = new OwnerInfoNews;
    public static OwnerInfoNotify = new OwnerInfoNotify;
    public static OwnerPayment = new OwnerPayment();
    public static OwnerPaymentCard = new OwnerPaymentCard();
    public static OwnerPaymentCustomer = new OwnerPaymentCustomer();
    public static OwnerRegist = new OwnerRegist();
    public static OwnerSubscription = new OwnerSubscription();
    public static OwnerMy = new OwnerMy();
    public static OwnerSite = new OwnerSite();
    public static OwnerAccount = new OwnerAccount();
    public static OwnerAccountEmail = new OwnerAccountEmail();
    public static OwnerSubscriber = new OwnerSubscriber();
    public static OwnerDomainWhois = new OwnerDomainWhois();
    public static OwnerBusinessItem = new OwnerBusinessItem();
    public static OwnerInvoice = new OwnerInvoice();
    public static OwnerCoupon = new OwnerCoupon();
    public static OwnerCouponTicket = new OwnerCouponTicket();
    public static Ownersite = new Ownersite();

    public static OwnerPlan = new OwnerPlan();
    public static ShopStaff = new ShopStaff();
    public static ShopMenu = new ShopMenu();
    public static ShopMenuCategory = new ShopMenuCategory();
    public static ShopOpenTypeIndex = new ShopOpenTypeIndex();
    public static ShopWorkSchduleTime = new ShopWorkScheduleTime();
    public static ShopWorkSchduleDay = new ShopWorkScheduleDay();
    public static Shop = new Shop();
    public static Owner = new Owner();
    public static User = new User();
    public static UserActivation = new UserActivation();
    public static FileIndex = new FileIndex();
    public static FileSign = new FileSign();
    public static FileUpload = new FileUpload();
    public static FileUploadPrepare = new FileUploadPrepare();
    public static Materialindex = new MaterialIndex();
    public static SiteMedia = new SiteMedia();
    public static SiteExValue = new SiteExValue();
    public static SiteTheme = new SiteTheme();
    public static Blog = new Blog();
    public static BlogArticle = new BlogArticle();
    public static BlogCategory = new BlogCategory();
    public static Var = new Var();
    public static SysVar = new SysVar();

    public static PostCode = new PostCode();
}
