/*
	
	YH: Data Spread Sheet is Here
	https://docs.google.com/spreadsheets/d/1GP-wc5RwVL54UcYqxjH_ZfCQWY7IAfuL_BSC2SXCL8s/edit?usp=sharing

*/

export const DummyProjects = [
{
	id: 1,
	name: 'Hashimoto Salon',
	plan: ['base'],
	trial: 0,
	domainPlan: 'subscribe',
	domainState: 'success',
	domainUrl: 'hashimoto-salon.com',
	subdomainUrl: 'aiueo.hair-design.salon',
	state: '',
	update: '2019/6/8'
}, 
{
	id: 2,
	name: 'CCEA',
	plan: ['base', 'reservation', 'card'],
	trial: 0,
	domainPlan: 'subscribe',
	domainState: 'wait',
	domainUrl: 'motion-sickness.com',
	subdomainUrl: 'motion-sickness.coffee-shop.com',
	state: '',
	update: '2019/12/4'
}, 
{
	id: 3,
	name: 'Digitale løsninger der',
	plan: ['trial'],
	trial: 12,
	domainPlan: '',
	domainState: '',
	domainUrl: '',
	subdomainUrl: 'yohei.hair-design.salon',
	state: '',
	update: '2020/1/6'
}, 
{
	id: 4,
	name: 'KIZUNA JAPAN',
	plan: ['trial'],
	trial: 0,
	domainPlan: '',
	domainState: '',
	domainUrl: '',
	subdomainUrl: 'aiueo.hair-design.salon',
	state: 'expired',
	update: '2020/10/24'
}, 
{
	id: 5,
	name: 'Mateusz Dziekonski',
	plan: ['base', 'reservation'],
	trial: 0,
	domainPlan: 'connect',
	domainState: 'success',
	domainUrl: 'jackbaure-hyper.co.jp',
	subdomainUrl: 'dawdaei.hair-design.salon',
	state: 'fail',
	update: '2020/9/14'
}, 
{
	id: 6,
	name: '奴隷onナルシスト',
	plan: ['base'],
	trial: 0,
	domainPlan: 'connect',
	domainState: 'wait',
	domainUrl: 'hashimoto-salon.com',
	subdomainUrl: 'aidawdawdaueo.hair-design.salon',
	state: '',
	update: '2019/6/13'
}, 
{
	id: 7,
	name: 'George Nakashima Woodworkers',
	plan: ['base'],
	trial: 0,
	domainPlan: '',
	domainState: '',
	domainUrl: '',
	subdomainUrl: 'aiueo.hair-design.salon',
	state: '',
	update: '2020/5/30'
}, 
{
	id: 8,
	name: 'Ursa Major Supercluster',
	plan: ['base'],
	trial: 0,
	domainPlan: '',
	domainState: '',
	domainUrl: '',
	subdomainUrl: 'aiuxzczeo.hair-design.salon',
	state: 'expired',
	update: '2019/6/15'
}, 
{
	id: 9,
	name: 'Enso Tyres',
	plan: ['trial'],
	trial: 3,
	domainPlan: '',
	domainState: '',
	domainUrl: 'hashimoto-salon.com',
	subdomainUrl: 'aiueo.hair-design.salon',
	state: '',
	update: '2019/6/16'
}, 
 
];