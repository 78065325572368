






















































































































































	// @ts-ignore
    import _ from './CPageStaffIndexStandard.ts'; export default _;
