















	// @ts-ignore
    import _ from './MPopupNoticeLogoData.ts'; export default _;
