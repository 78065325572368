
































	// @ts-ignore
    import _ from './ProcedureDomainMoveIndex.ts'; export default _;
