import Vue from 'vue';
import $ from 'jquery';
import 'jquery.nicescroll';
import Sortable from 'vue-sortable';
import draggable from 'vuedraggable';
import resize from 'vue-resize-directive';
import * as VueGoogleMaps from 'vue2-google-maps';

import {appAuthModule} from "@/store/app/auth";
// import {AuthManager} from "@/classes/app/auth-manager";
// import {managerProjectModule} from "@/store/manager/project";
// import {appApiModule} from "@/store/app/api";

import OverlayScrollbars from 'overlayscrollbars';
import {OverlayScrollbarsPlugin} from "overlayscrollbars-vue";
import {bodyModule} from "@/store/body";

Vue.directive('resize', resize);
Vue.directive('sortable', Sortable);
Vue.component('draggable', draggable);
Vue.use(OverlayScrollbarsPlugin);
Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyB5BUKd_mAeamkj5dFv9krusyG5y_FhN6Y', // process.env.VUE_APP_GOOGLE_MAP_API,
        libraries: 'places',
        region: 'JP',
        language: 'ja'
    }
})
Vue.config.productionTip = false


window['$'] = $;
window['jQuery'] = $;

const boot = async () => {
    await appAuthModule.setup();
};
//
boot().then(() => {
    console.log('boot()');
});

// const element = document.createElement("script");
// element.src = '/common/vendor/trackpad-scroll-emulator/jquery.trackpad-scroll-emulator.min.js';
// document.body.appendChild(element);
OverlayScrollbars(document.body, {
    nativeScrollbarsOverlaid: {
        initialize: false,
    },
    // callbacks: {
    //     onScrollStart: (e: any) => {
    //         console.log('scroll start');
    //     },
    //     onScrollStop: (e: any) => {
    //         console.log('scroll stop');
    //     },
    //     onScroll: (e: any) => {
    //         console.log('vvvvaaa');
    //         // console.log('Global Scroll > *', e);
    //         bodyModule.updateScrollEvent(e);
    //     }
    // },
});

$('.os-viewport').on('scroll', (e: any) => {
    //console.log('scroll by jquery');
    bodyModule.updateScrollEvent(e);
});

$(document).on('scroll', (e: any) => {
    bodyModule.updateScrollEvent(e);
});

