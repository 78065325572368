import {Component, Prop, Vue} from "vue-property-decorator";
import ACBlock from '@/classes/theme/class/a-c-block';
import ViewUtil from "@/classes/view/view-util";
import {themeMainModule} from '@/store/theme/theme-main';
import CBlockMix from '@/classes/theme/mixin/c-block-mix';

import {DummyGallery} from '@/configs/dummy/dummy-gallery';

@Component({
    mixins: [CBlockMix],
    components: {
    }
})
export default class CBlockListOneLarge extends ACBlock {

	/*
		処理用変数
	*/
	public data: any = [];


	public created() {

        /*
            DummeHomeのデータから指定Blockのデータを取得
            設定画面: manager/project/home/
        */
        this.init(this.k);

	}


	/*

		Component処理

	*/
	public get odata(): any {

		if( this.blockData.data == 'gallery' ) {
			this.data = DummyGallery.slice(0,3);
		}

		return this.data;

	}


	/*

		共通処理

	*/
	//Hoverしたときの表現を特殊にするかどうか
	public get isImghover(): any {
		return themeMainModule.brand.decor[this.k].imgHover;
	}

}

