




























































































// @ts-ignore
import _ from './CPageStoreIndexStandard.ts';

export default _;
