import {Component, Watch, Prop, Vue} from '@/node_modules/nuxt-property-decorator';
import APopup from "@/classes/page/a-popup";
import {accountModule} from '@/store/account';

import MPopupMBody from '@/components/popup/module/MPopupMBody.vue';

@Component({
    components: {
	    MPopupMBody
    }
})
export default class MPopupDomainConnectCheckSuccess extends APopup {
    
    ////////// POPUP LAYER 共通処理 /////////////////////////////////
	
	public onClose(to: string=''): any {
		if(to != ''){
			this.$router.push(to);
		}
        this.$emit('close');
	}
	
    public toProcedureClose(to: string, value: string){
	    accountModule.updateDevProcedure(value);
	    this.$router.push(to)
        this.$emit('close');
    }
}
