






































	// @ts-ignore
    import _ from './AccountCard.ts'; export default _;
