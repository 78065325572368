import {Mutation, MutationAction, Action, VuexModule, getModule, Module} from 'vuex-module-decorators';
import store from '@/classes/core/store';

// state's interface
export interface ITipsModule {
    current: any;
    lock: boolean;
}

@Module({dynamic: true, store, name: 'tips', namespaced: true})
class Tips extends VuexModule implements ITipsModule {

    public current: any = null;
    
    //Click時に一定時間反応しないようにするためのスイッチ
    public lock: boolean = false;

    @Mutation
    public clearCurrent() {
        this.current = null;
    }

    @Mutation
    public updateCurrent(current: any = null) {
        this.current = current;
    }
    
    @Mutation
    public updateLock( value: boolean ) {
        this.lock = value;
    }
}

export const tipsModule = getModule(Tips);

