import {Component} from "nuxt-property-decorator";
import RoutingUtil from "@/classes/view/routing-util";
import AAccount from '@/classes/page/a-account';
import {accountModule} from '@/store/account';
import {appModule} from '@/store/app';

import MInputCard from '@/components/part/account/m-input-card.vue';
import MSelectPayment from '@/components/part/account/part/MSelectPayment.vue';
import MItemsPrice from '@/components/part/account/part/MItemsPrice.vue';
import MTotalPrice from '@/components/part/account/part/MTotalPrice.vue';
import MConfirmMemo from '@/components/part/account/part/MConfirmMemo.vue';
import MCoupon from '@/components/part/account/part/MCoupon.vue';
import MCheckConditions from '@/components/part/account/part/MCheckConditions.vue';

@Component({
    components: {
	    MInputCard,
	    MSelectPayment,
	    MItemsPrice,
	    MTotalPrice,
	    MConfirmMemo,
	    MCoupon,
	    MCheckConditions
    }
})
export default class ProcedureShareConfirm extends AAccount {

	public dev_procedure: string = accountModule.dev_procedure;

	public mypagetype: string = 'procedure';
	public mydivider: string = 'half';

    // SSRでサーバーサイドの処理から変数をセット
    public async asyncData(context: any): Promise<any> {
        return {
            test: 128,
            title: 'Indexa',
        }
    }



    // ヘッダタグの構成要素
    public head() {
        return {
            //title: this.title
        }
    }

    ///////// 全ページ共通 ////////////////////////////////////////////
    public transition(to: any, from: any) {
		return RoutingUtil.transition(to, from);
	}

    public created(){

		///////// 全ページ共通 ////////////////////////////////////////////
		this.setPagetype( appModule, this.mypagetype, this.mydivider );

    }


}
