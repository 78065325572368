import {Component, Watch, Vue} from "vue-property-decorator";
import {themeMainModule} from '@/store/theme/theme-main';
import CLogoFooterMix from '@/classes/theme/mixin/c-logo-footer-mix';
import CNavigationMix from '@/classes/theme/mixin/c-navigation-mix';

import {DummyProject} from '@/configs/dummy/dummy-project';
import {DummyStore} from '@/configs/dummy/dummy-store';
import {DummyNavCategory} from '@/configs/dummy/dummy-nav-category';

import CLink from '@/components/theme/module/CLink.vue';

@Component({
    mixins: [
        CLogoFooterMix,
        CNavigationMix,
    ],
    components: {
	    CLink,
    }
})
export default class CFooter extends Vue {

    public primaryNav: any;
    public secondaryNav: any;


    // Watch /////////////////////////////////////////////////////////////////////////

    @Watch('projectNavigationPagePrimary')
    public watchProjectNavigationPagePrimary() {

        this.layoutFooterNav();
    }

    @Watch('projectNavigationPageSecondary')
    public watchProjectNavigationPageSecondary() {

        this.layoutFooterNav();
    }


    // Computed /////////////////////////////////////////////////////////////////////////

    /*
	    FOOTER INFO
    */
    public get ofooterTitle(): any {
	    if(themeMainModule.footer.title) {
		    return themeMainModule.footer.title;
	    }
	    return themeMainModule.project.tagline + themeMainModule.project.shopname;
    }

	public get oaddress1(): any {
	    if(themeMainModule.mainStoreAddress1) {
		    return themeMainModule.mainStoreAddress1;
	    }
		return DummyStore[0].address1;
	}

	public get oaddress2(): any {
	    if(themeMainModule.mainStoreAddress2) {
		    return themeMainModule.mainStoreAddress2;
	    }
		return DummyStore[0].address2;
	}

	public get otel(): any {
	    if(themeMainModule.mainStoreTel) {
		    return themeMainModule.mainStoreTel;
	    }
		return DummyStore[0].tel;
	}

	public get oopen(): any {
	    if(themeMainModule.mainStoreOpen) {
		    return themeMainModule.mainStoreOpen;
	    }
		return DummyStore[0].open;
	}

	public get oclose(): any {
	    if(themeMainModule.mainStoreClose) {
		    return themeMainModule.mainStoreClose;
	    }
		return DummyStore[0].close;
	}

    /*
	    FOOTER OTHER
    */

    public get categories(): any {
        return DummyNavCategory;
    }

    public get ohomeName(): any {

    	const d: any = themeMainModule.projectNavigationPage.findByKey('id', 'index');
    	if( !d ) { return }

	    return d.name;
    }

    /*
	    FOOTER BOTTOM
    */
    public get oshopname(): any {
	    return themeMainModule.shopname;
    }

    /*
	    FOOTER RIGHT
    */
    public get isPrThought(): any {
	    return themeMainModule.footer.prThought;
    }


    // Method /////////////////////////////////////////////////////////////////////////

    public created() {
        this.layoutFooterNav();
    }

    /*
        メニューの個数にばらつきがある場合、揃える
        primary と secondary に2つ以上の差がある場合。
    */
    public layoutFooterNav() {

        if (!this!['projectNavigationPagePrimary'] || !this!['projectNavigationPageSecondary']) { return; }

        let p: any = [];
        let s: any = [];
        const primaryNum = this!['projectNavigationPagePrimary'].length;
        const secondaryNum = this!['projectNavigationPageSecondary'].length;
        let gap = primaryNum - secondaryNum;

        // Primary が多い
        if (gap > 1) {
            const _length = this!['projectNavigationPagePrimary'].length - (gap - 1);

            //Primary
            for (let i = 0 ; i < _length ; i++) {
                p.push(this!['projectNavigationPagePrimary'][i]);
            }

            //Secondary
            for (let i = _length ; i < this!['projectNavigationPagePrimary'].length ; i++) {
                s.push(this!['projectNavigationPagePrimary'][i]);
            }

            for (let i = 0 ; i < this!['projectNavigationPageSecondary'].length ; i++) {
                s.push(this!['projectNavigationPageSecondary'][i]);
            }

            this.primaryNav = p;
            this.secondaryNav = s;

        // secondary が多い
        } else if (gap < -1) {
            const _length = this!['projectNavigationPageSecondary'].length - (gap - 1);

            //Secondary
            for (let i = 0 ; i < _length ; i++) {
                p.push(this!['projectNavigationPageSecondary'][i]);
            }

            //Primary
            for (let i = _length - 1 ; i < this!['projectNavigationPageSecondary'].length ; i++) {
                s.push(this!['projectNavigationPageSecondary'][i]);
            }

            for (let i = 0 ; i < this!['projectNavigationPagePrimary'].length ; i++) {
                s.push(this!['projectNavigationPagePrimary'][i]);
            }

            this.primaryNav = p;
            this.secondaryNav = s;

        } else {
            this.primaryNav = this!['projectNavigationPagePrimary'];
            this.secondaryNav = this!['projectNavigationPageSecondary'];
        }


    }
}



