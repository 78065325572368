










	// @ts-ignore
    import _ from './MInviewItem.ts'; export default _;
