import {$v} from "@/classes/utils/var-util";
import {EnvUtil} from "@/classes/app/env-util";
import {Env} from "@/classes/app/env";

export class DomainUtil {

    public static preview(domain: any, nodev: boolean = false): string {
        if (!nodev && EnvUtil.isAppModeDevelop) {
            return 'localhost:3001/preview/' + self.domain(domain, true);
        } else {
            const udom = $v.p(domain, 'user_domain');
            return !!udom ? udom :
                `${$v.p(domain, 'sub')}.${$v.p(domain, 'domain')}`;
        }
    }

    public static url(domain: any, nodev: boolean = false): string {

        const d = self.domain(domain, nodev);
        return ((!nodev && EnvUtil.isAppModeDevelop) ? 'http' : 'https') + '://' + d;
    }

    public static domain(domain: any, nodev: boolean = false): string {

        const udom = $v.p(domain, 'user_domain');
        // console.log('DomainUtil.domain() > ', domain);
        if (!nodev && EnvUtil.isAppModeDevelop) {
            if (!$v.isEmpty(udom)) {
                return udom.replace(/\./g, '_') + '.to004.t0f.cc';
            } else {
                const sub = $v.p(domain, 'sub');
                const dom = $v.p(domain, 'domain');
                return `${sub}.test.${dom}:3001`;
            }
        } else {
            return !!udom ? udom :
                `${$v.p(domain, 'sub')}.${$v.p(domain, 'domain')}`;
        }
    }

    public static subodmain(domain: any): string {
        return `${domain.sub}.${domain.domain}`.replace(/\.+/g, '.');
    }

    public static statusL(domain: any): string {
        return '';
    }
}

const self = DomainUtil;
