import {sprintf} from "sprintf-js";
import VarUtil from "../../../../../../utils/var-util";
import {Lang} from "@/classes/app/app-const";
import {ISiteStoreHoliday} from "@/classes/domain/module/model/site/store/i-site-store";
import {HolidayDefaultFormatter} from "@/classes/domain/module/model/site/store/holiday-default-formatter";
import {WorktimeDefaultFormatter} from "@/classes/domain/module/model/site/store/worktime-default-formatter";

export class MvAddressBlock2BodyRender {

    private readonly _project: any;

    public constructor(project: any) {
        this._project = project;
    }

    public render(): string {
        return sprintf("%s<br>%s",
            this.mainStoreOpen(),
            this.mainStoreClose(),
        );
    }

    private mainStoreOpen(): string {

        // console.log('mainStoreOpen //////////////////////////////////////////');
        // console.log(' - ', this.p('mainstore.worktimes'));

        const worktimes = this.p('mainstore.worktimes') || [];
        return (new WorktimeDefaultFormatter(worktimes, {
            lang: Lang.En,
        })).format();
    }

    private mainStoreClose(): string {
        const holidays = this.p('mainstore.holidays', []);

        const r = [];
        holidays.map((_: ISiteStoreHoliday) => {
            r.push((new HolidayDefaultFormatter(_, {
                lang: Lang.En,
            })).format());
        });

        return 'Close on ' + (r.join('<br>'));
    }


    public p(path: string, def: any = ''): any {
        return VarUtil.p(this._project, path, def);
    }
}
