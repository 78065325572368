/*

	YH: Data Spread Sheet is Here
	https://docs.google.com/spreadsheets/d/1GP-wc5RwVL54UcYqxjH_ZfCQWY7IAfuL_BSC2SXCL8s/edit?usp=sharing

*/

export const DummyPageGallery =
{
	id: 'gallery',
	name: 'Collection',
	type: 'page',
	pattern: {
		index: 'c-page-gallery-index-standard',
		detail: 'c-page-gallery-detail-standard',
	},
	aspect: 'horizontal',
};










