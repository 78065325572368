import {Component, Watch, Prop, Vue} from '@/node_modules/nuxt-property-decorator';
import APopup from "@/classes/page/a-popup";

//upload-layerの表示管理
import {uploadlayerModule} from '@/store/uploadlayer';

import MPopupMBody from '@/components/popup/module/MPopupMBody.vue';

@Component({
    components: {
	    MPopupMBody
    }
})
export default class MPopupNoticeLogoData extends APopup {
    
    ////////// POPUP LAYER 共通処理 /////////////////////////////////
    
	public onCloseUploadLayerOpen(value: string) {
		if(value != ''){
			uploadlayerModule.updateLayer(value);
		}
	    this.$emit('close');
	}
	
	public onClosePopupOpen(value: string) {
		if(value != ''){
			this.onPopupOpen(value);
		}
	    this.$emit('close');
	}
	
	public onClose(to: string=''): any {
		if(to != ''){
			this.$router.push(to);
		}
        this.$emit('close');
	}
	
}
