import {Component} from "nuxt-property-decorator";
import APage from '@/classes/page/a-page';
import {popupModule} from '@/store/popup';
import {managerBlogArticleModule} from "@/store/manager/blog-article";
import {managerBlogModule} from "@/store/manager/blog";
import {$apm} from "@/classes/app/app-manager";
import {DevUtil} from "@/classes/utils/dev-util";

@Component({
    components: {}
})
export default class MPopupUploadPost extends APage {

    public page: string = 'select-img';
    public type: string = popupModule.type || 'blog';
    public nextOpenDelay: number = 600;


    public on(to: string) {
        this.page = to;
    }

    // Methods //////////////////////////////////////////////////////////
    public openPopup(value: string) {

        // console.log(value);

        this.$emit('close');

        //一旦閉じてから開くアニメーションにするためのDelay
        setTimeout(() => {
            this.onPopupOpen(value);
        }, this.nextOpenDelay);
    }

    // Events /////////////////////////////////////////////////////////
    public async onClickAddNew() {
        await managerBlogArticleModule.create();

        this.openPopup('article-detail--' + this.type);
        await managerBlogModule.fetch(true);
    }

    public onClickAddFromCopy() {
        this.openPopup('post-copy');
    }

    public onClose(to: string = ''): any {

        if (to != '') {
            this.$router.push(to);
        }
        this.$emit('close');
    }

}
