export const AppConst = {
    // 言語指定
    Lang: {
        Ja: 'ja',
        En: 'en',
    },

    Country: {
        Jp: 'jp',
        Us: 'us',
        Ca: 'ca',
        Tw: 'tw',
    },

    // 進捗100%
    ProgressFull: 100,

    Auth: {
        Manager: 'manager',
    },

    Weeks: [
        'w1', 'w2', 'w3', 'w4', 'w5', 'w6', 'w0', 'w7',
    ],

    UploaderSource: {
        WIZARD: 0,
        SITE: 1,
    },

    Logo: {
        DEFAULT_FONT_ID: 'interRegular',
        DEFAULT_SCALE: {
            navLogo: 1, //ヘッダーのロゴスケール
            footerLogo: 1, //フッターのロゴスケール
            blockLogo: 1, //ブロックで使用される場合のスケール
            loadingLogo: 1, //Loadingでのスケール
        },
    },
};

export enum Lang {
    Ja = 'ja',
    En = 'en',
}

export enum Country {
    Jp = 'jp',
    Us = 'us',
}

export enum AppType {
    Production, // プロダクション
    Ownersite   // オーナーサイト
}

// 記事ステータス（各種掲載対象データにも適用）
export enum ArticleState {
    Publish = 'publish',
    Draft = 'draft',
    Inactive = 'inactive',
    Deleted = 'deleted',
}
