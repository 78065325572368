import {DummyProject} from '@/configs/dummy/dummy-project';
import {EnvUtil} from "@/classes/app/env-util";
import {MainModuleDummyBinder} from "@/classes/domain/module/os/project/binder/main-module-dummy-binder";
import {MainModuleOwnerBinder} from "@/classes/domain/module/os/project/binder/main-module-owner-binder";
import WUtil from "@/classes/view/w-util";

export class MainModuleBinder {

    public static bindProject(project: any, forceDummy: boolean = false) {

        project = project || DummyProject;

        WUtil.browser(() => {
            console.log('MainModuleBinder.bind() > ', project);
        });

        if (forceDummy || EnvUtil.isDummy) {
            console.log(' - dummy bind');
            // 従来のBinder
            MainModuleDummyBinder.bindProject(project);
        } else {
            console.log(' - project bind');
            // システムデータ対応版
            MainModuleOwnerBinder.bindProject(project);
        }
    }
}

const self = MainModuleBinder;
