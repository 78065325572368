import {Component, Vue} from 'nuxt-property-decorator';
import {themeMainModule} from '@/store/theme/theme-main';

import CTab from '@/components/theme/module/CTab.vue';

@Component({
    components: {
        CTab,
    }
})
export default class CMenuMb extends Vue {

    /*
        CATEGORY
    */
    public d: any = ['Hair','Esthe','Massage'];

}
