import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class MContentsTitle extends Vue {
    
    //Title
    @Prop({default: ''})
    public title: string;
    
    //Sub Title
    @Prop({default: ''})
    public sub: string;
    
}