import {Component, Watch, Prop, Vue} from '@/node_modules/nuxt-property-decorator';
import MPopupLayer from '@/components/popup/MPopupLayer.vue';
import {IPopup, popupModule} from "@/store/popup";
import {Popup} from '@/configs/popup';
import {ExtWizardMake} from "@/classes/ext/manager/ext-wizard-make";
import {SystemConfig} from "@/configs/system";

@Component({
    components: {
        MPopupLayer
    }
})
export default class MPopupFrame extends Vue {

    public ary: any = Popup;

    // Watch /////////////////////////////
    // @Watch('open')
    // public watchCurrent() {
    //     if ($v.isEmpty(this.open)) {
    //         return;
    //     }
    //
    //     console.log('watchCurrent > open > ', this.open);
    //
    //     const data = Popup.findByKey('id', this.open);
    //     if (!data) {
    //         console.log('Popup Open Error');
    //         return;
    //     }
    //     const instance: any = this.$refs[data.id];
    //
    //     if (!instance) {
    //         return;
    //     }
    //
    //     instance.onOpen(data);
    //     popupModule.updateId('');
    // }

    // Methods ////////////////////////////////////////////////////////////////////
    public isDisplay(p: string) {
        return (popupModule.id === p);
    }

    // Computeds /////////////////////////////////////////////////////////////////
    // Popups
    public get systemPopups(): string[] {
        return SystemConfig.systemPopups;
    }

    // Popups
    public get popups(): IPopup[] {
        return popupModule.popups;
    }

    // Computed
    public get extWizard(): ExtWizardMake {
        return new ExtWizardMake(this);
    }

    public get open(): any {
        return popupModule.id;
    }
}

