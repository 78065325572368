/*

	YH: Data Spread Sheet is Here
	https://docs.google.com/spreadsheets/d/18RF7vwKqvJwd7ZKlx4oDoTJSatrjOCZ9_Qh2O49ikhM/edit?usp=sharing

*/

export const Faq = [

{
	id: 11,
	title: 'サービスリリース予定はいつですか?',
	inner: '2020年夏頃を予定しております。 リリース時期の詳細はニュースページまたは、各SNSで発信してまいります。',
},
{
	id: 1,
	title: '試してつかうことはできますか?',
	inner: '2週間無料でお使いいただけます。 カード情報の入力も必要なく、無料期間終了後に自動でお支払いされることはありません。 引き続きお使いいただける場合、プランを選び、ご契約へおすすみください。',
},
{
	id: 2,
	title: '店舗数を増やすとなにが変わりますか?',
	inner: '店舗数を増やすと、ホームページの店舗紹介ページを公開できる数が増え、スタッフを店舗に結びつけることができます。店舗ごとにホームページを作成したい場合は、新しくホームページを作成してください。',
},
{
	id: 3,
	title: '複数店舗には対応できますか?',
	inner: 'はい。 お店リストページが生成され、お店ごとにページが生成されます。 スタッフもお店ごとに管理することができます。 お店ごとに ホームページを作成されたい場合は、新規ホームページを作成してください。 ',
},
{
	id: 4,
	title: '独自ドメインを購入できますか？',
	inner: 'はい。 独自ドメインを購入することができます。 すでにドメインをお持ちの場合も接続することが可能です。ドメインの購入や接続はログイン後のアカウント管理画面から行うことができます。  ',
},
{
	id: 5,
	title: 'プランを切り替えることはできますか？',
	inner: 'はい。いつでもプランを切り替えることができます。 サービスの性質上、アップグレード時は、日割り計算されますが、ダウングレードやご解約時は払い戻しはいたしかねますのでご了承ください。',
},
{
	id: 6,
	title: 'プライスメニューは店舗ごとに作成できますか?',
	inner: 'プライスメニューは1つのホームページに対して1つ作成でき、お店ごとに全く違うメニューを作成したい場合は、ジャンル機能を応用することで可能です。対応していないお店の選択もできます。',
},
{
	id: 7,
	title: '契約期間はいつまでですか?',
	inner: '月払いか、年払いのご契約があります。 プロジェクトごとにいつでもご解約することができ、いつでも再契約することができます。 ご解約後の残り日数分は払い戻しができませんのでご了承ください。',
},
{
	id: 8,
	title: '支払い方法はなにがありますか?',
	inner: 'VISA, MASTERCARD, AMEX, JCBをご指定いただけます。 ご契約後は自動更新となります。 カード情報はカード決済代行会社STRIPEですべて管理されます。 弊社ではカード情報を一切保存・管理いたしません。',
},
{
	id: 9,
	title: 'Eメールアカウントはつくれますか?',
	inner: 'はい。 THOUGHTで取得したドメインで、G Suite EメールのEメールアカウントを購入できます。 G Suiteには、Googleカレンダー、ドキュメント、ドライブなども含まれます。',
},
{
	id: 10,
	title: '他に質問があるのですが。',
	inner: 'ご契約についてのご質問は、ヘルプ＆サポートセンターにアクセスしてください。',
},

];
