import {UiStoreWorktime} from "@/classes/domain/module/manager/ui/store-worktime/ui-store-worktime";
import {IWorktime} from "@/classes/domain/module/manager/ui/store-worktime/i-worktime";
import {SystemConfig} from "@/configs/system";
import {sprintf} from "sprintf-js";
import {TimeUtil} from "@/classes/utils/time-util";
import {$v} from "@/classes/utils/var-util";

export class UiStoreWorktimeUtil {

    public static time2Px(time: number, pxUnit: number, timeUnit: number): number {
        // 10px move, 12.5 px = 30min
        return time === 0 ? 0 : Math.floor(time / timeUnit) * pxUnit;
    }

    public static px2Time(px: number, pxUnit: number, timeUnit: number): number {
        // 10px move, 12.5 px = 30min
        return px === 0 ? 0 : Math.floor(px / pxUnit) * timeUnit;
    }

    public static createWorkTime(wt: IWorktime, timeBase: number, timeLimit: number, timeUnit: number, pxUnit: number) {
        return new UiStoreWorktime(
            wt,
            timeBase,
            timeLimit,
            timeUnit,
            pxUnit,
        );
    }

    public moveY(uiwt: UiStoreWorktime, moveY: number, pxUnit: number, timeUnit: number): UiStoreWorktime {

        const wt = {...uiwt.worktime};
        const moveTime = (moveY === 0) ? 0 : self.px2Time(moveY, pxUnit, timeUnit);
        wt.st = wt.st + moveTime;

        return new UiStoreWorktime(
            wt,
            uiwt.timeBase,
            uiwt.timeLimit,
            uiwt.pxUnit,
            uiwt.timeUnit,
        );
    }

    /**
     * 営業時間の取りまとめ出力
     * @param wts
     * @param weeks
     */
    public static worktimesL(wts: IWorktime[], weeks: any = null): string[] {

        weeks = weeks | SystemConfig.weeks as any;
        let r = [
            // {w: [], l: '00:00-00:00'}
        ];
        const sep = SystemConfig.weekSeparator;
        wts.map((_: IWorktime) => {

            const tL = sprintf('%s%s%s',
                TimeUtil.m2hmL(_.st),
                sep,
                TimeUtil.m2hmL(_.end)
            );

            const current = r.findByKey('l', tL);
            if (!!current) {
                r = r.map((__: any) => {
                    if (__.l !== tL) {
                        return __;
                    } else {
                        return {
                            w: __.w.from(_.w),
                            l: __.l,
                        };
                    }
                });
            } else {
                r.push({
                    w: [_.w],
                    l: tL,
                });
            }
        });

        return r.map((_: any, idx: number) => {
            return ((idx > 0) ? self.weekL(_.w) : '') + _.l;
        });
    }

    public static weekL(wks: string[], weeks: any = null): string {
        weeks = Array.isArray(weeks) ? weeks : SystemConfig.weeks;

        // console.log('wks > ', wks);
        if (wks.length === 8) {
            return '';
        }

        let wt = [];
        let last = -1;
        let grp = [];
        const sep = SystemConfig.weekSeparator;

        weeks.map((w: any, idx: number) => {

            if (wks.indexOf(w.key) < 0) {
                if (grp.length > 0 && grp.length < 3) {
                    wt.push(grp.join(','));
                } else if (grp.length > 2) {
                    wt.push(grp.first() + sep + grp.last());
                }
                grp = [];
                return;
            }

            const next = (weeks.length > idx) ? weeks[idx + 1] : null;
            const nk = $v.p(next, 'key');

            if (nk === 'w7' || wks.indexOf(nk) < 0) {
                grp.push(w.labelS);
                if (grp.length > 0 && grp.length < 3) {
                    wt.push(grp.join(','));
                } else if (grp.length > 2) {
                    wt.push(grp.first() + sep + grp.last());
                } else {
                    wt.push(w.labelS);
                }
                grp = [];
            } else {
                grp.push(w.labelS);
            }

            last = idx;
        });

        console.log('weekL > ', wks, wt.join(''));

        return wt.join();
    }
}

const self = UiStoreWorktimeUtil;
