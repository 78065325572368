/*

	YH: Data Spread Sheet is Here
	https://docs.google.com/spreadsheets/d/1GP-wc5RwVL54UcYqxjH_ZfCQWY7IAfuL_BSC2SXCL8s/edit?usp=sharing

*/

export const DummyPageStaff =
{
	id: 'staff',
	name: 'Staff',
	type: 'page',
	pattern: {
		index: 'c-page-staff-index-standard',
		detail: 'c-page-staff-detail-standard',
	},
	aspect: 'auto',
};



















