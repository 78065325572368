import {Component} from "nuxt-property-decorator";
import RoutingUtil from "@/classes/view/routing-util";
import AAccount from '@/classes/page/a-account';
import {accountModule} from '@/store/account';
import {appModule} from '@/store/app';

import MSecondaryAccount from '@/components/account/account/secondary/MSecondaryAccount.vue';
import MDomainsDetail from '@/components/part/account/MDomainsDetail.vue';

@Component({
    components: {
        MSecondaryAccount,
	    MDomainsDetail
    }
})
export default class AccountDomainsDetail extends AAccount {

	public state: string = accountModule.dev_domainStates;

	public mypagetype: string = 'account';
	public mydivider: string = 's';

    // SSRでサーバーサイドの処理から変数をセット
    public async asyncData(context: any): Promise<any> {
        return {
            test: 128,
            title: 'Indexa',
        }
    }

    // ヘッダタグの構成要素
    public head() {
        return {
            //title: this.title
        }
    }

    ///////// 全ページ共通 ////////////////////////////////////////////
    public transition(to: any, from: any) {
		return RoutingUtil.transition(to, from);
	}

    public created(){

		///////// 全ページ共通 ////////////////////////////////////////////
		this.setPagetype( appModule, this.mypagetype, this.mydivider );

    }

}
