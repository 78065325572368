import AVueComponent from "@/classes/page/a-vue-component";
import {Component, Prop, Vue} from '@/node_modules/nuxt-property-decorator';

export default class APopup extends AVueComponent {
	
    @Prop({default: ''})
    public title: string;
    
    @Prop({default: ''})
    public titleicon: string;
    
    @Prop({default: ''})
    public body: string;
    
    @Prop({default: ''})
    public img: string;
    
    @Prop({default: ''})
    public closelink: string;
    
}