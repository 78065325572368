import {Component, Watch, Prop, Vue} from "vue-property-decorator";
import {themeMainModule} from '@/store/theme/theme-main';
import {editModule} from '@/store/edit';
import {themeStateModule} from '@/store/theme/theme-state';

import * as $ from 'jquery';

@Component
export default class CLoaderFrame extends Vue {

	public styleFrame: any = {};
    public displayLoader: boolean = false;

    //device sizeが変わったときに一旦ローディング
// 	@Watch('deviceSize')
// 	public watchDeviceSize() {
//     	this.start();
// 	}

    //ManagerでScaleを編集してるときにローダーを表示する
	@Watch('logoLoadingScale')
	public watchLogoLoadingScale() {
    	this.start();
	}

	public mounted() {

        setTimeout( () => {
            this.start();
        }, 5);

        //LOADED OUT: Logoの読み込みがおわったら。
        setTimeout( () => {
            themeStateModule.updateLoadingLogo(true);
        }, 700);
	}


    public start() {

    	//Start Loading
		this.$nextTick(() => {

            //position: fixed のサイズの調整
        	if (themeMainModule.manager) {
                this.styleFrame = {
                    top: `${this.mainFrameOffset.top}px`,
            	    width: `${themeMainModule.mainFrameWidth}px`,
            	    height: `${themeMainModule.mainFrameHeight}px`,
                };
        	}

        	themeStateModule.updateLoadingMain(true);

	        setTimeout( () => {
                themeStateModule.updateLoadingLogo(false);
            }, 6600);

	        setTimeout( () => {
                themeStateModule.updateLoadingMain(false);
            }, 7400);
        });
    }

    public get type(): any {
// 	    return null;
        return themeMainModule.project.loader.type;
    }

	public get mainFrameOffset(): any {
    	return themeMainModule.mainFrameOffset;
	}

// 	public get deviceSize(): any {
//     	return themeMainModule.deviceSize;
// 	}

    //ManagerでScaleを編集してるときにローダーを表示する
	public get logoLoadingScale(): any {
    	return themeMainModule.loadingLogoScale;
	}

	public get isLoadedLogo(): any {
    	return themeStateModule.loadingLogo;
	}

}



