import {Component, Prop, Vue} from "vue-property-decorator";
import AVueComponent from '@/classes/page/a-vue-component';
import {sortModule} from '@/store/sort';

@Component
export default class MList extends AVueComponent {

    //Link
    @Prop({
        default: () => {
        }
    })
    public to: any;

    //Sort
    @Prop({default: false})
    public sort: boolean;

    //Data
    @Prop({
        default: () => {
        }
    })
    public list: any;

    /*
	    リンク
    */
    public onTo(value: any) {

        if (!value) {
            return
        }
        //並び替えモードじゃないときだけリンク
        if (!sortModule.run) {
            if (!value.type) {
                return
            }

            if (value.type == 'popup') {
                this.onPopupOpen(value.to);
            } else {
                this.$router.push(value.to);
            }
        }
    }

    // Computed ///////////////////////////////////////////////
    public get vlist(): any {
        return this.list;
    }

    // - draggable
    public set vlist(list: any) {
        this.$emit('sort', list);
    }


    ///////// MODE ////////////////////////////////////////////

    /*
	    並び替え&削除
    */
    public get isSort(): any {
        return sortModule.run;
    }

    public get isSortMode(): any {
        console.log('-mode-' + sortModule.mode);
        return '-mode-' + sortModule.mode;
    }

    public get classSortMode(): any {
        return sortModule.run ? '-mode-' + sortModule.mode : '';
    }


    // Events ///////////////////////////////////////////////////
    public onClickDelete(item: any) {
        // console.log('MList.onClickDelete > item > ', item);
        this.$emit('delete', item);
    }

    public onSort() {
        console.log('MList.onSort()');
        this.$emit('sort', this.list);
    }
}
