import {ISiteStoreWorktime} from "@/classes/domain/module/model/site/store/i-site-store";
import {AppConst, Lang} from "@/classes/app/app-const";
import {SelectUtil} from "@/classes/utils/select-util";
import {LangLabel} from "@/configs/lang/label";
import {$v} from "@/classes/utils/var-util";
import {LangLabelUtil} from "@/classes/domain/lang/lang-label-util";

export class HolidayUtil {

    public static readonly langPath: string = 'holiday';
    public static readonly everyWeekKey: string = 'everyWeek';

    public static readonly readonlyformats: any[] = [
        {lang: Lang.En, label: '%cycle% Evenry Month'},
        {lang: Lang.Ja, label: '毎週'},
    ];

    public static get ws(): string[] {
        return AppConst.Weeks;
    }

    /**
     *
     */
    public static get everyWeeks(): string[] {
        const r = [];
        Object.keys(LangLabel).map((lang: string) => {
            const _ = self.src(lang).findByKey('key', self.everyWeekKey);
            r.push(_.label);
        });

        return r;
    }

    public static src(lang: string): any[] {
        return $v.p(LangLabel[lang], self.langPath, []);
    }

    // 営業時間が全部同じ
    public static isOneWorktime(worktime: ISiteStoreWorktime[]): boolean {

        let eq = true;
        const st = worktime[0].st;
        const end = worktime[1].end;

        self.ws.map((_: string) => {
            const wt = worktime[st];
            if (wt.st !== st && wt.end !== end) {
                eq = false;
            }
        });

        return eq;
    };

    /**
     * "毎週" を示す Cycleか
     * @param cycle
     */
    public static isEvenryWeekCycle(cycle: number): boolean {
        return (cycle === 0);
    }

    /**
     * "毎週"か
     * @param label
     */
    public static isEvenryWeek(label: string): boolean {

        // console.log('isEveryWeek > ', self.everyWeeks.indexOf(label) ? 'T' : 'F',
        //     label,
        //     self.everyWeeks.indexOf(label));
        return (self.everyWeeks.indexOf(label) >= 0);
    }

    /**
     *
     * @param cycle
     * @param weeks
     * @param lang
     */
    public static format(cycle: number, weeks: string[], lang: Lang = Lang.Ja): string {

        const cycleL = SelectUtil.selectedL(cycle, 'store-holiday-pattern');
        return '';
    }

    /**
     *
     * @param txt
     * @param lang
     */
    public static rejectEveryWeek(txt: string, lang: Lang = Lang.Ja): string {
        switch (lang) {
            case Lang.Ja:
                return txt.replace(/毎週/, '');
            case Lang.En:
                return txt.replace(/every week/i, '');
            default:
                return txt;
        }
    }

    /**
     * キーワードの変換
     * @param key
     * @param from
     * @param to
     */
    public static trans(key: string, from: Lang = Lang.Ja, to: Lang = Lang.En): string {
        const lkey = self.findLangKeyByTxt(key, from);
        return $v.p((LangLabel[to].holiday).findByKey('key', lkey), 'label', '');
    }

    /**
     * キーワードから言語Indexを返す
     * @param key
     * @param lang
     */
    public static findLangKeyByTxt(key: string, lang: Lang = Lang.Ja): string {
        return LangLabelUtil.findLangKeyByTxt(key, 'holiday', lang);
    }
}

const self = HolidayUtil;
