import {Component, Watch, Prop, Vue} from '@/node_modules/nuxt-property-decorator';
import APopup from "@/classes/page/a-popup";
import {popupModule} from '@/store/popup';
import {uploadlayerModule} from '@/store/uploadlayer';
import {appUploadimgMaterial as mModule, IMaterial} from "@/store/app/uploadimg/material";
import WUtil from "@/classes/view/w-util";
import VarUtil from "@/classes/utils/var-util";
import {sprintf} from "sprintf-js";
import {ExtUploadImg} from "@/classes/ext/ext-upload-img";
import {IUploadimgState, uploadimgModule} from "@/store/uploadimg";
import {SystemConfig} from "@/configs/system";
import {upfileModule} from "@/store/upfile";

@Component
export default class MPopupUploadImgUnsplash extends APopup {

    public modeSearch: boolean = false;
    public extUploadImg: ExtUploadImg;
    public state = {
        config: null as any,
        view: {
            ready: false,
        },
    };

    // Base ////////////////////////////////////////////////////////
    public created() {
        super.created();
        this.extUploadImg = new ExtUploadImg(this);
        this.state.config = this.extUploadImg.getImageConfig();
    }

    public async mounted() {
        WUtil.browser(async () => {
            console.log('browser');
            await this.load();
            this.state.view.ready = true;
        });

        console.log('config is > ', this.state.config);
    }


    ////////// POPUP LAYER 共通処理 /////////////////////////////////
    public onClose(to: string = ''): any {
        this.$emit('close');
    }

    // Computed ///////////////////////////////////////////////////
    public get id(): string {
        return uploadimgModule.current;
    }

    public get isReady(): boolean {
        return this.state.view.ready;
    }

    public get isLoading(): boolean {
        return this.extLoading.isLoading;
    }

    public get recordRows(): IMaterial[][] {

        const rows = [];
        let cols = [];
        for (let i = 0; i < mModule.records.length; i++) {

            cols.push(mModule.records[i]);

            if (cols.length === 3
                || (mModule.records.length - 1 === i)) {
                rows.push(Array.from(cols));
                cols = [];
            }
        }

        return rows;
    }

    public get imgState(): IUploadimgState {
        return uploadimgModule.uploadimgs.findByKey('id', this.id);
    }

    public get uploadConfig(): any {
        return SystemConfig.uploadimg.purpose.findByKey('key', this.id);
    }

    // Methods /////////////////////////////////////////////////////
    public async load() {
        console.log('MPopupUploadImgUnsplash.fetch()');
        await this.extLoading.load(async () => {
            await mModule.fetch();
        });
        console.log(' - ', mModule.records);
    }

    public userNameL(r: IMaterial): string {
        return VarUtil.path(r, 'ex.user.name');
    }

    public thumbL(r: IMaterial): string {
        return VarUtil.path(r, 'ex.urls.small');
    }

    public linkL(r: IMaterial): string {
        return sprintf('https://unsplash.com/photos/%s',
            VarUtil.path(r, 'srcId'));
    }


    // Events ////////////////////////////////////////////////////////////////
    public onUploadBack(value: string) {
        uploadlayerModule.updateLayer(value);
    }

    public onSearch() {
        this.modeSearch = !this.modeSearch;
        popupModule.closeWrapper ? popupModule.updateCloseWrapper(false) : popupModule.updateCloseWrapper(true);
        console.log(popupModule.closeWrapper);
    }

    public async onClickThumbnail(r: IMaterial) {

        // サイズが大きいのでConfirmを挟みたい
        const width = VarUtil.path(r, 'ex.size.width');
        const height = VarUtil.path(r, 'ex.size.height');
        if (!confirm(sprintf("写真を選択しますか？(%spx ✕ %spx)", width, height))) {
            return;
        }

        const config = this.state.config;
        const mimeType = 'image/jpeg';
        const img = new Image();
        img.crossOrigin = "https://images.unsplash.com";
        img.onload = () => {
            // する
            let canvas = document.createElement('canvas');
            canvas.width = width;
            canvas.height = height;
            let ctx = canvas.getContext('2d');

            // @ts-ignore
            ctx.drawImage(img, 0, 0, width, height);
            ctx.canvas.toBlob(async (blob: Blob) => {
                const imageFile = new File([blob], `unsplash-${r.srcId}.jpg`, {
                    type: mimeType,
                    lastModified: Date.now(),
                });

                await this.extUploadImg.processImage(imageFile, config, this.imgState);

                this.$parent.$emit('close');

            }, mimeType, config.quality || 1);
        };
        img.src = VarUtil.path(r, 'ex.urls.full');
    }
}
