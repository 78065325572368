import {IResizerRequest} from "@/classes/core/module/image/i-resizer-request";
import ImageUtil from "@/classes/utils/image-util";

export class ImageService {

    public async resize(request: IResizerRequest | any): Promise<any> {
        return await ImageUtil.resize(request);
    }

    public async toDataURI(buffer: any): Promise<string> {
        return await ImageUtil.toDataURI(buffer);
    }
}
