import {Component, Watch, Prop, Vue} from '@/node_modules/nuxt-property-decorator';
import APopup from "@/classes/page/a-popup";
import {appModule} from '@/store/app';
import {tabModule} from '@/store/tab';

import MTab from '@/components/module/MTab.vue';
import MTabContents from '@/components/module/MTabContents.vue';
import MTabMove from '@/components/module/MTabMove.vue';
import {appAuthModule} from "@/store/app/auth";
import {ExtEdit} from "@/classes/ext/ext-edit";

import $ from 'jquery';

@Component({
    components: {
        MTab,
        MTabContents,
        MTabMove
    }
})
export default class MPopupLogin extends APopup {

    public tabmap: any = [
        {
            id: 1,
            tabid: 1,
            label: 'ソーシャルログイン'
        },
        {
            id: 2,
            tabid: 2,
            label: 'メールでログイン'
        },
        {
            id: 3,
            tabid: 2
        }
    ];

    public state = {
        config: {
            editId: 'mPopupLogin',
        },
        view: {
            ready: false,
        },
        input: {
            email: '',
        },
        errors: [],
    };

    public extEdit: ExtEdit;

    // Created ///////////////////////////////////////////////////
    public async mounted() {
        this.state.view.ready = true;
    }

    // Computed ////////////////////////////////////////////////////
    public get input(): any {
        return this.state.input;
    }

    // Method //////////////////////////////////////////////////////
    public async loginByEmail() {
        console.log('loginByEmail() > ', this.input);
        try {
            if (await appAuthModule.postAuth(this.input.email)) {
                await tabModule.moveTab({k: 'login', show: 3});
            }
        } catch (e) {
            console.log('error > ', e);
        }
    }

    // Events////////////////////////////////////////////////////////
    public onSignin() {
        appModule.updateSignin(true);
        this.$emit('close');
        this.$router.push('/manager/');
    }

    public async onInput(e: any) {
        // console.log('onInput > ', e);
        this.state.input = Object.assign({}, this.state.input, {
            [e.name]: e.value,
        });
    }

    public async onClickLoginEmail() {
        await this.loginByEmail();
    }

    public async onSubmit() {
        await this.loginByEmail();
    }


    ////////// POPUP LAYER 共通処理 /////////////////////////////////
    public onClose(to: string = ''): any {
        if (to != '') {
            this.$router.push(to);
        }
        this.$emit('close');
    }
}
