





























































	// @ts-ignore
    import _ from './MPopupAccountPayDetail.ts'; export default _;
