export default class LayoutsUtil {

    /*
        GRID カラム数
        $el には対象のリストアイテムのクラスを入れる
        $el = $('.-openclose .c-list-item')
    */
    public static gridColumnNumber($el: any): any {

        //1からYをみていって、Yが前のYと違った時の番号がカラム数になる
        let gridnum: number = 1;
        let y: number = $el.eq(1).offset().top;

        $el.each(function (i: number, $i: any) {

            if(!$i.offset) {
                return;
            }

            var now = $i.offset().top;

            //Grid数の計算
            if (now != y) {

                gridnum = i;
                return false;

            } else {
                y = now;
            }
        });

        return gridnum;
    }

}
