/*

	YH: Data Spread Sheet is Here
	https://docs.google.com/spreadsheets/d/1LU73eygtCKuQDHk5ezhlTSXi4wo650g0pEm5kdNfiq4/edit?usp=sharing

*/

export const Logos = [

{
	id: 'poppinsThin',
	type:'sans',
	name:'Poppins Thin',
	class: 's-logo--poppinsThin',
	lang:'en',
	mfamily: 'Poppins:100',
	sfamily: 'Poppins:300'
},
{
	id: 'poppinsMedium',
	type:'sans',
	name:'Poppins Medium',
	class: 's-logo--poppinsMedium',
	lang:'en',
	mfamily: 'Poppins:500',
	sfamily: 'Poppins:500'
},
{
	id: 'poppinsBold',
	type:'sans',
	name:'Poppins Bold',
	class: 's-logo--poppinsBold',
	lang:'en',
	mfamily: 'Poppins:700',
	sfamily: 'Poppins:700'
},
{
	id: 'boogaloo',
	type:'comic',
	name:'Boogaloo',
	class: 's-logo--boogaloo',
	lang:'en',
	mfamily: 'Boogaloo',
	sfamily: 'Boogaloo'
},
{
	id: 'cabinSketch',
	type:'comic',
	name:'Cabin Sketch',
	class: 's-logo--cabinSketch',
	lang:'en',
	mfamily: 'Cabin+Sketch:700',
	sfamily: 'Cabin+Sketch:400'
},
{
	id: 'dancingScript',
	type:'script',
	name:'Dancing Script',
	class: 's-logo--dancingScript',
	lang:'en',
	mfamily: 'Dancing+Script:400',
	sfamily: 'Lexend+Deca'
},
{
	id: 'caveatRegular',
	type:'comic',
	name:'Caveat Regular',
	class: 's-logo--caveatRegular',
	lang:'en',
	mfamily: 'Caveat:400',
	sfamily: 'Caveat:400'
},
{
	id: 'gaegu',
	type:'comic',
	name:'Gaegu',
	class: 's-logo--gaegu',
	lang:'en',
	mfamily: 'Gaegu:400',
	sfamily: 'Gaegu:400'
},
{
	id: 'amaticSCRegular',
	type:'comic',
	name:'Amatic SC Regular',
	class: 's-logo--amaticSCRegular',
	lang:'en',
	mfamily: 'Amatic+SC:400',
	sfamily: 'Amatic+SC:400'
},
{
	id: 'emilysCandy',
	type:'serif',
	name:'Emilys Candy',
	class: 's-logo--emilysCandy',
	lang:'en',
	mfamily: 'Emilys+Candy',
	sfamily: 'Emilys+Candy'
},
{
	id: 'frederickaTheGreat',
	type:'comic',
	name:'Fredericka the Great',
	class: 's-logo--frederickaTheGreat',
	lang:'en',
	mfamily: 'Fredericka+the+Great',
	sfamily: 'Fredericka+the+Great'
},
{
	id: 'greatVibes',
	type:'script',
	name:'Great Vibes',
	class: 's-logo--greatVibes',
	lang:'en',
	mfamily: 'Great+Vibes',
	sfamily: 'Poppins:400'
},
{
	id: 'crimpsonTextSB',
	type:'serif',
	name:'Crimson Text SB',
	class: 's-logo--crimpsonTextSB',
	lang:'en',
	mfamily: 'Crimson+Text:600',
	sfamily: 'Lexend+Deca'
},
{
	id: 'comfortaaBold',
	type:'rounded',
	name:'Comfortaa Bold',
	class: 's-logo--comfortaaBold',
	lang:'en',
	mfamily: 'Comfortaa:700',
	sfamily: 'Comfortaa:700'
},
{
	id: 'alluraRegular',
	type:'script',
	name:'Allura',
	class: 's-logo--alluraRegular',
	lang:'en',
	mfamily: 'Allura',
	sfamily: 'Poppins:500'
},
{
	id: 'abrilFatface',
	type:'serif',
	name:'Abril Fatface',
	class: 's-logo--abrilFatface',
	lang:'en',
	mfamily: 'Abril+Fatface',
	sfamily: 'Abril+Fatface'
},
{
	id: 'interRegular',
	type:'sans',
	name:'Inter Regular',
	class: 's-logo--interRegular',
	lang:'en',
	mfamily: 'inter-regular',
	sfamily: 'inter-regular'
},
{
	id: 'interMedium',
	type:'sans',
	name:'Inter Medium',
	class: 's-logo--interMedium',
	lang:'en',
	mfamily: 'inter-medium',
	sfamily: 'inter-medium'
},
{
	id: 'interMediumClear',
	type:'sans',
	name:'Inter Regular',
	class: 's-logo--interMediumClear',
	lang:'en',
	mfamily: 'inter-medium-clear',
	sfamily: 'inter-medium-clear'
},
{
	id: 'interSemiBold',
	type:'sans',
	name:'Inter Semi Bold',
	class: 's-logo--interSemiBold',
	lang:'en',
	mfamily: 'inter-semi-bold',
	sfamily: 'inter-semi-bold'
},
{
	id: 'interBold',
	type:'sans',
	name:'Inter Bold',
	class: 's-logo--interBold',
	lang:'en',
	mfamily: 'inter-bold',
	sfamily: 'inter-bold'
},
{
	id: 'lexendDeca',
	type:'sans',
	name:'Lexend Deca',
	class: 's-logo--lexendDeca',
	lang:'en',
	mfamily: 'Lexend+Deca',
	sfamily: 'Lexend+Deca'
},
{
	id: 'lexendExa',
	type:'sans',
	name:'Lexend Exa',
	class: 's-logo--lexendExa',
	lang:'en',
	mfamily: 'Lexend+Exa',
	sfamily: 'Lexend+Exa'
},
{
	id: 'pompiere',
	type:'comic',
	name:'Pompiere',
	class: 's-logo--pompiere',
	lang:'en',
	mfamily: 'Pompiere',
	sfamily: 'Pompiere'
},
{
	id: 'mPLUS1pExbold',
	type:'sans',
	name:'M PLUS 1p',
	class: 's-logo--mPLUS1pExbold',
	lang:'ja',
	mfamily: 'M+PLUS+1p:800',
	sfamily: 'Noto+Sans+JP:900'
},
{
	id: 'notoSansBold',
	type:'sans',
	name:'Noto Sans Bold',
	class: 's-logo--notoSansBold',
	lang:'ja',
	mfamily: 'Noto+Sans+JP:700',
	sfamily: 'Noto+Sans+JP:900'
},
{
	id: 'notoSerifBold',
	type:'serif',
	name:'Noto Serif JP',
	class: 's-logo--notoSerifBold',
	lang:'ja',
	mfamily: 'Noto+Serif+JP:700',
	sfamily: 'Noto+Serif+JP:700'
},
{
	id: 'notoSerifLight',
	type:'serif',
	name:'Noto Serif JP',
	class: 's-logo--notoSerifLight',
	lang:'ja',
	mfamily: 'Noto+Serif+JP:300',
	sfamily: 'Noto+Serif+JP:400'
},
{
	id: 'kosugi',
	type:'sans',
	name:'Kosugi',
	class: 's-logo--kosugi',
	lang:'ja',
	mfamily: 'Kosugi',
	sfamily: 'Noto+Sans+JP:900'
},
{
	id: 'kosugiMaru',
	type:'rounded',
	name:'Kosugi Maru',
	class: 's-logo--kosugiMaru',
	lang:'ja',
	mfamily: 'Kosugi+Maru',
	sfamily: 'Noto+Serif+JP:700'
},

];
