import {Component, Watch, Prop, Vue} from "vue-property-decorator";
import TxtUtil from "@/classes/view/txt-util";
import LogoUtil from '@/classes/view/logo-util';
import {themeMainModule} from '@/store/theme/theme-main';

import {TxtSize} from '@/configs/auto-resize/txt-size';
import {$v} from "@/classes/utils/var-util";

@Component({
    components: {}
})
export default class CMvContentTxt extends Vue {

    @Prop({default: null})
    public size: number;

    @Prop({default: null})
    public body: string;

    public styleSize: any = {};
    public styleScale: any = {};
    public cssvarsLineHeight: any = {};
    public baseLine: any = {};
    public baseFontsize: any = {};
    public visible: boolean = false;

    @Watch('stitle')
    public watchName() {
        this.resize();
    }

    @Watch('deviceSize')
    public watchDeviceSize() {
        this.$nextTick(() => {
            this.resize();
        });
    }

    ///// Test ///////////////////////////////////////////////

    @Prop({default: null})
    public sizeAcc: number;

    @Prop({default: null})
    public fontsizeAcc: number;

    @Prop({default: null})
    public lineAcc: number;


    @Watch('sizeAcc')
    public watchTestSizeAcc() {
        this.resize();
    }

    @Watch('fontsizeAcc')
    public watchTestFontSizeAcc() {
        this.resize();
    }

    @Watch('lineAcc')
    public watchTestLineAcc() {
        this.resize();
    }

    @Watch('size')
    public watchSize() {
        this.resize();
    }

    ///// Test End ///////////////////////////////////////////////////

    public mounted() {

        this.$nextTick(() => {
            this.resize();
        });
    }

    public resize() {

        const headEl = this.$refs.head as HTMLElement;

        this.baseLine = {
            ja: parseInt(window.getComputedStyle(headEl).getPropertyValue('--mv-txt-base-line-ja'), 10),
            en: parseInt(window.getComputedStyle(headEl).getPropertyValue('--mv-txt-base-line-en'), 10),
        };

        if (!this.baseLine && !headEl && !this.visible) {
            return;
        }

        //処理が終わるまで非表示にする
        this.visible = false;

        //オリジナルwidthを得るために一旦transformを解除
        this.styleScale = {};
        this.cssvarsLineHeight = {};

        //Scale計算
        this.$nextTick(() => {

            let fontsize = 0;

            //enタグがついているか確認
            if ($(this.$refs.inner).length > 0) {
                if ($('.c-mv-content-txt-head-inner span span').hasClass('en')) {
                    fontsize = parseInt(window.getComputedStyle(headEl).getPropertyValue('--mv-txt-base-size-en'));

                } else {
                    fontsize = parseInt(window.getComputedStyle(headEl).getPropertyValue('--mv-txt-base-size-ja'));
                }
            }

            /*
                返す値
                w,h : 外枠のサイズ
                scale : transformの値
            */
            const scale = LogoUtil.resizeScaleByArea(
                $(this.$refs.inner),
                this.stitle,
                this.nsize,
                this.nsizeAcc,
                this.manualScale,
                fontsize,
                this.nfontsizeAcc,
            );

            const resizedLine: any = LogoUtil.resizeLineHeightByRatio(
                $(this.$refs.inner),
                this.baseLine,
                this.nlineAcc,
                this.stitle,
            );

            this.styleScale = {
                transform: ($v.num(scale) > 0) ? `scale(${scale})` : null,
            };

            this.cssvarsLineHeight = {
                '--mv-txt-line-ja': `${resizedLine.ja}px`,
                '--mv-txt-line-en': `${resizedLine.en}px`,
            };

            this.visible = true;

        });
    }


    public get nsize(): any {

        if (this.size) {
            return this.size;
        }
        return TxtSize[0].size;
    }

    public get nsizeAcc(): any {

        if (this.sizeAcc) {
            return this.sizeAcc;
        }
        return TxtSize[0].sizeAcc;
    }

    public get nfontsizeAcc(): any {

        if (this.fontsizeAcc) {
            return this.fontsizeAcc;
        }
        return TxtSize[0].fontsizeAcc;
    }

    public get nlineAcc(): any {

        if (this.lineAcc) {
            return this.lineAcc;
        }
        return TxtSize[0].lineAcc;
    }

    public get stitle(): any {

        if (this.body) {
            return this.body;
        }

        return themeMainModule.mvContentTxt;
    }

    /*
        手動スケールの取得
    */
    public get manualScale(): any {
        return themeMainModule.mvContentTxtScale;
    }

    /*
        文字種別
    */
    public get classCaseTitle(): any {
        return TxtUtil.detectCase(this.stitle);
    }

    public get deviceSize(): any {
        return themeMainModule.deviceSize;
    }

}

