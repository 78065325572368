import {Component, Watch, Vue} from 'nuxt-property-decorator';
import AVueComponent from '@/classes/page/a-vue-component';
import CCssvarsMix from '@/classes/theme/mixin/c-cssvars-mix';
import MHelpFrame from '@/components/module/MHelpFrame.vue';
import MTourFrame from '@/components/module/MTourFrame.vue';
import MNavFrame from '@/components/module/MNavFrame.vue';
import MNoticeFrame from '@/components/module/MNoticeFrame.vue';
import MTipsFrame from '@/components/module/MTipsFrame.vue';
import MPopupFrame from '@/components/popup/MPopupFrame.vue';
import MSelectFrame from '@/components/select/MSelectFrame.vue';

//Account
import {ownersiteModule} from '@/store/ownersite';

@Component({
    mixins: [CCssvarsMix],
    components: {
	    MTourFrame,
	    MHelpFrame,
	    MNavFrame,
	    MNoticeFrame,
	    MTipsFrame,
	    MPopupFrame,
	    MSelectFrame,
    }
})
export default class Dev extends AVueComponent {


}





