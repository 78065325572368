
















	// @ts-ignore
    import _ from './MSecondaryNews.ts'; export default _;
