

























// @ts-ignore
import _ from "./CPageIndexIndexStandard.ts";

export default _;
