import Quill from 'quill';
import QuillUtil from './quill-util';
import VarUtil from '@/classes/utils/var-util';

export interface IMedia {
    mkey: string;
    url: string;
}

export default class QuillOperator {

    private _rte: Quill;
    private _target: string;
    private _medias: IMedia[];
    private _mediaCount: number = 0;
    private _lock: boolean = false;
    private _last: any;
    private _article: any;

    get rte(): Quill {
        return this._rte;
    }

    set rte(value: Quill) {
        this._rte = value;
    }

    set medias(value: IMedia[]) {
        this._medias = value;
        this._mediaCount = value.length;
    }

    get mediaCount(): number {
        return this._mediaCount;
    }

    set mediaCount(value: number) {
        this._mediaCount = value;
    }

    get lock(): boolean {
        return this._lock;
    }

    set lock(value: boolean) {
        this._lock = value;
    }

    get last(): any {
        return this._last;
    }

    set last(value: any) {
        this._last = value;
    }

    get article(): any {
        return this._article;
    }

    set article(value: any) {
        this._article = value;
    }

    public inited(): boolean {
        return (typeof (this._rte['on']) === 'function');
    }

    /**
     *
     */
    public init(target: string = '#editor') {

        this._target = target;

        const toolbarOptions = {
            container: [[{'header': 2}, 'bold', 'link'], ['image', 'video']]
        };

        const icons = Quill.import('ui/icons');
        icons['bold'] = '<div class="m-icon-q-strong"></div>';
        icons['header'] = '<div class="m-icon-q-head"></div>';
        icons['link'] = '<div class="m-icon-link"></div>';
        icons['image'] = '<div class="m-icon-img"></div>';
        icons['video'] = '<div class="m-icon-video"></div>';

        const block = Quill.import('blots/block');

        console.log('QuillOperator.init()');
        this.rte = new Quill(target, {
            modules: {
                toolbar: toolbarOptions
            },
            placeholder: 'ここに本文を入力...',
            theme: 'snow'
        });
    }

    public getPostContent(): any {

        let file = [];
        let content = VarUtil.export<any>({}, this.getContents());

        (content.ops as any[]).map((_: any) => {
            if (!VarUtil.path(_, 'insert.image')) {
                return _;
            }

            if (this.isDataURI(_.insert.image)) {
                console.log('getPostBody > insert.image', _);

                let imgkey = VarUtil.path(_, 'attributes.img');
                if (!imgkey) {
                    imgkey = `img${this.mediaCount++}`;
                }

                file.push({
                    content: _.insert.image,
                    img: imgkey
                });

                _.insert.image = imgkey;
            } else {
                const img = this._medias.findByKey('url', VarUtil.path(_, 'insert.image'));
                _.insert.image = img ? img.mkey : _.insert.image;
            }

            return _;
        });

        console.log('QuillOperator.getpostBody > delta > ', content);

        return {
            body: JSON.stringify(content),
            file: file
        };
    }

    public initContents(content: any) {

        if (!VarUtil.has(content, 'ops')) {
            throw 'content ops failed';
        }

        const ncontent = {
            ops: content.ops.map((_: any) => {
                if (!VarUtil.has(_, 'insert')
                    || !VarUtil.has(_, 'insert.image')) {
                    return _;
                } else {
                    const mkey = VarUtil.path(_, 'insert.image');
                    const media = this._medias.findByKey('mkey', mkey);
                    const url = VarUtil.path(media, 'url');

                    if (!media || !url) {
                        return _;
                    } else {
                        return {
                            insert: {
                                image: url,
                            },
                        };
                    }
                }
            }),
        };

        this.setContents(ncontent);
    }

    /**
     * @param content
     */
    public isDataURI(content: string): boolean {
        return QuillUtil.isDataURI(content);
    }

    // Wrappers ////////////////////////////////////////////////////////////////////
    public insertEmbed(index: number, type: string, source: string = 'api') {
        this.rte.insertEmbed(index, type, source);
    }

    public getContents(): any {
        return this._rte.getContents();
    }

    public setContents(contents: any) {
        this._rte.setContents(contents);
    }

    public updateContents(contents: any) {
        this.setContents(contents);
    }

    public on(eventName: 'text-change' | 'selection-change' | 'editor-once', process: any) {
        this._rte.on(eventName as any, process as any);
    }

    public once(eventName: 'text-change' | 'selection-change' | 'editor-once', process: any) {
        this._rte.once(eventName as any, process as any);
    }

    public off(eventName: 'text-change' | 'selection-change' | 'editor-once', process: any) {
        this._rte.once(eventName as any, process as any);
    }
}
