import {IApiRequest} from './i-api-request';
import {sprintf} from 'sprintf-js';
import ApiConst from './api-const';
import AVueComponent from '@/classes/page/a-vue-component';
import VarUtil, {$v} from '@/classes/utils/var-util';
import WUtil from "@/classes/view/w-util";
import {Env} from "@/classes/app/env";

export default class ApiUtil {
    /**
     *
     * @type {string}
     */
    public static baseUrl: string = '/api/v1';

    /**
     *
     * @param url
     * @param domain
     */
    public static url(url: string, domain: string | null = null): string {

        if (Env.DATA_REF !== 'dummy') {
            if (WUtil.isBrowser) {
                if (!domain && Env.API_HOST_BROWSER) {
                    domain = Env.API_HOST_BROWSER;
                }
            } else {
                if (!domain && Env.API_HOST_SSR) {
                    domain = Env.API_HOST_SSR;
                }
            }
        }

        const reg = new RegExp(ApiUtil.baseUrl);
        const r = VarUtil.tap<string>(url, (_: string) => {
            const __ = reg.test(url) ? _ : ApiUtil.baseUrl + url;
            return (!!domain) ? domain + __ : __;
        });

        // console.log('url is ', Env, r);

        return r;

        // return WUtil.isBrowser ? r : Env.API_HOST_SSR + r;
        // return r;
    }

    /**
     * リクエストオブジェクトからURLSearchParamsパラメータを作成する
     *
     * @param {IApiRequest} req
     * @returns {URLSearchParams}
     */
    public static request2Param(req: IApiRequest): any {
        let params = null;
        switch (req.enctype) {
            case ApiConst.EncType.Multipart:
                params = new FormData();
                break;
            default:
                params = new URLSearchParams();
                break;
        }

        if (req.param) {
            for (let i in req.param) {
                // console.log('key=%s, val=%s', i, req.param[i]);
                if (typeof req.param[i] == 'object'
                    && !(req.param[i] instanceof File)
                    && !(req.param[i] instanceof Blob)) {
                    for (let j in req.param[i]) {
                        params.append(sprintf('%s[%s]', i, j), req.param[i][j]);
                    }
                } else {
                    params.append(i, req.param[i]);
                }
            }
        }
        return params;
    }

    public static isSuccess(res: any): boolean {
        return (ApiConst.Res.Ok == res.data.result);
    }

    public static isError(res: any): boolean {
        return (ApiConst.Res.Ok != res.data.result);
    }

    public static bindError(target: any, res: any) {
        console.log('ApiUtil.bindError(): api error', res.data);

        target.errors = res.data.error.messages;
    }

    public static common(component: AVueComponent, cb: (res: any) => void): (res: any) => void {
        return (res: any) => {
            if (ApiUtil.isSuccess(res)) {
                cb(res);
            } else {
                ApiUtil.bindError(component, res);
            }
        };
    }

    public static async post(api: any, record: any = {}, extract: string = 'record'): Promise<any> {
        console.log('post > ');
        try {
            const id = $v.path(record, 'toid');
            const ex = (!!id) ? api.put(id, record) : api.post(record);
            return await ex.execAsync(extract);
        } catch (e) {
            return e;
        }
    }
}
