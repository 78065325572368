import {Vue, Component, Prop, Watch} from 'vue-property-decorator';
import {AArticleDetail} from "@/components/popup/articleDetail/AArticleDetail";
import MTextarea from "@/components/module/MTextarea";
import {ExtEdit} from "@/classes/ext/ext-edit";

@Component({
    components: {
        MTextarea,
    }
})
export default class News extends AArticleDetail {
    public get extEdit(): ExtEdit {
        return new ExtEdit(this);
    }

    public async onInput(e: any) {
        await this.extEdit.onInput(e);
    }
}
