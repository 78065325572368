/*

	YH: Data Spread Sheet is Here
	https://docs.google.com/spreadsheets/d/1GP-wc5RwVL54UcYqxjH_ZfCQWY7IAfuL_BSC2SXCL8s/edit?usp=sharing

*/

export const DummyPageMenu =
{
	id: 'menu',
	name: 'Price',
	type: 'page',
	pattern: {
		index: 'c-page-menu-index-standard',
		detail: '',
	},
	aspect: 'horizontal',
};










