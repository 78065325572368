






























import {Component, Vue} from 'nuxt-property-decorator';
import AVueComponent from '@/classes/page/a-vue-component';

import MPrice from '@/components/module/MPrice.vue';

@Component({
    components: {
	    MPrice
    }
})
export default class MSelectPayment extends AVueComponent {
	
}
	
