



































	import {Component,Prop, Vue} from 'nuxt-property-decorator';

	@Component({
	    components: {
	    }
	})
	export default class MCheckConditions extends Vue {

        @Prop({default: 'ご請求金額'})
        public title: string;

        @Prop({default: '<span class="en">Thought Branding Plan</span>'})
        public iname: string;
	}
