import {Action, getModule, Module, Mutation, VuexModule} from "@/node_modules/vuex-module-decorators";
import store from "@/classes/core/store";
import ManagerApi from "@/classes/app/api/manager-api";
import {$apm} from "@/classes/app/app-manager";

export interface IShop {
    toid: string;
    title: string;
    body: string;
    is_active: string;
    related_twitter: number;
    related_facebook: number;
    published_at: string;
    created_at: string;
    updated_at: string;
    categories?: string[];
}

export interface IShopFilter {
    type?: string;
    status?: number;
    word?: string;
    date?: string;
}

export interface IManagerShopModule {
    fetched: boolean;
    filter: any;
    page: number;
    info: any;
    records: IShop[];
    current: string;

    //
    updateCurrent(value?: string);

    //
    fetch(force?: boolean): Promise<any>;

    //
    save(record: any): Promise<any>;
}

@Module({dynamic: true, store, name: 'managerShop', namespaced: true})
class Store extends VuexModule implements IManagerShopModule {

    public fetched: boolean = false;
    public filter: any = {
        type: 'blog',
        word: '',
        status: null,
        date: '',
    };
    private _page: number = 1;
    private _info: any = null;
    private _records: IShop[] = [];
    private _current: string = '';

    public get page(): number {
        return this._page;
    }

    public get info(): IShop[] {
        return this._info;
        0
    }

    public get records(): IShop[] {
        return this._records;
    }

    public get current(): string {
        return this._current;
    }

    @Mutation updatePage(value: number) {
        this._page = value;
    }

    @Mutation
    public updateInfo(value: any) {
        this._info = value;
    }

    @Mutation
    public updateRecords(value: IShop[]) {
        this._records = value;
    }

    @Mutation
    public updateFilter(filter: IShopFilter) {
        this.filter = Object.assign({}, M.filter, filter);
    }

    @Mutation
    public updateCurrent(value: string = '') {
        this._current = value;
    }

    @Mutation
    public updateFetched(fetched: boolean) {
        this.fetched = fetched;
    }

    @Action
    public async resetFilter(): Promise<void> {
        M.updateFilter({
            status: 0,
            word: '',
            date: '',
        });
    }

    @Action
    public async fetch(force: boolean = false): Promise<any> {

        if (!force && M.fetched) {
            return;
        }

        try {
            console.debug('store/manager/shop.ts > rq > ', $apm.pj, $apm.siteid);
            const records = await ManagerApi.Shop.get(Object.assign({
                site: $apm.siteid,
                page: M.page
            }, M.filter)).execAsync('records.list');
            console.debug('store/manager/shop.ts > rs > ', records);
            M.updateRecords(records || []);
            M.updateFetched(true);

            return null;
        } catch (e) {
            console.error(e);
            return e;
        }
    }

    @Action
    public async add(): Promise<any> {
        try {
            const store = await ManagerApi.Shop.post({
                site: $apm.siteid,
            }).execAsync();
            return store;
        } catch (e) {
            console.error('Error > ', e);
            return null;
        }
    }

    @Action
    public async save(record: any): Promise<any> {
        try {
            await ManagerApi.Shop.put(record.toid, record).execAsync();
        } catch (e) {
            console.error('Error > ', e);
        }
    }
}

export const managerShopModule = getModule(Store);

const M = managerShopModule;
