import {Mutation, MutationAction, Action, VuexModule, getModule, Module} from 'vuex-module-decorators';
import store from '@/classes/core/store';
import {$v} from "@/classes/utils/var-util";

// スタイル
export enum ManagerLoaderStyle {
    // progress bar
    Progress = 'progress',
    // くるくる
    Circle = 'circle',
}

// 進行度のタイプ
export enum ManagerLoaderProgressType {
    // 1秒程度で自動で最後までいく
    Auto = 'auto',
    // progressに値を直接いれるタイプ
    Manual = 'manual',
    // ローディングが終わった時点で progressに 100をいれるタイプ
    Semiauto = 'semiauto',
}

// 背景
export enum ManagerLoaderBg {
    // 背景なし
    Transparent = 'transparent',
    // 半透明 6%
    Light = 'light',
    // 半透明 40%
    Medium = 'medium',
    // グレーで塗りつぶし
    Fill = 'fill',
}

// state's interface
export interface IManagerLoader {
    id: string;
    //Loading するかしないか
    loading: boolean;
    style: ManagerLoaderStyle;
    progressType: ManagerLoaderProgressType;
    /**
     * Loading時の背景
     */
    bg: ManagerLoaderBg;
    title: string;
    /**
     *  進行度合いのメッセージ
     *  文字列がなければ自動で % 表示される
     */
    body: string;
    /**
     * 進行度  0 ~ 100
     */
    progress: number;
}

export interface IManagerLoaderRequest {
    id: string;
    loading?: boolean;
    style?: ManagerLoaderStyle;
    progressType?: ManagerLoaderProgressType;
    bg?: ManagerLoaderBg;
    title?: string;
    body?: string;
    progress?: number;
}

// state's interface
export interface IManagerLoaderModule {
    loaders: IManagerLoader[];
}

const TEMPLATE: IManagerLoader = {
    id: '@',
    loading: false,
    style: ManagerLoaderStyle.Circle,
    progressType: ManagerLoaderProgressType.Auto,
    title: 'Loading Thought...',
    body: '',
    progress: 0,
    bg: ManagerLoaderBg.Transparent,
};

@Module({dynamic: true, store, name: 'managerLoader', namespaced: true})
class Store extends VuexModule implements IManagerLoaderModule {

    private _loaders: IManagerLoader[] = [{...TEMPLATE}];

    // Getters //////////////////////////////////////
    public get loaders(): IManagerLoader[] {
        return this._loaders;
    }

    public get titleDefault(): string {
        return 'Loading Thought...';
    }

    // - Compat //////////////////////////////////////
    public get basic(): IManagerLoader {
        return this._loaders.findByKey('id', '@');
    }

    public get loading(): boolean {
        return M.basic.loading;
    }

    public get style(): string {
        return M.basic.style;
    }

    public get progressType(): string {
        return M.basic.progressType;
    }

    public get title(): string {
        return M.basic.title;
    }

    public get body(): string {
        return M.basic.body;
    }

    public get progress(): number {
        return M.basic.progress;
    }

    public get bg(): string {
        return M.basic.bg;
    }

    // Mutations //////////////////////////////////////////
    @Mutation
    public updateLoader(value: IManagerLoaderRequest) {
        const base = M.loaders.findByKey('id', value.id);
        this._loaders = this._loaders.replaceByKey('id', {
            ...base,
            ...value,
        }).array;
    }

    @Mutation
    public removeLoader(id: string) {
        this._loaders = this._loaders.rejectByKey('id', id);
    }

    //Loadingの表示スイッチ
    @Mutation
    public updateLoading(value: boolean) {
        M.updateLoader({
            ...M.basic,
            ...{
                loading: value,
            },
        });
    }

    @Mutation
    public updateStyle(value: ManagerLoaderStyle) {
        M.updateLoader({
            ...M.basic,
            ...{
                style: value,
            },
        });
    }

    @Mutation
    public updateProgressType(value: ManagerLoaderProgressType) {
        M.updateLoader({
            ...M.basic,
            ...{
                progressType: value,
            },
        });
    }

    @Mutation
    public updateProgress(value: number) {

        if (M.basic.progress === value) {
            return;
        }

        console.log('updateProgress > ', value);
        M.updateLoader({
            ...M.basic,
            ...{
                progress: value,
            },
        });
    }

    @Mutation
    public updateBg(value: ManagerLoaderBg) {
        M.updateLoader({
            ...M.basic,
            ...{
                bg: value,
            },
        });
    }

    //タイトル
    @Mutation
    public updateTitle(value: string) {
        M.updateLoader({
            ...M.basic,
            ...{
                title: $v.isEmpty(value) ? M.titleDefault : value,
            },
        });
    }

    /*
        進行度合いのメッセージ
        文字列がなければ自動で % 表示される
    */
    @Mutation
    public updateBody(value: string) {
        M.updateLoader({
            ...M.basic,
            ...{
                body: value,
            },
        });
    }

    // Actions //////////////////////////////////////////////
    //初期値にリセット
    @Action
    public async init(req: IManagerLoaderRequest) {
        M.updateLoader({
            ...{
                progress: 0,
            },
            ...req,
            ...{
                loading: false,
            },
        });

        if (!!$v.p(req, 'loading')) {
            setTimeout(() => {
                M.updateLoader({
                    id: req.id,
                    loading: true,
                });
            }, 100);
        }

        console.log('managerLoaderModule.inited() > ', M.loaders.findByKey('id', req.id));

        // this.updateProgress(0);
        // this.updateBg('transparent');
        //
        // //Circle
        // if (style == 'circle') {
        //     this.updateStyle('circle');
        //     this.updateProgressType('manual');
        //
        // } else {
        //     this.updateStyle('progress');
        //     this.updateProgressType('auto');
        //     this.updateTitle('読み込み中...');
        //     this.updateBody('');
        // }
    }
}

export const managerLoaderModule = getModule(Store);

const M = managerLoaderModule;
