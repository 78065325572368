import {Component, Watch, Prop, Vue} from "vue-property-decorator";
import ColorUtil from '@/classes/view/color-util';
import {themeMainModule} from '@/store/theme/theme-main';
import {themeStateModule} from '@/store/theme/theme-state';

@Component
export default class CMvSwiper extends Vue {

	public static self: CMvSwiper;
    public loading: boolean = true;
    public nowNumber: number = 0;

    @Prop({default: '/common/img'})
    public base: string | null;

	//DATA
	@Prop({default: () => {} })
	public d: any;

	//ANIMATION
	@Prop({default: 'fade'})
	public motion: string;

	//AUTO PLAY
	@Prop({default: 3000})
	public autoplay: number;

	/*
    	BUTTONS
    	none / thumb
	*/
	@Prop({default: 'none'})
	public ui: string;

	/*
    	全画面など、可変サイズの場合は、TRUEにする
	*/
	@Prop({default: false})
	public resize: boolean;


    @Watch('nresize')
    public watchResize() {
		this!['slide'].update();
    }

    public mounted() {

		CMvSwiper.self = this;
		setTimeout(
		    () => {
				this.init();
                this.loading = false;
		    }, 300);
    }

    public init() {
		this!['slide'].init();
		this.nowNumber = this!['slide']['realIndex'] + 1;
    }

    public setAutoplay(): any {
	    if(this.autoplay){
			return {
				delay: this.autoplay,
			}
	    }
	    return false;
    }

    //Swiper
    public swiperOption: any = {

	    init: false,
        loop: true,
        lazy: true,
        autoplay: this.setAutoplay(),
        slidesPerView: 'auto',
        disableOnInteraction: false,
		parallax: true,
		grabCursor: true,
        effect: this.motion,
        speed: 540,
        centeredSlides: true,
        spaceBetween: 0,
        on: {
	        init: function () {

	            if( !CMvSwiper.self ){
		            return;
	            }

	        },
            slideChange: function () {

	            if( !CMvSwiper.self ){
		            return;
	            }
	            CMvSwiper.self.slideChanged();

                //画像の明るさをいれる
	            ColorUtil.blackOrWhiteFromImage( CMvSwiper.self.base + CMvSwiper.self.d[CMvSwiper.self!['slide']['realIndex']] )
                    .then(CMvSwiper.self.imageBrightnessResolve, CMvSwiper.self.imageBrightnessRejected);
            },
            tap() {
            }
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        }
    };

    public imageBrightnessResolve(value: string) {
        themeStateModule.updateMvImageBrightness(value);
    }

    public imageBrightnessRejected(value: string) {
        themeStateModule.updateMvImageBrightness('bk');
    }

    public slideChanged() {
		this.nowNumber = this!['slide']['realIndex'] + 1;
    }

    public get totalNumber(): any {
        return this.d.length;
    }

    public get nresize() {

        if(this.resize){
            return themeMainModule.mainFrameResized;
        }
	    return;
    }
}

