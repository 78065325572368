
























	// @ts-ignore
    import _ from './MSelectFrame.ts'; export default _;
