import {
    ApiExecuterAsync,
    ApiExecuterFactory,
    ApiMethod,
    ApiUtil
} from '@/classes/core/api/import';
import {vsprintf} from 'sprintf-js';

export default class Var {

    public get(param: any = {}): ApiExecuterAsync {
        let url = ApiUtil.url('/var');
        let request = ApiExecuterFactory.createRequest({
            method: ApiMethod.Get,
            url: url,
            param: param
        });

        return ApiExecuterFactory.createAsync(request);
    }
}
