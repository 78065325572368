import {IApiBehavior} from './i-api-behavior';
import {IApiRequest} from './i-api-request';
import VarUtil, {$v} from '@/classes/utils/var-util';

export abstract class AApiExecuterAsync {
    private _request: IApiRequest;
    private _behavior: IApiBehavior;

    constructor(behavior: IApiBehavior) {
        this._behavior = behavior;
    }

    public init(request: IApiRequest) {
        this._request = request;
    }

    public param(param: any): AApiExecuterAsync {
        this._request.param = VarUtil.export<any>(this._request.param || {}, param);
        return this;
    }

    public config(config: any): AApiExecuterAsync {
        this._request.config = VarUtil.export<any>(this._request.config || {}, config);
        return this;
    }

    public uploading(cb: (e: ProgressEvent) => void): AApiExecuterAsync {
        this.config({
            onUploadProgress: cb
        });
        return this;
    }

    public then(cb: (res: any) => void): AApiExecuterAsync {
        this._request.cbThen = cb;
        return this;
    }

    public failue(cb: (res: any) => void): AApiExecuterAsync {
        this._request.cbCatch = cb;
        return this;
    }

    public all(cb: (res: any) => void): AApiExecuterAsync {
        this._request.cbAll = cb;
        return this;
    }

    public async exec(): Promise<any> {
        await this._behavior.access(this._request);
    }

    public async execAsync(extract: string | null = null): Promise<any> {
        let r = null;
        let err = null;

        await this.then((res: any) => {
            if (!extract) {
                r = res.data;
            } else {
                if (VarUtil.has(res.data, extract)) {
                    r = VarUtil.path(res.data, extract);
                } else {
                    console.log('execAsync() > res.data', res.data);
                }
            }
        }).failue((e: any) => {
            err = e;
        }).exec();

        if (!!err) {
            throw err;
        }

        return r;
    }
}
