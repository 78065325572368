import {appAuthModule, AuthErrorCode, AuthProduct, IAuth, IAuthErrorBag} from "@/store/app/auth";
import VarUtil from "@/classes/utils/var-util";
import {EnvUtil} from "@/classes/app/env-util";

export class AuthManager {

    public static get auth(): IAuth | null {
        return appAuthModule.auths.findByKey('prod', AuthProduct.To004Owner);
    }

    public static isLogin(): boolean {
        if (EnvUtil.isDummy) {
            return true;
        } else {
            return !!VarUtil.path(AuthManager.auth, 'token');
        }
    }

    public static isE401(prod: AuthProduct = AuthProduct.To004Owner): boolean {
        const e: IAuthErrorBag | null = appAuthModule.errors.findByKey('prod', prod);
        return !!e ? (e.code === AuthErrorCode.E401) : false;
    }
}
