





































































// @ts-ignore
import _ from './CPageBlogIndexStandard.ts'; export default _;
