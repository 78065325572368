import {Component, Prop, Vue} from "vue-property-decorator";
import {sortModule} from '@/store/sort';

@Component
export default class MSortListBtn extends Vue {

    @Prop({default: 'delete'})
    public mode: string;
	
	/*
		並び替え＆削除の切り替え
		@mode
			sort : 並び替えと削除モード
			delete : 削除モード
	*/
	public onSort() {
		sortModule.run ? sortModule.updateRun(false) : sortModule.updateRun(true);
		
		//モードが指定されているとき
		//それ以外は開閉のみ
		if( this.mode ) {
			sortModule.updateMode(this.mode);
		}
		
	}
    
}