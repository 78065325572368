import {AExt} from "@/classes/ext/a-ext";
import {IProdWizardInput, prodWizardModule} from "@/store/prod/wizard";
import {$v} from "@/classes/utils/var-util";
import {ManagerService} from "@/classes/domain/module/manager/manager-service";
import {SiteStartState} from "@/classes/domain/module/manager/site/site-const";
import {DomainUtil} from "@/classes/domain/module/model/domain/domain-util";
import {managerProjectModule} from "@/store/manager/project";
import {DevUtil} from "@/classes/utils/dev-util";
import {EnvUtil} from "@/classes/app/env-util";
import ManagerApi from "@/classes/app/api/manager-api";

export class ExtWizardMake extends AExt {

    // Computed //////////////////////////////////
    public get pj(): any {
        return $v.p(this.vue, 'pj');
    }

    public get progress(): IProdWizardInput {
        return prodWizardModule.input;
    }

    // Methods /////////////////////////////////////
    public async loadWizardProgress() {
        await prodWizardModule.load();
        DevUtil.log('ExtWizardMake.loadWizardProgress() > ', this.progress);
    }

    public async reset() {
        await prodWizardModule.resetInput();
    }

    public async initPjStatus(): Promise<boolean> {

        const startStatus = !!this.pj ? ManagerService.siteStart.detectStarting(this.pj) : null;
        const domain = $v.has(this.pj, 'domain') ? DomainUtil.domain(this.pj.domain, true) : '';

        DevUtil.log('ExtWizardMake.initPjStatus() > path is ', this.vue.$route.path);
        DevUtil.log(' >>> ', domain);

        // 謎アクセス時
        if (!domain) {
            this.vue.$nextTick(async function () {
                // @ts-ignore
                await (this as any).$router.push(`/manager`);
            });
            return false;
        }

        // Wizardアクセス時
        DevUtil.log('ExWizardMake.initPjStatus() > ', startStatus, domain, this.pj, this.pj.ex);
        switch (startStatus) {
            case SiteStartState.WorkType:
                await this.vue.$router.push(`/wizard-start/${domain}/type`);
                return false;
            case SiteStartState.Wizard:
                DevUtil.log(' - SiteStartState.Wizard');
                // if (/^\/manager\/wizard-make/.test(this.vue.$route.path)) {
                //     DevUtil.log(' - is wizard access >', startStatus);
                //     DevUtil.log(' >>> ');
                //     if (startStatus === SiteStartState.Active) {
                //         this.vue.$nextTick(async function () {
                //             // @ts-ignore
                //             await this.$router.push(`/manager/project/${domain}`);
                //         });
                //     }
                //     return false;
                // }
                if (!/^\/manager\/wizard-make/.test(this.vue.$route.path)) {
                    const step = $v.tap($v.p(this.pj, 'ex.start.step', 'start'), (_: string) => {
                        console.log(' - Wizard', _);
                        return (String(_) === '0') ? 'start' : _;
                    });
                    await this.vue.$router.push(`/manager/wizard-make/${domain}/${step}`);
                    return false;
                } else {
                    DevUtil.log(' - R2');
                    return true;
                }

            default:
                console.log('ExWizardMake.initPjStatus() > active');
                return true;
        }
    }

    /**
     * 入力を更新
     *
     * @param input
     * @param save
     */
    public async update(input: any, save: boolean = true) {
        await prodWizardModule.updateInput({
            ...this.progress,
            ...input,
        });
        if (save) {
            await this.save();
        }
    }

    /**
     * 入力を部分更新
     * @param path
     * @param value
     * @param save
     */
    public async patch(path: string, value: any, save: boolean = true) {
        const p = $v.put({...this.progress}, path, value);
        DevUtil.log('patch > ', path, value, p);
        await this.update(p, save);
    }

    public async save(input: any = null) {

        if (!$v.p(this.pj, 'toid')) {
            console.log('ExtWizardMake.save() > save error toid is blank');
            return;
        }
        DevUtil.log('extWizardMake.save() > (base, input) > ', this.progress, input);
        await prodWizardModule.save({
            pj: this.pj,
            input: $v.isObject(input) ? {
                ...this.progress,
                ...input,
            } : this.progress
        });
    }

    public async saveSite(pinput: any = null): Promise<boolean> {

        // if (EnvUtil.isDummy) {
        //     return true;
        // }
        //
        // const input = pinput || {
        //     ...this.progress,
        //     ...{
        //         completed: 1,
        //     }
        // };
        //
        // await prodWizardModule.save({
        //     pj: this.pj,
        //     input,
        // });
        //
        // managerProjectModule.updateRecord({
        //     ...this.pj,
        //     ...{
        //         ex: {
        //             ...this.pj.ex,
        //             ...{
        //                 start: input,
        //             },
        //         },
        //     },
        // });
        //
        // await prodWizardModule.saveLocalStorage();

        return true;
    }

    /**
     * 進行を保存
     * @param step
     */
    public async saveProgress(step: string) {
        console.log('ExtWizardMake.saveProgress() > ');
        await this.save({
            step,
        });
    }

    /**
     * 完了処理
     */
    public async finish(): Promise<boolean> {

        try {
            const res = await ManagerApi
                .OfficialStart
                .postCommit({
                    site: this.pj.toid,
                })
                .execAsync();
            return true;
        } catch (e) {
            return false;
        }
    }
}
