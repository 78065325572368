import {Component, Prop, Vue} from '@/node_modules/nuxt-property-decorator';
import {appModule} from '@/store/app';

import TeNavFoot from '@/components/part/teaser/TeNavFoot.vue';

@Component({
    components: {
        TeNavFoot,
    }
})
export default class TeGlobalFooter extends Vue {


    public get isActive(): any {

        if (this.$nuxt.$route.name == 'index') {

            if(appModule.deviceSize != 'mb'){
                return false;
            }

            return true;
        }
        return true;
    }

    public get deviceSize(): any {
        return appModule.deviceSize;
    }

}
