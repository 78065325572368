import {Mutation, Action, VuexModule, getModule, Module} from 'vuex-module-decorators';
import store from '@/classes/core/store';
import VarUtil, {$v} from '@/classes/utils/var-util';
import {Brands} from '@/configs/brands';
import ProdApi from "@/classes/app/api/prod-api";
import {DevUtil} from "@/classes/utils/dev-util";
import {HttpStatus} from "@/configs/system";

// state's interface
export interface IProdStartModule {
    loaded: boolean;
    input: IProdStartInput;
    error: string;
}

export interface IProdStartInput {
    step: number;
    shopName: string;
    site: string,
    transId: string;
    brandId: string;
    layout: {
        patternId: string,
        brandId: string,
        layout: string,
        basecolor: string,
        sccolor: string,
    };
    sitename: string;
    dkey: string;
    domain: {
        sub: string;
        domain: string;
    };
    project: string,    // domain.dkey
}

const INPUT_SCHEMA = '200524';
const INPUT_DEFAULT = {
    schema: INPUT_SCHEMA,
    shopName: '',
    site: '',
    step: 0,
    transId: '',
    brandId: '',
    layout: {
        brandId: '',
        layout: '',
    },
    sitename: '',
    dkey: '',
    domain: {
        sub: '',
        domain: '.hairdesign.salon',
    },
    project: '',
};


@Module({dynamic: true, store, name: 'prodStart', namespaced: true})
class Store extends VuexModule implements IProdStartModule {

    public loaded: boolean = false;
    public input: IProdStartInput = {...INPUT_DEFAULT} as any;
    public error: string = '';

    public get brandIndex(): number {
        for (let i = 0; i < Brands.length; i++) {
            if (Brands[i].brandid === this.input.brandId) {
                return i;
            }
        }
        return 0;
    }

    @Mutation
    public updateInput(value: any) {
        this.input = value;
    }

    @Mutation
    public udpateError(value: string = '') {
        this.error = value;
    }

    @Mutation
    public updateLoaded(value: boolean = false) {
        this.loaded = value;
    }

    // Actions ////////////////////////////////////////////////////////////////
    @Action
    public async filterInput(): Promise<IProdStartInput> {
        return VarUtil.tap({...M.input}, (r: IProdStartInput) => {
            r.domain.domain = r.domain.domain.replace(/^\./, '');
            return r;
        });
    }

    @Action
    public async inputValue(param: any) {
        // console.log('inpuValue > base > ', M.input);

        const upd = Object.assign({}, M.input, param);
        M.updateInput(upd);

        // console.log('inputVluae > ', param, M.input);

        if (!VarUtil.path(param, 'nosave')) {
            console.log('prodStartModule.inputValue() > save');
            await M.save();
        }
    }

    @Action
    public async resetInput() {
        M.updateInput(Object.assign({}, INPUT_DEFAULT));
        await M.save(true);
    }

    @Action
    public async check(): Promise<boolean> {

        const tid = M.input.transId;
        console.log('prodStartModule.check() > start > ', tid);

        if (!tid) {
            return false;
        }

        try {
            console.log('prodStartModule.check() > ', tid);
            await ProdApi.Official.show(tid).execAsync();
            console.log(' - OK', tid);
            return true;
        } catch (e) {
            console.log(' - Error!');
            return false;
        }
    }

    @Action
    public async open() {
        const transId = await ProdApi.Official.start().execAsync('transId');

        console.log('open() > transid =', transId);

        await prodStartModule.inputValue({
            transId,
        });

        await M.save();
    }

    // @Action
    // public async commit() {
    //
    // }

    // ユーザー登録処理
    @Action
    public async postUser(user: any): Promise<any> {
        try {
            const param = {
                ...await M.filterInput(),
                ...{
                    account: {
                        ...user,
                        ...{
                            type: -1,
                        },
                    },
                },
            };
            return await ProdApi.Official.storeUser(param).execAsync();
        } catch (e) {

            const r = [];
            const status = $v.p(e, 'response.status');

            if (status === HttpStatus.Error) {
                const errors = VarUtil.path(e, 'response.data.error.messages');
                DevUtil.log('error > ', status, errors);
                Object.keys(errors).map((k: string) => {

                    r.push({
                        name: k.split('.').last(),
                        messages: errors[k],
                    })
                });
            } else {
                r.push({
                    name: '@',
                    messages: [
                        e.message,
                    ],
                });
            }
            return r;
        }
    }

    @Action
    public async postDomain(user: any): Promise<any> {
        try {
            const param = {
                ...await M.filterInput(),
                ...{
                    account: {
                        ...user,
                        ...{
                            type: -1,
                        },
                    },
                },
            };
            return await ProdApi.Official.domain(param).execAsync();
        } catch (e) {

            const r = [];
            const status = $v.p(e, 'response.status');

            if (status === HttpStatus.Error) {
                const errors = VarUtil.path(e, 'response.data.error.messages');
                DevUtil.log('error > ', status, errors);
                Object.keys(errors).map((k: string) => {

                    r.push({
                        name: k.split('.').last(),
                        messages: errors[k],
                    })
                });
            } else {
                r.push({
                    name: '@',
                    messages: [
                        e.message,
                    ],
                });
            }
            return r;
        }
    }

    @Action
    public async save(reset: boolean = false) {
        // console.log('prodStartModule.save() > ', M.input);
        localStorage.prodStartModule = reset ? null : JSON.stringify(M.input);
    }

    @Action
    public async load() {

        if (M.loaded) {
            return;
        }

        await M.updateLoaded(true);

        // Schemaを比較してリセットする
        const src = localStorage.prodStartModule;
        if (!!src) {
            const input = JSON.parse(src);
            const schema = VarUtil.path(input, 'schema');
            if (schema && schema === INPUT_SCHEMA) {
                await M.updateInput(input);
                return;
            }
        }

        console.log('prodStartModule.load() > resetInput');
        await M.resetInput();
    }
}

export const prodStartModule = getModule(Store);

const M = prodStartModule;
