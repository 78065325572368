import {Component, Watch, Prop, Vue} from 'vue-property-decorator';
import {themeMainModule} from '@/store/theme/theme-main';
import CPatternMix from '@/classes/theme/mixin/c-pattern-mix';

import {DummyGallery} from '@/configs/dummy/dummy-gallery';
import {osGalleryModule} from '@/store/os/gallery';
import CArticleFooter from '@/components/theme/module/CArticleFooter';

@Component({
    mixins: [CPatternMix],
    components: {
        CArticleFooter
    }
})
export default class CPageGalleryDetailStandard extends Vue {


	//ACTIVE CATEGORY
	public activeId: string = '0';


    public textlink: any = [
        {
            name: 'facebook',
            link: 'https://www.facebook.com/sharer/sharer.php?u=https://thought-brand.com/'
        },
        {
            name: 'twitter',
            link: 'https://twitter.com/share?url=https://thought-brand.com/'
        },
        {
            name: 'pocket',
            link: 'http://getpocket.com/edit?url=https://thought-brand.com/&title=THOUGHT'
        },
    ];

    /*
        Category Dataを取得
    */
    public get list(): any {
        // return DummyArticleCategory;
        return osGalleryModule.content!['galleries'] || [];
    }

    /*
        Category Dataを取得
    */
    public get categories(): any {
        // return DummyArticleCategory;
        return osGalleryModule.content!['categories'] || [];
    }

    public get categoryList(): any {
        return this.article.category.map((_: any) => {
            return {
                name: _,
                link: '/gallery/category/' + encodeURI(_)
            };
        });
    }

    /*
        Category Dataを取得
    */
    public get article(): any {
        return osGalleryModule.content!['gallery'] || [];
    }


    public get articleFooterContent(): any {
        return {
            date: this.article.date,
            textlink: this.textlink,
            categories: this.categoryList,
        };
    }
}



