import {Component, Prop, Vue} from "vue-property-decorator";
import {themeMainModule} from '@/store/theme/theme-main';
import {DummyProject} from '@/configs/dummy/dummy-project';
import CLink from '@/components/theme/module/CLink.vue';

@Component({
    components: {
	    CLink,
    }
})
export default class CSlotNav extends Vue {


    @Prop({default:() => {} })
    public d: any;

    public length: number = this.d.length;

	public timer: any = 1;
	public now: number = 1;
	public duration: number	= 5000;
    public nowClass: string = 'is-1';

    public created() {

        this.timer = setTimeout(
            () => {
				this.next();
				this.loop();
            }, this.duration);
    }


    public get onow(): any {
	    return this.nowClass;
    }


    public onPrev() {
		clearTimeout(this.timer);
		this.prev();
    }

    public onNext() {
		clearTimeout(this.timer);
		this.next();
    }

	public loop(){

        this.timer = setTimeout(
            () => {
				this.next();
				this.loop();
            }, this.duration);
	}

	public stop(){
		clearTimeout(this.timer);
	}

	public move(){
		this.nowClass = 'is-' + this.now;
	}

	public prev(){
		if( this.now <= 1 ){
			this.now = this.length;
		}else{
			--this.now;
		}
		this.move();
	}

	public next(){
		if( this.now >= this.length ){
			this.now = 1;
		}else{
			++this.now;
		}
		this.move();
	}
}

