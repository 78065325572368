import {$v} from "@/classes/utils/var-util";
import {IWorktime} from "@/classes/domain/module/manager/ui/store-worktime/i-worktime";
import {UiStoreWorktimeUtil} from "@/classes/domain/module/manager/ui/store-worktime/ui-store-worktime-util";
import {IWorktimeEditBlock} from "@/classes/domain/module/manager/ui/store-worktime/i-worktime-edit-block";

export class UiStoreWorktime {

    protected _worktime: IWorktime;
    protected readonly _timeBase: number;
    protected readonly _timeLimit: number;
    protected readonly _pxUnit: number;
    protected readonly _timeUnit: number;

    constructor(worktime: IWorktime,
                timeBase: number,
                timeLimit: number,
                timeUnit: number,
                pxUnit: number
    ) {
        this._worktime = worktime;
        this._timeBase = timeBase;
        this._timeLimit = timeLimit;
        this._pxUnit = pxUnit;
        this._timeUnit = timeUnit;
    }

    public get worktime(): IWorktime {
        return this._worktime;
    }

    public get rawStartTime(): number {
        return this.worktime.st;
    }

    public get startTime(): number {
        return this.rawStartTime - this.timeBase;
    }

    public get rawEndTime(): number {
        return this.worktime.end
    }

    public get endTime(): number {
        return this.rawEndTime - this.timeBase;
    }

    public get rangeTime(): number {
        return this.endTime - this.startTime;
    }

    public get timeBase(): number {
        return this._timeBase;
    }

    public get timeLimit(): number {
        return this._timeLimit;
    }

    public get pxUnit(): number {
        return this._pxUnit;
    }

    public get timeUnit(): number {
        return this._timeUnit;
    }

    // rawTailPixがRangeを超えているか
    public get isOverRange(): boolean {
        return (this.endPx > this.timeLimitPx);

    }

    public get startPx(): number {
        return this.t2px(this.startTime);
    }

    public get endPx(): number {
        return this.t2px(this.endTime);
    }

    public get rangePx(): number {
        return this.t2px(this.rangeTime);
    }

    // 描画可能域
    public get timeLimitPx(): number {
        return UiStoreWorktimeUtil.time2Px(
            this.timeLimit,
            this.pxUnit,
            this.timeUnit);
    }

    // ブロック開始位置
    public get topPx(): number {
        return this.isOverRange ? this.maxTopPx : this.topRawPx;
    }

    // ブロック開始位置（本来）
    public get topRawPx(): number {
        const tb = this.timeBase;
        const st = this.worktime.st;
        return UiStoreWorktimeUtil.time2Px(
            $v.num(st - tb), this.pxUnit, this.timeUnit
        );
    }

    // ブロック開始最大可能位置
    public get maxTopPx(): number {
        return (this.timeLimitPx - this.heightPx);
    }

    // 最大高さ（時間）
    public get maxRawHeightTime(): number {
        return this.timeBase + this.timeLimit;
    }

    // ブロック高さ
    public get heightPx(): number {
        return this.endPx - this.startPx;
    }

    public get block(): any {
        return {
            top: this.topPx,
            height: this.heightPx,
        }
    }

    public get editBlock(): IWorktimeEditBlock {
        return {
            worktime: {...this.worktime},
            block: this.block,
        };
    }

    public get styleBlock(): any {
        return {
            top: `${this.block.top}px`,
            height: `${this.block.height}px`,
        };
    }

    // Methods /////////////////////////////////////////////////////////////
    // - Util系 --------------------------------------------
    public tu(t: number): number {
        return (t === 0) ? 0 :
            (this.timeUnit * (Math.floor(t / this.timeUnit)) + (
                (t % this.timeUnit >= (this.timeUnit / 2)) ? this.timeUnit : 0));
    }

    public pxu(px: number): number {
        return (px === 0) ? 0 :
            (this.pxUnit * (Math.floor(px / this.pxUnit)) + (
                (px % this.pxUnit >= (this.pxUnit / 2)) ? this.pxUnit : 0));
    }

    public t2px(t: number): number {
        return UiStoreWorktimeUtil.time2Px(t, this.pxUnit, this.timeUnit);
    }

    public px2t(px: number): number {
        return UiStoreWorktimeUtil.px2Time(px, this.pxUnit, this.timeUnit);
    }

    // - Move系 --------------------------------------------
    // 移動による制限を加味した下限PX計算
    public calcMoveTopPx(movePx: number): number {
        const nextPx = this.startPx + this.pxu(movePx);

        if (nextPx < 0) {
            return 0;
        } else if (nextPx > (this.endPx - this.pxUnit)) {
            return this.endPx - this.pxUnit;
        } else {
            return nextPx;
        }
    }

    // 移動による制限を加味した位置PX計算
    public calcMovePx(movePx: number): number {
        const nextPx = this.startPx + this.pxu(movePx);
        const nextTailPx = nextPx + this.heightPx;

        if (nextPx < 0) {
            return 0;
        } else if (nextTailPx >= this.timeLimitPx) {
            return this.maxTopPx;
        } else {
            return nextPx;
        }
    }

    // 移動による制限を加味した上限PX計算
    public calcMoveBottomPx(movePx: number): number {
        const nextPx = this.endPx + this.pxu(movePx);
        const limitPx = this.startPx + this.pxUnit;

        if (nextPx < limitPx) {
            return limitPx;
        } else if (nextPx > this.timeLimitPx) {
            return this.timeLimitPx;
        } else {
            return nextPx;
        }
    }

    // - Calc系 --------------------------------------------
    // TOP指定ピクセルが領域をオーバーするか
    public isOverTopPx(px: number): boolean {
        const top = (px === 0) ? 0 : this.pxUnit * (px / this.pxUnit);
        return ((top + this.heightPx) > this.timeLimitPx);
    }

    // 指定ピクセルが領域をオーバーするか
    public isOverHeightPx(px: number = this.heightPx): boolean {
        const hpx = (px === 0) ? 0 : this.pxUnit * (px / this.pxUnit);
        return ((this.topRawPx + hpx) > this.timeLimitPx);
    }

    // 指定Timeが領域をオーバーするか
    public isOverHeightTime(time: number): boolean {
        return (this.startTime + time > this.timeLimit);
    }

    // - Update系 -----------------------------------------------
    public from(st: number | null = null, end: number | null = null): UiStoreWorktime {
        // console.log('st=%s, end=%s', st, end);
        return new UiStoreWorktime(
            {
                ...this.worktime,
                ...{
                    st: (st !== null ? st + this.timeBase : this.rawStartTime),
                    end: (end !== null ? end + this.timeBase : this.rawEndTime),
                },
            },
            this.timeBase,
            this.timeLimit,
            this.timeUnit,
            this.pxUnit,
        );
    }

    public updateTime(st: number, end: number): UiStoreWorktime {
        return this.from(st, end);
    }

    public moveTime(mv: number): UiStoreWorktime {
        return this.movePx(this.t2px(mv));
    }

    public movePx(mv: number): UiStoreWorktime {
        const moveToTime = this.px2t(this.calcMovePx(mv));
        return this.from(moveToTime,
            moveToTime + this.rangeTime);
    }

    public updateStartTime(time: number): UiStoreWorktime {
        return this.from(time);
    }

    public moveStartTime(time: number): UiStoreWorktime {
        return this.moveStartPx(this.t2px(time));
    }

    public updateEndTime(time: number): UiStoreWorktime {
        return this.from(null, time);
    }

    public moveEndTime(time: number): UiStoreWorktime {
        return this.moveEndPx(this.t2px(time));
    }

    public moveStartPx(px: number): UiStoreWorktime {
        return this.from(
            this.px2t(this.calcMoveTopPx(px)));
    }

    public moveEndPx(px: number): UiStoreWorktime {
        return this.from(
            null,
            this.px2t(this.calcMoveBottomPx(px)));
    }
}
