import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class MHeader extends Vue {

    @Prop({default: ''})
    public to: string;
    
    @Prop({default: ''})
    public title: string;
    
    public get back(): any {
	    
		return { path: this.to , query: { d: 'back' } };
		
    }
    
}