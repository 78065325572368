export const LangLabel = {
    en: {
        word: [
            {
                key: 'save',
                label: 'SAVE',
            },
            {
                key: 'ok',
                label: 'OK',
            },
            {
                key: 'cancel',
                label: 'CANCEL',
            }
        ],

        manager: {
            popup: {
                pageLink: [
                    {
                        key: 'title',
                        label: '外部リンクの設定',
                    },
                    {
                        key: 'description',
                        label: '外部予約サイトや特設ページをナビゲーションに表示したい場合に使用します。',
                    },
                    {
                        key: 'fieldLabel',
                        label: 'メニュー名',
                    },
                    {
                        key: 'fieldLink',
                        label: 'リンクアドレス',
                    },
                ]
            }
        },
        week: [
            {
                key: 'w0',
                label: 'Sunday'
            },
            {
                key: 'w1',
                label: 'Monday'
            },
            {
                key: 'w2',
                label: 'Tuesday'
            },
            {
                key: 'w3',
                label: 'Wednesday'
            },
            {
                key: 'w4',
                label: 'Thusday',
            },
            {
                key: 'w5',
                label: 'Friday'
            },
            {
                key: 'w6',
                label: 'Saturday'
            },
            {
                key: 'w7',
                label: 'Public holiday'
            },
        ],

        holiday: [
            {
                key: 'everyWeek',
                label: 'Every week',

            },
            {
                key: 'first',
                label: '1st',
            },
            {
                key: 'second',
                label: '2nd',
            },
            {
                key: 'third',
                label: '3rd',
            },
            {
                key: 'force',
                label: '4th',
            },
        ],
    },
    ja: {
        word: [
            {
                key: 'save',
                label: '保存',
            },
            {
                key: 'ok',
                label: 'OK',
            },
            {
                key: 'cancel',
                label: 'キャンセル',
            }
        ],

        manager: {
            popup: {
                pageLink: [
                    {
                        key: 'title',
                        label: '外部リンクの設定',
                    },
                    {
                        key: 'description',
                        label: '外部予約サイトや特設ページをナビゲーションに表示したい場合に使用します。',
                    },
                    {
                        key: 'fieldLabel',
                        label: 'メニュー名',
                    },
                    {
                        key: 'fieldLink',
                        label: 'リンクアドレス',
                    },
                ]
            }
        },

        week: [
            {
                key: 'w0',
                label: '日曜'
            },
            {
                key: 'w1',
                label: '月曜'
            },
            {
                key: 'w2',
                label: '火曜'
            },
            {
                key: 'w3',
                label: '水曜'
            },
            {
                key: 'w4',
                label: '木曜',
            },
            {
                key: 'w5',
                label: '金曜'
            },
            {
                key: 'w6',
                label: '土曜'
            },
            {
                key: 'w7',
                label: '祝日'
            },
        ],

        holiday: [
            {
                key: 'everyWeek',
                label: '毎週',
            },
            {
                key: 'first',
                label: '第1',
            },
            {
                key: 'second',
                label: '第2',
            },
            {
                key: 'third',
                label: '第3',
            },
            {
                key: 'force',
                label: '第4',
            },
        ],
    },
};
