export default class Throttle {

    private cnt;
    private time;
    private wait;

    constructor(wait: number = 1500) {
        this.cnt = 0;
        this.wait = wait;
        this.time = Date.now();
    }

    public throttle(fn: () => void) {
        let t = this.limit - Date.now();
        // console.log("Thlottle() t=%s", t);
        if (this.cnt == 0 || t < 0) {
            fn();
            this.time = Date.now();
            this.cnt++;
        }
    }

    public async throttleAsync(fn: () => Promise<any>) {
        let t = this.limit - Date.now();
        if (this.cnt == 0 || t < 0) {
            await fn();
            this.time = Date.now();
            this.cnt++;
        }
    }

    delay(fn: () => void, wait: number = 1500) {
        setTimeout(() => {
            this.throttle(fn)
        }, wait);
    }

    debounce(fn: () => void) {
        this.throttle(() => {
            setTimeout(() => {
                fn();
            }, this.wait);
        });
    }

    get limit(): number {
        return (this.time + this.wait);
    }

    /**
     *
     * @param wait
     * @returns {Throttle}
     */
    static create(wait: number = 1500) {
        return new Throttle(wait);
    }
}
