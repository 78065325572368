import {Component, Watch, Prop, Vue} from "vue-property-decorator";
// import {ScrollUtil} from '@/classes/view/scroll-util';
import {themeMainModule} from '@/store/theme/theme-main';
import {themeStateModule} from '@/store/theme/theme-state';
import {editModule} from '@/store/edit';
import {DummyPageHome} from '@/configs/dummy/dummy-page-home';

import * as $ from 'jquery';
import {$apm} from "@/classes/app/app-manager";

@Component({
//     mixins: [
//     ],
})
export default class CEditFrame extends Vue {

    @Prop({default: ''})
    public target: string | null;

    public styleLayer: any = {};
    public styleToolLeft: any = {};
    public editId: string = '';
    public hasCurrent: boolean = false;
    public pos: any = {
        top: 0,
        left: 0,
    }
    public resetMethod: any;

    // 表示処理
    @Watch('current')
    public watchCurrent() {

        if (this.current) {

            // スタイルを適用してから表示させるために hasCurrent を遅らせる
            if (this.current.eid != this.editId) {

                // 一旦非表示
                this.hasCurrent = false;

                // Style適用
                setTimeout(
                    () => {
                        this.setStyles();
                        setTimeout(
                            () => {
                                this.checkToolWidth();
                            }, 2);
                    }, 2);

                // 表示
                setTimeout(
                    () => {
                        this.hasCurrent = !!this.current;
                    }, 5);

            } else {
                setTimeout(
                    () => {
                        this.setStyles();
                        setTimeout(
                            () => {
                                this.checkToolWidth();
                                this.hasCurrent = !!this.current;
                            }, 5);
                    }, 5);
            }

        } else {
            this.hasCurrent = !!this.current;
        }
    }

    /*
        Scroll
        Fixed, Stickyの場合のみ、スクロール追従させる
    */
    @Watch('scrollTop')
    public watchScrollTop() {
        this.setStyles();
    }

    public get current(): any {
        if (!themeMainModule.manager) {
            return;
        }
        return editModule.current;
    }

    public get scrollTop(): number {
        if (this.current && this.current.fixed) {
            return themeMainModule.scrollTop;
        }
        return;
    }

    public mouseenter() {
        if (!editModule.forceActive) {
            //console.log(this.current.eid + ' CEditFrame mouseenter');
            editModule.updateLock(true);
        }
    }

    public mouseleave() {
        if (editModule.lock && !editModule.forceActive) {
            //console.log('CEditFrame mouseleave 2');
            editModule.updateLock(false);
        }
    }

    /*
        Change Layouts, Components
    */
    public onPrev() {
        this.changeComponent('prev');
    }

    public onNext() {
        this.changeComponent('next');
    }

    public changeComponent(direction: string) {

        // 自分のデータを探す
        // Is Block
        if (this.current.type == 'block') {

            // 今自分のComponentがどれか
            const index = this.findMyComponent(this.current.mydata.component, direction);

            // Change Transition name
            this.changeTransition(direction);

            // Change Component
            this.changeBlock(this.current.mydata, index);

            this.setStyleAndTransition();

            // Is Sidecover
        } else if (this.current.type == 'sidecover') {

            // 今自分のComponentがどれか
            const index = this.findMyComponent(themeMainModule.scType, direction);

            // Change Transition name
            this.changeTransition(direction);

            // Change Component
            this.changeSidecover(index);

            this.setStyleAndTransition();

            // Layout
        } else if (this.current.type == 'layout') {

            // 今自分のComponentがどれか
            const index = this.findMyComponent(themeMainModule.project.global.layout, direction);

            editModule.updateLayoutChangeAnimation(true);

            // Layout ID 入れ替え
            setTimeout(
                () => {
                    themeMainModule.updateLayoutId(this.current.list[index].id);
                }, 1300);

            // カーテンを閉じる
            setTimeout(
                () => {
                    editModule.updateLayoutChangeAnimation(false);
                }, 1400);

            this.setStyleAndTransition();
        }
    }

    // 今のComponentがlistの何番目なのか
    public findMyComponent(value: string, direction: string): any {

        // 今自分のComponentがどれか
        let index: number = 0;

        // レイアウト変更の場合
        if (this.current.listType == 'layouts') {
            this.current.list.filter(function (item, i) {
                if (item.id == value) {
                    //console.log(i);
                    index = i;
                }
            });
        }

        // Is Prev
        if (direction == 'prev') {
            //console.log('prev');

            // Layout Data
            if (this.current.listType == 'layouts') {
                //console.log('layouts');
                --index;

                // 配列
            } else {
                //console.log(this.current.list);
                index = (this.current.list.indexOf(value)) - 1;
            }

            if (index < 0) {
                return this.current.list.length - 1;
            }

            // Is Next
        } else {
            //console.log('next');

            // Layout Data
            if (this.current.listType == 'layouts') {
                //console.log('layouts');
                ++index;

                // 配列
            } else {
                index = (this.current.list.indexOf(value)) + 1;
            }

            if (index > this.current.list.length - 1) {
                return 0;
            }

        }

        return index;
    }

    // Change Transition name
    public changeTransition(direction: string) {

        editModule.updateEditTransition('ct-edit-change-' + direction);
        editModule.updateForceActive(true);
        clearTimeout(this.resetMethod);
        this.resetMethod = null;
    }

    // Change block
    public changeBlock(mydata: any, index: number) {

        // データを書き換え
        // Block データのcomponentに変更後のcomponentを代入
        mydata.component = this.current.list[index];

        // データをUpdate
        themeMainModule.updateHomeBlockDataData(mydata);
    }

    // Change Sidecover
    public changeSidecover(index: number) {
        themeMainModule.updateScType(this.current.list[index]);
    }

    public get isManager(): any {
        // console.log('manager: ' + themeMainModule.manager);
        return themeMainModule.manager;
    }

    public get hasCurrentEdit(): boolean {
        return (!!this.current && !!this.current.edit);
    }


    // Styles ////////////////////////////////////////////////////////////

    // Styles
    public setStyles() {

        if (this.current) {
            //console.log('setStyles');
            this.editId = this.current.eid;

            //cMainFrameからの相対座標を取得
            let pos: any = {
                top: 0,
                left: 0
            };

            let $el: any = this.current.el;
            let $p: any;
            let parentName: string = '';

            // この親が.c-main-frameになるまで繰り返す
            let i = 0;

            while (i < 8) {

                //Margin Topがある場合は、Y座標に+
                const marginTop = parseInt($el.css('margin-top'));
                const marginLeft = parseInt($el.css('margin-left'));

                // offsetTop offsetLeftを取得
                pos.top += $el.position().top + marginTop;
                pos.left += $el.position().left + marginLeft;

                // offsetParentで親のクラス名をみる
                $p = $el.offsetParent();
                parentName = $p.get(0).className.split(' ')[0];

                $el = $p;

                const position = $el.css('position');
//                     console.log(`${i}: top: ${pos.top}`);

                if (parentName == 'c-main-frame' || position == 'fixed' || position == 'sticky') {
//                     if ($el.css('top') != 'auto') {
//                         pos.top += themeMainModule.mainFrameHeight;
//                     }
//                     const marginTop = parseInt($el.css('margin-top'));

                    if (position == 'fixed') {

                        const marginTop = parseInt($el.css('margin-top'));
                        const marginLeft = parseInt($el.css('margin-left'));

                        pos.top += $el.position().top - this.mainFrameOffset.top + marginTop;
                        pos.left += marginLeft;

                    } else {
                        pos.left += $el.position().left;
                    }

                    break;
                }

                i++;
            }

            this.pos = pos;

            this.styleLayer = {
                top: `${this.pos.top + themeMainModule.scrollTop}px`,
                left: `${this.pos.left}px`,
                width: `${this.current.size.w}px`,
                height: `${this.current.size.h}px`,
            }
        }
    }

    // ボタンの位置を調整
    public checkToolWidth() {

        if (this.current) {
            // console.log('CEditFrame > checkToolWidth');
            const tool_left = this.pos.left + $(this.$refs.toolinner).outerWidth();
            const ww = $(window).width();

            // 右にはみでていないか
            if (ww < tool_left + 10) {
                this.styleToolLeft = {
                    left: `${ww - tool_left - 25}px`,
                }
            } else {
                this.styleToolLeft = {}
            }
        }
    }

    // Set Style and Transition
    public setStyleAndTransition() {

        //Fade out 300ms
        //Fade in delay 400ms
        //Height 200ms

        this.resetMethod = setTimeout(() => {

            // 最上部の場合はスクロールしない
            const y = this.cMainFrameScrollTop;

            if (this.current) {
                const parentClassName = this.current.el.parent().attr('class');

                if (
                    parentClassName == '.c-sidecover-frame' ||
                    parentClassName == '.c-mv-frame'
                ) {
                    const to = this.current.el.offset().top + y - 120;
                    $('.c-scroll-frame').animate({scrollTop: to});
                    /*                         ScrollUtil.move(this.current.el.offset().top + y - 120); */
                }
            }

            // 対象ModuleのComponent Transitionを解除
            editModule.updateEditTransition('');

            // 強制的 Edit Boxアクティブを解除
            editModule.updateForceActive(false);

            // 変更されたComponentの情報を再取得するためのスイッチ
            editModule.updateSwitchedComponent(this.current.eid);
        }, 740);
    }

    // Navigation で Home で下にスクロールした時にナビゲーションの色を黒にするレイアウトのときに色を切り替えた時にtrueにする
    public get switchScrollDownReverseColor(): any {

        if (this.current) {
            if (this.current.eid == 'nav--nav' && themeStateModule.switchScrollDownReverseColor) {
                return true;
            }
        }

        return false;
    }

    public get cMainFrameScrollTop(): any {
        return themeMainModule.scrollTop;
    }

    public get mainFrameOffset(): any {
        return themeMainModule.mainFrameOffset;
    }


    // Methods /////////////////////////////////////////////////
    public mlink(to: string) {
        return to.replace(/^\/manager\/project\//, `/manager/project/${$apm.pjid}/`);
    }
}



