import {IApiBehavior} from './i-api-behavior';
import {IApiRequest} from './i-api-request';
import _ from 'lodash';

export default class ApiExecuter {
    private _request: IApiRequest;
    private _behavior: IApiBehavior;

    constructor(behavior: IApiBehavior) {
        this._behavior = behavior;
    }

    public init(request: IApiRequest) {
        this._request = request;
    }

    public then(cb: (res: any) => Promise<void> | void): ApiExecuter {
        this._request.cbThen = cb;
        return this;
    }

    public failue(cb: (res: any) => Promise<void> | void): ApiExecuter {
        this._request.cbCatch = cb;
        return this;
    }

    public all(cb: (res: any) => Promise<void> | void): ApiExecuter {
        this._request.cbAll = cb;
        return this;
    }

    public exec(): void {
        this._behavior.access(this._request);
    }

    public async execAsync(extract: string): Promise<any> {
        let r = null;
        await this.then((res: any) => {
            if (!!extract && _.has(res.data, extract)) {
                r = res![extract];
            } else {
                console.log('execAsync() > res.data', res.data);
                throw new Error(`${extract} not found`);
            }
        }).exec();
        return r;
    }
}
