/*

	YH: Data Spread Sheet is Here
	https://docs.google.com/spreadsheets/d/1GP-wc5RwVL54UcYqxjH_ZfCQWY7IAfuL_BSC2SXCL8s/edit?usp=sharing

*/
/*

	YH: Data Spread Sheet is Here
	https://docs.google.com/spreadsheets/d/1GP-wc5RwVL54UcYqxjH_ZfCQWY7IAfuL_BSC2SXCL8s/edit?usp=sharing

*/

export const DummyMenuItem2 = [
    {
        id: 1,
        isActive: 1,
        title: 'Hair',
        priority: 1,
        menuGroups: [
            {
                id: 1,
                menuCategoryId: 1,
                isActive: 1,
                title: 'CUT',
                menus: [
                    {
                        id: 1,
                        menuGroupId: 1,
                        isActive: 1,
                        title: '気がついてみると、さっきから、ごとごとごとごと、ジョバンニの乗っている小さな列車が走りつづけていたのでした。',
                        price: 4000,
                        range: 1,
                        note: '',
                        priotiry: 1
                    },
                    {
                        id: 2,
                        menuGroupId: 1,
                        isActive: 1,
                        title: 'カット 高校生',
                        price: 2600,
                        range: 1,
                        note: '',
                        priotiry: 2
                    },
                    {
                        id: 3,
                        menuGroupId: 1,
                        isActive: 1,
                        title: 'カット 小中校生',
                        price: 1800,
                        range: 1,
                        note: '※中央区店、西区店のみの対応となります。',
                        priotiry: 3
                    },
                    {
                        id: 4,
                        menuGroupId: 1,
                        isActive: 1,
                        title: 'カット 6歳以下',
                        price: 2600,
                        range: 1,
                        note: '',
                        priotiry: 4
                    },
                    {
                        id: 5,
                        menuGroupId: 1,
                        isActive: 1,
                        title: '前髪カット',
                        price: 2600,
                        range: 1,
                        note: '鮮やかさと透明感をプラスするオレンジピンク系のベースカラーにアッシュ系カラーをナチュラルブレンド。',
                        priotiry: 5
                    },
                ]
            },
            {
                id: 2,
                menuCategoryId: 2,
                isActive: 1,
                title: 'TREATMENT',
                menus: [
                    {
                        id: 6,
                        menuGroupId: 2,
                        isActive: 1,
                        title: 'カット 大人',
                        price: 4000,
                        range: 1,
                        note: '',
                        priotiry: 1
                    },
                    {
                        id: 7,
                        menuGroupId: 2,
                        isActive: 1,
                        title: 'カット 高校生',
                        price: 2600,
                        range: 1,
                        note: '',
                        priotiry: 2
                    },
                    {
                        id: 8,
                        menuGroupId: 3,
                        isActive: 1,
                        title: 'カット 小中校生',
                        price: 1800,
                        range: 1,
                        note: '※中央区店、西区店のみの対応となります。',
                        priotiry: 3
                    },
                    {
                        id: 9,
                        menuGroupId: 4,
                        isActive: 1,
                        title: 'カット 6歳以下',
                        price: 2600,
                        range: 1,
                        note: '',
                        priotiry: 4
                    },
                    {
                        id: 10,
                        menuGroupId: 5,
                        isActive: 1,
                        title: '前髪カット',
                        price: 2600,
                        range: 1,
                        note: '鮮やかさと透明感をプラスするオレンジピンク系のベースカラーにアッシュ系カラーをナチュラルブレンド。',
                        priotiry: 5
                    },
                ]
            },

        ]
    },

    {
        id: 2,
        isActive: 1,
        title: 'Esthetic',
        priority: 2,
        menuGroups: [
            {
                id: 3,
                menuCategoryId: 2,
                isActive: 1,
                title: 'CUT',
                priority: 1,
                menus: [
                    {
                        id: 11,
                        menuGroupId: 3,
                        isActive: 1,
                        title: 'カット 大人',
                        price: 4000,
                        range: 1,
                        note: '',
                        priotiry: 1
                    },
                    {
                        id: 12,
                        menuGroupId: 3,
                        isActive: 1,
                        title: 'カット 高校生',
                        price: 2600,
                        range: 1,
                        note: '',
                        priotiry: 2
                    },
                    {
                        id: 13,
                        menuGroupId: 3,
                        isActive: 1,
                        title: 'カット 小中校生',
                        price: 1800,
                        range: 1,
                        note: '※中央区店、西区店のみの対応となります。',
                        priotiry: 3
                    },
                    {
                        id: 14,
                        menuGroupId: 3,
                        isActive: 1,
                        title: 'カット 6歳以下',
                        price: 2600,
                        range: 1,
                        note: '',
                        priotiry: 4
                    },
                    {
                        id: 15,
                        menuGroupId: 3,
                        isActive: 1,
                        title: '前髪カット',
                        price: 2600,
                        range: 1,
                        note: '鮮やかさと透明感をプラスするオレンジピンク系のベースカラーにアッシュ系カラーをナチュラルブレンド。',
                        priotiry: 5
                    },
                ]
            },
            {
                id: 4,
                menuCategoryId: 2,
                isActive: 1,
                title: 'TREATMENT',
                priority: 2,
                menus: [
                    {
                        id: 16,
                        menuGroupId: 4,
                        isActive: 1,
                        title: 'カット 大人',
                        price: 4000,
                        range: 1,
                        note: '',
                        priotiry: 1
                    },
                    {
                        id: 17,
                        menuGroupId: 4,
                        isActive: 1,
                        title: 'カット 高校生',
                        price: 2600,
                        range: 1,
                        note: '',
                        priotiry: 2
                    },
                    {
                        id: 18,
                        menuGroupId: 4,
                        isActive: 1,
                        title: 'カット 小中校生',
                        price: 1800,
                        range: 1,
                        note: '※中央区店、西区店のみの対応となります。',
                        priotiry: 3
                    },
                    {
                        id: 19,
                        menuGroupId: 4,
                        isActive: 1,
                        title: 'カット 6歳以下',
                        price: 2600,
                        range: 1,
                        note: '',
                        priotiry: 4
                    },
                    {
                        id: 20,
                        menuGroupId: 4,
                        isActive: 1,
                        title: '前髪カット',
                        price: 2600,
                        range: 1,
                        note: '鮮やかさと透明感をプラスするオレンジピンク系のベースカラーにアッシュ系カラーをナチュラルブレンド。',
                        priotiry: 5
                    },
                ]
            },
        ]
    },
];

