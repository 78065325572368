import {Action, getModule, Module, Mutation, VuexModule} from "@/node_modules/vuex-module-decorators";
import store from "@/classes/core/store";
import VarUtil from "@/classes/utils/var-util";
import {appAuthModule} from "@/store/app/auth";

export interface IAppApiModule {
}

@Module({dynamic: true, store, name: 'appApi', namespaced: true})
class Store extends VuexModule implements IAppApiModule {

    @Action
    public async e(res: any) {

        const status = Number(VarUtil.path(res, 'response.status'));
        switch (status) {
            case 401:
                await M.e401();
                break;
            default:
                break;
        }
    }

    @Action
    public async e401() {
        console.log('E401 > logout()');
        await appAuthModule.logout();
    }
}

export const appApiModule = getModule(Store);

const M = appApiModule;
