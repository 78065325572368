import {Action, getModule, Module, Mutation, VuexModule} from "@/node_modules/vuex-module-decorators";
import store from "@/classes/core/store";
import VarUtil, {$v} from "@/classes/utils/var-util";
import ManagerApi from "@/classes/app/api/manager-api";
import ProdApi from "@/classes/app/api/prod-api";
import {IValidateErrorBag} from "@/classes/validation/validator";
import {DevUtil} from "@/classes/utils/dev-util";
import app from "@/classes/app/app";
import WebApi from "@/classes/app/api/manager/web-api";

export enum AuthErrorCode {
    None = 0,
    E401 = 401,
}

export enum AuthProduct {
    To004Owner = 'to004.owner',
}

export interface IAuthRequest {
    prod?: AuthProduct;
    project?: string | null;
    owner?: any;
    user?: any;
    token?: string;
    loginedAt?: string;
}

export interface IAuth {
    prod: AuthProduct;
    project: string;
    owner: any;
    user: any;
    token: string;
    loginedAt: string;
}

export interface IAuthErrorBag extends IValidateErrorBag {
    prod: AuthProduct,
    code: AuthErrorCode,
}

export interface IAppAuthModule {
    auth: IAuth | null;
    auths: IAuth[];
    errors: IAuthErrorBag[];
}

const LS_NAME = 'appAuthModule';

@Module({dynamic: true, store, name: 'appAuth', namespaced: true})
class Store extends VuexModule implements IAppAuthModule {

    public auth: IAuth | null = null;
    public auths: IAuth[] = [];
    public errors: IAuthErrorBag[] = [];

    @Mutation
    public updateAuths(value: IAuth[]) {
        this.auths = value;
    }

    @Mutation
    public updateAuth(value: IAuth | null = null) {
        this.auths = this.auths.replaceByKey('prod', value).array;
    }

    @Mutation
    public removeAuth(prod: AuthProduct) {
        this.auths = this.auths.rejectByKey('prod', prod);
    }

    @Mutation
    public updateErrors(value: IAuthErrorBag[] = []) {
        this.errors = value;
    }

    @Mutation
    public updateError(value: IAuthErrorBag) {
        this.errors = this.errors.replaceByKey('name', value).array;
    }

    // Actions ///////////////////////////////////////
    @Action
    public async isAuthTo004(): Promise<boolean> {
        return !!M.auths.findByKey('prod', AuthProduct.To004Owner);
    }

    @Action
    public async hello() {
        try {
            const res = await ProdApi.System.hello().execAsync();
            const owner = $v.p(res, 'owner');

            if (!!owner) {
                console.log('authModule.hello() > session ', owner);
                return;
            }

            console.error('authModule.hello() > no session logout >', owner, JSON.stringify(res));
            alert('DEBUG: LOGOUT');
            await M.logout();
        } catch (e) {
        }
    }

    @Action
    public async loginTo004Owner(auth: IAuthRequest) {
        M.updateAuth({
            prod: AuthProduct.To004Owner,
            project: VarUtil.path(auth, 'project'),
            owner: VarUtil.path(auth, 'owner'),
            user: VarUtil.path(auth, 'user'),
            token: VarUtil.path(auth, 'token'),
            loginedAt: $v.datetimeFormat(),
        });
        await M.save();
    }

    @Action
    public async login(auth: IAuthRequest) {
        M.updateAuth({
            ...(M.auths.findByKey('prod', auth.prod) || {}),
            ...{
                prod: VarUtil.path(auth, 'prod'),
                project: VarUtil.path(auth, 'project'),
                owner: VarUtil.path(auth, 'owner'),
                user: VarUtil.path(auth, 'user'),
                token: VarUtil.path(auth, 'token'),
                loginedAt: $v.datetimeFormat(),
            }
        });
        await M.save();
    }

    @Action
    public async postAuth(src: string) {
        try {
            const param = {
                email: src,
            };
            const res = await ProdApi.OwnerAuth.post(param).execAsync();
            return true;
        } catch (e) {
            return false;
        }
    }

    @Action
    public async logout(prod: AuthProduct | null = null) {

        if (!prod) {
            await M.clear();
        } else {
            M.removeAuth(prod);
        }

        localStorage.clear();  //全データを消去
        await ManagerApi.System.logout(prod).execAsync();

        console.log('store/auth.logout()');
    }

    @Action
    public async setup() {

        await M.load();
        if (await M.isAuthTo004()) {
            await M.hello();
        }
    }

    @Action
    public async load() {
        try {
            const auths = VarUtil.tap(localStorage.getItem(LS_NAME), (src: string) => {
                if (!src) {
                    return [];
                }
                const auths = JSON.parse(src);
                return Array.isArray(auths) ? auths : [];
            });

            // DevUtil.log('authModule.load() > ', auths);
            appAuthModule.updateAuths(auths);
        } catch (e) {
            appAuthModule.updateAuths([]);
        }
    }

    @Action
    public async save() {
        localStorage.setItem(LS_NAME, JSON.stringify(M.auths));
    }

    @Action
    public async clear() {
        M.updateAuths([]);
    }

    @Action
    public async e401(e: any) {
        console.log('sore/manager/project.fetch() error > ', $v.p(e, 'response.statusCode'));
        if ($v.p(e, 'response.statusCode') === 401) {
            console.log('- logout');
            await appAuthModule.logout(AuthProduct.To004Owner);
        }

        return e;
    }
}

export const appAuthModule = getModule(Store);

const M = appAuthModule;
