export enum SiteStartState {
    Active = 'active', // 正常
    WorkType = 'worktype', // 業種選択
    Wizard = 'wizard', // スタートプログレス
    Other = 'other', // その他の異常
}


export class SiteConst {

    public static readonly Start
}
