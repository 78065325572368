import {Component, Watch, Vue} from 'nuxt-property-decorator';
import AVueComponent from '@/classes/page/a-vue-component';
import {themeMainModule} from '@/store/theme/theme-main';
import {themeStateModule} from '@/store/theme/theme-state';
import {themeCssvarsModule} from '@/store/theme/theme-cssvars';
import {editModule} from '@/store/edit';
// import {ScrollUtil} from '@/classes/view/scroll-util';
import VarUtil from "@/classes/utils/var-util";
import UseragentUtil from '@/classes/view/useragent-util';
import CCssvarsMix from '@/classes/theme/mixin/c-cssvars-mix';

import {Logos} from '@/configs/logos';
import {Brands} from '@/configs/brands';
import {DummyProject} from '@/configs/dummy/dummy-project';

import CLoaderFrame from '@/components/theme/module/CLoaderFrame.vue';
import CPopupFrame from '@/components/theme/module/popup/CPopupFrame.vue';
import CSelectFrame from '@/components/theme/module/select/CSelectFrame.vue';
import CHeader from '@/components/theme/module/header/CHeader.vue';
import CFooter from '@/components/theme/module/footer/CFooter.vue';
import CMv from '@/components/theme/module/home/CMv.vue';
import CSidecover from '@/components/theme/module/home/sidecover/CSidecover.vue';

import * as $ from 'jquery';
import {OverlayScrollbarsComponent} from "@/node_modules/overlayscrollbars-vue";
import {EnvUtil} from "@/classes/app/env-util";
import {ownersiteModule} from "@/store/ownersite";
import {MainModuleBinder} from "@/classes/domain/module/os/project/main-module-binder";


@Component({
    mixins: [CCssvarsMix],
    components: {
        CHeader,
        CMv,
        CSidecover,
        CFooter,
        CSelectFrame,
        CPopupFrame,
        CLoaderFrame,
    }
})
export default class CLayoutStandard extends Vue {

    public mScrollFrameOption: any = {
        callbacks: {
            onScroll: this.onScrollMpf,
        }
    };

    /*
        Webfont
        mac: Mac, iOS
        win: windows, android
        setWebfontsでセット
    */
    public webfonts: any = {

        mac: {
            adobe: '',
            monotype: '',
            google: ''
        },

        ios: {
            adobe: '',
            monotype: '',
            google: ''
        },

        windows: {
            adobe: '',
            monotype: '',
            google: ''
        },

        android: {
            adobe: '',
            monotype: '',
            google: ''
        }
    };

    /*
        処理用変数
    */
    public $cMainFrame: any;
    public resizeTimer: any = false;
    public lastDevice: string = '';
    public ticking: any = false;
    //public $mScrollFrame: any;
    public fixedObj: any = [];
    public isCMainFrameMounted: boolean = false;

    //setTimeout
    public visibleContents: boolean = true;
    public visibleContentsDuration: number = 300;
//     public switchScrollDownReverseColor: boolean = false;


// 	@Watch('navOpen')
// 	public watchNavOpen(value: boolean) {
//
// 		/*
// 			メニューが開いていたらc-main-frameのnicescrollを止める
// 		*/
// 		if(themeMainModule.deviceDevice == 'pc') {
// 			if(value){
// 				ScrollUtil.remove();
//
// 			}else{
// 				ScrollUtil.init();
// 			}
// 		}
// 	}


    public head() {
        return {
            meta: [
                {name: 'format-detection', content: 'telephone=no'}
            ]
        }
    }


    /*
	    CREATED
    */
    public start() {

        // MainModuleBinder.bindProject(DummyProject);

        // console.log('CLayoutStandard.start() > ', this.project.navigation);
        //
        // //初回ローディング
        // themeStateModule.updateLoadingMain(true);
        //
        // //PROJECT DATA を STORE に格納
        // themeMainModule.updateProject(this.project);
        // themeMainModule.updateShopname(this.project.logo.shopname);
        // themeMainModule.updateTagline(this.project.logo.tagline);
        // themeMainModule.updateNavbottom(this.project.footer.navbottom);
        //
        // //LOGO SCALES
        // themeMainModule.updateNavLogoScale(this.project.logo.scale.navLogo);
        // themeMainModule.updateFooterLogoScale(this.project.logo.scale.footerLogo);
        // themeMainModule.updateBlockLogoScale(this.project.logo.scale.blockLogo);
        // themeMainModule.updateLoadingLogoScale(this.project.logo.scale.loadingLogo);
        //
        // //NAVIGATION DATA
        // themeMainModule.updateProjectNavigationPage(this.project.navigation.page);
        // themeMainModule.updateProjectNavigationSns(this.project.navigation.sns);
        //
        // //MAIN STORE
        // //FOOTER などで使用
        // themeMainModule.updateMainStoreAddress1(this.project.mainstore.address1);
        // themeMainModule.updateMainStoreAddress2(this.project.mainstore.address2);
        // themeMainModule.updateMainStoreOpen(this.project.mainstore.open);
        // themeMainModule.updateMainStoreClose(this.project.mainstore.close);
        // themeMainModule.updateMainStoreMap(this.project.mainstore.map);
        // themeMainModule.updateMainStoreTel(this.project.mainstore.tel);
        // themeMainModule.updateMainStoreReservation(this.project.mainstore.reservation);
        //
        // //FOOTER
        // themeMainModule.updateFooter(this.project.footer);
        //
        // //言語を設定
        // themeMainModule.updateLang('ja');
        //
        // //BRAND DATA
        // const brand: any = Brands.findByKey('brandid', this.project.global.brandid);
        // if (!brand) {
        //     return
        // }
        // // console.log('CLayoutStandard > ', brand);
        // themeMainModule.updateBrand(brand);
        //
        // //LAYOUT DATA
        // const layout: any = brand.layouts.findByKey('id', this.project.global.layout);
        // if (!layout) {
        //     return
        // }
        // //console.log(layout);
        // themeMainModule.updateLayout(layout);

        this.classLayoutId = '-layout--' + themeMainModule.project.global.layout;
        console.log('CLayoutStandard > start() > ', this.classLayoutId);

        // //MV
        // this.initMvData();
        //
        // //SIDECOVER
        // this.initSidecoverData();
        //
        // //読み込むWebfontをOSごとにセット
        // this.setWebfonts(this.webfonts, brand.webfont, this.project.logo);
    }

    /*
	    MOUNTED
    */
    public mounted() {

        console.log('CLayoutStandard.mounted()', this.project);

        this.start();

        //this.$mScrollFrame = this.$refs['scrollframe'] as HTMLElement;

        /*
            デバイス判定
            themeMainModule.device にセット
        */
        this.setDevice();
        this.$cMainFrame = $(this.$refs.mainframe);

        this.$nextTick(() => {

            //Init CSS vars
            this!['updateCssvars'](themeCssvarsModule, themeMainModule);

            /*
                SET BREAK POINTS
                changedBreakpoint()
            */
            this.resize();

            this.$nextTick(() => {

                //Overlay Scrollbarは、c-main-frameの動的クラスがすべて設定されてから読み込まないとバグるので遅らせる
                this.isCMainFrameMounted = true;

                //一旦コンテンツを透明にしておく
                setTimeout(() => {
                    this.visibleContents = false;

                }, this.visibleContentsDuration);

                //ローダーの非表示
                setTimeout(() => {
                    themeStateModule.updateLoadingLogo(false);
                }, 1600);

                setTimeout(() => {
                    themeStateModule.updateLoadingMain(false);
                }, 2200);
            });
        });
    }


    /////////// Changed Layout //////////////////////////////////////////////

    public classLayoutId: string = '';

    @Watch('layoutId')
    public watchLayoutId(value: string) {
        this.changeLayoutId(value);
    }

    public get project(): any {
        return EnvUtil.isDummy ? DummyProject : ownersiteModule.project;
    }

    public get layoutId(): any {
        if (!themeMainModule.project) {
            return;
        }
        return VarUtil.path(themeMainModule, 'project.global.layout') || 'photo-fill';
    }

    public changeLayoutId(value: string) {
//     	console.log('Changed layout id');

        //LAYOUT DATA
        const layout: any = themeMainModule.brand.layouts.findByKey('id', this.project.global.layout);
        if (!layout) {
            return
        }

        themeMainModule.updateLayout(layout);

        //Init CSS vars
        this!['updateCssvars'](themeCssvarsModule, themeMainModule);

        this.resize();

        this.classLayoutId = '-layout--' + value;
    }


//     public changedLayoutId() {
//     	console.log('Changed layout id');
//
// 	    //LAYOUT DATA
//     	const layout: any = themeMainModule.brand.layouts.findByKey('id', this.project.global.layout);
//     	if( !layout ) { return }
//
// 	    themeMainModule.updateLayout(layout);
//
// 	    //Init CSS vars
// 	    this!['updateCssvars'](themeCssvarsModule, themeMainModule);
//
// 		this.resize();
//     }

//     public get classLayoutId(): any {
// 	    return '-layout--' + themeMainModule.project.global.layout;
//     }


    /*
	    Init data
    */
    public initSidecoverData() {
        themeMainModule.updateScType(this.project.sidecover.type);
        themeMainModule.updateScDesign(this.project.sidecover.design);
        themeMainModule.updateScAddrTitle(this.project.sidecover.addrTitle);
        themeMainModule.updateScAddrLink(this.project.sidecover.addrLink);
        themeMainModule.updateScAddress(this.project.sidecover.address);
        themeMainModule.updateScAddrFooter(this.project.sidecover.addrFooter);
        themeMainModule.updateScAddrFooterTitle(this.project.sidecover.addrFooterTitle);
        themeMainModule.updateScAddrFooterLink(this.project.sidecover.addrFooterLink);
    }


    public initMvData() {
        themeMainModule.updateMvAddress(this.project.mv.address);
        themeMainModule.updateMvTopics(this.project.mv.topics);
        themeMainModule.updateMvContent(this.project.mv.content);
        themeMainModule.updateMvImg(this.project.mv.img);
        themeMainModule.updateMvContentTxt(this.project.mv.contentTxt);
        themeMainModule.updateMvContentImg(this.project.mv.contentImg);
        themeMainModule.updateMvContentImgScale(this.project.mv.contentImgScale);
        themeMainModule.updateMvContentLogoScale(this.project.mv.ContentLogoScale);
        themeMainModule.updateMvContentTxtScale(this.project.mv.ContentTxtScale);

        themeMainModule.updateMvAddressTitle(this.project.mvAddress.title);
        themeMainModule.updateMvAddressBlock1title(this.project.mvAddress.block1title);
        themeMainModule.updateMvAddressBlock1body(this.project.mvAddress.block1body);
        themeMainModule.updateMvAddressBlock1link(this.project.mvAddress.block1link);
        themeMainModule.updateMvAddressBlock2title(this.project.mvAddress.block2title);
        themeMainModule.updateMvAddressBlock2body(this.project.mvAddress.block2body);
        themeMainModule.updateMvAddressBlock3title(this.project.mvAddress.block3title);
        themeMainModule.updateMvAddressBlock3body(this.project.mvAddress.block3body);
        themeMainModule.updateMvAddressBlock3link(this.project.mvAddress.block3link);
    }


    /*
        デバイス判定
    */
    public setDevice() {
        themeMainModule.updateDeviceOs(UseragentUtil.os());
        themeMainModule.updateDeviceDevice(UseragentUtil.device());
    }


    /*
	    読み込むWebfontをセット
	    1. デザインに必要な書体
	    2. Thought Logosの場合 使われてる書体
    */
    public setWebfonts(webfonts: any, map: any, logo: any) {

        webfonts = {

            mac: {
                adobe: map.adobe,
                monotype: map.monotype,
                google: []
            },

            ios: {
                adobe: map.adobe,
                monotype: map.monotype,
                google: []
            },

            windows: {
                adobe: map.adobe,
                monotype: map.monotype,
                google: [map.google_ja, map.google_en]
            },

            android: {
                adobe: map.adobe,
                monotype: map.monotype,
                google: [map.google_ja, map.google_en]
            }
        };


        /*
            mac_load: Mac, iOSでもGoogle Fontsを読み込むか
        */
        if (map.mac_load) {
            webfonts.mac.google = [map.google_ja, map.google_en];
            webfonts.ios.google = [map.google_ja, map.google_en];
        }


        /*
            ロゴがThought Logosだった場合必要な書体を登録
        */
        if (logo.type == 'webfont') {

            const logodata: any = Logos.findByKey('id', logo.white);
            if (!logodata) {
                return
            }

            //ロゴデータがはいってなければ追加
            if (!themeMainModule.thoughtLogo) {
                themeMainModule.updateThoughtLogo(logodata);
            }

            //Googleに書体を追加
            webfonts.windows.google.push(themeMainModule.thoughtLogo.mfamily, themeMainModule.thoughtLogo.sfamily);
            webfonts.android.google.push(themeMainModule.thoughtLogo.mfamily, themeMainModule.thoughtLogo.sfamily);

        }
    }

    public get getMedia(): any {
        return themeMainModule.manager;
    }

    // Switch Layout
    public get layoutChangeAnimation(): any {
        return editModule.layoutChangeAnimation;
    }


    // CSS Class ///////////////////////////////////////////////////////////////

    public get classLang(): any {
        return '-' + themeMainModule.lang;
    }

    public get classMedia(): any {
        if (this.getMedia) {
            return '-media--manager'
        }
        return '-media--user';
    }

    public get classDeviceSize(): any {
        return '-' + themeMainModule.deviceSize;
    }

    public get classDeviceDevice(): any {
        return '-device--' + themeMainModule.deviceDevice;
    }

    public get classPageType(): any {
        return '-p-type--' + themeMainModule.pageType;
    }

    public get classPagePage(): any {
        return '-p-page--' + themeMainModule.pagePage;
    }

    public get classPageDepth(): any {
        return '-p-depth--' + themeMainModule.pageDepth;
    }

    public get classSidecoverDesign(): any {
        return '-' + themeMainModule.scDesign;
    }

    //MV のテキストカラー autoの場合
    public get classMvImageBrightness(): any {
        if (themeCssvarsModule.mvTxtBase == 'auto') {
            return '-mv-txt--' + themeStateModule.mvImageBrightness;
        }
        return;
    }

    /*
		MV
    */
    public get pageType(): any {
        return themeMainModule.pageType;
    }

    /*
	    NAVIGATION
    */
    public get navOpen(): any {
        return themeStateModule.navOpen;
    }

    // Events ////////////////////////////////////////////////////////////////

    // Scroll ///////////////////////////////////////////////////////////////

    public onScrollMpf() {
        if (process.client) {
            this.ticking = this.ticking || requestAnimationFrame(this.scrollUpdate);
        }
    }

    public scrollUpdate() {

        //ScrollTop
        const y = (this.$refs.mScrollframe as OverlayScrollbarsComponent).osInstance().scroll().position.y;

        themeMainModule.updateScrollTop(y);

        // Navigation が固定で背景がないLayoutの場合, 下にスクロールしたら文字の色をtxtにする
        if (y > themeMainModule.mainFrameHeight - 200) {
            themeStateModule.updateSwitchScrollDownReverseColor(true);
            /*             this.switchScrollDownReverseColor = true; */

        } else {
            themeStateModule.updateSwitchScrollDownReverseColor(false);
            /*             this.switchScrollDownReverseColor = false; */
        }

        this['headerColor']();
        this.ticking = false;
    }


    // Resize ///////////////////////////////////////////////////////////////

    @Watch('mainFrameResized')
    public watchMainFrameResized() {
        console.log('owner resized Event');

        if (process.client) {
            if (this.$cMainFrame.length == 0) {
                return;
            }
        }

        const w: number = this.$cMainFrame.outerWidth();
        const h: number = this.$cMainFrame.outerHeight();

        let pos = this.$cMainFrame.offset();

        //Wizardなどのタブレットのときは top: 0 にする
        if (this.$cMainFrame.closest('.m-device').length == 1) {

            pos.top = 0;
        }

        themeMainModule.updateMainFrameWidth(w);
        themeMainModule.updateMainFrameHeight(h);
        themeMainModule.updateMainFrameOffset(pos);


        if (themeMainModule.onResize == true) {
            themeMainModule.updateDeviceSize(UseragentUtil.size(w));
            //this.$refs.mScrollframe.osInstance().update();
        }

        // 最初に、ビューポートの高さを取得し、0.01を掛けて1%の値を算出して、vh単位の値を取得
        let vh = h * 0.01;
        // カスタム変数--vhの値をドキュメントのルートに設定
        document.documentElement.style.setProperty('--c-vh', `${vh}px`);
    }

    public get mainFrameResized(): any {
        return themeMainModule.mainFrameResized;
    }

    public resize() {
//         console.log('owner resized');
        themeMainModule.updateMainFrameResized();
    }

    public get switchScrollDownReverseColor(): any {
        return themeStateModule.switchScrollDownReverseColor;
    }

    /*
        表示スイッチ
    */
    public get isLoadingMain(): any {
        return themeStateModule.loadingMain;
    }

// 	public get loading(): any {
//     	themeStateModule.loading;
// 	}


}
