import {Component, Watch, Prop, Vue} from '@/node_modules/nuxt-property-decorator';
import APopup from "@/classes/page/a-popup";

import MPopupMBody from '@/components/popup/module/MPopupMBody.vue';
import ViewModelUtil from "@/classes/view/view-model-util";
import {appEditModule, IEdit} from "@/store/app/edit";
import {$v} from "@/classes/utils/var-util";
import {ExtEdit} from "@/classes/ext/ext-edit";
import {$apm} from "@/classes/app/app-manager";
import {managerShopMenuCategoryModule, managerShopMenuCategoryModule as M} from "@/store/manager/shop-menu-category";
import {SelectUtil} from "@/classes/utils/select-util";
import {ISelectSource} from "@/classes/domain/model";

@Component({
    components: {
        MPopupMBody
    }
})
export default class MPopupMenuCategory extends APopup {

    public state: any = ViewModelUtil.createPageState({
        config: {
            editId: 'menuCategory',
        },
    });

    // Init ////////////////////////////////////////////////////////
    public created() {
        super.created();
    }

    public async mounted() {
        // console.log('mounted() > config', this.config);
        await this.initEdit();
    }

    public async initEdit() {
        await appEditModule.start({
            id: this.editId,
            input: {
                title: '',
                title_head: '',
                color: this.selectSourceColorDefaultValue,
                is_active: 1,
            },
        });
    }

    ////////// POPUP LAYER 共通処理 /////////////////////////////////
    public onClose(to: string = ''): any {
        this.$emit('close');
    }

    // Computed //////////////////////////////////////////////////////
    // component config
    public get cfg(): string {
        return $v.path(this.state, 'config');
    }

    public get editId(): string {
        return $v.path(this.cfg, 'editId');
    }

    public get extEdit(): ExtEdit {
        return new ExtEdit(this);
    }

    public get edit(): IEdit {
        return this.extEdit.edit;
    }

    public get category(): any {
        return managerShopMenuCategoryModule.records.findByKey('toid',
            $v.path(this.$route, 'params.category')
        );
    }

    public get input(): any {
        return this.extEdit.edit.input;
    }

    public get filterInput(): any {
        return {
            ...this.input,
            ...{
                site: $apm.pj.toid,
                parent: this.category.toid,
            }
        };
    }

    public get selectSourceColor(): ISelectSource {
        return SelectUtil.findSource('menu-icon-color');
    }

    public get selectSourceColorDefaultValue(): ISelectSource {
        return this.selectSourceColor.d[0];
    }

    public get selectColorL(): string {
        return SelectUtil.selectedL(this.input.color, 'menu-icon-color');
    }

    public get classSelectColor(): string {
        const s = this.selectSourceColor;
        const idx = !!s ? s.d.indexOf(this.input.color) : -1;
        return idx >= 0 ? s.icon[idx] : '';
    }

    // Methods ///////////////////////////////////////////////////////
    public e(elm: string): string[] {
        return [];
    }

    // Events ////////////////////////////////////////////////////////
    public async onInput(e: any) {

        await this.extEdit.onInput(e);

        if (e.name === 'title'
            && e.value !== ''
            && this.input.title_head.length === 0) {
            const value = this.input.title
                .substring(0, 1)
                .toUpperCase();
            await this.extEdit.updateInput({
                name: 'title_head',
                value,
            });
        }
    }

    public async onSubmit() {

        // console.log('onSubmit', this.filterInput, $apm.pj);
        await this.extLoading.load(async () => {
            await M.save(this.filterInput);
            await M.fetch();
        });

        this.$emit('close');
    }
}
