



















	// @ts-ignore
    import _ from './MInput.ts'; export default _;
