import {Component, Prop, Vue} from 'nuxt-property-decorator';
import {themeMainModule} from '@/store/theme/theme-main';
import CPatternMix from '@/classes/theme/mixin/c-pattern-mix';
// import {ScrollUtil} from '@/classes/view/scroll-util';
import LayoutsUtil from "@/classes/view/layouts-util";

import {DummyStaff} from '@/configs/dummy/dummy-staff';
import {DummyArticleCategory} from '@/configs/dummy/dummy-article-category';

import * as $ from 'jquery';
import {osStaffModule} from '@/store/os/staff';

@Component({
    mixins: [CPatternMix],
    components: {}
})
export default class CPageStaffIndexStandard extends Vue {

    @Prop({default: '0'})
    public active: string;

    /*
    CList の表示項目設定
    */
    @Prop({default: false})
    public img: boolean;

    @Prop({default: false})
    public subtitle: boolean;

    @Prop({default: false})
    public cat: boolean;

    @Prop({default: false})
    public body: boolean;

    @Prop({default: false})
    public footer: boolean;

    @Prop({default: true})
    public detail: boolean;


    /*
    CONST
    */
    public transitionTime: number = 190;

    //ACTIVE CATEGORY
    public activeId: string = '0';
    public activeItemId: string = '';

    /*
    開閉で使う変数
    */
    public isOpen: boolean = false;
    public detailContentImg: any = [];
    public detailContentTitle: string = '';
    public detailContentSubtitle: string = '';
    public detailContentBody: string = '';
    public detailContentSns: any = [];
    public detailContentWebsite: string = '';

    //強制的に表示非表示してリセットしないとSWIPERが正常に動作しないので、スイッチを使って一瞬切り替える
    public updateSwiper: boolean = true;

    //クリックされた要素が何個目か
    public openList: number = -1;
    public nowItemNumber: number;
    public lastItemNumber: number;

    //クリックされた要素が何段目か
    public nowOpenRow: number = 0;
    public clickedOpenRow: number = 0;
    public gridNumber: number = 0;

    public styleDetail: any = {};
    public classListOpen: string = '';
    public isHidden: boolean = false;

    // public list: any = DummyStaff;


    public mounted() {

        /*
            STAFF
        */
        if (themeMainModule.deviceSize != 'mb' && !this.isNoImgs && this.list.length >= 4) {
            this.gridNumber = LayoutsUtil.gridColumnNumber( $('.-openclose .c-list-item') );
        }

        this.$nextTick(() => {

            /*
                ここにページ遷移での処理を記述
                id を指定しれば最初から指定のアイテムが開く
            */
            //this.onToggle(id);
        });

    }


    public get list(): any {
        return osStaffModule.content!['staffs'] || [];
    }

    /*
        PAGE TITLE
    */

    public get pageId(): any {
        return themeMainModule.pageData.id;
    }


    /*
        Category Dataを取得
    */
    public get categories(): any {
        return DummyArticleCategory;
    }


    /*
        CLICK
    */
    public onToggle(id: string) {

        //id からリスト番号とデータを入手
        const i = this.list.findIndex(({id}) => id === id);
        const item = this.list.findByKey('id', id);

        const $el: any = $(this.$refs[id] as HTMLElement);
        this.gridNumber = LayoutsUtil.gridColumnNumber( $('.-openclose .c-list-item') );

        this.nowItemNumber = i;
        //console.log(`lastItemNumber: ${this.lastItemNumber} / nowItemNumber: ${this.nowItemNumber}`);

        //
        if (this.lastItemNumber != this.nowItemNumber) {

            this.clickedOpenRow = 2 + Math.floor(this.nowItemNumber / this.gridNumber);

            //現在の段と同じか
            if (this.nowOpenRow == this.clickedOpenRow) {

                //同じ
                //閉じていたら開く
                if (!this.isOpen) {
                    this.detailOpen(item);
                } else {
                    this.drawDetailContent(item);
                }
                this.activeItemId = item.id;

            } else {
                //違う段
                this.detailCloseOpen(item);
            }
            this.nowOpenRow = this.clickedOpenRow;
            this.lastItemNumber = this.nowItemNumber;

            //同じものをクリック
        } else {

            //閉じていたら開く
            if (!this.isOpen) {
                this.detailOpen(item);
            } else {
                this.detailClose();
            }
        }
    }


    /*
        DETAIL
    */
    public detailOpen(item: any) {

        //console.log('c_listDetailOpen();');
        //段数をgrid-rowの開始位置にいれる
        this.styleDetail = {
            'grid-row': this.clickedOpenRow + ' / span 1',
            '-ms-grid-row': this.clickedOpenRow,			//IE11
            '-ms-grid-row-span': this.clickedOpenRow		//IE11
        };

        this.isHidden = true;
        this.classListOpen = '-fadeout';
        this.updateDetailContentData(item);
        this.activeItemId = item.id;
        this.isOpen = true;

        window.location.href = window.location.pathname + '#staff-' + item.id;

        setTimeout(
            () => {
                this.isHidden = false;
                this.classListOpen = '';
            }, 420);
    }


    public detailCloseOpen(item: any) {

        //console.log('c_listDetailCloseOpen();');
        //閉じる
        if (this.isOpen) {

            //CLOSE
            this.detailClose();

            //OPEN
            setTimeout(
                () => {
                    this.detailOpen(item);
                }, (this.transitionTime * 2) + 300);

        } else {
            this.detailOpen(item);
        }
    }


    /*
        データの入れ替え
    */
    public updateDetailContentData(item: any) {
        this.detailContentTitle = item.title;
        this.detailContentSubtitle = item.subtitle;
        this.detailContentBody = item.body;
        this.detailContentImg = item.img;
        this.detailContentSns = item.sns;
        this.detailContentWebsite = item.website;

        this.updateSwiper = false;

        //FORCE UPDATE
        setTimeout(
            () => {
                this.updateSwiper = true;
            }, 2);
    }


    /*
        データの変更表示
    */
    public drawDetailContent(item: any) {

        //高さを維持
        this.styleDetail = {
            height: `${$(this.$refs.detail).outerHeight()}px`,
            'grid-row': this.clickedOpenRow + ' / span 1',
            '-ms-grid-row': this.clickedOpenRow,			//IE11
            '-ms-grid-row-span': this.clickedOpenRow		//IE11
        };

        //一旦フェードアウト
        this.classListOpen = '-fadeout';

        //中身入れ替え
        setTimeout(
            () => {
                this.updateDetailContentData(item);
            }, 220);

        //表示
        setTimeout(
            () => {
                this.classListOpen = '';
            }, 440);
    }


    /*
        閉じる
    */
    public detailClose() {
        this.isOpen = false;
        this.activeItemId = '';
    }

    public get odetailContentImg(): any {
        return this.detailContentImg;
    }

    public get sdetailContentTitle(): any {
        return this.detailContentTitle;
    }

    public get sdetailContentSubtitle(): any {
        return this.detailContentSubtitle;
    }

    public get sdetailContentBody(): any {
        return this.detailContentBody;
    }

    public get odetailContentSns(): any {
        return this.detailContentSns;
    }

    public get sdetailContentWebsite(): any {
        return this.detailContentWebsite;
    }

	// すべて画像がなければクラスをいれてデザインを変更
	public get isNoImgs(): any {

    	for (let i = 0 ; i < this.list.length ; i++) {

    	    if (this.list[i].img != '' || this.list[i].img.length > 0) {
        	    return false;
    	    }
    	}

    	return true;

	}

    //人数が3人以下の場合
	public get isLittle(): any {
    	if (this.list.length >= 4 && !this.isNoImgs) {
        	return false;
    	}

    	return true;
	}

}



