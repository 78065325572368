import {Mutation, Action, VuexModule, getModule, Module} from 'vuex-module-decorators';
import store from '@/classes/core/store';
//import {Component} from "nuxt-property-decorator";
import o from '@/classes/core/o';
import VarUtil from '@/classes/utils/var-util';

export interface IWizardPage {
    step: number,
    id: string,
    type: string,
    good: string,
    secondary: string,
    secWebPage: string,
    secWebBlock: string,
}

// state's interface
export interface IWizardStore {

    id: string;
    genre: string;
    type: string;
    good: boolean;
    secondary: string;
    secWebPage: string;
    secWebBlock: string;

    step: number;
    totalStep: number;
    wizardData: any;
}

@Module({dynamic: true, store, name: 'wizard', namespaced: true})
class Wizard extends VuexModule implements IWizardStore {

    public id: string = '';
    public genre: string = 'wizard-make';
    public type: string = 'break';
    public good: boolean = false;
    public secondary: string = '';

    //Secondary Frame のコンテンツ
    public secWebPage: string = '';
    public secWebBlock: string = '';

    //States
    public step: number = 0;
    public totalStep: number = 0;

    //Wizardの全ページデータ
    public wizardData: IWizardPage[] = [];

    @Action
    public update(values: any) {
        const ov = o(values);
        ov.foreach((k: string, v: any) => {
            if (VarUtil.has(this, k)) {
                const method = k.toUpperCase();
                this![method](v);
            }
        });
    }

    @Mutation
    public updateGenre(value: string) {
        this.genre = value;
    }

    @Mutation
    public updateId(value: string) {
        this.id = value;
    }

    @Mutation
    public updateTotalStep(value: number) {
        this.totalStep = value;
    }

    @Mutation
    public updateType(value: string) {
        this.type = value;
    }

    @Mutation
    public updateGood(value: boolean) {
        this.good = value;
    }

    @Mutation
    public updateSecondary(value: string) {
        this.secondary = value;
    }

    @Mutation
    public updateSecWebPage(value: string) {
        this.secWebPage = value;
    }

    @Mutation
    public updateSecWebBlock(value: string) {
        this.secWebBlock = value;
    }

    @Mutation
    public updateStep(value: number) {
        this.step = value;
    }

    @Mutation
    public updateWizardData(value: IWizardPage[]) {
        this.wizardData = value;
    }

}

export const wizardModule = getModule(Wizard);

