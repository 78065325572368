import {AExt} from "@/classes/ext/a-ext";
import {StoreAppEditUtil} from "@/classes/view/store/app/store-app-edit-util";
import {appEditModule} from "@/store/app/edit";
import {IValidateErrorBag} from "@/classes/validation/validator";
import {$v} from "@/classes/utils/var-util";

export class ExtEdit extends AExt {

    public get config() {
        return this.vue.state.config;
    }

    public get editId() {
        return $v.path(this.vue, 'mid')
            || $v.path(this.vue, 'editId')
            || $v.path(this.vue, 'config.editId')
            || '@';
    }

    public get edit() {
        return StoreAppEditUtil.find(this.editId);
    }

    public get input() {
        return this.edit!.input || {};
    }

    public get errors(): IValidateErrorBag[] {
        return this.edit!.errors || [];
    }

    public get hasErrors(): boolean {
        return (this.errors.length > 0);
    }

    // Methods ///////////////////////////////////////
    public async init() {
        await appEditModule.updateEdit(StoreAppEditUtil.createEdit({
            id: this.editId,
        }));
    }

    public classError(elm: string, errorClass: string = 'state-error', base: any = {}) {
        return Object.assign({},
            base, {
                [errorClass]: this.hasError(elm),
            });
    }

    public error(elm: string): IValidateErrorBag | null {
        return this.errors.findByKey('name', elm);
    }

    public hasError(elm: string): boolean {

        const elms = elm.split(',');

        let ec = 0;
        elms.filter((e: string) => {
            ec += !!this.error(elm) ? 1 : 0;
        });

        return ec > 0;
    }

    /**
     * {[key]: [value]}
     * @param input
     */
    public async updateInput(input: any) {
        // console.log('updateInput > ', input);
        await appEditModule.input({
            id: this.editId,
            input: {
                ...this.input,
                ...input,
            },
        });
    }

    public async onInput(input: any) {
        const upd = $v.adaptInput(input);
        await this.updateInput({
            [upd.name]: upd.value,
        });
    }

    public async updateErrors(errors: IValidateErrorBag[]) {
        await appEditModule.assign({
            id: this.editId,
            errors: errors,
        });
    }
}
