import {Component, Watch, Prop, Vue} from "vue-property-decorator";
import {themeMainModule} from '@/store/theme/theme-main';

import * as $ from 'jquery';

@Component({
    components: {
    }
})
export default class CImgRatio extends Vue {

	public saspect: string = '';

	@Prop({default: 'div'})
	public tag: string;

	@Prop({default: '/common/img'})
	public base: string | null;

	@Prop({default: 'auto'})
	public aspect: string;

	@Prop({default: '' })
	public src: any;

	@Prop({default: true})
	public loading: boolean;

	// UIを設置する?
	// none / thumb
	@Prop({default: 'none'})
	public ui: string;

	// MOBILEのときは強制的にautoにする?
	@Prop({default: false})
	public mbauto: boolean;


	public mounted() {
		this.ratio();
	}

	public ratio() {

		if(this.aspect == 'auto') {

			this.setRatio();

		}else{
			this.saspect = this.aspect;
		}

        if(themeMainModule.deviceSize == 'mb') {

    		if(this.mbauto) {

    			//MOBILEのときはautoで計算
    			this.setRatio();
    		}
        }
	}


	public setRatio() {

		let image: any = new Image();

		if(this.isSrcArray){

    		//配列の時 1枚目をテストする
    		image.src = this.base + this.src[0];

		}else{
		    image.src = this.base + this.src;
		}

		image.addEventListener('load', () => {

			const ratio: number = image.width / image.height;

			if( ratio < 0.87 ){
				this.saspect = 'vertical';

			}else if( ratio > 1.13 ){
				this.saspect = 'horizontal';

			}else{
				this.saspect = 'square';

			}

			this.$emit('mounted-ratio');

		});
	}

	public get classRatio(): any {
		 return '-' + this.saspect;
	}

    /*
        A IMG or ARRAY IMGS
    */
    public get isSrcArray(): any {

        if(this.src){

            if(typeof this.src == 'string'){

                return false;

            }else if(typeof this.src == 'object'){

                if(this.src.length > 1){
                    return true;
                }else{
                    return false;
                }

            }
        }

        return false;

    }
}

