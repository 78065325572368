import {Component, Watch, Prop, Vue} from '@/node_modules/nuxt-property-decorator';
import ViewPosMix from '@/classes/mixin/view-pos-mix';
import * as $ from 'jquery';
import {selectModule} from "@/store/select";

import MSelectAccountMenu from '@/components/select/MSelectAccountMenu.vue';
import MSelectAddCat from '@/components/select/MSelectAddCat.vue';
import MSelectColorBase from '@/components/select/MSelectColorBase.vue';
import MSelectColorMvTxt from '@/components/select/MSelectColorMvTxt.vue';
import MSelectColorSidecover from '@/components/select/MSelectColorSidecover.vue';
import MSelectColorSidecoverTxt from '@/components/select/MSelectColorSidecoverTxt.vue';
import MSelectNews from '@/components/select/MSelectNews.vue';
import MSelectInfo from '@/components/select/MSelectInfo.vue';
import MSelectList from '@/components/select/MSelectList.vue';
import MSelectUploadimg from '@/components/select/MSelectUploadimg.vue';
//import MSelectInput from '@/components/select/MSelectInput.vue';

@Component({
    mixins: [ViewPosMix],
    components: {
        MSelectAccountMenu,
        MSelectAddCat,
        MSelectColorBase,
        MSelectColorMvTxt,
        MSelectColorSidecover,
        MSelectColorSidecoverTxt,
        MSelectInfo,
        MSelectList,
        MSelectNews,
        MSelectUploadimg,
        //MSelectInput
    }
})
export default class MSelectFrame extends Vue {

    @Prop({default: ''})
    public target: string | null;

    @Watch('current')
    public watchCurrent() {
        console.log('MSelectFrame.watchCurrent()');
        this.setStyles();
    }

    public styles = {};
    public styleArrow = {};
    public componentName: string = '';
    public sizeclass: string = '';
    public isNoArrow: boolean = false;
    public d: any = [];
    public icon: any = [];
    public minWidth: number = 70;

    public get oconponent(): any {
        return this.componentName;
    }

    public get od(): any {
        return this.d;
    }

    public get oicon(): any {
        return this.icon;
    }

    public get current(): any {
        return selectModule.current;
    }

    // public get width(): number {
    //     return selectModule.width;
    // }

    public get hasCurrent(): boolean {
        return !!this.current;
    }

    public onClose() {
        //console.log('MSelectFrame onClose');
        selectModule.clearCurrent();
    }

    public setStyles() {

        if (!this.current) {
            return;
        }

        this.componentName = this.current.component;
        this.d = this.current.d;
        this.icon = this.current.icon;

        const pos = this.current.pos;
        const size = this.current.size;

        //Select 描画後に計算
        this.$nextTick(() => {
            const minw = $(this.$refs.mPopWrapper).outerWidth() + 16;
            const myw = size.w < minw ? minw : size.w;
            const myh = $(this.$refs.mPopWrapper).outerHeight();
            const ww = $(window).width();
            const wh = $(window).height();

            // console.log('myw > ', myw, size, selectModule.width);

            //座標
            const p: any = this!['posPop'](pos, size, myw, myh, ww, wh, this.current.cpos, this);

            //サイズ Size Class 指定あり
            if (this.current.sizeclass) {
                this.sizeclass = 'm-pop--' + this.current.sizeclass;
                this.styles = {
                    top: `${p.top}px`,
                    left: `${p.left}px`,
                }

                //Size Class 指定なし
            } else {
                this.sizeclass = '';

                //ボタンサイズが小さければ auto にする
                if (size.w < this.minWidth) {
                    this.styles = {
                        top: `${p.top}px`,
                        left: `${p.left}px`,
                    }

                } else {
                    this.styles = {
                        top: `${p.top}px`,
                        left: `${p.left}px`,
                        width: `${myw}px`
                    }
                }
            }
        });
    }

    public mounted() {

        console.log('MSelectFrame.mounted() > current', this.current);

        //console.log($(this.target).height());
        //ターゲットにマウスイベントを設定
    }

}
