import {Mutation, MutationAction, Action, VuexModule, getModule, Module} from 'vuex-module-decorators';
import store from '@/classes/core/store';

// state's interface
export interface IManagerDevModule {
    isBlogFilter: boolean;
}

@Module({dynamic: true, store, name: 'managerDev', namespaced: true})
class ManagerDev extends VuexModule implements IManagerDevModule {

    public isBlogFilter: boolean = false;

    @Mutation
    public updateIsBlogFilter(value: boolean) {
        this.isBlogFilter = value;
    }
}

export const managerDevModule = getModule(ManagerDev);

