import {Component, Vue, Prop} from '@/node_modules/nuxt-property-decorator';
import {selectModule} from '@/store/select';
import {ISelect} from "@/classes/domain/model";
import {SelectUtil} from "@/classes/utils/select-util";

@Component({
    components: {}
})
export default class MSelectList extends Vue {

    // Base /////////////////////////////////////////
    // public mounted() {
    //     console.log('MSelectList.mounted() > ', this.d, this.list);
    // }

    // Computed /////////////////////////////////////
    public get name(): string {
        return selectModule.name;
    }

    public get value(): string {
        return selectModule.value;
    }

    public get list(): ISelect[] {
        return SelectUtil.source2selects(selectModule.current.src || selectModule.current.d);
    }

    public get icon(): ISelect[] {
        return selectModule.current.icon;
    }

    /////// 共通処理 /////////////////////////////////
    ////////// POPUP LAYER 共通処理 /////////////////////////////////
    public onClose(to: string = ''): any {
        if (to != '') {
            this.$router.push(to);
        }
        this.$emit('close');
    }

    public async onClickValue(input: ISelect) {

        // console.log('MSelectList > onClickValue > ', input);

        if (!!selectModule.onSelect) {
            await selectModule.onSelect({
                name: this.name,
                value: input.value,
            });
        } else {
            console.log('MSelectList > onClickValue() > is null');
        }

        selectModule.updateLastSelected(input);
        selectModule.updateCurrent(false);

        this.$emit('close');
    }
}
