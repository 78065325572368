












	// @ts-ignore
    import _ from './MInview.ts'; export default _;
