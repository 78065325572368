import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const VUEX_PROPERTIES = ['state', 'getters', 'actions', 'mutations']
let store = {}

void (function updateModules() {
  // If store is an exported method = classic mode (deprecated)

  // Enforce store modules
  store.modules = store.modules || {}

  resolveStoreModules(require('../../store/account.ts'), 'account.ts')
  resolveStoreModules(require('../../store/app.ts'), 'app.ts')
  resolveStoreModules(require('../../store/body.ts'), 'body.ts')
  resolveStoreModules(require('../../store/cmd.ts'), 'cmd.ts')
  resolveStoreModules(require('../../store/edit.ts'), 'edit.ts')
  resolveStoreModules(require('../../store/layout-select.ts'), 'layout-select.ts')
  resolveStoreModules(require('../../store/notice.ts'), 'notice.ts')
  resolveStoreModules(require('../../store/official.ts'), 'official.ts')
  resolveStoreModules(require('../../store/openBlock.ts'), 'openBlock.ts')
  resolveStoreModules(require('../../store/ownersite.ts'), 'ownersite.ts')
  resolveStoreModules(require('../../store/popup.ts'), 'popup.ts')
  resolveStoreModules(require('../../store/price-machine.ts'), 'price-machine.ts')
  resolveStoreModules(require('../../store/select.ts'), 'select.ts')
  resolveStoreModules(require('../../store/sort.ts'), 'sort.ts')
  resolveStoreModules(require('../../store/tab.ts'), 'tab.ts')
  resolveStoreModules(require('../../store/tips.ts'), 'tips.ts')
  resolveStoreModules(require('../../store/tour.ts'), 'tour.ts')
  resolveStoreModules(require('../../store/upfile.ts'), 'upfile.ts')
  resolveStoreModules(require('../../store/uploadimg.ts'), 'uploadimg.ts')
  resolveStoreModules(require('../../store/uploadlayer.ts'), 'uploadlayer.ts')
  resolveStoreModules(require('../../store/wizard.ts'), 'wizard.ts')
  resolveStoreModules(require('../../store/os/index.ts'), 'os/index.ts')
  resolveStoreModules(require('../../store/app/api.ts'), 'app/api.ts')
  resolveStoreModules(require('../../store/app/auth.ts'), 'app/auth.ts')
  resolveStoreModules(require('../../store/app/edit.ts'), 'app/edit.ts')
  resolveStoreModules(require('../../store/app/loading.ts'), 'app/loading.ts')
  resolveStoreModules(require('../../store/app/session.ts'), 'app/session.ts')
  resolveStoreModules(require('../../store/app/var.ts'), 'app/var.ts')
  resolveStoreModules(require('../../store/layout/default.ts'), 'layout/default.ts')
  resolveStoreModules(require('../../store/manager/app.ts'), 'manager/app.ts')
  resolveStoreModules(require('../../store/manager/blog-article.ts'), 'manager/blog-article.ts')
  resolveStoreModules(require('../../store/manager/blog-category.ts'), 'manager/blog-category.ts')
  resolveStoreModules(require('../../store/manager/blog.ts'), 'manager/blog.ts')
  resolveStoreModules(require('../../store/manager/dev.ts'), 'manager/dev.ts')
  resolveStoreModules(require('../../store/manager/loader.ts'), 'manager/loader.ts')
  resolveStoreModules(require('../../store/manager/postcode.ts'), 'manager/postcode.ts')
  resolveStoreModules(require('../../store/manager/project.ts'), 'manager/project.ts')
  resolveStoreModules(require('../../store/manager/shop-menu-category.ts'), 'manager/shop-menu-category.ts')
  resolveStoreModules(require('../../store/manager/shop-menu-group.ts'), 'manager/shop-menu-group.ts')
  resolveStoreModules(require('../../store/manager/shop-menu.ts'), 'manager/shop-menu.ts')
  resolveStoreModules(require('../../store/manager/shop-staff.ts'), 'manager/shop-staff.ts')
  resolveStoreModules(require('../../store/manager/shop.ts'), 'manager/shop.ts')
  resolveStoreModules(require('../../store/manager/site-media.ts'), 'manager/site-media.ts')
  resolveStoreModules(require('../../store/manager/site.ts'), 'manager/site.ts')
  resolveStoreModules(require('../../store/os/blog.ts'), 'os/blog.ts')
  resolveStoreModules(require('../../store/os/gallery.ts'), 'os/gallery.ts')
  resolveStoreModules(require('../../store/os/menu.ts'), 'os/menu.ts')
  resolveStoreModules(require('../../store/os/staff.ts'), 'os/staff.ts')
  resolveStoreModules(require('../../store/os/store.ts'), 'os/store.ts')
  resolveStoreModules(require('../../store/prod/start.ts'), 'prod/start.ts')
  resolveStoreModules(require('../../store/prod/wizard.ts'), 'prod/wizard.ts')
  resolveStoreModules(require('../../store/theme/theme-cssvars.ts'), 'theme/theme-cssvars.ts')
  resolveStoreModules(require('../../store/theme/theme-main.ts'), 'theme/theme-main.ts')
  resolveStoreModules(require('../../store/theme/theme-state.ts'), 'theme/theme-state.ts')
  resolveStoreModules(require('../../store/app/uploadimg/material.ts'), 'app/uploadimg/material.ts')

  // If the environment supports hot reloading...
})()

// createStore
export const createStore = store instanceof Function ? store : () => {
  return new Vuex.Store(Object.assign({
    strict: (process.env.NODE_ENV !== 'production')
  }, store))
}

function resolveStoreModules(moduleData, filename) {
  moduleData = moduleData.default || moduleData
  // Remove store src + extension (./foo/index.js -> foo/index)
  const namespace = filename.replace(/\.(js|mjs|ts)$/, '')
  const namespaces = namespace.split('/')
  let moduleName = namespaces[namespaces.length - 1]
  const filePath = `store/${filename}`

  moduleData = moduleName === 'state'
    ? normalizeState(moduleData, filePath)
    : normalizeModule(moduleData, filePath)

  // If src is a known Vuex property
  if (VUEX_PROPERTIES.includes(moduleName)) {
    const property = moduleName
    const storeModule = getStoreModule(store, namespaces, { isProperty: true })

    // Replace state since it's a function
    mergeProperty(storeModule, moduleData, property)
    return
  }

  // If file is foo/index.js, it should be saved as foo
  const isIndexModule = (moduleName === 'index')
  if (isIndexModule) {
    namespaces.pop()
    moduleName = namespaces[namespaces.length - 1]
  }

  const storeModule = getStoreModule(store, namespaces)

  for (const property of VUEX_PROPERTIES) {
    mergeProperty(storeModule, moduleData[property], property)
  }

  if (moduleData.namespaced === false) {
    delete storeModule.namespaced
  }
}

function normalizeRoot(moduleData, filePath) {
  moduleData = moduleData.default || moduleData

  if (moduleData.commit) {
    throw new Error(`[nuxt] ${filePath} should export a method that returns a Vuex instance.`)
  }

  if (typeof moduleData !== 'function') {
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData)
  }
  return normalizeModule(moduleData, filePath)
}

function normalizeState(moduleData, filePath) {
  if (typeof moduleData !== 'function') {
    console.warn(`${filePath} should export a method that returns an object`)
    const state = Object.assign({}, moduleData)
    return () => state
  }
  return normalizeModule(moduleData, filePath)
}

function normalizeModule(moduleData, filePath) {
  if (moduleData.state && typeof moduleData.state !== 'function') {
    console.warn(`'state' should be a method that returns an object in ${filePath}`)
    const state = Object.assign({}, moduleData.state)
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData, { state: () => state })
  }
  return moduleData
}

function getStoreModule(storeModule, namespaces, { isProperty = false } = {}) {
  // If ./mutations.js
  if (!namespaces.length || (isProperty && namespaces.length === 1)) {
    return storeModule
  }

  const namespace = namespaces.shift()

  storeModule.modules[namespace] = storeModule.modules[namespace] || {}
  storeModule.modules[namespace].namespaced = true
  storeModule.modules[namespace].modules = storeModule.modules[namespace].modules || {}

  return getStoreModule(storeModule.modules[namespace], namespaces, { isProperty })
}

function mergeProperty(storeModule, moduleData, property) {
  if (!moduleData) return

  if (property === 'state') {
    storeModule.state = moduleData || storeModule.state
  } else {
    storeModule[property] = Object.assign({}, storeModule[property], moduleData)
  }
}
