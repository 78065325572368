import {Component, Watch, Prop, Vue} from "vue-property-decorator";

@Component({
    components: {
    }
})
export default class MInviewItem extends Vue {


    @Prop({default: 'div'})
	public tag: string;

	/*
		じゅんばん
	*/
    @Prop({default: 1})
	public num: number;


    @Prop({default: false})
	public random: boolean;


	public classNumber: string = '-inview-item-1';


	public created() {

		let n: number = this.num;

		if(this.random){
			n = Math.floor( Math.random() * 4 ) + 1 ;
		}

		this.classNumber =  '-inview-item-' + String(n);
	}


}



