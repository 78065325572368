











	// @ts-ignore
    import _ from './CEdit.ts'; export default _;
