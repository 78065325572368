




















import {Component,Prop, Vue} from 'nuxt-property-decorator';

@Component({
    components: {
    }
})
export default class MConfirmMemo extends Vue {
	
}
