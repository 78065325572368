import {Component, Prop, Vue} from '@/node_modules/nuxt-property-decorator';
import MToggleListItem from '@/components/module/MToggleListItem.vue';
import {Faq} from "@/configs/faq";

@Component({
    components: {
	    MToggleListItem
    }
})
export default class MToggleList extends Vue {

    @Prop({default: () => Faq})
	public list: any;

}
