import {Component, Vue, Watch} from 'nuxt-property-decorator';
import AVueComponent from '@/classes/page/a-vue-component';
import CmdController from "@/components/part/share/CmdController.vue";
import {IManagerLoader, managerLoaderModule} from "@/store/manager/loader";
import MLoaderFrame from "@/components/module/MLoaderFrame";

@Component({
    components: {
        MLoaderFrame,
        CmdController,
    }
})
export default class Simple extends AVueComponent {

    public async mounted() {
    }

    public get managerLoaders(): IManagerLoader[] {
        return managerLoaderModule.loaders;
    }
}
