import $ from 'jquery';
import WUtil from "@/classes/view/w-util";
import {$v} from "@/classes/utils/var-util";

const boot = () => {
    if (!$v.has(window, 'FONTPLUS')) {
        console.log('no FONTPLUS');
        setTimeout(() => boot(), 50);
    } else {
        console.log('has FONTPLUS');

        // @ts-ignore
        window!['FONTPLUS'].async();

        // @ts-ignore
        window!['FONTPLUS'].load(
            [
                {
                    'fontname': 'FP-HiraKakuProN-W3',
                    'nickname': 'FP-HiraKakuProN-W3',
                    'text': '写真を撮って、熱意を書いて、できあがり。自分でできる、小規模店のためのホームページ作成ツール。大きな力に負けないブランドを、自分でつくろう。質問にこたえるだけで高品質なサイトのできあがり。ギャラリーやお店ページ、スタッフ管理、メニューまで一瞬です。あなたが入力した内容にあわせて自動レイアウト。熟練デザイナーがそばにいるような価値を提供します。かんたんでも多機能です。 地図や電話番号の強調、スタッフのプロフィール、メニュー表まで作成できます。高品質無料素材を、その場ですぐにご利用になれます。保存したりアップロードする手間もありません。スマートフォンのために、何かをする必要はありません。編集すると自動でページが生成されます。結果を常にみながら編集ができるので、出来栄えを見ながら感覚的に作成できます。通常制作の10分の1以下で、自由に更新できる高品質なホームページが手に入ります。ヘアーサロン、移動店舗、カフェなどのデザイン重視の小規模店のオーナー向けホームページ作成ツール(ソート)を年夏ごろリリースします。 手軽に高品質なホームページを作成でき、高品質無料写真素材の利用、世界初のフォントシステムを搭載した、美しいデザインを誰でも表現できます。 リリースまでお楽しみに。'
                },
                {
                    'fontname': 'FP-KoburinaGoStdN-W3',
                    'nickname': 'FP-KoburinaGoStdN-W3',
                    'text': 'お店に必要なものがそろっています。質問にこたえるだけで。自動レイアウトエンジン。50000点以上の無料素材。スマホやタブレットにも対応。結果をみながら編集できる。1日たったの80円で。ブランディングツール Thought2020年夏リリース予定！'
                },
                {
                    'fontname': 'TradeGothicLTPro',
                    'nickname': 'loadname',
                    'text': 'Skip0123456789HAIR DESIGN STUDIO WEEKEND.4-7 ASUKAZE CHUO-KU2020 umer DebutCOMING SONomingoonJust answer the question.Automatic layout engine.Features for salons, shops.Free stock photos are ready.Mobile layouts available.Real-time Editing.Cheaper than a cup of coffee.'
                },
                {
                    'fontname': 'TradeGothicLTPro-Bold',
                    'nickname': 'loadname',
                    'text': '0123456789'
                },
            ],
            (arg: any) => {
                console.log('Font plus loaded', arg);
            },
            'fontplus',
        );
    }
};

WUtil.browser(() => {
    $(() => {
        boot();
    });
});
