import {Mutation, MutationAction, Action, VuexModule, getModule, Module} from 'vuex-module-decorators';
import store from '@/classes/core/store';
import {OsLoader} from '@/classes/app/os/os-loader';
import {ownersiteModule} from '@/store/ownersite';

// state's interface
export interface IOsMenuModule {
    content: any;
}

@Module({dynamic: true, store, name: 'osMenu', namespaced: true})
class Store extends VuexModule implements IOsMenuModule {

    // Page data
    public content: any | null = null;

    @Mutation
    public updateContent(value: any) {
        this.content = value;
    }

    @Action
    public async fetch() {

        // console.log('Dummy pj', JSON.stringify(DummyProject));
        // console.log('Dummy page home', JSON.stringify(DummyPageHome));

        const page = await OsLoader.load('menu');
        ownersiteModule.updateProject(page.project);
        osMenuModule.updateContent(page.content);
    }
}

export const osMenuModule = getModule(Store);

