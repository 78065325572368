import {Component} from "nuxt-property-decorator";
import RoutingUtil from "@/classes/view/routing-util";
import AAccount from '@/classes/page/a-account';
import {accountModule} from '@/store/account';
import {priceMachineModule} from '@/store/price-machine';
import {appModule} from '@/store/app';

import MSecondaryPlanChange from '@/components/account/procedure/secondary/plan/MSecondaryPlanChange.vue';
import MPriceMachine from '@/components/module/MPriceMachine.vue';
import MToggleList from '@/components/module/MToggleList.vue';

@Component({
    components: {
        MSecondaryPlanChange,
	    MPriceMachine,
        MToggleList,
    }
})
export default class ProcedurePlanChangeSelectStore extends AAccount {

	public mypagetype: string = 'procedure';
	public mydivider: string = 'half';

    ///////// このページ ////////////////////////////////////////////
    public isMulti: boolean = false;



    // SSRでサーバーサイドの処理から変数をセット
    public async asyncData(context: any): Promise<any> {
        return {
            test: 128,
            title: 'Indexa',
        }
    }

    // ヘッダタグの構成要素
    public head() {
        return {
            //title: this.title
        }
    }

    ///////// 全ページ共通 ////////////////////////////////////////////
    public transition(to: any, from: any) {
		return RoutingUtil.transition(to, from);
	}

    public created(){

		///////// 全ページ共通 ////////////////////////////////////////////
		this.setPagetype( appModule, this.mypagetype, this.mydivider );

    }


    ///////// このページ ////////////////////////////////////////////
    public get storeNumber(): any {
        return priceMachineModule.storeNumber;
    }

}
