import {Component, Watch, Prop, Vue} from 'vue-property-decorator';
import {themeMainModule} from '@/store/theme/theme-main';
import CPatternMix from '@/classes/theme/mixin/c-pattern-mix';

import {DummyStore} from '@/configs/dummy/dummy-store';
import {osStoreModule} from '@/store/os/store';


@Component({
    mixins: [CPatternMix],
    components: {}
})
export default class CPageStoreDetailStandard extends Vue {


    // public me: any = {};
    // public list: any = DummyStore;


    @Prop({default: '1'})
    public active: string;


    public created() {
        // const d: any = DummyStore.findByKey('id', this.active );
        // if( !d ){
        // 	return
        // }
        // this.me = d;
    }

    public get list(): any {
        return osStoreModule.content!['stores'] || DummyStore;
    }

    public get me(): any {
        return osStoreModule.content!['store'] || {};
    }
}

