import {Mutation, VuexModule, getModule, Module} from 'vuex-module-decorators';
import store from '@/classes/core/store';

// state's interface
export interface IManagerAppModule {
    readyPrimary: boolean;
}

@Module({dynamic: true, store, name: 'managerApp', namespaced: true})
class ManagerApp extends VuexModule implements IManagerAppModule {

    private _readyPrimary: boolean = false;

    // Getters //////////////////////////
    public get readyPrimary(): boolean {
        return this._readyPrimary;
    }

    // Mutations ////////////////////////
    @Mutation
    public updateReadyPrimary(value: boolean) {
        this._readyPrimary = value;
    }
}

export const managerAppModule = getModule(ManagerApp);

const M = managerAppModule;
